<template>
  <div class="report" style="font-size: 10px">
    <table class="table table-bordered table-hover z-1 position-relative">
      <thead class="sticky-element">
        <tr>
          <th scope="col" class="align-middle text-center" rowspan="2" width="50">
            <b>Kode Item</b>
          </th>
          <th scope="col" class="align-middle text-center" rowspan="2">
            <b>Nama Item</b>
          </th>
          <th scope="col" class="align-middle text-center" rowspan="2">
            <b>Grup Item</b>
          </th>
          <th scope="col" class="align-middle text-center" rowspan="2"><b>Item UM</b></th>
          <th scope="col" class="align-middle text-center" colspan="2" width="50">
            <b>Stok Awal</b>
          </th>
          <th scope="col" class="align-middle text-center" colspan="2" width="50">
            <b>Masuk</b>
          </th>
          <th scope="col" class="align-middle text-center" colspan="2" width="50">
            <b>Keluar</b>
          </th>
          <th scope="col" class="align-middle text-center" rowspan="2" width="50">
            <b>Unit Cost</b>
          </th>
          <th scope="col" class="align-middle text-center" colspan="2" width="50">
            <b>Stok Akhir</b>
          </th>
        </tr>
        <tr>
          <th class="align-middle text-center" width="40"><b>Qty</b></th>
          <th class="align-middle text-center" width="40"><b>Nilai</b></th>
          <th class="align-middle text-center" width="40"><b>Qty</b></th>
          <th class="align-middle text-center" width="40"><b>Nilai</b></th>
          <th class="align-middle text-center" width="40"><b>Qty</b></th>
          <th class="align-middle text-center" width="40"><b>Nilai</b></th>
          <th class="align-middle text-center" width="40"><b>Qty</b></th>
          <th class="align-middle text-center" width="40"><b>Nilai</b></th>
        </tr>
      </thead>
      <tbody v-if="realData.length > 0">
        <tr v-for="entry in realData">
          <td>{{ entry.itemCode }}</td>
          <td>{{ entry.itemName }}</td>
          <td>
            <span v-for="group in entry.itemGroup">{{ group }},</span>
          </td>
          <td>{{ entry.itemInvStock.invUM }}</td>
          <td class="text-center">{{ entry.itemInvStock.invBegining }}</td>
          <td class="text-center">{{ entry.itemInvStock.invBegining }}</td>

          <td class="text-center">{{ entry.itemInvStock.invIn }}</td>
          <td class="text-center">{{ entry.itemInvStock.invIn }}</td>

          <td class="text-center">{{ entry.itemInvStock.invOut }}</td>
          <td class="text-center">{{ entry.itemInvStock.invOut }}</td>

          <td class="text-center">{{ entry.itemInvStock.invOut }}</td>

          <td class="text-center">{{ entry.itemInvStock.invEnd }}</td>
          <td class="text-center">{{ entry.itemInvStock.invEnd }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="6">Data Tidak Ditemukan</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th scope="col" class="text-center" width="50"><b>Kode Item</b></th>
          <th scope="col" class="text-center"><b>Nama Item</b></th>
          <th scope="col" class="text-center"><b>Grup Item</b></th>
          <th scope="col" class="text-center"><b>Item UM</b></th>
          <th scope="col" class="text-center" width="100" colspan="2">
            <b>STOK AWAL</b>
          </th>
          <th scope="col" class="text-center" width="100" colspan="2"><b>MASUK</b></th>
          <th scope="col" class="text-center" width="100" colspan="2"><b>KELUAR</b></th>
          <th scope="col" class="text-center" width="50">
            <b>UNIT COST</b>
          </th>
          <th scope="col" class="text-center" width="100" colspan="2">
            <b>STOK AKHIR</b>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { formatNumber } from "../function/number";
export default {
  props: {
    saldoAwal: String,
    data: Array,
  },
  data() {
    return {
      saldoAkhir: "0",
      saldoIn: "0",
      saldoOut: "0",
    };
  },
  computed: {
    realData() {
      return this.data;
    },
    updateSaldoAwal() {
      return formatNumber(this.saldoAwal.toString());
    },
    updateSaldoIn() {
      let ini = this.data
        .filter((x) => parseFloat(x.transIn) > 0 && parseFloat(x.transOut) <= 0)
        .reduce((sum, item) => sum + parseFloat(item.transAmount), 0);
      this.saldoIn = ini;
      return formatNumber(ini);
    },
    updateSaldoOut() {
      let out = this.data
        .filter((x) => parseFloat(x.transOut) > 0 && parseFloat(x.transIn) <= 0)
        .reduce((sum, item) => sum + parseFloat(item.transAmount), 0);
      if (out < 0) {
        out = out * -1;
      }
      this.saldoOut = out;
      return formatNumber(out);
    },
    updateSaldoAkhir() {
      return formatNumber((this.saldoAwal + this.saldoIn - this.saldoOut).toString());
    },
  },
  methods: {},
};
</script>

<style scoped></style>
