<template>
  <main id="content" role="main" class="main">
    <audio controls ref="audioElement" class="d-none">
      <source :src="require('../assets/audio/bell.mp3')" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
    <transition name="fade" mode="out-in">
      <div v-if="isDataLoaded === ''" key="empty">
        <loading></loading>
      </div>

      <!-- Main Content -->
      <div v-else-if="isDataLoaded" key="truthy">
        <div class="content container-fluid p-3 ps-0 pt-0">
          <div class="row my-1">
            <div class="col-4 d-flex align-items-center">
              <ul class="breadcrumb m-0">
                <li>
                  <router-link to="/dashboard">
                    <a href="#"><i class="bi-house-door"></i></a>
                  </router-link>
                </li>
                <li>
                  <router-link :to="$route.path">
                    <a href="#">Resep Masuk Bulanan</a>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="col-4 text-center"></div>
          </div>
          <hr class="my-2" />

          <!-- Content -->
          <template v-if="isDataLoaded">
            <div class="card">
              <div class="card-header bg-light p-2">
                <div class="row">
                  <div
                    class="row col-10 d-flex align-items-center justify-content-between"
                  >
                    <div class="w-25">
                      <!-- <b>TOTAL DATA: {{ hotData.length }}</b> -->
                      <b
                        >TOTAL DATA:
                        <div
                          v-if="isRefreshLoaded === ''"
                          class="spinner-border spinner-border-sm"
                          role="status"
                        ></div>
                        <span v-else>{{ totalData }}</span></b
                      >
                    </div>
                    <b-form-group style="width: 200px">
                      <b-form-input
                        type="month"
                        class="form-control-sm py-1"
                        v-model="getMonth"
                        @change="getData"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div
                    class="col-2 d-flex justify-content-between ps-0"
                    style="z-index: 2"
                  >
                    <button
                      type="button"
                      @click="refreshData"
                      class="btn btn-light px-2 py-0"
                    >
                      <i class="bi-arrow-repeat"></i>
                    </button>
                    <b-button-group>
                      <button class="btn btn-primary btn-xs" @click="printReport">
                        <i class="bi-printer pe-2"></i>Print
                      </button>
                      <b-dropdown id="dropdown2" variant="primary" size="xs" right>
                        <div>
                          <p class="p-2 pb-0 m-0">Export</p>
                        </div>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="generatePDF">PDF</b-dropdown-item>
                        <b-dropdown-item>xls</b-dropdown-item>
                      </b-dropdown>
                    </b-button-group>
                  </div>
                </div>
              </div>

              <transition name="fade" mode="out-in">
                <div v-if="isRefreshLoaded === ''" key="empty">
                  <loading></loading>
                </div>

                <!-- Main Content -->
                <div v-else-if="isRefreshLoaded" key="truthy">
                  <div class="card-body p-2 printData">
                    <div class="text-center">
                      <span class="h2">List Resep Masuk Bulanan</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between"></div>
                    <hr class="my-2" />
                    <!-- tabel view untuk detil -->
                    <b-table
                      striped
                      hover
                      :items="liveData"
                      :fields="tableHeader"
                      label-sort-asc=""
                      label-sort-desc=""
                      class="myTable listTable"
                    >
                      <template #cell(PoSupplier)="data">
                        {{ data.item.PoSupplier.userName }}
                      </template>
                      <template #cell(transIdMember)="data">
                        <template v-if="data.item.transIdMember">
                          {{ data.item.transIdMember.userAddress[0].location }}
                        </template>
                      </template>
                      <template #cell(transPesanan)="data">
                        <div class="inner-table">
                          <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th style="width: 100px">Nama Barang</th>
                                <th>Qty</th>
                                <th>Harga</th>
                                <th>Total</th>
                                <!-- Tambahkan kolom lain di sini sesuai kebutuhan -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item, index) in data.item.transPesanan"
                                :key="index"
                              >
                                <td>{{ item.itemName }}</td>
                                <td>{{ item.itemQty }}</td>
                                <td>
                                  {{ formatNumberInt(item.itemMasterPrice.priceValue) }}
                                </td>
                                <td>
                                  {{
                                    formatNumberInt(
                                      item.itemMasterPrice.priceValue * item.itemQty
                                    )
                                  }}
                                </td>
                                <!-- Tambahkan kolom lain di sini sesuai kebutuhan -->
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </template>
                      <template v-slot:cell(actions)="data">
                        <b-dropdown
                          id="dropdown-option"
                          right
                          size="xs"
                          variant="primary"
                          v-if="data.item.transStatusSO === 'resep'"
                        >
                          <template #button-content class="px-1 py-0">
                            <i class="bi-gear px-1 py-0"></i>
                          </template>
                          <b-dropdown-item @click="detilAction(data.item)"
                            >Edit</b-dropdown-item
                          >
                        </b-dropdown>
                      </template>
                    </b-table>
                  </div>
                </div>

                <!-- Error -->
                <div v-else key="other">
                  <connection-timeout></connection-timeout>
                </div>
              </transition>
            </div>
          </template>
          <div v-else>Loading...</div>
        </div>
      </div>

      <!-- Error -->
      <div v-else key="other">
        <connection-timeout></connection-timeout>
      </div>
    </transition>
  </main>
</template>

<script>
// import router from "@/router";
import io from "socket.io-client";
import { API } from "@/API";

import { formatNumberInt } from "../components/function/number";
import {
  getListReportApotekSO,
  getListReportApotekSOBulanan,
} from "../components/services/components";

import loading from "../components/loading.vue";
import connectionTimeout from "../components/connectionTimeout.vue";
import { getMonth } from "@/components/function/date";
export default {
  components: {
    loading,
    connectionTimeout,
  },
  data() {
    return {
      getMonth: "",
      newData: "",
      audioSource: "../assets/audio/bell.mp3",
      isDataLoaded: "",
      isRefreshLoaded: true,
      // pagination
      rows: 3,
      perPage: 10,
      currentPage: 1,
      optionQuery: ["start at", "equal", "include"],
      pageList: ["10", "25", "50", "Semua"],
      // ===
      filterList: [],
      filterText: "",
      tableHeader: [],
      dataListPO: [],
    };
  },
  async mounted() {
    const timeout = 30000; // 10 seconds
    try {
      this.getMonth = await getMonth();
      console.log("bulan ini: ", this.getMonth);
      this.addFilterList();
      (this.tableHeader = [
        {
          key: "transId",
          label: "Nomor SO",
          sortable: true,
          class: "align-top",
          thClass: "text-center",
        },
        {
          key: "transKasir.userName",
          label: "Dokter",
          sortable: true,
          class: "align-top",
          thClass: "text-center",
        },
        {
          key: "transName",
          label: "Pasien",
          sortable: true,
          class: "align-top",
          thClass: "text-center",
        },
        {
          key: "transIdMember",
          label: "Alamat Pasien",
          sortable: true,
          class: "align-top",
          thClass: "text-center",
          thStyle: "width: 200px",
        },
        {
          key: "transPesanan",
          label: "Item Resep",
          sortable: true,
          class: "align-top",
          thClass: "text-center",
        },
        {
          key: "transDate",
          label: "Tgl. Berobat",
          sortable: true,
          class: "align-top",
          thClass: "text-center",
        },
        {
          key: "transStatusSO",
          label: "Status",
          sortable: true,
          class: "align-top",
          thClass: "text-center",
        },
      ]),
        // (this.dataListPO = await getListReportApotekSO());
        (this.dataListPO = await getListReportApotekSOBulanan(this.getMonth));
      // console.log("ini baru : ", halo);
      const timeoutPromise = new Promise((resolve) => setTimeout(resolve, timeout));

      // Wait for either finalPromise or timeoutPromise to resolve
      const result = await Promise.race([this.dataListPO, timeoutPromise]);
      this.isDataLoaded = result !== undefined;
    } catch (error) {
      this.isDataLoaded = false;
    }

    // WebSocket connection
    const socket = io(API);
    socket.on("woReceipt", async (message) => {
      console.log("ini message: ", message);
      if (message) {
        console.log("kiriman", message);
        if (message.pesan === "cekResep") {
          // this.dataListPO = await getListReportApotekSO();
          this.dataListPO = await getListReportApotekSOBulanan(this.getMonth);
          // console.log("hasil bulanan: ", coba);
          this.newData = message.data;
          this.playAudio();
        }
      } else {
        alert("kosong");
      }
    });
  },
  computed: {
    liveData() {
      if (this.newData !== "") {
        return this.dataListPO.reverse().map((x) => {
          const data = {
            ...x,
          };
          if (x.transNoSO === this.newData) {
            data._rowVariant = "success";
          }
          return data;
        });
      } else {
        return this.dataListPO.reverse();
      }
    },
    totalData() {
      console.log("hasilnya :", typeof this.liveData);
      console.log(this.liveData);
      if (typeof this.liveData === "array" || typeof this.liveData === "object") {
        return this.liveData.length;
      } else {
        return "-";
      }
    },
    filteredTableData() {
      const filterText = this.filterText.toLowerCase().trim();
      return this.dataListPO.filter((row) => {
        for (let key in row) {
          const value = String(row[key]);
          if (value.toLowerCase().includes(filterText)) {
            return true;
          }
        }
        return false;
      });
    },
  },
  methods: {
    refreshData() {
      this.getData();
    },
    generatePDF() {},
    printReport() {
      console.log("ini print", this.getReportContent());
      const printWindow = window.open("", "_blank");
      const printDocument = `
        <html>
          <head>
	          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet">
          <style>
          body {
            font-size: 10px !important
          }
th {
  font-weight: 500
}
tr {
  font-size: 10px !important
}
          </style>
            </head>

          <body>
            ${this.getReportContent()}
          </body>
        </html>
      `;
      printWindow.document.write(printDocument);
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
        printWindow.close();
      };
    },
    getReportContent() {
      const reportView = document.querySelector(".printData");
      if (reportView) {
        return reportView.innerHTML;
      }
      return "No content found.";
    },
    async getData() {
      this.isRefreshLoaded = "";
      try {
        const tes = await getListReportApotekSOBulanan(this.getMonth);
        console.log(tes);
        this.dataListPO = [...tes];
        this.isRefreshLoaded = true;
      } catch (error) {
        this.isRefreshLoaded = false;
      }
    },
    formatNumberInt(data) {
      return formatNumberInt(data);
    },
    playAudio() {
      const audio = this.$refs.audioElement; // Access the audio element using the ref
      audio.play().catch((error) => {
        alert("Klik Dimanapun di dalam Page Agar Notif Berbunyi!");
      }); // Play the audio
    },
    addFilterList() {
      const data = {
        filterBaris: "",
        startFrom: "",
        filter1: "",
        logic: "",
        filter2: "",
      };
      this.filterList.push(data);
    },

    removeFilterRow(data) {
      this.filterList.splice(data, 1);
    },
    detilAction(ponum) {
      // alert(data);
      const data = JSON.stringify(ponum);
      this.$router.push({ name: this.$route.name + "-edit", query: { data } });
    },
  },
};
</script>
<style scope></style>
