var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c(
        "div",
        {
          ref: "contentDiv",
          staticClass: "content container-fluid p-3 ps-0 pt-0",
        },
        [_vm._m(0), [_vm._m(1)]],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "breadcrumb m-1 mt-0" }, [
      _c("li", [
        _c("a", { attrs: { href: "#" } }, [
          _c("i", { staticClass: "bi-house-door" }),
        ]),
      ]),
      _c("li", [_vm._v("Laporan Laba Rugi")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h2", { staticClass: "text-center" }, [_vm._v("Laporan Laba Rugi")]),
      _c("div", { staticClass: "section" }, [
        _c("h4", { staticClass: "judul" }, [_vm._v("I. PENJUALAN BERSIH")]),
        _c("table", [
          _c("tr", [
            _c("td", [_vm._v("Penjualan")]),
            _c("td", { staticClass: "nominal" }),
            _c("td", { staticClass: "nominal" }),
            _c("td", { staticClass: "nominal" }, [_vm._v("Rp 3.500.000")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Retur Penjualan")]),
            _c("td", { staticClass: "nominal" }),
            _c("td", { staticClass: "nominal" }),
            _c("td", { staticClass: "nominal" }, [_vm._v("Rp 300.000")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Potongan Penjualan")]),
            _c("td", { staticClass: "nominal" }),
            _c("td", { staticClass: "nominal" }),
            _c("td", { staticClass: "nominal" }, [_vm._v("Rp 200.000")]),
          ]),
        ]),
      ]),
      _c("h4", { staticClass: "judul" }, [_vm._v("II HARGA POKOK PENJUALAN")]),
      _c("table", [
        _c("tr", [
          _c("td", [_vm._v("Persediaan Barang Jadi Awal")]),
          _c("td", { staticClass: "nominal" }, [_vm._v("Rp 1.000.000")]),
          _c("td", { staticClass: "nominal" }),
          _c("td", { staticClass: "nominal" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v("Harga Pokok Produksi")]),
          _c("td", { staticClass: "nominal" }, [_vm._v("Rp 1.000.000")]),
          _c("td", { staticClass: "nominal" }),
          _c("td", { staticClass: "nominal" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v("Barang Tersedia Untuk Dijual")]),
          _c("td", { staticClass: "nominal" }),
          _c("td", { staticClass: "nominal" }, [_vm._v("Rp 1.000.000")]),
          _c("td", { staticClass: "nominal" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v("Persediaan Barang Jadi Akhir")]),
          _c("td", { staticClass: "nominal" }),
          _c("td", { staticClass: "nominal" }, [_vm._v("Rp 1.000.000")]),
          _c("td", { staticClass: "nominal" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v("Harga Pokok Penjualan")]),
          _c("td", { staticClass: "nominal" }),
          _c("td", { staticClass: "nominal" }),
          _c("td", { staticClass: "nominal" }, [_vm._v("Rp 1.000.000")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("Laba Kotor")]),
          _c("td", { staticClass: "nominal" }),
          _c("td", { staticClass: "nominal" }),
          _c("td", { staticClass: "nominal" }, [_vm._v("Rp 1.000.000")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }