<template>
  <div>
    <button @click="print">Print</button>
  </div>
</template>

<script>
export default {
  methods: {
    print() {
      // Implementasi logika untuk mengirim perintah langsung ke printer
      try {
        const printer = new escpos.Printer(new escpos.USB(0x04b8, 0x0202));
        printer.text("\x1B@Hello, this is ESC/P printing.\x0A\x0A\x0A\x1Bd\x01");
        printer.cut();
        printer.close();
      } catch (error) {
        console.error("Error printing:", error);
      }
    },
  },
};
</script>

<style scoped>
.hello {
  text-align: center;
  margin-top: 60px;
}
</style>
