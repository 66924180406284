<template>
  <main id="content" role="main" class="main">
    <transition name="fade" mode="out-in">
      <div v-if="isDataLoaded === ''" key="empty">
        <loading></loading>
      </div>

      <!-- Main Content -->
      <div v-else-if="isDataLoaded" key="truthy">
        <div class="content container-fluid p-3 ps-0 pt-0">
          <div class="row my-1">
            <div class="col-4 d-flex align-items-center">
              <ul class="breadcrumb m-0">
                <li>
                  <router-link to="/dashboard">
                    <a href="#"><i class="bi-house-door"></i></a>
                  </router-link>
                </li>
                <li>
                  <router-link :to="$route.path">
                    <a href="#">Kasbank</a>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="col-4 text-center"><span class="h2">List Kasbank</span></div>
          </div>
          <hr class="my-2" />

          <!-- Content -->
          <div class="card">
            <div class="card-header bg-light p-2">
              <div class="row d-flex">
                <div class="col-9 row">
                  <div
                    class="d-flex col-12 align-items-center pe-0 mt-2"
                    v-for="(data, i) in filterList"
                  >
                    <select
                      style="font-size: 12px; max-width: 125px"
                      class="form-select form-select-sm py-1 px-2"
                      v-model="data.filterBaris"
                    >
                      <option value="" v-if="!filterBaris">Filter Baris</option>
                      <option
                        v-for="micro in tableHeader"
                        :value="micro.key"
                        v-if="micro.key !== 'actions'"
                      >
                        {{ micro.label }}
                      </option>
                    </select>
                    <select
                      class="mx-2 form-select form-select-sm py-1 px-2"
                      style="font-size: 12px; max-width: 125px"
                      v-model="data.startFrom"
                    >
                      <option value="" v-if="!data.startFrom">Tipe Pencarian</option>
                      <option :value="micro" v-for="micro in optionQuery">
                        {{ micro }}
                      </option>
                    </select>
                    <div class="input-group" style="width: 150px">
                      <div class="input-group-prepend">
                        <span class="input-group-text py-1 px-2"
                          ><i class="bi-search" style="font-size: 10px"></i
                        ></span>
                      </div>
                      <input
                        type="search"
                        class="form-control form-control-sm p-1"
                        placeholder="Cari Data..."
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        style="font-size: 12px"
                        v-model="data.filter1"
                      />
                    </div>
                    <div class="input-group px-2" style="width: 150px">
                      <div class="input-group-prepend">
                        <span class="input-group-text py-1 px-2"
                          ><i class="bi-search" style="font-size: 10px"></i
                        ></span>
                      </div>
                      <input
                        type="search"
                        class="form-control form-control-sm p-1"
                        placeholder="Cari Data..."
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        style="font-size: 12px"
                        v-model="data.filter2"
                      />
                    </div>
                    <select
                      class="mx-2 form-select form-select-sm py-1"
                      style="font-size: 12px; max-width: 65px"
                      v-model="data.logic"
                    >
                      <option value="and">dan</option>
                      <option value="or">atau</option>
                    </select>
                    <i
                      class="bi-plus-circle text-primary ms-1"
                      style="cursor: pointer"
                      @click="addFilterList"
                    ></i>
                    <i
                      class="bi-x-circle text-danger ms-1"
                      style="cursor: pointer"
                      @click="removeFilterRow(i)"
                    ></i>
                  </div>
                </div>

                <div
                  class="col-3 d-flex justify-content-between mt-2 align-items-start p-0"
                  style="z-index: 2"
                >
                  <button type="button" class="btn-xs btn btn-outline-success">
                    Cari
                  </button>
                  <button type="button" class="btn-xs btn btn-outline-warning">
                    Reset
                  </button>
                  <div class="btn-group me-2">
                    <router-link
                      :to="$route.path + '/add'"
                      class="btn btn-xs btn-primary"
                    >
                      Tambah
                    </router-link>
                    <button
                      type="button"
                      class="btn btn-xs btn-primary dropdown-toggle dropdown-toggle-split"
                      href="javascript:;"
                      id="accountNavbarDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-dropdown-animation
                    ></button>
                    <div class="dropdown">
                      <div
                        class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                        aria-labelledby="accountNavbarDropdown"
                      >
                        <div class="flex-grow-1 ms-3">
                          <p class="card-text text-body" style="font-size: 12px">
                            Import file
                          </p>
                        </div>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#"> json</a>
                        <a class="dropdown-item" href="#">xls</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body p-2">
              <div class="d-flex align-items-center justify-content-between">
                <div
                  style="font-size: 11px; width: 300px"
                  class="d-flex align-items-center justify-content-between"
                >
                  Menampilkan 1 - 10 data dari {{ rows }} Data
                  <select
                    class="form-select py-1"
                    aria-label="Default select example"
                    style="width: 75px; font-size: 12px; padding: 0 0.5rem !important"
                    v-model="perPage"
                  >
                    <option v-for="data in pageList" :value="data">
                      {{ data }}
                    </option>
                  </select>
                </div>
                <b-pagination
                  class="m-0"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  ><template #first-text
                    ><button class="btn btn-outline-primary btn-xs">
                      First
                    </button></template
                  >
                  <template #prev-text
                    ><button class="btn btn-outline-primary btn-xs">
                      Prev
                    </button></template
                  >
                  <template #next-text
                    ><button class="btn btn-outline-primary btn-xs">
                      Next
                    </button></template
                  >
                  <template #last-text
                    ><button class="btn btn-outline-primary btn-xs">
                      Last
                    </button></template
                  >
                  <template #ellipsis-text> ... </template>
                  <template #page="{ page, active }">
                    <b v-if="active">{{ page }}</b>
                    <i v-else>{{ page }}</i>
                  </template></b-pagination
                >
              </div>
              <hr class="my-2" />
              <!-- tabel view untuk detil -->
              <b-table
                striped
                hover
                :items="listData"
                :fields="tableHeader"
                label-sort-asc=""
                label-sort-desc=""
                label-sort-clear="Reset"
                class="myTable listTable"
              >
                <template #cell(PoSupplier)="data">
                  {{ data.item.PoSupplier.userName }}
                </template>
                <template #cell(transIdMember)="data">
                  <template v-if="data.item.transIdMember">
                    {{ data.item.transIdMember.userAddress[0].nameAddr }}
                  </template>
                </template>
                <template #cell(transPesanan)="data">
                  <div class="inner-table">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Nama Barang</th>
                          <th>Qty</th>
                          <th>Harga</th>
                          <!-- Tambahkan kolom lain di sini sesuai kebutuhan -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in data.item.transPesanan" :key="index">
                          <td>{{ item.itemName }}</td>
                          <td>{{ item.itemQty }}</td>
                          <td>{{ item.itemMasterPrice[0].priceName }}</td>
                          <!-- Tambahkan kolom lain di sini sesuai kebutuhan -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
                <template v-slot:cell(actions)="data">
                  <b-dropdown id="dropdown-option" right size="xs" variant="primary">
                    <template #button-content class="px-1 py-0">
                      <i class="bi-gear px-1 py-0"></i>
                    </template>
                    <b-dropdown-item @click="detilAction(data.item)"
                      >Detail</b-dropdown-item
                    >
                  </b-dropdown>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>

      <!-- Error -->
      <div v-else key="other">
        <connection-timeout></connection-timeout>
      </div>
    </transition>
  </main>
</template>

<script>
// import router from "@/router";
import { getSO, getC, getD } from "../components/services/components";
import { formatNumber } from "../components/function/number";

import loading from "../components/loading.vue";
import connectionTimeout from "../components/connectionTimeout.vue";
export default {
  components: {
    loading,
    connectionTimeout,
  },
  data() {
    return {
      isDataLoaded: "",
      rows: 3,
      perPage: 10,
      currentPage: 1,
      optionQuery: ["start at", "equal", "include"],
      pageList: ["10", "25", "50"],
      // ===
      filterList: [],
      filterText: "",
      tableHeader: [],
      dataListPO: [],
    };
  },
  async mounted() {
    const timeout = 30000; // 10 seconds
    try {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      let month = currentDate.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }

      const coba = await getC(year, month);
      console.log("datanya", coba);
      // this.data = coba
      //   .sort((a, b) => a.transEffDate.localeCompare(b.transEffDate))
      //   .map((x) => {
      //     const data = {
      //       ...x,
      //     };
      //     if (x.transAmount.startsWith("-")) {
      //       data.transAmount = x.transAmount.slice(1);
      //     }
      //     data.transAmount = this.formatNumber(data.transAmount);
      //     return data;
      //   })
      // .sort();
      this.data = coba.map((x) => {
        const data = {
          ...x,
        };
        if (x.transAmount.startsWith("-")) {
          data.transAmount = x.transAmount.slice(1);
        }
        data.transAmount = this.formatNumber(data.transAmount);
        return data;
      });
      this.rows = this.data.length;
      this.addFilterList();
      (this.tableHeader = [
        {
          key: "code",
          label: "Kode Voucher",
          sortable: true,
          class: "align-top",
          thClass: "text-center align-middle",
        },
        {
          key: "transCoaCode",
          label: "Kode COA",
          sortable: true,
          class: "align-top",
          thClass: "text-center align-middle",
        },
        {
          key: "transCoaName",
          label: "Nama COA",
          sortable: true,
          class: "align-top",
          thClass: "text-center align-middle",
        },
        {
          key: "user",
          label: "Creator",
          sortable: true,
          class: "align-top",
          thClass: "text-center align-middle",
        },
        {
          key: "desc",
          label: "Deksripsi",
          sortable: true,
          class: "align-top",
          thClass: "text-center align-middle",
          thStyle: "width: 200px",
        },
        {
          key: "transEffDate",
          label: "Trans. Date",
          sortable: true,
          class: "align-top",
          thClass: "text-center align-middle",
        },
        {
          key: "transType",
          label: "Tipe",
          sortable: true,
          class: "align-top",
          thClass: "text-center align-middle",
        },
        {
          key: "transAmount",
          label: "Nominal",
          sortable: true,
          class: "align-top",
          thClass: "text-center align-middle",
        },
        {
          key: "actions",
          label: "Opsi",
          class: "align-top",
          thClass: "text-center align-middle",
        },
      ]),
        (this.dataListPO = await getSO());
      const timeoutPromise = new Promise((resolve) => setTimeout(resolve, timeout));

      // Wait for either finalPromise or timeoutPromise to resolve
      const result = await Promise.race([this.dataListPO, timeoutPromise]);
      this.isDataLoaded = result !== undefined;
    } catch (error) {
      this.isDataLoaded = false;
    }
  },
  computed: {
    listData() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.data.slice(start, end);
    },
    filteredTableData() {
      const filterText = this.filterText.toLowerCase().trim();
      return this.data.filter((row) => {
        for (let key in row) {
          const value = String(row[key]);
          if (value.toLowerCase().includes(filterText)) {
            return true;
          }
        }
        return false;
      });
    },
  },
  methods: {
    formatNumber(data) {
      return formatNumber(data);
    },
    addFilterList() {
      const data = {
        filterBaris: "",
        startFrom: "",
        filter1: "",
        logic: "",
        filter2: "",
      };
      this.filterList.push(data);
    },

    removeFilterRow(data) {
      this.filterList.splice(data, 1);
    },
    async detilAction(sonum) {
      const sendData = await getD(encodeURIComponent(sonum.code));
      this.$router.push({
        name: this.$route.name + "-detail",
        params: { dataDetail: sendData },
      });
    },
  },
};
</script>
<style scope></style>
