var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "report", staticStyle: { "font-size": "10px" } },
      [
        _c(
          "table",
          {
            staticClass:
              "table table-bordered table-striped z-1 position-relative",
          },
          [
            _c("thead", { staticClass: "sticky-element bg-secondary" }, [
              _c("tr", [
                _c(
                  "th",
                  {
                    staticClass: "text-center align-middle text-white",
                    attrs: { scope: "col", width: "50" },
                  },
                  [_c("b", [_vm._v("NO")])]
                ),
                _c(
                  "th",
                  {
                    staticClass: "text-center align-middle text-white",
                    attrs: { scope: "col", width: "100" },
                  },
                  [_c("b", [_vm._v("HARI/TANGGAL")])]
                ),
                _c(
                  "th",
                  {
                    staticClass: "text-center align-middle text-white",
                    attrs: { scope: "col", rowspan: "2" },
                  },
                  [_c("b", [_vm._v("POLI (DOKTER)")])]
                ),
                _c(
                  "th",
                  {
                    staticClass: "text-center align-middle text-white",
                    attrs: { scope: "col", width: "65" },
                  },
                  [_c("b", [_vm._v("TOTAL PASIEN")])]
                ),
                _c(
                  "th",
                  {
                    staticClass: "text-center align-middle text-white",
                    attrs: { scope: "col", width: "90" },
                  },
                  [_c("b", [_vm._v("ADMIN")])]
                ),
                _c(
                  "th",
                  {
                    staticClass: "text-center align-middle text-white",
                    attrs: { scope: "col", width: "90" },
                  },
                  [_c("b", [_vm._v("TARIF DOKTER")])]
                ),
                _c(
                  "th",
                  {
                    staticClass: "text-center align-middle text-white",
                    attrs: { scope: "col", width: "90" },
                  },
                  [_c("b", [_vm._v("TINDAKAN")])]
                ),
                _c(
                  "th",
                  {
                    staticClass: "text-center align-middle text-white",
                    attrs: { scope: "col", width: "90" },
                  },
                  [_c("b", [_vm._v("Tarif Resep")])]
                ),
                _c(
                  "th",
                  {
                    staticClass: "text-center align-middle text-white",
                    attrs: { scope: "col", width: "90" },
                  },
                  [_c("b", [_vm._v("IN")])]
                ),
                _c(
                  "th",
                  {
                    staticClass: "text-center align-middle text-white",
                    attrs: { scope: "col", width: "90" },
                  },
                  [_c("b", [_vm._v("OUT")])]
                ),
                _c(
                  "th",
                  {
                    staticClass: "text-center align-middle text-white",
                    attrs: { scope: "col", width: "90" },
                  },
                  [_c("b", [_vm._v("SALDO")])]
                ),
              ]),
            ]),
            _c("tbody", [
              _c("tr"),
              _c("tr", [
                _c("td", { attrs: { rowspan: "7" } }, [_c("b", [_vm._v("1")])]),
                _c("td", { attrs: { rowspan: "7" } }, [
                  _c("b", [_vm._v("Senin, 22/10/2023")]),
                ]),
                _c("td", {}, [_vm._v("dr. Edy Susanto")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("33")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 990.000")]),
                _c("td", { staticClass: "text-end" }, [
                  _vm._v("Rp. 2.700.000"),
                ]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 135.000")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [
                  _vm._v("Rp. 3.825.000"),
                ]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 187.000")]),
                _c("td", { staticClass: "text-end" }, [
                  _vm._v("Rp. 3.638.000"),
                ]),
              ]),
              _c("tr", [
                _c("td", {}, [_vm._v("dr. Rahma Anindita")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("25")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 500.000")]),
                _c("td", { staticClass: "text-end" }, [
                  _vm._v("Rp. 1.520.000"),
                ]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [
                  _vm._v("Rp. 2.020.000"),
                ]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [
                  _vm._v("Rp. 2.020.000"),
                ]),
              ]),
              _c("tr", [
                _c("td", {}, [_vm._v("dr. Mohamad Ananto")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("1")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 20.000")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 80.000")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 100.000")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 100.000")]),
              ]),
              _c("tr", [
                _c("td", {}, [_vm._v("dr. Amelya Amardani")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
              ]),
              _c("tr", [
                _c("td", {}, [_vm._v("Laboratotium")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 20.000")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 20.000")]),
              ]),
              _c("tr", [
                _c("td", {}, [_vm._v("Bidan")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
              ]),
              _c("tr", [
                _c("td", { attrs: { colspan: "6" } }, [
                  _c("b", [_vm._v("TOTAL")]),
                ]),
                _c("td", { staticClass: "text-end" }, [
                  _c("b", [_vm._v("Rp. 5.965.000")]),
                ]),
                _c("td", { staticClass: "text-end" }, [
                  _c("b", [_vm._v("Rp. 187.000")]),
                ]),
                _c("td", { staticClass: "text-end" }, [
                  _c("b", [_vm._v("Rp. 5.778.000")]),
                ]),
              ]),
              _c("tr"),
              _c("tr", [
                _c("td", { attrs: { rowspan: "7" } }, [_c("b", [_vm._v("1")])]),
                _c("td", { attrs: { rowspan: "7" } }, [
                  _c("b", [_vm._v("Senin, 22/10/2023")]),
                ]),
                _c("td", {}, [_vm._v("dr. Edy Susanto")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("33")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 990.000")]),
                _c("td", { staticClass: "text-end" }, [
                  _vm._v("Rp. 2.700.000"),
                ]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 135.000")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [
                  _vm._v("Rp. 3.825.000"),
                ]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 187.000")]),
                _c("td", { staticClass: "text-end" }, [
                  _vm._v("Rp. 3.638.000"),
                ]),
              ]),
              _c("tr", [
                _c("td", {}, [_vm._v("dr. Rahma Anindita")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("25")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 500.000")]),
                _c("td", { staticClass: "text-end" }, [
                  _vm._v("Rp. 1.520.000"),
                ]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [
                  _vm._v("Rp. 2.020.000"),
                ]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [
                  _vm._v("Rp. 2.020.000"),
                ]),
              ]),
              _c("tr", [
                _c("td", {}, [_vm._v("dr. Mohamad Ananto")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("1")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 20.000")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 80.000")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 100.000")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 100.000")]),
              ]),
              _c("tr", [
                _c("td", {}, [_vm._v("dr. Amelya Amardani")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
              ]),
              _c("tr", [
                _c("td", {}, [_vm._v("Laboratotium")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 20.000")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 20.000")]),
              ]),
              _c("tr", [
                _c("td", {}, [_vm._v("Bidan")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
                _c("td", { staticClass: "text-end" }, [_vm._v("Rp. 0")]),
              ]),
              _c("tr", [
                _c("td", { attrs: { colspan: "6" } }, [
                  _c("b", [_vm._v("TOTAL")]),
                ]),
                _c("td", { staticClass: "text-end" }, [
                  _c("b", [_vm._v("Rp. 5.965.000")]),
                ]),
                _c("td", { staticClass: "text-end" }, [
                  _c("b", [_vm._v("Rp. 187.000")]),
                ]),
                _c("td", { staticClass: "text-end" }, [
                  _c("b", [_vm._v("Rp. 5.778.000")]),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }