var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "data-pasien px-3" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6 border py-1" }, [
          _c("b", [_vm._v("Nama : ")]),
          _vm._v(" " + _vm._s(_vm.fixData.userName)),
        ]),
        _c("div", { staticClass: "col-6 border py-1" }, [
          _c("b", [_vm._v("No.Telp : ")]),
          _vm._v(" " + _vm._s(_vm.fixData.userPhone)),
        ]),
        _c("div", { staticClass: "col-6 border py-1" }, [
          _c("b", [_vm._v("Tgl. Lahir : ")]),
          _vm._v(" " + _vm._s(_vm.fixData.userBirth)),
        ]),
        _c("div", { staticClass: "col-6 border py-1" }, [
          _c("b", [_vm._v("NIK : ")]),
          _vm._v(" " + _vm._s(_vm.fixData.userNIK)),
        ]),
        _c("div", { staticClass: "col-6 border py-1" }, [
          _c("b", [_vm._v("Jenis Kelamin : ")]),
          _vm._v(" " + _vm._s(_vm.fixData.userGender) + " "),
        ]),
        _c("div", { staticClass: "col-6 border py-1" }, [
          _c("b", [_vm._v("Agama : ")]),
          _vm._v(" " + _vm._s(_vm.fixData.userReligion)),
        ]),
        _c("div", { staticClass: "col-6 border py-1" }, [
          _c("b", [_vm._v("Pekerjaan : ")]),
          _vm._v(" " + _vm._s(_vm.fixData.userJob)),
        ]),
        _c("div", { staticClass: "col-6 border py-1" }, [
          _c("b", [_vm._v("Pendidikan : ")]),
          _vm._v(" " + _vm._s(_vm.fixData.userEducation) + " "),
        ]),
        _c("div", { staticClass: "col-12 border py-1" }, [
          _c("b", [_vm._v("Alamat : ")]),
          _vm._v(" " + _vm._s(_vm.fixData.userAddress[0].location) + " "),
        ]),
      ]),
    ]),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "data-soap" },
      _vm._l(_vm.fixDataRM.woReceiptTemp, function (data) {
        return _c("div", { staticClass: "border p-2" }, [
          _c("p", [_c("b", [_vm._v("Proses " + _vm._s(data.woSoap))])]),
          _c("div", {
            domProps: {
              innerHTML: _vm._s(data.woTextArea.replace(/\/n/g, "<br>")),
            },
          }),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "header d-flex align-items-center justify-content-center",
      },
      [_c("b", [_vm._v("Data Pemeriksaan (SOAP)")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }