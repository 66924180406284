<template>
  <div class="col-12">
    <transition name="fade" mode="out-in">
      <div v-if="isDataLoaded === ''" key="empty">
        <loadingAuto style="min-height: 350px"></loadingAuto>
      </div>

      <div v-else-if="isDataLoaded" key="truthy">
        <div class="row">
          <div class="col-lg-12 mb-3 mb-lg-5">
            <!-- Card -->
            <div class="card h-100">
              <!-- Header -->
              <div class="card-header card-header-content-sm-between py-2 bg-custom-1">
                <h4 class="card-header-title mb-2 mb-sm-0 text-white">
                  Grafik Kasbank Bulanan
                </h4>

                <!-- Nav -->

                <div class="col-4 d-flex align-items-center">
                  <b-form-input
                    type="month"
                    class="py-1"
                    v-model="editableDate"
                    @input="refreshData"
                  ></b-form-input>
                </div>
                <!-- End Nav -->
              </div>
              <!-- End Header -->

              <!-- Body -->
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col-sm mb-2 mb-sm-0">
                    <div class="d-flex align-items-center">
                      <!-- INI BUAT KENAIKAN TOTAL -->

                      <!-- <span class="h1 mb-0">35%</span>
                    <span class="text-success ms-2">
                      <i class="bi-graph-up"></i> 25.3%
                    </span> -->
                    </div>
                  </div>
                  <!-- End Col -->

                  <div class="col-sm-auto align-self-sm-end">
                    <div class="row fs-6 text-body">
                      <div class="col-auto" v-for="data in chartData.datasets">
                        <span
                          class="legend-indicator"
                          :style="{ backgroundColor: data.backgroundColor }"
                        ></span>
                        {{ data.label }}
                      </div>
                      <!-- End Col -->
                    </div>
                    <!-- End Row -->
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Row -->

                <!-- Bar Chart -->
                <div class="chartjs-custom">
                  <chart-component :chartData="chartData" />
                </div>
                <!-- End Bar Chart -->
              </div>
              <!-- End Body -->
            </div>
            <!-- End Card -->
          </div>
          <!-- End Col -->
        </div>

        <div class="row">
          <div class="col-lg-12 mb-3 mb-lg-5">
            <!-- Card -->
            <div class="card h-100">
              <!-- Header -->
              <div class="card-header card-header-content-sm-between py-2 bg-custom-1">
                <h4 class="card-header-title mb-2 mb-sm-0 text-white">
                  Laporan Kasbank Bulanan
                </h4>

                <!-- Nav -->
                <div class="col-4 d-flex align-items-center">
                  <b-form-input
                    type="month"
                    class="py-1"
                    v-model="editableDate"
                    disabled
                  ></b-form-input>
                </div>
                <!-- End Nav -->
              </div>
              <!-- End Header -->

              <!-- Body -->
              <div class="card-body p-0">
                <!-- End Row -->

                <!-- Bar Chart -->
                <div class="chartjs-custom">
                  <table class="table table-bordered">
                    <thead class="bg-light">
                      <tr>
                        <th scope="col"></th>
                        <th
                          v-for="header in data"
                          scope="col"
                          class="text-center"
                        >
                          <b>{{ header.coaName }}</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Awal Periode</th>
                        <td v-for="awal in data" class="text-center">
                          {{ formatNumber(awal.FaPriceBegining) }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Akhir Periode</th>
                        <td
                          v-for="akhir in data"
                          class="text-center"
                          v-if="!isNaN(akhir.FaPriceEnd)"
                        >
                          {{ formatNumber(akhir.FaPriceEnd) }}
                        </td>
                        <td class="text-center" v-else>0,00</td>
                      </tr>
                      <tr>
                        <th scope="row">% Kenaikan/Penurunan</th>
                        <td v-for="awal in data" class="text-center">
                          {{ awal.presentase }} %
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- End Bar Chart -->
              </div>
              <!-- End Body -->
            </div>
            <!-- End Card -->
          </div>
          <!-- End Col -->
        </div>
      </div>

      <div v-else key="other">
        <connection-timeout></connection-timeout>
      </div>
    </transition>
  </div>
</template>

<script>
import chartComponent from "../../components/chartComponent.vue";
import loadingAuto from "../../components/loadingAuto.vue";

import connectionTimeout from "../../components/connectionTimeout.vue";
import { getQ } from "../../components/services/components";
export default {
  components: {
    chartComponent,
    loadingAuto,
    connectionTimeout,
  },
  data() {
    return {
      isDataLoaded: "",
      editableDate: "",
      chartData: null,
      data: "",
      saldoAwal: "0",
      transIn: "0",
      transOut: "0",
      saldoAkhir: "0",
      lastSaldoAwal: "0",
      lastTransIn: "0",
      lastTransOut: "0",
      lastSaldoAkhir: "0",
    };
  },
  methods: {
    formatNumber(value) {
      const floatValue = parseFloat(value);
      if (isNaN(floatValue)) {
        return value; // Return the original string value if it's not a valid number
      }
      const parts = floatValue.toFixed(2).toString().split(".");
      const wholePart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      if (parts.length > 1) {
        return `${wholePart},${parts[1]}`;
      } else {
        return wholePart;
      }
    },
    getChartData(data) {
      let label;
      let saldoAwal;
      let saldoAkhir;
      let transIn;
      let transOut;
      console.log("cok", data);
      if (data) {
        label = data.map((x) => x.coaName);
        saldoAwal = data.map((x) => parseFloat(x.FaPriceBegining));
        saldoAkhir = data.map((x) => parseFloat(x.FaPriceEnd));
        transIn = data.map((x) => parseFloat(x.FaPriceIn));
        transOut = data.map((x) => parseFloat(x.FaPriceOut));
      }
      this.chartData = {
        class: "canvasHeight300",
        labels: label,
        datasets: [
          {
            label: "Saldo Awal Periode",
            data: saldoAwal,
            backgroundColor: "#377dff",
            hoverBackgroundColor: "#377dff",
            borderColor: "#377dff",
            maxBarThickness: "50",
          },
          {
            label: "Transaksi Masuk",
            data: transIn,
            backgroundColor: "#00AB8E",
            borderColor: "#00AB8E",
            maxBarThickness: "50",
          },
          {
            label: "Transaksi Keluar",
            data: transOut,
            backgroundColor: "#ED4C78",
            borderColor: "#ED4C78",
            maxBarThickness: "50",
          },
          {
            label: "Saldo Akhir Periode",
            data: saldoAkhir,
            backgroundColor: "#FE8638",
            borderColor: "#FE8638",
            maxBarThickness: "50",
          },
        ],
      };
      return true
    },
    async refreshData() {
      const [year, month] = this.editableDate.split("-");
      const currentData = await getQ(year, month);
      if (currentData) {
        this.data = currentData;
        this.notEmpty = true;
        this.getChartData(this.data);
      } else {
        (this.notEmpty = false), (this.data = { saldoAwal: [], saldoAkhir: [] });
      }
    },
  },
  async mounted() {
    const timeout = 30000; // 10 seconds
    try {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      let month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      this.editableDate = year + "-" + month;
      this.data = await getQ(year, month)

      const timeoutPromise = new Promise((resolve) => setTimeout(resolve, timeout));
      // Wait for either finalPromise or timeoutPromise to resolve
      const result = await Promise.race([this.data, timeoutPromise]);
      this.getChartData(this.data);
      this.isDataLoaded = result !== undefined;
    } catch (error) {
      this.isDataLoaded = false;
    }
  },
};
</script>
