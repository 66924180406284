var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c("label", { attrs: { for: "vendorId" } }, [_vm._v("Vendor ID: 0x")]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.vendor,
            expression: "vendor",
          },
        ],
        attrs: { type: "text", name: "vendorId", placeholder: "vendorId" },
        domProps: { value: _vm.vendor },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.vendor = $event.target.value
          },
        },
      }),
      _c("p", [
        _c("a", { attrs: { href: "#" }, on: { click: _vm.getDeviceInfo } }, [
          _vm._v("Click me to get USB device info"),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }