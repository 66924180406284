var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report", staticStyle: { "font-size": "10px" } },
    [
      _c(
        "table",
        { staticClass: "table table-bordered z-1 position-relative" },
        [
          _vm._m(0),
          _vm.data.length > 0
            ? _c(
                "tbody",
                [
                  _vm._l(_vm.data, function (entry) {
                    return _c("tr", [
                      _c("td", [_vm._v(_vm._s(entry.transNo))]),
                      _c("td", [_vm._v(_vm._s(entry.coaName))]),
                      _c("td", [_vm._v(_vm._s(entry.coaCode))]),
                      _c("td", [_vm._v(_vm._s(entry.transDescription))]),
                      !isNaN(parseFloat(entry.transIn))
                        ? _c("td", [_vm._v("Rp. " + _vm._s(entry.transIn))])
                        : _vm._e(),
                      !isNaN(parseFloat(entry.transOut))
                        ? _c("td", [_vm._v("Rp. " + _vm._s(entry.transOut))])
                        : _vm._e(),
                    ])
                  }),
                  _c("tr", [
                    _vm._m(1),
                    _vm._m(2),
                    _c("td", [
                      _c("b", [_vm._v("Rp. " + _vm._s(_vm.updateSaldoIn))]),
                    ]),
                    _c("td", [
                      _c("b", [_vm._v("Rp. " + _vm._s(_vm.updateSaldoOut))]),
                    ]),
                  ]),
                  _c("tr", [
                    _vm._m(3),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm.updateSaldoAwal
                        ? _c("b", [
                            _vm._v("Rp. " + _vm._s(_vm.updateSaldoAwal)),
                          ])
                        : _c("b", [_vm._v("0.00")]),
                    ]),
                  ]),
                  _c("tr", [
                    _vm._m(4),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm.updateSaldoAkhir
                        ? _c("b", [
                            _vm._v("Rp. " + _vm._s(_vm.updateSaldoAkhir)),
                          ])
                        : _c("b", [_vm._v("0.00")]),
                    ]),
                  ]),
                  _vm._m(5),
                ],
                2
              )
            : _c("tbody", [_vm._m(6)]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "sticky-element" }, [
      _c("tr", [
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "110" } },
          [_c("b", [_vm._v("No. Bukti")])]
        ),
        _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
          _c("b", [_vm._v("Nama Perkiraan")]),
        ]),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "100" } },
          [_c("b", [_vm._v("Perkiraan")])]
        ),
        _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
          _c("b", [_vm._v("Deskripsi")]),
        ]),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "130" } },
          [_c("b", [_vm._v("Penerimaan")])]
        ),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "130" } },
          [_c("b", [_vm._v("Pengeluaran")])]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { attrs: { rowspan: "5", colspan: "3" } }, [
      _c("div", { staticClass: "w-100 row d-flex align-items-center" }, [
        _c(
          "div",
          { staticClass: "col-4 d-flex justify-content-between pe-0 my-1" },
          [_c("span", [_vm._v("Uang Tunai")]), _c("span", [_vm._v(": Rp.")])]
        ),
        _c(
          "div",
          { staticClass: "col-8 d-flex justify-content-between ps-0 pt-2" },
          [_c("p", { staticClass: "m-0 border-bottom border-secondary w-100" })]
        ),
        _c(
          "div",
          { staticClass: "col-4 d-flex justify-content-between pe-0 my-1" },
          [_c("span", [_vm._v("Bon Sementara")]), _c("span", [_vm._v(": Rp.")])]
        ),
        _c(
          "div",
          { staticClass: "col-8 d-flex justify-content-between ps-0 pt-2" },
          [_c("p", { staticClass: "m-0 border-bottom border-secondary w-100" })]
        ),
        _c(
          "div",
          { staticClass: "col-4 d-flex justify-content-between pe-0 my-1" },
          [_c("span", [_vm._v("Saldo Kasir")]), _c("span", [_vm._v(": Rp.")])]
        ),
        _c(
          "div",
          { staticClass: "col-8 d-flex justify-content-between ps-0 pt-2" },
          [_c("p", { staticClass: "m-0 border-bottom border-secondary w-100" })]
        ),
        _c(
          "div",
          { staticClass: "col-4 d-flex justify-content-between pe-0 my-1" },
          [_c("span", [_vm._v("Sisa Keuangan")]), _c("span", [_vm._v(": Rp.")])]
        ),
        _c(
          "div",
          { staticClass: "col-8 d-flex justify-content-between ps-0 pt-2" },
          [_c("p", { staticClass: "m-0 border-bottom border-secondary w-100" })]
        ),
        _c(
          "div",
          { staticClass: "col-4 d-flex justify-content-between pe-0 my-1" },
          [_c("span", [_vm._v("Beda Kas")]), _c("span", [_vm._v(": Rp.")])]
        ),
        _c(
          "div",
          { staticClass: "col-8 d-flex justify-content-between ps-0 pt-2" },
          [_c("p", { staticClass: "m-0 border-bottom border-secondary w-100" })]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "text-end" }, [_c("b", [_vm._v("Total :")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "text-end" }, [
      _c("b", [_vm._v("Saldo Awal :")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "text-end" }, [
      _c("b", [_vm._v("Saldo Akhir :")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "p-0", attrs: { colspan: "3", rowspan: "2" } }, [
        _c("table", { staticClass: "table table-bordered" }, [
          _c("tr", [
            _c(
              "th",
              { staticClass: "text-center", staticStyle: { width: "30%" } },
              [_vm._v("Kasir")]
            ),
            _c(
              "th",
              { staticClass: "text-center", staticStyle: { width: "30%" } },
              [_vm._v("Accounting")]
            ),
            _c(
              "th",
              { staticClass: "text-center", staticStyle: { width: "30%" } },
              [_vm._v("Pimpinan")]
            ),
          ]),
          _c("tr", [
            _c("td", [_c("br"), _c("br"), _c("br"), _c("br")]),
            _c("td", [_c("br"), _c("br"), _c("br"), _c("br")]),
            _c("td", [_c("br"), _c("br"), _c("br"), _c("br")]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("Data Tidak Ditemukan")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }