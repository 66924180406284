var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    {
      staticClass:
        "js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered bg-white m-0",
    },
    [
      _c("div", { staticClass: "navbar-vertical-container" }, [
        _c(
          "div",
          { staticClass: "navbar-vertical-footer-offset d-flex flex-wrap" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: "/dashboard",
                  "data-toggle": "collapse",
                  "data-target": "#dashboard_dr",
                },
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "navbar-brand",
                    attrs: { href: "#", "aria-label": "Front" },
                  },
                  [
                    _c("img", {
                      staticClass: "navbar-brand-logo",
                      staticStyle: { "max-width": "1rem" },
                      attrs: {
                        src: _vm.imgLogo,
                        alt: "Logo",
                        "data-hs-theme-appearance": "default",
                      },
                    }),
                    _c("img", {
                      staticClass: "navbar-brand-logo",
                      staticStyle: { "max-width": "1rem" },
                      attrs: {
                        src: _vm.imgLogo,
                        alt: "Logo",
                        "data-hs-theme-appearance": "dark",
                      },
                    }),
                    _c("img", {
                      staticClass: "navbar-brand-logo-mini",
                      staticStyle: { "max-width": "1rem" },
                      attrs: {
                        src: _vm.imgLogoMini,
                        alt: "Logo",
                        "data-hs-theme-appearance": "default",
                      },
                    }),
                    _c("img", {
                      staticClass: "navbar-brand-logo-mini",
                      staticStyle: { "max-width": "1rem" },
                      attrs: {
                        src: _vm.imgLogoMini,
                        alt: "Logo",
                        "data-hs-theme-appearance": "dark",
                      },
                    }),
                  ]
                ),
              ]
            ),
            _vm._m(0),
            _c("div", { staticClass: "navbar-vertical-content w-100" }, [
              _c(
                "div",
                {
                  staticClass:
                    "nav nav-pills nav-vertical card-navbar-nav pt-0",
                  attrs: { id: "navbarVerticalMenu" },
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [
                      _vm.isDataLoaded === null
                        ? _c("div", { key: "empty" }, [_c("loadingSidebar")], 1)
                        : _vm.isDataLoaded === true
                        ? _c(
                            "div",
                            { key: "truthy" },
                            [
                              _c(
                                "div",
                                { staticClass: "nav-item d-none" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: "/dashboard",
                                        "data-toggle": "collapse",
                                        "data-target": "#usaha",
                                      },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "nav-link",
                                          class:
                                            _vm.$route.path === "/dashboard"
                                              ? "active text-primary"
                                              : "",
                                          attrs: {
                                            href: "#",
                                            "data-placement": "left",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "bi-house-door nav-icon",
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "nav-link-title" },
                                            [_vm._v("Dashboard")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "nav-item d-none" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link dropdown-toggle",
                                    attrs: {
                                      href: "#masterDropdown",
                                      role: "button",
                                      "data-bs-toggle": "collapse",
                                      "data-bs-target": "#masterDropdown",
                                      "aria-expanded": "false",
                                      "aria-controls": "masterDropdown",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi-stack nav-icon",
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "nav-link-title" },
                                      [_vm._v("Master")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "nav-collapse collapse",
                                    attrs: {
                                      id: "masterDropdown",
                                      "data-bs-parent": "#masterDropdown",
                                    },
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/master/usaha" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path ===
                                              "/master/usaha"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("1. Setup Perusahaan")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/master/coa" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path === "/master/coa"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("2. COA")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: { to: "/master/data-customer" },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path ===
                                              "/master/data-customer"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("3. Pasien")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/master/supplier" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path ===
                                              "/master/supplier"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("4. Supplier")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/master/user" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path === "/master/user"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("5. Manajemen Staff")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/master/item" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path === "/master/item"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("6. Master Item")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/master/formula" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path ===
                                              "/master/formula"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("7. Paket Layanan")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/master/mapping-location",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path ===
                                              "/master/mapping-location"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("8. Mapping Location")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/master/period" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path ===
                                              "/master/period"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("9. Periode")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/master/routing" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path ===
                                              "/master/routing"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("10. Verifikasi ")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/master/setup" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path ===
                                              "/master/setup"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("11. Setup")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/master/credit-terms" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path ===
                                              "/master/credit-terms"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("12. Credit Terms")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/master/cost-center" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path ===
                                              "/master/cost-center"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("13. Cost Center")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "nav-item d-none" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link dropdown-toggle",
                                    class: _vm.checkTabFinance()
                                      ? "text-primary"
                                      : "",
                                    attrs: {
                                      href: "#financeDropdown",
                                      role: "button",
                                      "data-bs-toggle": "collapse",
                                      "data-bs-target": "#financeDropdown",
                                      "aria-expanded": "false",
                                      "aria-controls": "financeDropdown",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi-cash-stack nav-icon",
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "nav-link-title" },
                                      [_vm._v("Finance")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "nav-collapse collapse",
                                    class: _vm.checkTabFinance() ? "show" : "",
                                    attrs: {
                                      id: "financeDropdown",
                                      "data-bs-parent": "#financeDropdown",
                                    },
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/transaksi/kasbank" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class: _vm.checkKasbank()
                                              ? "active text-primary"
                                              : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("Kasbank")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/transaksi/jurnal-entry",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class: _vm.checkJurnalEntry()
                                              ? "active text-primary"
                                              : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("Jurnal Entry")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/transaksi/po-invoice-cash",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class: _vm.checkPoInvoiceCash()
                                              ? "active text-primary"
                                              : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("Purchase Invoice Cash")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: { to: "/transaksi/po-invoice" },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class: _vm.checkPoInvoiceCredit()
                                              ? "active text-primary"
                                              : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("Purchase Invoice Kredit")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/transaksi/sales-invoice",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class: _vm.checkSalesInvoice()
                                              ? "active text-primary"
                                              : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("Sales Invoice")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "nav-item d-none" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link dropdown-toggle",
                                    class: _vm.checkTabPelayanan()
                                      ? "text-primary"
                                      : "",
                                    attrs: {
                                      href: "#produksiDropdown",
                                      role: "button",
                                      "data-bs-toggle": "collapse",
                                      "data-bs-target": "#produksiDropdown",
                                      "aria-expanded": "false",
                                      "aria-controls": "produksiDropdown",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi-boxes nav-icon",
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "nav-link-title" },
                                      [_vm._v("Pelayanan")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "nav-collapse collapse",
                                    class: _vm.checkTabPelayanan()
                                      ? "show"
                                      : "",
                                    attrs: {
                                      id: "produksiDropdown",
                                      "data-bs-parent": "#produksiDropdown",
                                    },
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: { to: "/master/data-customer" },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("List Pasien")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/transaksi/wip-workorder",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class: _vm.checkPelayanan()
                                              ? "active text-primary"
                                              : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("Pelayanan")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "nav-item d-none" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link dropdown-toggle",
                                    class: _vm.checkTabGudang()
                                      ? "text-primary"
                                      : "",
                                    attrs: {
                                      href: "#gudangDropdown",
                                      role: "button",
                                      "data-bs-toggle": "collapse",
                                      "data-bs-target": "#gudangDropdown",
                                      "aria-expanded": "false",
                                      "aria-controls": "gudangDropdown",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi-house nav-icon",
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "nav-link-title" },
                                      [_vm._v("Gudang")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "nav-collapse collapse",
                                    class: _vm.checkTabGudang() ? "show" : "",
                                    attrs: {
                                      id: "gudangDropdown",
                                      "data-bs-parent": "#gudangDropdown",
                                    },
                                  },
                                  [
                                    _c("router-link", {
                                      attrs: { to: "/transaksi/kasbank-v2" },
                                    }),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/transaksi/billOfMaterial",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class: _vm.checkBillOfMaterial()
                                              ? "active text-primary"
                                              : "",
                                            attrs: { href: "#" },
                                          },
                                          [
                                            _vm._v(
                                              "Bill of Material (Pelayanan)"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: { to: "/transaksi/po-receipt" },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class: _vm.checkPoReceipt()
                                              ? "active text-primary"
                                              : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("Purchase Order Receipt")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/transaksi/shipment" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class: _vm.checkShipment()
                                              ? "active text-primary"
                                              : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("Shipment")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "nav-item d-none" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link dropdown-toggle",
                                    class: _vm.checkTabPembelian()
                                      ? "text-primary"
                                      : "",
                                    attrs: {
                                      href: "#pembelianDropdown",
                                      role: "button",
                                      "data-bs-toggle": "collapse",
                                      "data-bs-target": "#pembelianDropdown",
                                      "aria-expanded": "false",
                                      "aria-controls": "pembelianDropdown",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi-cart3 nav-icon",
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "nav-link-title" },
                                      [_vm._v("Pembelian")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "nav-collapse collapse",
                                    class: _vm.checkTabPembelian()
                                      ? "show"
                                      : "",
                                    attrs: {
                                      id: "pembelianDropdown",
                                      "data-bs-parent": "#pembelianDropdown",
                                    },
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/transaksi/po" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class: _vm.checkPO()
                                              ? "active text-primary"
                                              : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("Purchase Order")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/transaksi/purchase-request",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class: _vm.checkPR()
                                              ? "active text-primary"
                                              : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("Purchase Request")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "nav-item d-none" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link dropdown-toggle",
                                    attrs: {
                                      href: "#browseDropdown",
                                      role: "button",
                                      "data-bs-toggle": "collapse",
                                      "data-bs-target": "#browseDropdown",
                                      "aria-expanded": "false",
                                      "aria-controls": "browseDropdown",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bi-distribute-vertical nav-icon",
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "nav-link-title" },
                                      [_vm._v("Browse")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "nav-collapse collapse",
                                    attrs: {
                                      id: "browseDropdown",
                                      "data-bs-parent": "#browseDropdown",
                                    },
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/browse/kasbank-v2" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("2. Transaksi Bulanan")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/browse/transaksi-harian",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("3. Kasbank Harian")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "nav-item d-none" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link dropdown-toggle",
                                    attrs: {
                                      href: "#reportDropdown",
                                      role: "button",
                                      "data-bs-toggle": "collapse",
                                      "data-bs-target": "#reportDropdown",
                                      "aria-expanded": "false",
                                      "aria-controls": "reportDropdown",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi-file-text nav-icon",
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "nav-link-title" },
                                      [_vm._v("Laporan")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "nav-collapse collapse",
                                    attrs: {
                                      id: "reportDropdown",
                                      "data-bs-parent": "#reportDropdown",
                                    },
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/report/kasbank-v2" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path ===
                                              "/report/kasbank-v2"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("1. Mutasi Bulanan")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/report/report-neraca-saldo",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path ===
                                              "/report/neraca-saldo"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("2. Neraca Saldo")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/report/transaksi-harian",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path ===
                                              "/report/transaksi-harian"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("3. Laporan Kas Harian")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/report/transaksi-bank-harian",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path ===
                                              "/report/transaksi-bank-harian"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("4. Laporan bank Harian")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/report/inventory" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path ===
                                              "/report/inventory"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("6. Inventory")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/report/inventory-stock-detail",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path ===
                                              "/report/inventory-stock-detail"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("7. Inventory Stock Detail")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/report/bincard" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            class:
                                              _vm.$route.path ===
                                              "/report/bincard"
                                                ? "active text-primary"
                                                : "",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("8. Bincard")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._l(_vm.organizedData, function (item, index) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    typeof item === "object" &&
                                    item.appGroupMenu === ""
                                      ? [
                                          _c(
                                            "div",
                                            { staticClass: "nav-item" },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: "/" + item.appLinkMenu,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "nav-link",
                                                      class:
                                                        _vm.$route.path.includes(
                                                          item.routePath
                                                        ) ||
                                                        _vm.$route.path ===
                                                          item.routePath
                                                          ? "active text-primary"
                                                          : "",
                                                      attrs: { href: "#" },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi-house-door nav-icon",
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "nav-link-title",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.appProcName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      : typeof item === "object"
                                      ? [
                                          _c(
                                            "div",
                                            { staticClass: "nav-item" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "nav-link dropdown-toggle",
                                                  attrs: {
                                                    href: "#",
                                                    role: "button",
                                                    "data-bs-toggle":
                                                      "collapse",
                                                    "data-bs-target":
                                                      "#reportDropdown_" +
                                                      index,
                                                    "aria-controls":
                                                      "reportDropdown_" + index,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toggleDropdown(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    class:
                                                      item.appIconMenu +
                                                      " nav-icon",
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "nav-link-title",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.appGroupMenu
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "nav-collapse collapse",
                                                  class:
                                                    _vm.openDropdownIndex ===
                                                    index.toString()
                                                      ? "show"
                                                      : "",
                                                  attrs: {
                                                    id:
                                                      "reportDropdown_" + index,
                                                  },
                                                },
                                                [
                                                  _vm._l(
                                                    item.items,
                                                    function (
                                                      menuItem,
                                                      menuItemIndex
                                                    ) {
                                                      return [
                                                        _c(
                                                          "router-link",
                                                          {
                                                            key: menuItemIndex,
                                                            attrs: {
                                                              to:
                                                                "/" +
                                                                menuItem.appLinkMenu,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "nav-link",
                                                                class:
                                                                  _vm.$route
                                                                    .path ===
                                                                  "/" +
                                                                    menuItem.appLinkMenu
                                                                    ? "active text-primary"
                                                                    : "",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    menuItem.appProcName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                        ]
                                      : [_c("div", [_vm._v(_vm._s(item))])],
                                  ],
                                  2
                                )
                              }),
                              _c("div", { staticClass: "nav-item d-none" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link dropdown-toggle",
                                    attrs: {
                                      href: "#laporanDropdown",
                                      role: "button",
                                      "data-bs-toggle": "collapse",
                                      "data-bs-target": "#laporanDropdown",
                                      "aria-expanded": "false",
                                      "aria-controls": "laporanDropdown",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bi-arrow-left-right nav-icon",
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "nav-link-title" },
                                      [_vm._v("Transaksi")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "nav-collapse collapse",
                                    attrs: {
                                      id: "laporanDropdown",
                                      "data-bs-parent": "#laporanDropdown",
                                    },
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/transaksi/bill-of-material",
                                        },
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: "/transaksi/sales-order",
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "nav-link",
                                                attrs: { href: "#" },
                                              },
                                              [_vm._v("1. Sales Order")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: "/transaksi/wip-workorder",
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "nav-link",
                                                attrs: { href: "#" },
                                              },
                                              [_vm._v("3. Work Order")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("4. Bill of Material (BoM)")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/transaksi/shipment" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("6. Shipment")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/transaksi/purchase-request",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("7. Purchase Request")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/transaksi/po" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("8. Purchase Order")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: { to: "/transaksi/po-receipt" },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("9. Purchase Order Receipt")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: { to: "/transaksi/po-invoice" },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("11. Purchase Invoice")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: { to: "/transaksi/po-payment" },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("12. Purchase Payment")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/transaksi/kasbank" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("13. Kasbank")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/transaksi/jurnal-entry",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("Jurnal Voucher")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: "/transaksi/sales-invoice",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link",
                                            attrs: { href: "#" },
                                          },
                                          [_vm._v("Sales Invoice")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          )
                        : _c("div", { key: "other" }),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._m(1),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler",
        attrs: { type: "button", disabled: "" },
      },
      [
        _c("i", {
          staticClass: "bi-arrow-bar-left navbar-toggler-short-align",
          attrs: {
            "data-bs-template":
              '<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "right",
            title: "Collapse",
          },
        }),
        _c("i", {
          staticClass: "bi-arrow-bar-right navbar-toggler-full-align",
          attrs: {
            "data-bs-template":
              '<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "right",
            title: "Expand",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "navbar-vertical-footer" }, [
      _c("ul", { staticClass: "navbar-vertical-footer-list" }, [
        _c("li", { staticClass: "navbar-vertical-footer-list-item" }, [
          _c("div", { staticClass: "dropdown dropup" }, [
            _c("button", {
              staticClass: "btn btn-ghost-secondary btn-icon rounded-circle",
              attrs: {
                type: "button",
                id: "selectThemeDropdown",
                "data-bs-toggle": "dropdown",
                "aria-expanded": "false",
                "data-bs-dropdown-animation": "",
              },
            }),
            _c(
              "div",
              {
                staticClass:
                  "dropdown-menu navbar-dropdown-menu navbar-dropdown-menu-borderless",
                attrs: { "aria-labelledby": "selectThemeDropdown" },
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: {
                      href: "#",
                      "data-icon": "bi-brightness-high",
                      "data-value": "default",
                    },
                  },
                  [
                    _c("i", { staticClass: "bi-brightness-high me-2" }),
                    _c(
                      "span",
                      {
                        staticClass: "text-truncate",
                        attrs: { title: "Light Mode" },
                      },
                      [_vm._v("Light Mode")]
                    ),
                  ]
                ),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: {
                      href: "#",
                      "data-icon": "bi-moon-stars",
                      "data-value": "auto",
                    },
                  },
                  [
                    _c("i", { staticClass: "bi-moon-stars me-2" }),
                    _c(
                      "span",
                      {
                        staticClass: "text-truncate",
                        attrs: { title: "Dark Mode" },
                      },
                      [_vm._v("Dark Mode")]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]),
        _c("li", { staticClass: "navbar-vertical-footer-list-item" }, [
          _c("div", { staticClass: "dropdown dropup" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-ghost-secondary btn-icon rounded-circle",
                attrs: {
                  type: "button",
                  id: "otherLinksDropdown",
                  "data-bs-toggle": "dropdown",
                  "aria-expanded": "false",
                  "data-bs-dropdown-animation": "",
                },
              },
              [_c("i", { staticClass: "bi-info-circle" })]
            ),
            _c(
              "div",
              {
                staticClass: "dropdown-menu navbar-dropdown-menu-borderless",
                attrs: { "aria-labelledby": "otherLinksDropdown" },
              },
              [
                _c("span", { staticClass: "dropdown-header" }, [
                  _vm._v("Help"),
                ]),
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [
                    _c("i", { staticClass: "bi-journals dropdown-item-icon" }),
                    _c(
                      "span",
                      {
                        staticClass: "text-truncate",
                        attrs: { title: "Resources & tutorials" },
                      },
                      [_vm._v("Resources & tutorials")]
                    ),
                  ]
                ),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "https://letsredi.id", target: "_blank" },
                  },
                  [
                    _c("i", { staticClass: "bi-gift dropdown-item-icon" }),
                    _c(
                      "span",
                      {
                        staticClass: "text-truncate",
                        attrs: { title: "What's new?" },
                      },
                      [_vm._v("What's new?")]
                    ),
                  ]
                ),
                _c("div", { staticClass: "dropdown-divider" }),
                _c("span", { staticClass: "dropdown-header" }, [
                  _vm._v("Contacts"),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: {
                      href: "https://api.whatsapp.com/send/?phone=6285935000057&text=Saya+tertarik+dengan+produk+REDI&type=phone_number&app_absent=0",
                      target: "_blank",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "bi-chat-left-dots dropdown-item-icon",
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "text-truncate",
                        attrs: { title: "Contact support" },
                      },
                      [_vm._v("Contact support")]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]),
        _c("li", { staticClass: "navbar-vertical-footer-list-item" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }