var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c(
        "div",
        {
          ref: "contentDiv",
          staticClass: "content container-fluid p-3 ps-0 pt-0",
        },
        [
          _c("ul", { staticClass: "breadcrumb m-1 mt-0" }, [
            _vm._m(0),
            _c("li", [_vm._v("Transaksi")]),
            _c("li", [_vm._v("Detail")]),
            _c("li", [_vm._v(_vm._s(_vm.poNumb))]),
          ]),
          _vm.isDataLoaded
            ? [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-header bg-light p-2" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "row col-9 d-flex align-items-center justify-content-end pe-0",
                        },
                        [
                          _c("b-form-group", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              "label-for": "coaKasbank",
                              "invalid-feedback": "Condition Harus Diisi!",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-3 d-flex justify-content-end py-2",
                          staticStyle: { "z-index": "2" },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "btn btn-outline-secondary btn-xs py-1 me-2",
                              attrs: { to: "/transaksi/sales-order" },
                            },
                            [
                              _c("i", { staticClass: "bi-chevron-left" }),
                              _vm._v("Kembali "),
                            ]
                          ),
                          _c(
                            "b-button-group",
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-xs",
                                  on: { click: _vm.printReport },
                                },
                                [
                                  _c("i", { staticClass: "bi-printer pe-2" }),
                                  _vm._v("Print "),
                                ]
                              ),
                              _c(
                                "b-dropdown",
                                {
                                  attrs: {
                                    id: "dropdown2",
                                    variant: "primary",
                                    size: "xs",
                                    right: "",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c("p", { staticClass: "p-2 pb-0 m-0" }, [
                                      _vm._v("Export"),
                                    ]),
                                  ]),
                                  _c("b-dropdown-divider"),
                                  _c(
                                    "b-dropdown-item",
                                    { on: { click: _vm.generatePDF } },
                                    [_vm._v("PDF")]
                                  ),
                                  _c("b-dropdown-item", [_vm._v("xls")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "card-body p-2 printData" }, [
                    _c("div", { staticClass: "container" }, [
                      _vm._m(1),
                      _c("div", { staticClass: "po-number" }),
                      _c("div", { staticClass: "po-date" }),
                      _c("div", { staticClass: "address-info row" }, [
                        _c("div", { staticClass: "col-6" }, [
                          _c("p", [
                            _c("b", [_vm._v("SO Number :")]),
                            _vm._v(" " + _vm._s(_vm.dataPOId[0].transId)),
                          ]),
                          _c("p", [
                            _c("b", [_vm._v("SO Date :")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatIndDate(_vm.dataPOId[0].transDate)
                                )
                            ),
                          ]),
                          _c("p", [
                            _c("b", [_vm._v("Sales :")]),
                            _vm._v(
                              " " + _vm._s(_vm.dataPOId[0].transKasir.userName)
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-6" }, [
                          _c("p", [
                            _c("b", [_vm._v("Customer :")]),
                            _vm._v(" " + _vm._s(_vm.dataPOId[0].transName)),
                          ]),
                          _c("p", [
                            _c("b", [_vm._v("Ship Plan :")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatIndDate(
                                    _vm.dataPOId[0].transShipmentPlanDate
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("p", [
                            _c("b", [_vm._v("Ship To :")]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.dataPOId[0].transShipTo[0].nameAddr)
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "order-details" }, [
                        _c("h2", [_vm._v("Item Details:")]),
                        _c(
                          "table",
                          {
                            staticStyle: {
                              "border-collapse": "collapse",
                              border: "none",
                            },
                          },
                          [
                            _vm._m(2),
                            _vm._l(_vm.dataPOId[0].transPesanan, function (dt) {
                              return _c("tr", [
                                _c(
                                  "td",
                                  { staticStyle: { "font-size": "12px" } },
                                  [_vm._v(_vm._s(dt.itemCode))]
                                ),
                                _c(
                                  "td",
                                  { staticStyle: { "font-size": "12px" } },
                                  [_vm._v(_vm._s(dt.itemName))]
                                ),
                                _c(
                                  "td",
                                  { staticStyle: { "font-size": "12px" } },
                                  [
                                    dt.itemBundling.length > 0
                                      ? [
                                          _vm._v(
                                            _vm._s(dt.itemBundling.length) +
                                              " Add-On"
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _c(
                                  "td",
                                  { staticStyle: { "font-size": "12px" } },
                                  [
                                    _vm._v(
                                      _vm._s(dt.itemQty) +
                                        " " +
                                        _vm._s(dt.itemUm)
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticStyle: { "font-size": "12px" } },
                                  [_vm._v(_vm._s(dt.itemMasterPrice[1]))]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-end",
                                    staticStyle: { "font-size": "12px" },
                                  },
                                  [
                                    _vm._v(
                                      " Rp. " +
                                        _vm._s(
                                          _vm.formatNumber(
                                            dt.itemMasterPrice[0]
                                              ? dt.itemMasterPrice[0]
                                              : 0
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-end",
                                    staticStyle: { "font-size": "12px" },
                                  },
                                  [
                                    _vm._v(
                                      " Rp. " +
                                        _vm._s(
                                          _vm.formatNumber(
                                            dt.itemMasterPrice[0] && dt.itemQty
                                              ? parseFloat(
                                                  dt.itemMasterPrice[0]
                                                ) * parseFloat(dt.itemQty)
                                              : 0
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            }),
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  staticStyle: { border: "none" },
                                  attrs: { colspan: "5", rowspan: "5" },
                                },
                                [
                                  _c("div", { staticClass: "divNotePrint" }, [
                                    _vm._m(3),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          margin: "10px 15px",
                                          "font-size": "15px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.dataPOId[0].PoNotes) +
                                            " "
                                        ),
                                        _c("br"),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "text-end py-0",
                                  staticStyle: {
                                    "font-size": "12px",
                                    height: "30px",
                                  },
                                },
                                [_vm._v(" SubTotal ")]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "text-end py-0",
                                  staticStyle: {
                                    "font-size": "12px",
                                    height: "30px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " Rp. " +
                                      _vm._s(
                                        _vm.formatNumber(
                                          _vm.dataPOId[0].transTotal
                                            ? _vm.dataPOId[0].transTotal
                                            : 0
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "text-end py-0",
                                  staticStyle: {
                                    "font-size": "12px",
                                    height: "30px",
                                  },
                                },
                                [_vm._v(" Tax ")]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "text-end py-0",
                                  staticStyle: {
                                    "font-size": "12px",
                                    height: "30px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " Rp. " +
                                      _vm._s(
                                        _vm.formatNumber(
                                          _vm.dataPOId[0].transPPN === "yes"
                                            ? _vm.dataPOId[0].transTotal * 0.11
                                            : 0
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "text-end py-0",
                                  staticStyle: {
                                    "font-size": "12px",
                                    height: "30px",
                                  },
                                },
                                [_vm._v(" DP ")]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "text-end py-0",
                                  staticStyle: {
                                    "font-size": "12px",
                                    height: "30px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " Rp. " +
                                      _vm._s(
                                        _vm.formatNumber(
                                          _vm.dataPOId[0].transDPValue
                                            ? _vm.dataPOId[0].transDPValue
                                            : 0
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "text-end py-0",
                                  staticStyle: {
                                    "font-size": "12px",
                                    height: "30px",
                                  },
                                },
                                [_vm._v(" Diskon ")]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "text-end py-0",
                                  staticStyle: {
                                    "font-size": "12px",
                                    height: "30px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " Rp." +
                                      _vm._s(
                                        _vm.formatNumber(
                                          _vm.discTotal(
                                            _vm.dataPOId[0].transDiscount
                                          )
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "text-end py-0",
                                  staticStyle: {
                                    "font-size": "12px",
                                    height: "30px",
                                  },
                                },
                                [_vm._v(" Grand Total ")]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "text-end py-0",
                                  staticStyle: {
                                    "font-size": "12px",
                                    height: "30px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " Rp. " +
                                      _vm._s(
                                        _vm.formatNumber(
                                          _vm.dataPOId[0].transTotal -
                                            _vm.dataPOId[0].transDPValue -
                                            _vm.discTotal(
                                              _vm.dataPOId[0].transDiscount
                                            ) +
                                            (_vm.dataPOId[0].transPPN === "yes"
                                              ? _vm.dataPOId[0].transTotal *
                                                0.11
                                              : 0)
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            : _c("div", [_vm._v("Loading...")]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "#" } }, [
        _c("i", { staticClass: "bi-house-door" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("h1", [_vm._v("SALES ORDER DETAIL")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticClass: "text-center" }, [_vm._v("Code")]),
      _c("th", { staticClass: "text-center" }, [_vm._v("Name")]),
      _c("th", { staticClass: "text-center" }, [_vm._v("Desc")]),
      _c("th", { staticClass: "text-center" }, [_vm._v("Qty.")]),
      _c("th", { staticClass: "text-center", attrs: { width: "75" } }, [
        _vm._v("Price Code"),
      ]),
      _c("th", { staticClass: "text-center" }, [_vm._v("Unit Price")]),
      _c("th", { staticClass: "text-center" }, [_vm._v("Total")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticStyle: { margin: "10px" } }, [
      _c("b", [_vm._v("Catatan :")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }