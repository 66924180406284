var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c(
        "div",
        { ref: "contentDiv", staticClass: "content container-fluid p-3 ps-0" },
        [
          _vm.isDataLoaded
            ? [
                _c("tableTransaksi", {
                  attrs: {
                    data: _vm.filteredTableData,
                    fields: _vm.tableFields,
                    settings: _vm.hotSettings,
                    routingLink: _vm.routing,
                    tableWidth: _vm.contentDivWidth,
                    filterText: _vm.filterText,
                  },
                }),
              ]
            : _c("div", [_vm._v("Loading...")]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }