var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form", [
    _c("div", { staticClass: "row my-1" }, [
      _c("div", { staticClass: "col-8 d-flex align-items-center" }, [
        _c("ul", { staticClass: "breadcrumb m-0" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/dashboard" } }, [
                _c("a", { attrs: { href: "#" } }, [
                  _c("i", { staticClass: "bi-house-door" }),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { name: _vm.posts.transRouting.replace("-detail", "") },
                  },
                },
                [
                  _c("a", { attrs: { href: "#" } }, [
                    _vm._v(_vm._s(_vm.posts.transTitle)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("li", [_vm._v("Detail")]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "col-4 d-flex justify-content-end align-items-center" },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: { name: _vm.posts.transRouting.replace("-detail", "") },
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary btn-xs py-1 me-2",
                  attrs: { type: "button" },
                },
                [_c("i", { staticClass: "bi-chevron-left" }), _vm._v("Kembali")]
              ),
            ]
          ),
          _c(
            "div",
            [
              _c(
                "b-dropdown",
                {
                  attrs: {
                    id: "dropdown-1",
                    right: "",
                    text: "Export",
                    variant: "primary",
                    size: "xs",
                  },
                },
                [
                  _c("b-dropdown-item", { on: { click: _vm.generatePDF } }, [
                    _vm._v("PDF"),
                  ]),
                  _c("b-dropdown-item", [_vm._v("xls")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "row scale-9", staticStyle: { width: "114%" } },
      _vm._l(_vm.dummyItems, function (field, index) {
        return field.componentName === "fieldHeader"
          ? _c("div", { key: index, class: [field.componentClass] }, [
              _c("div", { staticClass: "card h-100" }, [
                _c(
                  "div",
                  { staticClass: "row px-3" },
                  _vm._l(field.componentMicro, function (micro) {
                    return _c(
                      "div",
                      { class: ["", micro.microClass] },
                      [
                        micro.key === "type"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass:
                                      "form-control form-control-sm py-0",
                                    attrs: {
                                      value:
                                        _vm.dataDetail[0].Trans[0].transType ===
                                        "kredit"
                                          ? "Pemasukan"
                                          : "pengeluaran",
                                      type: micro.microType,
                                      placeholder: micro.microText,
                                      disabled: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : micro.key === "kasbankCode"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass:
                                      "form-control form-control-sm py-0",
                                    attrs: {
                                      value:
                                        _vm.dataDetail[0].coaCode +
                                        " (" +
                                        _vm.dataDetail[0].coaName +
                                        ")",
                                      type: micro.microType,
                                      placeholder: micro.microText,
                                      disabled: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : micro.key === "transAmount"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass:
                                      "form-control form-control-sm py-0",
                                    attrs: {
                                      value: _vm.getBeginning(),
                                      type: micro.microType,
                                      placeholder: micro.microText,
                                      disabled: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass:
                                      "form-control form-control-sm py-0",
                                    attrs: {
                                      type: micro.microType,
                                      placeholder: micro.microText,
                                      disabled: "",
                                    },
                                    model: {
                                      value: micro.value,
                                      callback: function ($$v) {
                                        _vm.$set(micro, "value", $$v)
                                      },
                                      expression: "micro.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ])
          : _vm._e()
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "row mb-3" },
      _vm._l(_vm.component, function (field) {
        return field.componentName === "fieldTable"
          ? _c("div", { class: [field.componentClass] }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "hot-container col-md-12",
                    staticStyle: { "overflow-x": "auto" },
                  },
                  [
                    _c("hot-table", {
                      attrs: {
                        settings: _vm.hotSettings,
                        data: _vm.refreshComponent,
                        rowHeaders: true,
                        colHeaders: true,
                        colWidths: _vm.widthTable,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e()
      }),
      0
    ),
    _c("div", { staticClass: "row mb-3" }, [
      _c(
        "div",
        { staticClass: "col-md-6 scale-9" },
        _vm._l(_vm.component, function (field) {
          return field.componentName === "fieldNote"
            ? _c("div", { class: ["h-100", field.componentClass] }, [
                _c(
                  "div",
                  { staticClass: "card h-100" },
                  [
                    _c("div", { staticClass: "card-header bg-light py-1" }, [
                      _vm._v(_vm._s(field.componentTitle)),
                    ]),
                    _vm._l(field.componentMicro, function (micro) {
                      return [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.dataDetail[0].note,
                              expression: "dataDetail[0].note",
                            },
                          ],
                          staticClass:
                            "form-control form-control-sm py-1 h-100",
                          staticStyle: { resize: "none" },
                          attrs: { placeholder: micro.microText, disabled: "" },
                          domProps: { value: _vm.dataDetail[0].note },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.dataDetail[0],
                                "note",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e()
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass: "col-md-6 row p-0 scale-9",
          staticStyle: { "transform-origin": "right top" },
        },
        _vm._l(_vm.component, function (field) {
          return field.componentName === "fieldTotal"
            ? _c(
                "div",
                { class: [field.componentClass] },
                _vm._l(field.componentMicro, function (micro) {
                  return _c(
                    "div",
                    { staticClass: "row d-flex justify-content-end" },
                    [
                      micro.key === "total"
                        ? [
                            _c(
                              "div",
                              {
                                class: [
                                  "border border-light bg-light d-flex align-items-center",
                                  micro.microClass,
                                ],
                              },
                              [_vm._v(_vm._s(micro.microName) + " :")]
                            ),
                            _c(
                              "div",
                              {
                                class: [
                                  "p-0 border border-light",
                                  micro.microClass,
                                ],
                              },
                              [
                                _c("input", {
                                  staticClass:
                                    "form-control form-control-sm py-1",
                                  staticStyle: { "text-align": "right" },
                                  attrs: {
                                    disabled: "",
                                    type: micro.microType,
                                  },
                                  domProps: {
                                    value: _vm.formatNumber(
                                      _vm.saldoAwal.FaPriceBegining
                                    ),
                                  },
                                  on: { input: _vm.refreshMethods },
                                }),
                              ]
                            ),
                          ]
                        : micro.key === "transAmount"
                        ? [
                            _c(
                              "div",
                              {
                                class: [
                                  "border border-light bg-light d-flex align-items-center",
                                  micro.microClass,
                                ],
                              },
                              [_vm._v(_vm._s(micro.microName) + " :")]
                            ),
                            _c(
                              "div",
                              {
                                class: [
                                  "p-0 border border-light",
                                  micro.microClass,
                                ],
                              },
                              [
                                _c("input", {
                                  staticClass:
                                    "form-control form-control-sm py-1",
                                  staticStyle: { "text-align": "right" },
                                  attrs: {
                                    disabled: "",
                                    type: micro.microType,
                                  },
                                  domProps: {
                                    value: _vm.getTotal(_vm.dataDetail[0].sum),
                                  },
                                  on: { input: _vm.refreshMethods },
                                }),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                0
              )
            : _vm._e()
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }