var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c("div", { ref: "contentDiv", staticClass: "w-100" }),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.isDataLoaded === ""
          ? _c("div", { key: "empty" }, [_c("loading")], 1)
          : _vm.isDataLoaded
          ? _c("div", { key: "truthy" }, [
              _c("ul", { staticClass: "breadcrumb m-1 mt-0" }, [
                _c("li", [
                  _c("a", { attrs: { href: "#" } }, [
                    _c("i", { staticClass: "bi-house-door" }),
                  ]),
                ]),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "text-primary",
                        attrs: { to: "/transaksi/shipment" },
                      },
                      [_vm._v("Shipment ")]
                    ),
                  ],
                  1
                ),
                _c("li", [_vm._v("Detail Shipment (Surat Jalan)")]),
                _c("li", [_vm._v(_vm._s(_vm.transHysNo))]),
              ]),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header bg-light p-2" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "row col-9 d-flex align-items-center justify-content-end pe-0",
                      },
                      [
                        _c("b-form-group", {
                          staticStyle: { width: "200px" },
                          attrs: {
                            "label-for": "coaKasbank",
                            "invalid-feedback": "Condition Harus Diisi!",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-3 d-flex justify-content-end py-2",
                        staticStyle: { "z-index": "2" },
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "btn btn-outline-secondary btn-xs py-1 me-2",
                            attrs: { to: "/transaksi/shipment" },
                          },
                          [
                            _c("i", { staticClass: "bi-chevron-left" }),
                            _vm._v("Kembali "),
                          ]
                        ),
                        _c(
                          "b-button-group",
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-xs",
                                on: { click: _vm.printReport },
                              },
                              [
                                _c("i", { staticClass: "bi-printer pe-2" }),
                                _vm._v("Print "),
                              ]
                            ),
                            _c(
                              "b-dropdown",
                              {
                                attrs: {
                                  id: "dropdown2",
                                  variant: "primary",
                                  size: "xs",
                                  right: "",
                                },
                              },
                              [
                                _c("div", [
                                  _c("p", { staticClass: "p-2 pb-0 m-0" }, [
                                    _vm._v("Export"),
                                  ]),
                                ]),
                                _c("b-dropdown-divider"),
                                _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.generatePDF } },
                                  [_vm._v("PDF")]
                                ),
                                _c("b-dropdown-item", [_vm._v("xls")]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "card-body p-2 printData" }, [
                  _c("div", { staticClass: "container" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("img", {
                            staticClass: "img-fluid w-25",
                            attrs: {
                              src: "/assets/img/logoPrint/dragon1.png",
                              alt: "Image Description",
                              "data-hs-theme-appearance": "default",
                            },
                          }),
                          _c("div", { staticClass: "w-50 text-center" }, [
                            _c("h1", [_c("u", [_vm._v("SURAT JALAN")])]),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "row mb-4 d-flex justify-content-between",
                      },
                      [
                        _c("div", { staticClass: "col-6" }, [
                          _c("h1", { staticClass: "m-0" }, [
                            _vm._v("DRAGON MAS"),
                          ]),
                          _c("h5", { staticClass: "m-0" }, [
                            _vm._v("CV. Dragon Mas Texa Prima"),
                          ]),
                          _c("p", { staticClass: "m-0" }, [
                            _vm._v("(0352) 3576041"),
                          ]),
                          _c("p", { staticClass: "m-0" }, [
                            _vm._v("www.PabrikMesinLaundry.com"),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-6" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6 pe-0" }, [
                              _c("b", [_vm._v("No. Surat Jalan")]),
                            ]),
                            _c("div", { staticClass: "col-6 ps-0" }, [
                              _c("b", [_vm._v(": ")]),
                              _vm.data
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.data[0].transHysNo)),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6 pe-0" }, [
                              _c("b", [_vm._v("No. Resi")]),
                            ]),
                            _c("div", { staticClass: "col-6 ps-0" }, [
                              _c("b", [_vm._v(": ")]),
                              _vm.data
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.data[0].transShipExpedisi.noResi
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6 pe-0" }, [
                              _c("b", [_vm._v("Tanggal")]),
                            ]),
                            _c("div", { staticClass: "col-6 ps-0" }, [
                              _c("b", [_vm._v(": ")]),
                              _vm.data
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.data[0].transHysEffDate)),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6 pe-0" }, [
                              _c("b", [_vm._v("Kepada")]),
                            ]),
                            _c("div", { staticClass: "col-6 ps-0" }, [
                              _c("b", [_vm._v(": ")]),
                              _vm.data
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.data[0].transShipCustNo.userName
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c("p", [
                      _vm._v(" Bersama ini kendaraan "),
                      _vm.data
                        ? _c("b", [
                            _vm._v(
                              _vm._s(_vm.data[0].transShipExpedisi.tipeMobil)
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(", Dengan Nomor "),
                      _vm.data
                        ? _c("b", [
                            _vm._v(
                              _vm._s(_vm.data[0].transShipExpedisi.platNomor)
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("table", { staticClass: "table table-bordered" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { width: "10px" } }, [
                            _vm._v("NO."),
                          ]),
                          _c(
                            "th",
                            {
                              staticClass: "text-center",
                              attrs: { width: "150" },
                            },
                            [_vm._v("BANYAKNYA")]
                          ),
                          _c("th", { staticClass: "text-center" }, [
                            _vm._v("KETERANGAN"),
                          ]),
                        ]),
                      ]),
                      _vm.data
                        ? _c(
                            "tbody",
                            _vm._l(
                              _vm.data[0].transHysItem,
                              function (dt, index) {
                                return _c("tr", [
                                  _c(
                                    "td",
                                    [
                                      dt.itemHysUm === "set"
                                        ? [
                                            _c("b", [
                                              _c("i", [
                                                _vm._v(_vm._s(index + 1)),
                                              ]),
                                            ]),
                                          ]
                                        : [_vm._v(_vm._s(index + 1))],
                                    ],
                                    2
                                  ),
                                  _c(
                                    "td",
                                    [
                                      dt.itemHysUm === "set"
                                        ? [
                                            _c("b", [
                                              _c("i", [
                                                _vm._v(
                                                  _vm._s(dt.itemHysQtyRcp) +
                                                    " " +
                                                    _vm._s(dt.itemHysUm)
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        : [
                                            _vm._v(
                                              _vm._s(dt.itemHysQtyRcp) +
                                                " " +
                                                _vm._s(dt.itemHysUm)
                                            ),
                                          ],
                                    ],
                                    2
                                  ),
                                  _c(
                                    "td",
                                    [
                                      dt.itemHysUm === "set"
                                        ? [
                                            _c("b", [
                                              _c("i", [
                                                _vm._v(_vm._s(dt.itemHysName)),
                                              ]),
                                            ]),
                                          ]
                                        : [_vm._v(_vm._s(dt.itemHysName))],
                                    ],
                                    2
                                  ),
                                ])
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "footerPrint" }, [
                      _c("div", { staticClass: "ttd" }, [
                        _c("p", [_vm._v("Pengirim")]),
                      ]),
                      _c("div", { staticClass: "ttd" }, [
                        _c("p", [_vm._v("Kurir")]),
                      ]),
                      _c("div", { staticClass: "ttd" }, [
                        _c("p", [_vm._v("Penerima")]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _c("div", { key: "other" }, [_c("connection-timeout")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }