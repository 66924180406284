var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report", staticStyle: { "font-size": "10px" } },
    [
      _c(
        "table",
        {
          staticClass: "table table-bordered table-hover z-1 position-relative",
        },
        [
          _vm._m(0),
          _vm.realData.length > 0
            ? _c(
                "tbody",
                [
                  _vm._l(_vm.realData, function (entry, index) {
                    return _c("tr", { staticStyle: { cursor: "pointer" } }, [
                      _c("td", { staticClass: "align-top" }, [
                        _vm._v(_vm._s(index + 1)),
                      ]),
                      _c("td", { staticClass: "align-top" }, [_vm._v("Nama")]),
                      _c("td", { staticClass: "align-top" }, [
                        _vm._v(_vm._s(entry.woNo)),
                      ]),
                      entry.woStartDate
                        ? _c("td", { staticClass: "align-top text-center" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatIndDate(entry.woStartDate)) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      entry.woEndDate
                        ? _c("td", { staticClass: "align-top text-center" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatIndDate(entry.woEndDate)) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("td", { staticClass: "align-top" }, [
                        _vm._v(_vm._s(entry.woNoBatch)),
                      ]),
                      _c("td", { staticClass: "align-top" }, [
                        _vm._v("itemmnya"),
                      ]),
                      _c("td", { staticClass: "align-top" }, [
                        _vm._v("jumlah"),
                      ]),
                      _c("td", { staticClass: "align-top" }, [
                        _vm._v("bandung"),
                      ]),
                    ])
                  }),
                  _vm._m(1),
                  _vm._m(2),
                ],
                2
              )
            : _c("tbody", [_vm._m(3)]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "sticky-element" }, [
      _c("tr", [
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "50" } },
          [_c("b", [_vm._v("No")])]
        ),
        _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
          _c("b", [_vm._v("User")]),
        ]),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "100" } },
          [_c("b", [_vm._v("Nomor WO")])]
        ),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "85" } },
          [_c("b", [_vm._v("start date")])]
        ),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "85" } },
          [_c("b", [_vm._v("end date")])]
        ),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "130" } },
          [_c("b", [_vm._v("no. batch")])]
        ),
        _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
          _c("b", [_vm._v("nama item")]),
        ]),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "130" } },
          [_c("b", [_vm._v("qty")])]
        ),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "130" } },
          [_c("b", [_vm._v("lokasi")])]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "align-top", attrs: { rowspan: "2" } }, [
        _vm._v("5"),
      ]),
      _c("td", { staticClass: "align-top", attrs: { rowspan: "2" } }, [
        _vm._v("Nama"),
      ]),
      _c("td", { staticClass: "align-top" }, [_vm._v("no wo")]),
      _c("td", { staticClass: "align-top text-center" }, [
        _vm._v("14-05-2001"),
      ]),
      _c("td", { staticClass: "align-top text-center" }, [
        _vm._v("14-05-2001"),
      ]),
      _c("td", { staticClass: "align-top" }, [_vm._v("batch")]),
      _c("td", { staticClass: "align-top" }, [_vm._v("itemmnya")]),
      _c("td", { staticClass: "align-top" }, [_vm._v("jumlah")]),
      _c("td", { staticClass: "align-top" }, [_vm._v("bandung")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "align-top" }, [_vm._v("no wo")]),
      _c("td", { staticClass: "align-top text-center" }, [
        _vm._v("14-05-2001"),
      ]),
      _c("td", { staticClass: "align-top text-center" }, [
        _vm._v("14-05-2001"),
      ]),
      _c("td", { staticClass: "align-top" }, [_vm._v("batch")]),
      _c("td", { staticClass: "align-top" }, [_vm._v("itemmnya")]),
      _c("td", { staticClass: "align-top" }, [_vm._v("jumlah")]),
      _c("td", { staticClass: "align-top" }, [_vm._v("bandung")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("Data Tidak Ditemukan")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }