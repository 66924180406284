var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c("div", { staticClass: "content container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4 d-flex align-items-end" }, [
            _c("ul", { staticClass: "breadcrumb m-1 mt-0" }, [
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/dashboard" } }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _c("i", { staticClass: "bi-house-door" }),
                    ]),
                  ]),
                ],
                1
              ),
              _c("li", [_vm._v("Staff")]),
            ]),
          ]),
          _vm._m(0),
          _c("hr", { staticClass: "mb-3 mt-1" }),
        ]),
        _c(
          "div",
          { staticClass: "card" },
          [
            _c(
              "div",
              { staticClass: "card-header py-0 bg-custom-1" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "mx-2", attrs: { size: "sm" } },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "filter-input",
                                type: "search",
                                pleaceholder: "Type to Search",
                              },
                              model: {
                                value: _vm.filter,
                                callback: function ($$v) {
                                  _vm.filter = $$v
                                },
                                expression: "filter",
                              },
                            }),
                            _c(
                              "b-input-group-append",
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      size: "sm",
                                      disabled: !_vm.filter,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.filter = ""
                                      },
                                    },
                                  },
                                  [_vm._v("Clear")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex justify-content-end align-items-center",
                      },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.modal-add",
                                modifiers: { "modal-add": true },
                              },
                            ],
                            attrs: { variant: "primary", size: "sm" },
                          },
                          [_c("i", { staticClass: "bi-plus-circle" })]
                        ),
                        _c(
                          "b-dropdown",
                          {
                            staticClass: "m-2",
                            attrs: {
                              id: "dropdown-right",
                              right: "",
                              text: "Filter Column",
                              variant: "dark",
                              size: "sm",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("i", { staticClass: "bi-sliders" }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _c("b-form-checkbox-group", {
                              attrs: {
                                id: "checkbox-group-1",
                                options: _vm.fields,
                                name: "flavour-1",
                                buttons: "",
                              },
                              model: {
                                value: _vm.selected,
                                callback: function ($$v) {
                                  _vm.selected = $$v
                                },
                                expression: "selected",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.selected.length > 0
              ? _c("b-table", {
                  ref: "tableItem",
                  staticClass: "fs-11",
                  attrs: {
                    "label-sort-asc": "",
                    "label-sort-desc": "",
                    "label-sort-clear": "",
                    bordered: true,
                    stacked: "md",
                    items: _vm.Item,
                    fields: _vm.activeFields,
                    "current-page": _vm.currentPage,
                    "per-page": _vm.perPage,
                    filter: _vm.filter,
                    "td-class": _vm.mainTable,
                  },
                  on: { filtered: _vm.onFiltered },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "cell(opsi)",
                        fn: function (row) {
                          return [
                            _c(
                              "div",
                              { attrs: { width: "0px" } },
                              [
                                _c(
                                  "b-dropdown",
                                  {
                                    staticClass: "button-y0",
                                    attrs: {
                                      id: "dropdown-right",
                                      right: "",
                                      text: "Right align",
                                      variant: "primary",
                                      size: "xs",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "button-content",
                                          fn: function () {
                                            return [
                                              _c("i", {
                                                staticClass: "bi-gear-fill",
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.paymenType(
                                              row.item,
                                              row.index,
                                              $event.target
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Hak Akses")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.info(
                                              row.item,
                                              row.index,
                                              $event.target
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Detail")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.edit(
                                              row.item,
                                              row.index,
                                              $event.target
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Edit")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.changePass(
                                              row.item,
                                              row.index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Ganti Password ")]
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.alertDelete("Item")
                                          },
                                        },
                                      },
                                      [_vm._v("Hapus")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(userAddress)",
                        fn: function (data) {
                          return [
                            _vm._l(data.value, function (address) {
                              return [
                                _c("p", { staticClass: "m-0" }, [
                                  _c("b", [
                                    _vm._v(_vm._s(address.nameAddr) + ":"),
                                  ]),
                                  _vm._v(" " + _vm._s(address.location)),
                                ]),
                              ]
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3961123425
                  ),
                })
              : _vm._e(),
            _c(
              "b-modal",
              {
                attrs: {
                  id: _vm.infoModal.id,
                  title: _vm.infoModal.title,
                  "ok-only": "",
                  "no-close-on-esc": "",
                  "no-close-on-backdrop": "",
                  "hide-header-close": "",
                },
                on: { hide: _vm.resetInfoModal },
                scopedSlots: _vm._u([
                  {
                    key: "modal-footer",
                    fn: function () {
                      return [
                        _vm.dataId !== "" &&
                        _vm.procId !== "" &&
                        _vm.microId !== ""
                          ? _c(
                              "div",
                              {
                                staticClass: "w-100 d-flex justify-content-end",
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "secondary", size: "sm" },
                                    on: {
                                      click: function ($event) {
                                        _vm.microId = ""
                                      },
                                    },
                                  },
                                  [_vm._v(" Kembali ")]
                                ),
                              ],
                              1
                            )
                          : _vm.dataId !== "" &&
                            _vm.procId !== "" &&
                            _vm.microId == ""
                          ? _c(
                              "div",
                              {
                                staticClass: "w-100 d-flex justify-content-end",
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "me-2",
                                    attrs: { variant: "secondary", size: "sm" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$bvModal.hide("info-modal")
                                      },
                                    },
                                  },
                                  [_vm._v(" Kembali ")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    attrs: { size: "sm", variant: "primary" },
                                    on: { click: _vm.addMicroservice },
                                  },
                                  [_vm._v("Tambah Micro")]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "w-100 d-flex justify-content-end",
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "me-2",
                                    attrs: { variant: "secondary", size: "sm" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$bvModal.hide("info-modal")
                                      },
                                    },
                                  },
                                  [_vm._v(" Kembali ")]
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "row col-lg-4" }, [
                    _c(
                      "label",
                      {
                        staticClass:
                          "avatar avatar-xxl overflow-hidden d-flex justify-content-center",
                        attrs: { for: "editAvatarUploaderModal" },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../assets/img/160x160/img1.jpg"),
                            alt: "icon",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "row col-lg-8" }, [
                    _c(
                      "div",
                      { staticClass: "col-5 d-flex justify-content-between" },
                      [_c("span", [_vm._v("Kode")]), _vm._v(":")]
                    ),
                    _c("div", { staticClass: "col-7 ps-1" }, [
                      _vm._v(_vm._s(_vm.infoModal.content.userCode)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-5 d-flex justify-content-between" },
                      [_c("span", [_vm._v("Staff")]), _vm._v(":")]
                    ),
                    _c("div", { staticClass: "col-7 ps-1" }, [
                      _vm._v(_vm._s(_vm.infoModal.content.userName)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-5 d-flex justify-content-between" },
                      [_c("span", [_vm._v("No. NPWP")]), _vm._v(":")]
                    ),
                    _c("div", { staticClass: "col-7 ps-1" }, [
                      _vm._v(_vm._s(_vm.infoModal.content.userTax)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-5 d-flex justify-content-between" },
                      [_c("span", [_vm._v("No. Telp")]), _vm._v(":")]
                    ),
                    _c("div", { staticClass: "col-7 ps-1" }, [
                      _vm._v(_vm._s(_vm.infoModal.content.userPhone)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-5 d-flex justify-content-between" },
                      [_c("span", [_vm._v("Email")]), _vm._v(":")]
                    ),
                    _c("div", { staticClass: "col-7 ps-1" }, [
                      _vm._v(_vm._s(_vm.infoModal.content.userEmail)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-5 d-flex justify-content-between" },
                      [_c("span", [_vm._v("Fee Dokter")]), _vm._v(":")]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-7 ps-1" },
                      [
                        _vm.infoModal.content.userPrice
                          ? [_vm._v(_vm._s(_vm.infoModal.content.userPrice))]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "col-5 d-flex justify-content-between" },
                      [_c("span", [_vm._v("Alamat")]), _vm._v(":")]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-7 ps-1" },
                      [
                        _vm._l(
                          _vm.infoModal.content.userAddress,
                          function (alamat) {
                            return [
                              _c("p", { staticClass: "m-0" }, [
                                _vm._v(
                                  "- " +
                                    _vm._s(alamat.nameAddr) +
                                    ": " +
                                    _vm._s(alamat.location)
                                ),
                              ]),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "b-modal",
              {
                attrs: {
                  id: _vm.procModal.id,
                  title: _vm.procModal.title,
                  stacked: "",
                  "ok-title": "Selesai",
                  "cancel-title": "Kembali",
                },
                on: { ok: _vm.handleAccess },
              },
              [
                _c(
                  "form",
                  {
                    ref: "form",
                    staticClass: "row",
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.handleEditAccess.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "px-2 bg-primary d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("span", { staticClass: "text-white m-0" }, [
                            _vm._v("Hak Akses"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.modal-addPayment",
                                      modifiers: { "modal-addPayment": true },
                                    },
                                  ],
                                  staticClass: "btn p-0",
                                  attrs: {
                                    type: "button",
                                    "data-toggle": "tooltip",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.mergeData(_vm.optionPayment)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "bi bi-plus text-white",
                                    staticStyle: { "font-size": "25px" },
                                  }),
                                ]
                              ),
                              _vm.showPayment
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn p-0",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "tooltip",
                                        title: "Hide",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showPayment = !_vm.showPayment
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "bi bi-caret-up-square text-white",
                                        staticStyle: { "font-size": "20px" },
                                      }),
                                    ]
                                  )
                                : _c(
                                    "button",
                                    {
                                      staticClass: "btn p-0",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "tooltip",
                                        title: "Show",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showPayment = !_vm.showPayment
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "bi bi-caret-down-square text-white",
                                        staticStyle: { "font-size": "20px" },
                                      }),
                                    ]
                                  ),
                            ]
                          ),
                        ]
                      ),
                      _vm.showPayment
                        ? _c("table", { staticClass: "table border" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("Kode"),
                                ]),
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("Nama"),
                                ]),
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("Deskripsi"),
                                ]),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { scope: "col" },
                                  },
                                  [_vm._v("Akses")]
                                ),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.optionPayment, function (period, i) {
                                return _c("tr", { key: i }, [
                                  _c("td", { staticClass: "align-top" }, [
                                    _vm._v(_vm._s(period.microCode)),
                                  ]),
                                  _c("td", { staticClass: "align-top" }, [
                                    _vm._v(_vm._s(period.microName)),
                                  ]),
                                  _c("td", { staticClass: "align-top" }, [
                                    _vm._v(_vm._s(period.microDescription)),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "text-center align-top" },
                                    [
                                      _vm._l(
                                        period.microAccess,
                                        function (value, key) {
                                          return value
                                            ? [_vm._v(" " + _vm._s(key) + ", ")]
                                            : _vm._e()
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ])
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                attrs: {
                  id: _vm.editModal.id,
                  title: _vm.editModal.title,
                  "ok-variant": "success",
                  "ok-title": "Edit",
                  "cancel-title": "Kembali",
                },
                on: { hidden: _vm.resetEditModal, ok: _vm.handleEdit },
              },
              [
                _c(
                  "form",
                  {
                    ref: "form",
                    staticClass: "row",
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.handleEditSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-md-12 d-flex justify-content-center align-items-center pe-5",
                        staticStyle: { "min-height": "120px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center align-items-end flex-wrap h-100 pe-5",
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass:
                                  "avatar avatar-xl avatar-circle avatar-uploader profile-cover-avatar bg-transparent d-flex align-items-end",
                                staticStyle: { border: "none" },
                                attrs: {
                                  "label-for": "img1",
                                  "invalid-feedback": "Tambah gambar dulu!",
                                  tabindex: "-1",
                                },
                                on: {
                                  change: (event) => _vm.previewFiles(event),
                                },
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "avatar avatar-xxl avatar-circle avatar-uploader profile-cover-avatar",
                                    staticStyle: { margin: "0" },
                                    attrs: {
                                      for: "addImage",
                                      tabindex: "-1",
                                      "invalid-feedback":
                                        "Provinsi Harus Diisi!",
                                    },
                                    on: {
                                      change: (event) =>
                                        _vm.previewFiles(event),
                                    },
                                  },
                                  [
                                    _vm.image !== ""
                                      ? _c("img", {
                                          staticClass: "avatar-img",
                                          attrs: {
                                            id: "editAvatarImgModal",
                                            src: _vm.image,
                                            alt: "Image Description",
                                          },
                                        })
                                      : _c("img", {
                                          staticClass: "avatar-img",
                                          attrs: {
                                            id: "editAvatarImgModal",
                                            src: require("../assets/img/160x160/img1.jpg"),
                                            alt: "Image Description",
                                          },
                                        }),
                                    _c("input", {
                                      staticClass:
                                        "js-file-attach avatar-uploader-input",
                                      attrs: {
                                        tabindex: "-1",
                                        type: "file",
                                        id: "addImage",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "avatar-uploader-trigger",
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "bi-pencil-fill avatar-uploader-icon shadow-sm",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("b-form-input", {
                                  staticClass:
                                    "js-file-attach avatar-uploader-input",
                                  attrs: { tabindex: "-1", id: "img1" },
                                  model: {
                                    value: _vm.image,
                                    callback: function ($$v) {
                                      _vm.image = $$v
                                    },
                                    expression: "image",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-none col-4 d-flex align-items-center justify-content-between",
                      },
                      [
                        _c("h5", { staticClass: "m-0" }, [
                          _vm._v(" Password "),
                        ]),
                        _c("h3", [_vm._v(":")]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-none col-md-8 my-1" },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { "label-for": "ItemCode" } },
                          [
                            _c("b-form-input", {
                              attrs: { disabled: "", id: "ItemCode" },
                              model: {
                                value: _vm.id,
                                callback: function ($$v) {
                                  _vm.id = $$v
                                },
                                expression: "id",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-4 d-flex align-items-center justify-content-between",
                      },
                      [
                        _c("h5", { staticClass: "m-0" }, [
                          _vm._v(" Nama Staff "),
                        ]),
                        _c("h3", [_vm._v(":")]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-8 my-1" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              "label-for": "ItemName",
                              "invalid-feedback": "Nama Staff Harus Diisi!",
                              state: _vm.NameState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "ItemName",
                                state: _vm.NameState,
                                required: "",
                              },
                              model: {
                                value: _vm.name,
                                callback: function ($$v) {
                                  _vm.name = $$v
                                },
                                expression: "name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-4 d-flex align-items-center justify-content-between",
                      },
                      [
                        _c("h5", { staticClass: "m-0" }, [
                          _vm._v(" Jenis Staff "),
                        ]),
                        _c("h3", [_vm._v(":")]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-8 my-1" },
                      [
                        _c("multiselect", {
                          attrs: {
                            id: "taxType",
                            options: _vm.optionsCategory,
                            placeholder: "Pilih Satu",
                            taggable: true,
                            multiple: false,
                          },
                          on: { tag: _vm.addCategory },
                          model: {
                            value: _vm.contact,
                            callback: function ($$v) {
                              _vm.contact = $$v
                            },
                            expression: "contact",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-4 d-flex align-items-center justify-content-between",
                      },
                      [
                        _c("h5", { staticClass: "m-0" }, [
                          _vm._v(" No. NPWP "),
                        ]),
                        _c("h3", [_vm._v(":")]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-8 my-1" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              "label-for": "ItemType",
                              "invalid-feedback": "No. NPWP Harus Diisi!",
                              state: _vm.typeState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "ItemType",
                                state: _vm.typeState,
                                required: "",
                              },
                              model: {
                                value: _vm.type,
                                callback: function ($$v) {
                                  _vm.type = $$v
                                },
                                expression: "type",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-4 d-flex align-items-center justify-content-between",
                      },
                      [
                        _c("h5", { staticClass: "m-0" }, [
                          _vm._v(" No. Telepon "),
                        ]),
                        _c("h3", [_vm._v(":")]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-8 my-1" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              "label-for": "ItemDesc",
                              "invalid-feedback": "Nomor Telepon Harus Diisi!",
                              state: _vm.descState,
                            },
                          },
                          [
                            _c(
                              "b-input-group",
                              { attrs: { prepend: "+62" } },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "ItemDesc",
                                    state: _vm.descState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.desc,
                                    callback: function ($$v) {
                                      _vm.desc = $$v
                                    },
                                    expression: "desc",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-4 d-flex align-items-center justify-content-between",
                      },
                      [
                        _c("h5", { staticClass: "m-0" }, [_vm._v(" Email ")]),
                        _c("h3", [_vm._v(":")]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-8 my-1" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              "label-for": "ItemContact",
                              "invalid-feedback": "Deskripsi Harus Diisi!",
                              state: _vm.addressState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "ItemContact",
                                state: _vm.addressState,
                                required: "",
                              },
                              model: {
                                value: _vm.address,
                                callback: function ($$v) {
                                  _vm.address = $$v
                                },
                                expression: "address",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-4 d-flex align-items-center justify-content-between",
                      },
                      [
                        _c("h5", { staticClass: "m-0" }, [
                          _vm._v(" Departemen "),
                        ]),
                        _c("h3", [_vm._v(":")]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-8 my-1" },
                      [
                        _c("multiselect", {
                          attrs: {
                            id: "taxType",
                            options: _vm.optionsDept,
                            placeholder: "Pilih Satu",
                            taggable: true,
                            multiple: false,
                          },
                          on: { tag: _vm.addCategoryDept },
                          model: {
                            value: _vm.dept,
                            callback: function ($$v) {
                              _vm.dept = $$v
                            },
                            expression: "dept",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-4 d-flex align-items-center justify-content-between",
                      },
                      [
                        _c("h5", { staticClass: "m-0" }, [_vm._v(" Loket ")]),
                        _c("h3", [_vm._v(":")]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-8 my-1" },
                      [
                        _c("multiselect", {
                          attrs: {
                            id: "taxType",
                            options: _vm.optionsLoket,
                            placeholder: "Pilih Satu",
                            taggable: true,
                            multiple: false,
                          },
                          on: { tag: _vm.addCategoryLoket },
                          model: {
                            value: _vm.loket,
                            callback: function ($$v) {
                              _vm.loket = $$v
                            },
                            expression: "loket",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col-md-12 mt-4" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "px-2 bg-primary d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("span", { staticClass: "text-white m-0" }, [
                            _vm._v("Alamat"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.modal-addAddress",
                                      modifiers: { "modal-addAddress": true },
                                    },
                                  ],
                                  staticClass: "btn p-0",
                                  attrs: {
                                    type: "button",
                                    "data-toggle": "tooltip",
                                    title: "Tambah Alamat",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "bi bi-plus text-white",
                                    staticStyle: { "font-size": "25px" },
                                  }),
                                ]
                              ),
                              _vm.showAddress
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn p-0",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "tooltip",
                                        title: "Hide",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showAddress = !_vm.showAddress
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "bi bi-caret-up-square text-white",
                                        staticStyle: { "font-size": "20px" },
                                      }),
                                    ]
                                  )
                                : _c(
                                    "button",
                                    {
                                      staticClass: "btn p-0",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "tooltip",
                                        title: "Show",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showAddress = !_vm.showAddress
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "bi bi-caret-down-square text-white",
                                        staticStyle: { "font-size": "20px" },
                                      }),
                                    ]
                                  ),
                            ]
                          ),
                        ]
                      ),
                      _vm.showAddress
                        ? _c("table", { staticClass: "table border" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("Nama"),
                                ]),
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("Lokasi"),
                                ]),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { scope: "col" },
                                  },
                                  [_vm._v("Opsi")]
                                ),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.optionAddress, function (address, i) {
                                return _c("tr", { key: i }, [
                                  _c("td", [_vm._v(_vm._s(address.nameAddr))]),
                                  _c("td", [_vm._v(_vm._s(address.location))]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn p-0",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editAddress(
                                              address,
                                              i,
                                              $event.target
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "bi bi-pencil-square text-warning",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn p-0",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeAddress(i)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "bi bi-trash text-danger",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "col-md-12 mt-4 d-none" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "p-2 bg-primary d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("span", { staticClass: "h3 text-white m-0" }, [
                            _vm._v("Contact Person"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.modal-addContact",
                                      modifiers: { "modal-addContact": true },
                                    },
                                  ],
                                  staticClass: "btn p-0",
                                  attrs: {
                                    type: "button",
                                    "data-toggle": "tooltip",
                                    title: "Tambah Contact Person",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "bi bi-plus text-white",
                                    staticStyle: { "font-size": "25px" },
                                  }),
                                ]
                              ),
                              _vm.showContact
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn p-0",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "tooltip",
                                        title: "Hide",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showContact = !_vm.showContact
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "bi bi-caret-up-square text-white",
                                        staticStyle: { "font-size": "20px" },
                                      }),
                                    ]
                                  )
                                : _c(
                                    "button",
                                    {
                                      staticClass: "btn p-0",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "tooltip",
                                        title: "Show",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showContact = !_vm.showContact
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "bi bi-caret-down-square text-white",
                                        staticStyle: { "font-size": "20px" },
                                      }),
                                    ]
                                  ),
                            ]
                          ),
                        ]
                      ),
                      _vm.showContact
                        ? _c("table", { staticClass: "table border" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("Nama"),
                                ]),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { scope: "col" },
                                  },
                                  [_vm._v("No. Telp")]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { scope: "col" },
                                  },
                                  [_vm._v("Opsi")]
                                ),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.optionContact, function (contact, i) {
                                return _c("tr", { key: i }, [
                                  _c("td", [_vm._v(_vm._s(contact.userName))]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(_vm._s(contact.userPhone)),
                                  ]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn p-0",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editContact(
                                              contact,
                                              i,
                                              $event.target
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "bi bi-pencil-square text-warning",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn p-0",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeContact(i)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "bi bi-trash text-danger",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                attrs: {
                  id: _vm.editProc.id,
                  title: _vm.editProc.title,
                  "no-close-on-esc": "",
                  "no-close-on-backdrop": "",
                  "hide-header-close": "",
                },
                scopedSlots: _vm._u(
                  [
                    _vm.addressIndex !== ""
                      ? {
                          key: "modal-footer",
                          fn: function () {
                            return [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "secondary" },
                                  on: { click: _vm.resetEditArray },
                                },
                                [_vm._v("Kembali")]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "success" },
                                  on: { click: _vm.saveEditAddress },
                                },
                                [_vm._v("Edit")]
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : _vm.contactIndex !== ""
                      ? {
                          key: "modal-footer",
                          fn: function () {
                            return [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "secondary" },
                                  on: { click: _vm.resetEditArray },
                                },
                                [_vm._v("Kembali")]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "success" },
                                  on: { click: _vm.saveEditContact },
                                },
                                [_vm._v("Edit")]
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : _vm.paymentIndex !== ""
                      ? {
                          key: "modal-footer",
                          fn: function () {
                            return [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "secondary" },
                                  on: { click: _vm.resetEditArray },
                                },
                                [_vm._v("Kembali")]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "success" },
                                  on: { click: _vm.saveEditPayment },
                                },
                                [_vm._v("Edit")]
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : _vm.dataId !== "" &&
                        _vm.procId !== "" &&
                        _vm.microId !== ""
                      ? {
                          key: "modal-footer",
                          fn: function () {
                            return [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "secondary" },
                                  on: { click: _vm.resetEditModal },
                                },
                                [_vm._v("Kembali")]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "success" },
                                  on: { click: _vm.handleMicroEdit },
                                },
                                [_vm._v("Edit")]
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : {
                          key: "modal-footer",
                          fn: function () {
                            return [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "secondary" },
                                  on: { click: _vm.resetEditModal },
                                },
                                [_vm._v("Kembali")]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "success" },
                                  on: { click: _vm.handleProcEdit },
                                },
                                [_vm._v("Edit")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                  ],
                  null,
                  true
                ),
              },
              [
                _vm.addressIndex !== ""
                  ? _c(
                      "form",
                      {
                        ref: "form",
                        staticClass: "row",
                        on: {
                          submit: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.handleProcSubmit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h5", { staticClass: "m-0" }, [
                              _vm._v(" Nama "),
                            ]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-for": "toppName",
                                  "invalid-feedback": "Nama Harus Diisi!",
                                  state: _vm.provState,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "toppName",
                                    state: _vm.provState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.prov,
                                    callback: function ($$v) {
                                      _vm.prov = $$v
                                    },
                                    expression: "prov",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h5", { staticClass: "m-0" }, [
                              _vm._v(" Lokasi "),
                            ]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-for": "addrecLocation",
                                  "invalid-feedback": "Lokasi Harus Diisi!",
                                  state: _vm.kabState,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "addrecLocation",
                                    state: _vm.kabState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.kab,
                                    callback: function ($$v) {
                                      _vm.kab = $$v
                                    },
                                    expression: "kab",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm.contactIndex !== ""
                  ? _c(
                      "form",
                      {
                        ref: "form",
                        staticClass: "row",
                        on: {
                          submit: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.handleProcSubmit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h5", { staticClass: "m-0" }, [
                              _vm._v(" Nama "),
                            ]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-for": "toppName",
                                  "invalid-feedback": "Nama Harus Diisi!",
                                  state: _vm.provState,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "toppName",
                                    state: _vm.provState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.prov,
                                    callback: function ($$v) {
                                      _vm.prov = $$v
                                    },
                                    expression: "prov",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h5", { staticClass: "m-0" }, [
                              _vm._v(" No. Telepon "),
                            ]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-for": "contactPhone",
                                  "invalid-feedback": "Harga Harus Diisi!",
                                  state: _vm.kabState,
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  { attrs: { prepend: "+62" } },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "number",
                                        id: "contactPhone",
                                        state: _vm.kabState,
                                        required: "",
                                      },
                                      model: {
                                        value: _vm.kab,
                                        callback: function ($$v) {
                                          _vm.kab = $$v
                                        },
                                        expression: "kab",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm.paymentIndex !== ""
                  ? _c(
                      "form",
                      {
                        ref: "form",
                        staticClass: "row",
                        on: {
                          submit: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.handleProcSubmit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h5", { staticClass: "m-0" }, [
                              _vm._v(" Tipe Payment "),
                            ]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-for": "toppName",
                                  "invalid-feedback": "Nama Harus Diisi!",
                                  state: _vm.provState,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "toppName",
                                    state: _vm.provState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.prov,
                                    callback: function ($$v) {
                                      _vm.prov = $$v
                                    },
                                    expression: "prov",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h5", { staticClass: "m-0" }, [
                              _vm._v(" Nomor Payment "),
                            ]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-for": "paymentNumber",
                                  "invalid-feedback":
                                    "Nomor Pembayaran Harus Diisi!",
                                  state: _vm.provState,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "paymentNumber",
                                    type: "number",
                                    state: _vm.provState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.kab,
                                    callback: function ($$v) {
                                      _vm.kab = $$v
                                    },
                                    expression: "kab",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h5", { staticClass: "m-0" }, [
                              _vm._v(" Nama Payment "),
                            ]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-for": "paymentName",
                                  "invalid-feedback":
                                    "Nama Pembayaran Harus Diisi!",
                                  state: _vm.provState,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "paymentName",
                                    state: _vm.provState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.kec,
                                    callback: function ($$v) {
                                      _vm.kec = $$v
                                    },
                                    expression: "kec",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm.dataId !== "" && _vm.procId !== "" && _vm.microId !== ""
                  ? _c(
                      "form",
                      {
                        ref: "form",
                        on: {
                          submit: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.handleMicroEdit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        !_vm.microVar
                          ? _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h5", { staticClass: "m-0" }, [
                                    _vm._v(" Nama "),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "procName",
                                        "invalid-feedback":
                                          "Nama Microservice Harus Diisi!",
                                        state: _vm.NameState,
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "procName",
                                          state: _vm.NameState,
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.name,
                                          callback: function ($$v) {
                                            _vm.name = $$v
                                          },
                                          expression: "name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h5", { staticClass: "m-0" }, [
                                    _vm._v(" Kode "),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "procMicroCode",
                                        "invalid-feedback":
                                          "Kode Microservice Harus Diisi!",
                                        state: _vm.descState,
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        {
                                          attrs: {
                                            prepend:
                                              this.infoModal.content
                                                .appProcCode + ".",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "procMicroCode",
                                              type: "number",
                                              state: _vm.descState,
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.microCode,
                                              callback: function ($$v) {
                                                _vm.microCode = $$v
                                              },
                                              expression: "microCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h5", { staticClass: "m-0" }, [
                                    _vm._v(" Tipe "),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "microType",
                                        "invalid-feedback":
                                          "Tipe Microservice Harus Diisi!",
                                        state: _vm.typeState,
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "microType",
                                          state: _vm.typeState,
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.type,
                                          callback: function ($$v) {
                                            _vm.type = $$v
                                          },
                                          expression: "type",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h5", { staticClass: "m-0" }, [
                                    _vm._v(" Deskripsi "),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { "label-for": "procDesc" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "procDesc" },
                                        model: {
                                          value: _vm.desc,
                                          callback: function ($$v) {
                                            _vm.desc = $$v
                                          },
                                          expression: "desc",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h5", { staticClass: "m-0" }, [
                                    _vm._v(" Icon "),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { "label-for": "microIcon" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "microIcon" },
                                        model: {
                                          value: _vm.image,
                                          callback: function ($$v) {
                                            _vm.image = $$v
                                          },
                                          expression: "image",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h5", { staticClass: "m-0" }, [
                                    _vm._v(" Placeholder "),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "microPleaceholder",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "microPleaceholder" },
                                        model: {
                                          value: _vm.prov,
                                          callback: function ($$v) {
                                            _vm.prov = $$v
                                          },
                                          expression: "prov",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h5", { staticClass: "m-0" }, [
                                    _vm._v(" Routing "),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { "label-for": "microRouting" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "microRouting" },
                                        model: {
                                          value: _vm.kab,
                                          callback: function ($$v) {
                                            _vm.kab = $$v
                                          },
                                          expression: "kab",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h5", { staticClass: "m-0" }, [
                                    _vm._v(" Navigate "),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: { "label-for": "microNaviagate" },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "microNaviagate" },
                                        model: {
                                          value: _vm.kec,
                                          callback: function ($$v) {
                                            _vm.kec = $$v
                                          },
                                          expression: "kec",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h5", { staticClass: "m-0" }, [
                                    _vm._v(" API Function Script "),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "microFunctionScript",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "microFunctionScript" },
                                        model: {
                                          value: _vm.prov,
                                          callback: function ($$v) {
                                            _vm.prov = $$v
                                          },
                                          expression: "prov",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex pt-3 justify-content-between",
                                },
                                [
                                  _c("h5", { staticClass: "m-0" }, [
                                    _vm._v(" Data "),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { "label-for": "microId" } },
                                    [
                                      _c("b-form-textarea", {
                                        attrs: { id: "microId" },
                                        model: {
                                          value: _vm.id,
                                          callback: function ($$v) {
                                            _vm.id = $$v
                                          },
                                          expression: "id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _c("JsonEditorVue", {
                              staticStyle: { height: "350px" },
                              attrs: {
                                Mode: "text",
                                options: {
                                  Mode: "text",
                                  Modes: ["text"],
                                  onError: _vm.handleJsonError,
                                },
                              },
                              model: {
                                value: _vm.arrayVariable,
                                callback: function ($$v) {
                                  _vm.arrayVariable = $$v
                                },
                                expression: "arrayVariable",
                              },
                            }),
                      ],
                      1
                    )
                  : _c(
                      "form",
                      {
                        ref: "form",
                        staticClass: "row",
                        on: {
                          submit: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.handleProcEdit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h5", { staticClass: "m-0" }, [
                              _vm._v(" Nama Proses* "),
                            ]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-for": "procName",
                                  "invalid-feedback":
                                    "Nama Proses Harus Diisi!",
                                  state: _vm.NameState,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "procName",
                                    state: _vm.NameState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.name,
                                    callback: function ($$v) {
                                      _vm.name = $$v
                                    },
                                    expression: "name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h5", { staticClass: "m-0" }, [
                              _vm._v(" Kode* "),
                            ]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-for": "procCode",
                                  "invalid-feedback": "Kode Harus Diisi!",
                                  state: _vm.descState,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "procCode",
                                    state: _vm.descState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.id,
                                    callback: function ($$v) {
                                      _vm.id = $$v
                                    },
                                    expression: "id",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h5", { staticClass: "m-0" }, [
                              _vm._v(" Group App* "),
                            ]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-for": "procGroup",
                                  "invalid-feedback": "Group App Harus Diisi!",
                                  state: _vm.typeState,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "procGroup",
                                    state: _vm.typeState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.type,
                                    callback: function ($$v) {
                                      _vm.type = $$v
                                    },
                                    expression: "type",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h5", { staticClass: "m-0" }, [
                              _vm._v(" Deskripsi "),
                            ]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { "label-for": "procDesc" } },
                              [
                                _c("b-form-input", {
                                  attrs: { id: "procDesc" },
                                  model: {
                                    value: _vm.desc,
                                    callback: function ($$v) {
                                      _vm.desc = $$v
                                    },
                                    expression: "desc",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h5", { staticClass: "m-0" }, [
                              _vm._v(" Routing* "),
                            ]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-for": "procRouting",
                                  "invalid-feedback": "Routing Harus Diisi!",
                                  state: _vm.provState,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "procRouting",
                                    state: _vm.provState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.prov,
                                    callback: function ($$v) {
                                      _vm.prov = $$v
                                    },
                                    expression: "prov",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h5", { staticClass: "m-0" }, [
                              _vm._v(" Icon "),
                            ]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { "label-for": "procIcon" } },
                              [
                                _c("b-form-input", {
                                  attrs: { id: "procIcon" },
                                  model: {
                                    value: _vm.kab,
                                    callback: function ($$v) {
                                      _vm.kab = $$v
                                    },
                                    expression: "kab",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
              ]
            ),
            _c(
              "b-modal",
              {
                ref: "modal",
                attrs: {
                  id: "modal-add",
                  size: "lg",
                  title: "Tambah Staff",
                  "no-close-on-esc": "",
                  "no-close-on-backdrop": "",
                  "ok-title": "Simpan",
                  "cancel-title": "Kembali",
                },
                on: {
                  show: _vm.resetModal,
                  hidden: _vm.resetModal,
                  ok: _vm.handleOk,
                },
              },
              [
                _c(
                  "form",
                  {
                    ref: "form",
                    staticClass: "row p-5",
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.handleSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-3 d-flex justify-content-center" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center align-items-end flex-wrap pb-3",
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass:
                                  "avatar avatar-xxl avatar-circle avatar-uploader profile-cover-avatar bg-transparent d-flex align-items-end",
                                staticStyle: { border: "none" },
                                attrs: {
                                  "label-for": "img1",
                                  "invalid-feedback": "Tambah gambar dulu!",
                                  tabindex: "-1",
                                },
                                on: {
                                  change: (event) => _vm.previewFiles(event),
                                },
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "avatar avatar-xxl avatar-circle avatar-uploader profile-cover-avatar",
                                    staticStyle: { margin: "0" },
                                    attrs: {
                                      for: "addImage",
                                      tabindex: "-1",
                                      "invalid-feedback":
                                        "Provinsi Harus Diisi!",
                                    },
                                    on: {
                                      change: (event) =>
                                        _vm.previewFiles(event),
                                    },
                                  },
                                  [
                                    _vm.image !== ""
                                      ? _c("img", {
                                          staticClass: "avatar-img",
                                          attrs: {
                                            id: "editAvatarImgModal",
                                            src: _vm.image,
                                            alt: "Image Description",
                                          },
                                        })
                                      : _c("img", {
                                          staticClass: "avatar-img",
                                          attrs: {
                                            id: "editAvatarImgModal",
                                            src: require("../assets/img/160x160/img1.jpg"),
                                            alt: "Image Description",
                                          },
                                        }),
                                    _c("input", {
                                      staticClass:
                                        "js-file-attach avatar-uploader-input",
                                      attrs: {
                                        tabindex: "-1",
                                        type: "file",
                                        id: "addImage",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "avatar-uploader-trigger",
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "bi-pencil-fill avatar-uploader-icon shadow-sm",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("b-form-input", {
                                  staticClass:
                                    "js-file-attach avatar-uploader-input",
                                  attrs: { tabindex: "-1", id: "img1" },
                                  model: {
                                    value: _vm.image,
                                    callback: function ($$v) {
                                      _vm.image = $$v
                                    },
                                    expression: "image",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "col-9 row m-0 p-0" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6 mb-2" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Nama",
                                "label-for": "ItemName",
                                "invalid-feedback": "Nama Staff Harus Diisi!",
                                state: _vm.NameState,
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "ItemName",
                                  state: _vm.NameState,
                                  required: "",
                                  placeholder: "Masukan Nama Staff",
                                },
                                model: {
                                  value: _vm.name,
                                  callback: function ($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Jenis Staff",
                                "label-for": "taxType",
                                "invalid-feedback": "Jenis Staff Harus Diisi!",
                                state: _vm.contactState,
                              },
                            },
                            [
                              _c("multiselect", {
                                attrs: {
                                  id: "taxType",
                                  options: _vm.optionsCategory,
                                  placeholder: "Pilih Satu",
                                  taggable: true,
                                  multiple: false,
                                },
                                on: { tag: _vm.addCategory },
                                model: {
                                  value: _vm.contact,
                                  callback: function ($$v) {
                                    _vm.contact = $$v
                                  },
                                  expression: "contact",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 mb-2" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "No. NPWP",
                                "label-for": "ItemType",
                                "invalid-feedback": "No. NPWP Harus Diisi!",
                                state: _vm.typeState,
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "ItemType",
                                  state: _vm.typeState,
                                  required: "",
                                  placeholder: "Masukan No.NPWP",
                                },
                                model: {
                                  value: _vm.type,
                                  callback: function ($$v) {
                                    _vm.type = $$v
                                  },
                                  expression: "type",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 mb-2" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Nomor Telepon",
                                "label-for": "usahaTelp",
                                "invalid-feedback":
                                  "Nomor Telepon Harus Diisi!",
                                state: _vm.descState,
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                { attrs: { prepend: "+62" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "number",
                                      id: "usahaTelp",
                                      state: _vm.descState,
                                      required: "",
                                      placeholder: "812212121212",
                                    },
                                    model: {
                                      value: _vm.desc,
                                      callback: function ($$v) {
                                        _vm.desc = $$v
                                      },
                                      expression: "desc",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 mb-2" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Email",
                                "label-for": "ItemContact",
                                "invalid-feedback": "Email Tidak Valid!",
                                state: _vm.addressState,
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "email",
                                  id: "ItemContact",
                                  state: _vm.addressState,
                                  required: "",
                                  placeholder: "contoh@email.com",
                                },
                                model: {
                                  value: _vm.address,
                                  callback: function ($$v) {
                                    _vm.address = $$v
                                  },
                                  expression: "address",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 mb-2" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Password",
                                "label-for": "ItemPass",
                                "invalid-feedback": "Password Harus Diisi!",
                                state: _vm.imageState,
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "password",
                                  id: "ItemPass",
                                  state: _vm.imageState,
                                  required: "",
                                  placeholder: "Masukan Password",
                                },
                                model: {
                                  value: _vm.code,
                                  callback: function ($$v) {
                                    _vm.code = $$v
                                  },
                                  expression: "code",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Departemen",
                                "label-for": "taxType",
                                "invalid-feedback":
                                  "Departemen Staff Harus Diisi!",
                                state: _vm.contactState,
                              },
                            },
                            [
                              _c("multiselect", {
                                attrs: {
                                  id: "taxType",
                                  options: _vm.optionsDept,
                                  placeholder: "Pilih Satu",
                                  multiple: false,
                                },
                                model: {
                                  value: _vm.dept,
                                  callback: function ($$v) {
                                    _vm.dept = $$v
                                  },
                                  expression: "dept",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: { label: "Loket", "label-for": "taxType" },
                            },
                            [
                              _c("multiselect", {
                                attrs: {
                                  id: "loketType",
                                  options: _vm.optionsLoket,
                                  placeholder: "Pilih Satu",
                                  taggable: true,
                                  multiple: false,
                                },
                                on: { tag: _vm.addCategoryLoket },
                                model: {
                                  value: _vm.loket,
                                  callback: function ($$v) {
                                    _vm.loket = $$v
                                  },
                                  expression: "loket",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-5 mt-4" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "px-2 bg-primary d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("span", { staticClass: "text-white m-0" }, [
                            _vm._v("Alamat"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.modal-addAddress",
                                      modifiers: { "modal-addAddress": true },
                                    },
                                  ],
                                  staticClass: "btn p-0",
                                  attrs: {
                                    type: "button",
                                    "data-toggle": "tooltip",
                                    title: "Tambah Alamat",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "bi bi-plus text-white",
                                    staticStyle: { "font-size": "25px" },
                                  }),
                                ]
                              ),
                              _vm.showAddress
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn p-0",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "tooltip",
                                        title: "Hide",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showAddress = !_vm.showAddress
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "bi bi-caret-up-square text-white",
                                        staticStyle: { "font-size": "20px" },
                                      }),
                                    ]
                                  )
                                : _c(
                                    "button",
                                    {
                                      staticClass: "btn p-0",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "tooltip",
                                        title: "Show",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showAddress = !_vm.showAddress
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "bi bi-caret-down-square text-white",
                                        staticStyle: { "font-size": "20px" },
                                      }),
                                    ]
                                  ),
                            ]
                          ),
                        ]
                      ),
                      _vm.showAddress
                        ? _c("table", { staticClass: "table border" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("Nama"),
                                ]),
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("Lokasi"),
                                ]),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { scope: "col" },
                                  },
                                  [_vm._v("Opsi")]
                                ),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.optionAddress, function (address, i) {
                                return _c("tr", { key: i }, [
                                  _c("td", [_vm._v(_vm._s(address.nameAddr))]),
                                  _c("td", [_vm._v(_vm._s(address.location))]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn p-0",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editAddress(
                                              address,
                                              i,
                                              $event.target
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "bi bi-pencil-square text-warning",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn p-0",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeAddress(i)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "bi bi-trash text-danger",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "col-md-6 mt-4 d-none" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "p-2 bg-primary d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("span", { staticClass: "h3 text-white m-0" }, [
                            _vm._v("Contact Person"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.modal-addContact",
                                      modifiers: { "modal-addContact": true },
                                    },
                                  ],
                                  staticClass: "btn p-0",
                                  attrs: {
                                    type: "button",
                                    "data-toggle": "tooltip",
                                    title: "Tambah Contact Person",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "bi bi-plus text-white",
                                    staticStyle: { "font-size": "25px" },
                                  }),
                                ]
                              ),
                              _vm.showContact
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn p-0",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "tooltip",
                                        title: "Hide",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showContact = !_vm.showContact
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "bi bi-caret-up-square text-white",
                                        staticStyle: { "font-size": "20px" },
                                      }),
                                    ]
                                  )
                                : _c(
                                    "button",
                                    {
                                      staticClass: "btn p-0",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "tooltip",
                                        title: "Show",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showContact = !_vm.showContact
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "bi bi-caret-down-square text-white",
                                        staticStyle: { "font-size": "20px" },
                                      }),
                                    ]
                                  ),
                            ]
                          ),
                        ]
                      ),
                      _vm.showContact
                        ? _c("table", { staticClass: "table border" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("Nama"),
                                ]),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { scope: "col" },
                                  },
                                  [_vm._v("No. Telp")]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { scope: "col" },
                                  },
                                  [_vm._v("Opsi")]
                                ),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.optionContact, function (contact, i) {
                                return _c("tr", { key: i }, [
                                  _c("td", [_vm._v(_vm._s(contact.userName))]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(_vm._s(contact.userPhone)),
                                  ]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn p-0",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editContact(
                                              contact,
                                              i,
                                              $event.target
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "bi bi-pencil-square text-warning",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn p-0",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeContact(i)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "bi bi-trash text-danger",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "col-md-7 mt-4" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "px-2 bg-dark d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("span", { staticClass: "text-white m-0" }, [
                            _vm._v("Hak Akses"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.modal-addPayment",
                                      modifiers: { "modal-addPayment": true },
                                    },
                                  ],
                                  staticClass: "btn p-0",
                                  attrs: {
                                    type: "button",
                                    "data-toggle": "tooltip",
                                    title: "Tambah Hak Akses",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.mergeData(_vm.optionPayment)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "bi bi-plus text-white",
                                    staticStyle: { "font-size": "25px" },
                                  }),
                                ]
                              ),
                              _vm.showPayment
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn p-0",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "tooltip",
                                        title: "Hide",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showPayment = !_vm.showPayment
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "bi bi-caret-up-square text-white",
                                        staticStyle: { "font-size": "20px" },
                                      }),
                                    ]
                                  )
                                : _c(
                                    "button",
                                    {
                                      staticClass: "btn p-0",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "tooltip",
                                        title: "Show",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showPayment = !_vm.showPayment
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "bi bi-caret-down-square text-white",
                                        staticStyle: { "font-size": "20px" },
                                      }),
                                    ]
                                  ),
                            ]
                          ),
                        ]
                      ),
                      _vm.showPayment
                        ? _c("table", { staticClass: "table border" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("Tipe"),
                                ]),
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("Kode"),
                                ]),
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("Nama"),
                                ]),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { scope: "col" },
                                  },
                                  [_vm._v("Opsi")]
                                ),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.optionPayment, function (period, i) {
                                return _c("tr", { key: i }, [
                                  _c("td", { staticClass: "align-top" }, [
                                    _vm._v(_vm._s(period.microCode)),
                                  ]),
                                  _c("td", { staticClass: "align-top" }, [
                                    _vm._v(_vm._s(period.microName)),
                                  ]),
                                  _c("td", { staticClass: "align-top" }, [
                                    _vm._v(_vm._s(period.microDescription)),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "text-center align-top" },
                                    [
                                      _vm._l(
                                        period.microAccess,
                                        function (value, key) {
                                          return value
                                            ? [_vm._v(" " + _vm._s(key) + ", ")]
                                            : _vm._e()
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ])
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                ref: "modal",
                attrs: {
                  id: "modal-addMicro",
                  size: "lg",
                  title: "Tambah Microservice",
                  "no-close-on-esc": "",
                  "no-close-on-backdrop": "",
                  "ok-title": "Simpan",
                  "cancel-title": "Kembali",
                },
                on: {
                  show: _vm.resetModal,
                  hidden: _vm.resetModal,
                  ok: _vm.handleMicroOk,
                },
              },
              [
                _c(
                  "form",
                  {
                    ref: "form",
                    staticClass: "row",
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.handleMicroSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h5 my-1",
                            attrs: {
                              label: "Nama Microservice",
                              "label-for": "microName",
                              "invalid-feedback":
                                "Nama Microservice Harus Diisi!",
                              state: _vm.NameState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "microName",
                                state: _vm.NameState,
                                required: "",
                              },
                              model: {
                                value: _vm.name,
                                callback: function ($$v) {
                                  _vm.name = $$v
                                },
                                expression: "name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h5 my-1",
                            attrs: {
                              label: "Tipe Microservice",
                              "label-for": "microType",
                              "invalid-feedback":
                                "Tipe Microservice Harus Diisi!",
                              state: _vm.typeState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "microType",
                                state: _vm.typeState,
                                required: "",
                              },
                              model: {
                                value: _vm.type,
                                callback: function ($$v) {
                                  _vm.type = $$v
                                },
                                expression: "type",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h5 my-1",
                            attrs: {
                              label: "Deskripsi Microservice",
                              "label-for": "microDesc",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "microDesc" },
                              model: {
                                value: _vm.desc,
                                callback: function ($$v) {
                                  _vm.desc = $$v
                                },
                                expression: "desc",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h5 my-1",
                            attrs: {
                              label: "icon Microservice",
                              "label-for": "microImage",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "microImage" },
                              model: {
                                value: _vm.image,
                                callback: function ($$v) {
                                  _vm.image = $$v
                                },
                                expression: "image",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h5 my-1",
                            attrs: {
                              label: "Placeholder Microservice",
                              "label-for": "microPleaceholder",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "microPleaceholder" },
                              model: {
                                value: _vm.prov,
                                callback: function ($$v) {
                                  _vm.prov = $$v
                                },
                                expression: "prov",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h5 my-1",
                            attrs: {
                              label: "Routing Microservice",
                              "label-for": "microRouting",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "microRouting" },
                              model: {
                                value: _vm.kab,
                                callback: function ($$v) {
                                  _vm.kab = $$v
                                },
                                expression: "kab",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h5 my-1",
                            attrs: {
                              label: "Navigasi Microservice",
                              "label-for": "microNavigate",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "microNavigate" },
                              model: {
                                value: _vm.kec,
                                callback: function ($$v) {
                                  _vm.kec = $$v
                                },
                                expression: "kec",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h5 my-1",
                            attrs: {
                              label: "API Function Script",
                              "label-for": "microAddress",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "microAddress" },
                              model: {
                                value: _vm.address,
                                callback: function ($$v) {
                                  _vm.address = $$v
                                },
                                expression: "address",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-3 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h5 my-1",
                            attrs: {
                              label: "Kode Microservice",
                              "label-for": "microData",
                              "invalid-feedback":
                                "Kode Microservice Harus Diisi!",
                              state: _vm.descState,
                            },
                          },
                          [
                            _c(
                              "b-input-group",
                              {
                                attrs: {
                                  prepend:
                                    this.infoModal.content.appProcCode + ".",
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "microData",
                                    type: "number",
                                    state: _vm.descState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.microCode,
                                    callback: function ($$v) {
                                      _vm.microCode = $$v
                                    },
                                    expression: "microCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-9 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h5 my-1",
                            attrs: {
                              label: "Data Microservice",
                              "label-for": "microData",
                            },
                          },
                          [
                            _c("b-form-textarea", {
                              attrs: { id: "microData" },
                              model: {
                                value: _vm.id,
                                callback: function ($$v) {
                                  _vm.id = $$v
                                },
                                expression: "id",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                ref: "modal",
                attrs: {
                  id: "modal-addProc",
                  size: "sm",
                  title: "Tambah Proses",
                  "hide-header-close": "",
                  "no-close-on-esc": "",
                  "no-close-on-backdrop": "",
                  "ok-title": "Simpan",
                  "cancel-title": "Kembali",
                },
                on: {
                  show: _vm.resetModal,
                  hidden: _vm.resetProcDetail,
                  ok: _vm.handleOkProc,
                },
              },
              [
                _c(
                  "form",
                  {
                    ref: "form",
                    staticClass: "row",
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.handleSubmitProc.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-12 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Nama Proses",
                              "label-for": "ItemName",
                              "invalid-feedback": "Nama Proc Harus Diisi!",
                              state: _vm.NameState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "ItemName",
                                state: _vm.NameState,
                                required: "",
                              },
                              model: {
                                value: _vm.name,
                                callback: function ($$v) {
                                  _vm.name = $$v
                                },
                                expression: "name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-12 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Kode",
                              "label-for": "ItemKode",
                              "invalid-feedback": "Kode Harus Diisi!",
                              state: _vm.descState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "ItemKode",
                                state: _vm.descState,
                                required: "",
                              },
                              model: {
                                value: _vm.id,
                                callback: function ($$v) {
                                  _vm.id = $$v
                                },
                                expression: "id",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-12 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Group App",
                              "label-for": "ItemType",
                              "invalid-feedback": "Group App Harus Diisi!",
                              state: _vm.typeState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "ItemType",
                                state: _vm.typeState,
                                required: "",
                              },
                              model: {
                                value: _vm.type,
                                callback: function ($$v) {
                                  _vm.type = $$v
                                },
                                expression: "type",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-12 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Deskripsi",
                              "label-for": "ItemDesc",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "ItemDesc" },
                              model: {
                                value: _vm.desc,
                                callback: function ($$v) {
                                  _vm.desc = $$v
                                },
                                expression: "desc",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                ref: "modal",
                attrs: {
                  id: "modal-addAddress",
                  size: "sm",
                  title: "Tambah Alamat",
                  "hide-header-close": "",
                  "no-close-on-esc": "",
                  "no-close-on-backdrop": "",
                },
                on: { hidden: _vm.resetArray, ok: _vm.handleOkAddress },
              },
              [
                _c(
                  "form",
                  {
                    ref: "form",
                    staticClass: "row",
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.handleSubmitAddress.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-12 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Nama Alamat",
                              "label-for": "addressName",
                              "invalid-feedback": "Nama Alamat Harus Diisi!",
                              state: _vm.provState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "addressName",
                                state: _vm.provState,
                                required: "",
                              },
                              model: {
                                value: _vm.prov,
                                callback: function ($$v) {
                                  _vm.prov = $$v
                                },
                                expression: "prov",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-12 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Lokasi",
                              "label-for": "usahaLokasi",
                              "invalid-feedback": "Lokasi Harus Diisi!",
                              state: _vm.kabState,
                            },
                          },
                          [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.kab,
                                  expression: "kab",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "usahaLokasi",
                                state: _vm.kabState,
                                required: "",
                                rows: "4",
                              },
                              domProps: { value: _vm.kab },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.kab = $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                ref: "modal",
                attrs: {
                  id: "modal-addContact",
                  size: "sm",
                  title: "Tambah Contact Person",
                  "hide-header-close": "",
                  "no-close-on-esc": "",
                  "no-close-on-backdrop": "",
                },
                on: { hidden: _vm.resetArray, ok: _vm.handleOkContact },
              },
              [
                _c(
                  "form",
                  {
                    ref: "form",
                    staticClass: "row",
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.handleSubmitContact.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-12 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Nama Contact Person",
                              "label-for": "contactName",
                              "invalid-feedback":
                                "Nama Contact Person Harus Diisi!",
                              state: _vm.provState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "contactName",
                                state: _vm.provState,
                                required: "",
                              },
                              model: {
                                value: _vm.prov,
                                callback: function ($$v) {
                                  _vm.prov = $$v
                                },
                                expression: "prov",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-12 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Nomor Telepon",
                              "label-for": "usahaTelp",
                              "invalid-feedback": "Nomor Telepon Harus Diisi!",
                              state: _vm.kabState,
                            },
                          },
                          [
                            _c(
                              "b-input-group",
                              { attrs: { prepend: "+62" } },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    type: "number",
                                    id: "usahaTelp",
                                    state: _vm.kabState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.kab,
                                    callback: function ($$v) {
                                      _vm.kab = $$v
                                    },
                                    expression: "kab",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                ref: "modal",
                attrs: {
                  id: "modal-addPayment",
                  size: "md",
                  title: "Tambah Hak Akses",
                  "hide-header-close": "",
                  "no-close-on-esc": "",
                  "no-close-on-backdrop": "",
                },
                on: { hidden: _vm.resetArray, ok: _vm.handleOkPayment },
              },
              [
                _c(
                  "form",
                  {
                    ref: "form",
                    staticClass: "row",
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.handleSubmitPayment.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-7 p-0 d-flex align-items-center" },
                        [
                          _c(
                            "label",
                            {
                              attrs: { for: "allCheck" },
                              on: { click: _vm.handleCheck },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.checkAll,
                                    expression: "checkAll",
                                  },
                                ],
                                staticClass: "form-check-input border-dark",
                                attrs: { id: "allCheck", type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.checkAll)
                                    ? _vm._i(_vm.checkAll, null) > -1
                                    : _vm.checkAll,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.checkAll,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.checkAll = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.checkAll = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.checkAll = $$c
                                    }
                                  },
                                },
                              }),
                              _vm._v(" Check All "),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-5 text-right p-0" },
                        [
                          _c(
                            "b-form-group",
                            { staticClass: "mb-2" },
                            [
                              _c("multiselect", {
                                staticClass: "my-1",
                                attrs: {
                                  options: _vm.optionsGroupApp,
                                  placeholder: "Select one",
                                  multiple: false,
                                  "show-labels": false,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.changeData()
                                  },
                                },
                                model: {
                                  value: _vm.groupApp,
                                  callback: function ($$v) {
                                    _vm.groupApp = $$v
                                  },
                                  expression: "groupApp",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-md-12 p-0" }, [
                        _vm.showPayment
                          ? _c(
                              "table",
                              { staticClass: "table table-bordered" },
                              [
                                _c("thead", {}, [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-center",
                                        attrs: { scope: "col" },
                                      },
                                      [_vm._v("Kode")]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-center",
                                        attrs: { scope: "col" },
                                      },
                                      [_vm._v("Nama")]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-center",
                                        attrs: { scope: "col" },
                                      },
                                      [_vm._v("Create")]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-center",
                                        attrs: { scope: "col" },
                                      },
                                      [_vm._v("Read")]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-center",
                                        attrs: { scope: "col" },
                                      },
                                      [_vm._v("Update")]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-center",
                                        attrs: { scope: "col" },
                                      },
                                      [_vm._v("Delete")]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.arrBlank, function (cok, i) {
                                    return _c("tr", [
                                      _c("td", [
                                        _vm._v(_vm._s(cok.appProcCode)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(cok.appProcName)),
                                      ]),
                                      _c("td", { staticClass: "text-center" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: cok.microAccess.create,
                                              expression:
                                                "cok.microAccess.create",
                                            },
                                          ],
                                          staticClass:
                                            "form-check-input border-dark",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              cok.microAccess.create
                                            )
                                              ? _vm._i(
                                                  cok.microAccess.create,
                                                  null
                                                ) > -1
                                              : cok.microAccess.create,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = cok.microAccess.create,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      cok.microAccess,
                                                      "create",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      cok.microAccess,
                                                      "create",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  cok.microAccess,
                                                  "create",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("td", { staticClass: "text-center" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: cok.microAccess.read,
                                              expression:
                                                "cok.microAccess.read",
                                            },
                                          ],
                                          staticClass:
                                            "form-check-input border-dark",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              cok.microAccess.read
                                            )
                                              ? _vm._i(
                                                  cok.microAccess.read,
                                                  null
                                                ) > -1
                                              : cok.microAccess.read,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = cok.microAccess.read,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      cok.microAccess,
                                                      "read",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      cok.microAccess,
                                                      "read",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  cok.microAccess,
                                                  "read",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("td", { staticClass: "text-center" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: cok.microAccess.update,
                                              expression:
                                                "cok.microAccess.update",
                                            },
                                          ],
                                          staticClass:
                                            "form-check-input border-dark",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              cok.microAccess.update
                                            )
                                              ? _vm._i(
                                                  cok.microAccess.update,
                                                  null
                                                ) > -1
                                              : cok.microAccess.update,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = cok.microAccess.update,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      cok.microAccess,
                                                      "update",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      cok.microAccess,
                                                      "update",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  cok.microAccess,
                                                  "update",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("td", { staticClass: "text-center" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: cok.microAccess.delete,
                                              expression:
                                                "cok.microAccess.delete",
                                            },
                                          ],
                                          staticClass:
                                            "form-check-input border-dark",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              cok.microAccess.delete
                                            )
                                              ? _vm._i(
                                                  cok.microAccess.delete,
                                                  null
                                                ) > -1
                                              : cok.microAccess.delete,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = cok.microAccess.delete,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      cok.microAccess,
                                                      "delete",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      cok.microAccess,
                                                      "delete",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  cok.microAccess,
                                                  "delete",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "modal fade",
                attrs: {
                  id: "exampleModalTopCover",
                  tabindex: "-1",
                  role: "dialog",
                  "aria-labelledby": "exampleModalTopCoverTitle",
                  "aria-hidden": "true",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "modal-dialog modal-dialog-centered",
                    attrs: { role: "document" },
                  },
                  [
                    _c("div", { staticClass: "modal-content" }, [
                      _c(
                        "div",
                        { staticClass: "modal-top-cover bg-dark text-center" },
                        [
                          _c(
                            "figure",
                            {
                              staticClass:
                                "position-absolute end-0 bottom-0 start-0",
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    preserveAspectRatio: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                    x: "0px",
                                    y: "0px",
                                    viewBox: "0 0 1920 100.1",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      fill: "#fff",
                                      d: "M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "modal-close",
                              on: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "esc",
                                      27,
                                      $event.key,
                                      ["Esc", "Escape"]
                                    )
                                  )
                                    return null
                                  return _vm.onEscape.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("button", {
                                staticClass: "btn-close btn-close-light",
                                attrs: {
                                  type: "button",
                                  "data-bs-dismiss": "modal",
                                  "aria-label": "Close",
                                },
                                on: { click: _vm.resetPass },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._m(1),
                      _c(
                        "div",
                        { staticClass: "modal-body text-center" },
                        [
                          _c("h3", { staticClass: "m-0" }, [
                            _vm._v("Ganti Password"),
                          ]),
                          _vm.passData !== null
                            ? [
                                _vm._v(
                                  " (" + _vm._s(_vm.passData.userName) + ") "
                                ),
                              ]
                            : [_vm._v(" () ")],
                          _c("div", { staticClass: "row mt-2" }, [
                            _c("div", { staticClass: "col-12 my-2" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.pass,
                                    expression: "pass",
                                  },
                                ],
                                attrs: {
                                  type: "password",
                                  placeholder: "Masukan Password Lama",
                                },
                                domProps: { value: _vm.pass },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.pass = $event.target.value
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "col-12 my-2" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.newPass,
                                    expression: "newPass",
                                  },
                                ],
                                attrs: {
                                  type: "password",
                                  placeholder: "Masukan Password Baru",
                                },
                                domProps: { value: _vm.newPass },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.newPass = $event.target.value
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ],
                        2
                      ),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-white",
                            attrs: {
                              type: "button",
                              "data-bs-dismiss": "modal",
                            },
                            on: { click: _vm.resetPass },
                          },
                          [_vm._v("Batal")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: {
                              type: "button",
                              "data-bs-dismiss": "modal",
                            },
                            on: { click: _vm.sendData },
                          },
                          [_vm._v("Simpan")]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
            _c(
              "b-row",
              { staticClass: "my-3" },
              [
                _c(
                  "b-col",
                  { staticClass: "my-1", attrs: { sm: "5", md: "6" } },
                  [
                    _c("span", { staticClass: "px-2" }, [_vm._v("Show")]),
                    _c("b-form-select", {
                      attrs: {
                        id: "per-page-select",
                        options: _vm.pageOptions,
                        size: "sm",
                      },
                      model: {
                        value: _vm.perPage,
                        callback: function ($$v) {
                          _vm.perPage = $$v
                        },
                        expression: "perPage",
                      },
                    }),
                    _vm._v(" Data of " + _vm._s(_vm.totalRows) + " Data "),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { staticClass: "my-1", attrs: { sm: "7", md: "6" } },
                  [
                    _c("b-pagination", {
                      staticClass: "my-0",
                      attrs: {
                        "total-rows": _vm.totalRows,
                        "per-page": _vm.perPage,
                        align: "fill",
                        size: "sm",
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4 text-center" }, [
      _c("span", { staticClass: "h2" }, [_vm._v("List Master Staff")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-top-cover-icon" }, [
      _c(
        "span",
        {
          staticClass:
            "icon icon-lg icon-light icon-circle icon-centered shadow-sm",
        },
        [_c("i", { staticClass: "bi-file-lock fs-2" })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }