<template>
   
    <main id="content" role="main" class="main">
      <b-modal ref="modal1" id="modal-1" title="DP" @ok="sendData"  ok-only>
      <div class="form-group">
        <div class="marketing-info">
          <h2>Informasi DP dari Marketing</h2>
          <div class="info-item">
            <strong>DP:</strong> {{ dataModalDP.transDPValue | formatCurrency}}
          </div>
          <div class="info-item">
            <strong>KE:</strong> {{ dataModalDP.transCoaName }}
          </div>
        </div>


        <label for="exampleInputEmail1">Bayar Ke :</label>
        <multiselect
          id="itemConvert"
          v-model="coaValue"
          :options="optionsCoa"
          placeholder="Select"
          :multiple="false"
          :custom-label="customLabel2"
        >
        </multiselect>
        <!-- <small id="emailHelp" class="form-text text-muted"
          >We'll never share your email with anyone else.</small
        > -->
      </div>
      <div class="form-group">
        <label for="nominalDP">Jumlah DP :</label>
        <input
          v-model="dpValue"
          v-format-number
          type="text"
          class="form-control"
          id="nominalDP"
          placeholder="0"
        />
      </div>
      </b-modal>
      <div class="content container-fluid p-3 ps-0 pt-0">
        <div class="row my-1">
          <div class="col-4 d-flex align-items-center">
            <ul class="breadcrumb m-0">
              <li>
                <router-link to="/dashboard">
                  <a href="#"><i class="bi-house-door"></i></a>
                </router-link>
              </li>
              <li>
                <router-link :to="$route.path">
                  <a href="#">ARDP</a>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-4 text-center"><span class="h2">List SO FOR ARDP</span></div>
        </div>
        <hr class="my-2" />
  
        <!-- Content -->
        <template v-if="isDataLoaded">
          <div class="card">
            <div class="card-header bg-light p-2">
              <div class="row d-flex">
                <div class="col-9 row">
                  <div
                    class="d-flex col-12 align-items-center pe-0 mt-2"
                    v-for="(data, i) in filterList"
                  >
                    <select
                      style="font-size: 12px; max-width: 125px"
                      class="form-select form-select-sm py-1 px-2"
                      v-model="data.filterBaris"
                    >
                      <option value="" v-if="!filterBaris">Filter Baris</option>
                      <option
                        v-for="micro in tableHeader"
                        :value="micro.key"
                        v-if="micro.key !== 'actions'"
                      >
                        {{ micro.label }}
                      </option>
                    </select>
                    <select
                      class="mx-2 form-select form-select-sm py-1 px-2"
                      style="font-size: 12px; max-width: 125px"
                      v-model="data.startFrom"
                    >
                      <option value="" v-if="!data.startFrom">Tipe Pencarian</option>
                      <option :value="micro" v-for="micro in optionQuery">
                        {{ micro }}
                      </option>
                    </select>
                    <div class="input-group" style="width: 150px">
                      <div class="input-group-prepend">
                        <span class="input-group-text py-1 px-2"
                          ><i class="bi-search" style="font-size: 10px"></i
                        ></span>
                      </div>
                      <input
                        type="search"
                        class="form-control form-control-sm p-1"
                        placeholder="Cari Data..."
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        style="font-size: 12px"
                        v-model="data.filter1"
                      />
                    </div>
                    <div class="input-group px-2" style="width: 150px">
                      <div class="input-group-prepend">
                        <span class="input-group-text py-1 px-2"
                          ><i class="bi-search" style="font-size: 10px"></i
                        ></span>
                      </div>
                      <input
                        type="search"
                        class="form-control form-control-sm p-1"
                        placeholder="Cari Data..."
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        style="font-size: 12px"
                        v-model="data.filter2"
                      />
                    </div>
                    <select
                      class="mx-2 form-select form-select-sm py-1"
                      style="font-size: 12px; max-width: 65px"
                      v-model="data.logic"
                    >
                      <option value="and">dan</option>
                      <option value="or">atau</option>
                    </select>
                    <i
                      class="bi-plus-circle text-primary ms-1"
                      style="cursor: pointer"
                      @click="addFilterList"
                    ></i>
                    <i
                      class="bi-x-circle text-danger ms-1"
                      style="cursor: pointer"
                      @click="removeFilterRow(i)"
                    ></i>
                  </div>
                </div>
  
                <div
                  class="col-3 d-flex justify-content-between mt-2 align-items-start p-0"
                  style="z-index: 2"
                >
                  <button type="button" class="btn-xs btn btn-outline-success">Cari</button>
                  <!-- <button type="button" class="btn-xs btn btn-outline-warning">
                    Reset
                  </button> -->
                  <!-- <div class="btn-group me-2">
                    <router-link :to="$route.path + '/add'" class="btn btn-xs btn-primary">
                      Tambah
                    </router-link>
                    <button
                      type="button"
                      class="btn btn-xs btn-primary dropdown-toggle dropdown-toggle-split"
                      href="javascript:;"
                      id="accountNavbarDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-dropdown-animation
                    ></button>
                    <div class="dropdown">
                      <div
                        class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                        aria-labelledby="accountNavbarDropdown"
                      >
                        <div class="flex-grow-1 ms-3">
                          <p class="card-text text-body" style="font-size: 12px">
                            Import file
                          </p>
                        </div>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#"> json</a>
                        <a class="dropdown-item" href="#">xls</a>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="card-body p-2">
              <div class="d-flex align-items-center justify-content-between">
                <div
                  style="font-size: 11px; width: 300px"
                  class="d-flex align-items-center justify-content-between"
                >
                  Menampilkan 1 - 10 data dari {{ rows }}
                  <select
                    class="form-select py-1"
                    aria-label="Default select example"
                    style="width: 75px; font-size: 12px; padding: 0 0.5rem !important"
                  >
                    <option v-for="data in pageList" :value="data">
                      {{ data }}
                    </option>
                  </select>
                </div>
                <b-pagination
                  class="m-0"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  ><template #first-text
                    ><button class="btn btn-outline-primary btn-xs">First</button></template
                  >
                  <template #prev-text
                    ><button class="btn btn-outline-primary btn-xs">Prev</button></template
                  >
                  <template #next-text
                    ><button class="btn btn-outline-primary btn-xs">Next</button></template
                  >
                  <template #last-text
                    ><button class="btn btn-outline-primary btn-xs">Last</button></template
                  >
                  <template #ellipsis-text> ... </template>
                  <template #page="{ page, active }">
                    <b v-if="active">{{ page }}</b>
                    <i v-else>{{ page }}</i>
                  </template></b-pagination
                >
              </div>
              <hr class="my-2" />
              <!-- tabel view untuk detil -->
              <b-table
                striped
                hover
                :items="dataListPO"
                :fields="tableHeader"
                label-sort-asc=""
                label-sort-desc=""
                class="myTable listTable"
              >
                <template #cell(PoSupplier)="data">
                  {{ data.item.PoSupplier.userName }}
                </template>
                <template #cell(transIdMember)="data">
                  <template v-if="data.item.transIdMember">
                    {{ data.item.transShipTo[0].location }}
                  </template>
                </template>
                <template #cell(transPesanan)="data">
                  <div class="inner-table">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Nama Barang</th>
                          <th width="50">Qty</th>
                          <th width="80">Harga</th>
                          <th width="100">Total</th>
                          <!-- Tambahkan kolom lain di sini sesuai kebutuhan -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in data.item.transPesanan" :key="index">
                          <td>{{ item.itemName }}</td>
                          <td>{{ item.itemQty }}</td>
                          <td v-if="item.itemMasterPrice[0]">
                            {{ formatNumberInt(item.itemMasterPrice[0].priceValue) }}
                          </td>
                          <td v-if="item.itemMasterPrice[0] && item.itemQty">
                            {{
                              formatNumberInt(
                                item.itemMasterPrice[0].priceValue * item.itemQty
                              )
                            }}
                          </td>
                          <!-- Tambahkan kolom lain di sini sesuai kebutuhan -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
                <template v-slot:cell(actions)="data">
                  <b-dropdown id="dropdown-1" right size="xs" variant="primary">
                    <template #button-content>
                      <i class="bi-gear"></i>
                    </template>
                    <b-dropdown-item @click="openModal(data.item)"
                      >Konfirmasi DP</b-dropdown-item
                    >
                  </b-dropdown>
                </template>
              </b-table>
            </div>
          </div>
        </template>
        <div v-else>Loading...</div>
      </div>
    </main>
  </template>
  
  <script>
  // import router from "@/router";
  import { getSOManufacture, getSOARDP,getCoa, postPaymentCredit ,postPaymentDP} from "../components/services/components";
  import {
  alertLoading,
  successAddData,
  errorAddData,
} from "../components/function/sweetalert";
  import { formatNumberInt ,removeThousandFormatter} from "../components/function/number";
  import { formatIndDate } from "../components/function/date";



  export default {
    components: {},
    data() {
      return {
        dataModalDP:"",
        optionsCoa: [],
        coaValue: "",
        dpValue: "",
        // pagination
        rows: 3,
        perPage: 10,
        currentPage: 1,
        optionQuery: ["start at", "equal", "include"],
        pageList: ["10", "25", "50", "Semua"],
        // ===
        filterList: [],
        filterText: "",
        tableHeader: [],
        dataListPO: [],
        isDataLoaded: false,
      };
    },
      filters: {
      formatCurrency(value) {
        // // Format value as currency in Indonesian Rupiah (IDR)
        // return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value);
          // Format value as currency in Indonesian Rupiah (IDR) and remove trailing zeros
      const formattedValue = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value);
      return formattedValue.replace(/(?:\,00|(\.\d+?)0+)$/, '$1');
      }
    },
    async mounted() {
      const coa = await getCoa();
      this.optionsCoa = coa.coaCashBank;
      this.addFilterList();
      (this.tableHeader = [
        {
          key: "transId",
          label: "SO Number",
          sortable: true,
          class: "align-top",
          thClass: "text-center align-middle",
        },
        {
          key: "transName",
          label: "Customer",
          sortable: true,
          class: "align-top",
          thClass: "text-center align-middle",
        },
        {
          key: "transKasir.userName",
          label: "Sales",
          sortable: true,
          class: "align-top",
          thClass: "text-center align-middle",
        },
        {
          key: "transPesanan",
          label: "Item",
          sortable: true,
          class: "align-top",
          thClass: "text-center align-middle",
        },
        {
          key: "transDate",
          label: "Tgl. Dibuat",
          sortable: true,
          class: "align-top",
          thClass: "text-center align-middle",
          thStyle: "width: 75px",
        },
        {
          key: "transShipmentPlanDate",
          label: "Tgl. Kirim",
          sortable: true,
          class: "align-top",
          thClass: "text-center align-middle",
          thStyle: "width: 75px",
        },
        {
          key: "transStatusSO",
          label: "Status",
          sortable: true,
          class: "align-top",
          thClass: "text-center align-middle",
        },
        {
          key: "actions",
          label: "Actions",
          class: "align-top",
          thClass: "text-center align-middle",
        },
      ]),
        (this.dataListPO = await getSOARDP("no"));
      console.log("coba", this.dataListPO);
      this.isDataLoaded = true;
    },
    computed: {
      filteredTableData() {
        const filterText = this.filterText.toLowerCase().trim();
        return this.data.filter((row) => {
          for (let key in row) {
            const value = String(row[key]);
            if (value.toLowerCase().includes(filterText)) {
              return true;
            }
          }
          return false;
        });
      },
    },
    methods: {
      
      sendData(event) {
        console.log("MASUK SINI KIRIM DATA")
      event.preventDefault();
      if (this.dpValue) {
        this.$swal({
          title: "Anda Yakin?",
          text: "Tekan Confirm jika anda sudah yakin!",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#00AB8E",
          confirmButtonText: "Confirm",
        }).then(async (result) => {
          if (result.isConfirmed) {
            alertLoading();
            try {
              const dateObj = new Date();
                const year = dateObj.getFullYear();
                const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Tambahkan '0' di depan jika bulan hanya satu digit
                const day = String(dateObj.getDate()).padStart(2, '0'); // Tambahkan '0' di depan jika tanggal hanya satu digit
                const currentDate = `${year}-${month}-${day}`;

              const data = {
                transDPValue : removeThousandFormatter(this.dpValue),
                transCoaName : this.coaValue.coaName,
                transCoaCode : this.coaValue.coaCode,
                transPesanan : this.dataModalDP.transPesanan,
                transId : this.dataModalDP.transId,
                transPPN : this.dataModalDP.transPPN,
                transUser: localStorage.getItem("_id"),
                transCustNo : this.dataModalDP.transIdMember,
                transEffDate: currentDate,
                transNote: ""
              }
              
              console.log("ARDP POST", JSON.stringify(data))
              await postPaymentDP(data,this.dataModalDP._id);
              successAddData();
              this.refreshPage();
              // this.$router.push({ name: "/transaksi/ardp" });
            } catch (error) {
              console.log("ASTAGA " , error)
              console.log("ASTAGA " ,JSON.stringify(error))
              errorAddData();
            }
          } else {
            $("#exampleModalTopCover").modal("show");
          }
        });
      }else {
        alert("ada data wajib yang masih kosong!");
      }
    },
    refreshPage() {
  // Reload the page
      location.reload();
    },
    openModal(data) {
      console.log("DATA MODAL ",JSON.stringify(data))
      this.dataModalDP = data
      // this.selectedCoa = coaValue;
      // this.selectedDPValue = dpValue;
      this.$refs.modal1.show(); // Show the modal
    },
      customLabel2(option) {
      return `${option.coaName} (${option.coaCode})`;
      // return option;
    },
      formatNumberInt(data) {
        return formatNumberInt(data);
      },
      formatIndDate(data) {
        return formatIndDate(data);
      },
      addFilterList() {
        const data = {
          filterBaris: "",
          startFrom: "",
          filter1: "",
          logic: "",
          filter2: "",
        };
        this.filterList.push(data);
      },
  
      removeFilterRow(data) {
        this.filterList.splice(data, 1);
      },
      detilAction(sonum) {
        // alert(sonum);
        this.$router.push({ name: this.$route.name + "-detail", params: { noSO: sonum } });
      },
      
    },
  };
  </script>
  <style scope>
  .marketing-info {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
}

.info-item {
  margin-bottom: 10px;
}
  </style>
  