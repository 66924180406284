<template>
  <div>
    <div class="data-pasien px-3">
      <div class="row">
        <div class="col-6 border py-1"><b>Nama : </b> {{ fixData.userName }}</div>
        <div class="col-6 border py-1"><b>No.Telp : </b> {{ fixData.userPhone }}</div>
        <div class="col-6 border py-1"><b>Tgl. Lahir : </b> {{ fixData.userBirth }}</div>
        <div class="col-6 border py-1"><b>NIK : </b> {{ fixData.userNIK }}</div>
        <div class="col-6 border py-1">
          <b>Jenis Kelamin : </b> {{ fixData.userGender }}
        </div>
        <div class="col-6 border py-1"><b>Agama : </b> {{ fixData.userReligion }}</div>
        <div class="col-6 border py-1"><b>Pekerjaan : </b> {{ fixData.userJob }}</div>
        <div class="col-6 border py-1">
          <b>Pendidikan : </b> {{ fixData.userEducation }}
        </div>
        <div class="col-12 border py-1">
          <b>Alamat : </b> {{ fixData.userAddress[0].location }}
        </div>
      </div>
    </div>
    <div class="header d-flex align-items-center justify-content-center">
      <b>Data Pemeriksaan (SOAP)</b>
    </div>
    <div class="data-soap">
      <div v-for="data in fixDataRM.woReceiptTemp" class="border p-2">
        <p>
          <b>Proses {{ data.woSoap }}</b>
        </p>
        <div v-html="data.woTextArea.replace(/\/n/g, '<br>')"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatIndDate } from "../function/date";
export default {
  props: {
    data: Array,
    dataRM: Array,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    fixData() {
      return this.data;
    },
    fixDataRM() {
      return this.dataRM;
    },
  },
  methods: {
    formatIndDate(date) {
      return formatIndDate(date);
    },
  },
};
</script>

<style scoped></style>
