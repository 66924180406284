<template>
  <main id="content" role="main" class="main">
    <div class="content container-fluid p-3 ps-0 pt-0" ref="contentDiv">
      <ul class="breadcrumb m-1 mt-0">
        <li>
          <a href="#"><i class="bi-house-door"></i></a>
        </li>
        <li>Laporan</li>
        <li>Inventory By Location</li>
      </ul>
      <!-- Content -->
      <tableReportInventoryByLocation :header="header" :data="data" />
    </div>
  </main>
</template>

<script>
import { get1, get0, getCoa } from "../components/services/components";
import tableReportInventoryByLocation from "../components/tableReportInventoryByLocation.vue";
export default {
  components: {
    tableReportInventoryByLocation,
  },
  data() {
    return {
      filterText: "",
      header: [],
      sendData: {},
      transData: [],
      contentDivWidth: 0,
      routing: "",
      tableFields: [],
      component: [],
      hotSettings: {
        licenseKey: "non-commercial-and-evaluation",
        colHeaders: true,

        rowHeaderWidth: 35,
        startRows: 10,
        readOnly: true,
        columns: [],
      },
      data: [],
      isDataLoaded: false,
      posts: [],
      coaList: [],
    };
  },
  async mounted() {
    // console.log(this.$route.name.replace("report-", ""));

    // const currentDate = new Date();
    // const year = currentDate.getFullYear();
    // let month = currentDate.getMonth() + 1;
    // if (month < 10) {
    //   month = '0'+month
    // }
    this.coaList = await getCoa();

    const transData = (await get0("kasbank")).masterData;
    this.posts.transData = transData;
    this.routing = this.posts.transRouting;
    this.data = this.coaList.dataList;
  },
  computed: {},
  methods: {},
};
</script>
