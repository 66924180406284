var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report", staticStyle: { "font-size": "10px" } },
    [
      _c(
        "table",
        {
          staticClass: "table table-bordered table-hover z-1 position-relative",
        },
        [
          _vm._m(0),
          _vm.realData.length > 0
            ? _c(
                "tbody",
                _vm._l(_vm.realData, function (entry) {
                  return _c("tr", [
                    _c("td", [_vm._v(_vm._s(entry.itemCode))]),
                    _c("td", [_vm._v(_vm._s(entry.itemName))]),
                    _c(
                      "td",
                      _vm._l(entry.itemGroup, function (group) {
                        return _c("span", [_vm._v(_vm._s(group) + ",")])
                      }),
                      0
                    ),
                    _c("td", [_vm._v(_vm._s(entry.itemInvStock.invUM))]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(entry.itemInvStock.invBegining)),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(entry.itemInvStock.invBegining)),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(entry.itemInvStock.invIn)),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(entry.itemInvStock.invIn)),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(entry.itemInvStock.invOut)),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(entry.itemInvStock.invOut)),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(entry.itemInvStock.invOut)),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(entry.itemInvStock.invEnd)),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(entry.itemInvStock.invEnd)),
                    ]),
                  ])
                }),
                0
              )
            : _c("tbody", [_vm._m(1)]),
          _vm._m(2),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "sticky-element" }, [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "align-middle text-center",
            attrs: { scope: "col", rowspan: "2", width: "50" },
          },
          [_c("b", [_vm._v("Kode Item")])]
        ),
        _c(
          "th",
          {
            staticClass: "align-middle text-center",
            attrs: { scope: "col", rowspan: "2" },
          },
          [_c("b", [_vm._v("Nama Item")])]
        ),
        _c(
          "th",
          {
            staticClass: "align-middle text-center",
            attrs: { scope: "col", rowspan: "2" },
          },
          [_c("b", [_vm._v("Grup Item")])]
        ),
        _c(
          "th",
          {
            staticClass: "align-middle text-center",
            attrs: { scope: "col", rowspan: "2" },
          },
          [_c("b", [_vm._v("Item UM")])]
        ),
        _c(
          "th",
          {
            staticClass: "align-middle text-center",
            attrs: { scope: "col", colspan: "2", width: "50" },
          },
          [_c("b", [_vm._v("Stok Awal")])]
        ),
        _c(
          "th",
          {
            staticClass: "align-middle text-center",
            attrs: { scope: "col", colspan: "2", width: "50" },
          },
          [_c("b", [_vm._v("Masuk")])]
        ),
        _c(
          "th",
          {
            staticClass: "align-middle text-center",
            attrs: { scope: "col", colspan: "2", width: "50" },
          },
          [_c("b", [_vm._v("Keluar")])]
        ),
        _c(
          "th",
          {
            staticClass: "align-middle text-center",
            attrs: { scope: "col", rowspan: "2", width: "50" },
          },
          [_c("b", [_vm._v("Unit Cost")])]
        ),
        _c(
          "th",
          {
            staticClass: "align-middle text-center",
            attrs: { scope: "col", colspan: "2", width: "50" },
          },
          [_c("b", [_vm._v("Stok Akhir")])]
        ),
      ]),
      _c("tr", [
        _c(
          "th",
          { staticClass: "align-middle text-center", attrs: { width: "40" } },
          [_c("b", [_vm._v("Qty")])]
        ),
        _c(
          "th",
          { staticClass: "align-middle text-center", attrs: { width: "40" } },
          [_c("b", [_vm._v("Nilai")])]
        ),
        _c(
          "th",
          { staticClass: "align-middle text-center", attrs: { width: "40" } },
          [_c("b", [_vm._v("Qty")])]
        ),
        _c(
          "th",
          { staticClass: "align-middle text-center", attrs: { width: "40" } },
          [_c("b", [_vm._v("Nilai")])]
        ),
        _c(
          "th",
          { staticClass: "align-middle text-center", attrs: { width: "40" } },
          [_c("b", [_vm._v("Qty")])]
        ),
        _c(
          "th",
          { staticClass: "align-middle text-center", attrs: { width: "40" } },
          [_c("b", [_vm._v("Nilai")])]
        ),
        _c(
          "th",
          { staticClass: "align-middle text-center", attrs: { width: "40" } },
          [_c("b", [_vm._v("Qty")])]
        ),
        _c(
          "th",
          { staticClass: "align-middle text-center", attrs: { width: "40" } },
          [_c("b", [_vm._v("Nilai")])]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("Data Tidak Ditemukan")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tfoot", [
      _c("tr", [
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "50" } },
          [_c("b", [_vm._v("Kode Item")])]
        ),
        _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
          _c("b", [_vm._v("Nama Item")]),
        ]),
        _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
          _c("b", [_vm._v("Grup Item")]),
        ]),
        _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
          _c("b", [_vm._v("Item UM")]),
        ]),
        _c(
          "th",
          {
            staticClass: "text-center",
            attrs: { scope: "col", width: "100", colspan: "2" },
          },
          [_c("b", [_vm._v("STOK AWAL")])]
        ),
        _c(
          "th",
          {
            staticClass: "text-center",
            attrs: { scope: "col", width: "100", colspan: "2" },
          },
          [_c("b", [_vm._v("MASUK")])]
        ),
        _c(
          "th",
          {
            staticClass: "text-center",
            attrs: { scope: "col", width: "100", colspan: "2" },
          },
          [_c("b", [_vm._v("KELUAR")])]
        ),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "50" } },
          [_c("b", [_vm._v("UNIT COST")])]
        ),
        _c(
          "th",
          {
            staticClass: "text-center",
            attrs: { scope: "col", width: "100", colspan: "2" },
          },
          [_c("b", [_vm._v("STOK AKHIR")])]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }