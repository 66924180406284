<template>
  <main id="content" role="main" class="main">
    <form class="content container-fluid p-3 ps-0 py-0" ref="contentDiv" @submit="sendData">
      <!-- Header -->
      <div class="row my-1">
        <div class="col-4 d-flex align-items-center">
          <ul class="breadcrumb m-0">
            <li>
              <router-link to="/dashboard">
                <a href="#"><i class="bi-house-door"></i></a>
              </router-link>
            </li>
            <li>
              <router-link :to="$route.path.replace('/add', '')">
                <a href="#">Sales Invoice</a>
              </router-link>
            </li>
            <li>Tambah</li>
          </ul>
        </div>
        <div class="col-4 text-center"><span class="h2">Tambah Sales Invoice</span></div>
        <div class="col-1 d-flex justify-content-end align-items-center">
          <div class="form-check form-switch d-flex align-items-center">
            <input type="checkbox" class="form-check-input" id="formSwitch1" v-model="checkedPajak"
              style="height: 17.5px; margin-top: 1px;" disabled>
            <label class="form-check-label" for="formSwitch1">Pajak</label>
          </div>
        </div>
        <div class="col-3 d-flex justify-content-end align-items-center">
          <router-link :to="$route.path.replace('/add', '')">
            <button class="btn btn-outline-secondary btn-xs py-1 me-2" type="button">
              <i class="bi-chevron-left"></i>Kembali</button>
          </router-link>
          <button type="submit" class="btn btn-primary btn-xs py-1">Simpan</button>
        </div>
      </div>


      <!-- End of Header -->

      <!-- Main Data -->
      <div class="main-data mt-4">
        <div class="row scale-9 position-relative" style="width: 114%; z-index: 1;">
          <!-- First Header -->
          <div class="col-6">
            <div class="card h-100">
              <div class="row px-3 py-1">
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between ">*Supplier<span>:</span></label>
                  <div class="col-sm-8 col-md-9 pe-0">
                    <multiselect @input="changeKode(transSupplier)" class="my-1" v-model="transSupplier"
                      :options="listSupplier" placeholder="Select one" :multiple="false" :show-labels="false" required
                      :custom-label="customLabel" track-by="_id">
                    </multiselect>
                  </div>
                </div>
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between ">Credit Terms <span>:</span></label>
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input v-model="transCreditTerms" size="sm" class="form-control-sm py-0 form-control" required
                      placeholder="Credit Terms" disabled></b-form-input>
                  </div>
                </div>
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between ">*No. Faktur <span>:</span></label>
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input v-model="transNoFaktur" size="sm" type="text" class="form-control-sm py-0 form-control"
                      required placeholder="Masukan Nomor Referensi"></b-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End of First Header -->

          <!-- Secondx Header -->
          <div class="col-6">
            <div class="card h-100">
              <div class="row px-3 py-1">
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between ">*Kode Invoice<span>:</span></label>
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input v-model="transInvNo" disabled size="sm" class="form-control-sm py-0 form-control"
                      placeholder="Kode Purchase Invoice"></b-form-input>
                  </div>
                </div>
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between ">*Invoice Date <span>:</span></label>
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input v-model="transInvDate" size="sm" type="date" class="form-control-sm py-0 form-control"
                      required placeholder="Masukan Tanggal Transaksi"></b-form-input>
                  </div>
                </div>
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between ">*Due Date <span>:</span></label>
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input v-model="transDueDate" size="sm" type="date" class="form-control-sm py-0 form-control"
                      required placeholder="Masukan Tanggal Transaksi"></b-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End of Secondx Header -->
        </div>

        <div class="row my-3">
          <!-- Hot Table -->
          <div class=" col-12">
            <div class="hot-container col-md-12 position-relative" style="overflow-x: auto; z-index: 0">
              <hot-table ref="hot" :settings="hotSettings" :data="fixExcelData" :rowHeaders="true"></hot-table>
            </div>
          </div>
          <!-- End of Hot Table -->
        </div>

        <!-- Footer -->
        <div class="row mb-3 d-flex justify-content-between">
          <div class="col-md-6 scale-9">
            <div class="h-100">
              <div class="card h-100">
                <div class="card-header bg-light py-1">Catatan</div>
                <textarea class="form-control form-control-sm py-1 h-100" style="resize: none"
                  placeholder="Masukan Catatan" v-model="transNotes"></textarea>
              </div>
            </div>

          </div>
          <div class="col-md-4 row p-0 scale-9" style="transform-origin: right top">
            <div class="row d-flex justify-content-end">
              <template v-if="checkedPajak">
                <div class="border border-light bg-light d-flex align-items-center justify-content-between col-5">Sub
                  Total
                  <span>:</span>
                </div>
                <div
                  class="p-0 border border-light bg-light pt-1 d-flex align-items-center justify-content-between col-7 pe-2">
                  <input type="text" :value="formatNumberInt(totalPrice)" class="form-control form-control-sm py-1"
                    style="text-align: right" disabled>
                </div>
                <div class="border border-light bg-light d-flex align-items-center justify-content-between col-5">PPN
                  (Rp.)
                  <span>:</span>
                </div>
                <div
                  class="p-0 border border-light bg-light pt-1 d-flex align-items-center justify-content-between col-7 pe-2">
                  <input type="text" :value="formatNumberInt(totalPPN)" class="form-control form-control-sm py-1"
                    style="text-align: right" disabled>
                </div>
              </template>
              <div class="border border-light bg-light d-flex align-items-center justify-content-between col-5">Grand
                Total
                <span>:</span>
              </div>
              <div
                class="p-0 border border-light bg-light pt-1 col-7 d-flex align-items-center justify-content-between col-5 pe-2">
                <input @input="refreshMethods()" type="text" :value="formatNumberInt(totalAmount)"
                  class="form-control form-control-sm py-1" style="text-align: right" disabled>
              </div>
            </div>
          </div>
        </div>
        <!-- End of Footer -->
      </div>

      <b-modal v-model="modalOpen" title="List Good Receipt (PO Receipt)" @ok="saveModalData" @hide="closeModal"
        :ok-disabled="modalInput.length < 1">
        <!-- Modal content here, e.g., input fields to collect data -->
        <b-form-group label="Pilih GR :" label-cols-sm="4" label-cols-lg="3" content-cols-sm="8" content-cols-lg="9"
          invalid-feedback="Condition Harus Diisi!">
          <multiselect class="my-1" v-model="modalInput" :options="filterGR(optionsInvoice)" placeholder="Pilih PO"
            :multiple="false" :show-labels="false"   :custom-label="customLabelKode">
          </multiselect>
        </b-form-group>
        <div class="col-12" v-if="modalInput.transHysNo">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Nama</th>
                <th scope="col">Qty</th>
                <th scope="col">Harga</th>
                <th scope="col">Due Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in modalInput.transHysItem">
                <td>{{ data.itemHysName }}</td>
                <td>{{ data.itemHysQtyRcp }}</td>
                <td>Rp. {{ formatNumberInt(data.itemHysPrice) }}</td>
                <td>{{ data.itemHysEffDate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-modal>
      <!-- End of Main Data -->
    </form>
  </main>
</template>

<script>

import { getCoa, getJEVoucher, addPI, getP, getK } from '../../components/services/components'
import { getFullDate } from '../../components/function/date'
import { formatNumberInt, removeThousandFormatter } from '../../components/function/number'
import { alertLoading, successAddData, errorAddData } from '../../components/function/sweetalert'
export default {
  data() {
    return {
      suppid: '',
      transNoFaktur: '',
      checkedPajak: false,
      transPayment: '',
      optionsInvoice: [],
      currentDate: '',
      dataItem: [],
      poList: [],
      locationList: [],
      modalOpen: false,
      modalInput: [],
      modalRow: null,
      modalCol: null,
      // Main Data
      transInvNo: '',
      transCreditTerms: '',
      transNo: '',
      transInvDate: '',
      transDueDate: '',
      itemHysQtyRcp: '',
      transNotes: '',
      transSupplier: '',
      transPaymentType: 'Cash',

      subTotal: '',
      prList: [],
      porList: [],
      listSupplier: [],
      // HOT Data   
      contentDivWidth: '',
      colHeader: ['*Kode', '*Kode Item', '*Nama Item', '*UoM', '*Qty.', '*Harga', 'Diskon', 'Total'],
      colBody: [
        {
          data: 'transHysNo', type: 'text',
          renderer: this.prRenderer,
          width: 130
        },
        {
          data: 'itemHysId', type: 'text',
          renderer: this.textRenderer,
          width: 130
        },
        {
          data: 'itemHysName', type: 'text',
          renderer: this.textRenderer,
        },
        {
          data: 'itemHysUm', type: 'text',
          renderer: this.textRenderer,
          width: 75
        },
        {
          data: 'itemHysQtyRcp', type: 'text',
          renderer: this.textRenderer,
          width: 50
        },
        {
          data: 'itemHysPrice', type: 'numeric',
          renderer: this.numberRenderer,
          validator: this.validateNumericValue,
          numericFormat: {
            pattern: '0,0',
          },
          width: 125
        },
        {
          data: 'transDiskon', type: 'numeric',
          renderer: this.numberRenderer,
          validator: this.validateNumericValue,
          numericFormat: {
            pattern: '0,0',
          },
          width: 125
        },
        {
          data: 'transTotal', type: 'numeric',
          renderer: this.numberRenderer,
          validator: this.validateNumericValue,
          numericFormat: {
            pattern: '0,0',
          },
          width: 125
        },
      ],
      excelData: [],
      hotSettings: {
        readOnly: true,
        fixedRowsBottom: 0,
        afterChange: this.onChange,
        beforeKeyDown: this.onKeyDown1,
        licenseKey: 'non-commercial-and-evaluation',

        height: 250,
        colHeaders: [],

        rowHeaderWidth: 35,
        startRows: 10,
        columns: [],
      },
    }
  },
  async mounted() {
    this.listSupplier = await getP()
    const currentDate = await getFullDate()
    const tes = await getK()
    this.prList = tes.filter(x => x.PrItemDetails.length > 0)
    this.porList = []
    this.coa = await getCoa()
    this.transNo = await getJEVoucher()
    this.transInvDate = currentDate
    this.transDueDate = currentDate
    this.hotSettings.colHeaders = this.colHeader
    this.hotSettings.columns = this.colBody
    this.hotSettings.columns.find(x => x.data === 'itemHysId').source = this.coa.coaNonBank.map(x => x.coaCode)
    this.hotSettings.columns.find(x => x.data === 'itemHysName').source = this.coa.coaNonBank.map(x => x.coaName)
    const dataObject = {}
    this.colBody.forEach(item => {
      dataObject[item.data] = '';
    });
    console.log(dataObject)
    for (let i = 0; i < 1000; i++) {
      const key = `row_${i}`;
      const objectAdd = { ...dataObject, key }
      this.excelData.push(objectAdd)
    }
    this.excelData = [...this.excelData]

    this.getContentWidth()
  },
  computed: {
    fixExcelData() {
      return this.excelData

    },
    totalKredit() {
      const tes = this.excelData.filter(x => x.Kredit !== '').map(x => x.Kredit)
      if (tes.length > 0) {
        const sum = tes.reduce((accumulator, currentValue) => {

          const numericValue = parseFloat(currentValue);
          if (!isNaN(numericValue)) {
            return accumulator + numericValue;
          } else {
            return accumulator;
          }
        }, 0);
        return sum
      } else {
        return 0
      }
    },
    totalDebet() {
      const tes = this.excelData.filter(x => x.Debet !== '').map(x => x.Debet)
      if (tes.length > 0) {
        const sum = tes.reduce((accumulator, currentValue) => {
          // Convert the current value to a number using parseFloat
          const numericValue = parseFloat(currentValue);

          // Check if the conversion is successful (not NaN) before adding to the accumulator
          if (!isNaN(numericValue)) {
            return accumulator + numericValue;
          } else {
            return accumulator; // Ignore non-numeric values
          }
        }, 0); // Start with an initial accumulator value of 0
        return sum
      } else {
        return 0
      }
    },
    totalPrice() {
      const tes = this.excelData.filter(x => x.transTotal !== '').map(x => x.transTotal)
      if (tes.length > 0) {
        const sum = tes.reduce((accumulator, currentValue) => {
          const numericValue = parseFloat(currentValue);

          if (!isNaN(numericValue)) {
            return accumulator + numericValue;
          } else {
            return accumulator;
          }
        }, 0);
        return sum
      } else {
        return 0
      }
    },
    totalPPN() {
      return this.totalPrice * 11 / 100
    },
    totalAmount() {
      if (this.checkedPajak) {
        return this.totalPrice + this.totalPPN
      } else {
        return this.totalPrice
      }
    }
  },
  methods: {
    filterGR(data) {
      console.log(data)
      const thisRow = this.excelData[this.modalRow]
      let cok = this.excelData.filter(x => x.transHysNo !== '').map(x => x.transHysNo.transHysNo)

      let finalData = data.filter(x => !cok.includes(x.transHysNo))

      if (thisRow !== '' && thisRow !== undefined) {
        if (thisRow.transHysNo !== '') {
          
          console.log('data', thisRow)
        }
      }
      return finalData
    },
    optionFilter(data) {
      if (data) {
        const tes = this.excelData.filter(x => x.itemHysId !== '').map(x => x.itemHysId)
        // data.PoItemDetails =  x.PoItemDetails.filter((obj) => !tes.includes(obj._id))
        return data.filter(x => {
          console.log(x)
          console.log(tes)
          const matchingCode = tes.find((codeObj) => codeObj._id === x._id);
          return !matchingCode
        })
      }
    },
    openModal(data, row) {
      console.log(data)
      if (data.transHysNo !== '') {
        this.modalInput = data.transHysNo
        this.dataItem = data.itemCode
      }
      console.log(data, row)
      this.modalOpen = true;
      this.modalRow = row;
    },
    saveModalData() {
      const finalData = this.modalInput.transHysItem.map(x => ({
        transHysNo: this.modalInput,
        itemHysId: x.itemHysId,
        itemHysName: x.itemHysName,
        itemHysUm: x.itemHysUm,
        itemHysQtyRcp: x.itemHysQtyRcp,
        itemHysPrice: x.itemHysPrice,
        transDiskon: '',
        transTotal: parseFloat(x.itemHysPrice) * parseFloat(x.itemHysQtyRcp)
      }))
      finalData.forEach((obj, index) => {
        const array1Index = this.modalRow + index;
        if (array1Index < this.excelData.length) {
          this.excelData[array1Index] = obj;
        }
      });
      console.log(finalData)
      this.excelData = [...this.excelData]
      console.log('hasil', this.excelData)
    },
    closeModal() {
      this.modalInput = []
      this.modalRow = null;
      this.dataItem = []
      this.modalOpen = false;
    },
    prRenderer(instance, td, row, col, prop, value, cellProperties) {
      const rowData = this.excelData[row];
      let dataDiv = ''
      let classDiv = 'd-flex align-items-center justify-content-between'
      if (rowData.transHysNo === '' || rowData.transHysNo === null) {
        dataDiv = null
        classDiv = 'd-flex align-items-center justify-content-between flex-row-reverse'
      } else {
        dataDiv = rowData.transHysNo.transHysNo
      }

      // Clear the cell content
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }

      // Create the buttons container element
      const buttonsContainer = document.createElement('div');
      buttonsContainer.className = classDiv
      buttonsContainer.innerHTML = dataDiv
      // Create the first button
      const button1 = document.createElement('button');
      button1.innerHTML = '<i class="bi-eye"></i>';
      button1.className = 'btn btn-xs btn-outline-secondary px-1 py-0 rounded-circle'
      button1.type = 'button'
      button1.addEventListener('click', () => {
        // Handle button 1 click event
        // this.handleButtonClick(rowData, 1);
        this.openModal(rowData, row)
      });
      buttonsContainer.appendChild(button1);

      // Append the buttons container to the cell
      td.appendChild(buttonsContainer);

      // Add necessary CSS classes
      td.classList.add('htCenter'); // Adjust the class based on your styling needs
    },
    textRenderer(instance, td, row, col, prop, value, cellProperties) {
      const rowData = this.excelData[row];
      let dataDiv = ''
      let classDiv = 'd-flex align-items-center justify-content-between'
      if (rowData.transHysNo === '' || rowData.transHysNo === null) {
        dataDiv = null
      } else {
        dataDiv = rowData[prop]
      }

      // Clear the cell content
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }

      // Create the buttons container element
      const buttonsContainer = document.createElement('div');
      buttonsContainer.className = classDiv
      buttonsContainer.innerHTML = dataDiv
      // Create the first button

      // Append the buttons container to the cell
      td.appendChild(buttonsContainer);

      // Add necessary CSS classes
    },
    numberRenderer(instance, td, row, col, prop, value, cellProperties) {
      const rowData = this.excelData[row];
      let dataDiv = ''
      let classDiv = 'd-flex align-items-center justify-content-between'
      if (rowData.transHysNo === '' || rowData.transHysNo === null) {
        dataDiv = null
      } else {
        dataDiv = formatNumberInt(rowData[prop])
      }

      // Clear the cell content
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }

      // Create the buttons container element
      const buttonsContainer = document.createElement('div');
      buttonsContainer.className = classDiv
      buttonsContainer.innerHTML = dataDiv
      // Create the first button

      // Append the buttons container to the cell
      td.appendChild(buttonsContainer);

      // Add necessary CSS classes
    },
    changeKode(data) {
      console.log(data)
      if (data && data.dataGR.length > 0) {
      this.suppId = data._id
        this.optionsInvoice = data.dataGR
        this.checkedPajak = data.dataGR[0].transHysPPN === 'yes' ? true : false
      } else {
      this.suppId = ''
        this.optionsInvoice = []
        this.checkedPajak = false
      }
      this.excelData = this.excelData.map(x => ({ transHysNo: '', itemHysId: '', itemHysUm: '', itemHysQtyRcp: '', itemHysPrice: '', transDiskon: '', transTotal: '' }))
    },
    // filterKode() {
    //   if (transPaymentType === 'Cash') {
    //     this.hotSettings.columns[0].source = this.prList.map(x => x.PrNo)
    //   }
    //   else if (transPaymentType === 'Credit') {
    //     this.hotSettings.columns[0].source = this.porList.map(x => x.PrNo)
    //   }
    // },
    customLabel(option) {
      return `${option.userName}`;
    },
    customLabel1(option) {
      return `${option.itemHysName} (${option.itemHysQtyRcp})`;
    },
    customLabelKode(option) {
      return `${option.transHysNo}`;
    },
    formatNumberInt(data) {
      return formatNumberInt(data)
    },
    validateEmptyValue(value, callback) {
      if (isNaN(value)) {
        callback(false);
      } else {
        callback(true);
      }
    },
    validateNumericValue(value, callback) {
      if (isNaN(value)) {
        callback(false);
      } else if (value < 0) {
        callback(false);
      } else {
        callback(true);
      }
    },
    onKeyDown1(event) {
      const hotInstance = this.$refs.hot.hotInstance;
      const selected = hotInstance.getSelected();
      const [startRow, startCol, endRow, endCol] = selected;

      if (event.key === "Tab" && startRow[1] >= 4 && this.excelData[startRow[0]].transDescription === '') {
        console.log(this.excelData[startRow[0]].transDescription)
        // alert('Uraian tidak Boleh Kosong!')
        hotInstance.selectCell(startRow[0], startRow[1] - 1);
      }
    },
    onChange(changes, source) {
      if (source === 'edit') {
        this.formatTable(changes);
      }
      else if (source === 'CopyPaste.paste') {
        this.formatTable(changes);
      }
    },
    formatTable(changes) {
      for (const change of changes) {
        const [row, prop, oldValue, newValue] = change;
        const baris = this.excelData[row]

        if (prop === 'transCoaCode' && baris) {
          const findName = this.coa.coaNonBank.find(x => x.coaCode === baris.transCoaCode)
          if (baris.transCoaCode !== null && findName !== undefined) {
            baris.transCoaName = findName.coaName
            if (baris.transCoaName === undefined) {
              baris.transCoaName = null
              baris.transCoaCode = null
            }
          } else {
            baris.transCoaName = null
            baris.transCoaCode = null
          }
          this.excelData = [...this.excelData]
        } else if (prop === 'transCoaName' && baris) {
          const findCode = this.coa.coaNonBank.find(x => x.coaName === baris.transCoaName)
          if (baris.transCoaName !== null && findCode !== undefined) {
            baris.transCoaCode = findCode.coaCode
          } else {
            baris.transCoaName = null
            baris.transCoaCode = null
          }
          this.excelData = [...this.excelData]
        } else if (prop === 'Kredit' || prop === 'Debet' && baris) {
          const oppositeProp = prop === 'Kredit' ? 'Debet' : 'Kredit';
          const oppositeValue = this.excelData[row][oppositeProp];
          if (newValue !== '' && newValue !== null) {
            // If one input has a non-empty value, set the opposite input to 0
            this.excelData[row][oppositeProp] = 0;
            this.hotSettings.columns.find(column => column.data === oppositeProp).readOnly = true;
          } else {
            this.excelData[row][oppositeProp] = '';
            this.hotSettings.columns.find(column => column.data === oppositeProp).readOnly = false;
          }
          this.excelData = [...this.excelData]
          this.hotSettings.columns = [...this.hotSettings.columns]
        } else {
          console.log('gagal')
        }
      }
    },
    sendData(event) {
      event.preventDefault()
      if (this.excelData.length > 0) {
        this.$swal({
          title: "Anda Yakin?",
          text: "Tekan Simpan jika anda sudah yakin!",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Simpan",
        }).then(async (result) => {
          if (result.isConfirmed) {
            alertLoading();
            try {
              const data = this.excelData
                .filter((item) => {
                  const transHysNoIsEmpty = item.transHysNo === "";

                  // Filter out objects with both transCoaCode and transCoaName empty
                  if (transHysNoIsEmpty) {
                    return false;
                  }

                  return true;
                })
                .map(x => {
                  const data = x.transHysNo
                  data.totalInvoice = this.totalPrice
                  data.PPN = this.checkedPajak === true ? this.totalPPN : 0
                  data.transReffExt = this.transNoFaktur
                  data.transHysUserTo = this.suppId
                  return data
                })
              const uniqueDataMap = {};
              const uniqueDataArray = data.filter(item => {
                if (!uniqueDataMap.hasOwnProperty(item.transHysNo)) {
                  uniqueDataMap[item.transHysNo] = true;
                  return true;
                }
                return false;
              });
              console.log('send data', uniqueDataArray)
              await addPI(data)
              successAddData();
              this.$router.push({ name: this.$route.name.replace('-add', '') })
            } catch (error) {
              errorAddData();
            }
          }
        });
      } else if (this.totalKredit !== this.totalDebet) {
        alert('Data Tidak Balance!')
      } else {
        alert('ada data wajib yang masih kosong!')
      }
    },
    getContentWidth() {
      const contentDiv = this.$refs.contentDiv;
      let totalWidth = 0;
      const tes = this.hotSettings.columns.filter(x => !x.width).length
      const widthTes = this.hotSettings.columns.filter(x => x.width)
      for (const column of widthTes) {
        if (column.width) {
          totalWidth += column.width;
        }
      }
      if (contentDiv) {
        this.hotSettings.colWidths = (contentDiv.offsetWidth - 70 - totalWidth) / tes;
      }
    },
  },
};
</script>