var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c("div", { ref: "contentDiv", staticClass: "w-100" }),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.isDataLoaded === ""
          ? _c("div", { key: "empty" }, [_c("loading")], 1)
          : _vm.isDataLoaded
          ? _c("div", { key: "truthy" }, [
              _c(
                "div",
                { staticClass: "content container-fluid p-3 ps-0 py-0" },
                [
                  _c("transaksiAddStockOpname", {
                    attrs: {
                      posts: _vm.posts,
                      component: _vm.refreshComponent,
                      hotSettings: _vm.hotSettings,
                      widthTable: _vm.contentDivWidth,
                      excelData: _vm.excelData,
                      refreshMethods: _vm.onRefresh(),
                    },
                  }),
                ],
                1
              ),
            ])
          : _c("div", { key: "other" }, [_c("connection-timeout")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }