<template>
  <div class="col-12">
    <transition name="fade" mode="out-in">
      <div v-if="isDataLoaded === ''" key="empty">
        <loadingAuto style="min-height: 130px"></loadingAuto>
      </div>
      <div v-else-if="isDataLoaded" key="truthy">
        <div class="tab-content" id="navTabContent">
          <div
            class="tab-pane py-2 show active"
            id="tabSummary"
            role="tabpanel"
            aria-labelledby="nav-tabSummary"
          >
            <!-- Nav -->
            <div class="row">
              <div class="col-sm-6 col-lg-3 mb-2">
                <!-- Card -->
                <a class="card card-hover-shadow h-100 card-style-1" href="#">
                  <div class="card-body">
                    <h6 class="card-subtitle text-dark">Saldo Awal Bulan Ini</h6>

                    <div class="row align-items-center gx-2 mb-1">
                      <div class="col-12">
                        <h3 class="card-title text-inherit my-2">
                          <div
                            v-if="
                              typeof currentData === 'object' &&
                              currentData.current.length > 0
                            "
                          >
                            <span class="h4">Rp.</span
                            >{{ formatPriceDot(currentData.current[0].saldoAwal) }}
                          </div>
                          <div v-else><span class="h4">Rp.</span>0</div>
                        </h3>
                      </div>
                      <!-- End Col -->
                    </div>
                    <!-- End Row -->

                    <span
                      class="badge"
                      style="font-size: 10px"
                      :class="
                        currentData.current[0].saldoAwal > currentData.lastMonth[0].saldoAwal
                          ? 'bg-soft-success text-success'
                          : 'bg-soft-danger text-danger  '
                      "
                    >
                      <i
                        :class="
                          currentData.current[0].saldoAwal > currentData.lastMonth[0].saldoAwal ? 'bi-graph-up' : 'bi-graph-down'
                        "
                      ></i>
                      Last Month
                    </span>
                    <span
                      class="text-body fs-8 ms-1"
                      style="font-size: 11px"
                      v-if="
                        typeof currentData === 'object' &&
                        currentData.lastMonth.length > 0
                      "
                      >Rp. {{ formatPriceDot(currentData.lastMonth[0].saldoAwal) }}</span
                    >
                    <span class="text-body fs-8 ms-1" style="font-size: 11px" v-else
                      >Rp. 0</span
                    >
                  </div>
                </a>
                <!-- End Card -->
              </div>

              <div class="col-sm-6 col-lg-3 mb-2">
                <!-- Card -->
                <a class="card card-hover-shadow h-100 card-style-1" href="#">
                  <div class="card-body">
                    <h6 class="card-subtitle text-dark">Pemasukan Bulan Ini</h6>

                    <div class="row align-items-center gx-2 mb-1">
                      <div class="col-12">
                        <h3 class="card-title text-inherit my-2">
                          <div
                            v-if="
                              typeof currentData === 'object' &&
                              currentData.current.length > 0
                            "
                          >
                            <span class="h4">Rp.</span
                            >{{ formatPriceDot(currentData.current[0].in) }}
                          </div>
                          <div v-else><span class="h4">Rp.</span>0</div>
                        </h3>
                      </div>
                      <!-- End Col -->
                    </div>
                    <!-- End Row -->

                    <span
                      class="badge"
                      style="font-size: 10px"
                      :class="
                        currentData.current[0].in > currentData.lastMonth[0].in
                          ? 'bg-soft-success text-success'
                          : 'bg-soft-danger text-danger  '
                      "
                    >
                      <i
                        :class="currentData.current[0].in > currentData.lastMonth[0].in ? 'bi-graph-up' : 'bi-graph-down'"
                      ></i>
                      Last Month
                    </span>
                    <span
                      class="text-body fs-8 ms-1"
                      style="font-size: 11px"
                      v-if="
                        typeof currentData === 'object' &&
                        currentData.lastMonth.length > 0
                      "
                      >Rp. {{ formatPriceDot(currentData.lastMonth[0].in) }}</span
                    >
                    <span class="text-body fs-8 ms-1" style="font-size: 11px" v-else
                      >Rp. 0</span
                    >
                  </div>
                </a>
                <!-- End Card -->
              </div>

              <div class="col-sm-6 col-lg-3 mb-2">
                <!-- Card -->
                <a class="card card-hover-shadow h-100 card-style-1" href="#">
                  <div class="card-body">
                    <h6 class="card-subtitle text-dark">Pengeluaran Bulan Ini</h6>

                    <div class="row align-items-center gx-2 mb-1">
                      <div class="col-12">
                        <h3 class="card-title text-inherit my-2">
                          <div
                            v-if="
                              typeof currentData === 'object' &&
                              currentData.current.length > 0
                            "
                          >
                            <span class="h4">Rp.</span
                            >{{ formatPriceDot(currentData.current[0].out) }}
                          </div>
                          <div v-else><span class="h4">Rp.</span>0</div>
                        </h3>
                      </div>
                      <!-- End Col -->
                    </div>
                    <!-- End Row -->

                    <span
                      class="badge"
                      style="font-size: 10px"
                      :class="
                        currentData.current[0].out > currentData.lastMonth[0].out
                          ? 'bg-soft-danger text-danger'
                          : 'bg-soft-success text-success'
                      "
                    >
                      <i
                        :class="currentData.current[0].out > currentData.lastMonth[0].out ? 'bi-graph-up' : 'bi-graph-down'"
                      ></i>
                      Last Month
                    </span>
                    <span
                      class="text-body fs-8 ms-1"
                      style="font-size: 11px"
                      v-if="
                        typeof currentData === 'object' &&
                        currentData.lastMonth.length > 0
                      "
                      >Rp. {{ formatPriceDot(currentData.lastMonth[0].out) }}</span
                    >
                    <span class="text-body fs-8 ms-1" style="font-size: 11px" v-else
                      >Rp. 0</span
                    >
                  </div>
                </a>
                <!-- End Card -->
              </div>
              <div class="col-sm-6 col-lg-3 mb-2">
                <!-- Card -->
                <a class="card card-hover-shadow h-100 card-style-1" href="#">
                  <div class="card-body">
                    <h6 class="card-subtitle text-dark">Saldo Saat Ini</h6>

                    <div class="row align-items-center gx-2 mb-1">
                      <div class="col-12">
                        <h3 class="card-title text-inherit my-2">
                          <div
                            v-if="
                              typeof currentData === 'object' &&
                              currentData.current.length > 0
                            "
                          >
                            <span class="h4">Rp.</span
                            >{{ formatPriceDot(currentData.current[0].saldoAkhir) }}
                          </div>
                          <div v-else><span class="h4">Rp.</span>0</div>
                        </h3>
                      </div>
                      <!-- End Col -->
                    </div>
                    <!-- End Row -->

                    <span
                      class="badge"
                      style="font-size: 10px"
                      :class="
                        currentData.current[0].saldoAkhir > currentData.lastMonth[0].saldoAkhir
                          ? 'bg-soft-success text-success'
                          : 'bg-soft-danger text-danger  '
                      "
                    >
                      <i
                        :class="
                          currentData.current[0].saldoAkhir
                           > currentData.lastMonth[0].saldoAkhir
                           ? 'bi-graph-up' : 'bi-graph-down'
                        "
                      ></i>
                      Last Month
                    </span>
                    <span
                      class="text-body fs-8 ms-1"
                      style="font-size: 11px"
                      v-if="
                        typeof currentData === 'object' &&
                        currentData.lastMonth.length > 0
                      "
                      >Rp.
                      {{ formatPriceDot(currentData.lastMonth[0].saldoAkhir) }}</span
                    >
                    <span class="text-body fs-8 ms-1" style="font-size: 11px" v-else
                      >Rp. 0</span
                    >
                  </div>
                </a>
                <!-- End Card -->
              </div>
            </div>
            <!-- End Nav -->
          </div>

          <div
            class="tab-pane py-2"
            id="tabPendapatan"
            role="tabpanel"
            aria-labelledby="nav-tabPendapatan"
          >
            <!-- Nav -->
            <div class="row">
              <div class="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                <!-- Card -->
                <a class="card card-hover-shadow h-100 card-style-1" href="#">
                  <div class="card-body">
                    <h6 class="card-subtitle text-dark">Pendapatan Outstanding</h6>

                    <div class="row align-items-center gx-2 mb-1">
                      <div class="col-12">
                        <h1 class="card-title text-inherit my-2">
                          <span class="h4">Rp.</span> 0
                        </h1>
                      </div>
                      <!-- End Col -->
                    </div>
                    <!-- End Row -->

                    <span class="badge bg-soft-success text-success">Today</span>
                    <span class="text-body fs-6 ms-1">Rp.50000000</span>
                  </div>
                </a>
                <!-- End Card -->
              </div>

              <div class="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                <!-- Card -->
                <a class="card card-hover-shadow h-100 card-style-1" href="#">
                  <div class="card-body">
                    <h6 class="card-subtitle text-dark">Pembayaran Registrasi</h6>

                    <div class="row align-items-center gx-2 mb-1">
                      <div class="col-12">
                        <h1 class="card-title text-inherit my-2">
                          <span class="h4">Rp.</span> 5.000.000
                        </h1>
                      </div>
                      <!-- End Col -->
                    </div>
                    <!-- End Row -->

                    <span class="badge bg-soft-success text-success">
                      <i class="bi-graph-up"></i>Today
                    </span>
                    <span class="text-body fs-6 ms-1">Rp.50000000</span>
                  </div>
                </a>
                <!-- End Card -->
              </div>

              <div class="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                <!-- Card -->
                <a class="card card-hover-shadow h-100 card-style-1" href="#">
                  <div class="card-body">
                    <h6 class="card-subtitle text-dark">Pembayaran Migrasi</h6>

                    <div class="row align-items-center gx-2 mb-1">
                      <div class="col-12">
                        <h1 class="card-title text-inherit my-2">
                          <span class="h4">Rp.</span> 10.000.000
                        </h1>
                      </div>
                      <!-- End Col -->
                    </div>
                    <!-- End Row -->

                    <span class="badge bg-soft-success text-success">
                      <i class="bi-graph-down"></i> Today
                    </span>
                    <span class="text-body fs-6 ms-1">Rp.50000000</span>
                  </div>
                </a>
                <!-- End Card -->
              </div>

              <div class="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                <!-- Card -->
                <a class="card card-hover-shadow h-100 card-style-1" href="#">
                  <div class="card-body">
                    <h6 class="card-subtitle text-dark">Penjualan Voucher</h6>

                    <div class="row align-items-center gx-2 mb-1">
                      <div class="col-12">
                        <h1 class="card-title text-inherit my-2">
                          <span class="h4">Rp.</span> 0
                        </h1>
                      </div>
                      <!-- End Col -->
                    </div>
                    <!-- End Row -->

                    <span class="badge bg-soft-success text-success">Today</span>
                    <span class="text-body fs-6 ms-1">Rp.50000000</span>
                  </div>
                </a>
                <!-- End Card -->
              </div>
            </div>
            <!-- End Nav -->
          </div>

          <div
            class="tab-pane py-2"
            id="tabPengeluaran"
            role="tabpanel"
            aria-labelledby="nav-tabPengeluaran"
          >
            <!-- Nav -->
            <div class="row">
              <div class="col-sm-6 col-lg-6 mb-3 mb-lg-5">
                <!-- Card -->
                <a class="card card-hover-shadow h-100 card-style-1" href="#">
                  <div class="card-body">
                    <h6 class="card-subtitle text-dark">Pembelian</h6>

                    <div class="row align-items-center gx-2 mb-1">
                      <div class="col-12">
                        <h1 class="card-title text-inherit my-2">
                          <span class="h4">Rp.</span> 3.000.000
                        </h1>
                      </div>
                      <!-- End Col -->
                    </div>
                    <!-- End Row -->

                    <span class="badge bg-soft-success text-success">
                      <i class="bi-graph-up"></i> 1.7%
                    </span>
                    <span class="text-body fs-6 ms-1">From Last Month</span>
                  </div>
                </a>
                <!-- End Card -->
              </div>

              <div class="col-sm-6 col-lg-6 mb-3 mb-lg-5">
                <!-- Card -->
                <a class="card card-hover-shadow h-100 card-style-1" href="#">
                  <div class="card-body">
                    <h6 class="card-subtitle text-dark">Perjalanan Dinas</h6>

                    <div class="row align-items-center gx-2 mb-1">
                      <div class="col-12">
                        <h1 class="card-title text-inherit my-2">
                          <span class="h4">Rp.</span> 7.000.000
                        </h1>
                      </div>
                      <!-- End Col -->
                    </div>
                    <!-- End Row -->

                    <span class="badge bg-soft-danger text-danger">
                      <i class="bi-graph-down"></i> 4.4%
                    </span>
                    <span class="text-body fs-6 ms-1">From Last Month</span>
                  </div>
                </a>
                <!-- End Card -->
              </div>
            </div>
            <!-- End Nav -->
          </div>

          <div
            class="tab-pane py-2"
            id="tabTotal"
            role="tabpanel"
            aria-labelledby="nav-tabTotal"
          >
            <!-- Nav -->
            <div class="row">
              <div class="col-sm-6 col-lg-4 mb-2">
                <!-- Card -->
                <a class="card card-hover-shadow h-100 card-style-1" href="#">
                  <div class="card-body">
                    <h6 class="card-subtitle text-dark">Total Saldo</h6>

                    <div class="row align-items-center gx-2 mb-1">
                      <div class="col-12">
                        <h1 class="card-title text-inherit my-2">
                          <span class="h4">Rp.</span> 25.000.000
                        </h1>
                      </div>
                      <!-- End Col -->
                    </div>
                    <!-- End Row -->

                    <span class="badge bg-soft-success text-success">
                      <i class="bi-graph-up"></i> 1.7%
                    </span>
                    <span class="text-body fs-6 ms-1">From Last Month</span>
                  </div>
                </a>
                <!-- End Card -->
              </div>

              <div class="col-sm-6 col-lg-4 mb-3 mb-lg-5">
                <!-- Card -->
                <a class="card card-hover-shadow h-100 card-style-1" href="#">
                  <div class="card-body">
                    <h6 class="card-subtitle text-dark">Total Pendapatan</h6>

                    <div class="row align-items-center gx-2 mb-1">
                      <div class="col-12">
                        <h1 class="card-title text-inherit my-2">
                          <span class="h4">Rp.</span> 20.000.000
                        </h1>
                      </div>
                      <!-- End Col -->
                    </div>
                    <!-- End Row -->

                    <span class="badge bg-soft-danger text-danger">
                      <i class="bi-graph-down"></i> 4.4%
                    </span>
                    <span class="text-body fs-6 ms-1">From Last Month</span>
                  </div>
                </a>
                <!-- End Card -->
              </div>

              <div class="col-sm-6 col-lg-4 mb-3 mb-lg-5">
                <!-- Card -->
                <a class="card card-hover-shadow h-100 card-style-1" href="#">
                  <div class="card-body">
                    <h6 class="card-subtitle text-dark">Total Pengeluaran</h6>

                    <div class="row align-items-center gx-2 mb-1">
                      <div class="col-12">
                        <h1 class="card-title text-inherit my-2">
                          <span class="h4">Rp.</span> 2.000.000
                        </h1>
                      </div>
                      <!-- End Col -->
                    </div>
                    <!-- End Row -->

                    <span class="badge bg-soft-secondary text-body">0.0%</span>
                    <span class="text-body fs-6 ms-1">From Last Month</span>
                  </div>
                </a>
                <!-- End Card -->
              </div>
            </div>
            <!-- End Nav -->
          </div>
        </div>
      </div>

      <div v-else key="other">
        <connection-timeout></connection-timeout>
      </div>
    </transition>
  </div>
</template>

<script>
import chartComponent from "../../components/chartComponent.vue";
import loadingAuto from "../../components/loadingAuto.vue";
import connectionTimeout from "../../components/connectionTimeout.vue";

import { getFullDate } from "../../components/function/date";
import { formatPriceDot } from "../../components/function/number";
import { getDashboardPeriod } from "../../components/services/components";
export default {
  props: {
    parentCurrentData: Array,
  },
  components: {
    loadingAuto,
    connectionTimeout,
  },
  computed: {
    fixData() {
      return parentCurrentData;
    },
  },
  data() {
    return {
      isDataLoaded: "",
    };
  },
  methods: {
    getTotalData(data) {
      return parseFloat(data.saldoAwal) + parseFloat(data.in) - parseFloat(data.out);
    },
    formatPriceDot(number) {
      return formatPriceDot(number);
    },
  },
  async mounted() {
    const timeout = 30000; // 10 seconds
    try {
      this.fullDate = await getFullDate();
      this.currentData = await getDashboardPeriod(this.fullDate);

      const timeoutPromise = new Promise((resolve) => setTimeout(resolve, timeout));

      // Wait for either finalPromise or timeoutPromise to resolve
      const result = await Promise.race([this.currentData, timeoutPromise]);
      this.isDataLoaded = result !== undefined;
    } catch (error) {
      this.isDataLoaded = false;
    }
  },
};
</script>
