import { render, staticRenderFns } from "./purchaseRequestKlinikAdd.vue?vue&type=template&id=60fe988e"
import script from "./purchaseRequestKlinikAdd.vue?vue&type=script&lang=js"
export * from "./purchaseRequestKlinikAdd.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60fe988e')) {
      api.createRecord('60fe988e', component.options)
    } else {
      api.reload('60fe988e', component.options)
    }
    module.hot.accept("./purchaseRequestKlinikAdd.vue?vue&type=template&id=60fe988e", function () {
      api.rerender('60fe988e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/transaksi/add/purchaseRequestKlinikAdd.vue"
export default component.exports