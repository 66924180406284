var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c("div", { ref: "contentDiv", staticClass: "w-100" }),
      _c("chatting", { staticStyle: { "z-index": "10" } }),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.isDataLoaded === ""
          ? _c("div", { key: "empty" }, [_c("loading")], 1)
          : _vm.isDataLoaded
          ? _c("div", { key: "truthy" }, [
              _c(
                "div",
                {
                  staticClass: "modal",
                  attrs: { tabindex: "-1", role: "dialog" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "modal-dialog",
                      attrs: { role: "document" },
                    },
                    [
                      _c("div", { staticClass: "modal-content" }, [
                        _c("div", { staticClass: "modal-header" }, [
                          _c("h5", { staticClass: "modal-title" }, [
                            _vm._v("Modal title"),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: {
                                type: "button",
                                "data-dismiss": "modal",
                                "aria-label": "Close",
                              },
                            },
                            [
                              _c("span", { attrs: { "aria-hidden": "true" } }, [
                                _vm._v("×"),
                              ]),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "modal-body printable-element" },
                          [
                            _c("div", { staticClass: "container" }, [
                              _vm.paymentData
                                ? _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-12 d-flex justify-content-between align-items-center",
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "img-fluid w-25",
                                          attrs: {
                                            src: "/assets/img/logoPrint/dragon1.png",
                                            alt: "Image Description",
                                            "data-hs-theme-appearance":
                                              "default",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "w-50 text-center" },
                                          [
                                            _c("h1", {}, [
                                              _c("b", [
                                                _c("u", [_vm._v("NOTA")]),
                                              ]),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("hr"),
                                    _c("div", { staticClass: "col-6" }, [
                                      _vm.paymentData.transHysNo
                                        ? _c("pre", { staticClass: "m-0" }, [
                                            _vm._v(
                                              "No. Nota   : " +
                                                _vm._s(
                                                  _vm.paymentData
                                                    .transShipCustNo.userPhone
                                                ) +
                                                "\nTgl. Bayar : " +
                                                _vm._s(
                                                  _vm.formatIndDate(
                                                    _vm.paymentData
                                                      .transHysEffDate
                                                  )
                                                ) +
                                                "\n    "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _c("div", { staticClass: "col-6" }, [
                                      _vm.paymentData.transHysNo
                                        ? _c("pre", { staticClass: "m-0" }, [
                                            _vm._v(
                                              "Nama Customer : " +
                                                _vm._s(
                                                  _vm.paymentData
                                                    .transShipCustNo.userName
                                                ) +
                                                "\nAlamat        : "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "white-space": "normal",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.paymentData
                                                      .transShipCustNo
                                                      .userAddress[0].location
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v("\n    "),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _vm.paymentData.transHysItem
                                      ? _c(
                                          "table",
                                          {
                                            staticClass: "table table-bordered",
                                          },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "text-center align-middle",
                                                  },
                                                  [_vm._v("Bayar Ke")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "text-center align-middle",
                                                  },
                                                  [_vm._v("Keterangan")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "text-center align-middle",
                                                    attrs: { width: "" },
                                                  },
                                                  [_vm._v("Total")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "text-center align-middle",
                                                    attrs: { width: "" },
                                                  },
                                                  [_vm._v("Diskon")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "text-center align-middle",
                                                    attrs: { width: "" },
                                                  },
                                                  [_vm._v("Sudah Dibayar")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "text-center align-middle",
                                                    attrs: { width: "" },
                                                  },
                                                  [_vm._v("Sisa")]
                                                ),
                                              ]),
                                            ]),
                                            _c("tbody", [
                                              _c("tr", [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "fs-11 align-middle",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.payMethods.coaName
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "fs-11 align-middle",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Pembayaran Invoice " +
                                                        _vm._s(
                                                          _vm.paymentData
                                                            .transSINo
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "fs-11 align-middle text-end",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Rp. " +
                                                        _vm._s(
                                                          _vm.formatNumberInt(
                                                            _vm.paymentData
                                                              .transTotal
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "fs-11 align-middle text-end",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Rp. " +
                                                        _vm._s(
                                                          _vm.formatNumberInt(
                                                            _vm.discTotal(
                                                              _vm.paymentData
                                                                .transDiscount
                                                            )
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "fs-11 align-middle text-end",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Rp. " +
                                                        _vm._s(
                                                          _vm.formatNumberInt(
                                                            _vm.paymentData
                                                              .transBayar
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "fs-11 align-middle text-end",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Rp. " +
                                                        _vm._s(
                                                          _vm.formatNumberInt(
                                                            _vm.paymentData
                                                              .transTotal -
                                                              _vm.paymentData
                                                                .transBayar -
                                                              _vm.discTotal(
                                                                _vm.paymentData
                                                                  .transDiscount
                                                              )
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "text-end",
                                                    attrs: { colspan: "5" },
                                                  },
                                                  [_vm._v("Total")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "text-end",
                                                    attrs: { colspan: "1" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Rp. " +
                                                        _vm._s(
                                                          _vm.formatNumberInt(
                                                            _vm.paymentData
                                                              .transTotal -
                                                              _vm.paymentData
                                                                .transBayar -
                                                              _vm.discTotal(
                                                                _vm.paymentData
                                                                  .transDiscount
                                                              )
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "text-end",
                                                    attrs: { colspan: "5" },
                                                  },
                                                  [_vm._v("Dibayar")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "text-end",
                                                    attrs: { colspan: "1" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Rp. " +
                                                        _vm._s(_vm.payNominal) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "text-end",
                                                    attrs: { colspan: "5" },
                                                  },
                                                  [_vm._v("Outstanding")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "text-end",
                                                    attrs: { colspan: "1" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Rp. " +
                                                        _vm._s(
                                                          _vm.formatNumberInt(
                                                            _vm.paymentData
                                                              .transTotal -
                                                              _vm.paymentData
                                                                .transBayar -
                                                              _vm.discTotal(
                                                                _vm.paymentData
                                                                  .transDiscount
                                                              ) -
                                                              parseFloat(
                                                                _vm.removeThousandFormatter(
                                                                  _vm.payNominal
                                                                )
                                                              )
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row d-flex align-items-center justify-content-around",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col-3 text-center" },
                                          [
                                            _vm._v(" Mengetahui "),
                                            _c("p", {
                                              staticClass: "mt-5 w-100",
                                            }),
                                            _c("br"),
                                            _c("hr"),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-3 text-center" },
                                          [
                                            _vm._v(" Kepada "),
                                            _c("p", {
                                              staticClass: "mt-5 w-100",
                                            }),
                                            _c("br"),
                                            _c("hr"),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "modal-footer" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "button" },
                            },
                            [_vm._v("Save changes")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: {
                                type: "button",
                                "data-dismiss": "modal",
                              },
                            },
                            [_vm._v(" Close ")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "content container-fluid p-3 ps-0 pt-0" },
                [
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: "modal-payment",
                        title: "Sales Invoice Payment",
                        size: "lg",
                      },
                      on: {
                        ok: function ($event) {
                          $event.preventDefault()
                          return _vm.pay()
                        },
                      },
                    },
                    [
                      _vm.paymentData
                        ? _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _vm.paymentData.transHysNo
                                ? _c("pre", { staticClass: "m-0" }, [
                                    _vm._v(
                                      "No. Invoice   : " +
                                        _vm._s(_vm.paymentData.transSINo) +
                                        "\nTgl. Invoice  : " +
                                        _vm._s(
                                          _vm.formatIndDate(
                                            _vm.paymentData.transHysEffDate
                                          )
                                        ) +
                                        "\n    "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _vm.paymentData.transHysNo
                                ? _c("pre", { staticClass: "m-0" }, [
                                    _vm._v(
                                      "Nama Customer : " +
                                        _vm._s(
                                          _vm.paymentData.transShipCustNo
                                            .userName
                                        ) +
                                        "\nAlamat        : "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "white-space": "normal",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.paymentData.transShipCustNo
                                              .userAddress[0].location
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v("\n    "),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm.paymentData.transHysItem
                              ? _c(
                                  "table",
                                  { staticClass: "table table-bordered" },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-center align-middle",
                                          },
                                          [_vm._v("Kode")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-center align-middle",
                                          },
                                          [_vm._v("Nama")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-center align-middle",
                                          },
                                          [_vm._v("Qty")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-center align-middle",
                                            attrs: { width: "150" },
                                          },
                                          [_vm._v("Harga")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-center align-middle",
                                            attrs: { width: "150" },
                                          },
                                          [_vm._v("Total")]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      [
                                        _vm._l(
                                          _vm.paymentData.transHysItem,
                                          function (data) {
                                            return _vm.paymentData.transHysItem
                                              .length > 0
                                              ? _c("tr", [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "fs-11 align-middle",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.itemHysCode
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "fs-11 align-middle",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.itemHysName
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "fs-11 align-middle",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.itemHysQtyRcp
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "fs-11 align-middle text-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data
                                                              .itemHysMasterPrice
                                                              .priceValue
                                                              ? "Rp. " +
                                                                  _vm.formatNumberInt(
                                                                    data
                                                                      .itemHysMasterPrice
                                                                      .priceValue
                                                                  )
                                                              : 0
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "fs-11 align-middle text-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Rp. " +
                                                          _vm._s(
                                                            data
                                                              .itemHysMasterPrice
                                                              .priceValue
                                                              ? _vm.formatNumberInt(
                                                                  data
                                                                    .itemHysMasterPrice
                                                                    .priceValue *
                                                                    data.itemHysQtyRcp
                                                                )
                                                              : 0
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e()
                                          }
                                        ),
                                        _c("tr", [
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-end",
                                              attrs: { colspan: "4" },
                                            },
                                            [_vm._v("Sub Total")]
                                          ),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-end",
                                              attrs: { colspan: "1" },
                                            },
                                            [
                                              _vm._v(
                                                " Rp. " +
                                                  _vm._s(
                                                    _vm.formatNumberInt(
                                                      _vm.paymentData
                                                        .transHysItem
                                                        ? _vm.totalPayment(
                                                            _vm.paymentData
                                                              .transHysItem
                                                          )
                                                        : 0
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("tr", [
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-end",
                                              attrs: { colspan: "4" },
                                            },
                                            [_vm._v("Diskon")]
                                          ),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-end",
                                              attrs: { colspan: "1" },
                                            },
                                            [
                                              _vm._v(
                                                " Rp. " +
                                                  _vm._s(
                                                    _vm.formatNumberInt(
                                                      _vm.discTotal(
                                                        _vm.paymentData
                                                          .transDiscount
                                                      )
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("tr", [
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-end",
                                              attrs: { colspan: "4" },
                                            },
                                            [_vm._v("Total")]
                                          ),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-end",
                                              attrs: { colspan: "1" },
                                            },
                                            [
                                              _vm._v(
                                                " Rp. " +
                                                  _vm._s(
                                                    _vm.formatNumberInt(
                                                      _vm.totalPayment(
                                                        _vm.paymentData
                                                          .transHysItem
                                                      ) -
                                                        _vm.discTotal(
                                                          _vm.paymentData
                                                            .transDiscount
                                                        )
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _c("h5", { staticClass: "mt-3" }, [
                        _vm._v("Payment History"),
                      ]),
                      _c("table", { staticClass: "table table-bordered m-0" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { attrs: { width: "10px" } }, [
                              _vm._v("NO."),
                            ]),
                            _c(
                              "th",
                              {
                                staticClass: "text-center",
                                attrs: { width: "150" },
                              },
                              [_vm._v("Trans. ID")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "text-center",
                                attrs: { width: "135" },
                              },
                              [_vm._v("Tgl. Bayar")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "text-center",
                                attrs: { width: "150" },
                              },
                              [_vm._v("Bayar Ke")]
                            ),
                            _c("th", { staticClass: "text-center" }, [
                              _vm._v("Keterangan"),
                            ]),
                            _c(
                              "th",
                              {
                                staticClass: "text-center",
                                attrs: { width: "135" },
                              },
                              [_vm._v("Jumlah")]
                            ),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm._l(_vm.payHistory, function (dt, index) {
                              return _vm.payHistory
                                ? _c("tr", [
                                    _c("td", [_vm._v(_vm._s(index + 1))]),
                                    _c("td", [_vm._v(_vm._s(dt.transId))]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.formatIndDate(dt.transDate))
                                      ),
                                    ]),
                                    _c("td", [_vm._v(_vm._s(dt.transPayTo))]),
                                    _c("td", [_vm._v(_vm._s(dt.transDesc))]),
                                    _c("td", { staticClass: "text-end" }, [
                                      _vm._v(
                                        "Rp. " +
                                          _vm._s(
                                            _vm.formatNumberInt(dt.transAmount)
                                          )
                                      ),
                                    ]),
                                  ])
                                : _c("tr", [
                                    _c("td", { attrs: { colspan: "6" } }, [
                                      _vm._v("Belum Ada Pembayaran!"),
                                    ]),
                                  ])
                            }),
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "text-end",
                                  attrs: { colspan: "5" },
                                },
                                [_c("b", [_vm._v("Menunggu Pembayaran")])]
                              ),
                              _c("td", { staticClass: "text-end" }, [
                                _vm.paymentData && _vm.paymentData.transDiscount
                                  ? _c("b", [
                                      _vm._v("Rp. "),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatNumberInt(
                                              _vm.paymentData.transTotal -
                                                _vm.paymentData.transBayar -
                                                _vm.discTotal(
                                                  _vm.paymentData.transDiscount
                                                )
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-between",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "w-50" },
                            [
                              _vm._v(" Masuk Ke : "),
                              _c("multiselect", {
                                staticClass: "my-1",
                                attrs: {
                                  options: _vm.optionsCoa,
                                  placeholder: "Metode Pembayaran",
                                  multiple: false,
                                  "show-labels": false,
                                  label: "coaName",
                                },
                                model: {
                                  value: _vm.payMethods,
                                  callback: function ($$v) {
                                    _vm.payMethods = $$v
                                  },
                                  expression: "payMethods",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "w-25 ps-3" }, [
                            _vm._v(" Jumlah Bayar : "),
                            _c("input", {
                              directives: [
                                {
                                  name: "format-number",
                                  rawName: "v-format-number",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.payNominal,
                                  expression: "payNominal",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: _vm.payNominal },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.payNominal = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "w-25 ps-3" }, [
                            _vm._v(" Ongkir : "),
                            _c("input", {
                              directives: [
                                {
                                  name: "format-number",
                                  rawName: "v-format-number",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.payOngkir,
                                  expression: "payOngkir",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: _vm.payOngkir },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.payOngkir = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: "modal-edit",
                        title: "Edit Sales Invoice",
                        size: "lg",
                      },
                      on: {
                        ok: function ($event) {
                          $event.preventDefault()
                          return _vm.edit()
                        },
                      },
                    },
                    [
                      _vm.paymentData
                        ? _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _vm.paymentData.transHysNo
                                ? _c("pre", { staticClass: "m-0" }, [
                                    _vm._v(
                                      "No. Invoice   : " +
                                        _vm._s(_vm.paymentData.transSINo) +
                                        "\nTgl. Invoice  : " +
                                        _vm._s(
                                          _vm.formatIndDate(
                                            _vm.paymentData.transHysEffDate
                                          )
                                        ) +
                                        "\n    "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _vm.paymentData.transHysNo
                                ? _c("pre", { staticClass: "m-0" }, [
                                    _vm._v(
                                      "Nama Customer : " +
                                        _vm._s(
                                          _vm.paymentData.transShipCustNo
                                            .userName
                                        ) +
                                        "\nAlamat        : "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "white-space": "normal",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.paymentData.transShipCustNo
                                              .userAddress[0].location
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v("\n    "),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm.paymentData.transHysItem
                              ? _c(
                                  "table",
                                  { staticClass: "table table-bordered" },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-center align-middle",
                                          },
                                          [_vm._v("Kode")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-center align-middle",
                                          },
                                          [_vm._v("Nama")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-center align-middle",
                                          },
                                          [_vm._v("Qty")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-center align-middle",
                                            attrs: { width: "150" },
                                          },
                                          [_vm._v("Harga")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-center align-middle",
                                            attrs: { width: "150" },
                                          },
                                          [_vm._v("Total")]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      [
                                        _vm._l(
                                          _vm.paymentData.transHysItem,
                                          function (data) {
                                            return _vm.paymentData.transHysItem
                                              .length > 0
                                              ? _c("tr", [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "fs-11 align-middle",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.itemHysCode
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "fs-11 align-middle",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.itemHysName
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "fs-11 align-middle",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.itemHysQtyRcp
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "fs-11 align-middle text-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data
                                                              .itemHysMasterPrice
                                                              .priceValue
                                                              ? "Rp. " +
                                                                  _vm.formatNumberInt(
                                                                    data
                                                                      .itemHysMasterPrice
                                                                      .priceValue
                                                                  )
                                                              : 0
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "fs-11 align-middle text-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Rp. " +
                                                          _vm._s(
                                                            data
                                                              .itemHysMasterPrice
                                                              .priceValue
                                                              ? _vm.formatNumberInt(
                                                                  data
                                                                    .itemHysMasterPrice
                                                                    .priceValue *
                                                                    data.itemHysQtyRcp
                                                                )
                                                              : 0
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e()
                                          }
                                        ),
                                        _c("tr", [
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-end",
                                              attrs: { colspan: "4" },
                                            },
                                            [_vm._v("Sub Total")]
                                          ),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-end",
                                              attrs: { colspan: "1" },
                                            },
                                            [
                                              _vm._v(
                                                " Rp. " +
                                                  _vm._s(
                                                    _vm.formatNumberInt(
                                                      _vm.paymentData
                                                        .transHysItem
                                                        ? _vm.totalPayment(
                                                            _vm.paymentData
                                                              .transHysItem
                                                          )
                                                        : 0
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("tr", [
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-end",
                                              attrs: { colspan: "4" },
                                            },
                                            [_vm._v("Diskon")]
                                          ),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-end",
                                              attrs: { colspan: "1" },
                                            },
                                            [
                                              _vm._v(
                                                " Rp. " +
                                                  _vm._s(
                                                    _vm.formatNumberInt(
                                                      _vm.discTotal(
                                                        _vm.paymentData
                                                          .transDiscount
                                                      )
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("tr", [
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-end",
                                              attrs: { colspan: "4" },
                                            },
                                            [_vm._v("Total")]
                                          ),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-end",
                                              attrs: { colspan: "1" },
                                            },
                                            [
                                              _vm._v(
                                                " Rp. " +
                                                  _vm._s(
                                                    _vm.formatNumberInt(
                                                      _vm.totalPayment(
                                                        _vm.paymentData
                                                          .transHysItem
                                                      ) -
                                                        _vm.discTotal(
                                                          _vm.paymentData
                                                            .transDiscount
                                                        )
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _c("h5", { staticClass: "mt-3" }, [
                        _vm._v("Payment History"),
                      ]),
                      _c("table", { staticClass: "table table-bordered m-0" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { attrs: { width: "10px" } }, [
                              _vm._v("NO."),
                            ]),
                            _c(
                              "th",
                              {
                                staticClass: "text-center",
                                attrs: { width: "150" },
                              },
                              [_vm._v("Trans. ID")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "text-center",
                                attrs: { width: "135" },
                              },
                              [_vm._v("Tgl. Bayar")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "text-center",
                                attrs: { width: "150" },
                              },
                              [_vm._v("Bayar Ke")]
                            ),
                            _c("th", { staticClass: "text-center" }, [
                              _vm._v("Keterangan"),
                            ]),
                            _c(
                              "th",
                              {
                                staticClass: "text-center",
                                attrs: { width: "135" },
                              },
                              [_vm._v("Jumlah")]
                            ),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm._l(_vm.payHistory, function (dt, index) {
                              return _vm.payHistory
                                ? _c("tr", [
                                    _c("td", [_vm._v(_vm._s(index + 1))]),
                                    _c("td", [_vm._v(_vm._s(dt.transId))]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.formatIndDate(dt.transDate))
                                      ),
                                    ]),
                                    _c("td", [_vm._v(_vm._s(dt.transPayTo))]),
                                    _c("td", [_vm._v(_vm._s(dt.transDesc))]),
                                    _c("td", { staticClass: "text-end" }, [
                                      _vm._v(
                                        "Rp. " +
                                          _vm._s(
                                            _vm.formatNumberInt(dt.transAmount)
                                          )
                                      ),
                                    ]),
                                  ])
                                : _c("tr", [
                                    _c("td", { attrs: { colspan: "6" } }, [
                                      _vm._v("Belum Ada Pembayaran!"),
                                    ]),
                                  ])
                            }),
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "text-end",
                                  attrs: { colspan: "5" },
                                },
                                [_c("b", [_vm._v("Menunggu Pembayaran")])]
                              ),
                              _c("td", { staticClass: "text-end" }, [
                                _vm.paymentData
                                  ? _c("b", [
                                      _vm._v(
                                        "Rp. " +
                                          _vm._s(
                                            _vm.formatNumberInt(
                                              _vm.grandAmount()
                                            )
                                          )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-between",
                        },
                        [
                          _c("div", { staticClass: "w-25 ps-3" }, [
                            _vm._v(" Ongkir : "),
                            _c("input", {
                              directives: [
                                {
                                  name: "format-number",
                                  rawName: "v-format-number",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.payOngkir,
                                  expression: "payOngkir",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: _vm.payOngkir },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.payOngkir = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "row my-1" }, [
                    _c(
                      "div",
                      { staticClass: "col-4 d-flex align-items-center" },
                      [
                        _c("ul", { staticClass: "breadcrumb m-0" }, [
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "/dashboard" } },
                                [
                                  _c("a", { attrs: { href: "#" } }, [
                                    _c("i", { staticClass: "bi-house-door" }),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "/transaksi/po-invoice/add" } },
                                [
                                  _c("a", { attrs: { href: "#" } }, [
                                    _vm._v("Sales Invoice"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "col-4 text-center" }, [
                      _c("span", { staticClass: "h2" }, [
                        _vm._v("List Sales Invoice"),
                      ]),
                    ]),
                  ]),
                  _c("hr", { staticClass: "my-2" }),
                  _vm.isDataLoaded
                    ? [
                        _c("div", { staticClass: "card" }, [
                          _c(
                            "div",
                            { staticClass: "card-header bg-light p-2" },
                            [
                              _c("div", { staticClass: "row d-flex" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-9 d-flex align-items-center",
                                  },
                                  [
                                    _c("b", [_vm._v("Customer :")]),
                                    _c("multiselect", {
                                      staticClass: "my-1 ms-2 w-50",
                                      attrs: {
                                        options: _vm.optionsCustomer,
                                        placeholder: "Pilih Customer",
                                        multiple: false,
                                        "show-labels": false,
                                        label: "userName",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.changeCust()
                                        },
                                      },
                                      model: {
                                        value: _vm.custList,
                                        callback: function ($$v) {
                                          _vm.custList = $$v
                                        },
                                        expression: "custList",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-3 d-flex justify-content-end mt-2 pe-3 align-items-start p-0",
                                    staticStyle: { "z-index": "2" },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn-xs btn btn-primary",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sendData()
                                          },
                                        },
                                      },
                                      [_vm._v(" Create ")]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "card-body p-2" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-between",
                                      staticStyle: {
                                        "font-size": "11px",
                                        width: "300px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Menampilkan 1 - 10 data dari " +
                                          _vm._s(_vm.rows) +
                                          " "
                                      ),
                                      _c(
                                        "select",
                                        {
                                          staticClass: "form-select py-1",
                                          staticStyle: {
                                            width: "75px",
                                            "font-size": "12px",
                                            padding: "0 0.5rem !important",
                                          },
                                          attrs: {
                                            "aria-label":
                                              "Default select example",
                                          },
                                        },
                                        _vm._l(_vm.pageList, function (data) {
                                          return _c(
                                            "option",
                                            { domProps: { value: data } },
                                            [_vm._v(" " + _vm._s(data) + " ")]
                                          )
                                        }),
                                        0
                                      ),
                                    ]
                                  ),
                                  _c("b-pagination", {
                                    staticClass: "m-0",
                                    attrs: {
                                      "total-rows": _vm.rows,
                                      "per-page": _vm.perPage,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "first-text",
                                          fn: function () {
                                            return [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-outline-primary btn-xs",
                                                },
                                                [_vm._v(" First ")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "prev-text",
                                          fn: function () {
                                            return [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-outline-primary btn-xs",
                                                },
                                                [_vm._v(" Prev ")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "next-text",
                                          fn: function () {
                                            return [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-outline-primary btn-xs",
                                                },
                                                [_vm._v(" Next ")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "last-text",
                                          fn: function () {
                                            return [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-outline-primary btn-xs",
                                                },
                                                [_vm._v(" Last ")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "ellipsis-text",
                                          fn: function () {
                                            return [_vm._v(" ... ")]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "page",
                                          fn: function ({ page, active }) {
                                            return [
                                              active
                                                ? _c("b", [
                                                    _vm._v(_vm._s(page)),
                                                  ])
                                                : _c("i", [
                                                    _vm._v(_vm._s(page)),
                                                  ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3004231875
                                    ),
                                    model: {
                                      value: _vm.currentPage,
                                      callback: function ($$v) {
                                        _vm.currentPage = $$v
                                      },
                                      expression: "currentPage",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("hr", { staticClass: "my-2" }),
                              _c("b-table", {
                                staticClass: "myTable",
                                attrs: {
                                  striped: "",
                                  hover: "",
                                  items: _vm.trueData,
                                  fields: _vm.tableHeader,
                                  "label-sort-asc": "",
                                  "label-sort-desc": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "cell(PoSupplier)",
                                      fn: function (data) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(data.item.PoSupplier) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(transTotal)",
                                      fn: function (data) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatNumberInt(
                                                  data.item.transTotal
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(transDiskon)",
                                      fn: function (data) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatNumberInt(
                                                  _vm.discTotal(
                                                    data.item.transDiscount
                                                  )
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(transDibayar)",
                                      fn: function (data) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatNumberInt(
                                                  data.item.transBayar
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(transBayar)",
                                      fn: function (data) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatNumberInt(
                                                  data.item.transTotal -
                                                    data.item.transBayar -
                                                    _vm.discTotal(
                                                      data.item.transDiscount
                                                    )
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(transHysPPN)",
                                      fn: function (data) {
                                        return undefined
                                      },
                                    },
                                    {
                                      key: "cell(poDate)",
                                      fn: function (data) {
                                        return undefined
                                      },
                                    },
                                    {
                                      key: "cell(transHysItem)",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "inner-table" },
                                            [
                                              _c(
                                                "table",
                                                {
                                                  staticClass:
                                                    "table table-bordered",
                                                },
                                                [
                                                  _c("thead", [
                                                    _c("tr", [
                                                      _c("th", [
                                                        _vm._v("Nama"),
                                                      ]),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticStyle: {
                                                            width: "50px",
                                                          },
                                                        },
                                                        [_vm._v("Qty")]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticStyle: {
                                                            width: "75px",
                                                          },
                                                        },
                                                        [_vm._v("Harga")]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticStyle: {
                                                            width: "75px",
                                                          },
                                                        },
                                                        [_vm._v("Total")]
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c(
                                                    "tbody",
                                                    _vm._l(
                                                      data.item.transHysItem,
                                                      function (item, index) {
                                                        return data.item
                                                          .transHysItem.length >
                                                          0
                                                          ? _c(
                                                              "tr",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "fs-10",
                                                              },
                                                              [
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "text-start",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.itemHysName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.itemHysQtyRcp
                                                                    )
                                                                  ),
                                                                ]),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "text-end",
                                                                  },
                                                                  [
                                                                    item
                                                                      .itemHysMasterPrice
                                                                      .priceValue
                                                                      ? [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.formatNumberInt(
                                                                                  item
                                                                                    .itemHysMasterPrice
                                                                                    .priceValue
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "text-end",
                                                                  },
                                                                  [
                                                                    item
                                                                      .itemHysMasterPrice
                                                                      .priceValue &&
                                                                    item.itemHysQtyRcp
                                                                      ? [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.formatNumberInt(
                                                                                  item.itemHysQtyRcp *
                                                                                    item
                                                                                      .itemHysMasterPrice
                                                                                      .priceValue
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(actions)",
                                      fn: function (data) {
                                        return [
                                          !data.item.transSINo
                                            ? _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: data.item.checkBox,
                                                    expression:
                                                      "data.item.checkBox",
                                                  },
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    data.item.checkBox
                                                  )
                                                    ? _vm._i(
                                                        data.item.checkBox,
                                                        null
                                                      ) > -1
                                                    : data.item.checkBox,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        data.item.checkBox,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            data.item,
                                                            "checkBox",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            data.item,
                                                            "checkBox",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        data.item,
                                                        "checkBox",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          data.item.transSINo
                                            ? _c(
                                                "b-dropdown",
                                                {
                                                  attrs: {
                                                    id: "dropdown-option",
                                                    right: "",
                                                    size: "xs",
                                                    variant: "primary",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "button-content",
                                                        fn: function () {
                                                          return [
                                                            _c("i", {
                                                              staticClass:
                                                                "bi-gear px-1 py-0",
                                                            }),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "b-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.detilAction(
                                                            data.item.transSINo
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Detail Invoice")]
                                                  ),
                                                  _c(
                                                    "b-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleEdit(
                                                            data.item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Edit Invoice")]
                                                  ),
                                                  _c(
                                                    "b-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handlePayment(
                                                            data.item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Bayar Invoice")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          data.item.transStatusBayar === "yes"
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-primary btn-xs",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handlePrint(
                                                        data.item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "bi-printer",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  5780852
                                ),
                              }),
                              _vm.checkPI ? [_c("loading")] : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]
                    : _c("div", [_vm._v("Loading...")]),
                ],
                2
              ),
            ])
          : _c("div", { key: "other" }, [_c("connection-timeout")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }