var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c("div", { ref: "contentDiv", staticClass: "w-100" }),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.isDataLoaded === ""
          ? _c("div", { key: "empty" }, [_c("loading")], 1)
          : _vm.isDataLoaded
          ? _c("div", { key: "truthy" }, [
              _c("ul", { staticClass: "breadcrumb m-1 mt-0" }, [
                _c("li", [
                  _c("a", { attrs: { href: "#" } }, [
                    _c("i", { staticClass: "bi-house-door" }),
                  ]),
                ]),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "text-primary",
                        attrs: { to: "/transaksi/sales-invoice-manufacture" },
                      },
                      [_vm._v("Shipment ")]
                    ),
                  ],
                  1
                ),
                _c("li", [_vm._v("Detail Shipment (Surat Jalan)")]),
                _c("li", [_vm._v(_vm._s(_vm.transHysNo))]),
              ]),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header bg-light p-2" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "row col-9 d-flex align-items-center justify-content-end pe-0",
                      },
                      [
                        _c("b-form-group", {
                          staticStyle: { width: "200px" },
                          attrs: {
                            "label-for": "coaKasbank",
                            "invalid-feedback": "Condition Harus Diisi!",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-3 d-flex justify-content-end py-2",
                        staticStyle: { "z-index": "2" },
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "btn btn-outline-secondary btn-xs py-1 me-2",
                            attrs: {
                              to: "/transaksi/sales-invoice-manufacture",
                            },
                          },
                          [
                            _c("i", { staticClass: "bi-chevron-left" }),
                            _vm._v("Kembali "),
                          ]
                        ),
                        _c(
                          "b-button-group",
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-xs",
                                on: { click: _vm.printReport },
                              },
                              [
                                _c("i", { staticClass: "bi-printer pe-2" }),
                                _vm._v("Print "),
                              ]
                            ),
                            _c(
                              "b-dropdown",
                              {
                                attrs: {
                                  id: "dropdown2",
                                  variant: "primary",
                                  size: "xs",
                                  right: "",
                                },
                              },
                              [
                                _c("div", [
                                  _c("p", { staticClass: "p-2 pb-0 m-0" }, [
                                    _vm._v("Export"),
                                  ]),
                                ]),
                                _c("b-dropdown-divider"),
                                _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.generatePDF } },
                                  [_vm._v("PDF")]
                                ),
                                _c("b-dropdown-item", [_vm._v("xls")]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "card-body p-2 printData" }, [
                  _c("div", { staticClass: "container" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("img", {
                            staticClass: "img-fluid w-25",
                            attrs: {
                              src: "/assets/img/logoPrint/dragon1.png",
                              alt: "Image Description",
                              "data-hs-theme-appearance": "default",
                            },
                          }),
                          _c("div", { staticClass: "w-50 text-center" }, [
                            _c("h1", { staticClass: "text-danger" }, [
                              _c("b", [_c("u", [_vm._v("UNPAID")])]),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _c("hr"),
                    _c(
                      "div",
                      {
                        staticClass: "row mb-4 d-flex justify-content-between",
                      },
                      [
                        _c("div", { staticClass: "col-4" }, [
                          _c("h5", { staticClass: "m-0" }, [
                            _vm._v("Invoice To"),
                          ]),
                          _c("p", { staticClass: "m-0" }, [
                            _vm._v(
                              _vm._s(_vm.data[0].transShipCustNo.userName)
                            ),
                          ]),
                          _c("p", { staticClass: "m-0" }, [
                            _vm._v(
                              _vm._s(_vm.data[0].transShipCustNo.userPhone)
                            ),
                          ]),
                          _c("p", { staticClass: "m-0" }, [
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.data[0].transShipCustNo.userAddress[0]
                                    .nameAddr
                                )
                              ),
                            ]),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.data[0].transShipCustNo.userAddress[0]
                                  .location
                              )
                            ),
                          ]),
                          _c("p"),
                        ]),
                        _c("div", { staticClass: "col-8 text-end" }, [
                          _c("h5", { staticClass: "m-0" }, [_vm._v("Pay To")]),
                          _c("p", { staticClass: "m-0" }, [
                            _vm._v("Transfer Pembayaran Ke:"),
                          ]),
                          _c("p", { staticClass: "m-0" }, [
                            _vm._v("- Bank Central Asia (BCA) 2890632281"),
                          ]),
                          _c("p", { staticClass: "m-0" }, [
                            _vm._v(
                              "- Bank Rakyat Indonesia (BRI) 649301000010567"
                            ),
                          ]),
                          _c("p", { staticClass: "m-0" }, [
                            _vm._v(
                              "- Bank Rakyat Indonesia (BRI) 384301022907534"
                            ),
                          ]),
                          _c("p", { staticClass: "m-0" }, [
                            _vm._v("Atau Cara Lain:"),
                          ]),
                          _c("p", { staticClass: "m-0" }, [
                            _vm._v("- Cash of Delivery (COD)"),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-4 mt-4" }, [
                          _c("h5", { staticClass: "m-0" }, [
                            _vm._v("Invoice Number"),
                          ]),
                          _vm.data[0].transSINo
                            ? _c("p", { staticClass: "m-0" }, [
                                _vm._v(_vm._s(_vm.data[0].transSINo)),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "col-8 mt-4 text-end" }, [
                          _c("h5", { staticClass: "m-0" }, [
                            _vm._v("Invoice Date"),
                          ]),
                          _vm.data[0].transHysEffDate
                            ? _c("p", { staticClass: "m-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatIndDate(
                                        _vm.data[0].transHysEffDate
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-header bg-light" }, [
                        _c("b", [_vm._v("INVOICE ITEMS")]),
                      ]),
                      _c("div", { staticClass: "card-body p-3" }, [
                        _c(
                          "table",
                          { staticClass: "table table-bordered m-0" },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { attrs: { width: "10px" } }, [
                                  _vm._v("NO."),
                                ]),
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v("KETERANGAN"),
                                ]),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { width: "75" },
                                  },
                                  [_vm._v("QTY")]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { width: "135" },
                                  },
                                  [_vm._v("HARGA")]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { width: "135" },
                                  },
                                  [_vm._v("TOTAL")]
                                ),
                              ]),
                            ]),
                            _vm.data
                              ? _c(
                                  "tbody",
                                  [
                                    _vm._l(
                                      _vm.data[0].transHysItem,
                                      function (dt, index) {
                                        return _c("tr", [
                                          _c("td", [_vm._v(_vm._s(index + 1))]),
                                          _c("td", [
                                            _vm._v(_vm._s(dt.itemHysName)),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(dt.itemHysQtyRcp) +
                                                " " +
                                                _vm._s(dt.itemHysUm)
                                            ),
                                          ]),
                                          _c(
                                            "td",
                                            { staticClass: "text-end" },
                                            [
                                              _vm._v(
                                                " Rp. " +
                                                  _vm._s(
                                                    dt.itemHysMasterPrice
                                                      .priceValue
                                                      ? _vm.formatNumberInt(
                                                          dt.itemHysMasterPrice
                                                            .priceValue
                                                        )
                                                      : 0
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-end" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    dt.itemHysMasterPrice
                                                      .priceValue &&
                                                      dt.itemHysQtyRcp
                                                      ? _vm.formatNumberInt(
                                                          dt.itemHysMasterPrice
                                                            .priceValue *
                                                            dt.itemHysQtyRcp
                                                        )
                                                      : 0
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    _c("tr", { staticClass: "bg-light" }, [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-end",
                                          attrs: { colspan: "4" },
                                        },
                                        [_c("b", [_vm._v("Sub Total")])]
                                      ),
                                      _c("td", { staticClass: "text-end" }, [
                                        _c("b", [
                                          _vm._v(
                                            "Rp. " +
                                              _vm._s(
                                                _vm.formatNumberInt(
                                                  _vm.subTotal(_vm.data)
                                                )
                                              )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c("tr", { staticClass: "bg-light" }, [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-end",
                                          attrs: { colspan: "4" },
                                        },
                                        [_c("b", [_vm._v("Diskon")])]
                                      ),
                                      _c("td", { staticClass: "text-end" }, [
                                        _c("b", [
                                          _vm._v(
                                            "Rp. " +
                                              _vm._s(
                                                _vm.formatNumberInt(
                                                  _vm.discTotal(
                                                    _vm.data[0].transDiscount
                                                  )
                                                )
                                              )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c("tr", { staticClass: "bg-light" }, [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-end",
                                          attrs: { colspan: "4" },
                                        },
                                        [_c("b", [_vm._v("Total")])]
                                      ),
                                      _c("td", { staticClass: "text-end" }, [
                                        _c("b", [
                                          _vm._v(
                                            "Rp. " +
                                              _vm._s(
                                                _vm.formatNumberInt(
                                                  _vm.grandTotal()
                                                )
                                              )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]),
                    _c("h5", { staticClass: "mt-3" }, [
                      _vm._v("Payment History"),
                    ]),
                    _c("table", { staticClass: "table table-bordered m-0" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { width: "10px" } }, [
                            _vm._v("NO."),
                          ]),
                          _c(
                            "th",
                            {
                              staticClass: "text-center",
                              attrs: { width: "150" },
                            },
                            [_vm._v("Trans. ID")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-center",
                              attrs: { width: "135" },
                            },
                            [_vm._v("Tgl. Bayar")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-center",
                              attrs: { width: "150" },
                            },
                            [_vm._v("Bayar Ke")]
                          ),
                          _c("th", { staticClass: "text-center" }, [
                            _vm._v("Keterangan"),
                          ]),
                          _c(
                            "th",
                            {
                              staticClass: "text-center",
                              attrs: { width: "135" },
                            },
                            [_vm._v("Jumlah")]
                          ),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(_vm.payHistory, function (dt, index) {
                            return _vm.payHistory
                              ? _c("tr", [
                                  _c("td", [_vm._v(_vm._s(index + 1))]),
                                  _c("td", [_vm._v(_vm._s(dt.transId))]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.formatIndDate(dt.transDate))
                                    ),
                                  ]),
                                  _c("td", [_vm._v(_vm._s(dt.transPayTo))]),
                                  _c("td", [_vm._v(_vm._s(dt.transDesc))]),
                                  _c("td", { staticClass: "text-end" }, [
                                    _vm._v(
                                      "Rp. " +
                                        _vm._s(
                                          _vm.formatNumberInt(dt.transAmount)
                                        )
                                    ),
                                  ]),
                                ])
                              : _c("tr", [
                                  _c("td", { attrs: { colspan: "6" } }, [
                                    _vm._v("Belum Ada Pembayaran!"),
                                  ]),
                                ])
                          }),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-end",
                                attrs: { colspan: "5" },
                              },
                              [_c("b", [_vm._v("Menunggu Pembayaran")])]
                            ),
                            _c("td", { staticClass: "text-end" }, [
                              _c("b", [
                                _vm._v(
                                  "Rp. " +
                                    _vm._s(
                                      _vm.formatNumberInt(_vm.grandAmount())
                                    )
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _c("div", { key: "other" }, [_c("connection-timeout")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }