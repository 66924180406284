<template>
  <div class="report" style="font-size: 10px">
    <table class="table table-bordered table-striped z-1 position-relative">
      <thead class="sticky-element bg-secondary">
        <tr>
          <th
            scope="col"
            class="text-center align-middle text-white"
            width="50"
          >
            <b>NO</b>
          </th>
          <th
            scope="col"
            class="text-center align-middle text-white"
            width="100"
          >
            <b>HARI/TANGGAL</b>
          </th>
          <th scope="col" class="text-center align-middle text-white" rowspan="2">
            <b>POLI (DOKTER)</b>
          </th>
          <th
            scope="col"
            class="text-center align-middle text-white"
            width="65"
          >
            <b>TOTAL PASIEN</b>
          </th>
          <th
            scope="col"
            class="text-center align-middle text-white"
            width="90"
          >
            <b>ADMIN</b>
          </th>
          <th
            scope="col"
            class="text-center align-middle text-white"
            width="90"
          >
            <b>TARIF DOKTER</b>
          </th>
          <th
            scope="col"
            class="text-center align-middle text-white"
            width="90"
          >
            <b>TINDAKAN</b>
          </th>
          <th
            scope="col"
            class="text-center align-middle text-white"
            width="90"
          >
            <b>Tarif Resep</b>
          </th>
          <th
            scope="col"
            class="text-center align-middle text-white"
            width="90"
          >
            <b>IN</b>
          </th>
          <th
            scope="col"
            class="text-center align-middle text-white"
            width="90"
          >
            <b>OUT</b>
          </th>
          <th
            scope="col"
            class="text-center align-middle text-white"
            width="90"
          >
            <b>SALDO</b>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <tr>
          <td rowspan="7"><b>1</b></td>
          <td rowspan="7"><b>Senin, 22/10/2023</b></td>
          <td class="">dr. Edy Susanto</td>
          <td class="text-end">33</td>
          <td class="text-end">Rp. 990.000</td>
          <td class="text-end">Rp. 2.700.000</td>
          <td class="text-end">Rp. 135.000</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 3.825.000</td>
          <td class="text-end">Rp. 187.000</td>
          <td class="text-end">Rp. 3.638.000</td>
          </tr>
          <tr>
          <td class="">dr. Rahma Anindita</td>
          <td class="text-end">25</td>
          <td class="text-end">Rp. 500.000</td>
          <td class="text-end">Rp. 1.520.000</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 2.020.000</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 2.020.000</td>
          </tr>
          <tr>
          <td class="">dr. Mohamad Ananto</td>
          <td class="text-end">1</td>
          <td class="text-end">Rp. 20.000</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 80.000</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 100.000</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 100.000</td>
          </tr>
          <tr>
          <td class="">dr. Amelya Amardani</td>
          <td class="text-end">0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          </tr>
          <tr>
          <td class="">Laboratotium</td>
          <td class="text-end">0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 20.000</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 20.000</td>
          </tr>
          <tr>
          <td class="">Bidan</td>
          <td class="text-end">0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          </tr>
          <tr>
          <td colspan="6"><b>TOTAL</b></td>
          <td class="text-end"><b>Rp. 5.965.000</b></td>
          <td class="text-end"><b>Rp. 187.000</b></td>
          <td class="text-end"><b>Rp. 5.778.000</b></td>
          </tr>
        </tr>
        <tr>
          <tr>
          <td rowspan="7"><b>1</b></td>
          <td rowspan="7"><b>Senin, 22/10/2023</b></td>
          <td class="">dr. Edy Susanto</td>
          <td class="text-end">33</td>
          <td class="text-end">Rp. 990.000</td>
          <td class="text-end">Rp. 2.700.000</td>
          <td class="text-end">Rp. 135.000</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 3.825.000</td>
          <td class="text-end">Rp. 187.000</td>
          <td class="text-end">Rp. 3.638.000</td>
          </tr>
          <tr>
          <td class="">dr. Rahma Anindita</td>
          <td class="text-end">25</td>
          <td class="text-end">Rp. 500.000</td>
          <td class="text-end">Rp. 1.520.000</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 2.020.000</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 2.020.000</td>
          </tr>
          <tr>
          <td class="">dr. Mohamad Ananto</td>
          <td class="text-end">1</td>
          <td class="text-end">Rp. 20.000</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 80.000</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 100.000</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 100.000</td>
          </tr>
          <tr>
          <td class="">dr. Amelya Amardani</td>
          <td class="text-end">0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          </tr>
          <tr>
          <td class="">Laboratotium</td>
          <td class="text-end">0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 20.000</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 20.000</td>
          </tr>
          <tr>
          <td class="">Bidan</td>
          <td class="text-end">0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          <td class="text-end">Rp. 0</td>
          </tr>
          <tr>
          <td colspan="6"><b>TOTAL</b></td>
          <td class="text-end"><b>Rp. 5.965.000</b></td>
          <td class="text-end"><b>Rp. 187.000</b></td>
          <td class="text-end"><b>Rp. 5.778.000</b></td>
          </tr>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatNumber } from "../function/number";
export default {
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped></style>
