<template>
  <div class="bg-dark main-body">
    <div class="data-top h-15 d-flex justify-content-between align-items-center">
      <div class="w-50 ps-3 d-flex">
        <div
          class="rounded-circle bg-white d-flex ustify-content-center align-items-center"
          style="width: 60px; height: 55px"
        >
          <img class="ms-1" :src="imgLogo" alt="Logo" style="max-width: 55px" />
        </div>
        <div class="vr mx-2 text-white"></div>
        <span class="display-4 text-white d-flex align-items-center">{{
          usahaTitle
        }}</span>
      </div>
      <div class="w-25 text-end pe-3">
        <span class="text-white display-4">{{ currentTime }}</span>
        <h5 class="text-white">{{ currentDay }}, {{ currentDate }}</h5>
      </div>
    </div>
    <div class="data-top h-55 d-flex">
      <div
        class="data-left w-50 p-3 d-flex justify-content-between flex-column flex-wrap"
      >
        <div
          class="text-center align-middle py-2 w-100"
          :class="data.currentLoket ? data.currentLoket.loketClass : 'bg-secondary'"
        >
          <span class="text-white display-4">Antrian Saat Ini</span>
        </div>
        <div
          class="h-50 w-100 d-flex justify-content-center align-items-center"
          :class="data.currentLoket ? data.currentLoket.loketClass : 'bg-secondary'"
        >
          <span class="text-white display-1">{{
            data.currentLoket ? data.currentLoket.loketNumber : ""
          }}</span>
        </div>
        <div
          class="text-center align-middle py-2 w-100"
          :class="data.currentLoket ? data.currentLoket.loketClass : 'bg-secondary'"
        >
          <span class="text-white display-4">{{
            data.currentLoket ? data.currentLoket.loketName : ""
          }}</span>
        </div>
      </div>
      <div class="data-left w-50 p-3">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/vkQM6p0dC6M?si=9frN2VmDykUu6cjY"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="data-top h-30 d-flex">
      <!-- Card -->
      <div class="h-100 w-25 p-3 pt-0" v-for="data in data.listLoket">
        <div class="card text-center w-100 h-100" :class="data.loketClass">
          <div class="card-body p-3 d-flex flex-column justify-content-between">
            <h5 class="card-title text-white">Antrian</h5>
            <span class="display-3 text-white">{{ data.loketNumber }}</span>
          </div>
          <div class="card-footer py-1 pt-2 h4 text-white">{{ data.loketName }}</div>
        </div>
      </div>
      <!-- End Card -->
    </div>
  </div>
</template>
<script>
import { getFullDate, formatIndDate } from "../function/date";

import io from "socket.io-client";
import { API } from "@/API";
export default {
  data() {
    return {
      // data: {
      //   currentLoket: {
      //     loketClass: "bg-dark",
      //     loketName: "Loket 4",
      //     loketNumber: "B001",
      //   },
      //   listLoket: [
      //     { loketClass: "bg-danger", loketName: "Loket 1", loketNumber: "B001" },
      //     { loketClass: "bg-warning", loketName: "Loket 2", loketNumber: "B001" },
      //     { loketClass: "bg-success", loketName: "Loket 3", loketNumber: "B001" },
      //     { loketClass: "bg-dark", loketName: "Loket 4", loketNumber: "B001" },
      //   ],
      // },
      data: {},
      currentDay: "",
      currentTime: new Date().toLocaleTimeString(),
      currentDate: "",
      usahaTitle: process.env.VUE_APP_TITLE || "",
      imgLogo: process.env.VUE_APP_LOGO || "",
      imgLogoMini: process.env.VUE_APP_LOGO_MINI || "",
      loket4: "",
    };
  },
  methods: {
    handleStorageChange(e) {
      if (e.key === "loket4") {
        // Handle the change in localStorage here
        console.log("localStorage changed:", e.newValue);
        this.loket4 = e.newValue;
      }
    },
    getIndonesianDayName() {
      const daysInIndonesian = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jum'at",
        "Sabtu",
      ];
      const currentDate = new Date();
      const dayIndex = currentDate.getDay();
      return daysInIndonesian[dayIndex];
    },
  },
  created() {
    // Update the time every second (1000 milliseconds)
    this.intervalId = setInterval(() => {
      this.currentTime = new Date().toLocaleTimeString();
    }, 1000);
  },
  beforeDestroy() {
    // Clean up the interval when the component is destroyed
    clearInterval(this.intervalId);
  },
  computed: {
    allState() {
      return this.$store.state;
    },
    // ...mapState(['loket4'])
  },
  async mounted() {
    // this.loket4 = localStorage.getItem("loket4");
    // console.log(this.allState);
    // window.addEventListener("storage", this.handleStorageChange);
    const date = await getFullDate();
    this.currentDate = formatIndDate(date);
    this.currentDay = this.getIndonesianDayName();

    // REST API request
    fetch(API + "/transWO/noSoundAntrian")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.data = data;
      });

    // WebSocket connection
    const socket = io(API);

    socket.on("message", (message) => {
      console.log(message);
      if (message.sound) {
        const base64Sound = message.sound;
        console.log(base64Sound);

        const binarySound = atob(base64Sound);
        const soundData = new Uint8Array(binarySound.length);

        for (let i = 0; i < binarySound.length; i++) {
          soundData[i] = binarySound.charCodeAt(i);
        }

        const blob = new Blob([soundData], { type: "audio/mp3" });
        const url = URL.createObjectURL(blob);

        const audioElement = new Audio(url);
        audioElement.play();
      }
      console.log(message);
      this.data = message;
    });
  },
};
</script>
<style scoped>
.main-body {
  height: 100vh;
  background-image: url("/public/assets/img/background/bg.png");
  background-size: 100%;
}
.h-15 {
  height: 15%;
  background-color: #4f8ac6;

  box-shadow: 0px 5px 10px 0px #132144;
}
.h-55 {
  height: 55%;
}
.h-30 {
  height: 30%;
}
</style>
