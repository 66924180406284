var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { attrs: { id: "content", role: "main" } }, [
    _c(
      "div",
      {
        staticClass: "position-fixed top-0 end-0 start-0 bg-img-start",
        staticStyle: {
          height: "32rem",
          "background-image": "url(assets/svg/components/card-6.svg)",
        },
      },
      [
        _c("div", { staticClass: "shape shape-bottom zi-1" }, [
          _c(
            "svg",
            {
              attrs: {
                preserveAspectRatio: "none",
                xmlns: "http://www.w3.org/2000/svg",
                x: "0px",
                y: "0px",
                viewBox: "0 0 1921 273",
              },
            },
            [
              _c("polygon", {
                attrs: { fill: "#fff", points: "0,273 1921,273 1921,0 " },
              }),
            ]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "container py-5 py-sm-7" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "mx-auto", staticStyle: { "max-width": "30rem" } },
        [
          _c("div", { staticClass: "card card-lg mb-5" }, [
            _c("div", { staticClass: "card-body" }, [
              _vm.isRegister === true
                ? _c(
                    "form",
                    {
                      staticClass: "js-validate needs-validation",
                      on: { submit: _vm.handleRegister },
                    },
                    [
                      _c("div", [
                        _vm._m(1),
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "fullNameSrEmail" },
                          },
                          [_vm._v("Full name")]
                        ),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "mb-4" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.first_name,
                                    expression: "first_name",
                                  },
                                ],
                                staticClass: "form-control form-control-lg",
                                attrs: {
                                  type: "text",
                                  name: "firstName",
                                  id: "firstName",
                                  placeholder: "Mark",
                                  "aria-label": "Mark",
                                  required: "",
                                },
                                domProps: { value: _vm.first_name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.first_name = $event.target.value
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "mb-4" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.last_name,
                                    expression: "last_name",
                                  },
                                ],
                                staticClass: "form-control form-control-lg",
                                attrs: {
                                  type: "text",
                                  name: "lastName",
                                  id: "lastName",
                                  placeholder: "Williams",
                                  "aria-label": "Williams",
                                  required: "",
                                },
                                domProps: { value: _vm.last_name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.last_name = $event.target.value
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "mb-4" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "signupSrEmail" },
                            },
                            [_vm._v("Your email")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.email,
                                expression: "email",
                              },
                            ],
                            staticClass: "form-control form-control-lg",
                            attrs: {
                              type: "email",
                              name: "email",
                              id: "signupSrEmail",
                              placeholder: "Markwilliams@site.com",
                              "aria-label": "Markwilliams@site.com",
                              required: "",
                            },
                            domProps: { value: _vm.email },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.email = $event.target.value
                              },
                            },
                          }),
                          _c("span", { staticClass: "invalid-feedback" }, [
                            _vm._v("Please enter a valid email address."),
                          ]),
                        ]),
                        _c("div", { staticClass: "mb-4" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "signupSrPhone" },
                            },
                            [_vm._v("No. Telepon")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "input-group",
                              attrs: {
                                "data-hs-validation-validate-class": "",
                              },
                            },
                            [
                              _vm._m(2),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.phone,
                                    expression: "phone",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  "aria-label":
                                    "Minimal nomer telepon adalah 10",
                                  required: "",
                                  minlength: "9",
                                  id: "signupSrPhone",
                                  placeholder: "xxx-xxxx-xxxx",
                                },
                                domProps: { value: _vm.phone },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.phone = $event.target.value
                                  },
                                },
                              }),
                            ]
                          ),
                          _c("span", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "Your password is invalid. Please try again."
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "mb-4" }, [
                          _vm._m(3),
                          _c(
                            "div",
                            {
                              staticClass: "input-group input-group-merge",
                              attrs: {
                                "data-hs-validation-validate-class": "",
                              },
                            },
                            [
                              (_vm.showPass ? "text" : "password") ===
                              "checkbox"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.password,
                                        expression: "password",
                                      },
                                    ],
                                    staticClass:
                                      "js-toggle-password form-control form-control-lg",
                                    attrs: {
                                      name: "password",
                                      id: "signupSrPassword",
                                      placeholder: "6+ characters required",
                                      "aria-label": "6+ characters required",
                                      required: "",
                                      minlength: "6",
                                      type: "checkbox",
                                    },
                                    domProps: {
                                      checked: Array.isArray(_vm.password)
                                        ? _vm._i(_vm.password, null) > -1
                                        : _vm.password,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.password,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.password = $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.password = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.password = $$c
                                        }
                                      },
                                    },
                                  })
                                : (_vm.showPass ? "text" : "password") ===
                                  "radio"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.password,
                                        expression: "password",
                                      },
                                    ],
                                    staticClass:
                                      "js-toggle-password form-control form-control-lg",
                                    attrs: {
                                      name: "password",
                                      id: "signupSrPassword",
                                      placeholder: "6+ characters required",
                                      "aria-label": "6+ characters required",
                                      required: "",
                                      minlength: "6",
                                      type: "radio",
                                    },
                                    domProps: {
                                      checked: _vm._q(_vm.password, null),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.password = null
                                      },
                                    },
                                  })
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.password,
                                        expression: "password",
                                      },
                                    ],
                                    staticClass:
                                      "js-toggle-password form-control form-control-lg",
                                    attrs: {
                                      name: "password",
                                      id: "signupSrPassword",
                                      placeholder: "6+ characters required",
                                      "aria-label": "6+ characters required",
                                      required: "",
                                      minlength: "6",
                                      type: _vm.showPass ? "text" : "password",
                                    },
                                    domProps: { value: _vm.password },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.password = $event.target.value
                                      },
                                    },
                                  }),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "input-group-append input-group-text",
                                  attrs: { href: "javascript:;" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showPass = !_vm.showPass
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "bi-eye" })]
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "invalid-feedback" }, [
                            _vm._v("Please enter a valid password."),
                          ]),
                        ]),
                        _c("div", { staticClass: "mb-4" }, [
                          _vm._m(4),
                          _c(
                            "div",
                            {
                              staticClass: "input-group input-group-merge",
                              attrs: {
                                "data-hs-validation-validate-class": "",
                              },
                            },
                            [
                              (_vm.showConfirm ? "text" : "password") ===
                              "checkbox"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.pass_confirm,
                                        expression: "pass_confirm",
                                      },
                                    ],
                                    staticClass:
                                      "js-toggle-password form-control form-control-lg",
                                    attrs: {
                                      name: "confPassword",
                                      id: "confirmPassword",
                                      placeholder: "6+ characters required",
                                      "aria-label": "6+ characters required",
                                      required: "",
                                      minlength: "6",
                                      type: "checkbox",
                                    },
                                    domProps: {
                                      checked: Array.isArray(_vm.pass_confirm)
                                        ? _vm._i(_vm.pass_confirm, null) > -1
                                        : _vm.pass_confirm,
                                    },
                                    on: {
                                      input: _vm.checkPass,
                                      change: function ($event) {
                                        var $$a = _vm.pass_confirm,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.pass_confirm = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.pass_confirm = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.pass_confirm = $$c
                                        }
                                      },
                                    },
                                  })
                                : (_vm.showConfirm ? "text" : "password") ===
                                  "radio"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.pass_confirm,
                                        expression: "pass_confirm",
                                      },
                                    ],
                                    staticClass:
                                      "js-toggle-password form-control form-control-lg",
                                    attrs: {
                                      name: "confPassword",
                                      id: "confirmPassword",
                                      placeholder: "6+ characters required",
                                      "aria-label": "6+ characters required",
                                      required: "",
                                      minlength: "6",
                                      type: "radio",
                                    },
                                    domProps: {
                                      checked: _vm._q(_vm.pass_confirm, null),
                                    },
                                    on: {
                                      input: _vm.checkPass,
                                      change: function ($event) {
                                        _vm.pass_confirm = null
                                      },
                                    },
                                  })
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.pass_confirm,
                                        expression: "pass_confirm",
                                      },
                                    ],
                                    staticClass:
                                      "js-toggle-password form-control form-control-lg",
                                    attrs: {
                                      name: "confPassword",
                                      id: "confirmPassword",
                                      placeholder: "6+ characters required",
                                      "aria-label": "6+ characters required",
                                      required: "",
                                      minlength: "6",
                                      type: _vm.showConfirm
                                        ? "text"
                                        : "password",
                                    },
                                    domProps: { value: _vm.pass_confirm },
                                    on: {
                                      input: [
                                        function ($event) {
                                          if ($event.target.composing) return
                                          _vm.pass_confirm = $event.target.value
                                        },
                                        _vm.checkPass,
                                      ],
                                    },
                                  }),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "input-group-append input-group-text",
                                  attrs: { href: "javascript:;" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showConfirm = !_vm.showConfirm
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "bi-eye" })]
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "text-danger",
                              class: _vm.passSame ? "d-none" : "",
                            },
                            [_vm._v("Password does not match!")]
                          ),
                        ]),
                        _vm._m(5),
                        _c(
                          "div",
                          { staticClass: "d-grid gap-2" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-lg",
                                attrs: { type: "submit" },
                              },
                              [_vm._v("Create an account")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "btn btn-link",
                                attrs: { to: "/" },
                              },
                              [
                                _vm._v(" Sudah Punya Akun? Login"),
                                _c("i", { staticClass: "bu-chevron-right" }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm.isRegister === false
                ? _c("div", [
                    _vm._m(6),
                    _c("div", { staticClass: "text-center" }, [
                      _vm._v(
                        "Kode OTP telah terkirim pada nomor +62 " +
                          _vm._s(_vm.phone) +
                          ". Kode ini hanya berlaku sampai " +
                          _vm._s(_vm.formatTime(_vm.remainingTime)) +
                          ". "
                      ),
                      _c(
                        "a",
                        {
                          class: _vm.remainingTime > 0 ? "disabled-link" : "",
                          attrs: { href: "#" },
                          on: { click: _vm.resendOTP },
                        },
                        [_vm._v("Klik disini,")]
                      ),
                      _vm._v(" untuk mengirim ulang Kode OTP"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-center mt-5" },
                      [
                        _c("v-otp-input", {
                          ref: "otpInput",
                          attrs: {
                            "input-classes": "otp-input",
                            separator: "-",
                            "num-inputs": 6,
                            "should-auto-focus": true,
                            "is-input-num": true,
                          },
                          on: {
                            "on-change": _vm.handleOnChange,
                            "on-complete": _vm.handleOnComplete,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "d-grid gap-2 mt-5" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-lg",
                          on: { click: _vm.registerAuth },
                        },
                        [_vm._v("Confirm OTP")]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "d-flex justify-content-center mb-5",
        attrs: { href: "#" },
      },
      [
        _c("img", {
          staticClass: "zi-2",
          staticStyle: { width: "12rem" },
          attrs: { src: "assets/img/logo.png", alt: "Image Description" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("div", { staticClass: "mb-5" }, [
        _c("h1", { staticClass: "display-5" }, [_vm._v("Create your account")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("+62")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-label w-100", attrs: { for: "signupSrPassword" } },
      [
        _c(
          "span",
          { staticClass: "d-flex justify-content-between align-items-center" },
          [_c("span", [_vm._v("Password")])]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-label w-100", attrs: { for: "signupSrPassword" } },
      [
        _c(
          "span",
          { staticClass: "d-flex justify-content-between align-items-center" },
          [_c("span", [_vm._v("Confirm Password")])]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-check mb-4" }, [
      _c("input", {
        staticClass: "form-check-input",
        attrs: {
          type: "checkbox",
          value: "",
          id: "termsCheckbox",
          required: "",
        },
      }),
      _c(
        "label",
        { staticClass: "form-check-label", attrs: { for: "termsCheckbox" } },
        [
          _vm._v(" I accept the "),
          _c("a", { attrs: { href: "#" } }, [_vm._v("Terms and Conditions")]),
        ]
      ),
      _c("span", { staticClass: "invalid-feedback" }, [
        _vm._v("Please accept our Terms and Conditions."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("div", { staticClass: "mb-5" }, [
        _c("h1", { staticClass: "display-5" }, [
          _vm._v("Confirm your phone pumber"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }