var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c(
        "div",
        {
          ref: "contentDiv",
          staticClass: "content container-fluid p-3 ps-0 py-0",
        },
        [
          _c(
            "b-modal",
            {
              attrs: {
                title: "List Harga",
                "ok-disabled": !this.modalInput.priceData,
              },
              on: { ok: _vm.saveModalData, hide: _vm.closeModal },
              model: {
                value: _vm.modalOpen,
                callback: function ($$v) {
                  _vm.modalOpen = $$v
                },
                expression: "modalOpen",
              },
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Jenis Harga",
                    "label-cols-sm": "4",
                    "label-cols-lg": "3",
                    "content-cols-sm": "8",
                    "content-cols-lg": "9",
                    "invalid-feedback": "Condition Harus Diisi!",
                  },
                },
                [
                  _c("multiselect", {
                    staticClass: "my-1",
                    attrs: {
                      options: _vm.priceList,
                      placeholder: "Pilih Jenis Harga",
                      multiple: false,
                      "show-labels": false,
                      "custom-label": _vm.customLabel0,
                    },
                    model: {
                      value: _vm.modalInput,
                      callback: function ($$v) {
                        _vm.modalInput = $$v
                      },
                      expression: "modalInput",
                    },
                  }),
                ],
                1
              ),
              _vm.modalInput
                ? [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-sm-4 col-lg-3 text-dark" },
                        [_vm._v("List Harga")]
                      ),
                      _c("div", { staticClass: "col-sm-8 col-lg-9" }, [
                        _c("table", { staticClass: "table table-bordered" }, [
                          _c("thead", [
                            _c("tr", [
                              _c(
                                "th",
                                { staticClass: "text-center align-middle" },
                                [_c("b", [_vm._v("Min. Qty")])]
                              ),
                              _c(
                                "th",
                                { staticClass: "text-center align-middle" },
                                [_c("b", [_vm._v("Harga Satuan")])]
                              ),
                              _c(
                                "th",
                                { staticClass: "text-center align-middle" },
                                [_c("b", [_vm._v("Diskon")])]
                              ),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.modalInput.priceData, function (price) {
                              return _c(
                                "tr",
                                [
                                  _c("td", [_vm._v(_vm._s(price.priceQty))]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.formatNumber(price.priceValue))
                                    ),
                                  ]),
                                  _vm._l(
                                    price.priceDiscount,
                                    function (discount) {
                                      return _c("td", [
                                        _vm._v(_vm._s(discount)),
                                      ])
                                    }
                                  ),
                                ],
                                2
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "b-modal",
            {
              attrs: { title: "List Add-On", "ok-disabled": "true" },
              on: { ok: _vm.saveModalDataAddOn, hide: _vm.closeModalAddOn },
              model: {
                value: _vm.modalOpenAddOn,
                callback: function ($$v) {
                  _vm.modalOpenAddOn = $$v
                },
                expression: "modalOpenAddOn",
              },
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Jenis Add-On",
                    "label-cols-sm": "4",
                    "label-cols-lg": "3",
                    "content-cols-sm": "8",
                    "content-cols-lg": "9",
                    "invalid-feedback": "Condition Harus Diisi!",
                  },
                },
                [
                  _c("multiselect", {
                    staticClass: "my-1",
                    attrs: {
                      options: _vm.emptyData,
                      placeholder: "Pilih Jenis Add-On",
                      multiple: false,
                      "show-labels": false,
                      "custom-label": _vm.customLabel0,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("transaksiAddSOKlinik", {
            attrs: {
              Item: _vm.Item,
              posts: _vm.posts,
              component: _vm.refreshComponent,
              hotSettings: _vm.hotSettings,
              widthTable: _vm.contentDivWidth,
              excelData: _vm.excelData,
              refreshMethods: _vm.onRefresh,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }