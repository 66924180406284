var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c(
        "div",
        { staticClass: "modal", attrs: { tabindex: "-1", role: "dialog" } },
        [
          _c(
            "div",
            { staticClass: "modal-dialog", attrs: { role: "document" } },
            [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "modal-body" },
                  [
                    _c("printNota", {
                      staticStyle: { "z-index": "0" },
                      attrs: { id: "printable-element" },
                    }),
                  ],
                  1
                ),
                _vm._m(1),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          ref: "contentDiv",
          staticClass: "content container-fluid p-3 ps-0 pt-0",
        },
        [
          _vm._m(2),
          _vm.isDataLoaded
            ? [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-header bg-light p-2" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "row col-9 d-flex align-items-center justify-content-end pe-0",
                        },
                        [
                          _c("b-form-group", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              "label-for": "coaKasbank",
                              "invalid-feedback": "Condition Harus Diisi!",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-3 d-flex justify-content-end py-2",
                          staticStyle: { "z-index": "2" },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "btn btn-outline-secondary btn-xs py-1 me-2",
                              attrs: { to: "/transaksi/po" },
                            },
                            [
                              _c("i", { staticClass: "bi-chevron-left" }),
                              _vm._v("Kembali "),
                            ]
                          ),
                          _c(
                            "b-button-group",
                            [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "print",
                                      rawName: "v-print",
                                      value: "#printable-element",
                                      expression: "'#printable-element'",
                                    },
                                  ],
                                  staticClass: "btn btn-primary btn-xs",
                                },
                                [
                                  _c("i", { staticClass: "bi-printer pe-2" }),
                                  _vm._v("Cek "),
                                ]
                              ),
                              _c(
                                "b-dropdown",
                                {
                                  attrs: {
                                    id: "dropdown2",
                                    variant: "primary",
                                    size: "xs",
                                    right: "",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c("p", { staticClass: "p-2 pb-0 m-0" }, [
                                      _vm._v("Export"),
                                    ]),
                                  ]),
                                  _c("b-dropdown-divider"),
                                  _c(
                                    "b-dropdown-item",
                                    { on: { click: _vm.generatePDF } },
                                    [_vm._v("PDF")]
                                  ),
                                  _c("b-dropdown-item", [_vm._v("xls")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "card-body p-2 printData" }, [
                    _c("button", { on: { click: _vm.connectToPrinter } }, [
                      _vm._v("Connect to Printer"),
                    ]),
                  ]),
                ]),
              ]
            : _c("div", [_vm._v("Loading...")]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Modal title")]),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "button" } },
        [_vm._v("Save changes")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          attrs: { type: "button", "data-dismiss": "modal" },
        },
        [_vm._v(" Close ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "breadcrumb m-1 mt-0" }, [
      _c("li", [
        _c("a", { attrs: { href: "#" } }, [
          _c("i", { staticClass: "bi-house-door" }),
        ]),
      ]),
      _c("li", [_vm._v("Report")]),
      _c("li", [_vm._v("printNota")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }