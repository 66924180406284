var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "loading-page d-flex align-items-center justify-content-center w-100 navbar-brand-logo-mini",
        staticStyle: { height: "calc(100vh - 150px)" },
      },
      [
        _c(
          "div",
          {
            staticClass: "spinner-border text-primary",
            staticStyle: { width: "2rem", height: "2rem" },
            attrs: { role: "status" },
          },
          [
            _c("span", { staticClass: "visually-hidden" }, [
              _vm._v("Loading..."),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }