var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c(
        "audio",
        { ref: "audioElement", staticClass: "d-none", attrs: { controls: "" } },
        [
          _c("source", {
            attrs: {
              src: require("../assets/audio/bell.mp3"),
              type: "audio/mpeg",
            },
          }),
          _vm._v(" Your browser does not support the audio element. "),
        ]
      ),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.isDataLoaded === ""
          ? _c("div", { key: "empty" }, [_c("loading")], 1)
          : _vm.isDataLoaded
          ? _c("div", { key: "truthy" }, [
              _c(
                "div",
                { staticClass: "content container-fluid p-3 ps-0 pt-0" },
                [
                  _c("div", { staticClass: "row my-1" }, [
                    _c(
                      "div",
                      { staticClass: "col-4 d-flex align-items-center" },
                      [
                        _c("ul", { staticClass: "breadcrumb m-0" }, [
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "/dashboard" } },
                                [
                                  _c("a", { attrs: { href: "#" } }, [
                                    _c("i", { staticClass: "bi-house-door" }),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: _vm.$route.path } },
                                [
                                  _c("a", { attrs: { href: "#" } }, [
                                    _vm._v("Resep Masuk"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "col-4 text-center" }, [
                      _c("span", { staticClass: "h2" }, [
                        _vm._v("List Resep Masuk"),
                      ]),
                    ]),
                  ]),
                  _c("hr", { staticClass: "my-2" }),
                  _vm.isDataLoaded
                    ? [
                        _c(
                          "transition",
                          { attrs: { name: "fade", mode: "out-in" } },
                          [
                            _vm.isRefreshLoaded === ""
                              ? _c("div", { key: "empty" }, [_c("loading")], 1)
                              : _vm.isRefreshLoaded
                              ? _c("div", { key: "truthy" }, [
                                  _c("div", { staticClass: "card" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "card-header bg-light p-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "row d-flex" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-9 row" },
                                              _vm._l(
                                                _vm.filterList,
                                                function (data, i) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex col-12 align-items-center pe-0 mt-2",
                                                    },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                data.filterBaris,
                                                              expression:
                                                                "data.filterBaris",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-select form-select-sm py-1 px-2",
                                                          staticStyle: {
                                                            "font-size": "12px",
                                                            "max-width":
                                                              "125px",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                data,
                                                                "filterBaris",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          !_vm.filterBaris
                                                            ? _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Filter Baris"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._l(
                                                            _vm.tableHeader,
                                                            function (micro) {
                                                              return micro.key !==
                                                                "actions"
                                                                ? _c(
                                                                    "option",
                                                                    {
                                                                      domProps:
                                                                        {
                                                                          value:
                                                                            micro.key,
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            micro.label
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                data.startFrom,
                                                              expression:
                                                                "data.startFrom",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "mx-2 form-select form-select-sm py-1 px-2",
                                                          staticStyle: {
                                                            "font-size": "12px",
                                                            "max-width":
                                                              "125px",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                data,
                                                                "startFrom",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          !data.startFrom
                                                            ? _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Tipe Pencarian "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._l(
                                                            _vm.optionQuery,
                                                            function (micro) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  domProps: {
                                                                    value:
                                                                      micro,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        micro
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-group",
                                                          staticStyle: {
                                                            width: "150px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-group-prepend",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "input-group-text py-1 px-2",
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "bi-search",
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "10px",
                                                                      },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  data.filter1,
                                                                expression:
                                                                  "data.filter1",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control form-control-sm p-1",
                                                            staticStyle: {
                                                              "font-size":
                                                                "12px",
                                                            },
                                                            attrs: {
                                                              type: "search",
                                                              placeholder:
                                                                "Cari Data...",
                                                              "aria-label":
                                                                "Recipient's username",
                                                              "aria-describedby":
                                                                "basic-addon2",
                                                            },
                                                            domProps: {
                                                              value:
                                                                data.filter1,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  data,
                                                                  "filter1",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-group px-2",
                                                          staticStyle: {
                                                            width: "150px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-group-prepend",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "input-group-text py-1 px-2",
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "bi-search",
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "10px",
                                                                      },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  data.filter2,
                                                                expression:
                                                                  "data.filter2",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control form-control-sm p-1",
                                                            staticStyle: {
                                                              "font-size":
                                                                "12px",
                                                            },
                                                            attrs: {
                                                              type: "search",
                                                              placeholder:
                                                                "Cari Data...",
                                                              "aria-label":
                                                                "Recipient's username",
                                                              "aria-describedby":
                                                                "basic-addon2",
                                                            },
                                                            domProps: {
                                                              value:
                                                                data.filter2,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  data,
                                                                  "filter2",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value: data.logic,
                                                              expression:
                                                                "data.logic",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "mx-2 form-select form-select-sm py-1",
                                                          staticStyle: {
                                                            "font-size": "12px",
                                                            "max-width": "65px",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                data,
                                                                "logic",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "and",
                                                              },
                                                            },
                                                            [_vm._v("dan")]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "or",
                                                              },
                                                            },
                                                            [_vm._v("atau")]
                                                          ),
                                                        ]
                                                      ),
                                                      _c("i", {
                                                        staticClass:
                                                          "bi-plus-circle text-primary ms-1",
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.addFilterList,
                                                        },
                                                      }),
                                                      _c("i", {
                                                        staticClass:
                                                          "bi-x-circle text-danger ms-1",
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeFilterRow(
                                                              i
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-3 d-flex justify-content-between mt-2 align-items-start p-0",
                                                staticStyle: { "z-index": "2" },
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-xs btn btn-outline-success",
                                                    attrs: { type: "button" },
                                                  },
                                                  [_vm._v(" Cari ")]
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-xs btn btn-outline-warning",
                                                    attrs: { type: "button" },
                                                  },
                                                  [_vm._v(" Reset ")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "btn-group me-2 d-none",
                                                  },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "btn btn-xs btn-primary",
                                                        attrs: {
                                                          to:
                                                            _vm.$route.path +
                                                            "/add",
                                                        },
                                                      },
                                                      [_vm._v(" Tambah ")]
                                                    ),
                                                    _c("button", {
                                                      staticClass:
                                                        "btn btn-xs btn-primary dropdown-toggle dropdown-toggle-split",
                                                      attrs: {
                                                        type: "button",
                                                        href: "javascript:;",
                                                        id: "accountNavbarDropdown",
                                                        "data-bs-toggle":
                                                          "dropdown",
                                                        "aria-expanded":
                                                          "false",
                                                        "data-bs-dropdown-animation":
                                                          "",
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "dropdown",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account",
                                                            attrs: {
                                                              "aria-labelledby":
                                                                "accountNavbarDropdown",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "flex-grow-1 ms-3",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "card-text text-body",
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "12px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Import file "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c("div", {
                                                              staticClass:
                                                                "dropdown-divider",
                                                            }),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "dropdown-item",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                              },
                                                              [_vm._v(" json")]
                                                            ),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "dropdown-item",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                              },
                                                              [_vm._v("xls")]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "card-body p-2" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center justify-content-between",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center justify-content-between",
                                                staticStyle: {
                                                  "font-size": "11px",
                                                  width: "300px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Menampilkan 1 - 10 data dari " +
                                                    _vm._s(_vm.rows) +
                                                    " "
                                                ),
                                                _c(
                                                  "select",
                                                  {
                                                    staticClass:
                                                      "form-select py-1",
                                                    staticStyle: {
                                                      width: "75px",
                                                      "font-size": "12px",
                                                      padding:
                                                        "0 0.5rem !important",
                                                    },
                                                    attrs: {
                                                      "aria-label":
                                                        "Default select example",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.pageList,
                                                    function (data) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          domProps: {
                                                            value: data,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(data) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                            _c("b-pagination", {
                                              staticClass: "m-0",
                                              attrs: {
                                                "total-rows": _vm.rows,
                                                "per-page": _vm.perPage,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "first-text",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-outline-primary btn-xs",
                                                          },
                                                          [_vm._v(" First ")]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "prev-text",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-outline-primary btn-xs",
                                                          },
                                                          [_vm._v(" Prev ")]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "next-text",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-outline-primary btn-xs",
                                                          },
                                                          [_vm._v(" Next ")]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "last-text",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-outline-primary btn-xs",
                                                          },
                                                          [_vm._v(" Last ")]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "ellipsis-text",
                                                    fn: function () {
                                                      return [_vm._v(" ... ")]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "page",
                                                    fn: function ({
                                                      page,
                                                      active,
                                                    }) {
                                                      return [
                                                        active
                                                          ? _c("b", [
                                                              _vm._v(
                                                                _vm._s(page)
                                                              ),
                                                            ])
                                                          : _c("i", [
                                                              _vm._v(
                                                                _vm._s(page)
                                                              ),
                                                            ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3004231875
                                              ),
                                              model: {
                                                value: _vm.currentPage,
                                                callback: function ($$v) {
                                                  _vm.currentPage = $$v
                                                },
                                                expression: "currentPage",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("hr", { staticClass: "my-2" }),
                                        _c("b-table", {
                                          staticClass: "myTable listTable",
                                          attrs: {
                                            striped: "",
                                            hover: "",
                                            items: _vm.liveData,
                                            fields: _vm.tableHeader,
                                            "label-sort-asc": "",
                                            "label-sort-desc": "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "cell(PoSupplier)",
                                                fn: function (data) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item.PoSupplier
                                                            .userName
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "cell(transDate)",
                                                fn: function (data) {
                                                  return [
                                                    data.item.transDate
                                                      ? [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatIndDate(
                                                                data.item
                                                                  .transDate
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "cell(transIdMember)",
                                                fn: function (data) {
                                                  return [
                                                    data.item.transIdMember
                                                      ? [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                data.item
                                                                  .transIdMember
                                                                  .userAddress[0]
                                                                  .location
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "cell(transPesanan)",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "inner-table",
                                                      },
                                                      [
                                                        _c(
                                                          "table",
                                                          {
                                                            staticClass:
                                                              "table table-bordered",
                                                          },
                                                          [
                                                            _c("thead", [
                                                              _c("tr", [
                                                                _c("th", [
                                                                  _vm._v(
                                                                    "Nama Barang"
                                                                  ),
                                                                ]),
                                                                _c("th", [
                                                                  _vm._v("Qty"),
                                                                ]),
                                                                _c("th", [
                                                                  _vm._v(
                                                                    "Harga"
                                                                  ),
                                                                ]),
                                                              ]),
                                                            ]),
                                                            _c(
                                                              "tbody",
                                                              _vm._l(
                                                                data.item
                                                                  .transPesanan,
                                                                function (
                                                                  item,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "tr",
                                                                    {
                                                                      key: index,
                                                                    },
                                                                    [
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.itemName
                                                                          )
                                                                        ),
                                                                      ]),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.itemQty
                                                                          )
                                                                        ),
                                                                      ]),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item
                                                                              .itemMasterPrice
                                                                              .priceName
                                                                          )
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "cell(actions)",
                                                fn: function (data) {
                                                  return [
                                                    data.item.transStatusSO ===
                                                    "resep"
                                                      ? _c(
                                                          "b-dropdown",
                                                          {
                                                            attrs: {
                                                              id: "dropdown-option",
                                                              right: "",
                                                              size: "xs",
                                                              variant:
                                                                "primary",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "button-content",
                                                                  fn: function () {
                                                                    return [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "bi-gear px-1 py-0",
                                                                      }),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "b-dropdown-item",
                                                              {
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.detilAction(
                                                                        data.item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("Edit")]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2876019158
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ])
                              : _c(
                                  "div",
                                  { key: "other" },
                                  [_c("connection-timeout")],
                                  1
                                ),
                          ]
                        ),
                      ]
                    : _c("div", [_vm._v("Loading...")]),
                ],
                2
              ),
            ])
          : _c("div", { key: "other" }, [_c("connection-timeout")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }