<template>
  <div class="report" style="font-size: 10px">
    <table class="table table-bordered table-hover z-1 position-relative">
      <thead class="sticky-element">
        <tr>
          <th scope="col" class="text-center" width="50"><b>No</b></th>
          <th scope="col" class="text-center"><b>User</b></th>
          <th scope="col" class="text-center" width="100"><b>Nomor WO</b></th>
          <th scope="col" class="text-center" width="85"><b>start date</b></th>
          <th scope="col" class="text-center" width="85"><b>end date</b></th>
          <th scope="col" class="text-center" width="130"><b>no. batch</b></th>
          <th scope="col" class="text-center"><b>nama item</b></th>
          <th scope="col" class="text-center" width="130"><b>qty</b></th>
          <th scope="col" class="text-center" width="130"><b>lokasi</b></th>
        </tr>
      </thead>
      <tbody v-if="realData.length > 0">
        <tr v-for="(entry, index) in realData" style="cursor: pointer">
          <td class="align-top">{{ index + 1 }}</td>
          <td class="align-top">Nama</td>
          <td class="align-top">{{ entry.woNo }}</td>
          <td class="align-top text-center" v-if="entry.woStartDate">
            {{ formatIndDate(entry.woStartDate) }}
          </td>
          <td class="align-top text-center" v-if="entry.woEndDate">
            {{ formatIndDate(entry.woEndDate) }}
          </td>
          <td class="align-top">{{ entry.woNoBatch }}</td>
          <td class="align-top">itemmnya</td>
          <td class="align-top">jumlah</td>
          <td class="align-top">bandung</td>
        </tr>
        <tr>
          <td class="align-top" rowspan="2">5</td>
          <td class="align-top" rowspan="2">Nama</td>
          
          <td class="align-top">no wo</td>
          <td class="align-top text-center">14-05-2001</td>
          <td class="align-top text-center">14-05-2001</td>
          <td class="align-top">batch</td>
          <td class="align-top">itemmnya</td>
          <td class="align-top">jumlah</td>
          <td class="align-top">bandung</td>

          <tr>
          <td class="align-top">no wo</td>
          <td class="align-top text-center">14-05-2001</td>
          <td class="align-top text-center">14-05-2001</td>
          <td class="align-top">batch</td>
          <td class="align-top">itemmnya</td>
          <td class="align-top">jumlah</td>
          <td class="align-top">bandung</td>
          </tr>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="6">Data Tidak Ditemukan</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatNumber } from "../function/number";
import { formatIndDate } from "../function/date";
export default {
  props: {
    saldoAwal: String,
    data: Array,
  },
  data() {
    return {
      saldoAkhir: "0",
      saldoIn: "0",
      saldoOut: "0",
    };
  },
  computed: {
    realData() {
      return this.data;
    },
  },
  methods: {
    formatNumber(data) {
      return formatNumber(data);
    },
    formatIndDate(date) {
      return formatIndDate(date);
    },
  },
};
</script>

<style scoped></style>
