<template>
  <div class="loading-page d-flex align-items-center justify-content-center me-5 pe-5"
      style="height: calc(100vh - 150px);">
      <div class="content container-fluid">
        <div class="row justify-content-center align-items-sm-center py-sm-10">
          <div class="col-9 col-sm-6 col-lg-4">
            <div class="text-center text-sm-end me-sm-4 mb-5 mb-sm-0">
              <img class="img-fluid" src="/assets/svg/illustrations/oc-thinking.svg" alt="Image Description"
                data-hs-theme-appearance="default">
              <img class="img-fluid" src="/assets/svg/illustrations-light/oc-thinking.svg" alt="Image Description"
                data-hs-theme-appearance="dark">
            </div>
          </div>
          <!-- End Col -->

          <div class="col-sm-6 col-lg-4 text-center text-sm-start">
            <h1 class="display-1 mb-0">522</h1>
            <p class="lead"><b>Connection Timeout,</b> silahkan cek koneksi internet anda!</p>
            <a class="btn btn-primary" @click="refreshPage">Muat ulang</a>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
    </div>
</template>

<script>
export default {
  methods: {
    refreshPage() {
      window.location.reload()
    },
  }
}
</script>
