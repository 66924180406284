<template>
  <main id="content" role="main" class="main">
    <div class="w-100" ref="contentDiv"></div>
    <transition name="fade" mode="out-in">
      <div v-if="isDataLoaded === ''" key="empty">
        <loading></loading>
      </div>
      <!-- Main Content -->
      <div v-else-if="isDataLoaded" key="truthy">
        <img v-if="showImage" :src="printImage" class="d-none" alt="" />
        <div class="content container-fluid p-3 ps-0 pt-0">
          <ul class="breadcrumb m-1 mt-0">
            <li>
              <a href="#"><i class="bi-house-door"></i></a>
            </li>
            <li>{{ posts.transTitle }}</li>
          </ul>
          <!-- Content -->
          <template v-if="isDataLoaded">
            <tableTransaksi
              :data="data"
              :fields="tableFields"
              :settings="hotSettings"
              :routingLink="routing"
              :tableWidth="contentDivWidth"
              :filterText="filterText"
            />
          </template>
          <div v-else>Loading...</div>
        </div>
      </div>

      <!-- Error -->
      <div v-else key="other">
        <connection-timeout></connection-timeout>
      </div>
    </transition>
  </main>
</template>

<script>
import { getListPelayanan, cancelWO } from "../components/services/components";
import tableTransaksi from "../components/tableTransaksi.vue";
import {
  getFullDate,
  getIndonesianDayName,
  formatIndDate,
} from "@/components/function/date";

import loading from "../components/loading.vue";
import connectionTimeout from "../components/connectionTimeout.vue";
export default {
  components: {
    loading,
    connectionTimeout,
    tableTransaksi,
  },
  data() {
    return {
      showImage: true,
      currentTime: new Date().toLocaleTimeString(),
      currentDate: "",
      iconPrint: process.env.VUE_APP_LOGO,
      isDataLoaded: "",
      filterText: "",
      sendData: {},
      transData: [],
      contentDivWidth: 0,
      routing: "",
      tableFields: [],
      component: [],
      hotSettings: {
        licenseKey: "non-commercial-and-evaluation",
        colHeaders: true,

        rowHeaderWidth: 35,
        startRows: 10,
        readOnly: true,
        columns: [],
        filters: true, // Enable the filters plugin
        dropdownMenu: [
          "alignment",
          "filter_by_condition",
          "filter_operators",
          "filter_by_value",
          "filter_action_bar",
        ],
        columnSorting: true, // Enable column sorting
      },
      data: [],
      posts: {
        transTitle: "Pelayanan",
        transRouting: "wo-pelayanan",
        transProperties: [
          {
            dataName: "menuList",
            dataMicro: [
              {
                key: "woNo",
                microName: "No. WO",
                microType: "text",
                width: 100,
                microText: "",
                microFormat: "",
                microClass: "",
              },
              {
                key: "woNoBatch",
                microName: "Antrian",
                microType: "text",
                width: 75,
                microText: "",
                microFormat: "",
                microClass: "",
              },
              {
                key: "woUserCust.userCode",
                microName: "No. RM",
                microType: "text",
                width: 100,
                microText: "",
                microFormat: "",
                microClass: "",
              },
              {
                key: "woUserCust.userName",
                microName: "Pasien",
                microType: "text",
                width: 0,
                microText: "",
                microFormat: "",
                microClass: "",
              },
              {
                key: "woItemName",
                microName: "Layanan",
                microType: "text",
                width: 0,
                microText: "",
                microFormat: "",
                microClass: "",
              },
              {
                key: "woStartDate",
                microName: "Start Date",
                microType: "date",
                width: 100,
                microText: "",
                microFormat: "DD-MM-YYYY",
                microClass: "",
              },
              // { key: 'operation', microName: 'Operation', microType: 'text', width: 0, microText: '', microFormat: '', microClass: '' },
              {
                key: "woStatus",
                microName: "Status",
                microType: "text",
                width: 55,
                microText: "",
                microFormat: "",
                microClass: "",
              },
              {
                key: "opsi",
                microName: "",
                microType: "dropdown",
                width: 50,
                microText: "",
                microFormat: "",
                microClass: "",
              },
            ],
          },
          {
            dataName: "routingButton",
            dataTitle: "button",
            dataClass: "col-md-12",
            dataMicro: [
              {
                key: "add",
                microName: "",
                microRouting: "add",
                microText: "",
                microClass: "btn-primary",
              },
            ],
          },
        ],
      },
    };
  },
  async mounted() {
    const timeout = 30000; // 10 seconds
    try {
      const currentDate = await getFullDate();
      this.currentDate = currentDate;
      const data = await getListPelayanan();
      console.log("ini data", data);
      this.getComponent();
      const trueFields = this.getFieldTable();

      const timeoutPromise = new Promise((resolve) => setTimeout(resolve, timeout));

      // Wait for either finalPromise or timeoutPromise to resolve
      const result = await Promise.race([trueFields, timeoutPromise]);

      this.hotSettings.columns = trueFields;
      this.isDataLoaded = true;
      this.routing = this.posts.transRouting;
      this.data = data;
      console.log("coba ges", this.data);
      // console.log("tester",this.data);
      this.$nextTick(() => {
        this.getContentWidth();
      });
      this.isDataLoaded = result !== undefined;
    } catch (error) {
      this.isDataLoaded = false;
    }
  },
  computed: {
    printImage() {
      return "../assets/img/print/logo-klinik.png";
    },
    filteredTableData() {
      const filterText = this.filterText.toLowerCase().trim();
      return this.data.filter((row) => {
        for (let key in row) {
          const value = String(row[key]);
          if (value.toLowerCase().includes(filterText)) {
            return true;
          }
        }
        return false;
      });
    },
  },
  created() {
    // Update the time every second (1000 milliseconds)
    this.intervalId = setInterval(() => {
      this.currentTime = new Date().toLocaleTimeString();
    }, 1000);
  },
  methods: {
    alertDelete(typeData, data) {
      const _id = data._id;
      this.$swal({
        title: "Anda Yakin?",
        text: "Anda Akan Membatalkan Pelayanan Ini!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonText: "Kembali",
        confirmButtonText: "Cancel Layanan!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.alertLoading();
          if (typeData === "Item") {
            try {
              const mainData = {
                woStatus: "Closed",
              };
              await cancelWO(mainData, _id);
              this.successDelete();
            } catch (error) {
              alert(error);
              this.failDelete();
            }
            location.reload();
          }
        }
      });
    },
    alertLoading() {
      this.$swal({
        title: "Loading...",
        text: "Mohon Ditunggu ya.",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        willOpen: () => {
          this.$swal.showLoading();
        },
      });
    },
    successDelete() {
      this.$swal("Berhasil!", "Data berhasil Dihapus", "success");
    },
    failDelete() {
      this.$swal("Gagal!", "Data gagal Dihapus", "error");
    },
    async printReceipt(data) {
      console.log(data);
      const hari = await getIndonesianDayName();
      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(`
        <html>
        <head>
          <title>Receipt</title>
          <style>
          
    @page { size:48mm 100mm } /* output size */
    body.receipt .sheet { width: 48mm; height: 100mm } /* sheet size */
    @media print { body.receipt { width: 48mm } } /* fix for Chrome */
            /* Define your CSS for the receipt here */
            body {
              font-family: Arial, sans-serif;
              width: 48mm; /* Adjust the width to match your paper size */
              height: 100mm;
            }
          </style>
        </head>
        <body class="receipt" style="margin-left:0">
          <!-- Your receipt content goes here -->
          <div class="sheet" style="text-align: center">
            <img src="${
              this.printImage
            }" alt="Your Image" style="width: 100px; height: 90px"> 
            <p><b>KLINIK UTAMA MUTIARA AISHA</b></p>
            <p style="font-size:11px;">JL. KI HAJAR DEWANTARA 04 SELOSARI MAGETAN</p>
            <p style="font-size:11px; margin: 0">082331 200 500</p>
            <h1 style="font-size: 50px !important;  ">${data.woNoBatch}</h1>
            <p><b>${data.woItemName}</b></p>
            <p style="font-size:11px; margin-top: 0"><b>${hari}, ${formatIndDate(
        this.currentDate
      )} | ${this.currentTime}</b></p>
      <p><b>SEMOGA SEHAT SELALU</b></p>
      <hr>
          </div>
        </body>
        </html>
      `);
      this.showImage = false;
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
      this.$nextTick(() => {
        this.showImage = true;
      });
    },
    getData() {
      return this.posts.transData.reduce((result, item) => {
        if (Array.isArray(item.header)) {
          item.header.forEach((microItem) => {
            result.push(microItem);
          });
        }
        return result;
      }, []);
    },
    serviceAdd() {
      this.$router.push("/transaksi/" + this.routing + "/add");
    },
    buttonRenderer(instance, td, row, col, prop, value, cellProperties) {
      const rowData = this.data[row];
      // Clear the cell content
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }

      // Create the buttons container element
      const buttonsContainer = document.createElement("div");

      if (rowData.woStatus !== "Closed") {
        // Create the first button
        const button0 = document.createElement("button");
        button0.innerHTML = '<i class="bi-x-lg"></i>';
        button0.className = "btn btn-xs btn-outline-danger px-1 py-0 rounded-circle";
        button0.addEventListener("click", () => {
          // Handle button 1 click event
          this.handleButtonClick(rowData, 0);
        });
        buttonsContainer.appendChild(button0);
        td.appendChild(buttonsContainer);
      }

      // Create the first button
      const button1 = document.createElement("button");
      button1.innerHTML = '<i class="bi-eye-fill"></i>';
      button1.className = "btn btn-xs btn-outline-success px-1 py-0 rounded-circle";
      button1.addEventListener("click", () => {
        // Handle button 1 click event
        this.handleButtonClick(rowData, 1);
      });
      buttonsContainer.appendChild(button1);
      td.appendChild(buttonsContainer);

      // Create the first button
      const button2 = document.createElement("button");
      button2.innerHTML = '<i class="bi-printer-fill"></i>';
      button2.className = "btn btn-xs btn-outline-primary px-1 py-0 rounded-circle";
      button2.addEventListener("click", () => {
        // Handle button 1 click event
        this.handleButtonClick(rowData, 2);
      });
      buttonsContainer.appendChild(button2);
      // Append the buttons container to the cell
      td.appendChild(buttonsContainer);

      // Add necessary CSS classes
      td.classList.add("htCenter"); // Adjust the class based on your styling needs
    },
    handleButtonClick(rowData, buttonIndex) {
      // Handle the button click event with row data
      if (buttonIndex === 0) {
        console.log(rowData);
        this.alertDelete("Item", rowData);
      } else if (buttonIndex === 1) {
        this.$router.push({
          name: this.routing + "-detail",
          params: { dataDetail: rowData.woNo },
        });
      } else if (buttonIndex === 2) {
        this.printReceipt(rowData);
      } else if (buttonIndex === 3) {
        this.$router.push({
          name: this.routing + "-edit",
          params: { dataDetail: this.sendData },
        });
      }
    },
    async getComponent() {
      this.component = this.posts.transProperties.find(
        ({ dataName }) => dataName === "menuList"
      ).dataMicro;
    },
    getFieldTable() {
      const fields = this.component;
      const trueFields = fields.map((x) => {
        if (x.microType === "date") {
          return {
            data: x.key,
            title: x.microName,
            type: x.microType,
            width: 110,
          };
        } else if (x.microType === "numeric") {
          return {
            data: x.key,
            title: x.microName,
            type: x.microType,
            width: 125,
            renderer: (instance, td, row, col, prop, value) => {
              if (typeof value === "number") {
                const formattedValue = value
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                td.innerText = formattedValue;
              } else {
                td.innerText = value;
              }
            },
            validator: (value, callback) => {
              if (value > 1000000000000 || value < 0) {
                callback(false);
              } else {
                callback(true);
              }
            },
          };
        } else if (x.microType === "dropdown") {
          return {
            data: "action",
            title: "opsi",
            width: 75,

            renderer: this.buttonRenderer,
          };
        } else {
          if (x.width <= 0) {
            return { data: x.key, title: x.microName, type: x.microType };
          } else {
            return {
              data: x.key,
              title: x.microName,
              type: x.microType,
              width: x.width,
            };
          }
        }
      });
      return trueFields;
    },
    getContentWidth() {
      const contentDiv = this.$refs.contentDiv;
      let columns = 0;
      const tes = this.hotSettings.columns.filter((x) => x.type === "text" && !x.width)
        .length;
      const widthTes = this.hotSettings.columns;
      for (let i = 0; i < widthTes.length; i++) {
        if (widthTes[i].width >= 0 || widthTes[i].width !== undefined) {
          columns += widthTes[i].width;
        }
      }
      if (contentDiv) {
        this.contentDivWidth = (contentDiv.offsetWidth - 85 - columns) / tes;
      }
    },
  },
};
</script>
