var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "row mb-2" },
      [
        _c(
          "b-form-group",
          {
            attrs: {
              "label-for": "coaKasbank",
              "invalid-feedback": "Condition Harus Diisi!",
            },
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-8 d-flex align-items-center" },
                [
                  _c("multiselect", {
                    staticClass: "my-1",
                    attrs: {
                      id: "coaKasbank",
                      options: _vm.data,
                      placeholder: "Pilih Kas/Bank",
                      multiple: false,
                      "show-labels": false,
                      "custom-label": _vm.customLabel,
                    },
                    on: { input: _vm.listData },
                    model: {
                      value: _vm.newData,
                      callback: function ($$v) {
                        _vm.newData = $$v
                      },
                      expression: "newData",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-4 d-flex align-items-center" },
                [
                  _c("b-form-input", {
                    attrs: { type: "month" },
                    on: { change: _vm.getData },
                    model: {
                      value: _vm.getDate,
                      callback: function ($$v) {
                        _vm.getDate = $$v
                      },
                      expression: "getDate",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.header, function (micro, index) {
        return _c(
          "div",
          { key: index, class: ["col-sm-6 col-lg-3 mb-3", micro.microClass] },
          [
            _c("div", { staticClass: "card card-hover-shadow h-100" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h6", { staticClass: "card-subtitle" }, [
                  _vm._v(_vm._s(micro.microName)),
                ]),
                _c("div", { staticClass: "row align-items-center gx-2" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "h1",
                      {
                        staticClass:
                          "card-title d-flex align-items-center my-2",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "h4 m-0 pe-1",
                            class: micro.value < 0 ? "text-danger" : "",
                          },
                          [_vm._v(_vm._s(micro.microCurrency))]
                        ),
                        _vm._v(" "),
                        _c("numberCounter", {
                          class: micro.value < 0 ? "text-danger" : "",
                          attrs: { "target-number": micro.value },
                        }),
                      ],
                      1
                    ),
                  ]),
                  micro.microName === "Saldo Akhir" && micro.value < 0
                    ? _c("div", { staticStyle: { "font-size": "11px" } }, [
                        _vm._v("Saldo Minus! Mohon cek transaksi"),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]
        )
      }),
      0
    ),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header bg-light p-2" }, [
        _c("div", { staticClass: "row" }, [
          _vm.filterOption === "Data"
            ? _c(
                "div",
                { staticClass: "col-10 d-flex align-items-center" },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterFields,
                          expression: "filterFields",
                        },
                      ],
                      staticClass: "form-select form-select-sm py-1 px-2",
                      staticStyle: {
                        "font-size": "12px",
                        "max-width": "125px",
                      },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.filterFields = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("Filter Baris"),
                      ]),
                      _vm._l(_vm.settings.columns, function (list) {
                        return _c(
                          "option",
                          { domProps: { value: list.data } },
                          [_vm._v(_vm._s(list.title))]
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterSort,
                          expression: "filterSort",
                        },
                      ],
                      staticClass: "mx-2 form-select form-select-sm py-1 px-2",
                      staticStyle: {
                        "font-size": "12px",
                        "max-width": "125px",
                      },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.filterSort = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("Mulai Dari"),
                      ]),
                      _c("option", { attrs: { value: "asc" } }, [
                        _vm._v("Atas"),
                      ]),
                      _c("option", { attrs: { value: "desc" } }, [
                        _vm._v("Bawah"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "input-group",
                      staticStyle: { width: "200px" },
                    },
                    [
                      _vm._m(0),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filter,
                            expression: "filter",
                          },
                        ],
                        staticClass: "form-control form-control-sm p-1",
                        staticStyle: { "font-size": "12px" },
                        attrs: {
                          type: "text",
                          placeholder: "Cari Data...",
                          "aria-label": "Recipient's username",
                          "aria-describedby": "basic-addon2",
                        },
                        domProps: { value: _vm.filter },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.filter = $event.target.value
                          },
                        },
                      }),
                      _c("div", { staticClass: "input-group-append" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-outline-secondary btn-xs",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.filter = ""
                              },
                            },
                          },
                          [_vm._v("hapus")]
                        ),
                      ]),
                    ]
                  ),
                  _vm._m(1),
                  _vm._l(_vm.filterList, function (list) {
                    return _c(
                      "div",
                      {
                        staticClass: "input-group ms-2",
                        staticStyle: { width: "125px" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: list.filter,
                              expression: "list.filter",
                            },
                          ],
                          staticClass: "form-control form-control-sm p-1",
                          staticStyle: { "font-size": "12px" },
                          attrs: {
                            disabled: _vm.filter === "",
                            type: "text",
                            placeholder: "...",
                            "aria-label": "Recipient's username",
                            "aria-describedby": "basic-addon2",
                          },
                          domProps: { value: list.filter },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(list, "filter", $event.target.value)
                            },
                          },
                        }),
                      ]
                    )
                  }),
                  _c("i", {
                    staticClass: "bi-plus-circle text-primary ms-1",
                    staticStyle: { cursor: "pointer" },
                    on: { click: _vm.addFilter },
                  }),
                  _c("i", {
                    staticClass: "bi-x-circle text-danger ms-1",
                    staticStyle: { cursor: "pointer" },
                    on: { click: _vm.removeFilter },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-secondary btn-xs ms-1",
                      attrs: { type: "button" },
                    },
                    [_vm._v("cari")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-secondary btn-xs ms-1",
                      attrs: { type: "button" },
                      on: { click: _vm.resetFilter },
                    },
                    [_vm._v("reset")]
                  ),
                ],
                2
              )
            : _vm.filterOption === "Period"
            ? _c("div", { staticClass: "col-10 d-flex align-items-center" }, [
                _c(
                  "span",
                  { staticClass: "me-2", staticStyle: { "font-size": "12px" } },
                  [_vm._v("Periode Waktu :")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "input-group",
                    staticStyle: { width: "125px" },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.startDate,
                          expression: "startDate",
                        },
                      ],
                      staticClass: "form-control form-control-sm p-1",
                      staticStyle: { "font-size": "12px" },
                      attrs: {
                        type: "date",
                        placeholder: "Cari Data...",
                        "aria-label": "Recipient's username",
                        "aria-describedby": "basic-addon2",
                      },
                      domProps: { value: _vm.startDate },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.startDate = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "span",
                  { staticClass: "mx-3", staticStyle: { "font-size": "12px" } },
                  [_vm._v("Sampai")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "input-group",
                    staticStyle: { width: "125px" },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.endDate,
                          expression: "endDate",
                        },
                      ],
                      staticClass: "form-control form-control-sm p-1",
                      staticStyle: { "font-size": "12px" },
                      attrs: {
                        type: "date",
                        placeholder: "Cari Data...",
                        "aria-label": "Recipient's username",
                        "aria-describedby": "basic-addon2",
                      },
                      domProps: { value: _vm.endDate },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.endDate = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "span",
                  { staticClass: "mx-3", staticStyle: { "font-size": "12px" } },
                  [_vm._v("-")]
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filterSort,
                        expression: "filterSort",
                      },
                    ],
                    staticClass: "mx-2 form-select form-select-sm py-1 px-2",
                    staticStyle: { "font-size": "12px", "max-width": "125px" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.filterSort = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("Mulai Dari"),
                    ]),
                    _c("option", { attrs: { value: "asc" } }, [_vm._v("Atas")]),
                    _c("option", { attrs: { value: "desc" } }, [
                      _vm._v("Bawah"),
                    ]),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-secondary btn-xs ms-1",
                    attrs: { type: "button" },
                  },
                  [_vm._v("cari")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-secondary btn-xs ms-1",
                    attrs: { type: "button" },
                    on: { click: _vm.resetFilter },
                  },
                  [_vm._v("reset")]
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "col-2 d-flex justify-content-end",
              staticStyle: { "z-index": "2" },
            },
            [
              _c(
                "b-button-group",
                [
                  _c(
                    "b-dropdown",
                    {
                      staticClass: "dropdown-without-icon",
                      attrs: {
                        id: "dropdown1",
                        variant: "primary",
                        size: "xs",
                        right: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "button-content",
                          fn: function () {
                            return [
                              _c("i", { staticClass: "bi-funnel-fill pe-1" }),
                              _vm._v("Filter "),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: { active: _vm.filterOption === "Data" },
                          on: {
                            click: function ($event) {
                              _vm.filterOption = "Data"
                            },
                          },
                        },
                        [_vm._v("Filter by Data")]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: { active: _vm.filterOption === "Period" },
                          on: {
                            click: function ($event) {
                              _vm.filterOption = "Period"
                            },
                          },
                        },
                        [_vm._v("Filter by Period")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        id: "dropdown2",
                        variant: "primary",
                        size: "xs",
                        right: "",
                      },
                    },
                    [
                      _c("div", [
                        _c("p", { staticClass: "p-2 pb-0 m-0" }, [
                          _vm._v("Export"),
                        ]),
                      ]),
                      _c("b-dropdown-divider"),
                      _c(
                        "b-dropdown-item",
                        { on: { click: _vm.generatePDF } },
                        [_vm._v("PDF")]
                      ),
                      _c("b-dropdown-item", [_vm._v("xls")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "card-body p-2" },
        [
          _c(
            "div",
            {
              staticClass: "d-flex align-items-center justify-content-between",
            },
            [
              _c("span", { staticStyle: { "font-size": "11px" } }, [
                _vm._v(_vm._s(_vm.dataRangeText)),
              ]),
              _c("nav", { attrs: { "aria-label": "..." } }, [
                _c(
                  "ul",
                  { staticClass: "pagination pagination-sm m-0" },
                  [
                    _vm._m(2),
                    _vm._l(_vm.totalPages, function (pageNumber) {
                      return _c(
                        "li",
                        {
                          key: pageNumber,
                          staticClass: "page-item",
                          class: { active: _vm.currentPage === pageNumber },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "page-link",
                              staticStyle: { "font-size": "12px" },
                              attrs: { href: "#" },
                            },
                            [_vm._v(_vm._s(pageNumber))]
                          ),
                        ]
                      )
                    }),
                    _vm._m(3),
                  ],
                  2
                ),
              ]),
            ]
          ),
          _c("hr", { staticClass: "my-2" }),
          _c("hot-table", {
            staticStyle: { "z-index": "0" },
            attrs: {
              settings: _vm.settings,
              data: _vm.hotData,
              rowHeaders: true,
              colHeaders: true,
              colWidths: _vm.tableWidth,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text py-1 px-2" }, [
        _c("i", {
          staticClass: "bi-search",
          staticStyle: { "font-size": "10px" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "select",
      {
        staticClass: "mx-2 form-select form-select-sm py-1 px-2",
        staticStyle: { "font-size": "12px", "max-width": "50px" },
      },
      [
        _c("option", { attrs: { value: "and" } }, [_vm._v("dan")]),
        _c("option", { attrs: { value: "or" } }, [_vm._v("atau")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "page-item" }, [
      _c(
        "a",
        {
          staticClass: "page-link",
          staticStyle: { "font-size": "12px" },
          attrs: { href: "#" },
        },
        [_c("i", { staticClass: "bi-chevron-double-left" })]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          staticClass: "page-link",
          staticStyle: { "font-size": "12px" },
          attrs: { href: "#" },
        },
        [_c("i", { staticClass: "bi-chevron-double-right" })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }