var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form", [
    _c("div", { staticClass: "row my-1" }, [
      _c("div", { staticClass: "col-4 d-flex align-items-center" }, [
        _c("ul", { staticClass: "breadcrumb m-0" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/dashboard" } }, [
                _c("a", { attrs: { href: "#" } }, [
                  _c("i", { staticClass: "bi-house-door" }),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { name: _vm.posts.transRouting.replace("-detail", "") },
                  },
                },
                [
                  _c("a", { attrs: { href: "#" } }, [
                    _vm._v(_vm._s(_vm.posts.transTitle)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("li", [_vm._v("Detail")]),
        ]),
      ]),
      _c("div", { staticClass: "col-4 align-items-center" }, [
        _c(
          "h4",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
              "flex-direction": "column",
            },
          },
          [_vm._v(" Detail " + _vm._s(_vm.posts.transTitle) + " ")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "col-4 d-flex justify-content-end align-items-center" },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: { name: _vm.posts.transRouting.replace("-detail", "") },
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary btn-xs py-1 me-2",
                  attrs: { type: "button" },
                },
                [
                  _c("i", { staticClass: "bi-chevron-left" }),
                  _vm._v("Kembali "),
                ]
              ),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-xs py-1",
              attrs: { type: "button" },
              on: { click: _vm.printReceipt },
            },
            [_c("i", { staticClass: "bi-printer pe-2" }), _vm._v("Print ")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "row scale-9 position-relative",
        staticStyle: { width: "114%", "z-index": "1" },
      },
      _vm._l(_vm.dummyItems, function (field, index) {
        return field.componentName === "fieldHeader"
          ? _c("div", { key: index, class: [field.componentClass] }, [
              _c("div", { staticClass: "card h-100" }, [
                _c(
                  "div",
                  { staticClass: "row px-3" },
                  _vm._l(field.componentMicro, function (micro) {
                    return _c(
                      "div",
                      { class: ["", micro.microClass] },
                      [
                        micro.microType === "select"
                          ? _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: micro.microName + " :",
                                      "label-cols-sm": "4",
                                      "label-cols-lg": "3",
                                      "content-cols-sm": "8",
                                      "content-cols-lg": "9",
                                    },
                                  },
                                  [
                                    _c("b-form-select", {
                                      staticClass:
                                        "mt-1 form-control-sm py-0 form-control",
                                      attrs: {
                                        options: micro.microOptions,
                                        size: "sm",
                                        disabled: micro.isDisabled,
                                      },
                                      model: {
                                        value: micro.value,
                                        callback: function ($$v) {
                                          _vm.$set(micro, "value", $$v)
                                        },
                                        expression: "micro.value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : micro.microType === "multiselect"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-for": micro.microName,
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                    "invalid-feedback":
                                      "Condition Harus Diisi!",
                                  },
                                },
                                [
                                  _c("multiselect", {
                                    staticClass: "my-1",
                                    attrs: {
                                      id: micro.microName,
                                      options: micro.microOptions,
                                      placeholder: "Select one",
                                      multiple: false,
                                      "show-labels": false,
                                      "custom-label": _vm.customLabel,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.changeCondition(micro.value)
                                      },
                                    },
                                    model: {
                                      value: micro.value,
                                      callback: function ($$v) {
                                        _vm.$set(micro, "value", $$v)
                                      },
                                      expression: "micro.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : micro.key === "productQty"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass:
                                      "form-control form-control-sm py-0",
                                    attrs: {
                                      type: micro.microType,
                                      placeholder: micro.microText,
                                      required: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.getQty(micro.value)
                                      },
                                    },
                                    model: {
                                      value: micro.value,
                                      callback: function ($$v) {
                                        _vm.$set(micro, "value", $$v)
                                      },
                                      expression: "micro.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass:
                                      "form-control form-control-sm py-0",
                                    attrs: {
                                      type: micro.microType,
                                      placeholder: micro.microText,
                                      required: "",
                                      disabled: micro.isDisabled,
                                    },
                                    model: {
                                      value: micro.value,
                                      callback: function ($$v) {
                                        _vm.$set(micro, "value", $$v)
                                      },
                                      expression: "micro.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ])
          : _vm._e()
      }),
      0
    ),
    _c("div", { staticClass: "row mb-3" }, [
      _c(
        "div",
        { staticClass: "col-md-12 scale-12" },
        _vm._l(_vm.component, function (field) {
          return field.componentName === "fieldNote"
            ? _c("div", { class: ["h-100", field.componentClass] }, [
                _c(
                  "div",
                  { staticClass: "card h-100" },
                  [
                    _c("div", { staticClass: "card-header bg-light py-1" }, [
                      _vm._v(" " + _vm._s(field.componentTitle) + " "),
                    ]),
                    _vm._l(field.componentMicro, function (micro) {
                      return [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: micro.value,
                              expression: "micro.value",
                            },
                          ],
                          staticClass:
                            "form-control form-control-sm py-1 h-100",
                          staticStyle: { resize: "none" },
                          attrs: { disabled: "", placeholder: micro.microText },
                          domProps: { value: micro.value },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(micro, "value", $event.target.value)
                            },
                          },
                        }),
                      ]
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e()
        }),
        0
      ),
    ]),
    _c(
      "div",
      { staticClass: "row mb-3" },
      _vm._l(_vm.excelData.woRecipeItem, function (data) {
        return _vm.excelData.woRecipeItem.length > 0
          ? _c(
              "div",
              { staticClass: "row", staticStyle: { "padding-bottom": "10px" } },
              [
                _c("div", { staticClass: "col-4" }, [
                  _c("ul", { staticClass: "list-group" }, [
                    _c("b", [
                      _c(
                        "li",
                        {
                          staticClass: "list-group-item active",
                          staticStyle: {
                            padding: "10px",
                            "border-radius": "5px 5px 0 0",
                          },
                        },
                        [
                          _c("i", {
                            class:
                              ["bi bi-"] +
                              data.processCode +
                              ["-square-fill "] +
                              ["list-group-icon"],
                          }),
                          _vm._v(" " + _vm._s(data.processName) + " "),
                        ]
                      ),
                    ]),
                    _c(
                      "li",
                      {
                        staticClass: "list-group-item",
                        staticStyle: {
                          "padding-bottom": "2px",
                          "padding-top": "1px",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "bi bi-box-seam list-group-icon",
                        }),
                        _c("b", [_vm._v("Menghasilkan :")]),
                        _c("br"),
                        _c("table", { staticClass: "table" }, [
                          _c("tr", [
                            _c("td"),
                            _c("td", [
                              _vm._v(_vm._s(data.recipeItemResult.itemName)),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(data.recipeQty) +
                                  " (" +
                                  _vm._s(data.recipeUM) +
                                  ")"
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "list-group-item",
                        staticStyle: {
                          "padding-bottom": "2px",
                          "padding-top": "1px",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "bi bi-receipt list-group-icon",
                        }),
                        _vm._v("Material :"),
                        _c("br"),
                        _c(
                          "table",
                          { staticClass: "table" },
                          _vm._l(data.recipeMaterial, function (itemData) {
                            return _c("tr", [
                              _c("td", [_vm._v(_vm._s(itemData.itemName))]),
                              _c("td", [_vm._v(_vm._s(itemData.qty))]),
                              _c("td", [_vm._v(_vm._s(itemData.UM))]),
                            ])
                          }),
                          0
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-4" }, [
                  _c("ul", { staticClass: "list-group" }, [
                    _c("b", [
                      _c(
                        "li",
                        {
                          staticClass: "list-group-item active",
                          staticStyle: {
                            padding: "10px",
                            "border-radius": "5px 5px 0 0",
                          },
                        },
                        [
                          _c("i", {
                            class: ["bi bi-receipt "] + ["list-group-icon"],
                          }),
                          _vm._v(" Penggunaan Material "),
                        ]
                      ),
                    ]),
                    _c(
                      "li",
                      {
                        staticClass: "list-group-item",
                        staticStyle: {
                          "padding-bottom": "2px",
                          "padding-top": "1px",
                        },
                      },
                      [
                        _c(
                          "table",
                          { staticClass: "table" },
                          _vm._l(data.recipeMaterial, function (itemData) {
                            return _c("tr", [
                              _c("td", [_vm._v(_vm._s(itemData.itemName))]),
                              _c("td", [
                                _vm._v(" " + _vm._s(itemData.qty) + " "),
                              ]),
                              _c("td", [_vm._v(_vm._s(itemData.UM))]),
                            ])
                          }),
                          0
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-4" }, [
                  _c("ul", { staticClass: "list-group" }, [
                    _c("b", [
                      _c(
                        "li",
                        {
                          staticClass: "list-group-item active",
                          staticStyle: {
                            padding: "10px",
                            "border-radius": "5px 5px 0 0",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "bi bi-box-seam list-group-icon",
                          }),
                          _c("b", [_vm._v("Hasil Work Order : ")]),
                          _vm._v(
                            " " + _vm._s(data.recipeItemResult.itemName) + " "
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "li",
                      {
                        staticClass: "list-group-item",
                        staticStyle: {
                          "padding-bottom": "2px",
                          "padding-top": "1px",
                        },
                      },
                      [
                        _c("table", { staticClass: "table" }, [
                          _c("tr", [
                            _c("td", [_vm._v(" Nama User ")]),
                            _c("td", [_vm._v(" Qty ")]),
                            _c("td", [
                              _vm._v("(" + _vm._s(data.recipeUM) + ")"),
                            ]),
                            _c("td", [_vm._v("location")]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]
            )
          : _c("div", { staticClass: "col-12" }, [
              _c("h4", [_vm._v("Layanan Ini Belum di Setup Tindakannya")]),
            ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }