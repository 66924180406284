var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c(
        "div",
        {
          ref: "contentDiv",
          staticClass: "content container-fluid p-3 ps-0 pt-0",
        },
        [
          _vm._m(0),
          _vm.isDataLoaded
            ? [
                _c("tableReportBankHarian", {
                  attrs: {
                    header: _vm.header,
                    data: _vm.data,
                    fields: _vm.tableFields,
                    settings: _vm.hotSettings,
                    routingLink: _vm.routing,
                    tableWidth: _vm.contentDivWidth,
                    filterText: _vm.filterText,
                  },
                }),
              ]
            : _c("div", [_vm._v("Loading...")]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "breadcrumb m-1 mt-0" }, [
      _c("li", [
        _c("a", { attrs: { href: "#" } }, [
          _c("i", { staticClass: "bi-house-door" }),
        ]),
      ]),
      _c("li", [_vm._v("Laporan Bank Harian")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }