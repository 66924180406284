var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report", staticStyle: { "font-size": "10px" } },
    [
      _c(
        "table",
        {
          staticClass: "z-1 position-relative table table-bordered table-hover",
        },
        [
          _vm._m(0),
          _vm.fixData.length > 0
            ? _c(
                "tbody",
                _vm._l(_vm.fixData, function (data, i) {
                  return _c("tr", [
                    _c("td", [_vm._v(_vm._s(i + 1))]),
                    _c("td", [_vm._v(_vm._s(data.itemName))]),
                    _c("td", [_vm._v(_vm._s(data.itemCode))]),
                    _c(
                      "td",
                      [
                        data.itemInvStock
                          ? [_vm._v(_vm._s(data.itemInvStock.invUM))]
                          : [_vm._v("-")],
                      ],
                      2
                    ),
                    _c(
                      "td",
                      [
                        data.itemInvStock
                          ? [_vm._v(_vm._s(data.itemInvStock.invBegining))]
                          : [_vm._v("-")],
                      ],
                      2
                    ),
                    _c(
                      "td",
                      [
                        data.itemInvStock
                          ? [
                              _vm._v(
                                _vm._s(_vm.sumInOut(data.itemInvStock.invIn))
                              ),
                            ]
                          : [_vm._v("-")],
                      ],
                      2
                    ),
                    _c(
                      "td",
                      [
                        data.itemInvStock
                          ? [
                              _vm._v(
                                _vm._s(_vm.sumInOut(data.itemInvStock.invOut))
                              ),
                            ]
                          : [_vm._v("-")],
                      ],
                      2
                    ),
                    _c(
                      "td",
                      [
                        data.itemInvStock
                          ? [_vm._v(_vm._s(data.itemInvStock.invEnd))]
                          : [_vm._v("-")],
                      ],
                      2
                    ),
                    _c("td", { staticClass: "text-end" }, [
                      _vm._v(
                        " Rp. " +
                          _vm._s(
                            _vm.formatNumberInt(
                              data.itemStandartHPP ? data.itemStandartHPP : 0
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", { staticClass: "text-end" }, [
                      _c(
                        "b",
                        [
                          data.itemInvStock && data.itemStandartHPP
                            ? [
                                _vm._v(
                                  "Rp. " +
                                    _vm._s(
                                      _vm.formatNumberInt(
                                        data.itemInvStock.invEnd *
                                          data.itemStandartHPP
                                      )
                                    )
                                ),
                              ]
                            : [_vm._v("Rp. -")],
                        ],
                        2
                      ),
                    ]),
                  ])
                }),
                0
              )
            : _c("tbody", [_vm._m(1)]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "sticky-element bg-secondary" }, [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "text-center align-middle text-white",
            attrs: { scope: "col", width: "50" },
          },
          [_c("b", [_vm._v("NO")])]
        ),
        _c(
          "th",
          {
            staticClass: "text-center align-middle text-white",
            attrs: { scope: "col" },
          },
          [_c("b", [_vm._v("NAMA OBAT")])]
        ),
        _c(
          "th",
          {
            staticClass: "text-center align-middle text-white",
            attrs: { scope: "col", width: "85" },
          },
          [_c("b", [_vm._v("No. Batch")])]
        ),
        _c(
          "th",
          {
            staticClass: "text-center align-middle text-white",
            attrs: { scope: "col", width: "50" },
          },
          [_c("b", [_vm._v("SATUAN")])]
        ),
        _c(
          "th",
          {
            staticClass: "text-center align-middle text-white",
            attrs: { scope: "col", width: "85" },
          },
          [_c("b", [_vm._v("STOK AWAL")])]
        ),
        _c(
          "th",
          {
            staticClass: "text-center align-middle text-white",
            attrs: { scope: "col", width: "85" },
          },
          [_c("b", [_vm._v("MASUK")])]
        ),
        _c(
          "th",
          {
            staticClass: "text-center align-middle text-white",
            attrs: { scope: "col", width: "85" },
          },
          [_c("b", [_vm._v("KELUAR")])]
        ),
        _c(
          "th",
          {
            staticClass: "text-center align-middle text-white",
            attrs: { scope: "col", width: "85" },
          },
          [_c("b", [_vm._v("STOK AKHIR")])]
        ),
        _c(
          "th",
          {
            staticClass: "text-center align-middle text-white",
            attrs: { scope: "col", width: "125" },
          },
          [_c("b", [_vm._v("HARGA BELI")])]
        ),
        _c(
          "th",
          {
            staticClass: "text-center align-middle text-white",
            attrs: { scope: "col", width: "125" },
          },
          [_c("b", [_vm._v("JUMLAH")])]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "10" } }, [
        _vm._v("Belum Ada data atau data kosong!"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }