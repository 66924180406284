<template>
  <div v-if="$route.name === 'login'">
    <router-view></router-view>
  </div>
  <div v-else-if="this.$route.name === 'register'">
    <router-view></router-view>
  </div>
  <div v-else-if="this.$route.name === 'add-on-antrian'">
    <router-view></router-view>
  </div>
  <div id="app" v-else>
    <Topbar style="z-index: 3" />
    <Sidebar style="z-index: 3" />
    <router-view style="z-index: 1" />
  </div>
</template>
<script>
import Topbar from "@/components/Topbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "app",
  components: {
    Topbar,
    Sidebar,
    Footer,
  },
  data() {
    return {};
  },
  created() {
    // Add a beforeunload event listener
    const pageTitle = process.env.VUE_APP_TITLE || "Default Title";
    const faviconPath = process.env.VUE_APP_FAVICON || "";

    document.title = pageTitle;
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = faviconPath;
    }

    const tes = process.env.VUE_APP_ENV;
    sessionStorage.setItem("env", tes);
    // Check if the flag is set in localStorage
    // window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  beforeDestroy() {
    // Remove the beforeunload event listener when the component is destroyed
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  methods: {
    handleBeforeUnload(event) {
      if (event.type === "beforeunload") {
        this.$store.dispatch("logout");
      }
      // Remove the item from localStorage when the browser is closed
      // localStorage.removeItem("domainCode");
      // localStorage.removeItem("token", '');
      // localStorage.removeItem("_id", '');
      // localStorage.removeItem("userName", '');
      // localStorage.removeItem("userName", '');
      // localStorage.removeItem("userEmail", '');
      // localStorage.removeItem("userPhone", '');
      // localStorage.removeItem("userDomainCode", '');
      // this.$store.dispatch('logout')

      // Optionally, you can display a confirmation message to the user
      // This message will be shown when they try to close the browser/tab

      // event.returnValue = "Are you sure you want to leave?";
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
  },
  mounted() {},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
/* Style the list */
.barcode-class-print {
  position: absolute;
  left: 0;
}
#dropdown-option button {
  padding: 0 0.25rem 0 0.25rem;
}
/* font size */
.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px !important;
}
/* end font size */

.sticky-element {
  position: sticky;
  top: 60px; /* Adjust this value to match the height of your fixed navbar */
  background-color: #f0f0f0;
  padding: 10px;
  z-index: 999; /* Ensure it's displayed above other content */
}

.button-y0 button {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.active .page-link {
  z-index: 1 !important;
}

/* .multiselect-md .multiselect__tags {
  min-height: 30px;
  padding: 8px;
  line-height: 0;
} */
.dropdown-without-icon .dropdown-toggle::after {
  content: none;
  /* Remove the triangle icon */
}

.disabled-link {
  /* Add styles to visually indicate that the link is disabled */
  color: #999;
  /* Change the color to a muted gray */
  cursor: not-allowed;
  /* Change the cursor to indicate it's not clickable */
  pointer-events: none;
  /* Disable mouse events on the element */
  text-decoration: none;
  /* Remove underline (optional, based on your preference) */
}

ul.breadcrumb {
  list-style: none;
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 11px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li + li:before {
  padding: 0 8px;
  color: black;
  content: "/\00a0";
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
  color: #0275d8;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}

.form-row {
  display: flex;
  align-items: center;
}

.form-control-sm {
  min-height: 2em !important;
}

.footer-offset {
  margin-bottom: 0 !important;
}

body {
  margin-bottom: 0;
  min-height: 100vh;
}

#header {
  scale: 0.9;
  transform-origin: left top;
  width: 92%;
}

#content .container-fluid {
  padding-left: 0;
  padding-top: 0;
}

.js-navbar-vertical-aside {
  scale: 0.9;
  transform-origin: top left;
  height: 110%;
}

.scale-9 {
  scale: 0.9;
  transform-origin: left top;
  width: 110%;
}

.footer {
  scale: 0.9;
  transform-origin: bottom left;
}

.dataTables_wrapper .pagination {
  display: flex;
  justify-content: flex-end;
}

#myTable_filter label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#myTable_filter label .form-control {
  margin: 1rem 0;
  margin-left: 2rem;
  width: 200px;
}

#myTable_length label {
  display: flex;
  align-items: center;
}

#myTable_length label .form-control {
  margin: 1rem;
  width: 50px;
}

.dropdown-toggle:not(.dropdown-toggle-empty)::after {
  border: none;
}

.nav-vertical.nav-pills .nav-link {
  margin-bottom: 0 !important;
}

.table > :not(caption) > * > * {
  padding: 0.25rem !important;
}

td {
  vertical-align: middle;
}

.modal-header {
  padding-top: 0.5rem !important;
}

.modal-header .close {
  border: none;
  background: transparent;
  font-size: 25px;
}

.modal-body {
  margin-top: 5px;
  border-top-width: 0.05rem;
  border-top: #f2f2f2 solid;
}

/* select */
.custom-select-1 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* card */
.card-style-1 {
  box-shadow: 0 0.1875rem 0.75rem rgba(140, 152, 164, 0.25) !important;
}

.card-style-1:hover {
  background-image: linear-gradient(180deg, #80b4d4, #418fbf);
}

/* input */
.custom-input-1::placeholder {
  color: #fff !important;
}

/* background */
.bg-custom-1 {
  background: linear-gradient(to bottom right, #80b4d4, #517a97);
}

.bg-custom-2 {
  background: linear-gradient(#e7e9eb, #c4c4c4) !important;
}

.modal-dialog .modal-content .modal-header {
  padding-bottom: 0.25rem;
}

.modal-dialog .modal-content .modal-header {
  background: linear-gradient(#e7e9eb, #c4c4c4) !important;
}

.modal-dialog .modal-content .modal-header .close {
  line-height: 20px;
}

.modal-dialog .modal-content .modal-body {
  margin-top: 0;
}

.custom-card .card-header {
  background: linear-gradient(to bottom right, #80b4d4, #517a97);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/* animation */
</style>
