var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$route.name === "login"
    ? _c("div", [_c("router-view")], 1)
    : this.$route.name === "register"
    ? _c("div", [_c("router-view")], 1)
    : this.$route.name === "add-on-antrian"
    ? _c("div", [_c("router-view")], 1)
    : _c(
        "div",
        { attrs: { id: "app" } },
        [
          _c("Topbar", { staticStyle: { "z-index": "3" } }),
          _c("Sidebar", { staticStyle: { "z-index": "3" } }),
          _c("router-view", { staticStyle: { "z-index": "1" } }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }