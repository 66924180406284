var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c(
        "div",
        {
          ref: "contentDiv",
          staticClass: "content container-fluid p-3 ps-0 pt-0",
        },
        [
          _c("div", { staticClass: "row my-1" }, [
            _c("div", { staticClass: "col-4 d-flex align-items-end" }, [
              _c("ul", { staticClass: "breadcrumb m-0" }, [
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: "/dashboard" } }, [
                      _c("a", { attrs: { href: "#" } }, [
                        _c("i", { staticClass: "bi-house-door" }),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: _vm.posts.transRouting.replace("-add", ""),
                          },
                        },
                      },
                      [
                        _c("a", { attrs: { href: "#" } }, [
                          _vm._v(_vm._s(_vm.posts.transTitle)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-4 text-center" }, [
              _c("span", { staticClass: "h2" }, [
                _vm._v("List " + _vm._s(_vm.posts.transTitle)),
              ]),
            ]),
            _c("hr", { staticClass: "mb-3 mt-1" }),
          ]),
          _vm.isDataLoaded
            ? [
                _c("tableTransaksiKasbank", {
                  attrs: {
                    data: _vm.data,
                    fields: _vm.tableFields,
                    settings: _vm.hotSettings,
                    routingLink: _vm.routing,
                    tableWidth: _vm.contentDivWidth,
                    filterText: _vm.filterText,
                  },
                }),
              ]
            : _c("div", [_vm._v("Loading...")]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }