var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c("div", { ref: "contentDiv", staticClass: "w-100" }),
      _c("chatting", { staticStyle: { "z-index": "10" } }),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.isDataLoaded === ""
          ? _c("div", { key: "empty" }, [_c("loading")], 1)
          : _vm.isDataLoaded
          ? _c("div", { key: "truthy" }, [
              _c(
                "div",
                { staticClass: "content container-fluid p-3 ps-0 pt-0" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "modal",
                      attrs: { tabindex: "-1", role: "dialog" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "modal-dialog",
                          attrs: { role: "document" },
                        },
                        [
                          _c("div", { staticClass: "modal-content" }, [
                            _c("div", { staticClass: "modal-header" }, [
                              _c("h5", { staticClass: "modal-title" }, [
                                _vm._v("Modal title"),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "close",
                                  attrs: {
                                    type: "button",
                                    "data-dismiss": "modal",
                                    "aria-label": "Close",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { "aria-hidden": "true" } },
                                    [_vm._v("×")]
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "modal-body" }, [
                              _vm.paymentData
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report p-3 printable-element",
                                      staticStyle: {
                                        "font-size": "18px",
                                        width: "100%",
                                        "font-family": "sans-serif",
                                      },
                                    },
                                    [
                                      _c(
                                        "h1",
                                        { staticClass: "text-center pt-4" },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              "KLINIK UTAMA MUTIARA AISHA"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("p", { staticClass: "text-center" }, [
                                        _vm._v(
                                          "JL. KI HAJAR DEWANTARA 04 SELOSARI MAGETAN"
                                        ),
                                      ]),
                                      _c("p", { staticClass: "text-center" }, [
                                        _vm._v("082331 200 500"),
                                      ]),
                                      _vm.paymentData.transId
                                        ? _c(
                                            "div",
                                            { staticClass: "row pb-1" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-3",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                },
                                                [_vm._v("Nm. Pasien")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-9",
                                                  staticStyle: {
                                                    "font-size": "18px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " : " +
                                                      _vm._s(
                                                        _vm.paymentData
                                                          .transName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-3",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                },
                                                [_vm._v("Nomor RM")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-9",
                                                  staticStyle: {
                                                    "font-size": "18px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " : " +
                                                      _vm._s(
                                                        _vm.paymentData
                                                          .transIdMember
                                                          .userCode
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-3",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                },
                                                [_vm._v("Alamat")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-9",
                                                  staticStyle: {
                                                    "font-size": "18px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " : " +
                                                      _vm._s(
                                                        _vm.paymentData
                                                          .transIdMember
                                                          .userAddress[0]
                                                          .location
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-3",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                },
                                                [_vm._v("Dokter")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-9",
                                                  staticStyle: {
                                                    "font-size": "18px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " : " +
                                                      _vm._s(
                                                        _vm.paymentData
                                                          .transKasir.userName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-3",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                },
                                                [_vm._v("Poli")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-9",
                                                  staticStyle: {
                                                    "font-size": "18px",
                                                  },
                                                },
                                                [_vm._v(":")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("div", {
                                        staticClass:
                                          "w-100 border-dark border-top border-bottom",
                                        staticStyle: { height: "0.25rem" },
                                      }),
                                      _c("div", { staticClass: "w-100" }, [
                                        _vm.paymentData.transPesanan
                                          ? _c(
                                              "table",
                                              { staticClass: "w-100" },
                                              [
                                                _c(
                                                  "thead",
                                                  {
                                                    staticClass:
                                                      "border-bottom border-dark",
                                                  },
                                                  [
                                                    _c("tr", [
                                                      _c(
                                                        "th",
                                                        {
                                                          staticStyle: {
                                                            "font-size": "20px",
                                                          },
                                                        },
                                                        [_vm._v("TRANSAKSI")]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-end",
                                                          staticStyle: {
                                                            "font-size": "20px",
                                                          },
                                                        },
                                                        [_vm._v("QTY")]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-end",
                                                          staticStyle: {
                                                            "font-size": "20px",
                                                          },
                                                        },
                                                        [_vm._v("DISC")]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-end",
                                                          staticStyle: {
                                                            "font-size": "20px",
                                                          },
                                                        },
                                                        [_vm._v("JUMLAH")]
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "tbody",
                                                  {
                                                    staticClass:
                                                      "border-bottom border-dark",
                                                  },
                                                  _vm._l(
                                                    _vm.paymentData
                                                      .transPesanan,
                                                    function (data) {
                                                      return _vm.paymentData
                                                        .transPesanan.length > 0
                                                        ? _c("tr", [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "18px",
                                                                  width: "60%",
                                                                },
                                                              },
                                                              [
                                                                data
                                                                  .itemGroup[0] ===
                                                                "layanan"
                                                                  ? [
                                                                      _vm._v(
                                                                        "ADMIN "
                                                                      ),
                                                                    ]
                                                                  : data
                                                                      .itemGroup[0] ===
                                                                    "Fee"
                                                                  ? [
                                                                      _vm._v(
                                                                        "TARIF "
                                                                      ),
                                                                    ]
                                                                  : _vm._e(),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.itemName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-end",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "18px",
                                                                  width: "5%",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.itemQty
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-end",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "18px",
                                                                  width:
                                                                    "17.5%",
                                                                },
                                                              },
                                                              [_vm._v(" 0 ")]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-end",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "18px",
                                                                  width:
                                                                    "17.5%",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.itemQty *
                                                                        data
                                                                          .itemMasterPrice
                                                                          .priceValue
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ])
                                                        : _vm._e()
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row d-flex justify-content-between",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-6 row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "col-5",
                                                    staticStyle: {
                                                      "font-size": "18px",
                                                    },
                                                  },
                                                  [_vm._v("Tanggal")]
                                                ),
                                                _vm.paymentData.transDate
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "col-7",
                                                        staticStyle: {
                                                          "font-size": "18px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " : " +
                                                            _vm._s(
                                                              _vm.formatIndDate(
                                                                _vm.paymentData
                                                                  .transDate
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "col-5 pe-0",
                                                    staticStyle: {
                                                      "font-size": "18px",
                                                    },
                                                  },
                                                  [_vm._v("No. Nota")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "col-7 pe-0",
                                                    staticStyle: {
                                                      "font-size": "18px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " : " +
                                                        _vm._s(
                                                          _vm.paymentData
                                                            .transNoSO
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-6 d-flex justify-content-between",
                                                      },
                                                      [
                                                        _vm._v(" SUBTOTAL "),
                                                        _c("span", [
                                                          _vm._v(":"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm.paymentData.grandTotal
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6 text-end",
                                                            staticStyle: {
                                                              "font-size":
                                                                "18px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .paymentData
                                                                    .grandTotal
                                                                ) +
                                                                ",00 "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-6 d-flex justify-content-between",
                                                        staticStyle: {
                                                          "font-size": "18px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" DISC "),
                                                        _c("span", [
                                                          _vm._v(":"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-6 text-end",
                                                        staticStyle: {
                                                          "font-size": "18px",
                                                        },
                                                      },
                                                      [_vm._v("0,00")]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-6 d-flex justify-content-between",
                                                        staticStyle: {
                                                          "font-size": "18px",
                                                        },
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v("TOTAL"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(":"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm.paymentData.grandTotal
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6 text-end",
                                                            staticStyle: {
                                                              "font-size":
                                                                "18px",
                                                            },
                                                          },
                                                          [
                                                            _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .paymentData
                                                                    .grandTotal
                                                                ) + ",00"
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass:
                                            "w-100 border-dark border-top border-bottom",
                                          staticStyle: { height: "0.25rem" },
                                        }),
                                        _c("p", [
                                          _vm.paymentData.grandTotal
                                            ? _c("b", [_vm._v("Terbilang :")])
                                            : _vm._e(),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.numberToText(
                                                  _vm.paymentData.grandTotal
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "text-center pt-3" },
                                          [
                                            _c("h2", [
                                              _vm._v(
                                                " Terima kasih atas kunjungannya "
                                              ),
                                              _c("p", [
                                                _vm._v("Semoga Lekas Sembuh"),
                                              ]),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "modal-footer" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: { type: "button" },
                                },
                                [_vm._v("Save changes")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary",
                                  attrs: {
                                    type: "button",
                                    "data-dismiss": "modal",
                                  },
                                },
                                [_vm._v(" Close ")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: "modal-payment",
                        title: "Detail Transaksi",
                        size: "lg",
                      },
                      on: {
                        ok: function ($event) {
                          $event.preventDefault()
                          return _vm.pay()
                        },
                      },
                    },
                    [
                      _vm.paymentData
                        ? _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _vm.paymentData.transId
                                ? _c("pre", { staticClass: "m-0" }, [
                                    _vm._v(
                                      "No. Invoice   :  " +
                                        _vm._s(_vm.paymentData.transId) +
                                        "\nNo. RM        :  " +
                                        _vm._s(
                                          _vm.paymentData.transIdMember.userCode
                                        ) +
                                        "\nNama Pasien   :  " +
                                        _vm._s(_vm.paymentData.transName) +
                                        "\n    "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _vm.paymentData.transId
                                ? _c("pre", { staticClass: "m-0" }, [
                                    _vm._v(
                                      "Tgl. Berobat  :  " +
                                        _vm._s(
                                          _vm.formatIndDate(
                                            _vm.paymentData.transDate
                                          )
                                        ) +
                                        "\nAlamat        :  " +
                                        _vm._s(
                                          _vm.paymentData.transIdMember
                                            .userAddress[0].location
                                        ) +
                                        "\n    "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.paymentData.transPesanan
                        ? _c("table", { staticClass: "table table-bordered" }, [
                            _c("thead", [
                              _c("tr", [
                                _c(
                                  "th",
                                  { staticClass: "text-center align-middle" },
                                  [_vm._v("Kode")]
                                ),
                                _c(
                                  "th",
                                  { staticClass: "text-center align-middle" },
                                  [_vm._v("Nama")]
                                ),
                                _c(
                                  "th",
                                  { staticClass: "text-center align-middle" },
                                  [_vm._v("Qty")]
                                ),
                                _c(
                                  "th",
                                  { staticClass: "text-center align-middle" },
                                  [_vm._v("Harga")]
                                ),
                                _c(
                                  "th",
                                  { staticClass: "text-center align-middle" },
                                  [_vm._v("Total")]
                                ),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              [
                                _vm._l(
                                  _vm.paymentData.transPesanan,
                                  function (data) {
                                    return _vm.paymentData.transPesanan.length >
                                      0
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              staticClass: "fs-11 align-middle",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.itemCode) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "fs-11 align-middle",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.itemName) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "fs-11 align-middle",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(data.itemQty) + " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "fs-11 align-middle text-end",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    data.itemMasterPrice
                                                      .priceValue
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "fs-11 align-middle text-end",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    data.itemQty *
                                                      data.itemMasterPrice
                                                        .priceValue
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e()
                                  }
                                ),
                                _c("tr", [
                                  _c(
                                    "th",
                                    {
                                      staticClass: "text-end",
                                      attrs: { colspan: "3" },
                                    },
                                    [_vm._v("Total")]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass: "text-end",
                                      attrs: { colspan: "2" },
                                    },
                                    [
                                      _vm._v(
                                        " Rp. " +
                                          _vm._s(_vm.paymentData.grandTotal) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                      _c("multiselect", {
                        staticClass: "my-1",
                        attrs: {
                          options: _vm.optionsCoa,
                          placeholder: "Metode Pembayaran",
                          multiple: false,
                          "show-labels": false,
                          label: "coaName",
                        },
                        model: {
                          value: _vm.payMethods,
                          callback: function ($$v) {
                            _vm.payMethods = $$v
                          },
                          expression: "payMethods",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "row my-1" }, [
                    _c(
                      "div",
                      { staticClass: "col-4 d-flex align-items-center" },
                      [
                        _c("ul", { staticClass: "breadcrumb m-0" }, [
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "/dashboard" } },
                                [
                                  _c("a", { attrs: { href: "#" } }, [
                                    _c("i", { staticClass: "bi-house-door" }),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "/transaksi/po-invoice/add" } },
                                [
                                  _c("a", { attrs: { href: "#" } }, [
                                    _vm._v("Sales Invoice"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "col-4 text-center" }, [
                      _c("span", { staticClass: "h2" }, [
                        _vm._v("List Sales Invoice"),
                      ]),
                    ]),
                  ]),
                  _c("hr", { staticClass: "my-2" }),
                  _vm.isDataLoaded
                    ? [
                        _c("div", { staticClass: "card" }, [
                          _c(
                            "div",
                            { staticClass: "card-header bg-light p-2" },
                            [
                              _c("div", { staticClass: "row d-flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-9 row" },
                                  _vm._l(_vm.filterList, function (data, i) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex col-12 align-items-center pe-0 mt-2",
                                      },
                                      [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: data.filterBaris,
                                                expression: "data.filterBaris",
                                              },
                                            ],
                                            staticClass:
                                              "form-select form-select-sm py-1 px-2",
                                            staticStyle: {
                                              "font-size": "12px",
                                              "max-width": "125px",
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  data,
                                                  "filterBaris",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            !_vm.filterBaris
                                              ? _c(
                                                  "option",
                                                  { attrs: { value: "" } },
                                                  [_vm._v("Filter Baris")]
                                                )
                                              : _vm._e(),
                                            _vm._l(
                                              _vm.tableHeader,
                                              function (micro) {
                                                return micro.key !== "actions"
                                                  ? _c(
                                                      "option",
                                                      {
                                                        domProps: {
                                                          value: micro.key,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              micro.label
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: data.startFrom,
                                                expression: "data.startFrom",
                                              },
                                            ],
                                            staticClass:
                                              "mx-2 form-select form-select-sm py-1 px-2",
                                            staticStyle: {
                                              "font-size": "12px",
                                              "max-width": "125px",
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  data,
                                                  "startFrom",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            !data.startFrom
                                              ? _c(
                                                  "option",
                                                  { attrs: { value: "" } },
                                                  [_vm._v("Tipe Pencarian")]
                                                )
                                              : _vm._e(),
                                            _vm._l(
                                              _vm.optionQuery,
                                              function (micro) {
                                                return _c(
                                                  "option",
                                                  {
                                                    domProps: { value: micro },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(micro) + " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "input-group",
                                            staticStyle: { width: "150px" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "input-group-prepend",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "input-group-text py-1 px-2",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "bi-search",
                                                      staticStyle: {
                                                        "font-size": "10px",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: data.filter1,
                                                  expression: "data.filter1",
                                                },
                                              ],
                                              staticClass:
                                                "form-control form-control-sm p-1",
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                              attrs: {
                                                type: "search",
                                                placeholder: "Cari Data...",
                                                "aria-label":
                                                  "Recipient's username",
                                                "aria-describedby":
                                                  "basic-addon2",
                                              },
                                              domProps: { value: data.filter1 },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    data,
                                                    "filter1",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "input-group px-2",
                                            staticStyle: { width: "150px" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "input-group-prepend",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "input-group-text py-1 px-2",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "bi-search",
                                                      staticStyle: {
                                                        "font-size": "10px",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: data.filter2,
                                                  expression: "data.filter2",
                                                },
                                              ],
                                              staticClass:
                                                "form-control form-control-sm p-1",
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                              attrs: {
                                                type: "search",
                                                placeholder: "Cari Data...",
                                                "aria-label":
                                                  "Recipient's username",
                                                "aria-describedby":
                                                  "basic-addon2",
                                              },
                                              domProps: { value: data.filter2 },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    data,
                                                    "filter2",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: data.logic,
                                                expression: "data.logic",
                                              },
                                            ],
                                            staticClass:
                                              "mx-2 form-select form-select-sm py-1",
                                            staticStyle: {
                                              "font-size": "12px",
                                              "max-width": "65px",
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  data,
                                                  "logic",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "and" } },
                                              [_vm._v("dan")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "or" } },
                                              [_vm._v("atau")]
                                            ),
                                          ]
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "bi-plus-circle text-primary ms-1",
                                          staticStyle: { cursor: "pointer" },
                                          on: { click: _vm.addFilterList },
                                        }),
                                        _c("i", {
                                          staticClass:
                                            "bi-x-circle text-danger ms-1",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeFilterRow(i)
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-3 d-flex justify-content-end mt-2 align-items-start p-0",
                                    staticStyle: { "z-index": "2" },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn-xs btn btn-outline-success",
                                        attrs: { type: "button" },
                                      },
                                      [_vm._v(" Cari ")]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn-xs btn btn-outline-warning",
                                        attrs: { type: "button" },
                                      },
                                      [_vm._v(" Reset ")]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "card-body p-2" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-between",
                                      staticStyle: {
                                        "font-size": "11px",
                                        width: "300px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Menampilkan 1 - 10 data dari " +
                                          _vm._s(_vm.rows) +
                                          " "
                                      ),
                                      _c(
                                        "select",
                                        {
                                          staticClass: "form-select py-1",
                                          staticStyle: {
                                            width: "75px",
                                            "font-size": "12px",
                                            padding: "0 0.5rem !important",
                                          },
                                          attrs: {
                                            "aria-label":
                                              "Default select example",
                                          },
                                        },
                                        _vm._l(_vm.pageList, function (data) {
                                          return _c(
                                            "option",
                                            { domProps: { value: data } },
                                            [_vm._v(" " + _vm._s(data) + " ")]
                                          )
                                        }),
                                        0
                                      ),
                                    ]
                                  ),
                                  _c("b-pagination", {
                                    staticClass: "m-0",
                                    attrs: {
                                      "total-rows": _vm.rows,
                                      "per-page": _vm.perPage,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "first-text",
                                          fn: function () {
                                            return [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-outline-primary btn-xs",
                                                },
                                                [_vm._v(" First ")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "prev-text",
                                          fn: function () {
                                            return [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-outline-primary btn-xs",
                                                },
                                                [_vm._v(" Prev ")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "next-text",
                                          fn: function () {
                                            return [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-outline-primary btn-xs",
                                                },
                                                [_vm._v(" Next ")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "last-text",
                                          fn: function () {
                                            return [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-outline-primary btn-xs",
                                                },
                                                [_vm._v(" Last ")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "ellipsis-text",
                                          fn: function () {
                                            return [_vm._v(" ... ")]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "page",
                                          fn: function ({ page, active }) {
                                            return [
                                              active
                                                ? _c("b", [
                                                    _vm._v(_vm._s(page)),
                                                  ])
                                                : _c("i", [
                                                    _vm._v(_vm._s(page)),
                                                  ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3004231875
                                    ),
                                    model: {
                                      value: _vm.currentPage,
                                      callback: function ($$v) {
                                        _vm.currentPage = $$v
                                      },
                                      expression: "currentPage",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("hr", { staticClass: "my-2" }),
                              _c("b-table", {
                                staticClass: "myTable",
                                attrs: {
                                  striped: "",
                                  hover: "",
                                  items: _vm.livePI,
                                  fields: _vm.tableHeader,
                                  "label-sort-asc": "",
                                  "label-sort-desc": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "cell(PoSupplier)",
                                      fn: function (data) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(data.item.PoSupplier) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(grandTotal)",
                                      fn: function (data) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(data.item.grandTotal) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(transHysPPN)",
                                      fn: function (data) {
                                        return undefined
                                      },
                                    },
                                    {
                                      key: "cell(poDate)",
                                      fn: function (data) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatIndDate(
                                                  data.item.dueDate
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(transPesanan)",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "inner-table" },
                                            [
                                              _c(
                                                "table",
                                                {
                                                  staticClass:
                                                    "table table-bordered",
                                                },
                                                [
                                                  _c("thead", [
                                                    _c("tr", [
                                                      _c(
                                                        "th",
                                                        {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                        },
                                                        [_vm._v("Kode")]
                                                      ),
                                                      _c("th", [
                                                        _vm._v("Nama"),
                                                      ]),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticStyle: {
                                                            width: "50px",
                                                          },
                                                        },
                                                        [_vm._v("Qty")]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticStyle: {
                                                            width: "75px",
                                                          },
                                                        },
                                                        [_vm._v("Harga")]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticStyle: {
                                                            width: "75px",
                                                          },
                                                        },
                                                        [_vm._v("Total")]
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c(
                                                    "tbody",
                                                    _vm._l(
                                                      data.item.transPesanan,
                                                      function (item, index) {
                                                        return data.item
                                                          .transPesanan.length >
                                                          0
                                                          ? _c(
                                                              "tr",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "fs-10",
                                                              },
                                                              [
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.itemCode
                                                                    )
                                                                  ),
                                                                ]),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.itemName
                                                                    )
                                                                  ),
                                                                ]),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.itemQty
                                                                    )
                                                                  ),
                                                                ]),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item
                                                                        .itemMasterPrice
                                                                        .priceValue
                                                                    )
                                                                  ),
                                                                ]),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.formatNumberInt(
                                                                          item.itemQty *
                                                                            item
                                                                              .itemMasterPrice
                                                                              .priceValue
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(actions)",
                                      fn: function (data) {
                                        return [
                                          data.item.transStatusSO === "open"
                                            ? _c(
                                                "b-dropdown",
                                                {
                                                  attrs: {
                                                    id: "dropdown-option",
                                                    right: "",
                                                    size: "xs",
                                                    variant: "primary",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "button-content",
                                                        fn: function () {
                                                          return [
                                                            _c("i", {
                                                              staticClass:
                                                                "bi-gear px-1 py-0",
                                                            }),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  data.item
                                                    .transStatusPayment ===
                                                  "Closed"
                                                    ? _c(
                                                        "b-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.detilAction(
                                                                data.item._id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Print")]
                                                      )
                                                    : _vm._e(),
                                                  data.item
                                                    .transStatusPayment === "no"
                                                    ? _c(
                                                        "b-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.detilAction(
                                                                data.item._id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Edit")]
                                                      )
                                                    : _vm._e(),
                                                  data.item.transStatusSO ===
                                                  "open"
                                                    ? _c(
                                                        "b-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handlePayment(
                                                                data.item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Payment")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          data.item.transStatusSO === "Closed"
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-primary btn-xs",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handlePrint(
                                                        data.item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "bi-printer",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1289696870
                                ),
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    : _c("div", [_vm._v("Loading...")]),
                ],
                2
              ),
            ])
          : _c("div", { key: "other" }, [_c("connection-timeout")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }