<template>
  <div class="report" style="font-size: 10px">
    <table class="table table-bordered z-1 position-relative">
      <thead class="sticky-element">
        <tr>
          <th scope="col" class="text-center" width="80"><b>Kode COA</b></th>
          <th scope="col" class="text-center"><b>Nama COA</b></th>
          <th scope="col" class="text-center" width="100"><b>Saldo Awal Debet</b></th>
          <th scope="col" class="text-center" width="100"><b>Saldo Awal Kredit</b></th>
          <th scope="col" class="text-center" width="100"><b>Mutasi Debet</b></th>
          <th scope="col" class="text-center" width="100"><b>Mutasi Kredit</b></th>
          <th scope="col" class="text-center" width="100"><b>Saldo Akhir Debet</b></th>
          <th scope="col" class="text-center" width="100"><b>Saldo Akhir Kredit</b></th>
        </tr>
      </thead>
      <tbody v-if="realData.length > 0">
        <tr v-for="entry in realData">
          <td>{{ entry.coaCode }}</td>
          <td>{{ entry.coaName }}</td>
          <td>
            <div v-if="entry.coaPlus === 'debet'">
              Rp.{{ formatNumber(entry.coaFaAmount.FaPriceBegining) }}
            </div> 
            <div v-else>
              Rp. 0.00
            </div> 
          </td>
          <td>
            <div v-if="entry.coaPlus === 'kredit'">
              Rp.{{ formatNumber(entry.coaFaAmount.FaPriceBegining) }}
            </div> 
            <div v-else>
              Rp. 0.00
            </div> 
          </td>
          <td>
            Rp.
            <template v-if="entry.coaFaAmount.FaPriceIn !== null">{{
               formatNumber(entry.coaFaAmount.FaPriceIn)
            }}</template>
            <template v-else>0.00</template>
          </td>
          <td>Rp. {{ formatNumber(entry.coaFaAmount.FaPriceOut) }}</td>
          <td>
            <template v-if="getSaldoAkhir(entry.coaPlus ,entry.coaFaAmount.FaPriceBegining ,entry.coaFaAmount.FaPriceIn,entry.coaFaAmount.FaPriceOut ) < 0">
                Rp. 0.00
            </template>
            <template v-else>
              Rp. {{ formatNumber(getSaldoAkhir(entry.coaPlus ,entry.coaFaAmount.FaPriceBegining ,entry.coaFaAmount.FaPriceIn,entry.coaFaAmount.FaPriceOut )) }}
            </template>
          </td>
          <td>
            <template v-if="getSaldoAkhir(entry.coaPlus ,entry.coaFaAmount.FaPriceBegining ,entry.coaFaAmount.FaPriceIn,entry.coaFaAmount.FaPriceOut ) < 0">
              Rp. {{ formatNumber(getSaldoAkhir(entry.coaPlus ,entry.coaFaAmount.FaPriceBegining ,entry.coaFaAmount.FaPriceIn,entry.coaFaAmount.FaPriceOut ) * -1 )}}
            </template>
            <template v-else>
              Rp. 0.00
            </template>
          </td>
          <!-- <td>
            Rp.
            <template v-if="entry.coaFaAmount.FaPriceEnd !== null">
              <template v-if="entry.coaFaAmount.FaPriceEnd < 0">
                <template>0.00</template>
              </template>
              <template v-else>
                {{formatNumber(entry.coaFaAmount.FaPriceEnd)}}
              </template>
            </template>

            <template v-else>0.00</template>
          </td>
          <td>
            Rp.
            <template v-if="entry.coaFaAmount.FaPriceEnd !== null">
              <div v-if="entry.coaFaAmount.FaPriceEnd < 0">
                {{formatNumber(entry.coaFaAmount.FaPriceEnd * -1)}}
              </div>
              <div v-else>
                <template>0.00</template>
              </div>
            </template>
            <template v-else>0.00</template>
          </td> -->
        </tr>
        <tr>
          <td colspan="2" class="centered-text">Jumlah</td>
          <td></td>
          <td></td>
          <td>Rp. {{totalDebet}}</td>
          <td>Rp. {{totalKredit}}</td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="6">Data Tidak Ditemukan</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatNumber } from "../function/number";
export default {
  props: {
    saldoAwal: String,
    data: Array,
  },
  data() {
    return {
      saldoAkhir: "0",
      saldoIn: "0",
      saldoOut: "0",
    };
  },
  computed: {
    realData() {
      return this.data;
    },

    totalDebet(){
        let sumIn = 0;
        this.data.map((entry)=>{
          sumIn += parseFloat( entry.coaFaAmount.FaPriceIn )
        })
        return formatNumber(sumIn.toString())
    },
    totalKredit(){
        let sumIn = 0;
        this.data.map((entry)=>{
          sumIn += parseFloat( entry.coaFaAmount.FaPriceOut )
        })
        return  formatNumber(sumIn.toString())
    },

    // totalAkhirDebet(){
    //     let sumIn = 0;
    //     this.data.map((entry)=>{
    //       let saldoAwalDebet = 0;
    //       let saldoAwalKredit = 0;
    //       if(entry.coaPlus === 'debet'){
    //         saldoAwalDebet = entry.coaFaAmount.FaPriceBegining
    //         const hasil = saldoAwalDebet - saldoAwalKredit + entry.coaFaAmount.FaPriceIn - entry.coaFaAmount.FaPriceOut ;
    //         if(hasil > 0){
    //           console.log("Jangkrek BOS " , hasil)
    //           sumIn += parseFloat(hasil)
    //         }
    //       }
    //     })

    //     return formatNumber(sumIn.toString())
    // },
    // totalAkhirKredit(){
    //     let sumIn = 0;
    //     this.data.map((entry)=>{
    //       let saldoAwalDebet = 0;
    //       let saldoAwalKredit = 0;
    //       if(entry.coaPlus === 'kredit'){
    //         saldoAwalKredit = entry.coaFaAmount.FaPriceBegining
    //         const hasil = saldoAwalDebet - saldoAwalKredit + entry.coaFaAmount.FaPriceIn - entry.coaFaAmount.FaPriceOut ;
    //         if(hasil > 0){
    //           console.log("Jangkrek BOS " , hasil)
    //           sumIn += parseFloat(hasil * -1)
    //         }
    //       }
    //     })

    //     return formatNumber(sumIn.toString())
    // },

    updateSaldoAwal() {
      return formatNumber(this.saldoAwal.toString());
    },
    updateSaldoIn() {
      let ini = this.data
        .filter((x) => parseFloat(x.transIn) > 0 && parseFloat(x.transOut) <= 0)
        .reduce((sum, item) => sum + parseFloat(item.transAmount), 0);
      this.saldoIn = ini;
      return formatNumber(ini);
    },
    updateSaldoOut() {
      let out = this.data
        .filter((x) => parseFloat(x.transOut) > 0 && parseFloat(x.transIn) <= 0)
        .reduce((sum, item) => sum + parseFloat(item.transAmount), 0);
      if (out < 0) {
        out = out * -1;
      }
      this.saldoOut = out;
      return formatNumber(out);
    },
    updateSaldoAkhir() {
      return formatNumber((this.saldoAwal + this.saldoIn - this.saldoOut).toString());
    },
  },
  methods: {
    getSaldoAkhir(coaPlus , saldoAwal , mutasiDebet , mutasiKredit ){
      let saldoAwalDebet = 0;
      let saldoAwalKredit = 0;
      if(coaPlus === 'debet'){
        saldoAwalDebet = saldoAwal
      }else{
        saldoAwalKredit = saldoAwal
      }
      const hasil = saldoAwalDebet - saldoAwalKredit + mutasiDebet - mutasiKredit ;
      return hasil;
    },
    formatNumber(data) {
      return formatNumber(data);
    },
  },
};
</script>

<style scoped>

.centered-text {
  text-align: center;
  font-weight: bold;
  font-size: medium;
}
</style>
