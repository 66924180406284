<template v-if="fields.length > 0">
  <form @submit="sendData">
    <b-modal id="modal-1" title="DP" ok-only>
      <div class="form-group">
        <label for="exampleInputEmail1">Bayar Ke :</label>
        <multiselect
          id="itemConvert"
          v-model="coaValue"
          :options="optionsCoa"
          placeholder="Select"
          :multiple="false"
          :custom-label="customLabel2"
        >
        </multiselect>
        <!-- <small id="emailHelp" class="form-text text-muted"
          >We'll never share your email with anyone else.</small
        > -->
      </div>
      <div class="form-group">
        <label for="nominalDP">Jumlah DP :</label>
        <input
          v-model="dpValue"
          v-format-number
          type="text"
          class="form-control"
          id="nominalDP"
          placeholder="0"
        />
      </div>
    </b-modal>
    <div class="row my-1">
      <div class="col-4 d-flex align-items-end">
        <ul class="breadcrumb m-0">
          <li>
            <router-link to="/dashboard">
              <a href="#"><i class="bi-house-door"></i></a>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: posts.transRouting.replace('-add', '') }">
              <a href="#">{{ posts.transTitle }}</a>
            </router-link>
          </li>
          <li>Tambah</li>
        </ul>
      </div>
      <div class="col-4 text-center">
        <span class="h2">Tambah {{ posts.transTitle }}</span>
      </div>
      <div class="col-1 d-flex justify-content-end align-items-center">
        <div class="form-check form-switch d-flex align-items-center d-none">
          <input
            type="checkbox"
            class="form-check-input"
            id="formSwitch1"
            v-model="checkedPajak"
            style="height: 17.5px; margin-top: 1px"
          />
          <label class="form-check-label" for="formSwitch1">Pajak</label>
        </div>
      </div>
      <div class="col-3 d-flex justify-content-end align-items-center">
        <router-link :to="{ name: posts.transRouting.replace('-add', '') }"
          ><button class="btn btn-outline-secondary btn-xs py-1 me-2" type="button">
            <i class="bi-chevron-left"></i>Kembali
          </button></router-link
        >
        <button class="btn btn-primary btn-xs py-1" type="submit">Tambah</button>
      </div>
    </div>

    <hr class="mb-3 mt-1" />
    <div class="row scale-9 position-relative" style="width: 114%; z-index: 1">
      <div
        v-for="(field, index) in dummyItems"
        :class="[field.componentClass]"
        v-if="field.componentName === 'fieldHeader'"
        :key="index"
      >
        <div class="card h-100">
          <div class="row px-3">
            <div v-for="micro in field.componentMicro" :class="['', micro.microClass]">
              <div class="row" v-if="micro.microType === 'select'">
                <b-form-group
                  :label="micro.microName + ' :'"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm="8"
                  content-cols-lg="9"
                >
                  <b-form-select
                    v-model="micro.value"
                    :options="micro.microOptions"
                    size="sm"
                    class="mt-1 form-control-sm py-0 form-control"
                  ></b-form-select>
                </b-form-group>
              </div>
              <template v-else-if="micro.microType === 'multiselect'">
                <b-form-group
                  :label="micro.microName + ' :'"
                  :label-for="micro.microName"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm="8"
                  content-cols-lg="9"
                  invalid-feedback="Condition Harus Diisi!"
                >
                  <template v-if="micro.key === 'shipTo'">
                    <multiselect
                      class="my-1"
                      :id="micro.microName"
                      v-model="micro.value"
                      :options="shipTo"
                      placeholder="Pilih Alamat"
                      :multiple="false"
                      :show-labels="false"
                      required
                      :custom-label="customLabel1"
                    >
                    </multiselect>
                  </template>
                  <template v-else-if="micro.key === 'customer'">
                    <multiselect
                      class="my-1"
                      :id="micro.microName"
                      v-model="micro.value"
                      :options="micro.microOptions"
                      @input="selectCustomer(micro.value)"
                      placeholder="Select one"
                      :multiple="false"
                      :show-labels="false"
                      required
                      :custom-label="customLabel"
                    >
                    </multiselect>
                  </template>
                  <template v-else>
                    <multiselect
                      @input="selectCustomer(micro.value)"
                      class="my-1"
                      :id="micro.microName"
                      v-model="micro.value"
                      :options="micro.microOptions"
                      placeholder="Select one"
                      :multiple="false"
                      :show-labels="false"
                      required
                      :custom-label="customLabel"
                    >
                    </multiselect>
                  </template>
                </b-form-group>
              </template>
              <template v-else-if="micro.key === 'orderNumber'">
                <b-form-group
                  :label="micro.microName + ' :'"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm="8"
                  content-cols-lg="9"
                >
                  <b-form-input
                    v-model="micro.value"
                    class="form-control form-control-sm py-0"
                    :type="micro.microType"
                    :placeholder="micro.microText"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </template>
              <template v-else>
                <b-form-group
                  :label="micro.microName + ' :'"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm="8"
                  content-cols-lg="9"
                >
                  <b-form-input
                    v-model="micro.value"
                    class="form-control form-control-sm py-0"
                    :type="micro.microType"
                    :placeholder="micro.microText"
                    required
                    :disabled="micro.isDisabled"
                  ></b-form-input>
                </b-form-group>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div
        v-for="field in component"
        :class="[field.componentClass]"
        v-if="field.componentName === 'fieldTable'"
      >
        <div class="row">
          <div
            class="hot-container col-md-12 position-relative"
            style="overflow-x: auto; z-index: 0"
          >
            <hot-table
              :settings="hotSettings"
              :data="fixExcelData"
              :rowHeaders="true"
              :colHeaders="true"
              :colWidths="widthTable"
            ></hot-table>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-6 scale-9">
        <div
          v-for="field in component"
          :class="['h-100', field.componentClass]"
          v-if="field.componentName === 'fieldNote'"
        >
          <div class="card h-100">
            <div class="card-header bg-light py-1">{{ field.componentTitle }}</div>
            <template v-for="micro in field.componentMicro">
              <textarea
                class="form-control form-control-sm py-1 h-100"
                style="resize: none"
                :placeholder="micro.microText"
                v-model="micro.microValue"
              ></textarea>
            </template>
          </div>
        </div>
      </div>
      <div class="col-md-6 row p-0 scale-9" style="transform-origin: right top">
        <div
          v-for="field in component"
          :class="[field.componentClass]"
          v-if="field.componentName === 'fieldTotal'"
        >
          <template v-if="field.componentMicro.length > 0">
            <div
              class="row d-flex justify-content-end"
              v-for="micro in field.componentMicro"
            >
              <template v-if="micro.key === 'tableTotal'">
                <div
                  :class="[
                    'border border-light bg-light d-flex justify-content-between align-items-center',
                    micro.microClass,
                  ]"
                >
                  {{ micro.microName }} <span>:</span>
                </div>
                <div :class="['p-0 border border-light', micro.microClass]">
                  <input
                    :disabled="!micro.editableTotal"
                    :type="micro.microValue"
                    :value="formatNumberInt(subTotal())"
                    class="form-control form-control-sm py-1"
                    style="text-align: right"
                  />
                </div>
              </template>
              <template v-else-if="micro.key === 'dp'">
                <div
                  :class="[
                    'border border-light bg-light d-flex justify-content-between align-items-center',
                    micro.microClass,
                  ]"
                >
                  {{ micro.microName }}
                  <span>
                    <b-button v-b-modal.modal-1 size="xs"
                      ><i class="bi-eye"></i
                    ></b-button>
                    :</span
                  >
                </div>
                <div :class="['p-0 border border-light', micro.microClass]">
                  <input
                    v-format-number
                    :disabled="!micro.editableTotal"
                    type="text"
                    :value="dpValue ? dpValue : 0"
                    class="form-control form-control-sm py-1"
                    style="text-align: right"
                    @change="checkTotal('DP')"
                    disabled
                  />
                </div>
              </template>
              <template v-else-if="micro.key === 'diskon'">
                <div
                  :class="[
                    'border border-light bg-light d-flex justify-content-between align-items-center',
                    micro.microClass,
                  ]"
                >
                  {{ micro.microName }} <span>:</span>
                </div>
                <div :class="['p-0 border border-light', micro.microClass]">
                  <input
                    v-format-number
                    :disabled="!micro.editableTotal"
                    type="text"
                    v-model="totalDiskon"
                    class="form-control form-control-sm py-1"
                    style="text-align: right"
                    @change="checkTotal('Diskon')"
                  />
                </div>
              </template>
              <template v-else-if="micro.key === 'total'">
                <div
                  :class="[
                    'border border-light bg-light d-flex justify-content-between align-items-center',
                    micro.microClass,
                  ]"
                >
                  {{ micro.microName }} <span>:</span>
                </div>
                <div :class="['p-0 border border-light', micro.microClass]">
                  <input
                    :disabled="!micro.editableTotal"
                    :type="micro.microValue"
                    :value="formatNumberInt(grandTotal())"
                    class="form-control form-control-sm py-1"
                    style="text-align: right"
                  />
                </div>
              </template>
              <template v-else-if="micro.key === 'ppn'">
                <template v-if="checkedPajak">
                  <div
                    :class="[
                      'border border-light bg-light d-flex justify-content-between align-items-center',
                      micro.microClass,
                    ]"
                  >
                    {{ micro.microName }} <span>:</span>
                  </div>
                  <div :class="['p-0 border border-light', micro.microClass]">
                    <input
                      :disabled="!micro.editableTotal"
                      :type="micro.microValue"
                      :value="formatNumberInt(totalPPN)"
                      class="form-control form-control-sm py-1"
                      style="text-align: right"
                    />
                  </div>
                </template>
              </template>
              <template v-else>
                <div
                  :class="[
                    'border border-light bg-light d-flex justify-content-between align-items-center',
                    micro.microClass,
                  ]"
                >
                  {{ micro.microName }} <span>:</span>
                </div>
                <div :class="['p-0 border border-light', micro.microClass]">
                  <input
                    :disabled="!micro.editableTotal"
                    @input="refreshMethods()"
                    :type="micro.microType"
                    v-model="micro.value"
                    class="form-control form-control-sm py-1"
                    style="text-align: right"
                  />
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { HotTable } from "@handsontable/vue";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";

// register Handsontable's modules
registerAllModules();
import { addTrans, getCoa } from "../services/components";
import { dynamicJS } from "../services/dynamic";
import {
  formatNumberInt,
  removeThousandFormatter,
} from "../../components/function/number";
export default {
  components: {
    HotTable,
  },
  props: {
    refreshMethods: {
      type: Function,
      required: true,
    },
    posts: {
      type: Object,
      required: true,
    },
    Item: {
      type: Array,
      required: true,
    },
    component: {
      type: Array,
      required: true,
    },
    hotSettings: {
      type: Object,
      required: true,
    },
    contentDivWidth: {
      type: Number,
      required: true,
    },
    widthTable: {
      type: Number,
      required: true,
    },
    excelData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      optionsCoa: [],
      coaValue: "",
      dpValue: "",
      totalDp: "",
      totalDiskon: "",
      checkedPajak: false,
      shipTo: [],
      dynamicFunction: dynamicJS,
      filter: "",
      filterFields: "",
      filterSort: "",
      filterList: [{ filter: "" }],
      checkedFields: [],
      sortBy: "",
      sortDesc: false,
      currentPage: 1,
      perPage: 8,
    };
  },
  computed: {
    totalPPN() {
      return (this.subTotal() * 11) / 100;
    },
    fixExcelData() {
      return this.excelData;
    },

    dummyItems() {
      const dummy = {};
      for (const key in this.component) {
        dummy[key] = { ...this.component[key] };
      }
      return dummy;
    },
    // subTotal() {
    //   let sum = 0
    //   for (const item of this.excelData) {
    //     const value = item.microValue; // Remove leading and trailing spaces
    //     if (value !== '') {
    //       sum += parseFloat(value); // Convert to float and add to the sum
    //     }
    //   }
    //   return sum
    // },
    dataRange() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.data.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.data.length / this.perPage);
    },
    dataRangeText() {
      const startIndex = (this.currentPage - 1) * this.perPage + 1;
      const endIndex = Math.min(startIndex + this.perPage - 1, this.data.length);
      return `${startIndex} - ${endIndex} data dari total ${this.data.length} data`;
    },
  },
  created() {
    this.initializeCheckedFields();
  },
  async mounted() {
    const coa = await getCoa();
    this.optionsCoa = coa.coaCashBank;
  },
  methods: {
    checkTotal(data) {
      if (this.grandTotal() < 0) {
        if (data === "DP") {
          this.dpValue = "";
        } else if (data === "Diskon") {
          this.totalDiskon = "";
        }
        alert(data + " Tidak boleh lebih dari Grand Total!");
      }
    },
    subTotal() {
      const tes = this.fixExcelData.reduce(
        (accumulator, item) => accumulator + item.tablePrice * item.tableQty,
        0
      );
      return tes;
    },
    grandTotal() {
      let tes;
      if (this.checkedPajak) {
        tes = this.subTotal() + this.totalPPN;
      } else {
        tes = this.subTotal();
      }
      tes =
        tes -
        removeThousandFormatter(this.totalDiskon) -
        removeThousandFormatter(this.dpValue);
      return tes;
    },
    refreshFields() {
      this.component
        .filter((x) => x.componentName === "fieldHeader")
        .forEach((y) => {
          y.componentMicro.find((z) => (z.value = ""));
        });
      this.component.find(
        (x) => x.componentName === "fieldNote"
      ).componentMicro[0].microValue = "";
    },
    formatNumberInt(data) {
      return formatNumberInt(data);
    },
    alertLoading() {
      this.$swal({
        title: "Loading...",
        text: "Mohon Ditunggu ya.",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        willOpen: () => {
          this.$swal.showLoading();
        },
      });
    },
    successAdd() {
      this.$swal("Berhasil!", "Data berhasil ditambahkan", "success");
    },
    generateIdChart(length) {
      const numChars = "0123456789";
      const alphaChars = "abcdefghijklmnopqrstuvwxyz";
      const characters = numChars.repeat(0.8 * length) + alphaChars.repeat(0.2 * length);
      let result = "";

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }

      return result;
    },
    selectCustomer(data) {
      if (data) {
        this.shipTo = data.userAddress;
      } else {
        this.shipTo = [];
        this.component
          .filter((x) => x.componentName === "fieldHeader")
          .forEach((y) => {
            y.componentMicro.find((z) => {
              if (z.key === "shipTo") {
                z.value = [];
              }
            });
          });
      }
    },
    addRequester(newCat) {
      const cat = {
        userName: newCat,
        userType: "-",
      };
      this.component
        .filter((x) => x.componentName === "fieldHeader")
        .forEach((y) => {
          y.componentMicro.find((z) => {
            if (z.microType === "multiselect") {
              if (z.key === "PrRequestBy") {
                z.microOptions.push(cat);
                z.value = cat;
              }
            }
          });
        });
    },
    customLabel(option) {
      return `${option.userName} (${option.userType})`;
    },
    customLabel1(option) {
      return `${option.nameAddr} (${option.location})`;
      return option;
    },
    customLabel2(option) {
      return `${option.coaName} (${option.coaCode})`;
      return option;
    },
    sendData(event) {
      event.preventDefault();
      // header Data
      const finalHeader = this.component.reduce((result, item) => {
        if (item.componentName === "fieldHeader" && Array.isArray(item.componentMicro)) {
          item.componentMicro.forEach((microItem) => {
            if (microItem.value !== undefined) {
              result[microItem.key] = microItem.value;
            } else {
              result[microItem.key] = "";
            }
          });
        }
        return result;
      }, {});
      // table Data
      const filteredItem = {};
      this.Item.forEach((item) => {
        filteredItem[item.itemCode] = item;
      });
      const fieldsTable = this.component.find(
        ({ componentName }) => componentName === "fieldTable"
      ).componentMicro;
      const finalTable = this.excelData
        .filter((item) => {
          return fieldsTable.some((obj) => {
            const value = item[obj.key];
            return (
              value !== "" && value !== undefined && value !== null && value.length > 0
            );
          });
        })
        .map((item) => {
          const data = {
            ...filteredItem[item.productCode],
            idCart: this.generateIdChart(24),
            itemQty: item.tableQty.toString(),
            itemHysStdAmount: filteredItem[item.productCode].itemStandartHPP
              ? filteredItem[item.productCode].itemStandartHPP
              : "0",
            itemStandartHPP: filteredItem[item.productCode].itemStandartHPP
              ? filteredItem[item.productCode].itemStandartHPP
              : "0",
          };
          if (item.tablePriceCode === "Custom") {
            data.itemMasterPrice = {
              priceCode: this.generateIdChart(24),
              priceName: "Custom",
              priceQty: item.tableQty.toString(),
              priceUm: item.tableUoM,
              priceExpression: "",
              priceValue: item.tablePrice,
              priceDiscount: [],
              // priceData: [
              //   {
              //     priceQty: item.tableQty.toString(),
              //     priceUm: item.tableUoM,
              //     priceExpression: "",
              //     priceValue: item.tablePrice,
              //     priceDiscount: [],
              //   },
              // ],
            };
          } else if (item.tablePriceCode) {
            data.itemMasterPrice = data.itemMasterPrice
              .map((x) => ({
                data: x,
                priceCode: x.priceCode,
                priceName: x.priceName,
                priceQty: x.priceData[0].priceQty,
                priceUm: item.tableUoM,
                priceExpression: x.priceData[0].priceExpression,
                priceValue: x.priceData[0].priceValue,
                priceDiscount: x.priceData[0].priceDiscount,
              }))
              .find((x) => x.priceName === item.tablePriceCode);
          }
          return data;
        });
      console.log(finalTable);
      // final Note
      const finalNote = this.component.reduce((result, item) => {
        if (item.componentName === "fieldNote" && Array.isArray(item.componentMicro)) {
          item.componentMicro.forEach((microItem) => {
            result[microItem.key] = microItem.microValue;
          });
        }
        return result;
      }, {});
      // final Total
      const finalTotal = this.component.reduce((result, item) => {
        if (item.componentName === "fieldTotal" && Array.isArray(item.componentMicro)) {
          item.componentMicro
            .filter((tes) => tes.editableTotal === true)
            .forEach((microItem) => {
              if (isNaN(microItem.value)) {
                result[microItem.key] = 0;
              } else {
                result[microItem.key] = microItem.value;
              }
            });
        }
        return result;
      }, {});
      // final Footer
      const finalFooter = {
        note: finalNote,
        total: finalTotal,
      };
      // final Data
      const now = new Date();
      const hour = String(now.getHours()).padStart(2, 0);
      const minutes = String(now.getMinutes()).padStart(2, 0);
      const finalData = {
        transDomain: localStorage.getItem("domainCode"), // nanti dibuat otomatis
        // transId: "0",
        transType: "SalesOrder",
        transKasir: finalHeader.sales._id,
        transName: finalHeader.customer.userName,
        transIdMember: finalHeader.customer._id,
        transTable: "",
        transStatusPayment: "no",
        transCetakShift: "no",
        transPayment: "",
        transPesanan: finalTable,
        transTime: hour + ":" + minutes,
        transDate: finalHeader.orderDate,
        transShipmentPlanDate: finalHeader.shipPlan,
        transShipTo: [finalHeader.shipTo],
        transCoaCode: this.coaValue.coaCode ? this.coaValue.coaCode : "",
        transCoaName: this.coaValue.coaName ? this.coaValue.coaName : "",
        transDPValue: this.dpValue ? this.dpValue.replace(/,/g, "") : "0",
        transRouting: [],
        transNoSo: "",
        transTotal:
          this.checkedPajak === true ? this.subTotal() + this.totalPPN : this.subTotal(),
        transBayar: this.dpValue ? parseFloat(this.dpValue.replace(/,/g, "")) : 0,
        // totalHysStdAmount: 0,
        // sisaBayar: 4000000,
        // transDiskon: 0,
        // totalPPN: 0,

        // tambahan
        transPPN: this.checkedPajak === true ? "yes" : "no",
        transPPNAmount: this.checkedPajak === true ? this.totalPPN : 0,
        transDP: this.transDP ? parseFloat(removeThousandFormatter(this.transDP)) : 0,
        transDiskon: this.totalDiskon ? removeThousandFormatter(this.totalDiskon) : 0,
        transDiscount: [
          {
            totalItem: "0",
            totalTrans: this.totalDiskon
              ? removeThousandFormatter(this.totalDiskon)
              : "0",
          },
        ],
      };
      console.log(finalData);
      console.log(JSON.stringify(finalData));
      const doneValidator = Object.entries(finalHeader).every(([key, value]) => {
        if (key === "orderNumber") {
          return true;
        } else if (value === "" || value === null) {
          return false;
        }
        return true;
      });
      const tableValidator = finalTable.every((obj) => {
        return Object.entries(obj).every(([key, value]) => {
          if (
            key === "itemBundling" ||
            key === "itemPajak" ||
            key === "itemImage" ||
            key === "itemCoA" ||
            key === "itemDescription" ||
            key === "itemStandartHPP"
          ) {
            console.log("true", key);
            return true;
          } else if (typeof value === "array" && value.length === 0) {
            console.log("false 1", key, value);
            return false;
          } else if (value === "" || value === null) {
            console.log("false 2", key, value);
            return false;
          }
          return true;
        });
      });
      console.log(doneValidator, finalTable.length, tableValidator);
      if (doneValidator && finalTable.length > 0 && tableValidator) {
        this.$swal({
          title: "Anda Yakin?",
          text: "Tekan Simpan jika anda sudah yakin!",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Simpan",
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.alertLoading();
            try {
              console.log("POST SO DRAGON ",JSON.stringify(finalData))
              const voucher = await addTrans(finalData);
              this.successAdd();
              this.refreshFields();
              this.$router.push({ name: this.posts.transRouting.replace("-add", "") });
            } catch (error) {
              alert(error);
            }
          }
        });
      } else {
        alert("ada data yang masih Kosong!");
      }
    },
    addList() {
      const link = "/transaksi/" + this.routingLink + "/add";
      this.$router.push(link);
    },
    resetFilter() {
      this.filter = "";
      this.filterFields = "";
      this.filterSort = "";
      this.filterList = [{ filter: "" }];
    },
    addFilter() {
      if (this.filter !== "") {
        this.filterList.push({ filter: "" });
      }
    },
    removeFilter() {
      if (this.filterList.length > 1) {
        this.filterList.splice(-1);
      }
    },
    serviceDetail(item) {
      const data = item._id;
      this.$store.commit("setDataProses", item);
      this.$router.push({ name: "transaksi-detail", params: { data } });
    },
    initializeCheckedFields() {
      if (this.fields !== undefined) {
        this.checkedFields = [];
        this.fields.forEach((field) => {
          this.checkedFields.push(field.key);
        });
      }
    },
    handleButtonClick(item) {
      // Perform action when the button is clicked
      console.log("Button clicked:", item);
    },
    handleRowClick(item) {
      // Handle row click event
      console.log("Row clicked:", item);
    },
  },
};
</script>
