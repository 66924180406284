<template>
  <main id="content" role="main" class="main">
    <div class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Modal title</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="report p-3 printable-element"
              style="font-size: 18px; width: 100%; font-family: sans-serif"
              v-if="fixCetakData"
            >
              <div class="row">
                <div class="col-12 d-flex flex-wrap">
                  <template v-if="fixCetakData" v-for="(data, index) in fixCetakData">
                    <div class="card m-3" style="width: 225px">
                      <div class="card-header">
                        <b style="font-size: 14px">Nama Item :</b>
                        <p style="font-size: 14px" class="m-0" v-if="data.itemHysName">
                          {{ data.itemHysName }}
                        </p>
                        <b style="font-size: 14px">ID Item :</b>
                        <p style="font-size: 14px" class="m-0" v-if="data.itemHysName">
                          {{ data.itemHysId }}
                        </p>
                      </div>
                      <div class="card-body px-0">
                        <b class="ps-2" style="font-size: 14px">No. Batch : </b
                        ><span style="font-size: 14px">{{ data.itemNoBatch }}</span>
                        <p class="m-0">
                          <svg
                            class="barcode-class-print"
                            :ref="'barcode-' + index"
                          ></svg>
                        </p>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary">Save changes</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="my-modal"
      title="Cetak Label"
      ok-title="Cetak"
      @ok="doPrint"
      cancel-title="Kembali"
    >
      <template v-if="cetakData">
        <!-- <svg ref="barcode"></svg> -->
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="text-center">Nama Item</th>
              <th class="text-center">No. Item</th>
              <th class="text-center">No. Kontrol</th>
              <th class="text-center">Qty. Cetak</th>
            </tr>
          </thead>
          <tbody v-if="cetakData.transHysItem">
            <tr v-for="data in cetakData.transHysItem">
              <td class="align-top">{{ data.itemHysName }}</td>
              <td class="align-top">{{ data.itemHysId }}</td>
              <td class="align-top">{{ data.itemNoBatch }}</td>
              <td class="align-top">
                <input type="number" class="w-100" v-model="data.cetakQty" />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4">Tidak Ada Data</td>
            </tr>
          </tbody>
        </table>
      </template>
    </b-modal>
    <div class="content container-fluid p-3 ps-0 pt-0" ref="contentDiv">
      <div class="row my-1">
        <div class="col-4 d-flex align-items-center">
          <ul class="breadcrumb m-0">
            <li>
              <router-link to="/dashboard">
                <a href="#"><i class="bi-house-door"></i></a>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: posts.transRouting }">
                <a href="#">List {{ posts.transTitle }}</a>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-4 text-center">
          <span class="h2">{{ posts.transTitle }}</span>
        </div>
      </div>
      <hr class="my-2" />

      <!-- Content -->
      <template v-if="isDataLoaded">
        <div class="card">
          <div class="card-header bg-light p-2">
            <div class="row d-flex">
              <div class="col-9 row">
                <div
                  class="d-flex col-12 align-items-center pe-0 mt-2"
                  v-for="(data, i) in filterList"
                >
                  <select
                    style="font-size: 12px; max-width: 125px"
                    class="form-select form-select-sm py-1 px-2"
                    v-model="data.filterBaris"
                  >
                    <option value="" v-if="!filterBaris">Filter Baris</option>
                    <option
                      v-for="micro in tableHeader"
                      :value="micro.key"
                      v-if="micro.key !== 'actions'"
                    >
                      {{ micro.label }}
                    </option>
                  </select>
                  <select
                    class="mx-2 form-select form-select-sm py-1 px-2"
                    style="font-size: 12px; max-width: 125px"
                    v-model="data.startFrom"
                  >
                    <option value="" v-if="!data.startFrom">Tipe Pencarian</option>
                    <option :value="micro" v-for="micro in optionQuery">
                      {{ micro }}
                    </option>
                  </select>
                  <div class="input-group" style="width: 150px">
                    <div class="input-group-prepend">
                      <span class="input-group-text py-1 px-2"
                        ><i class="bi-search" style="font-size: 10px"></i
                      ></span>
                    </div>
                    <input
                      type="search"
                      class="form-control form-control-sm p-1"
                      placeholder="Cari Data..."
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      style="font-size: 12px"
                      v-model="data.filter1"
                    />
                  </div>
                  <div class="input-group px-2" style="width: 150px">
                    <div class="input-group-prepend">
                      <span class="input-group-text py-1 px-2"
                        ><i class="bi-search" style="font-size: 10px"></i
                      ></span>
                    </div>
                    <input
                      type="search"
                      class="form-control form-control-sm p-1"
                      placeholder="Cari Data..."
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      style="font-size: 12px"
                      v-model="data.filter2"
                    />
                  </div>
                  <select
                    class="mx-2 form-select form-select-sm py-1"
                    style="font-size: 12px; max-width: 65px"
                    v-model="data.logic"
                  >
                    <option value="and">dan</option>
                    <option value="or">atau</option>
                  </select>
                  <i
                    class="bi-plus-circle text-primary ms-1"
                    style="cursor: pointer"
                    @click="addFilterList"
                  ></i>
                  <i
                    class="bi-x-circle text-danger ms-1"
                    style="cursor: pointer"
                    @click="removeFilterRow(i)"
                  ></i>
                </div>
              </div>

              <div
                class="col-3 d-flex justify-content-between mt-2 align-items-start p-0"
                style="z-index: 2"
              >
                <button type="button" class="btn-xs btn btn-outline-success">Cari</button>
                <button type="button" class="btn-xs btn btn-outline-warning">
                  Reset
                </button>
                <div class="btn-group me-2">
                  <router-link :to="$route.path + '/add'" class="btn btn-xs btn-primary">
                    Tambah
                  </router-link>
                  <button
                    type="button"
                    class="btn btn-xs btn-primary dropdown-toggle dropdown-toggle-split"
                    href="javascript:;"
                    id="accountNavbarDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-dropdown-animation
                  ></button>
                  <div class="dropdown">
                    <div
                      class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                      aria-labelledby="accountNavbarDropdown"
                    >
                      <div class="flex-grow-1 ms-3">
                        <p class="card-text text-body" style="font-size: 12px">
                          Import file
                        </p>
                      </div>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item" href="#"> json</a>
                      <a class="dropdown-item" href="#">xls</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body p-2">
            <div class="d-flex align-items-center justify-content-between">
              <div
                style="font-size: 11px; width: 300px"
                class="d-flex align-items-center justify-content-between"
              >
                Menampilkan 1 - 10 data dari {{ rows }}
                <select
                  class="form-select py-1"
                  aria-label="Default select example"
                  style="width: 75px; font-size: 12px; padding: 0 0.5rem !important"
                >
                  <option v-for="data in pageList" :value="data">
                    {{ data }}
                  </option>
                </select>
              </div>
              <b-pagination
                class="m-0"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                ><template #first-text
                  ><button class="btn btn-outline-primary btn-xs">First</button></template
                >
                <template #prev-text
                  ><button class="btn btn-outline-primary btn-xs">Prev</button></template
                >
                <template #next-text
                  ><button class="btn btn-outline-primary btn-xs">Next</button></template
                >
                <template #last-text
                  ><button class="btn btn-outline-primary btn-xs">Last</button></template
                >
                <template #ellipsis-text> ... </template>
                <template #page="{ page, active }">
                  <b v-if="active">{{ page }}</b>
                  <i v-else>{{ page }}</i>
                </template></b-pagination
              >
            </div>
            <hr class="my-2" />
            <!-- tabel view untuk detil -->
            <b-table
              striped
              hover
              :items="dataListJE"
              :fields="tableHeader"
              label-sort-asc=""
              label-sort-desc=""
              class="myTable listTable"
            >
              <template #cell(PoSupplier)="data">
                {{ data.item.PoSupplier.userName }}
              </template>
              <template #cell(transEffDate)="data">
                {{ formatIndDate(data.item.transHysItem[0].itemHysEffDate) }}
              </template>
              <template #cell(coaType)="data">
                <template v-if="data.item.coaTrans[0].transType"> Kredit </template>
                <template v-else> Debet </template>
              </template>
              <template #cell(transHysItem)="data">
                <div class="inner-table">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th style="width: 125px">Nama Item</th>
                        <th>Location</th>
                        <th style="width: 75px">No Kontrol</th>
                        <th style="width: 75px">Qty. Order</th>
                        <th style="width: 75px">Qty. Recive</th>
                        <!-- Tambahkan kolom lain di sini sesuai kebutuhan -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in data.item.transHysItem" :key="index">
                        <td>{{ item.itemHysName }}</td>
                        <td>
                          <template v-if="item.itemLocation">
                            {{ item.itemLocation.name }}
                          </template>
                        </td>
                        <td>
                          <template v-if="item.itemNoBatch">
                            {{ item.itemNoBatch }}
                          </template>
                        </td>
                        <td>{{ item.itemHysQtyOrder }}</td>
                        <td>{{ item.itemHysQtyRcp }}</td>
                        <!-- Tambahkan kolom lain di sini sesuai kebutuhan -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <template v-slot:cell(actions)="data">
                <b-dropdown id="dropdown-option" right size="xs" variant="primary">
                  <template #button-content class="px-1 py-0">
                    <i class="bi-gear px-1 py-0"></i>
                  </template>
                  <b-dropdown-item @click="detilAction(data.item.transId)"
                    >Detail</b-dropdown-item
                  >
                  <b-dropdown-item @click="showModal(data.item)"
                    >Cetak Label</b-dropdown-item
                  >
                </b-dropdown>
              </template>
            </b-table>
          </div>
        </div>
      </template>
      <div v-else>Loading...</div>
    </div>
  </main>
</template>

<script>
// import router from "@/router";
import JsBarcode from "jsbarcode";

import { get1, getPOReceipt } from "../components/services/components";
import { formatNumberInt } from "../components/function/number";
import { formatIndDate } from "../components/function/date";
export default {
  components: {},
  data() {
    return {
      fixCetakData: [],
      cetakData: [],
      // pagination
      rows: 3,
      perPage: 10,
      currentPage: 1,
      optionQuery: ["start at", "equal", "include"],
      pageList: ["10", "25", "50", "Semua"],
      // ===
      listUser: [],
      filterList: [],
      filterText: "",
      sendData: {},
      transData: [],
      contentDivWidth: 0,
      routing: "",
      tableHeader: [],
      component: [],
      dataListJE: [],
      isDataLoaded: false,
      posts: [],
    };
  },
  async mounted() {
    this.addFilterList();
    this.posts = await get1(this.$route.name);
    this.tableHeader = [
      {
        key: "transHysNo",
        label: "No. PO Receipt",
        sortable: true,
        class: "align-top",
        thClass: "text-center",
        thStyle: "width:125px",
      },
      {
        key: "transHysReff[0]",
        label: "Kode PO",
        sortable: true,
        class: "align-top",
        thClass: "text-center",
        thStyle: "width:125px",
      },
      {
        key: "transHysItem",
        label: "List Item",
        sortable: true,
        class: "align-top",
        thClass: "text-center",
        thStyle: "",
      },
      {
        key: "transHysUser.userName",
        label: "Receipt By",
        sortable: true,
        class: "align-top",
        thClass: "text-center",
        thStyle: "",
      },
      {
        key: "transEffDate",
        label: "Receipt Date",
        sortable: true,
        class: "align-top",
        thClass: "text-center",
        thStyle: "width:100px",
      },
      {
        key: "actions",
        label: "Actions",
        class: "align-top",
        thClass: "text-center",
        thStyle: "width:75px",
      },
    ];
    // this.listUser = await getUser()
    // this.listSupplier = await getSupplier()
    let newData = await getPOReceipt();
    this.dataListJE = newData.map((x) => ({
      ...x,
      transHysItem: x.transHysItem.map((y) => ({ ...y, cetakQty: y.itemHysQtyRcp })),
    }));
    // console.log('coba',this.dataListJE)
    this.isDataLoaded = true;
  },
  computed: {
    filteredTableData() {
      const filterText = this.filterText.toLowerCase().trim();
      return this.data.filter((row) => {
        for (let key in row) {
          const value = String(row[key]);
          if (value.toLowerCase().includes(filterText)) {
            return true;
          }
        }
        return false;
      });
    },
  },
  methods: {
    doPrint(event) {
      event.preventDefault();
      // this.generateBarcode();
      const tes = this.cetakData.transHysItem.flatMap((item) =>
        Array.from({ length: parseFloat(item.cetakQty, 10) }, () => ({ ...item }))
      );
      this.fixCetakData = tes;
      console.log("hasil", tes);

      this.$nextTick(() => {
        tes.forEach((barcodeValue, index) => {
          console.log("tes 1", barcodeValue);
          const svg = this.$refs["barcode-" + index];

          // Ensure that the SVG element exists
          if (!svg) {
            console.error(`SVG element for barcode ${index} not found`);
            return;
          }

          // Replace 'CODE128' with your desired barcode format
          const barcodeFormat = "CODE128";

          // Options for the barcode
          const options = {
            format: barcodeFormat,
            height: 50,
          };

          // Generate the barcode using JsBarcode
          if (barcodeValue) {
            JsBarcode(svg, barcodeValue.itemNoBatch, options);
            // svg.style.width = "200px";
          }
        });
        this.printReport();
      });
    },
    printReport() {
      console.log(this.getReportContent());
      const printWindow = window.open("", "_blank");
      const printDocument = `
        <html>
          <head>
	          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet">
          <style>
              th {
                font-weight: 500
              }
              tr {
                font-size: 10px
              }
              body {
                padding-left: 1px;
                width: 5.65in;
              }
          </style>
            </head>

          <body>
            <div style="width: 800px">

            ${this.getReportContent()}
            </div>
          </body>
        </html>
      `;
      printWindow.document.write(printDocument);
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
        printWindow.close();
      };
    },
    getReportContent() {
      const reportView = document.querySelector(".printable-element");
      if (reportView) {
        return reportView.innerHTML;
      }
      return "No content found.";
    },
    generateBarcode() {
      console.log("tes");
      const svg = this.$refs.barcode;

      // Replace '123456789' with your desired barcode value
      const barcodeValue = "M123456789";

      // Replace 'CODE128' with your desired barcode format
      const barcodeFormat = "CODE128";

      // Options for the barcode
      const options = {
        format: barcodeFormat,
        height: 50,
      };

      // Generate the barcode using JsBarcode
      console.log("tes 2");
      JsBarcode(svg, barcodeValue, options);

      console.log("tes 3");
    },
    showModal(data) {
      this.cetakData = data;
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    formatIndDate(data) {
      return formatIndDate(data);
    },
    formatNumberInt(data) {
      return formatNumberInt(data);
    },
    addFilterList() {
      const data = {
        filterBaris: "",
        startFrom: "",
        filter1: "",
        logic: "",
        filter2: "",
      };
      this.filterList.push(data);
    },

    removeFilterRow(data) {
      this.filterList.splice(data, 1);
    },
    detilAction(sonum) {
      // alert(sonum);
      this.$router.push({ name: this.$route.name + "-detail", params: { noSO: sonum } });
    },
  },
};
</script>
<style scope></style>
