var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "fixed", bottom: "10px", right: "10px" } },
    [
      _vm.showChat
        ? _c(
            "div",
            {
              staticClass: "bg-light bg-white border border-secondary rounded",
              staticStyle: { width: "350px", height: "80vh" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "chat-header p-2 d-flex justify-content-between rounded-top bg-info align-items-center border-bottom border-secondary",
                },
                [
                  _c("b", { staticClass: "text-white" }, [_vm._v("Pesan")]),
                  _c("i", {
                    staticClass: "bi-x text-white",
                    staticStyle: { "font-size": "20px", cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        _vm.showChat = false
                      },
                    },
                  }),
                ]
              ),
              _vm._m(0),
            ]
          )
        : _c(
            "button",
            {
              staticClass: "btn btn-lg btn-info rounded-circle",
              staticStyle: { padding: "5px 10px" },
              on: {
                click: function ($event) {
                  _vm.showChat = !_vm.showChat
                },
              },
            },
            [
              _c("i", {
                staticClass: "bi-chat-text-fill",
                staticStyle: { "font-size": "20px" },
              }),
            ]
          ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "chatting-body p-1 pt-3" }, [
      _c("div", { staticClass: "w-100" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            {
              staticClass:
                "card-header p-2 fs-11 d-flex justify-content-between",
            },
            [
              _c("b", [_vm._v("Azam Rizky Andrean")]),
              _c("b", [_vm._v("22:10 - 22/10/2023")]),
            ]
          ),
          _c("div", { staticClass: "card-body p-2" }, [
            _vm._v(
              " tes lorem ipsum dolot sit amet dan lain lain dan lain lain "
            ),
          ]),
        ]),
        _c("hr", { staticClass: "my-2" }),
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            {
              staticClass:
                "card-header p-2 fs-11 d-flex justify-content-between",
            },
            [
              _c("b", [_vm._v("Azam Rizky Andrean")]),
              _c("b", [_vm._v("22:10 - 22/10/2023")]),
            ]
          ),
          _c("div", { staticClass: "card-body p-2" }, [
            _vm._v(
              " tes lorem ipsum dolot sit amet dan lain lain dan lain lain "
            ),
          ]),
        ]),
        _c("hr", { staticClass: "my-2" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }