<template>
  <main id="content" role="main" class="main">
    <div class="content container-fluid p-3 ps-0 py-0" ref="contentDiv">
      <transaksiDetailKlinikPOR
        :posts="posts"
        :component="component"
        :hotSettings="hotSettings"
        :widthTable="contentDivWidth"
        :dataDetail="dummyData"
        :refreshMethods="refreshValue()"
      ></transaksiDetailKlinikPOR>
    </div>
  </main>
</template>

<script>
import { get1 } from "../../components/services/components";
import transaksiDetailKlinikPOR from "../../components/transaksi/transaksiDetailKlinikPOR.vue";
import { HotTable } from "@handsontable/vue";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";

// register Handsontable's modules
registerAllModules();
export default {
  props: {
    dataDetail: {
      type: Object,
      required: true,
    },
  },
  components: {
    transaksiDetailKlinikPOR,
    HotTable,
  },
  data() {
    return {
      dummyData: [],
      detailData: [],
      contentDivWidth: 0,
      hotSettings: {
        afterChange: this.refreshValue,
        licenseKey: "non-commercial-and-evaluation",
        height: 200,
        colHeaders: true,
        readOnly: true,

        rowHeaderWidth: 35,
        startRows: 10,
        columns: [],
        // Other Handsontable settings
      },
      component: [],
      posts: {
        // transTitle: 'Purchase Order Receipt',
        transTitle: "Barang Datang",
        transRouting: "po-receipt-klinik-detail",
        transProperties: [
          {
            dataName: "menuDetail",
            dataMicro: [
              {
                componentName: "fieldHeader",
                componentTitle: "",
                componentClass: "col-md-6",
                componentMicro: [
                  {
                    key: "supplier",
                    microName: "Supplier",
                    microType: "text",
                    microText: "Masukan supplier",
                    microClass: "",
                  },
                  {
                    key: "noPOR",
                    microName: "No. PO Receipt",
                    microType: "text",
                    microText: "Masukan nomor purchase order receipt",
                    microClass: "",
                  },
                  {
                    key: "noSJ",
                    microName: "No. Surat Jalan",
                    microType: "text",
                    microText: "Masukan nomor surat jalan",
                    microClass: "",
                  },
                ],
              },
              {
                componentName: "fieldHeader",
                componentTitle: "",
                componentClass: "col-md-6",
                componentMicro: [
                  {
                    key: "noKen",
                    microName: "No. Kendaraan",
                    microType: "text",
                    microText: "Masukan nomor kendaraan",
                    microClass: "",
                  },
                  {
                    key: "tglReceipt",
                    microName: "Tgl. Receipt",
                    microType: "date",
                    microText: "Masukan tujuan pengiriman",
                    microClass: "",
                  },
                ],
              },
              {
                componentName: "fieldTable",
                componentTitle: "",
                componentClass: "col-md-12",
                componentMicro: [
                  {
                    key: "poCode",
                    microName: "Kode PO",
                    microType: "text",
                    width: 100,
                    microText: "Masukan Nama",
                    microClass: "",
                  },
                  {
                    key: "itemCode",
                    microName: "Kode Item",
                    microType: "text",
                    width: 100,
                    microText: "Masukan Nama",
                    microClass: "",
                  },
                  {
                    key: "itemName",
                    microName: "Nama Item",
                    microType: "text",
                    width: 0,
                    microText: "Masukan Nama",
                    microClass: "",
                  },
                  {
                    key: "noBatch",
                    microName: "No. Batch",
                    microType: "text",
                    width: 0,
                    microText: "Masukan Nama",
                    microClass: "",
                  },
                  {
                    key: "uom",
                    microName: "UoM",
                    microType: "text",
                    width: 100,
                    microText: "Masukan Nama",
                    microClass: "",
                  },
                  {
                    key: "qtyRequest",
                    microName: "Qty. Request",
                    microType: "numeric",
                    width: 0,
                    microText: "Masukan Quantity",
                    microClass: "",
                  },
                  {
                    key: "qtyReceive",
                    microName: "Qty. Receive",
                    microType: "numeric",
                    width: 0,
                    microText: "Masukan Quantity",
                    microClass: "",
                  },
                ],
              },
              {
                componentName: "fieldNote",
                componentTitle: "Catatan",
                componentClass: "col-md-12",
                componentMicro: [
                  {
                    key: "note",
                    microName: "Note",
                    microType: "textarea",
                    microText: "Masukan Catatan Tambahan",
                    microClass: "",
                    microValue: "",
                  },
                ],
              },
              {
                componentName: "fieldTotal",
                componentTitle: "Total",
                componentClass: "col-md-12",
                componentMicro: [
                  {
                    key: "qtyRequest",
                    microName: "Total Qty Request",
                    value: 0,
                    editableTotal: false,
                    microType: "number",
                    microMath: "",
                    microClass: "col-md-4",
                  },
                  {
                    key: "qtyReceive",
                    microName: "Total Qty Receive",
                    value: 0,
                    editableTotal: false,
                    microType: "number",
                    microMath: "",
                    microClass: "col-md-4",
                  },
                  {
                    key: "total",
                    microName: "Grand Total",
                    editableTotal: false,
                    value: "",
                    microType: "number",
                    microClass: "col-md-4 d-none",
                  },
                ],
              },
              {
                componentName: "routingButton",
                componentTitle: "button",
                componentClass: "col-md-12",
                componentMicro: [
                  {
                    key: "back",
                    microName: "Kembali",
                    microRouting: "back",
                    microText: "Kembali",
                    microClass: "btn-light",
                  },
                  {
                    key: "submit",
                    microName: "",
                    microRouting: "add",
                    microText: "",
                    microClass: "btn-primary",
                  },
                ],
              },
            ],
          },
          {
            dataName: "routingButton",
            dataTitle: "button",
            dataClass: "col-md-12",
            dataMicro: [
              {
                key: "add",
                microName: "",
                microRouting: "add",
                microText: "",
                microClass: "btn-primary",
              },
            ],
          },
        ],
      },
    };
  },
  async mounted() {
    this.component = await this.getComponent();
    const trueFields = this.getFieldTable();
    this.hotSettings.columns = trueFields;
    if (
      this.component.find(({ componentName }) => componentName === "fieldTotal")
        .componentMicro.length > 0
    ) {
      this.getTotalData();
      this.getTotalAuto();
    }
    this.findData();
    this.getContentWidth();

    this.$nextTick(() => {
      this.getContentWidth();
    });
  },
  created() {
    this.dummyData = JSON.parse(JSON.stringify(this.dataDetail));
  },
  methods: {
    async refreshValue() {
      console.log(this.posts);
      this.component = await this.getComponent();
      if (
        this.component.find(({ componentName }) => componentName === "fieldTotal")
          .componentMicro.length > 0
      ) {
        this.getTotalData();
        this.getTotalAuto();
      }
    },
    sumProperty(property) {
      return this.dataDetail.table.reduce(
        (sum, entry) => parseFloat(sum) + entry[property],
        0
      );
    },
    getTotal(property) {
      return this.sumProperty(property);
    },
    getTotalData() {
      this.component
        .find(({ componentName }) => componentName === "fieldTotal")
        .componentMicro.filter((x) => x.key !== "total" && x.editableTotal !== true)
        .forEach((item) => {
          item.value = parseFloat(this.getTotal(item.key));
        });
    },
    getTotalAuto() {
      let totalNumber = 0;
      const totalValue = this.component.find(
        ({ componentName }) => componentName === "fieldTotal"
      ).componentMicro;
      totalValue
        .filter((x) => x.key !== "total")
        .forEach((x) => {
          if (x.microMath === "minus") {
            if (Number.isNaN(x.value)) {
              totalNumber = parseFloat(totalNumber) + 0;
            } else {
              totalNumber = parseFloat(totalNumber) + -1 * x.value;
            }
          } else if (x.microMath === "plus") {
            if (Number.isNaN(x.value)) {
              totalNumber = parseFloat(totalNumber) + 0;
            } else {
              totalNumber = parseFloat(totalNumber) + x.value;
            }
          } else {
          }
        });
      let finalNumber = totalNumber;
      totalValue
        .filter(
          (x) => x.key !== "total" && x.microMath !== "minus" && x.microMath !== "plus"
        )
        .forEach((x) => {
          if (x.microMath === "percent-minus") {
            if (Number.isNaN(x.value)) {
              finalNumber += 0;
            } else {
              finalNumber -= (parseFloat(totalNumber) * x.value) / 100;
            }
          } else if (x.microMath === "percent-plus") {
            if (Number.isNaN(x.value)) {
              finalNumber += 0;
            } else {
              finalNumber += (parseFloat(totalNumber) * x.value) / 100;
            }
          } else {
          }
        });
      this.component
        .find(({ componentName }) => componentName === "fieldTotal")
        .componentMicro.find((x) => x.key === "total").value = finalNumber;
    },
    findData() {
      const headerData = this.dataDetail.header[0];
      const footerData = this.dataDetail.footer[0];
      const combinedData = { ...headerData, ...footerData };
      this.detailData = Object.entries(combinedData).map(([key, value]) => ({
        key,
        value,
      }));
      this.component.forEach((component) => {
        component.componentMicro.forEach((item) => {
          const matchedObject = this.detailData.find((obj) => obj.key === item.key);
          if (matchedObject) {
            item.value = matchedObject.value;
          }
        });
      });
    },
    getContentWidth() {
      const contentDiv = this.$refs.contentDiv;
      let columns = 0;
      const tes = this.hotSettings.columns.filter((x) => x.type === "text" && !x.width)
        .length;
      const widthTes = this.hotSettings.columns;
      for (let i = 0; i < widthTes.length; i++) {
        if (widthTes[i].width >= 0 || widthTes[i].width !== undefined) {
          columns += widthTes[i].width;
        }
      }
      if (contentDiv) {
        this.contentDivWidth = (contentDiv.offsetWidth - 85 - columns) / tes;
      }
    },
    async getComponent() {
      return this.posts.transProperties.find(({ dataName }) => dataName === "menuDetail")
        .dataMicro;
    },
    getFieldTable() {
      const fields = this.component.find(
        ({ componentName }) => componentName === "fieldTable"
      ).componentMicro;
      const trueFields = fields.map((x) => {
        if (x.microType === "date") {
          return { data: x.key, title: x.microName, type: x.microType, width: 100 };
        } else if (x.microType === "numeric") {
          return {
            data: x.key,
            title: x.microName,
            type: x.microType,
            width: 125,
            renderer: (instance, td, row, col, prop, value) => {
              if (typeof value === "number") {
                const formattedValue = value
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                td.innerText = formattedValue;
              } else {
                td.innerText = value;
              }
            },
            validator: (value, callback) => {
              if (value > 1000000000000 || value < 0) {
                callback(false);
              } else {
                callback(true);
              }
            },
          };
        } else {
          if (x.width <= 0) {
            return { data: x.key, title: x.microName, type: x.microType };
          } else {
            return { data: x.key, title: x.microName, type: x.microType, width: x.width };
          }
        }
      });
      return trueFields;
    },
  },
};
</script>
