var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c("div", { staticClass: "content container-fluid" }, [
        _c(
          "div",
          { staticClass: "card" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c(
                      "b-input-group",
                      { staticClass: "mx-2", attrs: { size: "sm" } },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "filter-input",
                            type: "search",
                            pleaceholder: "Type to Search",
                          },
                          model: {
                            value: _vm.filter,
                            callback: function ($$v) {
                              _vm.filter = $$v
                            },
                            expression: "filter",
                          },
                        }),
                        _c(
                          "b-input-group-append",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { size: "sm", disabled: !_vm.filter },
                                on: {
                                  click: function ($event) {
                                    _vm.filter = ""
                                  },
                                },
                              },
                              [_vm._v("Clear")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex justify-content-end align-items-center",
                  },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.modal-add",
                            modifiers: { "modal-add": true },
                          },
                        ],
                        attrs: { variant: "primary", size: "sm" },
                      },
                      [_c("i", { staticClass: "bi-plus-circle" })]
                    ),
                    _c(
                      "b-dropdown",
                      {
                        staticClass: "m-2",
                        attrs: {
                          id: "dropdown-right",
                          right: "",
                          text: "Filter Column",
                          variant: "dark",
                          size: "sm",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "button-content",
                            fn: function () {
                              return [_c("i", { staticClass: "bi-sliders" })]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("b-form-checkbox-group", {
                          attrs: {
                            id: "checkbox-group-1",
                            options: _vm.fields,
                            name: "flavour-1",
                            buttons: "",
                          },
                          model: {
                            value: _vm.selected,
                            callback: function ($$v) {
                              _vm.selected = $$v
                            },
                            expression: "selected",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.selected.length > 0
              ? _c(
                  "b-table",
                  {
                    ref: "tableItem",
                    staticClass: "fs-11",
                    attrs: {
                      "label-sort-asc": "",
                      "label-sort-desc": "",
                      "label-sort-clear": "",
                      bordered: true,
                      stacked: "md",
                      items: _vm.Item,
                      fields: _vm.activeFields,
                      "current-page": _vm.currentPage,
                      "per-page": _vm.perPage,
                      filter: _vm.filter,
                      "tbody-tr-class": "row-class",
                    },
                    on: { filtered: _vm.onFiltered },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(opsi)",
                          fn: function (row) {
                            return [
                              _c(
                                "div",
                                { attrs: { width: "0px" } },
                                [
                                  _c(
                                    "b-dropdown",
                                    {
                                      attrs: {
                                        id: "dropdown-right",
                                        right: "",
                                        text: "Right align",
                                        variant: "primary",
                                        size: "xs",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "button-content",
                                            fn: function () {
                                              return [
                                                _c("i", {
                                                  staticClass: "bi-gear-fill",
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.info(
                                                row.item,
                                                row.index,
                                                $event.target
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Detail")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.edit(
                                                row.item,
                                                row.index,
                                                $event.target
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Edit")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.alertDelete(
                                                "Item",
                                                row.item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Hapus")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(icon)",
                          fn: function (row) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-center",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "avatar overflow-hidden d-flex justify-content-center",
                                      attrs: { for: "editAvatarUploaderModal" },
                                    },
                                    [
                                      _c("img", {
                                        attrs: { src: row.value, alt: "icon" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1751763092
                    ),
                  },
                  [_vm._v("> ")]
                )
              : _vm._e(),
            _c(
              "b-modal",
              {
                attrs: {
                  id: _vm.infoModal.id,
                  title: _vm.infoModal.title,
                  "ok-only": "",
                  "no-close-on-esc": "",
                  "no-close-on-backdrop": "",
                  "hide-header-close": "",
                },
                on: { hide: _vm.resetInfoModal },
                scopedSlots: _vm._u([
                  {
                    key: "modal-footer",
                    fn: function () {
                      return [
                        _vm.dataId !== "" &&
                        _vm.procId !== "" &&
                        _vm.microId !== ""
                          ? _c(
                              "div",
                              {
                                staticClass: "w-100 d-flex justify-content-end",
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "secondary", size: "sm" },
                                    on: {
                                      click: function ($event) {
                                        _vm.microId = ""
                                      },
                                    },
                                  },
                                  [_vm._v(" Kembali ")]
                                ),
                              ],
                              1
                            )
                          : _vm.dataId !== "" &&
                            _vm.procId !== "" &&
                            _vm.microId == ""
                          ? _c(
                              "div",
                              {
                                staticClass: "w-100 d-flex justify-content-end",
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "me-2",
                                    attrs: { variant: "secondary", size: "sm" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$bvModal.hide("info-modal")
                                      },
                                    },
                                  },
                                  [_vm._v(" Kembali ")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    attrs: { size: "sm", variant: "primary" },
                                    on: { click: _vm.addMicroservice },
                                  },
                                  [_vm._v("Tambah Micro")]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "w-100 d-flex justify-content-end",
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "me-2",
                                    attrs: { variant: "secondary", size: "sm" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$bvModal.hide("info-modal")
                                      },
                                    },
                                  },
                                  [_vm._v(" Kembali ")]
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm.dataId !== "" && _vm.procId !== "" && _vm.microId === ""
                  ? _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-4 d-flex justify-content-between",
                          },
                          [_c("span", [_vm._v("Kode")]), _vm._v(": ")]
                        ),
                        _c("div", { staticClass: "col-8 ps-1" }, [
                          _vm._v(_vm._s(_vm.infoModal.content.appProcCode)),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "col-4 d-flex justify-content-between",
                          },
                          [_c("span", [_vm._v("Nama Proses")]), _vm._v(": ")]
                        ),
                        _c("div", { staticClass: "col-8 ps-1" }, [
                          _vm._v(_vm._s(_vm.infoModal.content.appProcName)),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "col-4 d-flex justify-content-between",
                          },
                          [_c("span", [_vm._v("Deskripsi")]), _vm._v(": ")]
                        ),
                        _c("div", { staticClass: "col-8 ps-1" }, [
                          _vm._v(_vm._s(_vm.infoModal.content.appProcDesc)),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "col-4 d-flex justify-content-between",
                          },
                          [_c("span", [_vm._v("Group App")]), _vm._v(": ")]
                        ),
                        _c("div", { staticClass: "col-8 ps-1" }, [
                          _vm._v(_vm._s(_vm.infoModal.content.appGroupMenu)),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "col-4 d-flex justify-content-between",
                          },
                          [_c("span", [_vm._v("Tanggal Dibuat")]), _vm._v(": ")]
                        ),
                        _c("div", { staticClass: "col-8 ps-1" }, [
                          _vm._v(_vm._s(_vm.infoModal.content.appProcCreated)),
                        ]),
                        _c("div", {
                          staticClass: "col-12 d-flex justify-content-center",
                        }),
                        _c("div", { staticClass: "row py-2 mt-2 border-top" }, [
                          _c("div", { staticClass: "col-2" }),
                          _c("div", { staticClass: "col-8 text-center" }, [
                            _c("h1", [_vm._v("Microservice")]),
                          ]),
                          _c("div", {
                            staticClass:
                              "col-2 d-flex justify-content-center align-items-center",
                          }),
                        ]),
                        _c("b-table", {
                          ref: "tableMicro",
                          staticClass: "table-bordered",
                          attrs: {
                            fields: _vm.microFields,
                            items: _vm.microservice,
                            hover: "",
                            "modal-scrollable": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "cell(opsiMicro)",
                                fn: function (row) {
                                  return [
                                    _c(
                                      "div",
                                      { attrs: { width: "0px" } },
                                      [
                                        _c(
                                          "b-dropdown",
                                          {
                                            staticClass: "button-y0",
                                            attrs: {
                                              id: "dropdown-right",
                                              right: "",
                                              text: "Right align",
                                              variant: "primary",
                                              size: "xs",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "button-content",
                                                  fn: function () {
                                                    return [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi-gear-fill",
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.infoMicro(
                                                      row.item,
                                                      row.index,
                                                      $event.target
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Detail")]
                                            ),
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editMicroModal(
                                                      row.item,
                                                      row.index,
                                                      $event.target
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Edit")]
                                            ),
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editMicroVar(
                                                      row.item,
                                                      row.index,
                                                      $event.target
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Edit Variabel")]
                                            ),
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                attrs: { href: "#" },
                                                on: { click: _vm.alertDelete },
                                              },
                                              [_vm._v("Hapus")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1643447471
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm.dataId !== "" && _vm.procId !== "" && _vm.microId !== ""
                  ? _c("div", { staticClass: "row" }, [
                      _c("h4", [_vm._v("Detail Microservice")]),
                      _c(
                        "div",
                        { staticClass: "col-4 d-flex justify-content-between" },
                        [_c("span", [_vm._v("Kode")]), _vm._v(": ")]
                      ),
                      _c("div", { staticClass: "col-8 ps-1" }, [
                        _vm._v(_vm._s(_vm.microModal.content.microCode)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-4 d-flex justify-content-between" },
                        [
                          _c("span", [_vm._v("Nama Microservise")]),
                          _vm._v(": "),
                        ]
                      ),
                      _c("div", { staticClass: "col-8 ps-1" }, [
                        _vm._v(_vm._s(_vm.microModal.content.microName)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-4 d-flex justify-content-between" },
                        [_c("span", [_vm._v("Tipe")]), _vm._v(": ")]
                      ),
                      _c("div", { staticClass: "col-8 ps-1" }, [
                        _vm._v(_vm._s(_vm.microModal.content.microType)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-4 d-flex justify-content-between" },
                        [_c("span", [_vm._v("Deskripsi")]), _vm._v(": ")]
                      ),
                      _c("div", { staticClass: "col-8 ps-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.microModal.content.microDescription) +
                            " "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-4 d-flex justify-content-between" },
                        [_c("span", [_vm._v("Placeholder")]), _vm._v(": ")]
                      ),
                      _c("div", { staticClass: "col-8 ps-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.microModal.content.microPleaceholder) +
                            " "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-4 d-flex justify-content-between" },
                        [_c("span", [_vm._v("Icon")]), _vm._v(": ")]
                      ),
                      _c("div", { staticClass: "col-8 ps-1" }, [
                        _vm._v(_vm._s(_vm.microModal.content.microIcon)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-4 d-flex justify-content-between" },
                        [_c("span", [_vm._v("Navigate")]), _vm._v(": ")]
                      ),
                      _c("div", { staticClass: "col-8 ps-1" }, [
                        _vm._v(_vm._s(_vm.microModal.content.microNavigate)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-4 d-flex justify-content-between" },
                        [_c("span", [_vm._v("Routing")]), _vm._v(": ")]
                      ),
                      _c("div", { staticClass: "col-8 ps-1" }, [
                        _vm._v(_vm._s(_vm.microModal.content.microRouting)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-4 d-flex justify-content-between" },
                        [
                          _c("span", [_vm._v("API Function Script")]),
                          _vm._v(": "),
                        ]
                      ),
                      _c("div", { staticClass: "col-8 ps-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.microModal.content.microAPIFunctionScript
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 d-flex flex-wrap justify-content-between my-4 shadow p-3 bg-white rounded",
                        },
                        [
                          _c(
                            "h4",
                            { staticClass: "d-flex align-items-center m-0" },
                            [_vm._v("Data Microservice")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-xs btn-outline-secondary",
                              attrs: { type: "button " },
                              on: {
                                click: function ($event) {
                                  _vm.microData = !_vm.microData
                                },
                              },
                            },
                            [
                              _vm.microData
                                ? _c("i", { staticClass: "bi-chevron-down" })
                                : _c("i", { staticClass: "bi-chevron-up" }),
                            ]
                          ),
                          _vm.microData
                            ? _c("pre", { staticClass: "w-100 mt-2 mb-0" }, [
                                _vm._v(
                                  _vm._s(
                                    JSON.stringify(
                                      _vm.microModal.content.microData,
                                      null,
                                      " "
                                    )
                                  ) + "\n                          "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 d-flex flex-wrap justify-content-between shadow p-3 bg-white rounded",
                        },
                        [
                          _c(
                            "h4",
                            { staticClass: "d-flex align-items-center m-0" },
                            [_vm._v(" Variabel Microservice ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-xs btn-outline-secondary",
                              attrs: { type: "button " },
                              on: {
                                click: function ($event) {
                                  _vm.microVariable = !_vm.microVariable
                                },
                              },
                            },
                            [
                              _vm.microVariable
                                ? _c("i", { staticClass: "bi-chevron-down" })
                                : _c("i", { staticClass: "bi-chevron-up" }),
                            ]
                          ),
                          _vm.microVariable
                            ? _c("pre", { staticClass: "w-100 mt-2 mb-0" }, [
                                _vm._v(
                                  _vm._s(
                                    JSON.stringify(
                                      _vm.microModal.content.microVariable,
                                      null,
                                      " "
                                    )
                                  ) + "\n                          "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ])
                  : _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "row col-lg-12" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col-5 d-flex justify-content-between",
                          },
                          [_c("span", [_vm._v("Kode COA")]), _vm._v(": ")]
                        ),
                        _c("div", { staticClass: "col-7 ps-1" }, [
                          _vm._v(_vm._s(_vm.infoModal.content.kasbankCode)),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "col-5 d-flex justify-content-between",
                          },
                          [_c("span", [_vm._v("Tipe COA")]), _vm._v(": ")]
                        ),
                        _c("div", { staticClass: "col-7 ps-1" }, [
                          _vm._v(_vm._s(_vm.infoModal.content.kasbankAmount)),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "col-5 d-flex justify-content-between",
                          },
                          [_c("span", [_vm._v("Nama COA")]), _vm._v(": ")]
                        ),
                        _c("div", { staticClass: "col-7 ps-1" }, [
                          _vm._v(_vm._s(_vm.infoModal.content.kasbankName)),
                        ]),
                      ]),
                    ]),
              ]
            ),
            _c(
              "b-modal",
              {
                attrs: {
                  id: _vm.procModal.id,
                  title: _vm.procModal.title,
                  stacked: "",
                  "ok-title": "Tambah Proses",
                  "cancel-title": "Kembali",
                },
                on: { ok: _vm.addProcModal },
              },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "b-table",
                      {
                        ref: "tableProc",
                        attrs: {
                          hover: "",
                          fields: _vm.procFields,
                          items: _vm.process,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(opsiProc)",
                            fn: function (row) {
                              return [
                                _c(
                                  "div",
                                  { attrs: { width: "0px" } },
                                  [
                                    _c(
                                      "b-dropdown",
                                      {
                                        staticClass: "button-y0",
                                        attrs: {
                                          id: "dropdown-right",
                                          right: "",
                                          text: "Right align",
                                          variant: "primary",
                                          size: "xs",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "button-content",
                                              fn: function () {
                                                return [
                                                  _c("i", {
                                                    staticClass: "bi-gear-fill",
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.infoProc(
                                                  row.item,
                                                  row.index,
                                                  $event.target
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Detail")]
                                        ),
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editProcModal(
                                                  row.item,
                                                  row.index,
                                                  $event.target
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Edit")]
                                        ),
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            attrs: { href: "#" },
                                            on: { click: _vm.alertDelete },
                                          },
                                          [_vm._v("Hapus")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [_vm._v(" " + _vm._s(_vm.process) + " ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                attrs: {
                  id: _vm.editModal.id,
                  title: _vm.editModal.title,
                  "ok-variant": "success",
                  "ok-title": "Edit",
                  "cancel-title": "Kembali",
                },
                on: { hidden: _vm.resetEditModal, ok: _vm.handleEdit },
              },
              [
                _c(
                  "form",
                  {
                    ref: "form",
                    staticClass: "row",
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.handleEditSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-4 d-flex align-items-center justify-content-between",
                      },
                      [
                        _c("h4", { staticClass: "m-0" }, [_vm._v("Tipe COA")]),
                        _c("h3", [_vm._v(":")]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-8 my-1" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              "label-for": "typeCOA",
                              "invalid-feedback": "Tipe COA Harus Diisi!",
                              state: _vm.typeState,
                            },
                          },
                          [
                            _c("multiselect", {
                              attrs: {
                                id: "typeCOA",
                                options: _vm.optionsCategory,
                                placeholder: "Select one",
                                taggable: true,
                                multiple: false,
                                "max-height": 200,
                                "track-by": "name",
                                label: "name",
                              },
                              on: { tag: _vm.addCategory },
                              model: {
                                value: _vm.type,
                                callback: function ($$v) {
                                  _vm.type = $$v
                                },
                                expression: "type",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-4 d-flex align-items-center justify-content-between",
                      },
                      [
                        _c("h4", { staticClass: "m-0" }, [_vm._v("Jenis")]),
                        _c("h3", [_vm._v(":")]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-8 my-1" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              "label-for": "codeCOA",
                              "invalid-feedback": "Kode COA Harus Diisi!",
                              state: _vm.NameState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "codeCOA",
                                state: _vm.NameState,
                                required: "",
                              },
                              model: {
                                value: _vm.name,
                                callback: function ($$v) {
                                  _vm.name = $$v
                                },
                                expression: "name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-4 d-flex align-items-center justify-content-between",
                      },
                      [
                        _c("h4", { staticClass: "m-0" }, [_vm._v("Nama COA")]),
                        _c("h3", [_vm._v(":")]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-8 my-1" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              "label-for": "nameCOA",
                              "invalid-feedback": "Nama COA Harus Diisi!",
                              state: _vm.descState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "nameCOA",
                                state: _vm.descState,
                                required: "",
                              },
                              model: {
                                value: _vm.desc,
                                callback: function ($$v) {
                                  _vm.desc = $$v
                                },
                                expression: "desc",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-4 d-flex align-items-center justify-content-between",
                      },
                      [
                        _c("h4", { staticClass: "m-0" }, [_vm._v("Tipe COA")]),
                        _c("h3", [_vm._v(":")]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-8 my-1" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              "label-for": "typeCOA",
                              "invalid-feedback": "Tipe COA Harus Diisi!",
                              state: _vm.typeState,
                            },
                          },
                          [
                            _c("multiselect", {
                              attrs: {
                                id: "typeCOA",
                                options: _vm.optionsCategory,
                                placeholder: "Select one",
                                taggable: true,
                                multiple: false,
                                "max-height": 200,
                                "track-by": "name",
                                label: "name",
                              },
                              on: { tag: _vm.addCategory },
                              model: {
                                value: _vm.type,
                                callback: function ($$v) {
                                  _vm.type = $$v
                                },
                                expression: "type",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                attrs: {
                  id: _vm.editProc.id,
                  title: _vm.editProc.title,
                  "ok-only": "",
                  "no-close-on-esc": "",
                  "no-close-on-backdrop": "",
                  "hide-header-close": "",
                },
                on: { hidden: _vm.resetEditProc },
                scopedSlots: _vm._u(
                  [
                    _vm.dataId !== "" && _vm.procId !== "" && _vm.microId !== ""
                      ? {
                          key: "modal-footer",
                          fn: function () {
                            return [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "secondary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$bvModal.hide(
                                        "edit-modal-proc"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Kembali")]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "success" },
                                  on: { click: _vm.handleMicroEdit },
                                },
                                [_vm._v("Edit")]
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : {
                          key: "modal-footer",
                          fn: function () {
                            return [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "secondary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$bvModal.hide(
                                        "edit-modal-proc"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Kembali")]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "success" },
                                  on: { click: _vm.handleProcEdit },
                                },
                                [_vm._v("Edit")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                  ],
                  null,
                  true
                ),
              },
              [
                _vm.dataId !== "" && _vm.procId !== "" && _vm.microId !== ""
                  ? _c(
                      "form",
                      {
                        ref: "form",
                        on: {
                          submit: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.handleMicroEdit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        !_vm.microVar
                          ? _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h4", { staticClass: "m-0" }, [
                                    _vm._v("Nama"),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "procName",
                                        "invalid-feedback":
                                          "Nama Microservice Harus Diisi!",
                                        state: _vm.NameState,
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "procName",
                                          state: _vm.NameState,
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.name,
                                          callback: function ($$v) {
                                            _vm.name = $$v
                                          },
                                          expression: "name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h4", { staticClass: "m-0" }, [
                                    _vm._v("Kode"),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "procMicroCode",
                                        "invalid-feedback":
                                          "Kode Microservice Harus Diisi!",
                                        state: _vm.descState,
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        {
                                          attrs: {
                                            prepend:
                                              this.infoModal.content
                                                .appProcCode + ".",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "procMicroCode",
                                              type: "number",
                                              state: _vm.descState,
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.microCode,
                                              callback: function ($$v) {
                                                _vm.microCode = $$v
                                              },
                                              expression: "microCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h4", { staticClass: "m-0" }, [
                                    _vm._v("Tipe"),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "microType",
                                        "invalid-feedback":
                                          "Tipe Microservice Harus Diisi!",
                                        state: _vm.typeState,
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "microType",
                                          state: _vm.typeState,
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.type,
                                          callback: function ($$v) {
                                            _vm.type = $$v
                                          },
                                          expression: "type",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h4", { staticClass: "m-0" }, [
                                    _vm._v("Deskripsi"),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { "label-for": "procDesc" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "procDesc" },
                                        model: {
                                          value: _vm.desc,
                                          callback: function ($$v) {
                                            _vm.desc = $$v
                                          },
                                          expression: "desc",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h4", { staticClass: "m-0" }, [
                                    _vm._v("Icon"),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { "label-for": "microIcon" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "microIcon" },
                                        model: {
                                          value: _vm.image,
                                          callback: function ($$v) {
                                            _vm.image = $$v
                                          },
                                          expression: "image",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h4", { staticClass: "m-0" }, [
                                    _vm._v("Placeholder"),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "microPleaceholder",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "microPleaceholder" },
                                        model: {
                                          value: _vm.prov,
                                          callback: function ($$v) {
                                            _vm.prov = $$v
                                          },
                                          expression: "prov",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h4", { staticClass: "m-0" }, [
                                    _vm._v("Routing"),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { "label-for": "microRouting" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "microRouting" },
                                        model: {
                                          value: _vm.kab,
                                          callback: function ($$v) {
                                            _vm.kab = $$v
                                          },
                                          expression: "kab",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h4", { staticClass: "m-0" }, [
                                    _vm._v("Navigate"),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: { "label-for": "microNaviagate" },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "microNaviagate" },
                                        model: {
                                          value: _vm.kec,
                                          callback: function ($$v) {
                                            _vm.kec = $$v
                                          },
                                          expression: "kec",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h4", { staticClass: "m-0" }, [
                                    _vm._v("API Function Script"),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "microFunctionScript",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "microFunctionScript" },
                                        model: {
                                          value: _vm.prov,
                                          callback: function ($$v) {
                                            _vm.prov = $$v
                                          },
                                          expression: "prov",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex pt-3 justify-content-between",
                                },
                                [
                                  _c("h4", { staticClass: "m-0" }, [
                                    _vm._v("Data"),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { "label-for": "microId" } },
                                    [
                                      _c("b-form-textarea", {
                                        attrs: { id: "microId" },
                                        model: {
                                          value: _vm.id,
                                          callback: function ($$v) {
                                            _vm.id = $$v
                                          },
                                          expression: "id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _c("JsonEditorVue", {
                              staticStyle: { height: "350px" },
                              attrs: {
                                Mode: "text",
                                options: {
                                  Mode: "text",
                                  Modes: ["text"],
                                  onError: _vm.handleJsonError,
                                },
                              },
                              model: {
                                value: _vm.arrayVariable,
                                callback: function ($$v) {
                                  _vm.arrayVariable = $$v
                                },
                                expression: "arrayVariable",
                              },
                            }),
                      ],
                      1
                    )
                  : _c(
                      "form",
                      {
                        ref: "form",
                        staticClass: "row",
                        on: {
                          submit: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.handleProcEdit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h4", { staticClass: "m-0" }, [
                              _vm._v("Nama Proses*"),
                            ]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-for": "procName",
                                  "invalid-feedback":
                                    "Nama Proses Harus Diisi!",
                                  state: _vm.NameState,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "procName",
                                    state: _vm.NameState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.name,
                                    callback: function ($$v) {
                                      _vm.name = $$v
                                    },
                                    expression: "name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h4", { staticClass: "m-0" }, [_vm._v("Kode*")]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-for": "procCode",
                                  "invalid-feedback": "Kode Harus Diisi!",
                                  state: _vm.descState,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "procCode",
                                    state: _vm.descState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.id,
                                    callback: function ($$v) {
                                      _vm.id = $$v
                                    },
                                    expression: "id",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h4", { staticClass: "m-0" }, [
                              _vm._v("Group App*"),
                            ]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-for": "procGroup",
                                  "invalid-feedback": "Group App Harus Diisi!",
                                  state: _vm.typeState,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "procGroup",
                                    state: _vm.typeState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.type,
                                    callback: function ($$v) {
                                      _vm.type = $$v
                                    },
                                    expression: "type",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h4", { staticClass: "m-0" }, [
                              _vm._v("Deskripsi"),
                            ]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { "label-for": "procDesc" } },
                              [
                                _c("b-form-input", {
                                  attrs: { id: "procDesc" },
                                  model: {
                                    value: _vm.desc,
                                    callback: function ($$v) {
                                      _vm.desc = $$v
                                    },
                                    expression: "desc",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h4", { staticClass: "m-0" }, [
                              _vm._v("Routing*"),
                            ]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-for": "procRouting",
                                  "invalid-feedback": "Routing Harus Diisi!",
                                  state: _vm.provState,
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "procRouting",
                                    state: _vm.provState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.prov,
                                    callback: function ($$v) {
                                      _vm.prov = $$v
                                    },
                                    expression: "prov",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-4 d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("h4", { staticClass: "m-0" }, [_vm._v("Icon")]),
                            _c("h3", [_vm._v(":")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 my-1" },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { "label-for": "procIcon" } },
                              [
                                _c("b-form-input", {
                                  attrs: { id: "procIcon" },
                                  model: {
                                    value: _vm.kab,
                                    callback: function ($$v) {
                                      _vm.kab = $$v
                                    },
                                    expression: "kab",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
              ]
            ),
            _c(
              "b-modal",
              {
                ref: "modal",
                attrs: {
                  id: "modal-add",
                  size: "md",
                  title: "Tambah",
                  "no-close-on-esc": "",
                  "no-close-on-backdrop": "",
                  "ok-title": "Tambah",
                  "cancel-title": "Kembali",
                },
                on: {
                  show: _vm.resetModal,
                  hidden: _vm.resetModal,
                  ok: _vm.handleOk,
                },
              },
              [
                _c(
                  "form",
                  {
                    ref: "form",
                    staticClass: "row",
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.handleSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-12 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Jenis Kasbank",
                              "label-for": "ItemType",
                              "invalid-feedback": "Jenis Kasbank Harus Diisi!",
                              state: _vm.typeState,
                            },
                          },
                          [
                            _c("multiselect", {
                              attrs: {
                                id: "itemType",
                                options: _vm.optionsCategory,
                                placeholder: "Select one",
                                multiple: false,
                                "max-height": 200,
                                "track-by": "index",
                              },
                              model: {
                                value: _vm.type,
                                callback: function ($$v) {
                                  _vm.type = $$v
                                },
                                expression: "type",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-12 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Nama Kasbank",
                              "label-for": "ItemName",
                              "invalid-feedback": "Nama Kasbank Harus Diisi!",
                              state: _vm.descState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "ItemName",
                                state: _vm.NameState,
                                required: "",
                              },
                              model: {
                                value: _vm.name,
                                callback: function ($$v) {
                                  _vm.name = $$v
                                },
                                expression: "name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-12 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Tanggal Dibuat",
                              "label-for": "codeCOA",
                              "invalid-feedback": "Tanggal Dibuat Harus Diisi!",
                              state: _vm.addressState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                type: "date",
                                id: "codeCOA",
                                state: _vm.addressState,
                                required: "",
                              },
                              model: {
                                value: _vm.address,
                                callback: function ($$v) {
                                  _vm.address = $$v
                                },
                                expression: "address",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-12 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Saldo Awal",
                              "label-for": "ItemType",
                              "invalid-feedback": "Saldo Awal Harus Diisi!",
                              state: _vm.descState,
                            },
                          },
                          [
                            _c(
                              "b-input-group",
                              { attrs: { prepend: "Rp." } },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    type: "number",
                                    id: "codeCOA",
                                    state: _vm.descState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.desc,
                                    callback: function ($$v) {
                                      _vm.desc = $$v
                                    },
                                    expression: "desc",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                ref: "modal",
                attrs: {
                  id: "modal-addMicro",
                  size: "lg",
                  title: "Tambah Microservice",
                  "no-close-on-esc": "",
                  "no-close-on-backdrop": "",
                  "ok-title": "Tambah",
                  "cancel-title": "Kembali",
                },
                on: {
                  show: _vm.resetModal,
                  hidden: _vm.resetModal,
                  ok: _vm.handleMicroOk,
                },
              },
              [
                _c(
                  "form",
                  {
                    ref: "form",
                    staticClass: "row",
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.handleMicroSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h4 my-1",
                            attrs: {
                              label: "Nama Microservice",
                              "label-for": "microName",
                              "invalid-feedback":
                                "Nama Microservice Harus Diisi!",
                              state: _vm.NameState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "microName",
                                state: _vm.NameState,
                                required: "",
                              },
                              model: {
                                value: _vm.name,
                                callback: function ($$v) {
                                  _vm.name = $$v
                                },
                                expression: "name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h4 my-1",
                            attrs: {
                              label: "Tipe Microservice",
                              "label-for": "microType",
                              "invalid-feedback":
                                "Tipe Microservice Harus Diisi!",
                              state: _vm.typeState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "microType",
                                state: _vm.typeState,
                                required: "",
                              },
                              model: {
                                value: _vm.type,
                                callback: function ($$v) {
                                  _vm.type = $$v
                                },
                                expression: "type",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h4 my-1",
                            attrs: {
                              label: "Deskripsi Microservice",
                              "label-for": "microDesc",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "microDesc" },
                              model: {
                                value: _vm.desc,
                                callback: function ($$v) {
                                  _vm.desc = $$v
                                },
                                expression: "desc",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h4 my-1",
                            attrs: {
                              label: "icon Microservice",
                              "label-for": "microImage",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "microImage" },
                              model: {
                                value: _vm.image,
                                callback: function ($$v) {
                                  _vm.image = $$v
                                },
                                expression: "image",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h4 my-1",
                            attrs: {
                              label: "Placeholder Microservice",
                              "label-for": "microPleaceholder",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "microPleaceholder" },
                              model: {
                                value: _vm.prov,
                                callback: function ($$v) {
                                  _vm.prov = $$v
                                },
                                expression: "prov",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h4 my-1",
                            attrs: {
                              label: "Routing Microservice",
                              "label-for": "microRouting",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "microRouting" },
                              model: {
                                value: _vm.kab,
                                callback: function ($$v) {
                                  _vm.kab = $$v
                                },
                                expression: "kab",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h4 my-1",
                            attrs: {
                              label: "Navigasi Microservice",
                              "label-for": "microNavigate",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "microNavigate" },
                              model: {
                                value: _vm.kec,
                                callback: function ($$v) {
                                  _vm.kec = $$v
                                },
                                expression: "kec",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h4 my-1",
                            attrs: {
                              label: "API Function Script",
                              "label-for": "microAddress",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "microAddress" },
                              model: {
                                value: _vm.address,
                                callback: function ($$v) {
                                  _vm.address = $$v
                                },
                                expression: "address",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-3 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h4 my-1",
                            attrs: {
                              label: "Kode Microservice",
                              "label-for": "microData",
                              "invalid-feedback":
                                "Kode Microservice Harus Diisi!",
                              state: _vm.descState,
                            },
                          },
                          [
                            _c(
                              "b-input-group",
                              {
                                attrs: {
                                  prepend:
                                    this.infoModal.content.appProcCode + ".",
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "microData",
                                    type: "number",
                                    state: _vm.descState,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.microCode,
                                    callback: function ($$v) {
                                      _vm.microCode = $$v
                                    },
                                    expression: "microCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-9 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "h4 my-1",
                            attrs: {
                              label: "Data Microservice",
                              "label-for": "microData",
                            },
                          },
                          [
                            _c("b-form-textarea", {
                              attrs: { id: "microData" },
                              model: {
                                value: _vm.id,
                                callback: function ($$v) {
                                  _vm.id = $$v
                                },
                                expression: "id",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                ref: "modal",
                attrs: {
                  id: "modal-addProc",
                  size: "sm",
                  title: "Tambah Proses",
                  "hide-header-close": "",
                  "no-close-on-esc": "",
                  "no-close-on-backdrop": "",
                  "ok-title": "Tambah",
                  "cancel-title": "Kembali",
                },
                on: {
                  show: _vm.resetModal,
                  hidden: _vm.resetProcDetail,
                  ok: _vm.handleOkProc,
                },
              },
              [
                _c(
                  "form",
                  {
                    ref: "form",
                    staticClass: "row",
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.handleSubmitProc.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-12 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Nama Proses",
                              "label-for": "ItemName",
                              "invalid-feedback": "Nama Proc Harus Diisi!",
                              state: _vm.NameState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "ItemName",
                                state: _vm.NameState,
                                required: "",
                              },
                              model: {
                                value: _vm.name,
                                callback: function ($$v) {
                                  _vm.name = $$v
                                },
                                expression: "name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-12 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Kode",
                              "label-for": "ItemKode",
                              "invalid-feedback": "Kode Harus Diisi!",
                              state: _vm.descState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "ItemKode",
                                state: _vm.descState,
                                required: "",
                              },
                              model: {
                                value: _vm.id,
                                callback: function ($$v) {
                                  _vm.id = $$v
                                },
                                expression: "id",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-12 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Group App",
                              "label-for": "ItemType",
                              "invalid-feedback": "Group App Harus Diisi!",
                              state: _vm.typeState,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "ItemType",
                                state: _vm.typeState,
                                required: "",
                              },
                              model: {
                                value: _vm.type,
                                callback: function ($$v) {
                                  _vm.type = $$v
                                },
                                expression: "type",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-12 mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Deskripsi",
                              "label-for": "ItemDesc",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "ItemDesc" },
                              model: {
                                value: _vm.desc,
                                callback: function ($$v) {
                                  _vm.desc = $$v
                                },
                                expression: "desc",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "b-row",
              { staticClass: "my-3" },
              [
                _c(
                  "b-col",
                  { staticClass: "my-1", attrs: { sm: "5", md: "6" } },
                  [
                    _c("span", { staticClass: "px-2" }, [_vm._v("Show")]),
                    _c("b-form-select", {
                      attrs: {
                        id: "per-page-select",
                        options: _vm.pageOptions,
                        size: "sm",
                      },
                      model: {
                        value: _vm.perPage,
                        callback: function ($$v) {
                          _vm.perPage = $$v
                        },
                        expression: "perPage",
                      },
                    }),
                    _vm._v(" Data of " + _vm._s(_vm.totalRows) + " Data "),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { staticClass: "my-1", attrs: { sm: "7", md: "6" } },
                  [
                    _c("b-pagination", {
                      staticClass: "my-0",
                      attrs: {
                        "total-rows": _vm.totalRows,
                        "per-page": _vm.perPage,
                        align: "fill",
                        size: "sm",
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }