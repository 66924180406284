var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c(
        "div",
        {
          ref: "contentDiv",
          staticClass: "content container-fluid p-3 ps-0 pt-0",
        },
        [
          _c("ul", { staticClass: "breadcrumb m-1 mt-0" }, [
            _vm._m(0),
            _c("li", [_vm._v("Transaksi")]),
            _c("li", [_vm._v("Detail PO")]),
            _c("li", [_vm._v(_vm._s(_vm.poNumb))]),
          ]),
          _vm.isDataLoaded
            ? [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-header bg-light p-2" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "row col-9 d-flex align-items-center justify-content-end pe-0",
                        },
                        [
                          _c("b-form-group", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              "label-for": "coaKasbank",
                              "invalid-feedback": "Condition Harus Diisi!",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-3 d-flex justify-content-end py-2",
                          staticStyle: { "z-index": "2" },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "btn btn-outline-secondary btn-xs py-1 me-2",
                              attrs: { to: "/transaksi/po" },
                            },
                            [
                              _c("i", { staticClass: "bi-chevron-left" }),
                              _vm._v("Kembali "),
                            ]
                          ),
                          _c(
                            "b-button-group",
                            [
                              _c(
                                "button",
                                { staticClass: "btn btn-primary btn-xs" },
                                [
                                  _c("i", {
                                    staticClass: "bi-printer pe-2",
                                    on: { click: _vm.printReport },
                                  }),
                                  _vm._v("Print "),
                                ]
                              ),
                              _c(
                                "b-dropdown",
                                {
                                  attrs: {
                                    id: "dropdown2",
                                    variant: "primary",
                                    size: "xs",
                                    right: "",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c("p", { staticClass: "p-2 pb-0 m-0" }, [
                                      _vm._v("Export"),
                                    ]),
                                  ]),
                                  _c("b-dropdown-divider"),
                                  _c(
                                    "b-dropdown-item",
                                    { on: { click: _vm.generatePDF } },
                                    [_vm._v("PDF")]
                                  ),
                                  _c("b-dropdown-item", [_vm._v("xls")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "card-body p-2 printData" }, [
                    _c("div", { staticClass: "container" }, [
                      _vm._m(1),
                      _c("div", { staticClass: "po-number" }, [
                        _c("p", [
                          _vm._v(
                            "Purchase Order Number: " +
                              _vm._s(_vm.dataPOId[0].PoNo)
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "po-date" }, [
                        _c("p", [
                          _vm._v("Date: " + _vm._s(_vm.dataPOId[0].poDate)),
                        ]),
                      ]),
                      _c("div", { staticClass: "address-info" }, [
                        _c("div", { staticClass: "address" }, [
                          _c("h2", [_vm._v("From:")]),
                          _c("p", [
                            _vm._v(_vm._s(_vm.dataPOId[0].PoDomain.domainName)),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.dataPOId[0].PoDomain.domainAddress)
                            ),
                          ]),
                          _vm.dataPOId[0].PoDomain.domainContactPerson.length >
                          0
                            ? _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.dataPOId[0].PoDomain
                                        .domainContactPerson[0].userEmail
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _vm.dataPOId[0].PoDomain.domainContactPerson.length >
                          0
                            ? _c("p", [
                                _vm._v(
                                  " Phone: " +
                                    _vm._s(
                                      _vm.dataPOId[0].PoDomain
                                        .domainContactPerson[0].userPhone
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "address" }, [
                          _c("h2", [_vm._v("To:")]),
                          _c("p", [
                            _vm._v(_vm._s(_vm.dataPOId[0].PoSupplier.userName)),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.dataPOId[0].PoSupplier.userAddress[0]
                                  .location
                              )
                            ),
                          ]),
                          _vm.dataPOId[0].PoDomain.domainContactPerson.length >
                          0
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(_vm.dataPOId[0].PoSupplier.userEmail)
                                ),
                              ])
                            : _vm._e(),
                          _vm.dataPOId[0].PoDomain.domainContactPerson.length >
                          0
                            ? _c("p", [
                                _vm._v(
                                  "Phone: " +
                                    _vm._s(_vm.dataPOId[0].PoSupplier.userPhone)
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "address" }, [
                          _c("h2", [_vm._v("Ship To:")]),
                          _c("p", [_vm._v(_vm._s(_vm.dataPOId[0].PoShipto))]),
                        ]),
                      ]),
                      _c("div", { staticClass: "order-details" }, [
                        _c("h2", [_vm._v("Order Details:")]),
                        _c(
                          "table",
                          {
                            staticStyle: {
                              "border-collapse": "collapse",
                              border: "none",
                            },
                          },
                          [
                            _vm._m(2),
                            _vm._l(
                              _vm.dataPOId[0].PoItemDetails,
                              function (dt) {
                                return _c("tr", [
                                  _c("td", [_vm._v(_vm._s(dt.itemName))]),
                                  _c("td", [
                                    _vm._v(_vm._s(dt.itemDescription)),
                                  ]),
                                  _c("td", [_vm._v(_vm._s(dt.itemQty))]),
                                  _c("td", [_vm._v(_vm._s(dt.itemUm))]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.formatNumber(dt.itemPrice))
                                    ),
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatNumber(
                                            parseFloat(dt.itemPrice) *
                                              parseFloat(dt.itemQty)
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ])
                              }
                            ),
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  staticStyle: { border: "none" },
                                  attrs: { colspan: "4", rowspan: "3" },
                                },
                                [
                                  _c("div", { staticClass: "divNotePrint" }, [
                                    _vm._m(3),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          margin: "10px 15px",
                                          "font-size": "15px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.dataPOId[0].PoNotes) +
                                            " "
                                        ),
                                        _c("br"),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("th", [_vm._v("SubTotal")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.formatNumber(_vm.subTotal))),
                              ]),
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Tax")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.formatNumber(_vm.subTotal * 0.11))
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Grand Total")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatNumber(
                                      _vm.subTotal + _vm.subTotal * 0.11
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ]),
                      _c("div", { staticClass: "footerPrint" }, [
                        _c("div", { staticClass: "ttd" }, [
                          _c("p", [_vm._v("Dibuat Oleh")]),
                          _c("span", { staticClass: "ttdName" }, [
                            _vm._v("( " + _vm._s(_vm.pembuat) + " )"),
                          ]),
                        ]),
                        _c("div", { staticClass: "ttd" }, [
                          _c("p", [_vm._v("Di Approve Oleh")]),
                          _c("span", { staticClass: "ttdName" }, [
                            _vm._v(
                              "( " + _vm._s(_vm.dataPOId[0].PoRouting) + " )"
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            : _c("div", [_vm._v("Loading...")]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "#" } }, [
        _c("i", { staticClass: "bi-house-door" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("h1", [_vm._v("PURCHASE ORDER")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("Item")]),
      _c("th", [_vm._v("Description")]),
      _c("th", [_vm._v("Quantity")]),
      _c("th", [_vm._v("UoM")]),
      _c("th", [_vm._v("Unit Price")]),
      _c("th", [_vm._v("Total")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticStyle: { margin: "10px" } }, [
      _c("b", [_vm._v("Catatan :")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }