var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form", [
    _c("div", { staticClass: "row my-1" }, [
      _c("div", { staticClass: "col-4 d-flex align-items-end" }, [
        _c("ul", { staticClass: "breadcrumb m-0" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/dashboard" } }, [
                _c("a", { attrs: { href: "#" } }, [
                  _c("i", { staticClass: "bi-house-door" }),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { name: _vm.posts.transRouting.replace("-add", "") },
                  },
                },
                [_c("a", { attrs: { href: "#" } }, [_vm._v("Defecta")])]
              ),
            ],
            1
          ),
          _c("li", [_vm._v("Detail")]),
        ]),
      ]),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "col-4 d-flex justify-content-end align-items-center" },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: { name: _vm.posts.transRouting.replace("-detail", "") },
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary btn-xs py-1 me-2",
                  attrs: { type: "button" },
                },
                [
                  _c("i", { staticClass: "bi-chevron-left" }),
                  _vm._v("Kembali "),
                ]
              ),
            ]
          ),
          _vm.confirmData
            ? _c(
                "button",
                {
                  staticClass: "btn btn-danger btn-xs py-1 me-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.sendData("rejectPR")
                    },
                  },
                },
                [_vm._v(" Reject ")]
              )
            : _vm._e(),
          _vm.confirmData
            ? _c(
                "button",
                {
                  staticClass: "btn btn-success btn-xs py-1 me-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.sendData("confirmPR")
                    },
                  },
                },
                [_vm._v(" Confirm ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("hr", { staticClass: "mb-3 mt-1" }),
    ]),
    _c(
      "div",
      { staticClass: "row scale-9", staticStyle: { width: "114%" } },
      _vm._l(_vm.dummyItems, function (field, index) {
        return field.componentName === "fieldHeader"
          ? _c("div", { key: index, class: [field.componentClass] }, [
              _c("div", { staticClass: "card h-100" }, [
                _c(
                  "div",
                  { staticClass: "row px-3" },
                  _vm._l(field.componentMicro, function (micro) {
                    return _c(
                      "div",
                      { class: ["", micro.microClass] },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: micro.microName + " :",
                              "label-cols-sm": "4",
                              "label-cols-lg": "3",
                              "content-cols-sm": "8",
                              "content-cols-lg": "9",
                            },
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control form-control-sm py-0",
                              attrs: {
                                type: micro.microType,
                                placeholder: micro.microText,
                                disabled: "",
                              },
                              model: {
                                value: micro.value,
                                callback: function ($$v) {
                                  _vm.$set(micro, "value", $$v)
                                },
                                expression: "micro.value",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ])
          : _vm._e()
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "row mb-3" },
      _vm._l(_vm.component, function (field) {
        return field.componentName === "fieldTable"
          ? _c("div", { class: [field.componentClass] }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "hot-container col-md-12",
                    staticStyle: { "overflow-x": "auto" },
                  },
                  [
                    _c("hot-table", {
                      staticStyle: { position: "relative", "z-index": "1" },
                      attrs: {
                        settings: _vm.hotSettings,
                        data: _vm.dataDetail.table,
                        rowHeaders: true,
                        colHeaders: true,
                        colWidths: _vm.widthTable,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e()
      }),
      0
    ),
    _c("div", { staticClass: "row mb-3" }, [
      _c(
        "div",
        { staticClass: "col-md-6 scale-9" },
        _vm._l(_vm.component, function (field) {
          return field.componentName === "fieldNote"
            ? _c("div", { class: ["h-100", field.componentClass] }, [
                _c(
                  "div",
                  { staticClass: "card h-100" },
                  [
                    _c("div", { staticClass: "card-header bg-light py-1" }, [
                      _vm._v(_vm._s(field.componentTitle)),
                    ]),
                    _vm._l(field.componentMicro, function (micro) {
                      return [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: micro.value,
                              expression: "micro.value",
                            },
                          ],
                          staticClass:
                            "form-control form-control-sm py-1 h-100",
                          staticStyle: { resize: "none" },
                          attrs: { placeholder: micro.microText, disabled: "" },
                          domProps: { value: micro.value },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(micro, "value", $event.target.value)
                            },
                          },
                        }),
                      ]
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e()
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass: "col-md-6 row p-0 scale-9",
          staticStyle: { "transform-origin": "right top" },
        },
        _vm._l(_vm.component, function (field) {
          return field.componentName === "fieldTotal"
            ? _c(
                "div",
                { class: [field.componentClass] },
                _vm._l(field.componentMicro, function (micro) {
                  return _c(
                    "div",
                    { staticClass: "row d-flex justify-content-end" },
                    [
                      _c(
                        "div",
                        {
                          class: [
                            "border border-light bg-light d-flex align-items-center",
                            micro.microClass,
                          ],
                        },
                        [_vm._v(" " + _vm._s(micro.microName) + " : ")]
                      ),
                      _c(
                        "div",
                        {
                          class: ["p-0 border border-light", micro.microClass],
                        },
                        [
                          micro.microType === "checkbox"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: micro.value,
                                    expression: "micro.value",
                                  },
                                ],
                                staticClass:
                                  "form-control form-control-sm py-1",
                                staticStyle: { "text-align": "right" },
                                attrs: { disabled: "", type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(micro.value)
                                    ? _vm._i(micro.value, null) > -1
                                    : micro.value,
                                },
                                on: {
                                  input: _vm.refreshMethods,
                                  change: function ($event) {
                                    var $$a = micro.value,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            micro,
                                            "value",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            micro,
                                            "value",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(micro, "value", $$c)
                                    }
                                  },
                                },
                              })
                            : micro.microType === "radio"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: micro.value,
                                    expression: "micro.value",
                                  },
                                ],
                                staticClass:
                                  "form-control form-control-sm py-1",
                                staticStyle: { "text-align": "right" },
                                attrs: { disabled: "", type: "radio" },
                                domProps: {
                                  checked: _vm._q(micro.value, null),
                                },
                                on: {
                                  input: _vm.refreshMethods,
                                  change: function ($event) {
                                    return _vm.$set(micro, "value", null)
                                  },
                                },
                              })
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: micro.value,
                                    expression: "micro.value",
                                  },
                                ],
                                staticClass:
                                  "form-control form-control-sm py-1",
                                staticStyle: { "text-align": "right" },
                                attrs: { disabled: "", type: micro.microType },
                                domProps: { value: micro.value },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        micro,
                                        "value",
                                        $event.target.value
                                      )
                                    },
                                    _vm.refreshMethods,
                                  ],
                                },
                              }),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4 text-center" }, [
      _c("span", { staticClass: "h2" }, [_vm._v("Detail Defecta")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }