// store.js
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { API } from '@/API';
import { errorNetwork } from './components/function/sweetalert'

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
    topbarTitle: '',
    routing: '',
    dataUsaha: [],
    dataProses: [],
    btnList: [],
    
    loket1: '',
    loket2: '001',
    loket3: '001',
    loket4: '',
    mainLoket: '',
    mainValue: '',
  },
  mutations: {
    setLoket4(state, data) {
      state.loket4 = data
      localStorage.setItem('loket4', data)
    },
    setAuthentication(state, status) {
      state.isAuthenticated = status;
      localStorage.setItem('isAuthenticated', status)
    },
    setTopbarTitle(state, data) {
      state.topbarTitle = data;
    },
    setRouting(state, data) {
      state.routing = data;
    },
    setDataUsaha(state, data) {
      state.dataUsaha = data;
    },
    setDataProses(state, data) {
      state.dataProses = data;
    },
    setBtnList(state, data) {
      state.btnList = data;
    }
  }, 
  actions: {
    updateDataUsaha({commit}, data) {
      commit('setDataUsaha', data);
      localStorage.setItem('dataUsaha', JSON.stringify(data));
    },
    fetchMyArray({commit}) {
      const storedData = localStorage.getItem('dataUsaha')
      const dataUsaha = JSON.parse(storedData) || [];
      commit('setDataUsaha', dataUsaha)
    },
    async login({ commit }, credentials) {
      const axiosInstance = axios.create({
        timeout: 5000, // Set the timeout to 5000 milliseconds (5 seconds)
      });
      try {
        const response = await axiosInstance.post(API + "/user/login", credentials);
        commit('setAuthentication', true);
        return response.data;
      } catch (error) {
        if (axios.isCancel(error)) {
          // The request was canceled due to a timeout
          alert('Request canceled due to timeout');
        } else {
          // Handle other errors here
          errorNetwork()
        }
        throw error;
      }
    },
    logout({ commit }) {
      commit('setAuthentication', false)
    }
  }
});

export default store;