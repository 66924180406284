var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c(
        "div",
        {
          ref: "contentDiv",
          staticClass: "content container-fluid p-3 ps-0 py-0",
        },
        [
          _c(
            "b-modal",
            {
              attrs: {
                title: "List Purchase Order Receipt",
                "ok-disabled":
                  _vm.modalInput.length < 1 || _vm.dataItem.length < 1,
              },
              on: { ok: _vm.saveModalData, hide: _vm.closeModal },
              model: {
                value: _vm.modalOpen,
                callback: function ($$v) {
                  _vm.modalOpen = $$v
                },
                expression: "modalOpen",
              },
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Pilih SP :",
                    "label-cols-sm": "4",
                    "label-cols-lg": "3",
                    "content-cols-sm": "8",
                    "content-cols-lg": "9",
                    "invalid-feedback": "Condition Harus Diisi!",
                  },
                },
                [
                  _c("multiselect", {
                    staticClass: "my-1",
                    attrs: {
                      options: _vm.optionsPO,
                      placeholder: "Pilih SP",
                      multiple: false,
                      "show-labels": false,
                      "custom-label": _vm.customLabel0,
                    },
                    model: {
                      value: _vm.modalInput,
                      callback: function ($$v) {
                        _vm.modalInput = $$v
                      },
                      expression: "modalInput",
                    },
                  }),
                ],
                1
              ),
              _vm.modalInput.PoNo
                ? [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Pilih Item :",
                          "label-cols-sm": "4",
                          "label-cols-lg": "3",
                          "content-cols-sm": "8",
                          "content-cols-lg": "9",
                          "invalid-feedback": "Condition Harus Diisi!",
                        },
                      },
                      [
                        _c("multiselect", {
                          staticClass: "my-1",
                          attrs: {
                            options: _vm.optionFilter(
                              _vm.modalInput.PoItemDetails
                            ),
                            placeholder: "Pilih PR",
                            multiple: false,
                            "show-labels": false,
                            "custom-label": _vm.customLabel1,
                          },
                          model: {
                            value: _vm.dataItem,
                            callback: function ($$v) {
                              _vm.dataItem = $$v
                            },
                            expression: "dataItem",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.dataItem.itemName
                ? [
                    _c("b", [_vm._v("Data Item")]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-4 col-lg-3" }, [
                        _vm._v("Kode"),
                      ]),
                      _c("div", { staticClass: "col-sm-8 col-lg-9" }, [
                        _vm._v(": " + _vm._s(_vm.dataItem.itemId)),
                      ]),
                      _c("div", { staticClass: "col-sm-4 col-lg-3" }, [
                        _vm._v("Nama"),
                      ]),
                      _c("div", { staticClass: "col-sm-8 col-lg-9" }, [
                        _vm._v(": " + _vm._s(_vm.dataItem.itemName)),
                      ]),
                      _c("div", { staticClass: "col-sm-4 col-lg-3" }, [
                        _vm._v("Item UoM"),
                      ]),
                      _c("div", { staticClass: "col-sm-8 col-lg-9" }, [
                        _vm._v(": " + _vm._s(_vm.dataItem.itemUm)),
                      ]),
                      _c("div", { staticClass: "col-sm-4 col-lg-3" }, [
                        _vm._v("Qty Request"),
                      ]),
                      _c("div", { staticClass: "col-sm-8 col-lg-9" }, [
                        _vm._v(": " + _vm._s(_vm.dataItem.itemQty)),
                      ]),
                      _c("div", { staticClass: "col-sm-4 col-lg-3" }, [
                        _vm._v("Qty on Hand"),
                      ]),
                      _c("div", { staticClass: "col-sm-8 col-lg-9" }, [
                        _vm._v(": " + _vm._s(_vm.dataItem.itemQtyReceipt)),
                      ]),
                      _c("div", { staticClass: "col-sm-4 col-lg-3" }, [
                        _vm._v("Qty Shortage"),
                      ]),
                      _c("div", { staticClass: "col-sm-8 col-lg-9" }, [
                        _vm._v(
                          " : " +
                            _vm._s(
                              _vm.dataItem.itemQty - _vm.dataItem.itemQtyReceipt
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c("transaksiAddKlinikPOR", {
            attrs: {
              posts: _vm.posts,
              component: _vm.refreshComponent,
              hotSettings: _vm.hotSettings,
              widthTable: _vm.contentDivWidth,
              excelData: _vm.excelData,
              optionsLocation: _vm.locationList,
            },
            on: { "data-updated": _vm.updateData },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }