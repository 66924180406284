<template>
  <main id="content" role="main" class="main">
    <form
      class="content container-fluid p-3 ps-0 py-0"
      ref="contentDiv"
      @submit="changePass"
    >
      <!-- Header -->
      <div class="row my-1">
        <div class="col-4 d-flex align-items-center">
          <ul class="breadcrumb m-0">
            <li>
              <router-link to="/dashboard">
                <a href="#"><i class="bi-house-door"></i></a>
              </router-link>
            </li>
            <li>
              <router-link :to="$route">
                <a href="#">Purchase Payment</a>
              </router-link>
            </li>
            <li>{{ transInvNo }}</li>
          </ul>
        </div>
        <div class="col-4 text-center">
          <span class="h2">Purchase Payment</span>
        </div>
        <div class="col-1 d-flex justify-content-end align-items-center">
          <div class="form-check form-switch d-flex align-items-center">
            <input
              type="checkbox"
              class="form-check-input"
              id="formSwitch1"
              v-model="checkedPajak"
              style="height: 17.5px; margin-top: 1px"
              disabled
            />
            <label class="form-check-label" for="formSwitch1">Pajak</label>
          </div>
        </div>
        <div class="col-3 d-flex justify-content-end align-items-center">
          <router-link to="/transaksi/po-invoice">
            <button class="btn btn-outline-secondary btn-xs py-1 me-2" type="button">
              <i class="bi-chevron-left"></i>Kembali
            </button>
          </router-link>
          <button type="submit" class="btn btn-success btn-xs py-1">Bayar</button>
        </div>
      </div>

      <!-- End of Header -->

      <!-- Main Data -->
      <div class="main-data mt-4">
        <div class="row scale-9 position-relative" style="width: 114%; z-index: 1">
          <!-- First Header -->
          <div class="col-6">
            <div class="card h-100">
              <div class="row px-3 py-1">
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between"
                    >*Supplier<span>:</span></label
                  >
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input
                      v-model="transSupplier"
                      size="sm"
                      class="form-control-sm py-0 form-control"
                      required
                      placeholder="Credit Terms"
                      disabled
                    ></b-form-input>
                  </div>
                </div>
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between"
                    >Credit Terms <span>:</span></label
                  >
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input
                      v-model="transCreditTerms"
                      size="sm"
                      class="form-control-sm py-0 form-control"
                      required
                      placeholder="Credit Terms"
                      disabled
                    ></b-form-input>
                  </div>
                </div>
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between"
                    >*No. Faktur <span>:</span></label
                  >
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input
                      v-model="transNoFaktur"
                      size="sm"
                      type="text"
                      class="form-control-sm py-0 form-control"
                      required
                      placeholder="Masukan Nomor Referensi"
                      disabled
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End of First Header -->

          <!-- Secondx Header -->
          <div class="col-6">
            <div class="card h-100">
              <div class="row px-3 py-1">
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between"
                    >*Kode Invoice<span>:</span></label
                  >
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input
                      v-model="transInvNo"
                      disabled
                      size="sm"
                      class="form-control-sm py-0 form-control"
                      placeholder="Kode Purchase Invoice"
                    ></b-form-input>
                  </div>
                </div>
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between"
                    >*Invoice Date <span>:</span></label
                  >
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input
                      v-model="transInvDate"
                      size="sm"
                      type="date"
                      class="form-control-sm py-0 form-control"
                      required
                      placeholder="Masukan Tanggal Transaksi"
                      disabled
                    ></b-form-input>
                  </div>
                </div>
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between"
                    >*Due Date <span>:</span></label
                  >
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input
                      v-model="transDueDate"
                      size="sm"
                      type="date"
                      class="form-control-sm py-0 form-control"
                      required
                      placeholder="Masukan Tanggal Transaksi"
                      disabled
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End of Secondx Header -->
        </div>

        <div class="row my-3">
          <!-- Hot Table -->
          <div class="col-12">
            <div
              class="hot-container col-md-12 position-relative"
              style="overflow-x: auto; z-index: 0"
            >
              <hot-table
                ref="hot"
                :settings="hotSettings"
                :data="fixExcelData"
                :rowHeaders="true"
              ></hot-table>
            </div>
          </div>
          <!-- End of Hot Table -->
        </div>

        <!-- Footer -->
        <div class="row mb-3 d-flex justify-content-between">
          <div class="col-md-6 scale-9">
            <div class="h-100">
              <div class="card h-100">
                <div class="card-header bg-light py-1">Catatan</div>
                <textarea
                  class="form-control form-control-sm py-1 h-100"
                  style="resize: none"
                  placeholder="Masukan Catatan"
                  v-model="transNotes"
                  disabled
                ></textarea>
              </div>
            </div>
          </div>
          <div class="col-md-4 row p-0 scale-9" style="transform-origin: right top">
            <div class="row d-flex justify-content-end">
              <template v-if="checkedPajak">
                <div
                  class="border border-light bg-light d-flex align-items-center justify-content-between col-5"
                >
                  Sub Total
                  <span>:</span>
                </div>
                <div
                  class="p-0 border border-light bg-light pt-1 d-flex align-items-center justify-content-between col-7 pe-2"
                >
                  <input
                    type="text"
                    :value="formatNumberInt(totalPrice)"
                    class="form-control form-control-sm py-1"
                    style="text-align: right"
                    disabled
                  />
                </div>
                <div
                  class="border border-light bg-light d-flex align-items-center justify-content-between col-5"
                >
                  PPN (Rp.)
                  <span>:</span>
                </div>
                <div
                  class="p-0 border border-light bg-light pt-1 d-flex align-items-center justify-content-between col-7 pe-2"
                >
                  <input
                    type="text"
                    :value="formatNumberInt(totalPPN)"
                    class="form-control form-control-sm py-1"
                    style="text-align: right"
                    disabled
                  />
                </div>
              </template>
              <div
                class="border border-light bg-light d-flex align-items-center justify-content-between col-5"
              >
                Grand Total
                <span>:</span>
              </div>
              <div
                class="p-0 border border-light bg-light pt-1 col-7 d-flex align-items-center justify-content-between col-5 pe-2"
              >
                <input
                  @input="refreshMethods()"
                  type="text"
                  :value="formatNumberInt(totalAmount)"
                  class="form-control form-control-sm py-1"
                  style="text-align: right"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <!-- End of Footer -->
      </div>

      <b-modal
        v-model="modalOpen"
        title="List Good Receipt (PO Receipt)"
        @ok="saveModalData"
        @hide="closeModal"
        :ok-disabled="modalInput.length < 1"
      >
        <!-- Modal content here, e.g., input fields to collect data -->
        <b-form-group
          label="Pilih GR :"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm="8"
          content-cols-lg="9"
          invalid-feedback="Condition Harus Diisi!"
        >
          <multiselect
            class="my-1"
            v-model="modalInput"
            :options="filterGR(optionsInvoice)"
            placeholder="Bayar Dengan"
            :multiple="false"
            :show-labels="false"
            :custom-label="customLabelKode"
          >
          </multiselect>
        </b-form-group>
        <div class="col-12" v-if="modalInput.transHysNo">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Nama</th>
                <th scope="col">Qty</th>
                <th scope="col">Harga</th>
                <th scope="col">Due Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in modalInput.transCoaItems">
                <td>{{ data.itemHysName }}</td>
                <td>{{ data.itemHysQtyRcp }}</td>
                <td>Rp. {{ formatNumberInt(data.itemHysPrice) }}</td>
                <td>{{ data.itemHysEffDate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-modal>

      <!-- Modal -->
      <div
        id="exampleModalTopCover"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalTopCoverTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <!-- Header -->
            <div class="modal-top-cover bg-dark text-center">
              <figure class="position-absolute end-0 bottom-0 start-0">
                <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 1920 100.1"
                >
                  <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                </svg>
              </figure>

              <div class="modal-close" @keydown.esc="onEscape">
                <button
                  type="button"
                  class="btn-close btn-close-light"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="resetPass"
                ></button>
              </div>
            </div>
            <!-- End Header -->

            <div class="modal-top-cover-icon">
              <span class="icon icon-lg icon-light icon-circle icon-centered shadow-sm">
                <i class="bi-file-lock fs-2"></i>
              </span>
            </div>

            <div class="modal-body text-center">
              <h3 class="m-0">Pembayaran Invoice</h3>
              <template v-if="passData !== null"> ({{ passData }}) </template>
              <template v-else> ({{ transInvNo }}) </template>
              <div class="row mt-2 d-flex justify-content-center">
                <div class="col-10 my-2">
                  <multiselect
                    class="my-1"
                    v-model="payMethods"
                    :options="optionsCoa"
                    placeholder="Metode Pembayaran"
                    :multiple="false"
                    :show-labels="false"
                    :custom-label="customLabelCoa"
                  >
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-white"
                data-bs-dismiss="modal"
                @click="resetPass"
              >
                Batal
              </button>
              <button
                type="button"
                class="btn btn-success"
                data-bs-dismiss="modal"
                @click="sendData"
              >
                Bayar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End Modal -->
      <!-- End of Main Data -->
    </form>
  </main>
</template>

<script>
import {
  getCoa,
  getJEVoucher,
  postPaymentCredit,
  getPOInvoiceByPI,
  getP,
  getK,
} from "../../components/services/components";
import { getFullDate } from "../../components/function/date";
import {
  formatNumberInt,
  removeThousandFormatter,
} from "../../components/function/number";
import {
  alertLoading,
  successAddData,
  errorAddData,
} from "../../components/function/sweetalert";
export default {
  data() {
    return {
      listdatane:[],
      totalInvoice: 0,
      tableData: [],
      optionsCoa: [],
      payMethods: null,
      passData: null,
      suppId: "",
      transNoFaktur: "",
      checkedPajak: false,
      transPayment: "",
      optionsInvoice: [],
      currentDate: "",
      dataItem: [],
      poList: [],
      locationList: [],
      modalOpen: false,
      modalInput: [],
      modalRow: null,
      modalCol: null,
      // Main Data
      transInvNo: "",
      transCreditTerms: "",
      transNo: "",
      transInvDate: "",
      transDueDate: "",
      itemHysQtyRcp: "",
      transNotes: "",
      transSupplier: "",
      transPaymentType: "Cash",

      subTotal: "",
      prList: [],
      porList: [],
      listSupplier: [],
      // HOT Data
      contentDivWidth: "",
      colHeader: ["*Kode", "*Nama Item", "*UoM", "*Qty.", "*Harga", "Diskon", "Total"],
      colBody: [
        {
          data: "transReff",
          type: "text",
          renderer: this.prRenderer,
          width: 130,
        },
        {
          data: "itemHysName",
          type: "text",
          renderer: this.textRenderer,
        },
        {
          data: "itemHysUm",
          type: "text",
          renderer: this.textRenderer,
          width: 75,
        },
        {
          data: "itemHysQtyRcp",
          type: "text",
          renderer: this.textRenderer,
          width: 50,
        },
        {
          data: "itemHysPrice",
          type: "numeric",
          validator: this.validateNumericValue,
          numericFormat: {
            pattern: "0,0",
          },
          width: 125,
        },
        {
          data: "transDiskon",
          type: "numeric",
          validator: this.validateNumericValue,
          numericFormat: {
            pattern: "0,0",
          },
          width: 125,
        },
        {
          data: "itemHysAmount",
          type: "numeric",
          validator: this.validateNumericValue,
          numericFormat: {
            pattern: "0,0",
          },
          width: 125,
        },
      ],
      excelData: [],
      hotSettings: {
        readOnly: true,
        fixedRowsBottom: 0,
        afterChange: this.onChange,
        beforeKeyDown: this.onKeyDown1,
        licenseKey: "non-commercial-and-evaluation",

        height: 250,
        colHeaders: [],

        rowHeaderWidth: 35,
        startRows: 10,
        columns: [],
      },
    };
  },
  async mounted() {
    const tes = this.listData;
    // this.listSupplier = await getP()
    const currentDate = await getFullDate();
    // const tes = await getK()
    // this.prList = tes.filter(x => x.PrItemDetails.length > 0)
    this.porList = [];
    const coa = await getCoa();
    this.optionsCoa = coa.coaCashBank;
    // this.transNo = await getJEVoucher()

    // cokdeh
    // this.transInvDate = currentDate;
    // this.transDueDate = currentDate;
    this.hotSettings.colHeaders = this.colHeader;
    this.hotSettings.columns = this.colBody;
    const data = this.tableData.map((x) => ({ ...x, ...x.transCoaItems[0] }));
    console.log("coba", data);
    data.forEach((element) => {
      this.excelData.push(element);
    });
    const dataObject = {};
    this.colBody.forEach((item) => {
      dataObject[item.data] = "";
    });
    this.getContentWidth();
  },
  computed: {
    async listData() {
      const tes = this.$route.query;
      if (tes.data) {
        const data = await getPOInvoiceByPI(tes.data);
        //   const data = JSON.parse(tes.data);
        console.log("DATANYA ",JSON.stringify(data))
        this.transInvDate =  data[0].transEffDate;
        this.transDueDate =  data[0].transDueDate;
        this.listdatane = data;
        this.transSupplier = data[0].transUserTo.userName;
        this.transNoFaktur = data[0].transReffExt;
        this.transInvNo = data[0].transNo;
        this.transNotes = "";
        this.checkedPajak = data[0].transPPN === "yes" ? true : false;
        this.tableData = data
          .flatMap((item) => this.duplicateObjectWithItems(item))
          .filter((x) => x.transCoaItems.length > 0);
        console.log("APA NIH BOSS ", JSON.stringify(this.tableData))
        // this.totalInvoice = this.tableData[0].totalInvoice;
        // this.totalInvoice = 0;

        return data;

        //   //   this.transNo = JSON.parse(tes.data)[0].coaTrans[0].transNo
        //   //   this.transEffDate = JSON.parse(tes.data)[0].coaTrans[0].transEffDate
        //   //   this.transNotes = JSON.parse(tes.data)[0].coaTrans[0].transNotes
        //   // return JSON.parse(tes.data).map(x => ({
        //   //   transCoaCode: x.coaCode,
        //   //   transCoaName: x.coaName,
        //   //   transCostCenter: x.coaTrans[0].transCostCenter,
        //   //   transReff: x.coaTrans[0].transReff,
        //   //   transCostCenter: x.coaTrans[0].transCostCenter,
        //   //   transDescription: x.coaTrans[0].transDescription,
        //   //   Kredit: x.coaTrans[0].transType === 'kredit' ? x.coaTrans[0].transAmount : 0,
        //   //   Debet: x.coaTrans[0].transType === 'debet' ? x.coaTrans[0].transAmount : 0
        //   // }))
      } else {
        return [];
      }
    },
    fixExcelData() {
      return this.excelData;
    },
    totalKredit() {
      const tes = this.excelData.filter((x) => x.Kredit !== "").map((x) => x.Kredit);
      if (tes.length > 0) {
        const sum = tes.reduce((accumulator, currentValue) => {
          const numericValue = parseFloat(currentValue);
          if (!isNaN(numericValue)) {
            return accumulator + numericValue;
          } else {
            return accumulator;
          }
        }, 0);
        return sum;
      } else {
        return 0;
      }
    },
    totalDebet() {
      const tes = this.excelData.filter((x) => x.Debet !== "").map((x) => x.Debet);
      if (tes.length > 0) {
        const sum = tes.reduce((accumulator, currentValue) => {
          // Convert the current value to a number using parseFloat
          const numericValue = parseFloat(currentValue);

          // Check if the conversion is successful (not NaN) before adding to the accumulator
          if (!isNaN(numericValue)) {
            return accumulator + numericValue;
          } else {
            return accumulator; // Ignore non-numeric values
          }
        }, 0); // Start with an initial accumulator value of 0
        return sum;
      } else {
        return 0;
      }
    },
    totalPrice() {
      console.log("BangKrik ",JSON.stringify(this.excelData))
      const tes = this.excelData
        .filter((x) => x.itemHysAmount !== "")
        .map((x) => x.itemHysAmount);

      console.log("APA INI V ", JSON.stringify(tes))

      if (tes.length > 0) {
        const sum = tes.reduce((accumulator, currentValue) => {
          const numericValue = parseFloat(currentValue);


          console.log("BAJJ ",numericValue , accumulator , numericValue , accumulator + numericValue)

          if (!isNaN(numericValue)) {
            return accumulator + numericValue;
          } else {
            return accumulator;
          }

        }, 0);
        return sum;
      } else {
        return 0;
      }
    },
    totalPPN() {
      return (this.totalPrice * 11) / 100;
    },
    totalAmount() {
      if (this.checkedPajak) {
        return this.totalPrice + this.totalPPN;
      } else {
        return this.totalPrice;
      }
    },
  },
  methods: {
    duplicateObjectWithItems(item) {
      console.log("Jancok ", item.transCoaItems.length)
      const duplicatedObjects = [];
      if (item.transCoaItems.length > 1) {
        item.transCoaItems.forEach((subItem) => {
          const duplicatedItem = { ...item, transCoaItems: [subItem] };
          duplicatedObjects.push(duplicatedItem);
        });
      } else {
        duplicatedObjects.push(item);
      }
      return duplicatedObjects;
    },
    changePass(event) {
      event.preventDefault();
      $("#exampleModalTopCover").modal("show");
      this.resetPass();
    },
    resetPass() {
      this.payMethods = null;
      this.passData = null;
    },
    filterGR(data) {
      const thisRow = this.excelData[this.modalRow];
      let cok = this.excelData
        .filter((x) => x.transNo !== "")
        .map((x) => x.transNo.transNo);

      let finalData = data.filter((x) => !cok.includes(x.transNo));

      if (thisRow !== "" && thisRow !== undefined) {
        if (thisRow.transNo !== "") {
        }
      }
      return finalData;
    },
    optionFilter(data) {
      if (data) {
        const tes = this.excelData
          .filter((x) => x.itemHysId !== "")
          .map((x) => x.itemHysId);
        // data.PoItemDetails =  x.PoItemDetails.filter((obj) => !tes.includes(obj._id))
        return data.filter((x) => {
          const matchingCode = tes.find((codeObj) => codeObj._id === x._id);
          return !matchingCode;
        });
      }
    },
    openModal(data, row) {
      if (data.transNo !== "") {
        this.modalInput = data.transNo;
        this.dataItem = data.itemCode;
      }
      this.modalOpen = true;
      this.modalRow = row;
    },
    saveModalData() {
      const finalData = this.modalInput.transCoaItems.map((x) => ({
        transHysNo: this.modalInput,
        itemHysId: x.itemHysId,
        itemHysName: x.itemHysName,
        itemHysUm: x.itemHysUm,
        itemHysQtyRcp: x.itemHysQtyRcp,
        itemHysPrice: x.itemHysPrice,
        transDiskon: "",
        totalInvoice: parseFloat(x.itemHysPrice) * parseFloat(x.itemHysQtyRcp),
      }));
      finalData.forEach((obj, index) => {
        const array1Index = this.modalRow + index;
        if (array1Index < this.excelData.length) {
          this.excelData[array1Index] = obj;
        }
      });
      this.excelData = [...this.excelData];
    },
    closeModal() {
      this.modalInput = [];
      this.modalRow = null;
      this.dataItem = [];
      this.modalOpen = false;
    },
    prRenderer(instance, td, row, col, prop, value, cellProperties) {
      const rowData = this.excelData[row];
      let dataDiv = "";
      let classDiv = "d-flex align-items-center justify-content-between";
      if (rowData[prop] === "" || rowData[prop] === null) {
        dataDiv = null;
      } else {
        dataDiv = rowData[prop];
      }

      // Clear the cell content
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }

      // Create the buttons container element
      const buttonsContainer = document.createElement("div");
      buttonsContainer.className = classDiv;
      buttonsContainer.innerHTML = dataDiv;

      // Append the buttons container to the cell
      td.appendChild(buttonsContainer);
    },
    textRenderer(instance, td, row, col, prop, value, cellProperties) {
      const rowData = this.excelData[row];
      let dataDiv = "";
      let classDiv = "d-flex align-items-center justify-content-between";
      if (rowData.transHysNo === "" || rowData.transHysNo === null) {
        dataDiv = null;
      } else {
        dataDiv = rowData[prop];
      }

      // Clear the cell content
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }

      // Create the buttons container element
      const buttonsContainer = document.createElement("div");
      buttonsContainer.className = classDiv;
      buttonsContainer.innerHTML = dataDiv;
      // Create the first button

      // Append the buttons container to the cell
      td.appendChild(buttonsContainer);

      // Add necessary CSS classes
    },
    numberRenderer(instance, td, row, col, prop, value, cellProperties) {
      const rowData = this.excelData[row];
      let dataDiv = "";
      let classDiv = "d-flex align-items-center justify-content-between";
      if (rowData.transHysNo === "" || rowData.transHysNo === null) {
        dataDiv = null;
      } else {
        dataDiv = formatNumberInt(rowData[prop]);
      }

      // Clear the cell content
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }

      // Create the buttons container element
      const buttonsContainer = document.createElement("div");
      buttonsContainer.className = classDiv;
      buttonsContainer.innerHTML = dataDiv;
      // Create the first button

      // Append the buttons container to the cell
      td.appendChild(buttonsContainer);

      // Add necessary CSS classes
    },
    changeKode(data) {
      if (data && data.dataGR.length > 0) {
        this.suppId = data._id;
        this.optionsInvoice = data.dataGR;
        this.checkedPajak = data.dataGR[0].transHysPPN === "yes" ? true : false;
      } else {
        this.suppId = "";
        this.optionsInvoice = [];
        this.checkedPajak = false;
      }
      this.excelData = this.excelData.map((x) => ({
        transHysNo: "",
        itemHysId: "",
        itemHysUm: "",
        itemHysQtyRcp: "",
        itemHysPrice: "",
        transDiskon: "",
        itemHysAmount: "",
      }));
    },
    // filterKode() {
    //   if (transPaymentType === 'Cash') {
    //     this.hotSettings.columns[0].source = this.prList.map(x => x.PrNo)
    //   }
    //   else if (transPaymentType === 'Credit') {
    //     this.hotSettings.columns[0].source = this.porList.map(x => x.PrNo)
    //   }
    // },
    customLabel(option) {
      return `${option.userName}`;
    },
    customLabel1(option) {
      return `${option.itemHysName} (${option.itemHysQtyRcp})`;
    },
    customLabelKode(option) {
      return `${option.transHysNo}`;
    },
    customLabelCoa(option) {
      return `${option.coaName} (${option.coaCode})`;
    },
    formatNumberInt(data) {
      return formatNumberInt(data);
    },
    validateEmptyValue(value, callback) {
      if (isNaN(value)) {
        callback(false);
      } else {
        callback(true);
      }
    },
    validateNumericValue(value, callback) {
      if (isNaN(value)) {
        callback(false);
      } else if (value < 0) {
        callback(false);
      } else {
        callback(true);
      }
    },
    onKeyDown1(event) {
      const hotInstance = this.$refs.hot.hotInstance;
      const selected = hotInstance.getSelected();
      const [startRow, startCol, endRow, endCol] = selected;

      if (
        event.key === "Tab" &&
        startRow[1] >= 4 &&
        this.excelData[startRow[0]].transDescription === ""
      ) {
        // alert('Uraian tidak Boleh Kosong!')
        hotInstance.selectCell(startRow[0], startRow[1] - 1);
      }
    },
    onChange(changes, source) {
      if (source === "edit") {
        this.formatTable(changes);
      } else if (source === "CopyPaste.paste") {
        this.formatTable(changes);
      }
    },
    formatTable(changes) {
      for (const change of changes) {
        const [row, prop, oldValue, newValue] = change;
        const baris = this.excelData[row];

        if (prop === "transCoaCode" && baris) {
          const findName = this.coa.coaNonBank.find(
            (x) => x.coaCode === baris.transCoaCode
          );
          if (baris.transCoaCode !== null && findName !== undefined) {
            baris.transCoaName = findName.coaName;
            if (baris.transCoaName === undefined) {
              baris.transCoaName = null;
              baris.transCoaCode = null;
            }
          } else {
            baris.transCoaName = null;
            baris.transCoaCode = null;
          }
          this.excelData = [...this.excelData];
        } else if (prop === "transCoaName" && baris) {
          const findCode = this.coa.coaNonBank.find(
            (x) => x.coaName === baris.transCoaName
          );
          if (baris.transCoaName !== null && findCode !== undefined) {
            baris.transCoaCode = findCode.coaCode;
          } else {
            baris.transCoaName = null;
            baris.transCoaCode = null;
          }
          this.excelData = [...this.excelData];
        } else if (prop === "Kredit" || (prop === "Debet" && baris)) {
          const oppositeProp = prop === "Kredit" ? "Debet" : "Kredit";
          const oppositeValue = this.excelData[row][oppositeProp];
          if (newValue !== "" && newValue !== null) {
            // If one input has a non-empty value, set the opposite input to 0
            this.excelData[row][oppositeProp] = 0;
            this.hotSettings.columns.find(
              (column) => column.data === oppositeProp
            ).readOnly = true;
          } else {
            this.excelData[row][oppositeProp] = "";
            this.hotSettings.columns.find(
              (column) => column.data === oppositeProp
            ).readOnly = false;
          }
          this.excelData = [...this.excelData];
          this.hotSettings.columns = [...this.hotSettings.columns];
        } else {
          console.log("gagal");
        }
      }
    },
    sendData(event) {
      event.preventDefault();
      if (this.excelData) {
        this.$swal({
          title: "Anda Yakin?",
          text: "Tekan Bayar jika anda sudah yakin!",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#00AB8E",
          confirmButtonText: "Bayar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            alertLoading();
            try {
              console.log("Haduh ",JSON.stringify(this.listData))
              const semuaTransCoaItems = this.listdatane.reduce((result, transaksi) => {
                // Menggabungkan transCoaItems dari setiap transaksi
                const transCoaItems = transaksi.transCoaItems;
                result = result.concat(transCoaItems);
                return result;
              }, []);
              const data = [
                {
                  coaCode: this.payMethods.coaCode,
                  coaName: this.payMethods.coaName,
                  transNo: this.transNoFaktur,
                  transReff: this.transInvNo,
                  transUser: localStorage.getItem("_id"),
                  transUserTo: this.listdatane[0].transUserTo._id,
                  transCoaCode: "",
                  transCoaName: "",
                  transType: "",
                  transName: "",
                  transAmount: this.totalAmount,
                  transDescription: `Pembayaran atas ${this.transInvNo}`,
                  transNotes: this.transNotes,
                  transEffDate: this.transInvDate,
                  transDueDate: this.transDueDate,
                  totalInvoice: this.totalInvoice,
                  transCoaItems:semuaTransCoaItems,
                  PPN: (this.totalInvoice * 11) / 100,
                },
              ];
              console.log("Payment Credit POST", JSON.stringify(data))
              await postPaymentCredit(data);
              successAddData();
              this.$router.push({ name: "po-invoice" });
            } catch (error) {
              console.log("ASTAGA " , error)
              console.log("ASTAGA " ,JSON.stringify(error))
              errorAddData();
            }
          } else {
            $("#exampleModalTopCover").modal("show");
          }
        });
      } else if (this.totalKredit !== this.totalDebet) {
        alert("Data Tidak Balance!");
      } else {
        alert("ada data wajib yang masih kosong!");
      }
    },
    getContentWidth() {
      const contentDiv = this.$refs.contentDiv;
      let totalWidth = 0;
      const tes = this.hotSettings.columns.filter((x) => !x.width).length;
      const widthTes = this.hotSettings.columns.filter((x) => x.width);
      for (const column of widthTes) {
        if (column.width) {
          totalWidth += column.width;
        }
      }
      if (contentDiv) {
        this.hotSettings.colWidths = (contentDiv.offsetWidth - 70 - totalWidth) / tes;
      }
    },
  },
};
</script>
