var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c(
        "div",
        {
          ref: "contentDiv",
          staticClass: "content container-fluid p-3 ps-0 pt-0",
        },
        [
          _vm._m(0),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.isDataLoaded === ""
              ? _c("div", { key: "empty" }, [_c("loading")], 1)
              : _vm.isDataLoaded
              ? _c("div", { key: "truthy" }, [
                  _c("div", [
                    _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        { staticClass: "card-header bg-light py-0 px-2" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row col-10 d-flex align-items-center justify-content-end pe-0",
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      "label-for": "coaKasbank",
                                      "invalid-feedback":
                                        "Condition Harus Diisi!",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      staticClass: "form-control-sm py-1",
                                      attrs: { type: "month" },
                                      on: { change: _vm.getData },
                                      model: {
                                        value: _vm.getDate,
                                        callback: function ($$v) {
                                          _vm.getDate = $$v
                                        },
                                        expression: "getDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-2 d-flex justify-content-end py-2",
                                staticStyle: { "z-index": "2" },
                              },
                              [
                                _c(
                                  "b-button-group",
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary btn-xs",
                                        on: { click: _vm.printReport },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "bi-printer pe-2",
                                        }),
                                        _vm._v("Print "),
                                      ]
                                    ),
                                    _c(
                                      "b-dropdown",
                                      {
                                        attrs: {
                                          id: "dropdown2",
                                          variant: "primary",
                                          size: "xs",
                                          right: "",
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "p",
                                            { staticClass: "p-2 pb-0 m-0" },
                                            [_vm._v("Export")]
                                          ),
                                        ]),
                                        _c("b-dropdown-divider"),
                                        _c(
                                          "b-dropdown-item",
                                          { on: { click: _vm.generatePDF } },
                                          [_vm._v("PDF")]
                                        ),
                                        _c("b-dropdown-item", [_vm._v("xls")]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "card-body p-2 printData" },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "fade", mode: "out-in" } },
                            [
                              _vm.isRefreshLoaded === ""
                                ? _c(
                                    "div",
                                    { key: "empty" },
                                    [_c("loading")],
                                    1
                                  )
                                : _vm.isDataLoaded
                                ? _c(
                                    "div",
                                    { key: "truthy" },
                                    [
                                      _c("h2", { staticClass: "text-center" }, [
                                        _vm._v("Laporan Klinik Bulanan"),
                                      ]),
                                      _c("dokterBulanan"),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { key: "other" },
                                    [_c("connection-timeout")],
                                    1
                                  ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ])
              : _c("div", { key: "other" }, [_c("connection-timeout")], 1),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "breadcrumb m-1 mt-0" }, [
      _c("li", [
        _c("a", { attrs: { href: "#" } }, [
          _c("i", { staticClass: "bi-house-door" }),
        ]),
      ]),
      _c("li", [_vm._v("Laporan Klinik Bulanan")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }