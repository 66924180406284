var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form", [
    _c("div", { staticClass: "row my-1" }, [
      _c("div", { staticClass: "col-4 d-flex align-items-center" }, [
        _c("ul", { staticClass: "breadcrumb m-0" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/dashboard" } }, [
                _c("a", { attrs: { href: "#" } }, [
                  _c("i", { staticClass: "bi-house-door" }),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { name: _vm.posts.transRouting.replace("-add", "") },
                  },
                },
                [
                  _c("a", { attrs: { href: "#" } }, [
                    _vm._v(_vm._s(_vm.posts.transTitle)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("li", [_vm._v("Tambah")]),
        ]),
      ]),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "col-4 d-flex justify-content-end align-items-center" },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: { name: _vm.posts.transRouting.replace("-add", "") },
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary btn-xs py-1 me-2",
                  attrs: { type: "button" },
                },
                [
                  _c("i", { staticClass: "bi-chevron-left" }),
                  _vm._v("Kembali "),
                ]
              ),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-xs py-1",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.sendData.apply(null, arguments)
                },
              },
            },
            [_vm._v(" Tambah ")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "row scale-9 position-relative",
        staticStyle: { width: "114%", "z-index": "1" },
      },
      _vm._l(_vm.dummyItems, function (field, index) {
        return field.componentName === "fieldHeader"
          ? _c("div", { key: index, class: [field.componentClass] }, [
              _c("div", { staticClass: "card h-100" }, [
                _c(
                  "div",
                  { staticClass: "row px-3" },
                  _vm._l(field.componentMicro, function (micro) {
                    return _c(
                      "div",
                      { class: ["", micro.microClass] },
                      [
                        micro.microType === "select"
                          ? _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: micro.microName + " :",
                                      "label-cols-sm": "4",
                                      "label-cols-lg": "3",
                                      "content-cols-sm": "8",
                                      "content-cols-lg": "9",
                                    },
                                  },
                                  [
                                    _c("b-form-select", {
                                      staticClass:
                                        "mt-1 form-control-sm py-0 form-control",
                                      attrs: {
                                        options: micro.microOptions,
                                        size: "sm",
                                        disabled: micro.isDisabled,
                                      },
                                      model: {
                                        value: micro.value,
                                        callback: function ($$v) {
                                          _vm.$set(micro, "value", $$v)
                                        },
                                        expression: "micro.value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : micro.microType === "multiselect"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-for": micro.microName,
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                    "invalid-feedback":
                                      "Condition Harus Diisi!",
                                  },
                                },
                                [
                                  _c("multiselect", {
                                    staticClass: "my-1",
                                    attrs: {
                                      id: micro.microName,
                                      options: micro.microOptions,
                                      placeholder: "Select one",
                                      multiple: false,
                                      "show-labels": false,
                                      "custom-label": _vm.customLabel,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.changeCondition(micro.value)
                                      },
                                    },
                                    model: {
                                      value: micro.value,
                                      callback: function ($$v) {
                                        _vm.$set(micro, "value", $$v)
                                      },
                                      expression: "micro.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : micro.key === "productQty"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass:
                                      "form-control form-control-sm py-0",
                                    attrs: {
                                      type: micro.microType,
                                      placeholder: micro.microText,
                                      required: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.getQty(micro.value)
                                      },
                                    },
                                    model: {
                                      value: micro.value,
                                      callback: function ($$v) {
                                        _vm.$set(micro, "value", $$v)
                                      },
                                      expression: "micro.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass:
                                      "form-control form-control-sm py-0",
                                    attrs: {
                                      type: micro.microType,
                                      placeholder: micro.microText,
                                      required: "",
                                      disabled: micro.isDisabled,
                                    },
                                    model: {
                                      value: micro.value,
                                      callback: function ($$v) {
                                        _vm.$set(micro, "value", $$v)
                                      },
                                      expression: "micro.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ])
          : _vm._e()
      }),
      0
    ),
    _c("div", { staticClass: "row mb-3" }, [
      _c(
        "div",
        { staticClass: "col-md-12 scale-12" },
        _vm._l(_vm.component, function (field) {
          return field.componentName === "fieldNote"
            ? _c("div", { class: ["h-100", field.componentClass] }, [
                _c(
                  "div",
                  { staticClass: "card h-100" },
                  [
                    _c("div", { staticClass: "card-header bg-light py-1" }, [
                      _vm._v(" " + _vm._s(field.componentTitle) + " "),
                    ]),
                    _vm._l(field.componentMicro, function (micro) {
                      return [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: micro.microValue,
                              expression: "micro.microValue",
                            },
                          ],
                          staticClass:
                            "form-control form-control-sm py-1 h-100",
                          staticStyle: { resize: "none" },
                          attrs: { placeholder: micro.microText },
                          domProps: { value: micro.microValue },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(micro, "microValue", $event.target.value)
                            },
                          },
                        }),
                      ]
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e()
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "row mb-3" }, [
      _vm.dataTable.length > 0
        ? _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.dataTable, function (data, i) {
              return _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "container p-0 border-0" }, [
                  _c("ul", { staticClass: "list-group" }, [
                    _c("b", [
                      _c(
                        "li",
                        {
                          staticClass:
                            "list-group-item active position-relative py-1",
                          staticStyle: {
                            padding: "10px",
                            "border-radius": "5px 5px 0 0",
                            "z-index": "0",
                          },
                        },
                        [
                          _c("i", {
                            class:
                              ["bi bi-"] +
                              data.pCode +
                              ["-square-fill "] +
                              ["list-group-icon"],
                          }),
                          _vm._v(" " + _vm._s(data.name) + " "),
                        ]
                      ),
                    ]),
                    _c("table", { staticClass: "table table-bordered" }, [
                      _vm._m(1, true),
                      _c(
                        "tbody",
                        _vm._l(data.items, function (tData, index) {
                          return _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "fs-11 align-top",
                                staticStyle: {
                                  "padding-top": "0 !important",
                                  "padding-bottom": "0 !important",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(tData.recipeItemResult.itemName) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "fs-11 align-top",
                                staticStyle: {
                                  "padding-top": "0 !important",
                                  "padding-bottom": "0 !important",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(tData.recipeItemResult.stockQty) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "fs-11 align-top",
                                staticStyle: {
                                  "padding-top": "0 !important",
                                  "padding-bottom": "0 !important",
                                },
                                attrs: { width: "50px" },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: tData.recipeQty,
                                      expression: "tData.recipeQty",
                                    },
                                  ],
                                  staticStyle: {
                                    width: "50px",
                                    padding: "0",
                                    height: "15px",
                                  },
                                  attrs: { type: "number" },
                                  domProps: { value: tData.recipeQty },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeRecipeQty(
                                        i,
                                        index,
                                        tData.recipeQty
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        tData,
                                        "recipeQty",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "fs-11 align-top",
                                staticStyle: {
                                  "padding-top": "0 !important",
                                  "padding-bottom": "0 !important",
                                },
                              },
                              [_vm._v(" " + _vm._s(tData.recipeUM) + " ")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "w-50 overflow-hidden fs-11 align-top",
                                staticStyle: {
                                  "padding-top": "0 !important",
                                  "padding-bottom": "0 !important",
                                },
                              },
                              _vm._l(tData.recipeMaterial, function (rData) {
                                return _c(
                                  "div",
                                  {
                                    class:
                                      tData.recipeMaterial.length > 1
                                        ? "row border"
                                        : "row",
                                  },
                                  [
                                    _c("td", { staticClass: "col-5" }, [
                                      _vm._v(_vm._s(rData.itemName)),
                                    ]),
                                    _c("td", { staticClass: "col-2" }, [
                                      _vm._v(_vm._s(rData.stockQty)),
                                    ]),
                                    _c("td", { staticClass: "col-2" }, [
                                      _vm._v(" " + _vm._s(rData.qty) + " "),
                                    ]),
                                    _c("td", { staticClass: "col-2" }, [
                                      _vm._v(_vm._s(rData.UM)),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]),
              ])
            }),
            0
          )
        : _c("div", { staticClass: "col-12" }, [
            _c("h4", [_vm._v("Item ini belum di setup formula")]),
          ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4 align-items-center" }, [
      _c(
        "h4",
        {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "justify-content": "center",
            "flex-direction": "column",
          },
        },
        [_vm._v(" Work Order Create ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "td",
          { staticClass: "text-center fs-11", attrs: { colspan: "4" } },
          [_c("b", [_vm._v("HASIL PRODUK")])]
        ),
        _c(
          "td",
          { staticClass: "text-center fs-11", attrs: { rowspan: "1" } },
          [_c("b", [_vm._v("MATERIAL")])]
        ),
      ]),
      _c("tr", [
        _c("td", { staticClass: "text-center fs-11" }, [
          _c("b", [_vm._v("NAMA")]),
        ]),
        _c("td", { staticClass: "text-center fs-11" }, [
          _c("b", [_vm._v("STOK")]),
        ]),
        _c("td", { staticClass: "text-center fs-11" }, [
          _c("b", [_vm._v("QTY")]),
        ]),
        _c("td", { staticClass: "text-center fs-11" }, [
          _c("b", [_vm._v("UM")]),
        ]),
        _c("td", [
          _c("div", { staticClass: "row" }, [
            _c("td", { staticClass: "col-5 fs-11" }, [
              _c("b", [_vm._v("NAMA")]),
            ]),
            _c("td", { staticClass: "col-2 fs-11" }, [
              _c("b", [_vm._v("STOK")]),
            ]),
            _c("td", { staticClass: "col-2 fs-11" }, [
              _c("b", [_vm._v("QTY")]),
            ]),
            _c("td", { staticClass: "col-2 fs-11" }, [_c("b", [_vm._v("UM")])]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }