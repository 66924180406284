var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c(
        "form",
        {
          ref: "contentDiv",
          staticClass: "content container-fluid p-3 ps-0 py-0",
          on: { submit: _vm.sendData },
        },
        [
          _c("div", { staticClass: "row my-1" }, [
            _c("div", { staticClass: "col-4 d-flex align-items-center" }, [
              _c("ul", { staticClass: "breadcrumb m-0" }, [
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: "/dashboard" } }, [
                      _c("a", { attrs: { href: "#" } }, [
                        _c("i", { staticClass: "bi-house-door" }),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: _vm.$route.path.replace("/add", "") } },
                      [_c("a", { attrs: { href: "#" } }, [_vm._v("Kasbon")])]
                    ),
                  ],
                  1
                ),
                _c("li", [_vm._v("Tambah")]),
              ]),
            ]),
            _vm._m(0),
            _c(
              "div",
              {
                staticClass:
                  "col-4 d-flex justify-content-end align-items-center",
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: _vm.$route.path.replace("/add", "") } },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-outline-secondary btn-xs py-1 me-2",
                        attrs: { type: "button" },
                      },
                      [
                        _c("i", { staticClass: "bi-chevron-left" }),
                        _vm._v("Kembali "),
                      ]
                    ),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-xs py-1",
                    attrs: { type: "submit" },
                  },
                  [_vm._v("Simpan")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "main-data mt-4" }, [
            _c(
              "div",
              {
                staticClass: "row scale-9 position-relative",
                staticStyle: { width: "114%", "z-index": "1" },
              },
              [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "card h-100" }, [
                    _c("div", { staticClass: "row px-3 py-1" }, [
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(1),
                        _c(
                          "div",
                          { staticClass: "col-sm-8 col-md-9 pe-0" },
                          [
                            _c("b-form-select", {
                              staticClass: "form-control",
                              attrs: { options: _vm.filteredType, size: "sm" },
                              model: {
                                value: _vm.transType,
                                callback: function ($$v) {
                                  _vm.transType = $$v
                                },
                                expression: "transType",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row px-3 py-1" }, [
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(2),
                        _c(
                          "div",
                          { staticClass: "col-sm-8 col-md-9 pe-0" },
                          [
                            _c("multiselect", {
                              attrs: {
                                options: _vm.optionsKasbank,
                                placeholder: "Select one",
                                multiple: false,
                                "show-labels": false,
                                "custom-label": _vm.customLabel,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.changeCondition(_vm.transKasbank)
                                },
                              },
                              model: {
                                value: _vm.transKasbank,
                                callback: function ($$v) {
                                  _vm.transKasbank = $$v
                                },
                                expression: "transKasbank",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row px-3 py-1" }, [
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(3),
                        _c(
                          "div",
                          { staticClass: "col-sm-8 col-md-9 pe-0" },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control-sm py-0 form-control",
                              attrs: {
                                size: "sm",
                                type: "date",
                                required: "",
                                placeholder: "Masukan Tanggal Transaksi",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.inOut(_vm.micro.value)
                                },
                              },
                              model: {
                                value: _vm.transEffDate,
                                callback: function ($$v) {
                                  _vm.transEffDate = $$v
                                },
                                expression: "transEffDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "card h-100" }, [
                    _c("div", { staticClass: "row px-3 py-1" }, [
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(4),
                        _c(
                          "div",
                          { staticClass: "col-sm-8 col-md-9 pe-0" },
                          [
                            _c("multiselect", {
                              attrs: {
                                options: _vm.optionsUser,
                                placeholder: "Diberikan oleh/kepada",
                                multiple: false,
                                "show-labels": false,
                                "custom-label": _vm.customLabel1,
                              },
                              model: {
                                value: _vm.transPIC,
                                callback: function ($$v) {
                                  _vm.transPIC = $$v
                                },
                                expression: "transPIC",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row px-3 py-1" }, [
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(5),
                        _c(
                          "div",
                          { staticClass: "col-sm-8 col-md-9 pe-0" },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control-sm py-0 form-control",
                              attrs: {
                                size: "sm",
                                disabled: "",
                                required: "",
                                placeholder: "Kode Voucher Kasbon",
                              },
                              model: {
                                value: _vm.voucherCode,
                                callback: function ($$v) {
                                  _vm.voucherCode = $$v
                                },
                                expression: "voucherCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row px-3 py-1" }, [
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(6),
                        _c(
                          "div",
                          { staticClass: "col-sm-8 col-md-9 pe-0" },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control-sm py-0 form-control",
                              attrs: {
                                size: "sm",
                                value: _vm.formatNumberInt(_vm.currentSaldo),
                                disabled: "",
                                placeholder: "Sisa Saldo Saat ini",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "row my-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "hot-container col-md-12 position-relative",
                    staticStyle: { "overflow-x": "auto", "z-index": "0" },
                  },
                  [
                    _c("hot-table", {
                      ref: "hot",
                      attrs: {
                        settings: _vm.hotSettings,
                        data: _vm.excelData,
                        rowHeaders: true,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "row mb-3 d-flex justify-content-between" },
              [
                _c("div", { staticClass: "col-md-6 scale-9" }, [
                  _c("div", { staticClass: "h-100" }, [
                    _c("div", { staticClass: "card h-100" }, [
                      _c("div", { staticClass: "card-header bg-light py-1" }, [
                        _vm._v("Catatan"),
                      ]),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.transNotes,
                            expression: "transNotes",
                          },
                        ],
                        staticClass: "form-control form-control-sm py-1 h-100",
                        staticStyle: { resize: "none" },
                        attrs: { placeholder: "Masukan Catatan" },
                        domProps: { value: _vm.transNotes },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.transNotes = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "col-md-4 row p-0 scale-9",
                    staticStyle: { "transform-origin": "right top" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row d-flex justify-content-end" },
                      [
                        _vm._m(7),
                        _c(
                          "div",
                          {
                            staticClass:
                              "p-0 border border-light bg-light pt-1 col-7 pe-2",
                          },
                          [
                            _c("input", {
                              staticClass: "form-control form-control-sm py-1",
                              staticStyle: { "text-align": "right" },
                              attrs: { type: "text", disabled: "" },
                              domProps: {
                                value: _vm.formatNumberInt(_vm.totalAmount),
                              },
                            }),
                          ]
                        ),
                        _vm._m(8),
                        _c(
                          "div",
                          {
                            staticClass:
                              "p-0 border border-light bg-light pt-1 col-7 pe-2",
                          },
                          [
                            _c("input", {
                              staticClass: "form-control form-control-sm py-1",
                              staticStyle: { "text-align": "right" },
                              attrs: { type: "text", disabled: "" },
                              domProps: {
                                value: _vm.formatNumberInt(_vm.totalAkhir),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.refreshMethods()
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4 text-center" }, [
      _c("span", { staticClass: "h2" }, [_vm._v("Tambah Kasbon")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 col-md-3 d-flex justify-content-between" },
      [_vm._v("*Tipe "), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 col-md-3 d-flex justify-content-between" },
      [_vm._v("*Kode Kasbank "), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 col-md-3 d-flex justify-content-between" },
      [_vm._v("*Trans. Date "), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 col-md-3 d-flex justify-content-between" },
      [_vm._v("*PIC "), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 col-md-3 d-flex justify-content-between" },
      [_vm._v("*Kode Voucher "), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 col-md-3 d-flex justify-content-between" },
      [_vm._v("*Saldo Saat Ini "), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "border border-light bg-light d-flex align-items-center justify-content-between col-5",
      },
      [_vm._v(" Total Amount "), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "border border-light bg-light d-flex align-items-center justify-content-between col-5",
      },
      [_vm._v(" Saldo Akhir "), _c("span", [_vm._v(":")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }