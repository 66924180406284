var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.isDataLoaded === ""
          ? _c("div", { key: "empty" }, [_c("loading")], 1)
          : _vm.isDataLoaded
          ? _c("div", { key: "truthy" }, [
              _c("div", { staticClass: "content container-fluid" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-4 d-flex align-items-end" }, [
                    _c("ul", { staticClass: "breadcrumb m-1 mt-0" }, [
                      _c(
                        "li",
                        [
                          _c("router-link", { attrs: { to: "/dashboard" } }, [
                            _c("a", { attrs: { href: "#" } }, [
                              _c("i", { staticClass: "bi-house-door" }),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("li", [_vm._v("Formula")]),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-4 text-center" }, [
                    _c("span", { staticClass: "h2" }, [
                      _vm._v("List Master Formula"),
                    ]),
                  ]),
                  _c("hr", { staticClass: "mb-3 mt-1" }),
                ]),
                _c(
                  "div",
                  { staticClass: "card mb-3 mb-lg-5" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-header py-0 bg-custom-1" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c(
                                  "b-input-group",
                                  {
                                    staticClass: "mx-2",
                                    attrs: { size: "sm" },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "filter-input",
                                        type: "search",
                                        placeholder: "Type to Search",
                                      },
                                      model: {
                                        value: _vm.filter,
                                        callback: function ($$v) {
                                          _vm.filter = $$v
                                        },
                                        expression: "filter",
                                      },
                                    }),
                                    _c(
                                      "b-input-group-append",
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              size: "sm",
                                              disabled: !_vm.filter,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.filter = ""
                                              },
                                            },
                                          },
                                          [_vm._v("Clear")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass:
                                  "d-flex justify-content-end align-items-center",
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.modal-add",
                                        modifiers: { "modal-add": true },
                                      },
                                    ],
                                    attrs: { variant: "primary", size: "sm" },
                                  },
                                  [_c("i", { staticClass: "bi-plus-circle" })]
                                ),
                                _c(
                                  "b-dropdown",
                                  {
                                    staticClass: "m-2",
                                    attrs: {
                                      id: "dropdown-right",
                                      right: "",
                                      text: "Filter Column",
                                      variant: "dark",
                                      size: "sm",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "button-content",
                                        fn: function () {
                                          return [
                                            _c("i", {
                                              staticClass: "bi-sliders",
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  },
                                  [
                                    _c("b-form-checkbox-group", {
                                      attrs: {
                                        id: "checkbox-group-1",
                                        options: _vm.fields,
                                        name: "flavour-1",
                                        buttons: "",
                                      },
                                      model: {
                                        value: _vm.selected,
                                        callback: function ($$v) {
                                          _vm.selected = $$v
                                        },
                                        expression: "selected",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.selected.length > 0
                      ? _c("b-table", {
                          ref: "tableUsaha",
                          attrs: {
                            "label-sort-asc": "",
                            "label-sort-desc": "",
                            "label-sort-clear": "",
                            bordered: true,
                            stacked: "md",
                            items: _vm.usaha,
                            fields: _vm.activeFields,
                            "current-page": _vm.currentPage,
                            "per-page": _vm.perPage,
                            filter: _vm.filter,
                            "tbody-tr-class": "row-class",
                          },
                          on: { filtered: _vm.onFiltered },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "cell(itemRecipe)",
                                fn: function (data) {
                                  return [
                                    _c("div", { staticClass: "inner-table" }, [
                                      _c(
                                        "table",
                                        { staticClass: "table table-bordered" },
                                        [
                                          _c(
                                            "thead",
                                            { staticClass: "bg-custom-1" },
                                            [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "text-center align-top text-white",
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                  },
                                                  [_vm._v(" Kode Proses ")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "text-center align-top text-white",
                                                    staticStyle: {
                                                      width: "auto",
                                                    },
                                                  },
                                                  [_vm._v(" Nama ")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "text-center align-top text-white",
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                  },
                                                  [_vm._v(" Qty ")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "text-center align-top text-white",
                                                    staticStyle: {
                                                      width: "75px",
                                                    },
                                                  },
                                                  [_vm._v(" UM ")]
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "tbody",
                                            _vm._l(
                                              data.item.itemRecipe,
                                              function (item, index) {
                                                return _c(
                                                  "tr",
                                                  { key: index },
                                                  [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.processCode
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.processName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.recipeQty)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.recipeUM)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                },
                              },
                              {
                                key: "cell(opsi)",
                                fn: function (row) {
                                  return [
                                    _c(
                                      "div",
                                      { attrs: { width: "0px" } },
                                      [
                                        _c(
                                          "b-dropdown",
                                          {
                                            staticClass: "button-y0",
                                            attrs: {
                                              id: "dropdown-right",
                                              right: "",
                                              text: "Right align",
                                              variant: "primary",
                                              size: "xs",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "button-content",
                                                  fn: function () {
                                                    return [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi-gear-fill",
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.info(
                                                      row.item,
                                                      row.index,
                                                      $event.target
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Detail")]
                                            ),
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.edit(
                                                      row.item,
                                                      row.index,
                                                      $event.target
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Edit")]
                                            ),
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                attrs: { href: "#" },
                                                on: { click: _vm.alertDelete },
                                              },
                                              [_vm._v("Hapus")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(icon)",
                                fn: function (row) {
                                  return undefined
                                },
                              },
                            ],
                            null,
                            false,
                            737397873
                          ),
                        })
                      : _vm._e(),
                    _c(
                      "b-modal",
                      {
                        attrs: {
                          id: _vm.infoModal.id,
                          size: "lg",
                          title: _vm.infoModal.title,
                          "ok-only": "",
                          "no-close-on-esc": "",
                          "no-close-on-backdrop": "",
                          "hide-header-close": "",
                        },
                        on: { hide: _vm.resetInfoModal },
                        scopedSlots: _vm._u([
                          {
                            key: "modal-footer",
                            fn: function () {
                              return [
                                _vm.dataId !== "" &&
                                _vm.procId !== "" &&
                                _vm.microId !== ""
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-100 d-flex justify-content-end",
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "secondary",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.microId = ""
                                              },
                                            },
                                          },
                                          [_vm._v(" Kembali ")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-100 d-flex justify-content-end",
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "me-2",
                                            attrs: {
                                              variant: "secondary",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$bvModal.hide(
                                                  "info-modal"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Kembali ")]
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-6 mb-2" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Item",
                                    "label-for": "itemConvert",
                                    "invalid-feedback":
                                      "Anda Harus Memilih Unit Item!",
                                    state: _vm.imageState,
                                  },
                                },
                                [
                                  _c("multiselect", {
                                    attrs: {
                                      id: "itemConvert",
                                      options: _vm.fixItem,
                                      placeholder: "Select",
                                      multiple: false,
                                      "custom-label": _vm.customLabel1,
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.image,
                                      callback: function ($$v) {
                                        _vm.image = $$v
                                      },
                                      expression: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6 mb-2" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Minimal Qty. Produksi",
                                    "label-for": "itemJenis",
                                    "invalid-feedback": "Harus Diisi!",
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    { attrs: { append: "pcs" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "number",
                                          disabled: "",
                                          placeholder:
                                            "Masukan Minimal kuantitas produksi",
                                        },
                                        model: {
                                          value: _vm.kat,
                                          callback: function ($$v) {
                                            _vm.kat = $$v
                                          },
                                          expression: "kat",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-md-12 mt-2" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-2 d-flex justify-content-between align-items-center",
                                staticStyle: { background: "#113448" },
                              },
                              [
                                _c("span", { staticClass: "text-white m-0" }, [
                                  _vm._v("Formula/Resep Produk"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _vm.showRecipe
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn p-0",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "tooltip",
                                              title: "Hide",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.showRecipe = !_vm.showRecipe
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "bi bi-caret-up-square text-white",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            }),
                                          ]
                                        )
                                      : _c(
                                          "button",
                                          {
                                            staticClass: "btn p-0",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "tooltip",
                                              title: "Show",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.showRecipe = !_vm.showRecipe
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "bi bi-caret-down-square text-white",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            }),
                                          ]
                                        ),
                                  ]
                                ),
                              ]
                            ),
                            _vm.showRecipe
                              ? _c("table", { staticClass: "table border" }, [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("Kode Proses"),
                                      ]),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("Nama Proses"),
                                      ]),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("UM Resep"),
                                      ]),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("Qty Resep"),
                                      ]),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { scope: "col" },
                                        },
                                        [_vm._v("Material Resep")]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { scope: "col" },
                                        },
                                        [_vm._v("Hasil")]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { scope: "col" },
                                        },
                                        [_vm._v("Opsi")]
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.optionRecipe,
                                      function (recipe, i) {
                                        return _c("tr", { key: i }, [
                                          _c(
                                            "td",
                                            { staticClass: "align-top" },
                                            [_vm._v(_vm._s(recipe.processCode))]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "align-top" },
                                            [_vm._v(_vm._s(recipe.processName))]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "align-top" },
                                            [_vm._v(_vm._s(recipe.recipeUM))]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "align-top" },
                                            [_vm._v(_vm._s(recipe.recipeQty))]
                                          ),
                                          _c(
                                            "td",
                                            _vm._l(
                                              recipe.recipeMaterial,
                                              function (material) {
                                                return _c(
                                                  "p",
                                                  { staticClass: "m-0" },
                                                  [
                                                    _vm._v(
                                                      " - " +
                                                        _vm._s(
                                                          material.itemName
                                                        ) +
                                                        "(" +
                                                        _vm._s(material.qty) +
                                                        " " +
                                                        _vm._s(material.UM) +
                                                        ") "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "align-top" },
                                            [
                                              typeof recipe.recipeItemResult ===
                                              "object"
                                                ? [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          recipe
                                                            .recipeItemResult
                                                            .itemName
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "align-top text-center",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn p-0",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.watchRecipe(
                                                        recipe,
                                                        i,
                                                        $event.target
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "bi bi-eye text-success",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "b-modal",
                      {
                        attrs: {
                          id: _vm.editModal.id,
                          size: "lg",
                          title: _vm.editModal.title,
                          "ok-title": "Simpan",
                        },
                        on: { hidden: _vm.resetEditModal, ok: _vm.handleEdit },
                      },
                      [
                        _c(
                          "form",
                          {
                            ref: "form",
                            on: {
                              submit: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.handleEditSubmit.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-6 mb-2" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Item",
                                        "label-for": "itemConvert",
                                        "invalid-feedback":
                                          "Anda Harus Memilih Unit Item!",
                                        state: _vm.imageState,
                                      },
                                    },
                                    [
                                      _c("multiselect", {
                                        attrs: {
                                          id: "itemConvert",
                                          options: _vm.fixItem,
                                          placeholder: "Select",
                                          multiple: false,
                                          "custom-label": _vm.customLabel1,
                                        },
                                        model: {
                                          value: _vm.image,
                                          callback: function ($$v) {
                                            _vm.image = $$v
                                          },
                                          expression: "image",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-6 mb-2" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Minimal Qty. Produksi",
                                        "label-for": "itemJenis",
                                        "invalid-feedback": "Harus Diisi!",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { append: "pcs" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              placeholder:
                                                "Masukan Minimal kuantitas produksi",
                                            },
                                            model: {
                                              value: _vm.kat,
                                              callback: function ($$v) {
                                                _vm.kat = $$v
                                              },
                                              expression: "kat",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "col-md-12 mt-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "px-2 d-flex justify-content-between align-items-center",
                                    staticStyle: { background: "#113448" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-white m-0" },
                                      [_vm._v("Formula/Resep Produk")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName:
                                                  "v-b-modal.modal-addRecipe",
                                                modifiers: {
                                                  "modal-addRecipe": true,
                                                },
                                              },
                                            ],
                                            staticClass: "btn p-0",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "tooltip",
                                              title:
                                                "Tambah Formula/Resep Produk",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "bi bi-plus text-white",
                                              staticStyle: {
                                                "font-size": "25px",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm.showRecipe
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "btn p-0",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "tooltip",
                                                  title: "Hide",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showRecipe =
                                                      !_vm.showRecipe
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-caret-up-square text-white",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "button",
                                              {
                                                staticClass: "btn p-0",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "tooltip",
                                                  title: "Show",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showRecipe =
                                                      !_vm.showRecipe
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-caret-down-square text-white",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                }),
                                              ]
                                            ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm.showRecipe
                                  ? _c(
                                      "table",
                                      { staticClass: "table border" },
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Kode Proses")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Nama Proses")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("UM Resep")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Qty Resep")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "col" },
                                              },
                                              [_vm._v("Material Resep")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "col" },
                                              },
                                              [_vm._v("Hasil")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "col" },
                                              },
                                              [_vm._v("Opsi")]
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.optionRecipe,
                                            function (recipe, i) {
                                              return _c("tr", { key: i }, [
                                                _c(
                                                  "td",
                                                  { staticClass: "align-top" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(recipe.processCode)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "align-top" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(recipe.processName)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "align-top" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(recipe.recipeUM)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "align-top" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(recipe.recipeQty)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  _vm._l(
                                                    recipe.recipeMaterial,
                                                    function (material) {
                                                      return _c(
                                                        "p",
                                                        { staticClass: "m-0" },
                                                        [
                                                          _vm._v(
                                                            " - " +
                                                              _vm._s(
                                                                material.itemName
                                                              ) +
                                                              "(" +
                                                              _vm._s(
                                                                material.qty
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                material.UM
                                                              ) +
                                                              ") "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "align-top" },
                                                  [
                                                    typeof recipe.recipeItemResult ===
                                                    "object"
                                                      ? [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                recipe
                                                                  .recipeItemResult
                                                                  .itemName
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "align-top text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass: "btn p-0",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.editRecipeData(
                                                              recipe,
                                                              i,
                                                              $event.target
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-pencil-square text-warning",
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass: "btn p-0",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeData(
                                                              i,
                                                              "optionRecipe"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-trash text-danger",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "b-modal",
                      {
                        attrs: {
                          id: _vm.editRecipe.id,
                          size: "lg",
                          title: _vm.editRecipe.title,
                          "no-close-on-esc": "",
                          "no-close-on-backdrop": "",
                          "hide-header-close": "",
                        },
                        on: { hide: _vm.resetAllIndex },
                        scopedSlots: _vm._u([
                          {
                            key: "modal-footer",
                            fn: function () {
                              return [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "secondary" },
                                    on: { click: _vm.resetEditRecipe },
                                  },
                                  [_vm._v("Kembali")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-6 mb-2" },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "*Kode Proses" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "recipeCode",
                                      required: "",
                                      placeholder: "Masukan Kode Proses",
                                      disabled: "",
                                      state:
                                        _vm.modalRecipe.processCode === null
                                          ? false
                                          : null,
                                    },
                                    model: {
                                      value: _vm.modalRecipe.processCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modalRecipe,
                                          "processCode",
                                          $$v
                                        )
                                      },
                                      expression: "modalRecipe.processCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6 mb-2" },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "*Nama Proses" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "recipeName",
                                      required: "",
                                      placeholder: "Masukan Nama Proses",
                                      disabled: "",
                                      state:
                                        _vm.modalRecipe.processName === null
                                          ? false
                                          : null,
                                    },
                                    model: {
                                      value: _vm.modalRecipe.processName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modalRecipe,
                                          "processName",
                                          $$v
                                        )
                                      },
                                      expression: "modalRecipe.processName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-4 mb-2" },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "*Qty Resep" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "number",
                                      id: "recipeQty",
                                      required: "",
                                      placeholder:
                                        "Kuantitas yg dihasilkan resep",
                                      disabled: "",
                                      state:
                                        _vm.modalRecipe.recipeQty === null
                                          ? false
                                          : null,
                                    },
                                    model: {
                                      value: _vm.modalRecipe.recipeQty,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modalRecipe,
                                          "recipeQty",
                                          $$v
                                        )
                                      },
                                      expression: "modalRecipe.recipeQty",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-4 mb-2" },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "*UM Resep" } },
                                [
                                  _c("multiselect", {
                                    class:
                                      _vm.modalRecipe.recipeUM === null
                                        ? "invalid"
                                        : "",
                                    attrs: {
                                      id: "itemUm",
                                      options: _vm.optionsUm,
                                      placeholder: "Pilih UM",
                                      "show-labels": true,
                                      taggable: true,
                                      multiple: false,
                                      disabled: "",
                                    },
                                    on: { tag: _vm.addConvert3 },
                                    model: {
                                      value: _vm.modalRecipe.recipeUM,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modalRecipe,
                                          "recipeUM",
                                          $$v
                                        )
                                      },
                                      expression: "modalRecipe.recipeUM",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-4 mb-2" },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "*Hasil Resep" } },
                                [
                                  _c("multiselect", {
                                    class:
                                      _vm.modalRecipe.recipeItemResult === null
                                        ? "invalid"
                                        : "",
                                    attrs: {
                                      id: "itemUm",
                                      options: _vm.optionsHasil,
                                      placeholder: "Hasil yg dihasilkan",
                                      "show-labels": false,
                                      "custom-label": _vm.customLabel1,
                                      multiple: false,
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.modalRecipe.recipeItemResult,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modalRecipe,
                                          "recipeItemResult",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "modalRecipe.recipeItemResult",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-md-12 mb-2 d-flex justify-content-between align-items-center",
                            },
                            [_c("h5", [_vm._v("List Material Resep")])]
                          ),
                          _c("div", { staticClass: "col-md-12" }, [
                            _vm.showRecipe
                              ? _c("table", { staticClass: "table border" }, [
                                  _c("thead", [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { scope: "col" },
                                        },
                                        [_vm._v("*Nama Item")]
                                      ),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v("&"),
                                      ]),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { scope: "col" },
                                        },
                                        [_vm._v("Kode Item")]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { scope: "col" },
                                        },
                                        [_vm._v("Deskripsi")]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { scope: "col" },
                                        },
                                        [_vm._v("*UM")]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { scope: "col" },
                                        },
                                        [_vm._v("*Qty")]
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.arrBlank, function (recipe, i) {
                                      return _c("tr", { key: i }, [
                                        _c(
                                          "td",
                                          { attrs: { colspan: "3" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("multiselect", {
                                                  class:
                                                    recipe.itemCode === null
                                                      ? "invalid"
                                                      : "",
                                                  attrs: {
                                                    id: "recipeCode",
                                                    disabled: "",
                                                    options: _vm.fixMaterial,
                                                    placeholder: "Select one",
                                                    taggable: false,
                                                    multiple: false,
                                                    "show-labels": false,
                                                    "custom-label":
                                                      _vm.customLabel1,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.changeUM(
                                                        recipe,
                                                        i
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: recipe.itemCode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        recipe,
                                                        "itemCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "recipe.itemCode",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticStyle: { width: "175px" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass: "px-2",
                                                  attrs: {
                                                    id: "itemDescription",
                                                    required: "",
                                                    disabled: "",
                                                    state:
                                                      recipe.itemDescription ===
                                                      null
                                                        ? false
                                                        : null,
                                                  },
                                                  model: {
                                                    value:
                                                      recipe.itemDescription,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        recipe,
                                                        "itemDescription",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "recipe.itemDescription",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("multiselect", {
                                                  class:
                                                    recipe.itemUM === null
                                                      ? "invalid"
                                                      : "",
                                                  attrs: {
                                                    id: "itemUmA",
                                                    options: _vm.optionsUm,
                                                    disabled: "",
                                                    placeholder: "Pilih",
                                                    "show-labels": false,
                                                    taggable: true,
                                                    multiple: false,
                                                  },
                                                  on: {
                                                    tag: function ($event) {
                                                      return _vm.addConvert4(
                                                        i,
                                                        $event
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: recipe.itemUM,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        recipe,
                                                        "itemUM",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "recipe.itemUM",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticStyle: { width: "100px" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "number",
                                                    id: "qty",
                                                    required: "",
                                                    disabled: "",
                                                    state:
                                                      recipe.qty === null
                                                        ? false
                                                        : null,
                                                  },
                                                  model: {
                                                    value: recipe.qty,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        recipe,
                                                        "qty",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "recipe.qty",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "b-modal",
                      {
                        attrs: {
                          id: _vm.editProc.id,
                          size: "lg",
                          title: _vm.editProc.title,
                          "no-close-on-esc": "",
                          "no-close-on-backdrop": "",
                          "hide-header-close": "",
                        },
                        on: { hide: _vm.resetAllIndex },
                        scopedSlots: _vm._u(
                          [
                            _vm.recipeIndex !== ""
                              ? {
                                  key: "modal-footer",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "secondary" },
                                          on: { click: _vm.resetEditModal },
                                        },
                                        [_vm._v("Kembali")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            type: "submit",
                                            variant: "success",
                                            disabled: _vm.title === "",
                                          },
                                          on: { click: _vm.submitEditRecipe },
                                        },
                                        [_vm._v("Edit")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : _vm.priceIndex !== ""
                              ? {
                                  key: "modal-footer",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "secondary" },
                                          on: { click: _vm.resetEditModal },
                                        },
                                        [_vm._v("Kembali")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            type: "submit",
                                            variant: "success",
                                            disabled: _vm.title === "",
                                          },
                                          on: { click: _vm.submitEditPrice },
                                        },
                                        [_vm._v("Edit")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : {
                                  key: "modal-footer",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "secondary" },
                                          on: { click: _vm.resetEditProc },
                                        },
                                        [_vm._v("Kembali")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm.priceIndex !== ""
                          ? _c(
                              "form",
                              {
                                ref: "form",
                                staticClass: "row",
                                on: {
                                  submit: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.handleEditPrice.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-4 d-flex align-items-center justify-content-between",
                                  },
                                  [
                                    _c("h4", { staticClass: "m-0" }, [
                                      _vm._v("Nama price"),
                                    ]),
                                    _c("h3", [_vm._v(":")]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-md-8 my-1" },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          "label-for": "toppName",
                                          "invalid-feedback":
                                            "Nama Harus Diisi!",
                                          state: _vm.titleState,
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "toppName",
                                            state: _vm.titleState,
                                            required: "",
                                            disabled:
                                              _vm.optionPrice.length === 0 &&
                                              _vm.optionAdd.length === 0,
                                          },
                                          model: {
                                            value: _vm.title,
                                            callback: function ($$v) {
                                              _vm.title = $$v
                                            },
                                            expression: "title",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-12 mb-2 d-flex justify-content-between align-items-center",
                                  },
                                  [
                                    _c("h5", [_vm._v("List Harga")]),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary btn-sm",
                                        class:
                                          _vm.optionPrice.length === 0 &&
                                          _vm.optionAdd.length === 0
                                            ? "d-none"
                                            : "",
                                        attrs: {
                                          type: "button",
                                          disabled: _vm.title == "",
                                        },
                                        on: { click: _vm.addRowPrice },
                                      },
                                      [_vm._v(" Tambah Baris ")]
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "col-md-12" }, [
                                  _vm.showPrice
                                    ? _c(
                                        "table",
                                        { staticClass: "table border" },
                                        [
                                          _c("thead", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("Jumlah (Qty)")]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("Harga")]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("Start Date")]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("End Date")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "text-center",
                                                  class:
                                                    _vm.optionPrice.length ===
                                                      0 &&
                                                    _vm.optionAdd.length === 0
                                                      ? "d-none"
                                                      : "",
                                                },
                                                [_vm._v(" Opsi ")]
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.arrBlank,
                                              function (price, i) {
                                                return _c("tr", { key: i }, [
                                                  _c(
                                                    "td",
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        [
                                                          _c("b-form-input", {
                                                            class:
                                                              price.priceQty ===
                                                              ""
                                                                ? "is-invalid"
                                                                : "",
                                                            attrs: {
                                                              disabled:
                                                                _vm.optionPrice
                                                                  .length ===
                                                                  0 &&
                                                                _vm.optionAdd
                                                                  .length === 0,
                                                              type: "number",
                                                              id: "priceValue",
                                                              required: "",
                                                            },
                                                            model: {
                                                              value:
                                                                price.priceQty,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    price,
                                                                    "priceQty",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "price.priceQty",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        [
                                                          _c(
                                                            "b-input-group",
                                                            {
                                                              attrs: {
                                                                prepend: "Rp.",
                                                              },
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "format-number",
                                                                    rawName:
                                                                      "v-format-number",
                                                                  },
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      price.priceValue,
                                                                    expression:
                                                                      "price.priceValue",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "form-control",
                                                                class:
                                                                  price.priceValue ===
                                                                  ""
                                                                    ? "is-invalid"
                                                                    : "",
                                                                attrs: {
                                                                  disabled:
                                                                    _vm
                                                                      .optionPrice
                                                                      .length ===
                                                                      0 &&
                                                                    _vm
                                                                      .optionAdd
                                                                      .length ===
                                                                      0,
                                                                  id: "priceValue",
                                                                  required: "",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    price.priceValue,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      )
                                                                        return
                                                                      _vm.$set(
                                                                        price,
                                                                        "priceValue",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        {
                                                          attrs: {
                                                            "invalid-feedback":
                                                              price.priceStartDate ===
                                                              ""
                                                                ? "Tidak Boleh Kosong!"
                                                                : null,
                                                            state:
                                                              price.priceStartDate ===
                                                              ""
                                                                ? false
                                                                : null,
                                                          },
                                                        },
                                                        [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              disabled:
                                                                _vm.optionPrice
                                                                  .length ===
                                                                  0 &&
                                                                _vm.optionAdd
                                                                  .length === 0,
                                                              type: "date",
                                                              id: "priceQTY",
                                                              required: "",
                                                            },
                                                            model: {
                                                              value:
                                                                price.priceStartDate,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    price,
                                                                    "priceStartDate",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "price.priceStartDate",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              disabled:
                                                                _vm.optionPrice
                                                                  .length ===
                                                                  0 &&
                                                                _vm.optionAdd
                                                                  .length === 0,
                                                              type: "date",
                                                              id: "priceQTY",
                                                            },
                                                            model: {
                                                              value:
                                                                price.priceEndDate,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    price,
                                                                    "priceEndDate",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "price.priceEndDate",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn p-0 py-2",
                                                          class:
                                                            _vm.optionPrice
                                                              .length === 0 &&
                                                            _vm.optionAdd
                                                              .length === 0
                                                              ? "d-none"
                                                              : "",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeRowPrice(
                                                                i
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "bi bi-trash text-danger",
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          : _vm.recipeIndex !== ""
                          ? _c("form", { ref: "form", staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-6 mb-2" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "*Kode Proses" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "recipeCode",
                                          required: "",
                                          placeholder: "Masukan Kode Proses",
                                          state:
                                            _vm.modalRecipe.processCode === null
                                              ? false
                                              : null,
                                        },
                                        model: {
                                          value: _vm.modalRecipe.processCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.modalRecipe,
                                              "processCode",
                                              $$v
                                            )
                                          },
                                          expression: "modalRecipe.processCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-6 mb-2" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "*Nama Proses" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "recipeName",
                                          required: "",
                                          placeholder: "Masukan Nama Proses",
                                          state:
                                            _vm.modalRecipe.processName === null
                                              ? false
                                              : null,
                                        },
                                        model: {
                                          value: _vm.modalRecipe.processName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.modalRecipe,
                                              "processName",
                                              $$v
                                            )
                                          },
                                          expression: "modalRecipe.processName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-4 mb-2" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "*Qty Resep" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "number",
                                          id: "recipeQty",
                                          required: "",
                                          placeholder:
                                            "Kuantitas yg dihasilkan resep",
                                          state:
                                            _vm.modalRecipe.recipeQty === null
                                              ? false
                                              : null,
                                        },
                                        model: {
                                          value: _vm.modalRecipe.recipeQty,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.modalRecipe,
                                              "recipeQty",
                                              $$v
                                            )
                                          },
                                          expression: "modalRecipe.recipeQty",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-4 mb-2" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "*UM Resep" } },
                                    [
                                      _c("multiselect", {
                                        class:
                                          _vm.modalRecipe.recipeUM === null
                                            ? "invalid"
                                            : "",
                                        attrs: {
                                          id: "itemUm",
                                          options: _vm.optionsUm,
                                          placeholder: "Pilih UM",
                                          "show-labels": true,
                                          taggable: true,
                                          multiple: false,
                                        },
                                        on: { tag: _vm.addConvert3 },
                                        model: {
                                          value: _vm.modalRecipe.recipeUM,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.modalRecipe,
                                              "recipeUM",
                                              $$v
                                            )
                                          },
                                          expression: "modalRecipe.recipeUM",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-4 mb-2" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "*Hasil Resep" } },
                                    [
                                      _c("multiselect", {
                                        class:
                                          _vm.modalRecipe.recipeItemResult ===
                                          null
                                            ? "invalid"
                                            : "",
                                        attrs: {
                                          id: "itemUm",
                                          options: _vm.optionsHasil,
                                          placeholder: "Hasil yg dihasilkan",
                                          "show-labels": false,
                                          "custom-label": _vm.customLabel1,
                                          multiple: false,
                                        },
                                        model: {
                                          value:
                                            _vm.modalRecipe.recipeItemResult,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.modalRecipe,
                                              "recipeItemResult",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "modalRecipe.recipeItemResult",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-md-12 mb-2 d-flex justify-content-between align-items-center",
                                },
                                [
                                  _c("h5", [_vm._v("List Material Resep")]),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary btn-sm",
                                      attrs: { type: "button" },
                                      on: { click: _vm.addRowRecipe },
                                    },
                                    [_vm._v(" Tambah Material ")]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "col-md-12" }, [
                                _vm.showRecipe
                                  ? _c(
                                      "table",
                                      { staticClass: "table border" },
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "col" },
                                              },
                                              [_vm._v("*Nama Item")]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "text-center" },
                                              [_vm._v("&")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "col" },
                                              },
                                              [_vm._v("Kode Item")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "col" },
                                              },
                                              [_vm._v("Deskripsi")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "col" },
                                              },
                                              [_vm._v("*UM")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "col" },
                                              },
                                              [_vm._v("*Qty")]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "text-center" },
                                              [_vm._v("Opsi")]
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.arrBlank,
                                            function (recipe, i) {
                                              return _c("tr", { key: i }, [
                                                _c(
                                                  "td",
                                                  { attrs: { colspan: "3" } },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c("multiselect", {
                                                          class:
                                                            recipe.itemCode ===
                                                            null
                                                              ? "invalid"
                                                              : "",
                                                          attrs: {
                                                            id: "recipeCode",
                                                            options:
                                                              _vm.fixMaterial,
                                                            placeholder:
                                                              "Select one",
                                                            taggable: false,
                                                            multiple: false,
                                                            "show-labels": false,
                                                            "custom-label":
                                                              _vm.customLabel1,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.changeUM(
                                                                recipe,
                                                                i
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              recipe.itemCode,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                recipe,
                                                                "itemCode",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "recipe.itemCode",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      width: "175px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c("b-form-input", {
                                                          staticClass: "px-2",
                                                          attrs: {
                                                            id: "itemDescription",
                                                            required: "",
                                                            state:
                                                              recipe.itemDescription ===
                                                              null
                                                                ? false
                                                                : null,
                                                          },
                                                          model: {
                                                            value:
                                                              recipe.itemDescription,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                recipe,
                                                                "itemDescription",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "recipe.itemDescription",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c("multiselect", {
                                                          class:
                                                            recipe.itemUM ===
                                                            null
                                                              ? "invalid"
                                                              : "",
                                                          attrs: {
                                                            id: "itemUmA",
                                                            options:
                                                              _vm.optionsUm,
                                                            placeholder:
                                                              "Pilih",
                                                            "show-labels": false,
                                                            taggable: true,
                                                            multiple: false,
                                                          },
                                                          on: {
                                                            tag: function (
                                                              $event
                                                            ) {
                                                              return _vm.addConvert4(
                                                                i,
                                                                $event
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              recipe.itemUM,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                recipe,
                                                                "itemUM",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "recipe.itemUM",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "number",
                                                            id: "qty",
                                                            required: "",
                                                            state:
                                                              recipe.qty ===
                                                              null
                                                                ? false
                                                                : null,
                                                          },
                                                          model: {
                                                            value: recipe.qty,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                recipe,
                                                                "qty",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "recipe.qty",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn p-0 py-2",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeRowRecipe(
                                                              i
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-trash text-danger",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ])
                          : _c("form"),
                      ]
                    ),
                    _c(
                      "b-modal",
                      {
                        ref: "modal",
                        attrs: {
                          id: "modal-add",
                          size: "lg",
                          title: "Tambah Formula",
                          "no-close-on-esc": "",
                          "no-close-on-backdrop": "",
                        },
                        on: {
                          show: _vm.resetModal,
                          hidden: _vm.resetModal,
                          ok: _vm.handleOk,
                        },
                      },
                      [
                        _c(
                          "form",
                          {
                            ref: "form",
                            on: {
                              submit: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.handleSubmit.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "p-5" }, [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-6 mb-2" },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Item",
                                          "label-for": "itemConvert",
                                          "invalid-feedback":
                                            "Anda Harus Memilih Unit Item!",
                                          state: _vm.imageState,
                                        },
                                      },
                                      [
                                        _c("multiselect", {
                                          attrs: {
                                            id: "itemConvert",
                                            options: _vm.fixItem,
                                            placeholder: "Select",
                                            multiple: false,
                                            "custom-label": _vm.customLabel1,
                                          },
                                          model: {
                                            value: _vm.image,
                                            callback: function ($$v) {
                                              _vm.image = $$v
                                            },
                                            expression: "image",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-md-6 mb-2" },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Minimal Qty. Produksi",
                                          "label-for": "itemJenis",
                                          "invalid-feedback": "Harus Diisi!",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { attrs: { append: "pcs" } },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "number",
                                                placeholder:
                                                  "Masukan Minimal kuantitas produksi",
                                              },
                                              model: {
                                                value: _vm.kat,
                                                callback: function ($$v) {
                                                  _vm.kat = $$v
                                                },
                                                expression: "kat",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "col-md-12 mt-2" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "px-2 d-flex justify-content-between align-items-center",
                                      staticStyle: { background: "#113448" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-white m-0" },
                                        [_vm._v("Formula/Resep Produk")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-modal",
                                                  rawName:
                                                    "v-b-modal.modal-addRecipe",
                                                  modifiers: {
                                                    "modal-addRecipe": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "btn p-0",
                                              attrs: {
                                                type: "button",
                                                "data-toggle": "tooltip",
                                                title:
                                                  "Tambah Formula/Resep Produk",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "bi bi-plus text-white",
                                                staticStyle: {
                                                  "font-size": "25px",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm.showRecipe
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass: "btn p-0",
                                                  attrs: {
                                                    type: "button",
                                                    "data-toggle": "tooltip",
                                                    title: "Hide",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showRecipe =
                                                        !_vm.showRecipe
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "bi bi-caret-up-square text-white",
                                                    staticStyle: {
                                                      "font-size": "20px",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _c(
                                                "button",
                                                {
                                                  staticClass: "btn p-0",
                                                  attrs: {
                                                    type: "button",
                                                    "data-toggle": "tooltip",
                                                    title: "Show",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showRecipe =
                                                        !_vm.showRecipe
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "bi bi-caret-down-square text-white",
                                                    staticStyle: {
                                                      "font-size": "20px",
                                                    },
                                                  }),
                                                ]
                                              ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.showRecipe
                                    ? _c(
                                        "table",
                                        { staticClass: "table border" },
                                        [
                                          _c("thead", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("Kode Proses")]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("Nama Proses")]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("UM Resep")]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("Qty Resep")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("Material Resep")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("Hasil")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("Opsi")]
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.optionRecipe,
                                              function (recipe, i) {
                                                return _c("tr", { key: i }, [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "align-top",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          recipe.processCode
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "align-top",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          recipe.processName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "align-top",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(recipe.recipeUM)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "align-top",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(recipe.recipeQty)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    _vm._l(
                                                      recipe.recipeMaterial,
                                                      function (material) {
                                                        return _c(
                                                          "p",
                                                          {
                                                            staticClass: "m-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " - " +
                                                                _vm._s(
                                                                  material.itemName
                                                                ) +
                                                                "(" +
                                                                _vm._s(
                                                                  material.qty
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  material.UM
                                                                ) +
                                                                ") "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "align-top",
                                                    },
                                                    [
                                                      typeof recipe.recipeItemResult ===
                                                      "object"
                                                        ? [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  recipe
                                                                    .recipeItemResult
                                                                    .itemName
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "align-top text-center",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn p-0",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editRecipeData(
                                                                recipe,
                                                                i,
                                                                $event.target
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "bi bi-pencil-square text-warning",
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn p-0",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeData(
                                                                i,
                                                                "optionRecipe"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "bi bi-trash text-danger",
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "b-modal",
                      {
                        ref: "modal",
                        attrs: {
                          id: "modal-addPrice",
                          size: "lg",
                          title: "Tambah Master Harga",
                          "hide-header-close": "",
                          "no-close-on-esc": "",
                          "no-close-on-backdrop": "",
                          "ok-disabled": _vm.arrBlank.length === 0,
                        },
                        on: {
                          hidden: _vm.resetAddModal,
                          ok: _vm.handleOkPrice,
                        },
                      },
                      [
                        _c(
                          "form",
                          {
                            ref: "form",
                            staticClass: "row",
                            on: {
                              submit: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.handleSubmitPrice.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "col-md-12 mb-2" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Nama Master Harga",
                                      "label-for": "priceName",
                                      "invalid-feedback":
                                        "Nama Master Harga Harus Diisi!",
                                      state: _vm.titleState,
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "priceName",
                                        state: _vm.titleState,
                                        required: "",
                                      },
                                      model: {
                                        value: _vm.title,
                                        callback: function ($$v) {
                                          _vm.title = $$v
                                        },
                                        expression: "title",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-12 mb-2 d-flex justify-content-between align-items-center",
                              },
                              [
                                _c("h5", [_vm._v("List Harga")]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary btn-sm",
                                    attrs: {
                                      type: "button",
                                      disabled: _vm.title == "",
                                    },
                                    on: { click: _vm.addRowPrice },
                                  },
                                  [_vm._v(" Tambah Baris ")]
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "col-md-12" }, [
                              _vm.showPrice
                                ? _c("table", { staticClass: "table border" }, [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("Jumlah (Qty)"),
                                        ]),
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("Harga"),
                                        ]),
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("Start Date"),
                                        ]),
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("End Date"),
                                        ]),
                                        _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [_vm._v("Opsi")]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(_vm.arrBlank, function (price, i) {
                                        return _c("tr", { key: i }, [
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "number",
                                                      id: "priceValue",
                                                      required: "",
                                                      state:
                                                        price.priceQty === null
                                                          ? false
                                                          : null,
                                                    },
                                                    model: {
                                                      value: price.priceQty,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          price,
                                                          "priceQty",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "price.priceQty",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    {
                                                      attrs: { prepend: "Rp." },
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "format-number",
                                                            rawName:
                                                              "v-format-number",
                                                          },
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              price.priceValue,
                                                            expression:
                                                              "price.priceValue",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        class:
                                                          price.priceQty ===
                                                          null
                                                            ? "is-invalid"
                                                            : "",
                                                        attrs: {
                                                          id: "priceValue",
                                                          required: "",
                                                        },
                                                        domProps: {
                                                          value:
                                                            price.priceValue,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              price,
                                                              "priceValue",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    "invalid-feedback":
                                                      price.priceStartDate ===
                                                      ""
                                                        ? "Tidak Boleh Kosong!"
                                                        : null,
                                                    state:
                                                      price.priceStartDate ===
                                                      ""
                                                        ? false
                                                        : null,
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "date",
                                                      id: "priceQTY",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value:
                                                        price.priceStartDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          price,
                                                          "priceStartDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "price.priceStartDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "date",
                                                      id: "priceQTY",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value: price.priceEndDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          price,
                                                          "priceEndDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "price.priceEndDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn p-0 py-2",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeRowPrice(
                                                        i
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "bi bi-trash text-danger",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      }),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "b-modal",
                      {
                        ref: "modal",
                        attrs: {
                          id: "modal-addTopping",
                          size: "sm",
                          title: "Tambah Topping/Add-Ons",
                          "hide-header-close": "",
                          "no-close-on-esc": "",
                          "no-close-on-backdrop": "",
                        },
                        on: {
                          hidden: _vm.resetAddTopping,
                          ok: _vm.handleOkTopping,
                        },
                      },
                      [
                        _c(
                          "form",
                          {
                            ref: "form",
                            staticClass: "row",
                            on: {
                              submit: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.handleSubmitTopping.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "col-md-12 mb-2" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "*Nama",
                                      "label-for": "toppingName",
                                      "invalid-feedback":
                                        "Nama Topping Harus Diisi!",
                                      state: _vm.modalTopping.titleState,
                                    },
                                  },
                                  [
                                    _c("multiselect", {
                                      attrs: {
                                        id: "itemJenis",
                                        options: _vm.optionsAddOns,
                                        placeholder:
                                          "Pilih Nama Topping/Add-Ons",
                                        multiple: false,
                                        "show-labels": false,
                                        label: "itemName",
                                      },
                                      model: {
                                        value: _vm.modalTopping.name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modalTopping,
                                            "name",
                                            $$v
                                          )
                                        },
                                        expression: "modalTopping.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-12 mb-2" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "*Harga",
                                      "label-for": "toppingPrice",
                                      "invalid-feedback": "Harga Harus Diisi!",
                                      state: _vm.modalTopping.priceState,
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      { attrs: { prepend: "Rp." } },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "format-number",
                                              rawName: "v-format-number",
                                            },
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.modalTopping.price,
                                              expression: "modalTopping.price",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            placeholder: "100.000",
                                            id: "toppingPrice",
                                            state: _vm.modalTopping.priceState,
                                            required: "",
                                          },
                                          domProps: {
                                            value: _vm.modalTopping.price,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.modalTopping,
                                                "price",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "b-modal",
                      {
                        ref: "modal",
                        attrs: {
                          id: "modal-addDiskon",
                          size: "lg",
                          title:
                            "Tambah Diskon Untuk Promo per Item (Contoh: Promo Ramadhan)",
                          "hide-header-close": "",
                          "no-close-on-esc": "",
                          "no-close-on-backdrop": "",
                          "ok-disabled": _vm.arrBlank.length === 0,
                        },
                        on: {
                          hidden: _vm.resetAddModal,
                          ok: _vm.handleOkDiskon,
                        },
                      },
                      [
                        _c(
                          "form",
                          {
                            ref: "form",
                            staticClass: "row",
                            on: {
                              submit: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.handleSubmitDiskon.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-12 mb-2 d-flex justify-content-between align-items-center",
                              },
                              [
                                _c("h5", [_vm._v("List Diskon")]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary btn-sm",
                                    attrs: { type: "button" },
                                    on: { click: _vm.addRowDiskon },
                                  },
                                  [_vm._v(" Tambah Baris Diskon ")]
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "col-md-12" }, [
                              _vm.showDiskon
                                ? _c("table", { staticClass: "table border" }, [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("Kode"),
                                        ]),
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("Qty Diskon"),
                                        ]),
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("Diskon"),
                                        ]),
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("Nominal"),
                                        ]),
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("Start Date"),
                                        ]),
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("End Date"),
                                        ]),
                                        _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [_vm._v("Opsi")]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.arrBlank,
                                        function (diskon, i) {
                                          return _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      "invalid-feedback":
                                                        diskon.codeDiscount ===
                                                        ""
                                                          ? "Tidak Boleh Kosong!"
                                                          : null,
                                                      state:
                                                        diskon.codeDiscount ===
                                                        ""
                                                          ? false
                                                          : null,
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      staticClass: "px-2",
                                                      attrs: {
                                                        id: "diskonCode",
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          diskon.codeDiscount,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            diskon,
                                                            "codeDiscount",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "diskon.codeDiscount",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      "invalid-feedback":
                                                        diskon.diskonQty === ""
                                                          ? "Tidak Boleh Kosong!"
                                                          : null,
                                                      state:
                                                        diskon.diskonQty === ""
                                                          ? false
                                                          : null,
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      staticClass: "px-2",
                                                      staticStyle: {
                                                        width: "60px",
                                                      },
                                                      attrs: {
                                                        type: "number",
                                                        id: "diskonValue",
                                                        required: "",
                                                      },
                                                      model: {
                                                        value: diskon.diskonQty,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            diskon,
                                                            "diskonQty",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "diskon.diskonQty",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      "invalid-feedback":
                                                        diskon.diskonType === ""
                                                          ? "Tidak Boleh Kosong!"
                                                          : null,
                                                      state:
                                                        diskon.diskonType === ""
                                                          ? false
                                                          : null,
                                                    },
                                                  },
                                                  [
                                                    _c("multiselect", {
                                                      staticStyle: {
                                                        "max-width": "100px",
                                                      },
                                                      attrs: {
                                                        options:
                                                          _vm.optionsPriceType,
                                                        placeholder: "Pilih",
                                                        "show-labels": false,
                                                      },
                                                      model: {
                                                        value:
                                                          diskon.diskonType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            diskon,
                                                            "diskonType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "diskon.diskonType",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      "invalid-feedback":
                                                        diskon.diskonValue ===
                                                        ""
                                                          ? "Tidak Boleh Kosong!"
                                                          : null,
                                                      state:
                                                        diskon.diskonValue ===
                                                        ""
                                                          ? false
                                                          : null,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group",
                                                      {
                                                        attrs: {
                                                          prepend: "Rp.",
                                                        },
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          staticClass: "px-2",
                                                          attrs: {
                                                            type: "number",
                                                            id: "diskonValue",
                                                            required: "",
                                                          },
                                                          model: {
                                                            value:
                                                              diskon.diskonValue,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                diskon,
                                                                "diskonValue",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "diskon.diskonValue",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      "invalid-feedback":
                                                        diskon.diskonStartDate ===
                                                        ""
                                                          ? "Tidak Boleh Kosong!"
                                                          : null,
                                                      state:
                                                        diskon.diskonStartDate ===
                                                        ""
                                                          ? false
                                                          : null,
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      staticClass: "px-2",
                                                      staticStyle: {
                                                        width: "125px",
                                                      },
                                                      attrs: {
                                                        type: "date",
                                                        id: "diskonQTY",
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          diskon.diskonStartDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            diskon,
                                                            "diskonStartDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "diskon.diskonStartDate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("b-form-input", {
                                                      staticClass: "px-2",
                                                      staticStyle: {
                                                        width: "125px",
                                                      },
                                                      attrs: {
                                                        type: "date",
                                                        id: "diskonQTY",
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          diskon.diskonEndDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            diskon,
                                                            "diskonEndDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "diskon.diskonEndDate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn p-0 py-2",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeRowDiskon(
                                                          i
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-trash text-danger",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "b-modal",
                      {
                        ref: "modal",
                        attrs: {
                          id: "modal-addTax",
                          size: "lg",
                          title: "Tambah Tipe Pajak",
                          "hide-header-close": "",
                          "no-close-on-esc": "",
                          "no-close-on-backdrop": "",
                          "ok-disabled": _vm.arrBlank.length === 0,
                        },
                        on: { hidden: _vm.resetAddModal, ok: _vm.handleOkTax },
                      },
                      [
                        _c(
                          "form",
                          {
                            ref: "form",
                            staticClass: "row",
                            on: {
                              submit: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.handleSubmitTax.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-12 mb-2 d-flex justify-content-between align-items-center",
                              },
                              [
                                _c("h5", [_vm._v("List Pajak")]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary btn-sm",
                                    attrs: { type: "button" },
                                    on: { click: _vm.addRowTax },
                                  },
                                  [_vm._v(" Tambah Baris ")]
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "col-md-12" }, [
                              _vm.showTax
                                ? _c("table", { staticClass: "table border" }, [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("Nama"),
                                        ]),
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("Jumlah"),
                                        ]),
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("Start Date"),
                                        ]),
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("End Date"),
                                        ]),
                                        _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [_vm._v("Opsi")]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(_vm.arrBlank, function (tax, i) {
                                        return _c("tr", { key: i }, [
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    "invalid-feedback":
                                                      tax.taxName === ""
                                                        ? "Tidak Boleh Kosong!"
                                                        : null,
                                                    state:
                                                      tax.taxName === ""
                                                        ? false
                                                        : null,
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "taxName",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value: tax.taxName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          tax,
                                                          "taxName",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "tax.taxName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    "invalid-feedback":
                                                      tax.taxValue === ""
                                                        ? "Tidak Boleh Kosong!"
                                                        : null,
                                                    state:
                                                      tax.taxValue === ""
                                                        ? false
                                                        : null,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    { attrs: { append: "%" } },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "number",
                                                          id: "taxValue",
                                                          required: "",
                                                          min: "0",
                                                          max: "100",
                                                        },
                                                        model: {
                                                          value: tax.taxValue,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              tax,
                                                              "taxValue",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "tax.taxValue",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    "invalid-feedback":
                                                      tax.taxStartDate === ""
                                                        ? "Tidak Boleh Kosong!"
                                                        : null,
                                                    state:
                                                      tax.taxStartDate === ""
                                                        ? false
                                                        : null,
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "date",
                                                      id: "taxQTY",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value: tax.taxStartDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          tax,
                                                          "taxStartDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "tax.taxStartDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "date",
                                                      id: "taxQTY",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value: tax.taxEndDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          tax,
                                                          "taxEndDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "tax.taxEndDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn p-0 py-2",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeRowTax(i)
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "bi bi-trash text-danger",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      }),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "b-modal",
                      {
                        ref: "modal",
                        attrs: {
                          id: "modal-addConvert",
                          size: "lg",
                          title: "Tambah Tipe Convert",
                          "hide-header-close": "",
                          "no-close-on-esc": "",
                          "no-close-on-backdrop": "",
                          "ok-disabled": _vm.arrBlank.length === 0,
                        },
                        on: {
                          hidden: _vm.resetAddModal,
                          ok: _vm.handleOkConvert,
                        },
                      },
                      [
                        _c(
                          "form",
                          {
                            ref: "form",
                            staticClass: "row",
                            on: {
                              submit: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.handleSubmitConvert.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-12 mb-2 d-flex justify-content-between align-items-center",
                              },
                              [
                                _c("h5", [_vm._v("List Convert")]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary btn-sm",
                                    attrs: { type: "button" },
                                    on: { click: _vm.addRowConvert },
                                  },
                                  [_vm._v(" Tambah Baris ")]
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "col-md-12" }, [
                              _vm.showConvert
                                ? _c("table", { staticClass: "table border" }, [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("Qty. Awal"),
                                        ]),
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("UM. Awal"),
                                        ]),
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("Qty. Item"),
                                        ]),
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v("UM. Item"),
                                        ]),
                                        _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [_vm._v("Opsi")]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.arrBlank,
                                        function (convert, i) {
                                          return _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "number",
                                                        id: "convertQtyA",
                                                        required: "",
                                                        state:
                                                          convert.qtyA === null
                                                            ? false
                                                            : null,
                                                      },
                                                      model: {
                                                        value: convert.qtyA,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            convert,
                                                            "qtyA",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "convert.qtyA",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("multiselect", {
                                                      class:
                                                        convert.qtyA === null
                                                          ? "invalid"
                                                          : "",
                                                      attrs: {
                                                        id: "itemUmA",
                                                        options: _vm.optionsUm,
                                                        placeholder: "Pilih UM",
                                                        "show-labels": false,
                                                        taggable: true,
                                                        multiple: false,
                                                      },
                                                      on: {
                                                        tag: _vm.addConvert1,
                                                      },
                                                      model: {
                                                        value: convert.umA,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            convert,
                                                            "umA",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "convert.umA",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "number",
                                                        id: "convertQtyB",
                                                        required: "",
                                                        state:
                                                          convert.qtyB === null
                                                            ? false
                                                            : null,
                                                      },
                                                      model: {
                                                        value: convert.qtyB,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            convert,
                                                            "qtyB",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "convert.qtyB",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("multiselect", {
                                                      class:
                                                        convert.qtyB === null
                                                          ? "invalid"
                                                          : "",
                                                      attrs: {
                                                        id: "itemUmA",
                                                        options: _vm.optionsUm,
                                                        placeholder: "Pilih UM",
                                                        "show-labels": false,
                                                        taggable: true,
                                                        multiple: false,
                                                      },
                                                      on: {
                                                        tag: _vm.addConvert1,
                                                      },
                                                      model: {
                                                        value: convert.umB,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            convert,
                                                            "umB",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "convert.umB",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn p-0 py-2",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeRowConvert(
                                                          i
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-trash text-danger",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "b-modal",
                      {
                        ref: "modal",
                        attrs: {
                          id: "modal-addRecipe",
                          size: "lg",
                          title: "Tambah Tipe Resep",
                          "hide-header-close": "",
                          "no-close-on-esc": "",
                          "no-close-on-backdrop": "",
                          "ok-disabled": _vm.arrBlank.length === 0,
                        },
                        on: {
                          hidden: _vm.resetAddRecipe,
                          ok: _vm.handleOkRecipe,
                        },
                      },
                      [
                        _c(
                          "form",
                          {
                            ref: "form",
                            staticClass: "row",
                            on: {
                              submit: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.handleSubmitRecipe.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "col-md-6 mb-2" },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "*Kode Proses" } },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "recipeCode",
                                        required: "",
                                        placeholder: "Masukan Kode Proses",
                                        state:
                                          _vm.modalRecipe.processCode === null
                                            ? false
                                            : null,
                                      },
                                      model: {
                                        value: _vm.modalRecipe.processCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modalRecipe,
                                            "processCode",
                                            $$v
                                          )
                                        },
                                        expression: "modalRecipe.processCode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-6 mb-2" },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "*Nama Proses" } },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "recipeName",
                                        required: "",
                                        placeholder: "Masukan Nama Proses",
                                        state:
                                          _vm.modalRecipe.processName === null
                                            ? false
                                            : null,
                                      },
                                      model: {
                                        value: _vm.modalRecipe.processName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modalRecipe,
                                            "processName",
                                            $$v
                                          )
                                        },
                                        expression: "modalRecipe.processName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4 mb-2" },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "*Qty Resep" } },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "number",
                                        id: "recipeQty",
                                        required: "",
                                        placeholder:
                                          "Kuantitas yg dihasilkan resep",
                                        state:
                                          _vm.modalRecipe.recipeQty === null
                                            ? false
                                            : null,
                                      },
                                      model: {
                                        value: _vm.modalRecipe.recipeQty,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modalRecipe,
                                            "recipeQty",
                                            $$v
                                          )
                                        },
                                        expression: "modalRecipe.recipeQty",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4 mb-2" },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "*UM Resep" } },
                                  [
                                    _c("multiselect", {
                                      class:
                                        _vm.modalRecipe.recipeUM === null
                                          ? "invalid"
                                          : "",
                                      attrs: {
                                        id: "itemUm",
                                        options: _vm.optionsUm,
                                        placeholder: "Pilih UM",
                                        "show-labels": true,
                                        taggable: true,
                                        multiple: false,
                                      },
                                      on: { tag: _vm.addConvert3 },
                                      model: {
                                        value: _vm.modalRecipe.recipeUM,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modalRecipe,
                                            "recipeUM",
                                            $$v
                                          )
                                        },
                                        expression: "modalRecipe.recipeUM",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4 mb-2" },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "*Hasil Resep" } },
                                  [
                                    _c("multiselect", {
                                      class:
                                        _vm.modalRecipe.recipeItemResult ===
                                        null
                                          ? "invalid"
                                          : "",
                                      attrs: {
                                        id: "itemUm",
                                        options: _vm.optionsHasil,
                                        placeholder: "Hasil yg dihasilkan",
                                        "show-labels": false,
                                        "custom-label": _vm.customLabel1,
                                        multiple: false,
                                      },
                                      model: {
                                        value: _vm.modalRecipe.recipeItemResult,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.modalRecipe,
                                            "recipeItemResult",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "modalRecipe.recipeItemResult",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-12 mb-2 d-flex justify-content-between align-items-center",
                              },
                              [
                                _c("h5", [_vm._v("List Material Resep")]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary btn-sm",
                                    attrs: { type: "button" },
                                    on: { click: _vm.addRowRecipe },
                                  },
                                  [_vm._v(" Tambah Material ")]
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "col-md-12" }, [
                              _vm.showRecipe
                                ? _c("table", { staticClass: "table border" }, [
                                    _c("thead", [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-center",
                                            attrs: { scope: "col" },
                                          },
                                          [_vm._v("*Nama Item")]
                                        ),
                                        _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [_vm._v("&")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-center",
                                            attrs: { scope: "col" },
                                          },
                                          [_vm._v("Kode Item")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-center",
                                            attrs: { scope: "col" },
                                          },
                                          [_vm._v("Deskripsi")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-center",
                                            attrs: { scope: "col" },
                                          },
                                          [_vm._v("*UM")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-center",
                                            attrs: { scope: "col" },
                                          },
                                          [_vm._v("*Qty")]
                                        ),
                                        _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [_vm._v("Opsi")]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.arrBlank,
                                        function (recipe, i) {
                                          return _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              { attrs: { colspan: "3" } },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("multiselect", {
                                                      class:
                                                        recipe.itemCode === null
                                                          ? "invalid"
                                                          : "",
                                                      attrs: {
                                                        id: "recipeCode",
                                                        options:
                                                          _vm.fixMaterial,
                                                        placeholder:
                                                          "Select one",
                                                        taggable: false,
                                                        multiple: false,
                                                        "show-labels": false,
                                                        "custom-label":
                                                          _vm.customLabel1,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeUM(
                                                            recipe,
                                                            i
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: recipe.itemCode,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            recipe,
                                                            "itemCode",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "recipe.itemCode",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: { width: "175px" },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("b-form-input", {
                                                      staticClass: "px-2",
                                                      attrs: {
                                                        id: "itemDescription",
                                                        required: "",
                                                        state:
                                                          recipe.itemDescription ===
                                                          null
                                                            ? false
                                                            : null,
                                                      },
                                                      model: {
                                                        value:
                                                          recipe.itemDescription,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            recipe,
                                                            "itemDescription",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "recipe.itemDescription",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("multiselect", {
                                                      class:
                                                        recipe.itemUM === null
                                                          ? "invalid"
                                                          : "",
                                                      attrs: {
                                                        id: "itemUmA",
                                                        options: _vm.optionsUm,
                                                        placeholder: "Pilih",
                                                        "show-labels": false,
                                                        taggable: true,
                                                        multiple: false,
                                                      },
                                                      on: {
                                                        tag: function ($event) {
                                                          return _vm.addConvert4(
                                                            i,
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: recipe.itemUM,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            recipe,
                                                            "itemUM",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "recipe.itemUM",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: { width: "100px" },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "number",
                                                        id: "qty",
                                                        required: "",
                                                        state:
                                                          recipe.qty === null
                                                            ? false
                                                            : null,
                                                      },
                                                      model: {
                                                        value: recipe.qty,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            recipe,
                                                            "qty",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "recipe.qty",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn p-0 py-2",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeRowRecipe(
                                                          i
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-trash text-danger",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "b-row",
                      { staticClass: "my-4" },
                      [
                        _c(
                          "b-col",
                          { staticClass: "my-1", attrs: { sm: "5", md: "6" } },
                          [
                            _c("span", { staticClass: "px-2" }, [
                              _vm._v("Show"),
                            ]),
                            _c("b-form-select", {
                              attrs: {
                                id: "per-page-select",
                                options: _vm.pageOptions,
                                size: "sm",
                              },
                              model: {
                                value: _vm.perPage,
                                callback: function ($$v) {
                                  _vm.perPage = $$v
                                },
                                expression: "perPage",
                              },
                            }),
                            _vm._v(
                              " Data of " + _vm._s(_vm.totalRows) + " Data "
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "my-1", attrs: { sm: "7", md: "6" } },
                          [
                            _c("b-pagination", {
                              staticClass: "my-0",
                              attrs: {
                                "total-rows": _vm.totalRows,
                                "per-page": _vm.perPage,
                                align: "fill",
                                size: "sm",
                              },
                              model: {
                                value: _vm.currentPage,
                                callback: function ($$v) {
                                  _vm.currentPage = $$v
                                },
                                expression: "currentPage",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _c("div", { key: "other" }, [_c("connection-timeout")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }