<template>
  <main id="content" role="main" class="main">
    <label for="vendorId">Vendor ID: 0x</label>
    <input type="text" name="vendorId" placeholder="vendorId" v-model="vendor" />

    <p><a href="#" @click="getDeviceInfo">Click me to get USB device info</a></p>
  </main>
</template>

<script>
export default {
  data() {
    return {
      vendor: "2a03",
    };
  },
  methods: {
    getDeviceInfo() {
      const vendorId = "0x" + this.vendor;

      navigator.usb
        .requestDevice({
          filters: [
            {
              vendorId: parseFloat(vendorId, 16),
            },
          ],
        })
        .then((device) => {
          console.log(device);
          console.log("Product ID: " + device.productId.toString(16));
          console.log("Vendor ID: " + device.vendorId.toString(16));
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
