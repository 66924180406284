var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report", staticStyle: { "font-size": "10px" } },
    [
      _c(
        "table",
        {
          staticClass: "z-1 position-relative table table-bordered table-hover",
        },
        [
          _vm._m(0),
          _vm.fixData.length > 0
            ? _c(
                "tbody",
                _vm._l(_vm.fixData, function (data, i) {
                  return _c("tr", [
                    _c("td", [_vm._v(_vm._s(i + 1))]),
                    _c("td", [_vm._v(_vm._s(data.itemName))]),
                    _c("td", [_vm._v(_vm._s(data.itemCode))]),
                    _c(
                      "td",
                      [
                        data.itemInvStock
                          ? [_vm._v(_vm._s(data.itemInvStock.invUM))]
                          : [_vm._v("-")],
                      ],
                      2
                    ),
                    _c(
                      "td",
                      [
                        data.itemInvStock
                          ? [_vm._v(_vm._s(data.itemInvStock.invBegining))]
                          : [_vm._v("-")],
                      ],
                      2
                    ),
                  ])
                }),
                0
              )
            : _c("tbody", [_vm._m(1)]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "sticky-element bg-secondary" }, [
      _c("tr", [
        _c(
          "th",
          {
            staticClass: "text-center align-middle text-white",
            attrs: { scope: "col", width: "50" },
          },
          [_c("b", [_vm._v("NO")])]
        ),
        _c(
          "th",
          {
            staticClass: "text-center align-middle text-white",
            attrs: { scope: "col" },
          },
          [_c("b", [_vm._v("NAMA Client")])]
        ),
        _c(
          "th",
          {
            staticClass: "text-center align-middle text-white",
            attrs: { scope: "col", width: "250" },
          },
          [_c("b", [_vm._v("Alamat")])]
        ),
        _c(
          "th",
          {
            staticClass: "text-center align-middle text-white",
            attrs: { scope: "col", width: "50" },
          },
          [_c("b", [_vm._v("No Hp Client")])]
        ),
        _c(
          "th",
          {
            staticClass: "text-center align-middle text-white",
            attrs: { scope: "col", width: "85" },
          },
          [_c("b", [_vm._v("Total Piutang")])]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "10" } }, [
        _vm._v("Belum Ada data atau data kosong!"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }