<template>
  <main id="content" role="main" class="main">
    <div class="w-100" ref="contentDiv"></div>
    <chatting style="z-index: 10"></chatting>
    <transition name="fade" mode="out-in">
      <div v-if="isDataLoaded === ''" key="empty">
        <loading></loading>
      </div>

      <!-- Main Content -->
      <div v-else-if="isDataLoaded" key="truthy">
        <div class="content container-fluid p-3 ps-0 pt-0">
          <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Modal title</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div
                    class="report p-3 printable-element"
                    style="font-size: 18px; width: 100%; font-family: sans-serif"
                    v-if="paymentData"
                  >
                    <!-- Title -->
                    <h1 class="text-center pt-4"><b>KLINIK UTAMA MUTIARA AISHA</b></h1>
                    <p class="text-center">JL. KI HAJAR DEWANTARA 04 SELOSARI MAGETAN</p>
                    <p class="text-center">082331 200 500</p>
                    <!-- End of Title -->

                    <!-- Header -->
                    <div class="row pb-1" v-if="paymentData.transId">
                      <div class="col-3" style="font-size: 20px">Nm. Pasien</div>
                      <div class="col-9" style="font-size: 18px">
                        : {{ paymentData.transName }}
                      </div>
                      <div class="col-3" style="font-size: 20px">Nomor RM</div>
                      <div class="col-9" style="font-size: 18px">
                        : {{ paymentData.transIdMember.userCode }}
                      </div>
                      <div class="col-3" style="font-size: 20px">Alamat</div>
                      <div class="col-9" style="font-size: 18px">
                        : {{ paymentData.transIdMember.userAddress[0].location }}
                      </div>
                      <div class="col-3" style="font-size: 20px">Dokter</div>
                      <div class="col-9" style="font-size: 18px">
                        : {{ paymentData.transKasir.userName }}
                      </div>
                      <div class="col-3" style="font-size: 20px">Poli</div>
                      <div class="col-9" style="font-size: 18px">:</div>
                    </div>
                    <div
                      class="w-100 border-dark border-top border-bottom"
                      style="height: 0.25rem"
                    ></div>
                    <div class="w-100">
                      <table class="w-100" v-if="paymentData.transPesanan">
                        <thead class="border-bottom border-dark">
                          <tr>
                            <th style="font-size: 20px">TRANSAKSI</th>
                            <th class="text-end" style="font-size: 20px">QTY</th>
                            <th class="text-end" style="font-size: 20px">DISC</th>
                            <th class="text-end" style="font-size: 20px">JUMLAH</th>
                          </tr>
                        </thead>
                        <tbody class="border-bottom border-dark">
                          <tr
                            v-for="data in paymentData.transPesanan"
                            v-if="paymentData.transPesanan.length > 0"
                          >
                            <td style="font-size: 18px; width: 60%">
                              <template v-if="data.itemGroup[0] === 'layanan'"
                                >ADMIN
                              </template>
                              <template v-else-if="data.itemGroup[0] === 'Fee'"
                                >TARIF
                              </template>
                              {{ data.itemName }}
                            </td>
                            <td class="text-end" style="font-size: 18px; width: 5%">
                              {{ data.itemQty }}
                            </td>
                            <td class="text-end" style="font-size: 18px; width: 17.5%">
                              0
                            </td>
                            <td class="text-end" style="font-size: 18px; width: 17.5%">
                              {{ data.itemQty * data.itemMasterPrice.priceValue }}
                              <!-- {{
                                formatNumberInt(
                                  data.itemQty * data.itemMasterPrice.priceValue
                                )
                              }} -->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="row d-flex justify-content-between">
                        <div class="col-6 row">
                          <div class="col-5" style="font-size: 18px">Tanggal</div>
                          <div
                            class="col-7"
                            style="font-size: 18px"
                            v-if="paymentData.transDate"
                          >
                            : {{ formatIndDate(paymentData.transDate) }}
                          </div>
                          <div class="col-5 pe-0" style="font-size: 18px">No. Nota</div>
                          <div class="col-7 pe-0" style="font-size: 18px">
                            : {{ paymentData.transNoSO }}
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="row">
                            <div class="col-6 d-flex justify-content-between">
                              SUBTOTAL <span>:</span>
                            </div>
                            <div
                              class="col-6 text-end"
                              style="font-size: 18px"
                              v-if="paymentData.grandTotal"
                            >
                              {{ paymentData.grandTotal }},00
                              <!-- {{ formatNumberInt(paymentData.grandTotal) }},00 -->
                            </div>
                            <div
                              class="col-6 d-flex justify-content-between"
                              style="font-size: 18px"
                            >
                              DISC <span>:</span>
                            </div>
                            <div class="col-6 text-end" style="font-size: 18px">0,00</div>
                            <div
                              class="col-6 d-flex justify-content-between"
                              style="font-size: 18px"
                            >
                              <b>TOTAL</b> <span>:</span>
                            </div>
                            <div
                              class="col-6 text-end"
                              style="font-size: 18px"
                              v-if="paymentData.grandTotal"
                            >
                              <!-- <b>{{ formatNumberInt(paymentData.grandTotal) }},00</b> -->
                              <b>{{ paymentData.grandTotal }},00</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="w-100 border-dark border-top border-bottom"
                        style="height: 0.25rem"
                      ></div>
                      <p>
                        <b v-if="paymentData.grandTotal">Terbilang :</b>
                        {{ numberToText(paymentData.grandTotal) }}
                      </p>
                      <div class="text-center pt-3">
                        <h2>
                          Terima kasih atas kunjungannya
                          <p>Semoga Lekas Sembuh</p>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary">Save changes</button>
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- end -->
          <b-modal
            id="modal-payment"
            title="Detail Transaksi"
            @ok.prevent="pay()"
            size="lg"
          >
            <div class="row" v-if="paymentData">
              <div class="col-6">
                <pre v-if="paymentData.transId" class="m-0">
  No. Invoice   :  {{ paymentData.transId }}
  No. RM        :  {{ paymentData.transIdMember.userCode }}
  Nama Pasien   :  {{ paymentData.transName }}
      </pre
                >
              </div>
              <div class="col-6">
                <pre v-if="paymentData.transId" class="m-0">
  Tgl. Berobat  :  {{ formatIndDate(paymentData.transDate) }}
  Alamat        :  {{ paymentData.transIdMember.userAddress[0].location }}
      </pre
                >
              </div>
            </div>
            <table class="table table-bordered" v-if="paymentData.transPesanan">
              <thead>
                <tr>
                  <th class="text-center align-middle">Kode</th>
                  <th class="text-center align-middle">Nama</th>
                  <th class="text-center align-middle">Qty</th>
                  <th class="text-center align-middle">Harga</th>
                  <th class="text-center align-middle">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="data in paymentData.transPesanan"
                  v-if="paymentData.transPesanan.length > 0"
                >
                  <td class="fs-11 align-middle">
                    {{ data.itemCode }}
                  </td>
                  <td class="fs-11 align-middle">
                    {{ data.itemName }}
                  </td>
                  <td class="fs-11 align-middle">
                    {{ data.itemQty }}
                  </td>
                  <td class="fs-11 align-middle text-end">
                    <!-- {{ formatNumberInt(data.itemMasterPrice.priceValue) }} -->
                    {{ data.itemMasterPrice.priceValue }}
                  </td>
                  <td class="fs-11 align-middle text-end">
                    <!-- {{ formatNumberInt(data.itemQty * data.itemMasterPrice.priceValue) }} -->
                    {{ data.itemQty * data.itemMasterPrice.priceValue }}
                  </td>
                </tr>
                <tr>
                  <th colspan="3" class="text-end">Total</th>
                  <th colspan="2" class="text-end">
                    Rp. {{ paymentData.grandTotal }}
                    <!-- Rp. {{ formatNumberInt(paymentData.grandTotal) }} -->
                  </th>
                </tr>
              </tbody>
            </table>
            <multiselect
              class="my-1"
              v-model="payMethods"
              :options="optionsCoa"
              placeholder="Metode Pembayaran"
              :multiple="false"
              :show-labels="false"
              label="coaName"
            >
            </multiselect>
          </b-modal>
          <div class="row my-1">
            <div class="col-4 d-flex align-items-center">
              <ul class="breadcrumb m-0">
                <li>
                  <router-link to="/dashboard">
                    <a href="#"><i class="bi-house-door"></i></a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/transaksi/po-invoice/add">
                    <a href="#">Sales Invoice</a>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="col-4 text-center">
              <span class="h2">List Sales Invoice</span>
            </div>
          </div>
          <hr class="my-2" />

          <!-- Content -->
          <template v-if="isDataLoaded">
            <div class="card">
              <div class="card-header bg-light p-2">
                <div class="row d-flex">
                  <div class="col-9 row">
                    <div
                      class="d-flex col-12 align-items-center pe-0 mt-2"
                      v-for="(data, i) in filterList"
                    >
                      <select
                        style="font-size: 12px; max-width: 125px"
                        class="form-select form-select-sm py-1 px-2"
                        v-model="data.filterBaris"
                      >
                        <option value="" v-if="!filterBaris">Filter Baris</option>
                        <option
                          v-for="micro in tableHeader"
                          :value="micro.key"
                          v-if="micro.key !== 'actions'"
                        >
                          {{ micro.label }}
                        </option>
                      </select>
                      <select
                        class="mx-2 form-select form-select-sm py-1 px-2"
                        style="font-size: 12px; max-width: 125px"
                        v-model="data.startFrom"
                      >
                        <option value="" v-if="!data.startFrom">Tipe Pencarian</option>
                        <option :value="micro" v-for="micro in optionQuery">
                          {{ micro }}
                        </option>
                      </select>
                      <div class="input-group" style="width: 150px">
                        <div class="input-group-prepend">
                          <span class="input-group-text py-1 px-2"
                            ><i class="bi-search" style="font-size: 10px"></i
                          ></span>
                        </div>
                        <input
                          type="search"
                          class="form-control form-control-sm p-1"
                          placeholder="Cari Data..."
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          style="font-size: 12px"
                          v-model="data.filter1"
                        />
                      </div>
                      <div class="input-group px-2" style="width: 150px">
                        <div class="input-group-prepend">
                          <span class="input-group-text py-1 px-2"
                            ><i class="bi-search" style="font-size: 10px"></i
                          ></span>
                        </div>
                        <input
                          type="search"
                          class="form-control form-control-sm p-1"
                          placeholder="Cari Data..."
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          style="font-size: 12px"
                          v-model="data.filter2"
                        />
                      </div>
                      <select
                        class="mx-2 form-select form-select-sm py-1"
                        style="font-size: 12px; max-width: 65px"
                        v-model="data.logic"
                      >
                        <option value="and">dan</option>
                        <option value="or">atau</option>
                      </select>
                      <i
                        class="bi-plus-circle text-primary ms-1"
                        style="cursor: pointer"
                        @click="addFilterList"
                      ></i>
                      <i
                        class="bi-x-circle text-danger ms-1"
                        style="cursor: pointer"
                        @click="removeFilterRow(i)"
                      ></i>
                    </div>
                  </div>

                  <div
                    class="col-3 d-flex justify-content-end mt-2 align-items-start p-0"
                    style="z-index: 2"
                  >
                    <button type="button" class="btn-xs btn btn-outline-success">
                      Cari
                    </button>
                    <button type="button" class="btn-xs btn btn-outline-warning">
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body p-2">
                <div class="d-flex align-items-center justify-content-between">
                  <div
                    style="font-size: 11px; width: 300px"
                    class="d-flex align-items-center justify-content-between"
                  >
                    Menampilkan 1 - 10 data dari {{ rows }}
                    <select
                      class="form-select py-1"
                      aria-label="Default select example"
                      style="width: 75px; font-size: 12px; padding: 0 0.5rem !important"
                    >
                      <option v-for="data in pageList" :value="data">
                        {{ data }}
                      </option>
                    </select>
                  </div>
                  <b-pagination
                    class="m-0"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    ><template #first-text
                      ><button class="btn btn-outline-primary btn-xs">
                        First
                      </button></template
                    >
                    <template #prev-text
                      ><button class="btn btn-outline-primary btn-xs">
                        Prev
                      </button></template
                    >
                    <template #next-text
                      ><button class="btn btn-outline-primary btn-xs">
                        Next
                      </button></template
                    >
                    <template #last-text
                      ><button class="btn btn-outline-primary btn-xs">
                        Last
                      </button></template
                    >
                    <template #ellipsis-text> ... </template>
                    <template #page="{ page, active }">
                      <b v-if="active">{{ page }}</b>
                      <i v-else>{{ page }}</i>
                    </template></b-pagination
                  >
                </div>
                <hr class="my-2" />
                <!-- tabel view untuk detil -->
                <b-table
                  striped
                  hover
                  :items="livePI"
                  :fields="tableHeader"
                  label-sort-asc=""
                  label-sort-desc=""
                  class="myTable"
                >
                  <template #cell(PoSupplier)="data">
                    {{ data.item.PoSupplier }}
                  </template>
                  <template #cell(grandTotal)="data">
                    <!-- {{ formatNumberInt(data.item.grandTotal) }} -->
                    {{ data.item.grandTotal }}
                  </template>
                  <template #cell(transHysPPN)="data"> </template>
                  <template #cell(poDate)="data">
                    {{ formatIndDate(data.item.dueDate) }}
                  </template>
                  <template #cell(transPesanan)="data">
                    <div class="inner-table">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th style="width: 100px">Kode</th>
                            <th>Nama</th>
                            <th style="width: 50px">Qty</th>
                            <th style="width: 75px">Harga</th>
                            <th style="width: 75px">Total</th>
                            <!-- Tambahkan kolom lain di sini sesuai kebutuhan -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="fs-10"
                            v-for="(item, index) in data.item.transPesanan"
                            :key="index"
                            v-if="data.item.transPesanan.length > 0"
                          >
                            <td>{{ item.itemCode }}</td>
                            <td>{{ item.itemName }}</td>
                            <td>{{ item.itemQty }}</td>
                            <td>{{ item.itemMasterPrice.priceValue }}</td>
                            <!-- <td>{{ formatNumberInt(item.itemMasterPrice.priceValue) }}</td> -->
                            <td>
                              {{
                                formatNumberInt(
                                  item.itemQty * item.itemMasterPrice.priceValue
                                )
                              }}
                            </td>
                            <!-- Tambahkan kolom lain di sini sesuai kebutuhan -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>
                  <template v-slot:cell(actions)="data">
                    <b-dropdown
                      id="dropdown-option"
                      right
                      size="xs"
                      variant="primary"
                      v-if="data.item.transStatusSO === 'open'"
                    >
                      <template #button-content class="px-1 py-0">
                        <i class="bi-gear px-1 py-0"></i>
                      </template>
                      <b-dropdown-item
                        @click="detilAction(data.item._id)"
                        v-if="data.item.transStatusPayment === 'Closed'"
                        >Print</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="detilAction(data.item._id)"
                        v-if="data.item.transStatusPayment === 'no'"
                        >Edit</b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="handlePayment(data.item)"
                        v-if="data.item.transStatusSO === 'open'"
                        >Payment</b-dropdown-item
                      >
                    </b-dropdown>
                    <button
                      class="btn btn-primary btn-xs"
                      @click="handlePrint(data.item)"
                      v-if="data.item.transStatusSO === 'Closed'"
                    >
                      <i class="bi-printer"></i>
                    </button>
                  </template>
                </b-table>
              </div>
            </div>
          </template>
          <div v-else>Loading...</div>
        </div>
      </div>

      <!-- Error -->
      <div v-else key="other">
        <connection-timeout></connection-timeout>
      </div>
    </transition>
  </main>
</template>

<script>
import chatting from "../components/add-on/chatting";

import io from "socket.io-client";
import { API } from "@/API";
// import router from "@/router";
import { numberToText } from "../components/function/terbilang";
import { formatNumberInt } from "../components/function/number";
import { formatIndDate, getFullDate } from "../components/function/date";
import {
  getSOFA,
  getSaldoKasbank,
  paymentSIKlinik,
} from "../components/services/components";

import loading from "../components/loading.vue";
import connectionTimeout from "../components/connectionTimeout.vue";
export default {
  components: {
    loading,
    connectionTimeout,
    chatting,
  },
  data() {
    return {
      newData: "",
      isDataLoaded: "",
      payMethods: "",
      optionsCoa: [],
      paymentData: {},
      // pagination
      rows: 3,
      perPage: 10,
      currentPage: 1,
      optionQuery: ["start at", "equal", "include"],
      pageList: ["10", "25", "50", "Semua"],
      // ===
      listUser: [],
      filterList: [],
      filterText: "",
      sendData: {},
      transData: [],
      contentDivWidth: 0,
      routing: "",
      tableHeader: [],
      component: [],
      dataPI: [],
      posts: [],
    };
  },
  async mounted() {
    const timeout = 30000; // 10 seconds
    try {
      const currentDate = await getFullDate();
      this.optionsCoa = await getSaldoKasbank(currentDate);
      this.payMethods = this.optionsCoa.find((x) => x.coaCode === "1.1.1.1.02");
      this.addFilterList();
      this.tableHeader = [
        {
          key: "transId",
          label: "No. SO",
          sortable: true,
          class: "align-top fs-11",
        },
        {
          key: "transNoSO",
          label: "No. WO",
          sortable: true,
          class: "align-top fs-11",
        },
        {
          key: "transIdMember.userName",
          label: "Pasien",
          sortable: true,
          class: "align-top fs-11",
        },
        {
          key: "transDate",
          label: "Due Date",
          sortable: true,
          class: "align-top fs-11",
        },
        {
          key: "transPesanan",
          label: "Item",
          sortable: true,
          class: "align-top text-center fs-11",
        },
        {
          key: "grandTotal",
          label: "Grand Total",
          sortable: true,
          class: "align-top fs-11",
        },
        {
          key: "transStatusPayment",
          label: "Payment",
          sortable: true,
          class: "align-top fs-11",
        },
        {
          key: "transStatusSO",
          label: "status",
          sortable: true,
          class: "align-top fs-11",
        },
        {
          key: "actions",
          label: "Actions",
          class: "align-top text-center fs-11",
          thStyle: "width: 75px;",
        },
      ];
      // this.listUser = await getUser()
      // this.listSupplier = await getSupplier()
      const final = await this.getMainData();

      const timeoutPromise = new Promise((resolve) => setTimeout(resolve, timeout));

      // Wait for either finalPromise or timeoutPromise to resolve
      const result = await Promise.race([final, timeoutPromise]);
      this.isDataLoaded = result !== undefined;
    } catch (error) {
      this.isDataLoaded = false;
    }

    // WebSocket connection
    const socket = io(API);
    socket.on("woUpdateApotek", async (message) => {
      console.log(message);
      if (message) {
        console.log("kiriman", message);
        if (message.pesan === "woUpdateApotek") {
          this.getMainData();
          this.newData = message.data;
        }
      }
    });
  },
  computed: {
    livePI() {
      let dataPI;
      if (this.newData !== "") {
        dataPI = this.dataPI.reverse().map((x) => {
          const data = {
            ...x,
          };
          if (x._id === this.newData) {
            data._rowVariant = "success";
          }
          return data;
        });
      } else {
        dataPI = this.dataPI.reverse();
      }
      return dataPI.filter((x) => x.transStatusSO !== "resep");
    },
    filteredTableData() {
      const filterText = this.filterText.toLowerCase().trim();
      return this.data.filter((row) => {
        for (let key in row) {
          const value = String(row[key]);
          if (value.toLowerCase().includes(filterText)) {
            return true;
          }
        }
        return false;
      });
    },
  },
  methods: {
    numberToText(data) {
      return numberToText(data);
    },
    printReport() {
      console.log(this.getReportContent());
      const printWindow = window.open("", "_blank");
      const printDocument = `
        <html>
          <head>
	          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet">
          <style>
              th {
                font-weight: 500
              }
              tr {
                font-size: 10px
              }
              body {
                padding-left: 1px;
                width: 5.65in;
              }
          </style>
            </head>

          <body>
            ${this.getReportContent()}
          </body>
        </html>
      `;
      printWindow.document.write(printDocument);
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
        printWindow.close();
      };
    },
    getReportContent() {
      const reportView = document.querySelector(".printable-element");
      if (reportView) {
        return reportView.innerHTML;
      }
      return "No content found.";
    },
    async connectToPrinter() {
      try {
        const device = await navigator.usb.requestDevice({
          filters: [{ vendorId: 0x04b8 }],
        });
        await device.open();
        await device.selectConfiguration(1);
        await device.claimInterface(0);

        // Define the title and its font size
        const title = "KLINIK UTAMA MUTIARA AISHA"; // Change \x32 to another value for different font sizes
        const address = "JL. KI HAJAR DEWANTARA 04 SELOSARI MAGETAN";
        const phone = "082331 200 500";
        // Define column names and fixed column widths
        const terbilang = numberToText(this.paymentData.grandTotal);
        const footer = ["Terima kasih atas kunjungannya", "Semoga Lekas Sembuh"];
        const columns = [
          { name: "TRANSAKSI", width: 35 },
          { name: "QTY", width: 5 },
          { name: "DISC", width: 15 },
          { name: "JUMLAH", width: 15 },
        ];
        const infoPasien = [
          { name: "", width: 15 },
          { name: "", width: 55 },
        ];
        const totalData = [
          { name: "", width: 15 },
          { name: "", width: 25 },
          { name: "", width: 10 },
          { name: "", width: 20 },
        ];
        // Calculate the total width of the dashed line based on column widths
        const totalWidth = columns.reduce((acc, column) => acc + column.width, 0);

        // Create the dashed line based on the total width
        const equalLine = "=".repeat(totalWidth);
        const dashedLine = "-".repeat(totalWidth);

        // Build the header row with fixed widths
        const header = columns
          .map((column) => column.name.padEnd(column.width))
          .join("  ");

        // Define your dynamic table data

        let tableData = [];
        this.paymentData.transPesanan.forEach((i) => {
          tableData.push([
            i.itemName,
            i.itemQty.toString(),
            "0",
            (i.itemQty * i.itemMasterPrice.priceValue).toString(),
          ]);
        });
        console.log(tableData);

        // const tableData = [
        //   ["Pemeriksaan Jantung", "1", 0, "50.000"],
        //   ["Pemeriksaan Darah", "1", 50.0, "1.000.000"],
        // ];
        // console.log(tableData);

        // const tableData = []
        // this.paymentData.transPesanan.forEach(i => {
        //   const data = [i.itemName, i.itemQty, "0", ]
        // });
        const tablePasien = [
          ["Nm. Pasien", ": " + this.paymentData.transIdMember.userName],
          ["Nomor RM", ": " + this.paymentData.transIdMember.userCode],
          ["Alamat", ": " + this.paymentData.transIdMember.userAddress[0].location],
          ["Dokter", ": " + this.paymentData.transKasir.userName],
          ["Poli", ": "],
        ];
        const tableTotal = [
          [
            "Tanggal",
            ": " + formatNumberInt(this.paymentData.transDate),
            "TOTAL",
            ": " + formatNumberInt(this.paymentData.grandTotal),
          ],
          ["No. Nota", ": " + this.paymentData.transNoSO, "DISC", ": 0"],
          ["", "", "SUBTOTAL", ": " + formatNumberInt(this.paymentData.grandTotal)],
        ];

        // Build the table content with fixed column widths
        const listPasien = [
          ...tablePasien.map((row) => {
            return row
              .map((cell, index) => cell.padEnd(infoPasien[index].width))
              .join("  ");
          }),
        ].join("\n");
        const listTotal = [
          ...tableTotal.map((row) => {
            return row
              .map((cell, index) => cell.padEnd(totalData[index].width))
              .join("  ");
          }),
          equalLine,
        ].join("\n");
        const tableContent = [
          equalLine,
          header,
          dashedLine,
          ...tableData.map((row) => {
            return row.map((cell, index) => cell.padEnd(columns[index].width)).join("  ");
          }),
          dashedLine,
        ].join("\n");

        const tableCommand = new TextEncoder().encode(
          "\x1B@" +
            "\x1B!" +
            "\x2D\x40" +
            title +
            "\x0A" +
            "\x1B@" +
            "\x1B!" +
            "\x1D!" +
            address +
            "\x0A" +
            phone +
            "\x0A" +
            "\x0A" +
            "\x1B!" +
            "\x1D!\x00" +
            listPasien +
            "\x0A" +
            tableContent +
            "\x0A" +
            listTotal +
            "\x0A" +
            terbilang +
            "\x0A" +
            "\x0A" +
            "\x0A" +
            footer[0] +
            "\x0A" +
            footer[1] +
            "\x1B!" +
            "\x1Bd\x01"
        );

        await device.transferOut(1, tableCommand);
        await device.close();
      } catch (error) {
        console.error("Error:", error);
      }
    },
    async getMainData() {
      this.dataPI = await getSOFA();
      return true;
    },
    // async pay() {
    //   const data = {
    //     transStatusSO: "closed",
    //     transStatusPayment: "yes",
    //     transPaymentType: this.payMethods,
    //   };
    //   await paymentSIKlinik(data, this.paymentData._id);
    // },
    pay(event) {
      console.log(this.paymentData, this.payMethods);
      this.$swal({
        title: "Anda Yakin?",
        text: "Tekan Bayar jika anda sudah yakin!",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Bayar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.alertLoading();
          try {
            const data = {
              ...this.paymentData,
              transPaymentType: {
                _id: this.payMethods._id,
                coaCode: this.payMethods.coaCode,
                coaName: this.payMethods.coaName,
              },
            };
            console.log("POST PAYMENT" , JSON.stringify(data))
            await paymentSIKlinik(data);
            this.successAdd();
            this.getMainData();
            this.printReport();
            // this.connectToPrinter();
            this.$bvModal.hide("modal-payment");
          } catch (error) {
            alert(error);
          }
        }
      });
    },
    alertLoading() {
      this.$swal({
        title: "Loading...",
        text: "Mohon Ditunggu ya.",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        willOpen: () => {
          this.$swal.showLoading();
        },
      });
    },
    successAdd() {
      this.$swal("Berhasil!", "Pembayaran Berhasil!", "success");
    },
    handlePayment(data) {
      console.log(data);
      this.$bvModal.show("modal-payment");
      this.paymentData = data;
    },
    handlePrint(data) {
      console.log(data);
      this.paymentData = data;
      this.$nextTick(() => {
        this.printReport();
      });
    },
    formatIndDate(data) {
      return formatIndDate(data);
    },
    formatNumberInt(data) {
      return formatNumberInt(data);
    },
    addFilterList() {
      const data = {
        filterBaris: "",
        startFrom: "",
        filter1: "",
        logic: "",
        filter2: "",
      };
      this.filterList.push(data);
    },

    removeFilterRow(data) {
      this.filterList.splice(data, 1);
    },
    detilAction(ponum) {
      console.log(ponum);
      // alert(ponum);
      // const data = JSON.stringify(ponum);
      this.$router.push({ name: this.$route.name + "-edit", query: { ponum } });
    },
    paymentAction(ponum) {
      // alert(ponum);
      this.$router.push({
        name: "si-payment-confirm",
        query: { data: JSON.stringify(ponum) },
      });
    },
  },
};
</script>
<style scope>
.b-pagination li {
  display: flex;
  align-items: center;
}

.b-pagination li .page-link {
  padding: 0.25rem 0;
  margin: 0 !important;
}

.myTable {
  border-collapse: collapse;
  margin: 0 !important;
  /* Hapus margin */
  padding: 0 !important;
  /* Hapus padding */
}

.myTable thead {
  /* Define your custom header class styles here */
  background-color: #f0f0f0;
  /* Change to your desired background color */
  color: rgb(240, 26, 26);
  /* Change to your desired text color */
  font-weight: bold;
  /* Change to your desired font weight */
}

.inner-table table {
  border-collapse: collapse;
  margin: 0;
  /* Hapus margin */
  padding: 0;
  /* Hapus padding */
}
</style>
