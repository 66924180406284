<template>
  <main id="content" role="main" class="main">
    <form
      class="content container-fluid p-3 ps-0 py-0"
      ref="contentDiv"
      @submit="sendData"
    >
      <!-- Header -->
      <div class="row my-1">
        <div class="col-4 d-flex align-items-center">
          <ul class="breadcrumb m-0">
            <li>
              <router-link to="/dashboard">
                <a href="#"><i class="bi-house-door"></i></a>
              </router-link>
            </li>
            <li>
              <router-link :to="$route.path.replace('/add', '')">
                <a href="#">Shipment</a>
              </router-link>
            </li>
            <li>Tambah</li>
          </ul>
        </div>
        <div class="col-4 text-center"><span class="h2">Tambah Shipment</span></div>
        <div class="col-1 d-flex justify-content-end align-items-center"></div>
        <div class="col-3 d-flex justify-content-end align-items-center">
          <router-link :to="$route.path.replace('/add', '')">
            <button class="btn btn-outline-secondary btn-xs py-1 me-2" type="button">
              <i class="bi-chevron-left"></i>Kembali
            </button>
          </router-link>
          <button type="submit" class="btn btn-primary btn-xs py-1">Simpan</button>
        </div>
      </div>

      <!-- End of Header -->

      <!-- Main Data -->
      <div class="main-data mt-4">
        <div class="row scale-9 position-relative" style="width: 114%; z-index: 1">
          <!-- First Header -->
          <div class="col-6">
            <div class="card h-100">
              <div class="row px-3 py-1">
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between"
                    >*Customer<span>:</span></label
                  >
                  <div class="col-sm-8 col-md-9 pe-0">
                    <multiselect
                      class="my-1"
                      v-model="transCustomer"
                      :options="listCustomer"
                      placeholder="Select one"
                      :multiple="false"
                      :show-labels="false"
                      :custom-label="customLabel"
                      track-by="id"
                      @input="changeCustomer()"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between"
                    >*No. Shipment <span>:</span></label
                  >
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input
                      v-model="transNoFaktur"
                      size="sm"
                      type="text"
                      class="form-control-sm py-0 form-control"
                      placeholder="Masukan Nomor Shipment (Pengiriman)"
                    ></b-form-input>
                  </div>
                </div>
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between"
                    >*Tgl. Shipment <span>:</span></label
                  >
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input
                      v-model="transDate"
                      size="sm"
                      type="date"
                      class="form-control-sm py-0 form-control"
                      required
                      placeholder="Masukan Tanggal Transaksi"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End of First Header -->

          <!-- Secondx Header -->
          <div class="col-6">
            <div class="card h-100">
              <div class="row px-3 py-1">
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between"
                    >*Ekspedisi<span>:</span></label
                  >
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input
                      v-model="transExpedition"
                      size="sm"
                      class="form-control-sm py-0 form-control"
                      placeholder="Masukan Nama Ekspedisi"
                    ></b-form-input>
                  </div>
                </div>
                <div class="row my-1 pe-0">
                  <label class="col-2 d-flex justify-content-between pe-0"
                    >*No. Resi<span>:</span></label
                  >
                  <div class="col-4 pe-0">
                    <b-form-input
                      v-model="transResi"
                      size="sm"
                      class="form-control-sm py-0 form-control"
                      placeholder="Nomor Resi"
                    ></b-form-input>
                  </div>
                  <label class="col-2 d-flex justify-content-between pe-0"
                    >*Supir<span>:</span></label
                  >
                  <div class="col-4 pe-0">
                    <b-form-input
                      v-model="transSupir"
                      size="sm"
                      class="form-control-sm py-0 form-control"
                      placeholder="Supir Kendaraan"
                    ></b-form-input>
                  </div>
                </div>
                <div class="row my-1 pe-0">
                  <label class="col-2 d-flex justify-content-between pe-0"
                    >*Jenis<span>:</span></label
                  >
                  <div class="col-4 pe-0">
                    <b-form-input
                      v-model="transKendaraan"
                      size="sm"
                      class="form-control-sm py-0 form-control"
                      placeholder="Jenis Kendaraan"
                    ></b-form-input>
                  </div>
                  <label class="col-2 d-flex justify-content-between pe-0"
                    >*No. Plat<span>:</span></label
                  >
                  <div class="col-4 pe-0">
                    <b-form-input
                      v-model="transPlatKendaraan"
                      size="sm"
                      class="form-control-sm py-0 form-control"
                      placeholder="Plat Nomor"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End of Secondx Header -->
        </div>

        <div class="row my-3">
          <!-- Hot Table -->
          <div class="col-12">
            <div
              class="hot-container col-md-12 position-relative"
              style="overflow-x: auto; z-index: 0"
            >
              <hot-table
                ref="hot"
                :settings="hotSettings"
                :data="fixExcelData"
                :rowHeaders="true"
              ></hot-table>
            </div>
          </div>
          <!-- End of Hot Table -->
        </div>

        <!-- Footer -->
        <div class="row mb-3 d-flex justify-content-between">
          <div class="col-md-6 scale-9">
            <div class="h-100">
              <div class="card h-100">
                <div class="card-header bg-light py-1">Catatan</div>
                <textarea
                  class="form-control form-control-sm py-1 h-100"
                  style="resize: none"
                  placeholder="Masukan Catatan"
                  v-model="transNotes"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <!-- End of Footer -->
      </div>

      <b-modal
        v-model="modalOpen"
        title="List Sales Order (SO)"
        @ok="saveModalData"
        @hide="closeModal"
        :ok-disabled="modalInput.length < 1"
      >
        <!-- Modal content here, e.g., input fields to collect data -->
        <b-form-group
          label="Pilih SO :"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm="8"
          content-cols-lg="9"
          invalid-feedback="Condition Harus Diisi!"
        >
          <multiselect
            class="my-1"
            v-model="modalInput"
            :options="filterSO(transCustomer)"
            placeholder="Pilih SO"
            :multiple="false"
            :show-labels="false"
            :custom-label="customLabelKode"
          >
          </multiselect>
        </b-form-group>
        <div class="col-12" v-if="modalInput.transId">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Nama</th>
                <th scope="col">Qty Req.</th>
                <th scope="col">UoM</th>
                <th scope="col">Ship To</th>
                <th scope="col">Opsi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in modalInput.transPesanan">
                <td>{{ data.itemName }}</td>
                <td>{{ data.itemQty }}</td>
                <td>{{ data.itemUm }}</td>
                <td>
                  <b>{{ modalInput.userAddress[0].nameAddr }} :</b
                  >{{ modalInput.userAddress[0].location }}
                </td>
                <td class="text-center">
                  <input
                    type="checkbox"
                    class="form-check"
                    :disabled="data.itemUm === 'set'"
                    v-model="data.checkData"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-modal>
      <!-- End of Main Data -->
    </form>
  </main>
</template>

<script>
import {
  getCoa,
  getJEVoucher,
  addShipment,
  getK,
  getListCustomerSO,
  getItemByLocation,
} from "../../components/services/components";
import { getFullDate } from "../../components/function/date";
import {
  formatNumberInt,
  removeThousandFormatter,
} from "../../components/function/number";
import {
  alertLoading,
  successAddData,
  errorAddData,
} from "../../components/function/sweetalert";
export default {
  data() {
    return {
      listItem: [],
      suppId: "",
      transNoFaktur: "",
      checkedPajak: false,
      transPayment: "",
      optionsInvoice: [],
      currentDate: "",
      dataItem: [],
      poList: [],
      locationList: [],
      modalOpen: false,
      modalInput: [],
      modalRow: null,
      modalCol: null,

      // Main Data
      transNo: "",
      transDate: "",
      itemHysQtyRcp: "",
      transNotes: "",
      transCustomer: "",
      transPaymentType: "Cash",
      transExpedition: "",
      transResi: "",
      transSupir: "",
      transKendaraan: "",
      transPlatKendaraan: "",

      subTotal: "",
      prList: [],
      porList: [],
      listCustomer: [],
      // HOT Data
      contentDivWidth: "",
      colHeader: [
        "*Kode SO",
        "Nama Item",
        "Qty. Req",
        "*Lokasi",
        "*No. Batch",
        "Qty. Batch",
        "*Qty. Send",
        "UoM",
        // "Ship To",
      ],
      colBody: [
        {
          data: "transId",
          type: "text",
          renderer: this.prRenderer,
          width: 140,
        },
        {
          data: "transName",
          type: "text",
        },
        {
          data: "itemHysQtyReq",
          type: "text",
          width: 60,
        },
        {
          data: "transLocation",
          type: "autocomplete",
          source: [],
          width: 100,
          readOnly: false,
          strict: true, // Allow user input even if it doesn't match the autocomplete list
          allowInvalid: false, // Don't allow invalid input
        },
        {
          data: "transNoBatch",
          type: "autocomplete",
          source: [],
          width: 75,
          readOnly: false,
          strict: true, // Allow user input even if it doesn't match the autocomplete list
          allowInvalid: false, // Don't allow invalid input
        },
        {
          data: "itemQtyBatch",
          type: "text",
          width: 65,
        },
        {
          data: "itemHysQtySend",
          type: "text",
          width: 75,
          readOnly: false,
        },
        {
          data: "itemHysUm",
          type: "text",
          width: 50,
        },
        // {
        //   data: "itemHysShipTo",
        //   type: "text",
        // },
      ],
      excelData: [],
      hotSettings: {
        readOnly: true,
        fixedRowsBottom: 0,
        afterChange: this.onChange,
        afterSelection: (r, c, r2, c2) => {
          // Check if the selected cell is in row 3 and column 0
          if (c === 3) {
            // console.log("Cell 3 selected");

            // console.log("tes 1", r, c);
            // console.log(this.excelData[r]);
            // Update the autocomplete source based on the value in the first cell
            // const categoryValue = this.$refs.hot.hotInstance.getDataAtCell(row, col); // Change ref to "hot"
            const categoryValue = this.excelData[r].itemName
              ? this.excelData[r].itemName
              : "";
            console.log(this.listItem.map((x) => x.itemName));
            const category = this.listItem.find(
              (item) => item.itemName === categoryValue
            );
            if (category) {
              this.$refs.hot.hotInstance.updateSettings({
                cells: (row, col) => {
                  if (col === 3) {
                    return {
                      // source: category.data,
                      source: category.invDetilBegining.map((x) => x.itemLocation.name),
                    };
                  }
                },
              });
            } else {
              // Reset the autocomplete source if the category is not found
              this.$refs.hot.hotInstance.updateSettings({
                cells: (row, col) => {
                  if (col === 3) {
                    return {
                      source: [],
                    };
                  }
                },
              });
            }
          } else if (c === 4) {
            console.log("Cell 4 selected");

            // console.log("tes 1", r, c);
            // console.log(this.excelData[r]);
            // Update the autocomplete source based on the value in the first cell
            // const categoryValue = this.$refs.hot.hotInstance.getDataAtCell(row, col); // Change ref to "hot"
            const categoryValue = this.excelData[r] ? this.excelData[r] : "";
            console.log(this.listItem.map((x) => x.itemName));
            console.log(categoryValue.itemLocation);
            console.log(this.excelData.filter((x) => x.transNoBatch));
            if (categoryValue.itemLocation) {
              this.$refs.hot.hotInstance.updateSettings({
                cells: (row, col) => {
                  if (col === 4) {
                    return {
                      // source: category.data,
                      source: categoryValue.itemLocation.invDetilBegining[0].data.map(
                        (x) => x.itemNoBatch
                      ),
                    };
                  }
                },
              });
            } else {
              // Reset the autocomplete source if the category is not found
              this.$refs.hot.hotInstance.updateSettings({
                cells: (row, col) => {
                  if (col === 4) {
                    return {
                      source: [],
                    };
                  }
                },
              });
            }
          }
        },
        licenseKey: "non-commercial-and-evaluation",

        height: 250,
        colHeaders: [],

        rowHeaderWidth: 35,
        startRows: 10,
        columns: [],
      },
    };
  },
  async mounted() {
    const apiCustomer = await getListCustomerSO();

    apiCustomer.dt = apiCustomer.dt.map((entry) => {
      const transPesanan = entry.transPesanan.map((x) => {
        const entryInTransShipment = entry.transShipment.find((shipment) =>
          shipment.transHysItem.some((y) => y.itemHysCode === x.itemCode)
        );

        const updatedItemQty =
          entryInTransShipment?.transHysItem.reduce(
            (total, y) => total + parseFloat(y.itemHysQtyRcp),
            0
          ) || 0;

        return {
          ...x,
          // itemQty: updatedItemQty.toString(),
        };
      });

      return {
        ...entry,
        transPesanan,
      };
    });

    // apiCustomer.dt = apiCustomer.dt.map((entry) => {
    //   const transPesanan = entry.transPesanan.map((x) => {
    //     return {
    //       ...x,
    //       itemQty: x.itemStatusDelivery ? (parseFloat(x.itemQty) - x.itemStatusDelivery[0].qtySend).toString() : x.itemQty

    //     }
    //   });

    //   return {
    //     ...entry,
    //     transPesanan,
    //   };
    // });

    console.log("data awal", apiCustomer);

    const mergedData = {};

    apiCustomer.dt.forEach((transaction) => {
      let { _id, transId, transIdMember, transPesanan } = transaction;
      const memberId = transIdMember._id;
      const userName = transIdMember.userName;
      const userAddress = transIdMember.userAddress;

      if (!mergedData[memberId]) {
        mergedData[memberId] = {
          id: memberId,
          userName: userName,
          data: [],
        };
      }
      transPesanan = transPesanan.map((x) => ({
        ...x,
        itemQty:
          x.itemStatusDelivery.length > 0
            ? (parseFloat(x.itemQty) - x.itemStatusDelivery[0].qtySend).toString()
            : x.itemQty,
      }));
      transPesanan = transPesanan.filter((x) => x.itemQty > 0);
      mergedData[memberId].data.push({
        _id,
        transId,
        transPesanan,
        userAddress,
      });
    });

    this.listCustomer = Object.values(mergedData);

    console.log(this.listCustomer);
    const currentDate = await getFullDate();
    const listItem = await getItemByLocation(currentDate, "product-material");
    this.listItem = this.formatListItem(listItem);
    console.log("percobaan", this.listItem);
    const tes = await getK();
    this.prList = tes.filter((x) => x.PrItemDetails.length > 0);
    this.porList = [];
    this.transDate = currentDate;
    this.hotSettings.colHeaders = this.colHeader;
    this.hotSettings.columns = this.colBody;
    this.fillTable();

    this.getContentWidth();
  },
  computed: {
    fixExcelData() {
      return this.excelData;
    },
    cobaComputed() {
      return ["halo"];
    },
    totalKredit() {
      const tes = this.excelData.filter((x) => x.Kredit !== "").map((x) => x.Kredit);
      if (tes.length > 0) {
        const sum = tes.reduce((accumulator, currentValue) => {
          const numericValue = parseFloat(currentValue);
          if (!isNaN(numericValue)) {
            return accumulator + numericValue;
          } else {
            return accumulator;
          }
        }, 0);
        return sum;
      } else {
        return 0;
      }
    },
    totalDebet() {
      const tes = this.excelData.filter((x) => x.Debet !== "").map((x) => x.Debet);
      if (tes.length > 0) {
        const sum = tes.reduce((accumulator, currentValue) => {
          // Convert the current value to a number using parseFloat
          const numericValue = parseFloat(currentValue);

          // Check if the conversion is successful (not NaN) before adding to the accumulator
          if (!isNaN(numericValue)) {
            return accumulator + numericValue;
          } else {
            return accumulator; // Ignore non-numeric values
          }
        }, 0); // Start with an initial accumulator value of 0
        return sum;
      } else {
        return 0;
      }
    },
    totalPrice() {
      const tes = this.excelData
        .filter((x) => x.transTotal !== "")
        .map((x) => x.transTotal);
      if (tes.length > 0) {
        const sum = tes.reduce((accumulator, currentValue) => {
          const numericValue = parseFloat(currentValue);

          if (!isNaN(numericValue)) {
            return accumulator + numericValue;
          } else {
            return accumulator;
          }
        }, 0);
        return sum;
      } else {
        return 0;
      }
    },
    totalPPN() {
      return (this.totalPrice * 11) / 100;
    },
    totalAmount() {
      if (this.checkedPajak) {
        return this.totalPrice + this.totalPPN;
      } else {
        return this.totalPrice;
      }
    },
  },
  methods: {
    changeCustomer() {
      console.log(this.excelData);
      const tes = this.excelData.map((obj) => {
        const newObj = {};
        for (const key in obj) {
          if (obj.hasOwnProperty(key) && key !== "key") {
            newObj[key] = "";
          } else {
            newObj[key] = obj[key];
          }
        }
        return newObj;
      });

      console.log("customer ganti", tes);
    },
    fillTable() {
      const dataObject = {};
      this.colBody.forEach((item) => {
        dataObject[item.data] = "";
      });
      for (let i = 0; i < 100; i++) {
        const key = `row_${i}`;
        const objectAdd = { ...dataObject, key };
        this.excelData.push(objectAdd);
      }
      this.excelData = [...this.excelData];
    },
    formatListItem(listItem) {
      let modifiedData = listItem.map((item) => {
        let modifiedDetilBegining = item.invDetilBegining.map((entry) => {
          return {
            itemLocation: entry.itemLocation,
            data: [
              {
                itemQty: entry.itemQty,
                itemNoBatch: entry.itemNoBatch,
              },
              // Add more entries if needed
            ],
          };
        });

        return {
          ...item,
          invDetilBegining: modifiedDetilBegining,
        };
      });

      console.log("hasilnya", modifiedData);

      return modifiedData;
    },
    filterSO(supplier) {
      console.log("list nya: ", supplier);
      if (typeof supplier === "object") {
        return supplier.data.map((x) => ({
          ...x,
          transPesanan: x.transPesanan.map((y) => {
            const data = {
              ...y,
              checkData: true,
            };
            return data;
          }),
        }));
      } else {
        return [];
      }
      // const thisRow = this.excelData[this.modalRow]
      // let cok = this.excelData.filter(x => x.transId !== '').map(x => x.transId.transId)

      // let finalData = data.filter(x => !cok.includes(x.transId))

      // if (thisRow !== '' && thisRow !== undefined) {
      //   if (thisRow.transId !== '') {
      //
      //   }
      // }
      // return finalData
    },
    optionFilter(data) {
      if (data) {
        const tes = this.excelData
          .filter((x) => x.itemHysId !== "")
          .map((x) => x.itemHysId);
        // data.PoItemDetails =  x.PoItemDetails.filter((obj) => !tes.includes(obj._id))
        return data.filter((x) => {
          const matchingCode = tes.find((codeObj) => codeObj._id === x._id);
          return !matchingCode;
        });
      }
    },
    openModal(data, row) {
      if (data.transId !== "") {
        this.modalInput = data.transId;
        this.dataItem = data.itemCode;
      }
      this.modalOpen = true;
      this.modalRow = row;
    },
    saveModalData() {
      const finalData = this.modalInput.transPesanan
        .filter((x) => x.checkData === true)
        .map((x) => ({
          ...x,
          transId: this.modalInput,
          transName: x.itemName,
          itemHysQtyReq: x.itemQty,
          itemHysUm: x.itemUm,
          itemHysShipTo:
            this.modalInput.userAddress[0].nameAddr +
            " : " +
            this.modalInput.userAddress[0].location,
        }));
      finalData.forEach((obj, index) => {
        const array1Index = this.modalRow + index;
        if (array1Index < this.excelData.length) {
          this.excelData[array1Index] = obj;
        }
        const data = {};
      });
      this.excelData = [...this.excelData];
      console.log(this.excelData);
    },
    closeModal() {
      this.modalInput = [];
      this.modalRow = null;
      this.dataItem = [];
      this.modalOpen = false;
    },
    prRenderer(instance, td, row, col, prop, value, cellProperties) {
      const rowData = this.excelData[row];
      let dataDiv = "";
      let classDiv = "d-flex align-items-center justify-content-between";
      if (rowData.transId === "" || rowData.transId === null) {
        dataDiv = null;
        classDiv = "d-flex align-items-center justify-content-between flex-row-reverse";
      } else {
        dataDiv = rowData.transId.transId;
      }

      // Clear the cell content
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }

      // Create the buttons container element
      const buttonsContainer = document.createElement("div");
      buttonsContainer.className = classDiv;
      buttonsContainer.innerHTML = dataDiv;
      // Create the first button
      const button1 = document.createElement("button");
      button1.innerHTML = '<i class="bi-eye"></i>';
      button1.className = "btn btn-xs btn-outline-secondary px-1 py-0 rounded-circle";
      button1.type = "button";
      button1.addEventListener("click", () => {
        // Handle button 1 click event
        // this.handleButtonClick(rowData, 1);
        this.openModal(rowData, row);
      });
      buttonsContainer.appendChild(button1);

      // Append the buttons container to the cell
      td.appendChild(buttonsContainer);

      // Add necessary CSS classes
      td.classList.add("htCenter"); // Adjust the class based on your styling needs
    },
    textRenderer(instance, td, row, col, prop, value, cellProperties) {
      const rowData = this.excelData[row];
      let dataDiv = "";
      let classDiv = "d-flex align-items-center justify-content-between";
      if (rowData.transId === "" || rowData.transId === null) {
        dataDiv = null;
      } else {
        dataDiv = rowData[prop];
      }

      // Clear the cell content
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }

      // Create the buttons container element
      const buttonsContainer = document.createElement("div");
      buttonsContainer.className = classDiv;
      buttonsContainer.innerHTML = dataDiv;
      // Create the first button

      // Append the buttons container to the cell
      td.appendChild(buttonsContainer);

      // Add necessary CSS classes
    },
    numberRenderer(instance, td, row, col, prop, value, cellProperties) {
      const rowData = this.excelData[row];
      let dataDiv = "";
      let classDiv = "d-flex align-items-center justify-content-between";
      if (rowData.transId === "" || rowData.transId === null) {
        dataDiv = null;
      } else {
        dataDiv = formatNumberInt(rowData[prop]);
      }

      // Clear the cell content
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }

      // Create the buttons container element
      const buttonsContainer = document.createElement("div");
      buttonsContainer.className = classDiv;
      buttonsContainer.innerHTML = dataDiv;
      // Create the first button

      // Append the buttons container to the cell
      td.appendChild(buttonsContainer);

      // Add necessary CSS classes
    },
    customLabel(option) {
      return `${option.userName}`;
    },
    customLabel1(option) {
      return `${option.itemHysName} (${option.itemHysQtyRcp})`;
    },
    customLabelKode(option) {
      return `${option.transId}`;
    },
    formatNumberInt(data) {
      return formatNumberInt(data);
    },
    validateEmptyValue(value, callback) {
      if (isNaN(value)) {
        callback(false);
      } else {
        callback(true);
      }
    },
    validateNumericValue(value, callback) {
      if (isNaN(value)) {
        callback(false);
      } else if (value < 0) {
        callback(false);
      } else {
        callback(true);
      }
    },
    onKeyDown1(event) {
      const hotInstance = this.$refs.hot.hotInstance;
      const selected = hotInstance.getSelected();
      const [startRow, startCol, endRow, endCol] = selected;

      if (
        event.key === "Tab" &&
        startRow[1] >= 4 &&
        this.excelData[startRow[0]].transDescription === ""
      ) {
        // alert('Uraian tidak Boleh Kosong!')
        hotInstance.selectCell(startRow[0], startRow[1] - 1);
      }
    },
    onChange(changes, source) {
      if (source === "edit") {
        this.formatTable(changes);
      } else if (source === "CopyPaste.paste") {
        this.formatTable(changes);
      }
    },
    formatTable(changes) {
      for (const change of changes) {
        const [row, prop, oldValue, newValue] = change;
        const baris = this.excelData[row];

        if (prop === "transLocation" && baris) {
          if (this.excelData[row].transLocation) {
            this.excelData[row].itemLocation = this.listItem.find(
              (x) => x.itemName === this.excelData[row].itemName
            );

            this.excelData[row].itemLocation.invDetilBegining = this.excelData[
              row
            ].itemLocation.invDetilBegining.filter(
              (y) => y.itemLocation.name === this.excelData[row].transLocation
            );
          }

          this.excelData = [...this.excelData];
        } else if (prop === "transNoBatch" && baris) {
          if (this.excelData[row].transLocation) {
            const tes = this.excelData[row].itemLocation.invDetilBegining[0].data.find(
              (x) => x.itemNoBatch === this.excelData[row].transNoBatch
            );
            console.log("data", tes);
            this.excelData[row].itemQtyBatch = tes.itemQty ? tes.itemQty : null;
            console.log("field", baris);
          }
          this.excelData = [...this.excelData];
        } else {
          console.log("gagal");
        }
      }
    },
    sendData(event) {
      event.preventDefault();
      console.log("customer: ", this.listCustomer);
      const tes = this.excelData
        .filter((item) => {
          const transIdIsEmpty = item.transId === "";

          // Filter out objects with both transCoaCode and transCoaName empty
          if (transIdIsEmpty) {
            return false;
          }

          return true;
        })
        .filter(
          (x) =>
            x.itemHysQtySend &&
            x.transLocation &&
            x.transNoBatch &&
            x.itemCategory !== "bundling" &&
            x.itemUm !== "set"
        );

      const aggregatedData = tes.reduce((accumulator, currentItem) => {
        const existingItem = accumulator.find(
          (item) => item.itemName === currentItem.itemName
        );

        if (existingItem) {
          // If the item already exists, add the quantities
          existingItem.itemHysQtySend = (
            parseFloat(existingItem.itemHysQtySend) +
            parseFloat(currentItem.itemHysQtySend)
          ).toString();
        } else {
          // If the item doesn't exist, add it to the accumulator
          accumulator.push({ ...currentItem });
        }

        return accumulator;
      }, []);

      console.log("send data", aggregatedData);
      console.log(tes);

      let tableValidator;
      if (aggregatedData.length > 0) {
        tableValidator = aggregatedData.every((obj) => {
          return Object.entries(obj).every(([key, value]) => {
            if (
              // key === "transName" ||
              // key === "transId" ||
              // key === "itemHysQtyReq" ||
              // key === "transLocation" ||
              // key === "transNoBatch" ||
              // key === "itemQtyBatch" ||
              // key === "itemHysQtySend" ||
              key === "itemHysUm" ||
              key === "itemCoA" ||
              key === "itemCostCenter"
            ) {
              console.log("true", key);
              return true;
            } else if (typeof value === "array" && value.length === 0) {
              console.log("false 1", key, value);
              return false;
            } else if (value === "" || value === null) {
              console.log("false 2", key, value);
              return false;
            } else if (value === "" || value === null) {
              console.log("false 2", key, value);
              return false;
            }
            return true;
          });
        });
      } else {
        tableValidator = false;
      }

      if (this.excelData.length > 0 && tableValidator) {
        // if (this.excelData.length > 0) {
        this.$swal({
          title: "Anda Yakin?",
          text: "Tekan Simpan jika anda sudah yakin!",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Simpan",
        }).then(async (result) => {
          if (result.isConfirmed) {
            alertLoading();
            try {
              // console.log("HELLO Ambil Data , " , JSON.stringify(this.excelData) )
              const data = this.excelData.filter((item) => item.itemHysQtySend > 0 );
              console.log("HOLAL",JSON.stringify(data));
              // const groupedData = data.reduce((result, item) => {
              //   console.log("grup :", result);
              //   console.log("item :", item);
              //   const existingGroup = result.find(
              //     (group) => group.itemReffCode === item.transId.transId
              //   );

              //   if (existingGroup) {
              //     console.log("ada");
              //   } else {
              //     console.log(item);
              //     result.push(item);
              //   }
              //   // if (existingGroup) {
              //   //   // existingGroup.data.push({
              //   //   //   id: item.id,
              //   //   //   nama: item.nama,
              //   //   //   data: item.data,
              //   //   // });
              //   // } else {
              //   //   result.push({
              //   //     transHysDomain: localStorage.getItem("domainCode"),
              //   //     transHysEffDate: this.transDate,
              //   //     transHysNo: this.transNoFaktur,
              //   //     transHysNotes: this.transNotes,
              //   //     transHysType: "DO",
              //   //     transHysUser: localStorage.getItem("_id"),
              //   //     transShipCustNo: this.transCustomer.id,
              //   //     transHysReff: group.transId.transId,
              //   //     transShipExpedisi: {
              //   //       namaEkpedisi: this.transExpedition,
              //   //       noResi: this.transResi,
              //   //       namaSupir: this.transSupir,
              //   //       tipeMobil: this.transKendaraan,
              //   //       platNomor: this.transPlatKendaraan,
              //   //     },
              //   //     transShipTo: group.itemHysShipTo,
              //   //   });
              //   // }

              //   // return result;
              // }, []);
              // console.log("hasinya bos", existingGroup);

              // .map((x) => {
              //   console.log("data", x);
              //   const tes = {
              //     transHysItem: {
              //       itemReffCode: x.transId.transId,
              //       itemReffName: "shipment",
              //       itemHysId: x._id,
              //       itemHysCode: x.itemCode,
              //       itemHysName: x.transName,
              //       itemHysImage: x.itemImage,
              //       itemHysUser: localStorage.getItem("_id"),
              //       itemHysUm: x.itemHysUm,
              //       itemHysQtyOrder: x.itemHysQtyReq,
              //       itemHysQtyRcp: x.itemHysQtySend,
              //       itemNoBatch: x.transNoBatch,
              //       itemLocation: x.itemLocation.invDetilBegining[0].itemLocation,
              //       itemHysMasterPrice: x.itemMasterPrice ? x.itemMasterPrice[0] : {},
              //       itemHysStdAmount: x.itemStandartHPP ? x.itemStandartHPP : "0",
              //       itemHysEffDate: this.transDate,
              //       // itemHysSpec: "",
              //       // itemHysActAmount: "",
              //       // itemHysQtyReceiptDetil: [],
              //       // itemHysQtyFrom: [],
              //       // itemHysQtyReceiptTo: [],
              //       // itemPPN: x.itemPPN,
              //       // ...x,
              //     },
              //     transHysDomain: localStorage.getItem("domainCode"),
              //     transHysEffDate: this.transDate,
              //     transHysNo: this.transNoFaktur,
              //     transHysNotes: this.transNotes,
              //     transHysType: "DO",
              //     transHysUser: localStorage.getItem("_id"),
              //     transShipCustNo: this.transCustomer.id,
              //     transHysReff: x.transId.transId,
              //     transShipExpedisi: {
              //       namaEkpedisi: this.transExpedition,
              //       noResi: this.transResi,
              //       namaSupir: this.transSupir,
              //       tipeMobil: this.transKendaraan,
              //       platNomor: this.transPlatKendaraan,
              //     },
              //     transShipTo: x.itemHysShipTo,
              //   };
              //   // data.transHysReff =
              //   console.log("tes 1.5");
              //   return tes;
              // });

              const reducedData = data.reduce((acc, transaksi) => {
                // Cari apakah sudah ada transaksi dengan ID yang sama dalam hasil reduksi
                const existingTransaksi = acc.find(
                  (item) => item.transId === transaksi.transHysReff
                );
                console.log("datanya", acc);
                if (existingTransaksi) {
                  console.log("lama", transaksi);
                  // Jika sudah ada, tambahkan data ke array yang sudah ada
                  existingTransaksi.transHysItem.push({
                    itemReffCode: transaksi.transId.transId,
                    itemReffName: "shipment",
                    itemHysId: transaksi._id,
                    itemHysCode: transaksi.itemCode,
                    itemHysName: transaksi.transName,
                    itemHysImage: transaksi.itemImage,
                    itemHysUser: localStorage.getItem("_id"),
                    itemHysUm: transaksi.itemHysUm,
                    itemHysQtyOrder: transaksi.itemHysQtyReq,
                    itemHysQtyRcp: transaksi.itemHysQtySend,
                    itemNoBatch: transaksi.transNoBatch ? transaksi.transNoBatch : "",
                    itemLocation: transaksi.itemLocation.invDetilBegining[0].itemLocation
                      ? transaksi.itemLocation.invDetilBegining[0].itemLocation
                      : {},
                    itemHysMasterPrice: transaksi.itemMasterPrice
                      ? transaksi.itemMasterPrice[0]
                      : {},
                    itemHysStdAmount: transaksi.itemStandartHPP
                      ? transaksi.itemStandartHPP
                      : "0",
                    itemHysEffDate: this.transDate,
                    itemHysCoA:transaksi.itemCoA,
                    itemHysCoAName:transaksi.itemCoAName,

                    // itemCode: transaksi.itemCode,
                    // itemCategory: transaksi.itemCategory,
                    // itemQuantityRequested: transaksi.itemQuantityRequested,
                    // itemUOM: transaksi.itemUOM,
                    // pajak: transaksi.pajak,
                    // image: transaksi.image,
                    // statusPrint: transaksi.statusPrint,
                    // statusEndMeja: transaksi.statusEndMeja,
                    // statusDelivery: transaksi.statusDelivery,
                  });
                } else {
                  console.log("baru", transaksi);
                  // Jika belum ada, tambahkan transaksi baru ke hasil reduksi
                  acc.push({
                    transHysReff: transaksi.transId.transId,
                    transHysDomain: localStorage.getItem("domainCode"),
                    transHysEffDate: this.transDate,
                    transHysNo: this.transNoFaktur,
                    transHysNotes: this.transNotes,
                    transHysType: "DO",
                    transHysUser: localStorage.getItem("_id"),
                    transShipCustNo: this.transCustomer.id,
                    transShipExpedisi: {
                      namaEkpedisi: this.transExpedition,
                      noResi: this.transResi,
                      namaSupir: this.transSupir,
                      tipeMobil: this.transKendaraan,
                      platNomor: this.transPlatKendaraan,
                    },
                    transShipTo: transaksi.itemHysShipTo,
                    transHysItem:
                      // transaksi.itemUm === "set" && transaksi.itemCategory === "bundling" ?
                      [
                        {
                          itemReffCode: transaksi.transId.transId,
                          itemReffName: "shipment",
                          itemHysId: transaksi._id,
                          itemHysCode: transaksi.itemCode,
                          itemHysName: transaksi.transName,
                          itemHysImage: transaksi.itemImage,
                          itemHysUser: localStorage.getItem("_id"),
                          itemHysUm: transaksi.itemHysUm,
                          itemHysQtyOrder: transaksi.itemHysQtyReq,
                          itemHysQtyRcp: transaksi.itemHysQtySend,
                          itemNoBatch: transaksi.transNoBatch
                            ? transaksi.transNoBatch
                            : "",
                          itemLocation: transaksi.itemLocation
                            ? transaksi.itemLocation.invDetilBegining[0].itemLocation
                            : {},
                          itemHysMasterPrice: transaksi.itemMasterPrice
                            ? transaksi.itemMasterPrice[0]
                            : {},
                          itemHysStdAmount: transaksi.itemStandartHPP
                            ? transaksi.itemStandartHPP
                            : "0",
                          itemHysEffDate: this.transDate,
                          itemHysCoA:transaksi.itemCoA,
                          itemHysCoAName:transaksi.itemCoAName,
                          // itemCode: transaksi.itemCode,
                          // itemCategory: transaksi.itemCategory,
                          // itemQuantityRequested: transaksi.itemQuantityRequested,
                          // itemUOM: transaksi.itemUOM,
                          // pajak: transaksi.pajak,
                          // image: transaksi.image,
                          // statusPrint: transaksi.statusPrint,
                          // statusEndMeja: transaksi.statusEndMeja,
                          // statusDelivery: transaksi.statusDelivery,
                        },
                      ],
                    // : [],
                  });
                }

                return acc;
              }, []);
              // console.log("berhasi", reducedData);

              // const uniqueDataMap = {};
              // const uniqueDataArray = data.filter(item => {
              //   console.log(item)
              //   if (!uniqueDataMap.hasOwnProperty(item.transHysReff)) {
              //     uniqueDataMap[item.transHysReff] = true;
              //     return true;
              //   }
              //   return false;
              // });const uniqueDataMap = {};

              // const uniqueDataArray = data.reduce((result, item) => {
              //   const key = item.transHysReff;
              //   if (!uniqueDataMap[key]) {
              //     uniqueDataMap[key] = { ...item, transHysItem: [item.transHysItem] };
              //     result.push(uniqueDataMap[key]);
              //   } else {
              //     uniqueDataMap[key].transHysItem.push(item.transHysItem);
              //   }

              //   return result;
              // }, []);
              // console.log("send data", reducedData);
              console.log("send data",JSON.stringify(reducedData));
              await addShipment(reducedData);
              successAddData();
              this.$router.push({ name: this.$route.name.replace("-add", "") });
            } catch (error) {
              errorAddData();
            }
          }
        });
      } else if (!tableValidator) {
        alert("Data di tabel masih ada yang kosong!");
      } else {
        alert("ada data wajib yang masih kosong!");
      }
    },
    getContentWidth() {
      const contentDiv = this.$refs.contentDiv;
      let totalWidth = 0;
      const tes = this.hotSettings.columns.filter((x) => !x.width).length;
      const widthTes = this.hotSettings.columns.filter((x) => x.width);
      for (const column of widthTes) {
        if (column.width) {
          totalWidth += column.width;
        }
      }
      if (contentDiv) {
        this.hotSettings.colWidths = (contentDiv.offsetWidth - 70 - totalWidth) / tes;
      }
    },
  },
};
</script>
