<template>
  <main id="content" role="main" class="main">
    <div class="w-100" ref="contentDiv"></div>
    <chatting style="z-index: 10"></chatting>
    <transition name="fade" mode="out-in">
      <div v-if="isDataLoaded === ''" key="empty">
        <loading></loading>
      </div>

      <!-- Main Content -->
      <div v-else-if="isDataLoaded" key="truthy">
        <div class="modal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Modal title</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body printable-element">
                <div class="container">
                  <div class="row" v-if="paymentData">
                    <div class="col-12 d-flex justify-content-between align-items-center">
                      <img
                        class="img-fluid w-25"
                        src="/assets/img/logoPrint/dragon1.png"
                        alt="Image Description"
                        data-hs-theme-appearance="default"
                      />
                      <div class="w-50 text-center">
                        <h1 class="">
                          <b><u>NOTA</u></b>
                        </h1>
                      </div>
                    </div>
                    <hr />
                    <div class="col-6">
                      <pre v-if="paymentData.transHysNo" class="m-0">
  No. Nota   : {{ paymentData.transShipCustNo.userPhone }}
  Tgl. Bayar : {{ formatIndDate(paymentData.transHysEffDate) }}
      </pre
                      >
                    </div>
                    <div class="col-6">
                      <pre v-if="paymentData.transHysNo" class="m-0">
  Nama Customer : {{ paymentData.transShipCustNo.userName }}
  Alamat        : <span style="white-space: normal">{{ paymentData.transShipCustNo.userAddress[0].location }}</span>
      </pre>
                    </div>
                    <!-- {{ paymentData.transHysItem }} -->
                    <table class="table table-bordered" v-if="paymentData.transHysItem">
                      <thead>
                        <tr>
                          <th class="text-center align-middle">Bayar Ke</th>
                          <th class="text-center align-middle">Keterangan</th>
                          <th class="text-center align-middle" width="">Total</th>
                          <th class="text-center align-middle" width="">Diskon</th>
                          <th class="text-center align-middle" width="">Sudah Dibayar</th>
                          <th class="text-center align-middle" width="">Sisa</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td class="fs-11 align-middle">
                            {{ payMethods.coaName }}
                          </td>
                          <td class="fs-11 align-middle">
                            Pembayaran Invoice {{ paymentData.transSINo }}
                          </td>
                          <td class="fs-11 align-middle text-end">
                            Rp.
                            {{ formatNumberInt(paymentData.transTotal) }}
                            <!-- {{ formatNumberInt(data.itemMasterPrice.priceValue) }} -->
                          </td>
                          <td class="fs-11 align-middle text-end">
                            Rp.
                            {{ formatNumberInt(discTotal(paymentData.transDiscount)) }}
                            <!-- {{ formatNumberInt(data.itemMasterPrice.priceValue) }} -->
                          </td>
                          <td class="fs-11 align-middle text-end">
                            Rp.
                            {{ formatNumberInt(paymentData.transBayar) }}
                            <!-- {{ formatNumberInt(data.itemMasterPrice.priceValue) }} -->
                          </td>
                          <td class="fs-11 align-middle text-end">
                            Rp.
                            {{
                              formatNumberInt(
                                paymentData.transTotal -
                                  paymentData.transBayar -
                                  discTotal(paymentData.transDiscount)
                              )
                            }}
                            <!-- {{ formatNumberInt(data.itemMasterPrice.priceValue) }} -->
                          </td>
                        </tr>
                        <tr>
                          <th colspan="5" class="text-end">Total</th>
                          <th colspan="1" class="text-end">
                            Rp.
                            {{
                              formatNumberInt(
                                paymentData.transTotal -
                                  paymentData.transBayar -
                                  discTotal(paymentData.transDiscount)
                              )
                            }}
                          </th>
                        </tr>
                        <tr>
                          <th colspan="5" class="text-end">Dibayar</th>
                          <th colspan="1" class="text-end">
                            Rp.
                            {{ payNominal }}
                          </th>
                        </tr>
                        <tr>
                          <th colspan="5" class="text-end">Outstanding</th>
                          <th colspan="1" class="text-end">
                            Rp.
                            {{
                              formatNumberInt(
                                paymentData.transTotal -
                                  paymentData.transBayar -
                                  discTotal(paymentData.transDiscount) -
                                  parseFloat(removeThousandFormatter(payNominal))
                              )
                            }}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                    <div class="row d-flex align-items-center justify-content-around">
                      <div class="col-3 text-center">
                        Mengetahui
                        <p class="mt-5 w-100"></p>
                        <br />
                        <hr />
                      </div>
                      <div class="col-3 text-center">
                        Kepada
                        <p class="mt-5 w-100"></p>
                        <br />
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary">Save changes</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="content container-fluid p-3 ps-0 pt-0">
          <!-- end -->
          <b-modal
            id="modal-payment"
            title="Sales Invoice Payment"
            @ok.prevent="pay()"
            size="lg"
          >
            <div class="row" v-if="paymentData">
              <div class="col-6">
                <pre v-if="paymentData.transHysNo" class="m-0">
  No. Invoice   : {{ paymentData.transSINo }}
  Tgl. Invoice  : {{ formatIndDate(paymentData.transHysEffDate) }}
      </pre
                >
              </div>
              <div class="col-6">
                <pre v-if="paymentData.transHysNo" class="m-0">
  Nama Customer : {{ paymentData.transShipCustNo.userName }}
  Alamat        : <span style="white-space: normal">{{ paymentData.transShipCustNo.userAddress[0].location }}</span>
      </pre>
              </div>
              <!-- {{ paymentData.transHysItem }} -->
              <table class="table table-bordered" v-if="paymentData.transHysItem">
                <thead>
                  <tr>
                    <th class="text-center align-middle">Kode</th>
                    <th class="text-center align-middle">Nama</th>
                    <th class="text-center align-middle">Qty</th>
                    <th class="text-center align-middle" width="150">Harga</th>
                    <th class="text-center align-middle" width="150">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="data in paymentData.transHysItem"
                    v-if="paymentData.transHysItem.length > 0"
                  >
                    <td class="fs-11 align-middle">
                      {{ data.itemHysCode }}
                    </td>
                    <td class="fs-11 align-middle">
                      {{ data.itemHysName }}
                    </td>
                    <td class="fs-11 align-middle">
                      {{ data.itemHysQtyRcp }}
                    </td>
                    <td class="fs-11 align-middle text-end">
                      {{
                        data.itemHysMasterPrice.priceValue
                          ? "Rp. " + formatNumberInt(data.itemHysMasterPrice.priceValue)
                          : 0
                      }}
                      <!-- {{ formatNumberInt(data.itemMasterPrice.priceValue) }} -->
                    </td>
                    <td class="fs-11 align-middle text-end">
                      Rp.
                      {{
                        data.itemHysMasterPrice.priceValue
                          ? formatNumberInt(
                              data.itemHysMasterPrice.priceValue * data.itemHysQtyRcp
                            )
                          : 0
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="4" class="text-end">Sub Total</th>
                    <th colspan="1" class="text-end">
                      Rp.
                      {{
                        formatNumberInt(
                          paymentData.transHysItem
                            ? totalPayment(paymentData.transHysItem)
                            : 0
                        )
                      }}
                    </th>
                  </tr>
                  <tr>
                    <th colspan="4" class="text-end">Diskon</th>
                    <th colspan="1" class="text-end">
                      Rp. {{ formatNumberInt(discTotal(paymentData.transDiscount)) }}
                    </th>
                  </tr>
                  <tr>
                    <th colspan="4" class="text-end">Total</th>
                    <th colspan="1" class="text-end">
                      Rp.
                      {{
                        formatNumberInt(
                          totalPayment(paymentData.transHysItem) -
                            discTotal(paymentData.transDiscount)
                        )
                      }}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>

            <h5 class="mt-3">Payment History</h5>
            <table class="table table-bordered m-0">
              <thead>
                <tr>
                  <th width="10px">NO.</th>
                  <th class="text-center" width="150">Trans. ID</th>
                  <th class="text-center" width="135">Tgl. Bayar</th>
                  <th class="text-center" width="150">Bayar Ke</th>
                  <th class="text-center">Keterangan</th>
                  <th class="text-center" width="135">Jumlah</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="payHistory" v-for="(dt, index) in payHistory">
                  <td>{{ index + 1 }}</td>
                  <td>{{ dt.transId }}</td>
                  <td>{{ formatIndDate(dt.transDate) }}</td>
                  <td>{{ dt.transPayTo }}</td>
                  <td>{{ dt.transDesc }}</td>
                  <td class="text-end">Rp. {{ formatNumberInt(dt.transAmount) }}</td>
                </tr>
                <!-- <tr v-for="(dt, index) in data[0].transHysItem">
                    <td>{{ index + 1 }}</td>
                    <td>{{ dt.itemHysName }}</td>
                    <td>{{ dt.itemHysQtyRcp }} {{ dt.itemHysUm }}</td>
                    <td class="text-end">Rp. 10.000.000</td>
                    <td class="text-end">Rp. 10.000.000</td>
                  </tr> -->
                <tr v-else>
                  <td colspan="6">Belum Ada Pembayaran!</td>
                </tr>
                <tr>
                  <td colspan="5" class="text-end"><b>Menunggu Pembayaran</b></td>
                  <td class="text-end">
                    <b v-if="paymentData && paymentData.transDiscount"
                      >Rp.
                      <!-- {{ formatNumberInt(grandAmount()) }} -->
                      <!-- {{ formatNumberInt(discTotal(paymentData.transDiscount)) }} -->

                      {{
                        formatNumberInt(
                          paymentData.transTotal -
                            paymentData.transBayar -
                            discTotal(paymentData.transDiscount)
                        )
                      }}
                      <!-- {{ paymentData.transBayar }}
                      {{ discTotal(paymentData.transDiscount) }} -->
                    </b>
                    <!-- <b v-if="paymentData">Rp. {{ formatNumberInt(grandAmount()  - discTotal(paymentData.transDiscount)) }}</b> -->
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex align-items-center justify-content-between">
              <div class="w-50">
                Masuk Ke :
                <multiselect
                  class="my-1"
                  v-model="payMethods"
                  :options="optionsCoa"
                  placeholder="Metode Pembayaran"
                  :multiple="false"
                  :show-labels="false"
                  label="coaName"
                >
                </multiselect>
              </div>

              <div class="w-25 ps-3">
                Jumlah Bayar :
                <input
                  v-format-number
                  class="form-control"
                  type="text"
                  v-model="payNominal"
                />
              </div>
              <div class="w-25 ps-3">
                Ongkir :
                <input
                  v-format-number
                  class="form-control"
                  type="text"
                  v-model="payOngkir"
                />
              </div>
            </div>
            <!-- <template #modal-footer>
              <div class="d-flex align-items-center justify-content-between">
                <div class="w-50">
                  Masuk Ke :
                  <multiselect
                    class="my-1"
                    v-model="payMethods"
                    :options="optionsCoa"
                    placeholder="Metode Pembayaran"
                    :multiple="false"
                    :show-labels="false"
                    label="coaName"
                  >
                  </multiselect>
                </div>

                <div class="w-50 ps-3">
                  Jumlah Bayar :
                  <input
                    v-format-number
                    class="form-control"
                    type="text"
                    v-model="payNominal"
                  />
                </div>
              </div>
            </template> -->
          </b-modal>
          <b-modal
            id="modal-edit"
            title="Edit Sales Invoice"
            @ok.prevent="edit()"
            size="lg"
          >
            <div class="row" v-if="paymentData">
              <div class="col-6">
                <pre v-if="paymentData.transHysNo" class="m-0">
  No. Invoice   : {{ paymentData.transSINo }}
  Tgl. Invoice  : {{ formatIndDate(paymentData.transHysEffDate) }}
      </pre
                >
              </div>
              <div class="col-6">
                <pre v-if="paymentData.transHysNo" class="m-0">
  Nama Customer : {{ paymentData.transShipCustNo.userName }}
  Alamat        : <span style="white-space: normal">{{ paymentData.transShipCustNo.userAddress[0].location }}</span>
      </pre>
              </div>
              <!-- {{ paymentData.transHysItem }} -->
              <table class="table table-bordered" v-if="paymentData.transHysItem">
                <thead>
                  <tr>
                    <th class="text-center align-middle">Kode</th>
                    <th class="text-center align-middle">Nama</th>
                    <th class="text-center align-middle">Qty</th>
                    <th class="text-center align-middle" width="150">Harga</th>
                    <th class="text-center align-middle" width="150">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="data in paymentData.transHysItem"
                    v-if="paymentData.transHysItem.length > 0"
                  >
                    <td class="fs-11 align-middle">
                      {{ data.itemHysCode }}
                    </td>
                    <td class="fs-11 align-middle">
                      {{ data.itemHysName }}
                    </td>
                    <td class="fs-11 align-middle">
                      {{ data.itemHysQtyRcp }}
                    </td>
                    <td class="fs-11 align-middle text-end">
                      {{
                        data.itemHysMasterPrice.priceValue
                          ? "Rp. " + formatNumberInt(data.itemHysMasterPrice.priceValue)
                          : 0
                      }}
                      <!-- {{ formatNumberInt(data.itemMasterPrice.priceValue) }} -->
                    </td>
                    <td class="fs-11 align-middle text-end">
                      Rp.
                      {{
                        data.itemHysMasterPrice.priceValue
                          ? formatNumberInt(
                              data.itemHysMasterPrice.priceValue * data.itemHysQtyRcp
                            )
                          : 0
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="4" class="text-end">Sub Total</th>
                    <th colspan="1" class="text-end">
                      Rp.
                      {{
                        formatNumberInt(
                          paymentData.transHysItem
                            ? totalPayment(paymentData.transHysItem)
                            : 0
                        )
                      }}
                    </th>
                  </tr>
                  <tr>
                    <th colspan="4" class="text-end">Diskon</th>
                    <th colspan="1" class="text-end">
                      Rp. {{ formatNumberInt(discTotal(paymentData.transDiscount)) }}
                    </th>
                  </tr>
                  <tr>
                    <th colspan="4" class="text-end">Total</th>
                    <th colspan="1" class="text-end">
                      Rp.
                      {{
                        formatNumberInt(
                          totalPayment(paymentData.transHysItem) -
                            discTotal(paymentData.transDiscount)
                        )
                      }}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>

            <h5 class="mt-3">Payment History</h5>
            <table class="table table-bordered m-0">
              <thead>
                <tr>
                  <th width="10px">NO.</th>
                  <th class="text-center" width="150">Trans. ID</th>
                  <th class="text-center" width="135">Tgl. Bayar</th>
                  <th class="text-center" width="150">Bayar Ke</th>
                  <th class="text-center">Keterangan</th>
                  <th class="text-center" width="135">Jumlah</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="payHistory" v-for="(dt, index) in payHistory">
                  <td>{{ index + 1 }}</td>
                  <td>{{ dt.transId }}</td>
                  <td>{{ formatIndDate(dt.transDate) }}</td>
                  <td>{{ dt.transPayTo }}</td>
                  <td>{{ dt.transDesc }}</td>
                  <td class="text-end">Rp. {{ formatNumberInt(dt.transAmount) }}</td>
                </tr>
                <!-- <tr v-for="(dt, index) in data[0].transHysItem">
                    <td>{{ index + 1 }}</td>
                    <td>{{ dt.itemHysName }}</td>
                    <td>{{ dt.itemHysQtyRcp }} {{ dt.itemHysUm }}</td>
                    <td class="text-end">Rp. 10.000.000</td>
                    <td class="text-end">Rp. 10.000.000</td>
                  </tr> -->
                <tr v-else>
                  <td colspan="6">Belum Ada Pembayaran!</td>
                </tr>
                <tr>
                  <td colspan="5" class="text-end"><b>Menunggu Pembayaran</b></td>
                  <td class="text-end">
                    <b v-if="paymentData">Rp. {{ formatNumberInt(grandAmount()) }}</b>
                    <!-- <b v-if="paymentData">Rp. {{ formatNumberInt(grandAmount()  - discTotal(paymentData.transDiscount)) }}</b> -->
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex align-items-center justify-content-between">
              <div class="w-25 ps-3">
                Ongkir :
                <input
                  v-format-number
                  class="form-control"
                  type="text"
                  v-model="payOngkir"
                />
              </div>
            </div>
            <!-- <template #modal-footer>
              <div class="d-flex align-items-center justify-content-between">
                <div class="w-50">
                  Masuk Ke :
                  <multiselect
                    class="my-1"
                    v-model="payMethods"
                    :options="optionsCoa"
                    placeholder="Metode Pembayaran"
                    :multiple="false"
                    :show-labels="false"
                    label="coaName"
                  >
                  </multiselect>
                </div>

                <div class="w-50 ps-3">
                  Jumlah Bayar :
                  <input
                    v-format-number
                    class="form-control"
                    type="text"
                    v-model="payNominal"
                  />
                </div>
              </div>
            </template> -->
          </b-modal>
          <div class="row my-1">
            <div class="col-4 d-flex align-items-center">
              <ul class="breadcrumb m-0">
                <li>
                  <router-link to="/dashboard">
                    <a href="#"><i class="bi-house-door"></i></a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/transaksi/po-invoice/add">
                    <a href="#">Sales Invoice</a>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="col-4 text-center">
              <span class="h2">List Sales Invoice</span>
            </div>
          </div>
          <hr class="my-2" />

          <!-- Content -->
          <template v-if="isDataLoaded">
            <div class="card">
              <div class="card-header bg-light p-2">
                <div class="row d-flex">
                  <div class="col-9 d-flex align-items-center">
                    <b>Customer :</b>
                    <multiselect
                      class="my-1 ms-2 w-50"
                      v-model="custList"
                      :options="optionsCustomer"
                      placeholder="Pilih Customer"
                      :multiple="false"
                      :show-labels="false"
                      label="userName"
                      @input="changeCust()"
                    >
                    </multiselect>
                  </div>
                  <!-- <div class="col-9 row">
                    <div
                      class="d-flex col-12 align-items-center pe-0 mt-2"
                      v-for="(data, i) in filterList"
                    >
                      <select
                        style="font-size: 12px; max-width: 125px"
                        class="form-select form-select-sm py-1 px-2"
                        v-model="data.filterBaris"
                      >
                        <option value="" v-if="!filterBaris">Filter Baris</option>
                        <option
                          v-for="micro in tableHeader"
                          :value="micro.key"
                          v-if="micro.key !== 'actions'"
                        >
                          {{ micro.label }}
                        </option>
                      </select>
                      <select
                        class="mx-2 form-select form-select-sm py-1 px-2"
                        style="font-size: 12px; max-width: 125px"
                        v-model="data.startFrom"
                      >
                        <option value="" v-if="!data.startFrom">Tipe Pencarian</option>
                        <option :value="micro" v-for="micro in optionQuery">
                          {{ micro }}
                        </option>
                      </select>
                      <div class="input-group" style="width: 150px">
                        <div class="input-group-prepend">
                          <span class="input-group-text py-1 px-2"
                            ><i class="bi-search" style="font-size: 10px"></i
                          ></span>
                        </div>
                        <input
                          type="search"
                          class="form-control form-control-sm p-1"
                          placeholder="Cari Data..."
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          style="font-size: 12px"
                          v-model="data.filter1"
                        />
                      </div>
                      <div class="input-group px-2" style="width: 150px">
                        <div class="input-group-prepend">
                          <span class="input-group-text py-1 px-2"
                            ><i class="bi-search" style="font-size: 10px"></i
                          ></span>
                        </div>
                        <input
                          type="search"
                          class="form-control form-control-sm p-1"
                          placeholder="Cari Data..."
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          style="font-size: 12px"
                          v-model="data.filter2"
                        />
                      </div>
                      <select
                        class="mx-2 form-select form-select-sm py-1"
                        style="font-size: 12px; max-width: 65px"
                        v-model="data.logic"
                      >
                        <option value="and">dan</option>
                        <option value="or">atau</option>
                      </select>
                      <i
                        class="bi-plus-circle text-primary ms-1"
                        style="cursor: pointer"
                        @click="addFilterList"
                      ></i>
                      <i
                        class="bi-x-circle text-danger ms-1"
                        style="cursor: pointer"
                        @click="removeFilterRow(i)"
                      ></i>
                    </div>
                  </div> -->

                  <div
                    class="col-3 d-flex justify-content-end mt-2 pe-3 align-items-start p-0"
                    style="z-index: 2"
                  >
                    <button
                      type="button"
                      class="btn-xs btn btn-primary"
                      @click="sendData()"
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body p-2">
                <div class="d-flex align-items-center justify-content-between">
                  <div
                    style="font-size: 11px; width: 300px"
                    class="d-flex align-items-center justify-content-between"
                  >
                    Menampilkan 1 - 10 data dari {{ rows }}
                    <select
                      class="form-select py-1"
                      aria-label="Default select example"
                      style="width: 75px; font-size: 12px; padding: 0 0.5rem !important"
                    >
                      <option v-for="data in pageList" :value="data">
                        {{ data }}
                      </option>
                    </select>
                  </div>
                  <b-pagination
                    class="m-0"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    ><template #first-text
                      ><button class="btn btn-outline-primary btn-xs">
                        First
                      </button></template
                    >
                    <template #prev-text
                      ><button class="btn btn-outline-primary btn-xs">
                        Prev
                      </button></template
                    >
                    <template #next-text
                      ><button class="btn btn-outline-primary btn-xs">
                        Next
                      </button></template
                    >
                    <template #last-text
                      ><button class="btn btn-outline-primary btn-xs">
                        Last
                      </button></template
                    >
                    <template #ellipsis-text> ... </template>
                    <template #page="{ page, active }">
                      <b v-if="active">{{ page }}</b>
                      <i v-else>{{ page }}</i>
                    </template></b-pagination
                  >
                </div>
                <hr class="my-2" />

                <!-- tabel view untuk detil -->
                <b-table
                  striped
                  hover
                  :items="trueData"
                  :fields="tableHeader"
                  label-sort-asc=""
                  label-sort-desc=""
                  class="myTable"
                >
                  <template #cell(PoSupplier)="data">
                    {{ data.item.PoSupplier }}
                  </template>
                  <template #cell(transTotal)="data">
                    {{ formatNumberInt(data.item.transTotal) }}
                  </template>
                  <template #cell(transDiskon)="data">
                    <!-- {{
                      formatNumberInt(
                        data.item.transTotal -
                          data.item.transBayar -
                          discTotal(data.item.transDiscount)
                      )
                    }} -->
                    {{ formatNumberInt(discTotal(data.item.transDiscount)) }}
                  </template>
                  <template #cell(transDibayar)="data">
                    <!-- {{
                      formatNumberInt(
                        data.item.transTotal -
                          data.item.transBayar -
                          discTotal(data.item.transDiscount)
                      )
                    }} -->
                    {{ formatNumberInt(data.item.transBayar) }}
                  </template>
                  <template #cell(transBayar)="data">
                    {{
                      formatNumberInt(
                        data.item.transTotal -
                          data.item.transBayar -
                          discTotal(data.item.transDiscount)
                      )
                    }}
                    <!-- {{ data.item.transTotal }} & {{ data.item.transBayar }} &
                    {{ discTotal(data.item.transDiscount) }} -->
                  </template>
                  <template #cell(transHysPPN)="data"> </template>
                  <template #cell(poDate)="data">
                    <!-- {{ formatIndDate(data.item.dueDate) }} -->
                  </template>
                  <template #cell(transHysItem)="data">
                    <div class="inner-table">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <!-- <th style="width: 100px">Kode</th> -->
                            <th>Nama</th>
                            <th style="width: 50px">Qty</th>
                            <th style="width: 75px">Harga</th>
                            <th style="width: 75px">Total</th>
                            <!-- Tambahkan kolom lain di sini sesuai kebutuhan -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="fs-10"
                            v-for="(item, index) in data.item.transHysItem"
                            :key="index"
                            v-if="data.item.transHysItem.length > 0"
                          >
                            <!-- <td>{{ item.itemHysCode }}</td> -->
                            <td class="text-start">{{ item.itemHysName }}</td>
                            <td>{{ item.itemHysQtyRcp }}</td>
                            <!-- <td>{{ item.itemMasterPrice.priceValue }}</td> -->
                            <!-- <td>{{ formatNumberInt(item.itemMasterPrice.priceValue) }}</td> -->
                            <td class="text-end">
                              <template v-if="item.itemHysMasterPrice.priceValue">
                                {{ formatNumberInt(item.itemHysMasterPrice.priceValue) }}
                              </template>
                              <!-- {{
                                formatNumberInt(
                                  item.itemQty * item.itemMasterPrice.priceValue
                                )
                              }} -->
                            </td>
                            <td class="text-end">
                              <template
                                v-if="
                                  item.itemHysMasterPrice.priceValue && item.itemHysQtyRcp
                                "
                              >
                                {{
                                  formatNumberInt(
                                    item.itemHysQtyRcp *
                                      item.itemHysMasterPrice.priceValue
                                  )
                                }}
                              </template>
                            </td>
                            <!-- Tambahkan kolom lain di sini sesuai kebutuhan -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>
                  <template v-slot:cell(actions)="data">
                    <input
                      type="checkbox"
                      v-model="data.item.checkBox"
                      v-if="!data.item.transSINo"
                    />
                    <b-dropdown
                      id="dropdown-option"
                      right
                      size="xs"
                      variant="primary"
                      v-if="data.item.transSINo"
                    >
                      <template #button-content class="px-1 py-0">
                        <i class="bi-gear px-1 py-0"></i>
                      </template>
                      <b-dropdown-item @click="detilAction(data.item.transSINo)"
                        >Detail Invoice</b-dropdown-item
                      >
                      <b-dropdown-item @click="handleEdit(data.item)"
                        >Edit Invoice</b-dropdown-item
                      >
                      <b-dropdown-item @click="handlePayment(data.item)"
                        >Bayar Invoice</b-dropdown-item
                      >
                    </b-dropdown>
                    <button
                      class="btn btn-primary btn-xs"
                      @click="handlePrint(data.item)"
                      v-if="data.item.transStatusBayar === 'yes'"
                    >
                      <i class="bi-printer"></i>
                    </button>
                  </template>
                </b-table>

                <template v-if="checkPI">
                  <loading></loading>
                </template>
              </div>
            </div>
          </template>
          <div v-else>Loading...</div>
        </div>
      </div>

      <!-- Error -->
      <div v-else key="other">
        <connection-timeout></connection-timeout>
      </div>
    </transition>
  </main>
</template>

<script>
import chatting from "../components/add-on/chatting";

import io from "socket.io-client";
import { API } from "@/API";
// import router from "@/router";
import {
  formatNumberInt,
  removeThousandFormatter,
  generateIdChart,
} from "../components/function/number";
import { formatIndDate, getFullDate } from "../components/function/date";
import {
  getDetailItemById,
  createSI,
  getSIDO,
  getSIDOCustomer,
  getSaldoKasbank,
  paymentSIDragon,
} from "../components/services/components";
import {
  alertLoading,
  successAddData,
  errorAddData,
} from "../components/function/sweetalert";
import loading from "../components/loading.vue";
import connectionTimeout from "../components/connectionTimeout.vue";
export default {
  components: {
    loading,
    connectionTimeout,
    chatting,
  },
  data() {
    return {
      payOngkir: "",
      itemOngkir: [],
      payHistory: [],
      payNominal: "",
      checkPI: false,
      trueData: [],
      newData: "",
      isDataLoaded: "",
      payMethods: "",
      custList: "",
      optionsCoa: [],
      optionsCustomer: [],
      paymentData: {},
      // pagination
      rows: 3,
      perPage: 10,
      currentPage: 1,
      optionQuery: ["start at", "equal", "include"],
      pageList: ["10", "25", "50", "Semua"],
      // ===
      listUser: [],
      filterList: [],
      filterText: "",
      transData: [],
      contentDivWidth: 0,
      routing: "",
      tableHeader: [],
      component: [],
      dataPI: [],
      posts: [],
    };
  },
  async mounted() {
    const timeout = 30000; // 10 seconds
    try {
      const currentDate = await getFullDate();
      this.itemOngkir = await getDetailItemById("6579f7fcba6ad78b1072a792");
      console.log("ini woi", this.itemOngkir);
      this.optionsCoa = await getSaldoKasbank(currentDate);
      this.addFilterList();
      this.tableHeader = [
        {
          key: "transSINo",
          label: "No. Invoice",
          sortable: true,
          class: "align-top fs-11",
        },
        {
          key: "transHysEffDate",
          label: "Ship Date",
          sortable: true,
          class: "align-top fs-11",
        },
        {
          key: "transHysItem",
          label: "Item",
          sortable: true,
          class: "align-top text-center fs-11",
        },
        {
          key: "transTotal",
          label: "Total",
          sortable: true,
          class: "align-top fs-11 text-center",
        },
        {
          key: "transDiskon",
          label: "Diskon",
          sortable: true,
          class: "align-top fs-11 text-center",
        },
        {
          key: "transDibayar",
          label: "Dibayar",
          sortable: true,
          class: "align-top fs-11 text-center",
        },
        {
          key: "transBayar",
          label: "Outstanding",
          sortable: true,
          class: "align-top fs-11 text-center",
        },
        {
          key: "transStatusBayar",
          label: "Status",
          sortable: true,
          class: "align-top fs-11 text-center",
        },
        {
          key: "actions",
          label: "Actions",
          class: "align-top text-center fs-11",
          thStyle: "width: 75px;",
        },
      ];
      // this.listUser = await getUser()
      // this.listSupplier = await getSupplier()
      const final = await this.getMainData();
      // Use an object to keep track of unique users based on their id
      console.log("pi", this.dataPI);
      const uniqueUsers = {};

      // Iterate through the dataArray
      this.dataPI.forEach((item) => {
        const userId = item.transShipCustNo._id;

        // If the user id is not in the uniqueUsers object, add it
        if (!uniqueUsers[userId]) {
          uniqueUsers[userId] = {
            userName: item.transShipCustNo.userName,
            _id: userId,
          };
        }
      });

      // Convert the uniqueUsers object values into an array
      const resultArray = Object.values(uniqueUsers);

      this.optionsCustomer = resultArray;

      const timeoutPromise = new Promise((resolve) => setTimeout(resolve, timeout));

      // Wait for either finalPromise or timeoutPromise to resolve
      const result = await Promise.race([final, timeoutPromise]);
      this.isDataLoaded = result !== undefined;
    } catch (error) {
      this.isDataLoaded = false;
    }

    // WebSocket connection
    const socket = io(API);
    socket.on("woUpdateApotek", async (message) => {
      console.log(message);
      if (message) {
        console.log("kiriman", message);
        if (message.pesan === "woUpdateApotek") {
          this.getMainData();
          this.newData = message.data;
        }
      }
    });
  },
  computed: {
    livePI() {
      let dataPI;
      if (this.newData !== "") {
        dataPI = this.dataPI.reverse().map((x) => {
          const data = {
            ...x,
          };
          if (x._id === this.newData) {
            data._rowVariant = "success";
          }
          return data;
        });
      } else {
        dataPI = this.dataPI.reverse();
      }
      return dataPI.filter((x) => x.transStatusSO !== "resep");
    },
    filteredTableData() {
      const filterText = this.filterText.toLowerCase().trim();
      return this.data.filter((row) => {
        for (let key in row) {
          const value = String(row[key]);
          if (value.toLowerCase().includes(filterText)) {
            return true;
          }
        }
        return false;
      });
    },
  },
  methods: {
    removeThousandFormatter(data) {
      return removeThousandFormatter(data);
    },
    grandAmount() {
      // let tes = 0;
      let tes = this.totalPayment(this.paymentData.transHysItem);
      if (tes) {
        this.payHistory.forEach((x) => {
          tes -= parseFloat(x.transAmount);
        });
      }
      return tes;
    },
    discTotal(data) {
      let totalData = 0;
      console.log("disc total:", data);
      if (data.length > 0) {
        console.log("tes", data);
        // Calculate the sum of itemHysStdAmount
        // Calculate the sum of (itemHysQtyRcp * itemHysMasterPrice.priceValue)
        let sumDisc = data.reduce((acc, item) => {
          console.log(acc, item);
          let qtyRcp = parseFloat(item.totalItem || 0);
          let priceValue = parseFloat(item.totalTrans || 0);
          return acc + qtyRcp + priceValue;
        }, 0);
        // Add both totalHysStdAmount and totalSisaBayar properties to the main object
        console.log(sumDisc);
        totalData += sumDisc;
      }
      return totalData;
    },
    totalPayment(data) {
      console.log("tes woi", data);
      if (data) {
        console.log("tes lagi");
        const totalValue = data.reduce((accumulator, currentItem) => {
          // Convert data.value to a number and multiply by qty
          const itemValue =
            Number(currentItem.itemHysMasterPrice.priceValue || 0) *
            Number(currentItem.itemHysQtyRcp || 0);

          // Add the calculated value to the accumulator
          return accumulator + itemValue;
        }, 0);
        return totalValue;
      } else {
        console.log("gak bahaya ta");
        return 0;
      }
      console.log("cok");
    },
    sendData() {
      console.log(this.trueData);
      const tes = this.trueData
        .map((x) => ({
          ...x,
          transHysUser: x.transHysUser._id,
          transShipCustNo: x.transShipCustNo._id,
          // transDiskon:
          //   x.transDiscount.length > 0
          //     ? x.transDiscount[0].totalItem + x.transDiscount[0].totalTrans
          //     : 0,
          transDiskon:
            x.transDiscount.length > 0
              ? parseFloat(x.transDiscount[0].totalItem) +
                parseFloat(x.transDiscount[0].totalTrans)
              : 0,
        }))
        .filter((x) => x.checkBox === true);

      tes.forEach((obj) => {
        // Calculate the sum of itemHysStdAmount
        let sumStdAmount = obj.transHysItem.reduce(
          (acc, item) => acc + parseFloat(item.itemHysStdAmount || 0),
          0
        );

        // Calculate the sum of (itemHysQtyRcp * itemHysMasterPrice.priceValue)
        let sumSisaBayar = obj.transHysItem.reduce((acc, item) => {
          let qtyRcp = parseFloat(item.itemHysQtyRcp || 0);
          let priceValue = parseFloat(item.itemHysMasterPrice.priceValue || 0);
          return acc + qtyRcp * priceValue;
        }, 0);

        let sumPPN = obj.transHysItem.reduce((acc, item) => {
          let qtyRcp = parseFloat(item.itemHysQtyRcp || 0);
          let priceValue = parseFloat(item.itemHysMasterPrice.priceValue || 0);
          // let qtyPajak = parseFloat(item.itemPajak[0].amountPajak || 0);
          return acc + (qtyRcp * priceValue) / 100;
          // return acc + (qtyRcp * priceValue * qtyPajak) / 100;
        }, 0);

        // Add both totalHysStdAmount and totalSisaBayar properties to the main object
        obj.totalHysStdAmount = sumStdAmount;
        obj.sisaBayar = sumSisaBayar;
        // obj.ppnValue = obj.transPPN === "yes" ? sumPPN : 0;
      });

      console.log(tes);
      // console.log(JSON.stringify(tes));
      if (tes.length > 0) {
        this.$swal({
          title: "Anda Yakin?",
          text: "Tekan Simpan jika anda sudah yakin!",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Simpan",
        }).then(async (result) => {
          if (result.isConfirmed) {
            alertLoading();
            try {
              console.log("send data", tes);
              await createSI(tes);
              successAddData();
              this.changeCust();
              // this.$router.push({ name: this.$route.name.replace("-add", "") });
            } catch (error) {
              errorAddData();
            }
          }
        });
      } else if (this.totalKredit !== this.totalDebet) {
        alert("Data Tidak Balance!");
      } else {
        alert("Tidak ada data yang anda pilih!");
      }
    },
    async changeCust() {
      // console.log("berhasil dan ", this.custList);
      if (this.custList) {
        this.trueData = [];
        this.checkPI = true;
        // console.log("woi", this.custList._id);
        const data = await getSIDOCustomer(this.custList._id);
        // console.log("halo", data);
        if (data[0]) {
          data[0].transHysItem = data[0].transHysItem.reverse();
        }
        this.checkPI = false;
        this.trueData = data;
        // console.log("datanya", data);
        // console.log(JSON.stringify(data));
      } else {
        // console.log("hai");
        this.trueData = [];
      }
    },
    numberToText(data) {
      return numberToText(data);
    },
    printReport() {
      console.log(this.getReportContent());
      const printWindow = window.open("", "_blank");
      const printDocument = `
        <html>
          <head>
	          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet">
          <style>
              th {
                font-weight: 500
              }
              tr {
                font-size: 10px
              }
              body {
                padding-left: 1px;
                width: 5.65in;
              }
          </style>
            </head>

          <body>
            ${this.getReportContent()}
          </body>
        </html>
      `;
      printWindow.document.write(printDocument);
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
        printWindow.close();
      };
    },
    getReportContent() {
      const reportView = document.querySelector(".printable-element");
      if (reportView) {
        return reportView.innerHTML;
      }
      return "No content found.";
    },
    async connectToPrinter() {
      try {
        const device = await navigator.usb.requestDevice({
          filters: [{ vendorId: 0x04b8 }],
        });
        await device.open();
        await device.selectConfiguration(1);
        await device.claimInterface(0);

        // Define the title and its font size
        const title = "KLINIK UTAMA MUTIARA AISHA"; // Change \x32 to another value for different font sizes
        const address = "JL. KI HAJAR DEWANTARA 04 SELOSARI MAGETAN";
        const phone = "082331 200 500";
        // Define column names and fixed column widths
        const terbilang = numberToText(this.paymentData.grandTotal);
        const footer = ["Terima kasih atas kunjungannya", "Semoga Lekas Sembuh"];
        const columns = [
          { name: "TRANSAKSI", width: 35 },
          { name: "QTY", width: 5 },
          { name: "DISC", width: 15 },
          { name: "JUMLAH", width: 15 },
        ];
        const infoPasien = [
          { name: "", width: 15 },
          { name: "", width: 55 },
        ];
        const totalData = [
          { name: "", width: 15 },
          { name: "", width: 25 },
          { name: "", width: 10 },
          { name: "", width: 20 },
        ];
        // Calculate the total width of the dashed line based on column widths
        const totalWidth = columns.reduce((acc, column) => acc + column.width, 0);

        // Create the dashed line based on the total width
        const equalLine = "=".repeat(totalWidth);
        const dashedLine = "-".repeat(totalWidth);

        // Build the header row with fixed widths
        const header = columns
          .map((column) => column.name.padEnd(column.width))
          .join("  ");

        // Define your dynamic table data

        let tableData = [];
        this.paymentData.transHysItem.forEach((i) => {
          tableData.push([
            i.itemName,
            i.itemQty.toString(),
            "0",
            (i.itemQty * i.itemMasterPrice.priceValue).toString(),
          ]);
        });
        console.log(tableData);

        // const tableData = [
        //   ["Pemeriksaan Jantung", "1", 0, "50.000"],
        //   ["Pemeriksaan Darah", "1", 50.0, "1.000.000"],
        // ];
        // console.log(tableData);

        // const tableData = []
        // this.paymentData.transHysItem.forEach(i => {
        //   const data = [i.itemName, i.itemQty, "0", ]
        // });
        const tablePasien = [
          ["Nm. Pasien", ": " + this.paymentData.transIdMember.userName],
          ["Nomor RM", ": " + this.paymentData.transIdMember.userCode],
          ["Alamat", ": " + this.paymentData.transIdMember.userAddress[0].location],
          ["Dokter", ": " + this.paymentData.transKasir.userName],
          ["Poli", ": "],
        ];
        const tableTotal = [
          [
            "Tanggal",
            // ": " + formatNumberInt(this.paymentData.transDate),
            "TOTAL",
            // ": " + formatNumberInt(this.paymentData.grandTotal),
          ],
          ["No. Nota", ": " + this.paymentData.transNoSO, "DISC", ": 0"],
          // ["", "", "SUBTOTAL", ": " + formatNumberInt(this.paymentData.grandTotal)],
        ];

        // Build the table content with fixed column widths
        const listPasien = [
          ...tablePasien.map((row) => {
            return row
              .map((cell, index) => cell.padEnd(infoPasien[index].width))
              .join("  ");
          }),
        ].join("\n");
        const listTotal = [
          ...tableTotal.map((row) => {
            return row
              .map((cell, index) => cell.padEnd(totalData[index].width))
              .join("  ");
          }),
          equalLine,
        ].join("\n");
        const tableContent = [
          equalLine,
          header,
          dashedLine,
          ...tableData.map((row) => {
            return row.map((cell, index) => cell.padEnd(columns[index].width)).join("  ");
          }),
          dashedLine,
        ].join("\n");

        const tableCommand = new TextEncoder().encode(
          "\x1B@" +
            "\x1B!" +
            "\x2D\x40" +
            title +
            "\x0A" +
            "\x1B@" +
            "\x1B!" +
            "\x1D!" +
            address +
            "\x0A" +
            phone +
            "\x0A" +
            "\x0A" +
            "\x1B!" +
            "\x1D!\x00" +
            listPasien +
            "\x0A" +
            tableContent +
            "\x0A" +
            listTotal +
            "\x0A" +
            terbilang +
            "\x0A" +
            "\x0A" +
            "\x0A" +
            footer[0] +
            "\x0A" +
            footer[1] +
            "\x1B!" +
            "\x1Bd\x01"
        );

        await device.transferOut(1, tableCommand);
        await device.close();
      } catch (error) {
        console.error("Error:", error);
      }
    },
    async getMainData() {
      this.dataPI = await getSIDO();
      return true;
    },
    // async pay() {
    //   const data = {
    //     transStatusSO: "closed",
    //     transStatusBayar: "yes",
    //     transPaymentType: this.payMethods,
    //   };
    //   await paymentSIDragon(data, this.paymentData._id);
    // },
    edit(event) {
      console.log("ini edit!");
    },
    pay(event) {
      let remainingPayment = removeThousandFormatter(this.payNominal);
      const transDetilSOBaru = [this.paymentData].flatMap((item) => {
        return item.transDetilSO.map((detilSO) => {
          const transBayar = Math.min(
            remainingPayment,
            detilSO.transTotal - detilSO.transBayar
          );
          if (detilSO.transBayar + transBayar === detilSO.transTotal) {
            detilSO.transStatusSO = "yes";
          } else if (detilSO.transBayar + transBayar === 0) {
            detilSO.transStatusSO = "no";
          } else {
            detilSO.transStatusSO = "partial";
          }
          detilSO.transBayar = transBayar;
          remainingPayment -= transBayar;
          return {
            ...detilSO,
            transBayar: detilSO.transBayar,
          };
        });
      });

      console.log(transDetilSOBaru);
      console.log(
        this.paymentData,
        this.payMethods,
        parseFloat(removeThousandFormatter(this.payNominal))
      );
      if (
        this.payNominal &&
        parseFloat(removeThousandFormatter(this.payNominal)) <= this.grandAmount()
      ) {
        this.$swal({
          title: "Anda Yakin?",
          text: "Tekan Bayar jika anda sudah yakin!",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Bayar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.alertLoading();
            try {
              console.log(this.paymentData);
              const tes = this.paymentData;
              tes.transDetilSO = transDetilSOBaru;
              tes.transHysUser = tes.transHysUser._id;
              tes.transShipCustNo = tes.transShipCustNo._id;
              tes.transBayar += parseFloat(removeThousandFormatter(this.payNominal));
              const data = {
                ...tes,
                coaCode: this.payMethods.coaCode,
                coaName: this.payMethods.coaName,
                // transPaymentType: {
                //   _id: this.payMethods._id,
                //   coaCode: this.payMethods.coaCode,
                //   coaName: this.payMethods.coaName,
                // },
                transPaymentValue: parseFloat(removeThousandFormatter(this.payNominal)),
                transDiskon: 0,
              };
              const data1 = this.itemOngkir.map((x) => ({
                itemHysCode: x.itemCode,
                itemHysId: x._id,
                itemHysImage: x.itemImage,
                itemHysMasterPrice: {
                  priceCode: generateIdChart(16),
                  priceDiscount: {
                    qtyDiscount: 0,
                    typeDiscount: "amount",
                    valueDiscount: 0,
                  },
                  priceName: "Custom",
                  priceGroup: "normal",
                  priceValue: removeThousandFormatter(this.payOngkir),
                },
                itemHysName: x.itemName,
                itemHysQtyFrom: "1",
                itemHysQtyOrder: "1",
                itemHysQtyRcp: "1",
                itemHysQtyReceiptTo: [],
                itemHysStdAmount: x.itemStandartHPP,
                itemHysUm: x.itemUM,
                itemHysUser: localStorage.getItem("_id"),
                itemPajak: [],
                itemReffCode: this.PaymentData
                  ? this.PaymentData.transHysItem[0].itemReffCode
                  : "",
                itemReffName: this.PaymentData
                  ? this.PaymentData.transHysItem[0].itemReffName
                  : "",
                _id: generateIdChart(16),
              }));
              console.log("mau kirim data:", data);
              data1.forEach((element) => {
                data.transHysItem.push(element);
              });
              console.log("mau kirim:", data);
              // console.log(this.paymentData);
              console.log(this.payOngkir);
              // console.log(JSON.stringify(data));
              await paymentSIDragon(data);
              this.successAdd();
              this.changeCust();
              this.printReport();
              // this.connectToPrinter();
              this.$bvModal.hide("modal-payment");
            } catch (error) {
              alert(error);
            }
          }
        });
      } else if (
        parseFloat(removeThousandFormatter(this.payNominal)) > this.grandAmount()
      ) {
        alert("Pembayaran melebihi jumlah yang harus dibayar!");
      } else {
        alert("Jumlah Bayar Tidak Boleh Kosong!");
      }
    },
    alertLoading() {
      this.$swal({
        title: "Loading...",
        text: "Mohon Ditunggu ya.",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        willOpen: () => {
          this.$swal.showLoading();
        },
      });
    },
    successAdd() {
      this.$swal("Berhasil!", "Pembayaran Berhasil!", "success");
    },
    handleEdit(data) {
      console.log(data);
      this.$bvModal.show("modal-edit");
      // this.paymentData = data.transHysItem.reverse();
      this.paymentData = data;
      console.log("ini edit data", this.paymentData);
      this.payHistory = [];
      if (data) {
        const tes = {
          transId: "-",
          transDate: data.transHysEffDate,
          transPayTo: "-",
          transDesc: "DP",
          transAmount: data.transDPValue,
        };
        this.payHistory.push(tes);
      }
    },
    handlePayment(data) {
      console.log(data);
      this.$bvModal.show("modal-payment");
      // this.paymentData = data.transHysItem.reverse();
      this.paymentData = data;
      console.log("ini payment data", this.paymentData);
      this.payHistory = [];
      if (data) {
        const tes = {
          transId: "-",
          transDate: data.transHysEffDate,
          transPayTo: "-",
          transDesc: "DP",
          transAmount: data.transDPValue,
        };
        this.payHistory.push(tes);
      }
    },
    handlePrint(data) {
      console.log(data);
      this.paymentData = data;
      this.$nextTick(() => {
        this.printReport();
      });
    },
    formatIndDate(data) {
      return formatIndDate(data);
    },
    formatNumberInt(data) {
      return formatNumberInt(data);
    },
    addFilterList() {
      const data = {
        filterBaris: "",
        startFrom: "",
        filter1: "",
        logic: "",
        filter2: "",
      };
      this.filterList.push(data);
    },

    removeFilterRow(data) {
      this.filterList.splice(data, 1);
    },
    detilAction(data) {
      console.log(data);
      // alert(ponum);
      // const data = JSON.stringify(ponum);
      this.$router.push({ name: "shipment-manufacture-detail", query: { data } });
    },
    paymentAction(ponum) {
      // alert(ponum);
      this.$router.push({
        name: "si-payment-confirm",
        query: { data: JSON.stringify(ponum) },
      });
    },
  },
};
</script>
<style scope>
.b-pagination li {
  display: flex;
  align-items: center;
}

.b-pagination li .page-link {
  padding: 0.25rem 0;
  margin: 0 !important;
}

.myTable {
  border-collapse: collapse;
  margin: 0 !important;
  /* Hapus margin */
  padding: 0 !important;
  /* Hapus padding */
}

.myTable thead {
  /* Define your custom header class styles here */
  background-color: #f0f0f0;
  /* Change to your desired background color */
  color: rgb(240, 26, 26);
  /* Change to your desired text color */
  font-weight: bold;
  /* Change to your desired font weight */
}

.inner-table table {
  border-collapse: collapse;
  margin: 0;
  /* Hapus margin */
  padding: 0;
  /* Hapus padding */
}
</style>
