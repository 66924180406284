var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c("div", { ref: "contentDiv", staticClass: "w-100" }),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.isDataLoaded === ""
          ? _c("div", { key: "empty" }, [_c("loading")], 1)
          : _vm.isDataLoaded
          ? _c("div", { key: "truthy" }, [
              _vm.showImage
                ? _c("img", {
                    staticClass: "d-none",
                    attrs: { src: _vm.printImage, alt: "" },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "content container-fluid p-3 ps-0 pt-0" },
                [
                  _c("ul", { staticClass: "breadcrumb m-1 mt-0" }, [
                    _c("li", [
                      _c("a", { attrs: { href: "#" } }, [
                        _c("i", { staticClass: "bi-house-door" }),
                      ]),
                    ]),
                    _c("li", [_vm._v(_vm._s(_vm.posts.transTitle))]),
                  ]),
                  _vm.isDataLoaded
                    ? [
                        _c("tableTransaksi", {
                          attrs: {
                            data: _vm.data,
                            fields: _vm.tableFields,
                            settings: _vm.hotSettings,
                            routingLink: _vm.routing,
                            tableWidth: _vm.contentDivWidth,
                            filterText: _vm.filterText,
                          },
                        }),
                      ]
                    : _c("div", [_vm._v("Loading...")]),
                ],
                2
              ),
            ])
          : _c("div", { key: "other" }, [_c("connection-timeout")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }