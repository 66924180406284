var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report", staticStyle: { "font-size": "10px" } },
    [
      _vm.data.length > 0
        ? _c(
            "div",
            [
              _vm._l(_vm.testIn, function (entry) {
                return [
                  _c(
                    "table",
                    {
                      staticClass:
                        "table table-bordered mb-1 z-1 position-relative",
                    },
                    [
                      _c("thead", { staticClass: "sticky-element" }, [
                        _c("tr", { staticClass: "bg-secondary" }, [
                          _c(
                            "th",
                            {
                              staticClass: "text-white",
                              staticStyle: { "font-size": "10px" },
                              attrs: { colspan: "2", scope: "col" },
                            },
                            [
                              _c("b", [_vm._v("Kode Item : ")]),
                              _vm._v(" " + _vm._s(entry.itemCode) + " "),
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-white",
                              staticStyle: { "font-size": "10px" },
                              attrs: { colspan: "6", scope: "col" },
                            },
                            [
                              _c("b", [_vm._v("Nama Item : ")]),
                              _vm._v(" " + _vm._s(entry.itemName) + " "),
                            ]
                          ),
                          _vm._m(0, true),
                          _c(
                            "th",
                            {
                              staticClass: "text-white",
                              staticStyle: { "font-size": "10px" },
                              attrs: { scope: "col", width: "90" },
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  _vm._s(entry.itemInvStock.invBegining) +
                                    " " +
                                    _vm._s(entry.itemInvStock.invUM)
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._m(1, true),
                      ]),
                      _c(
                        "tbody",
                        [
                          entry.itemInvStock.invIn.length > 0
                            ? _vm._l(
                                entry.itemInvStock.invIn,
                                function (trans) {
                                  return _c("tr", [
                                    _c("td", { staticClass: "text-center" }, [
                                      _vm._v(_vm._s(trans.transHysNo)),
                                    ]),
                                    _c(
                                      "td",
                                      [
                                        trans.itemLocation
                                          ? [
                                              _vm._v(
                                                _vm._s(trans.itemLocation.name)
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _c("td", { staticClass: "text-center" }, [
                                      _vm._v(_vm._s(trans.transHysEffDate)),
                                    ]),
                                    _c("td", { staticClass: "text-center" }, [
                                      _vm._v(_vm._s(trans.transHysReff)),
                                    ]),
                                    _c("td", { staticClass: "text-center" }, [
                                      _vm._v(_vm._s(trans.itemNoBatch)),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(trans.transHysType)),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(trans.transHysNotes)),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(trans.itemHysQtyRcp) +
                                          " " +
                                          _vm._s(trans.itemHysUm)
                                      ),
                                    ]),
                                    _c("td", [_vm._v("-")]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(trans.itemHysQtyRcp) +
                                          " " +
                                          _vm._s(trans.itemHysUm)
                                      ),
                                    ]),
                                  ])
                                }
                              )
                            : _vm._e(),
                          entry.itemInvStock.invOut.length > 0
                            ? _vm._l(
                                entry.itemInvStock.invOut,
                                function (trans) {
                                  return _c("tr", [
                                    _c("td", { staticClass: "text-center" }, [
                                      _vm._v(_vm._s(trans.transHysNo)),
                                    ]),
                                    _c(
                                      "td",
                                      [
                                        trans.itemLocation
                                          ? [
                                              _vm._v(
                                                _vm._s(trans.itemLocation.name)
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _c("td", { staticClass: "text-center" }, [
                                      _vm._v(_vm._s(trans.transHysEffDate)),
                                    ]),
                                    _c("td", { staticClass: "text-center" }, [
                                      _vm._v(_vm._s(trans.transHysReff)),
                                    ]),
                                    _c("td", { staticClass: "text-center" }, [
                                      _vm._v(_vm._s(trans.itemNoBatch)),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(trans.transHysType)),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(trans.transHysNotes)),
                                    ]),
                                    _c("td", [_vm._v("-")]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(trans.itemHysQtyRcp) +
                                          " " +
                                          _vm._s(trans.itemHysUm)
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(trans.itemHysQtyRcp) +
                                          " " +
                                          _vm._s(trans.itemHysUm)
                                      ),
                                    ]),
                                  ])
                                }
                              )
                            : _vm._e(),
                          entry.itemInvStock.invOut.length === 0 &&
                          entry.itemInvStock.invIn.length === 0
                            ? [_vm._m(2, true)]
                            : _vm._e(),
                          _c("tr", [
                            _c("td", { attrs: { colspan: "8" } }),
                            _vm._m(3, true),
                            _c("td", [
                              _c("b", [
                                _vm._v(
                                  _vm._s(entry.itemInvStock.invEnd) +
                                    " " +
                                    _vm._s(entry.itemInvStock.invUM)
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ]
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      {
        staticClass: "text-white",
        staticStyle: { "font-size": "10px" },
        attrs: { colspan: "1", scope: "col" },
      },
      [_c("b", [_vm._v("Stok Awal : ")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "th",
        {
          staticClass: "text-center",
          staticStyle: { "font-size": "10px" },
          attrs: { scope: "col", width: "110" },
        },
        [_c("b", [_vm._v("No. Histori")])]
      ),
      _c(
        "th",
        {
          staticClass: "text-center",
          staticStyle: { "font-size": "10px" },
          attrs: { scope: "col", width: "130" },
        },
        [_c("b", [_vm._v("Lokasi")])]
      ),
      _c(
        "th",
        {
          staticClass: "text-center",
          staticStyle: { "font-size": "10px" },
          attrs: { scope: "col", width: "80" },
        },
        [_c("b", [_vm._v("Tgl. Trans")])]
      ),
      _c(
        "th",
        {
          staticClass: "text-center",
          staticStyle: { "font-size": "10px" },
          attrs: { scope: "col", width: "80" },
        },
        [_c("b", [_vm._v("Reff")])]
      ),
      _c(
        "th",
        {
          staticClass: "text-center",
          staticStyle: { "font-size": "10px" },
          attrs: { scope: "col", width: "80" },
        },
        [_c("b", [_vm._v("Batch")])]
      ),
      _c(
        "th",
        {
          staticClass: "text-center",
          staticStyle: { "font-size": "10px" },
          attrs: { scope: "col", width: "90" },
        },
        [_c("b", [_vm._v("Tipe Trans")])]
      ),
      _c(
        "th",
        {
          staticClass: "text-center",
          staticStyle: { "font-size": "10px" },
          attrs: { scope: "col" },
        },
        [_c("b", [_vm._v("Uraian")])]
      ),
      _c(
        "th",
        {
          staticClass: "text-center",
          staticStyle: { "font-size": "10px" },
          attrs: { scope: "col", width: "80" },
        },
        [_c("b", [_vm._v("Masuk")])]
      ),
      _c(
        "th",
        {
          staticClass: "text-center",
          staticStyle: { "font-size": "10px" },
          attrs: { scope: "col", width: "80" },
        },
        [_c("b", [_vm._v("Keluar")])]
      ),
      _c(
        "th",
        {
          staticClass: "text-center",
          staticStyle: { "font-size": "10px" },
          attrs: { scope: "col", width: "80" },
        },
        [_c("b", [_vm._v("Total")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("td", { attrs: { colspan: "8" } }, [_vm._v("-")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { attrs: { colspan: "1" } }, [
      _c("b", [_vm._v("STOK AKHIR :")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }