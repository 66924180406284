<template>
  <main id="content" role="main" class="main">
    <div class="w-100" ref="contentDiv"></div>
    <transition name="fade" mode="out-in">
      <div v-if="isDataLoaded === ''" key="empty">
        <loading></loading>
      </div>

      <!-- Main Content -->
      <div v-else-if="isDataLoaded" key="truthy">
        <div class="content container-fluid p-3 ps-0 py-0">
          <transaksiAddStockOpname
            :posts="posts"
            :component="refreshComponent"
            :hotSettings="hotSettings"
            :widthTable="contentDivWidth"
            :excelData="excelData"
            :refreshMethods="onRefresh()"
          ></transaksiAddStockOpname>
        </div>
      </div>

      <!-- Error -->
      <div v-else key="other">
        <connection-timeout></connection-timeout>
      </div>
    </transition>
  </main>
</template>

<script>
import {
  getFieldTable,
  getComponentAdd,
  dataTableAdd,
  refreshValue,
  getContentWidth,
} from "../../components/services/transaksi";
import {
  get1,
  getCustomerLite,
  getMappingLocation,
} from "../../components/services/components";
import { getFullDate, getMonth } from "../../components/function/date";
import { getAllWObyDomain } from "../../components/services/transComponent";
import transaksiAddStockOpname from "../../components/transaksi/transaksiAddStockOpname.vue";

import loading from "../../components/loading.vue";
import connectionTimeout from "../../components/connectionTimeout.vue";
export default {
  components: {
    loading,
    connectionTimeout,
    transaksiAddStockOpname,
  },
  data() {
    return {
      listPasien: [],
      isDataLoaded: "",
      qtyWO: [],
      contentDivWidth: 0,
      excelData: [],
      hotSettings: {
        readOnly: true,
        afterChange: this.onRefresh,
        licenseKey: "non-commercial-and-evaluation",
        height: 200,
        colHeaders: true,

        rowHeaderWidth: 35,
        startRows: 10,
        columns: [{ data: "tes", type: "numeric" }],
        // Other Handsontable settings
      },
      component: [],
      posts: [],
      itemTable: [],
    };
  },
  async mounted() {
    const timeout = 30000; // 10 seconds
    try {
      this.posts = await get1(this.$route.name);
      this.component = await getComponentAdd(this.posts);
      // const qtyWO = []
      this.component
        .filter((x) => x.componentName === "fieldHeader")
        .forEach((item) => {
          // console.log('data',item.componentMicro.find(dataItem => dataItem.key ==='productQty'))
          this.qtyWO = item.componentMicro.filter(
            (dataItem) => dataItem.key === "productQty"
          );
          // qrtWO.push(...matchingData);
        });
      // console.log('cok', this.qtyWO)
      this.hotSettings.columns = await getFieldTable(this.component);
      console.log(this.hotSettings.columns);
      // this.hotSettings.columns.find(x => x.data === 'qty').renderer = this.qtyRenderer
      await dataTableAdd(this.excelData, this.hotSettings.columns);
      this.onRefresh();
      this.contentDivWidth = await getContentWidth(
        this.$refs.contentDiv,
        this.hotSettings.columns
      );

      const pasien = await getCustomerLite();
      this.listPasien = pasien;

      this.moreFunction();
      const timeoutPromise = new Promise((resolve) => setTimeout(resolve, timeout));

      // Wait for either finalPromise or timeoutPromise to resolve
      const result = await Promise.race([this.contentDivWidth, timeoutPromise]);
      this.isDataLoaded = result !== undefined;
    } catch (error) {
      this.isDataLoaded = false;
    }
  },
  computed: {
    refreshComponent() {
      return this.component.map((item) => ({
        ...item,
      }));
    },
  },
  methods: {
    async moreFunction() {
      const tes = localStorage.getItem("userName");
      const currentDate = await getFullDate();
      const apiItem = await getMappingLocation();
      console.log(apiItem);
      this.component
        .filter((x) => x.componentName === "fieldHeader")
        .forEach((y) => {
          y.componentMicro.find((z) => {
            if (z.microType === "multiselect") {
              if (z.key === "productLocation") {
                z.microOptions = apiItem;
              }
            } else if (z.key === "pasienName") {
              z.value = tes;
            } else if (z.key === "opnameDate") {
              z.value = currentDate;
            }
          });
        });
    },
    onRefresh() {
      refreshValue(this.excelData, this.component);
    },
  },
};
</script>
