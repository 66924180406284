var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c(
        "form",
        {
          ref: "contentDiv",
          staticClass: "content container-fluid p-3 ps-0 py-0",
          on: { submit: _vm.sendData },
        },
        [
          _c("div", { staticClass: "row my-1" }, [
            _c("div", { staticClass: "col-4 d-flex align-items-center" }, [
              _c("ul", { staticClass: "breadcrumb m-0" }, [
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: "/dashboard" } }, [
                      _c("a", { attrs: { href: "#" } }, [
                        _c("i", { staticClass: "bi-house-door" }),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: _vm.$route.path.replace("/add", "") } },
                      [_c("a", { attrs: { href: "#" } }, [_vm._v("Shipment")])]
                    ),
                  ],
                  1
                ),
                _c("li", [_vm._v("Tambah")]),
              ]),
            ]),
            _vm._m(0),
            _c("div", {
              staticClass:
                "col-1 d-flex justify-content-end align-items-center",
            }),
            _c(
              "div",
              {
                staticClass:
                  "col-3 d-flex justify-content-end align-items-center",
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: _vm.$route.path.replace("/add", "") } },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-outline-secondary btn-xs py-1 me-2",
                        attrs: { type: "button" },
                      },
                      [
                        _c("i", { staticClass: "bi-chevron-left" }),
                        _vm._v("Kembali "),
                      ]
                    ),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-xs py-1",
                    attrs: { type: "submit" },
                  },
                  [_vm._v("Simpan")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "main-data mt-4" }, [
            _c(
              "div",
              {
                staticClass: "row scale-9 position-relative",
                staticStyle: { width: "114%", "z-index": "1" },
              },
              [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "card h-100" }, [
                    _c("div", { staticClass: "row px-3 py-1" }, [
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(1),
                        _c(
                          "div",
                          { staticClass: "col-sm-8 col-md-9 pe-0" },
                          [
                            _c("multiselect", {
                              staticClass: "my-1",
                              attrs: {
                                options: _vm.listCustomer,
                                placeholder: "Select one",
                                multiple: false,
                                "show-labels": false,
                                "custom-label": _vm.customLabel,
                                "track-by": "id",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.changeCustomer()
                                },
                              },
                              model: {
                                value: _vm.transCustomer,
                                callback: function ($$v) {
                                  _vm.transCustomer = $$v
                                },
                                expression: "transCustomer",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(2),
                        _c(
                          "div",
                          { staticClass: "col-sm-8 col-md-9 pe-0" },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control-sm py-0 form-control",
                              attrs: {
                                size: "sm",
                                type: "text",
                                placeholder:
                                  "Masukan Nomor Shipment (Pengiriman)",
                              },
                              model: {
                                value: _vm.transNoFaktur,
                                callback: function ($$v) {
                                  _vm.transNoFaktur = $$v
                                },
                                expression: "transNoFaktur",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(3),
                        _c(
                          "div",
                          { staticClass: "col-sm-8 col-md-9 pe-0" },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control-sm py-0 form-control",
                              attrs: {
                                size: "sm",
                                type: "date",
                                required: "",
                                placeholder: "Masukan Tanggal Transaksi",
                              },
                              model: {
                                value: _vm.transDate,
                                callback: function ($$v) {
                                  _vm.transDate = $$v
                                },
                                expression: "transDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "card h-100" }, [
                    _c("div", { staticClass: "row px-3 py-1" }, [
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(4),
                        _c(
                          "div",
                          { staticClass: "col-sm-8 col-md-9 pe-0" },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control-sm py-0 form-control",
                              attrs: {
                                size: "sm",
                                placeholder: "Masukan Nama Ekspedisi",
                              },
                              model: {
                                value: _vm.transExpedition,
                                callback: function ($$v) {
                                  _vm.transExpedition = $$v
                                },
                                expression: "transExpedition",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(5),
                        _c(
                          "div",
                          { staticClass: "col-4 pe-0" },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control-sm py-0 form-control",
                              attrs: { size: "sm", placeholder: "Nomor Resi" },
                              model: {
                                value: _vm.transResi,
                                callback: function ($$v) {
                                  _vm.transResi = $$v
                                },
                                expression: "transResi",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._m(6),
                        _c(
                          "div",
                          { staticClass: "col-4 pe-0" },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control-sm py-0 form-control",
                              attrs: {
                                size: "sm",
                                placeholder: "Supir Kendaraan",
                              },
                              model: {
                                value: _vm.transSupir,
                                callback: function ($$v) {
                                  _vm.transSupir = $$v
                                },
                                expression: "transSupir",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(7),
                        _c(
                          "div",
                          { staticClass: "col-4 pe-0" },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control-sm py-0 form-control",
                              attrs: {
                                size: "sm",
                                placeholder: "Jenis Kendaraan",
                              },
                              model: {
                                value: _vm.transKendaraan,
                                callback: function ($$v) {
                                  _vm.transKendaraan = $$v
                                },
                                expression: "transKendaraan",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._m(8),
                        _c(
                          "div",
                          { staticClass: "col-4 pe-0" },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control-sm py-0 form-control",
                              attrs: { size: "sm", placeholder: "Plat Nomor" },
                              model: {
                                value: _vm.transPlatKendaraan,
                                callback: function ($$v) {
                                  _vm.transPlatKendaraan = $$v
                                },
                                expression: "transPlatKendaraan",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "row my-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "hot-container col-md-12 position-relative",
                    staticStyle: { "overflow-x": "auto", "z-index": "0" },
                  },
                  [
                    _c("hot-table", {
                      ref: "hot",
                      attrs: {
                        settings: _vm.hotSettings,
                        data: _vm.fixExcelData,
                        rowHeaders: true,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "row mb-3 d-flex justify-content-between" },
              [
                _c("div", { staticClass: "col-md-6 scale-9" }, [
                  _c("div", { staticClass: "h-100" }, [
                    _c("div", { staticClass: "card h-100" }, [
                      _c("div", { staticClass: "card-header bg-light py-1" }, [
                        _vm._v("Catatan"),
                      ]),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.transNotes,
                            expression: "transNotes",
                          },
                        ],
                        staticClass: "form-control form-control-sm py-1 h-100",
                        staticStyle: { resize: "none" },
                        attrs: { placeholder: "Masukan Catatan" },
                        domProps: { value: _vm.transNotes },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.transNotes = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "b-modal",
            {
              attrs: {
                title: "List Sales Order (SO)",
                "ok-disabled": _vm.modalInput.length < 1,
              },
              on: { ok: _vm.saveModalData, hide: _vm.closeModal },
              model: {
                value: _vm.modalOpen,
                callback: function ($$v) {
                  _vm.modalOpen = $$v
                },
                expression: "modalOpen",
              },
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Pilih SO :",
                    "label-cols-sm": "4",
                    "label-cols-lg": "3",
                    "content-cols-sm": "8",
                    "content-cols-lg": "9",
                    "invalid-feedback": "Condition Harus Diisi!",
                  },
                },
                [
                  _c("multiselect", {
                    staticClass: "my-1",
                    attrs: {
                      options: _vm.filterSO(_vm.transCustomer),
                      placeholder: "Pilih SO",
                      multiple: false,
                      "show-labels": false,
                      "custom-label": _vm.customLabelKode,
                    },
                    model: {
                      value: _vm.modalInput,
                      callback: function ($$v) {
                        _vm.modalInput = $$v
                      },
                      expression: "modalInput",
                    },
                  }),
                ],
                1
              ),
              _vm.modalInput.transId
                ? _c("div", { staticClass: "col-12" }, [
                    _c("table", { staticClass: "table table-bordered" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("Nama"),
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("Qty Req."),
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("UoM"),
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("Ship To"),
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("Opsi"),
                          ]),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.modalInput.transPesanan, function (data) {
                          return _c("tr", [
                            _c("td", [_vm._v(_vm._s(data.itemName))]),
                            _c("td", [_vm._v(_vm._s(data.itemQty))]),
                            _c("td", [_vm._v(_vm._s(data.itemUm))]),
                            _c("td", [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm.modalInput.userAddress[0].nameAddr
                                  ) + " :"
                                ),
                              ]),
                              _vm._v(
                                _vm._s(_vm.modalInput.userAddress[0].location) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: data.checkData,
                                    expression: "data.checkData",
                                  },
                                ],
                                staticClass: "form-check",
                                attrs: {
                                  type: "checkbox",
                                  disabled: data.itemUm === "set",
                                },
                                domProps: {
                                  checked: Array.isArray(data.checkData)
                                    ? _vm._i(data.checkData, null) > -1
                                    : data.checkData,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = data.checkData,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            data,
                                            "checkData",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            data,
                                            "checkData",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(data, "checkData", $$c)
                                    }
                                  },
                                },
                              }),
                            ]),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4 text-center" }, [
      _c("span", { staticClass: "h2" }, [_vm._v("Tambah Shipment")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 col-md-3 d-flex justify-content-between" },
      [_vm._v("*Customer"), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 col-md-3 d-flex justify-content-between" },
      [_vm._v("*No. Shipment "), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 col-md-3 d-flex justify-content-between" },
      [_vm._v("*Tgl. Shipment "), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 col-md-3 d-flex justify-content-between" },
      [_vm._v("*Ekspedisi"), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-2 d-flex justify-content-between pe-0" },
      [_vm._v("*No. Resi"), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-2 d-flex justify-content-between pe-0" },
      [_vm._v("*Supir"), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-2 d-flex justify-content-between pe-0" },
      [_vm._v("*Jenis"), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-2 d-flex justify-content-between pe-0" },
      [_vm._v("*No. Plat"), _c("span", [_vm._v(":")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }