<template v-if="fields.length > 0">
  <form>
    <div class="row my-1">
      <div class="col-4 d-flex align-items-center">
        <ul class="breadcrumb m-0">
          <li>
            <router-link to="/dashboard">
              <a href="#"><i class="bi-house-door"></i></a>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: posts.transRouting.replace('-add', '') }">
              <a href="#">Stock Opname</a>
            </router-link>
          </li>
          <li>Tambah</li>
        </ul>
      </div>
      <div class="col-4 align-items-center">
        <h4
          style="display: flex;align-items: center;justify-content: center;flex-direction: column; !important"
        >
          Tambah Stock Opname
        </h4>
      </div>
      <div class="col-4 d-flex justify-content-end align-items-center">
        <router-link :to="{ name: posts.transRouting.replace('-add', '') }"
          ><button class="btn btn-outline-secondary btn-xs py-1 me-2" type="button">
            <i class="bi-chevron-left"></i>Kembali
          </button></router-link
        >
        <button
          type="button"
          class="btn btn-primary btn-xs py-1"
          @click.prevent="sendData"
        >
          Simpan
        </button>
      </div>
    </div>
    <div class="row scale-9 position-relative" style="width: 114%; z-index: 1">
      <div
        v-for="(field, index) in dummyItems"
        :class="[field.componentClass]"
        v-if="field.componentName === 'fieldHeader'"
        :key="index"
      >
        <div class="card h-100">
          <div class="row px-3">
            <div v-for="micro in field.componentMicro" :class="['', micro.microClass]">
              <div class="row" v-if="micro.microType === 'select'">
                <b-form-group
                  :label="micro.microName + ' :'"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm="8"
                  content-cols-lg="9"
                >
                  <b-form-select
                    v-model="micro.value"
                    :options="micro.microOptions"
                    size="sm"
                    class="mt-1 form-control-sm py-0 form-control"
                    :disabled="micro.isDisabled"
                  ></b-form-select>
                </b-form-group>
              </div>
              <template
                v-else-if="
                  micro.microType === 'multiselect' && micro.key === 'productLocation'
                "
              >
                <b-form-group
                  :label="micro.microName + ' :'"
                  :label-for="micro.microName"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm="8"
                  content-cols-lg="9"
                  invalid-feedback="Condition Harus Diisi!"
                >
                  <multiselect
                    class="my-1"
                    :id="micro.microName"
                    v-model="locationData"
                    :options="micro.microOptions"
                    placeholder="Select one"
                    :multiple="false"
                    :show-labels="false"
                    :custom-label="customLabel1"
                    @input="changeValue"
                  >
                  </multiselect>
                </b-form-group>
              </template>
              <template
                v-else-if="
                  micro.microType === 'multiselect' && micro.key === 'productName'
                "
              >
                <b-form-group
                  :label="micro.microName + ' :'"
                  :label-for="micro.microName"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm="8"
                  content-cols-lg="9"
                  invalid-feedback="Condition Harus Diisi!"
                >
                  <multiselect
                    class="my-1"
                    @input="changeCondition(micro.value)"
                    :id="micro.microName"
                    v-model="micro.value"
                    :options="micro.microOptions"
                    placeholder="Select one"
                    :multiple="false"
                    :show-labels="false"
                    :custom-label="customLabel"
                  >
                  </multiselect>
                </b-form-group>
              </template>
              <template v-else-if="micro.microType === 'multiselect'">
                <b-form-group
                  :label="micro.microName + ' :'"
                  :label-for="micro.microName"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm="8"
                  content-cols-lg="9"
                  invalid-feedback="Condition Harus Diisi!"
                >
                  <multiselect
                    class="my-1"
                    :id="micro.microName"
                    v-model="micro.value"
                    :options="micro.microOptions"
                    placeholder="Select one"
                    :multiple="false"
                    :show-labels="false"
                    :custom-label="customLabel"
                  >
                  </multiselect>
                </b-form-group>
              </template>
              <template v-else-if="micro.key === 'productQty'">
                <b-form-group
                  :label="micro.microName + ' :'"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm="8"
                  content-cols-lg="9"
                >
                  <b-form-input
                    v-model="micro.value"
                    class="form-control form-control-sm py-0"
                    :type="micro.microType"
                    :placeholder="micro.microText"
                    required
                    @change="getQty(micro.value)"
                  ></b-form-input>
                </b-form-group>
              </template>
              <template v-else>
                <b-form-group
                  :label="micro.microName + ' :'"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm="8"
                  content-cols-lg="9"
                >
                  <b-form-input
                    v-model="micro.value"
                    class="form-control form-control-sm py-0"
                    :type="micro.microType"
                    :placeholder="micro.microText"
                    required
                    :disabled="micro.isDisabled"
                  ></b-form-input>
                </b-form-group>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-12 scale-12">
        <div
          v-for="field in component"
          :class="['h-100', field.componentClass]"
          v-if="field.componentName === 'fieldNote'"
        >
          <div class="card h-100">
            <div class="card-header bg-light py-1">
              {{ field.componentTitle }}
            </div>
            <template v-for="micro in field.componentMicro">
              <textarea
                class="form-control form-control-sm py-1 h-100"
                style="resize: none"
                :placeholder="micro.microText"
                v-model="micro.microValue"
              ></textarea>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3"></div>
    <table class="table table-bordered">
      <thead class="bg-light table-border">
        <tr>
          <th width="50" class="text-center">No</th>
          <th width="" class="text-center">Kode & Nama Item</th>
          <th width="" class="text-center">No. Kontrol</th>
          <th width="100" class="text-center">Satuan</th>
          <th width="100" class="text-center">Qty Current</th>
          <th width="100" class="text-center">Qty Adjust</th>
          <th width="50" class="text-center">Opsi</th>
        </tr>
      </thead>
      <tbody v-if="listItem.length > 0">
        <tr v-for="(data, index) in listItem">
          <td>{{ index + 1 }}</td>
          <td>
            <multiselect
              class="my-1"
              @input="changeRowItem(index)"
              :id="'kodeItem' + index"
              v-model="data.item"
              :options="fixOptionsItem"
              placeholder="Select one"
              :multiple="false"
              :show-labels="false"
              :custom-label="customLabel"
            >
            </multiselect>
          </td>
          <td>
            <multiselect
              class="my-1"
              :id="'kodeBatch' + index"
              v-model="data.dataBatch"
              :options="optionsBatch(data.item.invDetilBegining)"
              placeholder="Select one"
              :multiple="false"
              :show-labels="false"
              label="itemNoBatch"
            >
            </multiselect>
          </td>
          <td>
            <tempalte v-if="data.item">{{ data.item.itemUM }}</tempalte>
          </td>
          <td>
            <tempalte v-if="data.dataBatch">{{ data.dataBatch.itemQty }}</tempalte>
          </td>
          <td>
            <input
              v-if="data.item"
              class="py-0"
              type="number"
              style="width: 100px"
              v-model="data.item.qtyAdjust"
              :disabled="!data.dataBatch"
            />
          </td>
          <td class="text-center">
            <i
              :class="
                index + 1 === listItem.length
                  ? 'bi-plus-circle text-primary'
                  : 'bi-x-circle text-danger'
              "
              style="cursor: pointer"
              @click="index + 1 === listItem.length ? addItem() : removeItem(index)"
            ></i>
            <!-- {{ index }} {{ listItem.length }} -->
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="6">Data Item Kosong!</td>
          <td class="text-center">
            <i
              class="bi-plus-circle text-primary"
              style="cursor: pointer"
              @click="addItem"
            ></i>
          </td>
        </tr>
      </tbody>
    </table>
  </form>
</template>

<script>
import { generateIdChart } from "../function/number";
import { getItemByLocation } from "../services/components";
import { dynamicJS } from "../services/dynamic";
import {
  getRouting,
  getNoAntrian,
  getItemWithSaldo,
  addStockOpname,
} from "../services/components";
import { getMonth } from "../function/date";
export default {
  props: {
    listQty: {
      type: String,
    },
    refreshMethods: {
      type: Function,
      required: true,
    },
    posts: {
      type: Object,
      required: true,
    },
    component: {
      type: Array,
      required: true,
    },
    hotSettings: {
      type: Object,
      required: true,
    },
    contentDivWidth: {
      type: Number,
      required: true,
    },
    widthTable: {
      type: Number,
      required: true,
    },
    excelData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      locationData: [],
      optionsItem: [],
      listItem: [],
      routing: [],
      minData: "",
      dataTable: [],
      tempData: [],
      multipler: "",
      dynamicFunction: dynamicJS,
      filter: "",
      filterFields: "",
      filterSort: "",
      filterList: [{ filter: "" }],
      checkedFields: [],
      sortBy: "",
      sortDesc: false,
      currentPage: 1,
      perPage: 8,
    };
  },
  computed: {
    fixOptionsItem() {
      if (this.locationData._id) {
        const tes = this.optionsItem.filter((x) =>
          x.invDetilBegining.some((y) => y.itemLocation.id === this.locationData._id)
        );
        console.log("tes", tes);
        return tes;
      } else {
        console.log("kosong");
        return [];
      }
    },
    dummyItems() {
      const dummy = {};
      for (const key in this.component) {
        dummy[key] = { ...this.component[key] };
      }
      console.log("component :", this.component);
      console.log("dummy", dummy);
      return dummy;
    },
    dataRange() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.data.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.data.length / this.perPage);
    },
    dataRangeText() {
      const startIndex = (this.currentPage - 1) * this.perPage + 1;
      const endIndex = Math.min(startIndex + this.perPage - 1, this.data.length);
      return `${startIndex} - ${endIndex} data dari total ${this.data.length} data`;
    },
  },
  created() {
    this.initializeCheckedFields();
  },
  async mounted() {
    const currentMonth = await getMonth();
    const item = await getItemByLocation(currentMonth, "material");
    this.optionsItem = item;
    console.log("data item", item);
    const tes = await getRouting();
    if (tes === []) {
      this.routing = tes.filter((x) => x.routingGroup === "WORK ORDER")[0].routingConfirm;
    }
  },
  methods: {
    optionsBatch(data) {
      if (data) {
        return data.filter((x) => x.itemLocation.id === this.locationData._id);
      }
    },
    changeValue(data) {
      console.log(data);
      this.listItem = [];
    },
    changeRowItem(index) {
      console.log(index);
      this.listItem[index].item.qtyAdjust = "";
      this.listItem[index].dataBatch = "";
      console.log(this.listItem[index]);
    },
    customLabel(option) {
      return `${option.itemCode} (${option.itemName})`;
    },
    addItem() {
      const data = {
        item: {
          itemCode: "",
          itemName: "",
          itemUM: "",
          invEnd: "",
          qtyAdjust: "",
        },
        dataBatch: null,
      };
      this.listItem.push(data);
    },
    removeItem(index) {
      this.listItem.splice(index, 1);
    },
    getQty(data) {
      let numericValue = parseFloat(data);
      numericValue =
        Math.round(numericValue / parseFloat(this.minData)) * parseFloat(this.minData);
      // Menghindari nilai negatif atau nol
      numericValue = Math.max(numericValue, parseFloat(this.minData));

      this.$nextTick(() => {
        this.component
          .filter((x) => x.componentName === "fieldHeader")
          .forEach((y) => {
            y.componentMicro.find((z) => {
              if (z.key === "productQty") {
                z.value = numericValue;
              }
            });
          });
      });
      this.component = [...this.component];
      let tes = Math.round(numericValue / parseFloat(this.minData));
      this.multipler = tes;
    },
    async changeCondition(data) {
      console.log(data);
      let id;
      if (data === null) {
        data.invSaldo = 0;
        data.itemMinRecipe = 0;
        data.itemRecipe = [];
        id = [];
      } else {
        id = await getNoAntrian(data.itemDefaultSetup[0].defaultValue);
      }
      this.multipler = 1;
      this.dataTable = [...data.itemRecipe];
      this.minData = data.itemMinRecipe;
      this.component
        .filter((x) => x.componentName === "fieldHeader")
        .forEach((y) => {
          y.componentMicro.find((z) => {
            console.log(z.key);
            if (z.key === "noBatch") {
              z.value = id;
            }
          });
        });
      this.component = [...this.component];
    },
    customLabel(option) {
      return `${option.itemName} - ${option.itemCode}`;
    },
    customLabel1(option) {
      return `${option.name} (${option.descript})`;
    },
    sendData(event) {
      event.preventDefault();
      // header Data
      const finalHeader = this.component.reduce((result, item) => {
        if (item.componentName === "fieldHeader" && Array.isArray(item.componentMicro)) {
          console.log("fase 1");
          item.componentMicro.forEach((microItem) => {
            if (microItem.value !== undefined) {
              result[microItem.key] = microItem.value;
            } else {
              result[microItem.key] = "";
            }
          });

          console.log("fase 2");
        }
        return result;
      }, {});
      const finalNote = this.component.reduce((result, item) => {
        if (item.componentName === "fieldNote" && Array.isArray(item.componentMicro)) {
          item.componentMicro.forEach((microItem) => {
            result[microItem.key] = microItem.microValue;
          });
        }
        return result;
      }, {});
      // table Data
      const fieldsTable = this.component.find(
        ({ componentName }) => componentName === "fieldTable"
      ).componentMicro;
      console.log("mau Kirim: ", this.listItem);
      const finalTable = this.excelData
        .filter((item) => {
          return fieldsTable.some((obj) => {
            const value = item[obj.key];
            return (
              value !== "" && value !== undefined && value !== null && value.length > 0
            );
          });
        })
        .map((item) => ({
          ...item,
        }));
      // final Note
      // final Total
      const finalTotal = this.component.reduce((result, item) => {
        if (item.componentName === "fieldTotal" && Array.isArray(item.componentMicro)) {
          item.componentMicro
            .filter((tes) => tes.editableTotal === true)
            .forEach((microItem) => {
              if (isNaN(microItem.value)) {
                result[microItem.key] = 0;
              } else {
                result[microItem.key] = microItem.value;
              }
            });
        }
        return result;
      }, {});
      // final Footer
      const finalFooter = {
        note: finalNote,
        total: finalTotal,
      };

      const finalData = this.listItem.map((x) => ({
        itemHysId: x.item._id,
        itemHysName: x.item.itemName,
        itemHysUm: x.item.itemUM,
        itemQtyStock: x.dataBatch.itemQty,
        itemQtyAdj: x.item.qtyAdjust,
        itemNoBatch: x.dataBatch.itemNoBatch,
        itemLocation: x.dataBatch.itemLocation,
        // itemLocation: {
        //   name: "Pabrik 1",
        //   id: "650d3a6ae3515b99321213a7",
        // },
      }));
      console.log(finalData);
      // console.log(JSON.stringify(finalData));
      // final Data

      // const finalData = {
      //   woDomain: localStorage.getItem("domainCode"),
      //   woNoBatch: finalHeader.noBatch,
      //   woNo: "",
      //   woRouting: finalHeader.productName.itemRouting[0].routingConfirm,
      //   woUserCreated: localStorage.getItem("_id"),
      //   woUserCust: finalHeader.pasienName._id,
      //   woitemId: finalHeader.productName._id,
      //   woItemName: finalHeader.productName.itemName,
      //   woItemGroup: finalHeader.productName.itemGroup,
      //   woItemCategory: finalHeader.productName.itemCategory,
      //   woItemUm: finalHeader.productName.itemUM,
      //   woItemQty: finalHeader.productQty,
      //   woScheduleDate: finalHeader.scheduleDate,
      //   woStartDate: finalHeader.scheduleDate,
      //   woNoted: finalNote.note,
      //   itemDefaultSetup: finalHeader.productName.itemDefaultSetup,
      //   woRecipeItem: finalHeader.productName.itemRecipe.map((x) => {
      //     const data = {
      //       ...x,
      //     };
      //     data.recipeQty = data.recipeQty * this.multipler;
      //     return data;
      //   }),
      //   // header: finalHeader,
      //   // item: finalHeader.productName,
      // };
      // console.log("tes", finalData);
      const doneValidator = Object.entries(finalHeader).every(([value, key]) => {
        if (key === "noBatch") {
          return true;
        }
        return value !== "";
      });
      if (doneValidator && finalData.length > 0) {
        this.$swal({
          title: "Anda Yakin?",
          text: "Tekan Simpan jika anda sudah yakin!",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Simpan",
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.alertLoading();
            try {
              // console.log(finalData);
              const userId = localStorage.getItem("_id");
              const voucher = await addStockOpname(finalData, userId);
              this.successAdd();
              this.refreshFields();
              this.$router.push({
                name: this.posts.transRouting.replace("-add", ""),
              });
            } catch (error) {
              alert(error);
            }
          }
        });
      } else {
        alert("ada data yang masih Kosong!");
      }
    },
    alertLoading() {
      this.$swal({
        title: "Loading...",
        text: "Mohon Ditunggu ya.",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        willOpen: () => {
          this.$swal.showLoading();
        },
      });
    },
    successAdd() {
      this.$swal("Berhasil!", "Data berhasil ditambahkan", "success");
    },
    refreshFields() {
      this.component
        .filter((x) => x.componentName === "fieldHeader")
        .forEach((y) => {
          y.componentMicro.find((z) => (z.value = ""));
        });
      this.component.find(
        (x) => x.componentName === "fieldNote"
      ).componentMicro[0].microValue = "";
    },
    addList() {
      const link = "/transaksi/" + this.routingLink + "/add";
      this.$router.push(link);
    },
    resetFilter() {
      this.filter = "";
      this.filterFields = "";
      this.filterSort = "";
      this.filterList = [{ filter: "" }];
    },
    addFilter() {
      if (this.filter !== "") {
        this.filterList.push({ filter: "" });
      }
    },
    removeFilter() {
      if (this.filterList.length > 1) {
        this.filterList.splice(-1);
      }
    },
    serviceDetail(item) {
      const data = item._id;
      this.$store.commit("setDataProses", item);
      this.$router.push({ name: "transaksi-detail", params: { data } });
    },
    initializeCheckedFields() {
      if (this.fields !== undefined) {
        this.checkedFields = [];
        this.fields.forEach((field) => {
          this.checkedFields.push(field.key);
        });
      }
    },
    handleButtonClick(item) {
      // Perform action when the button is clicked
      console.log("Button clicked:", item);
    },
    handleRowClick(item) {
      // Handle row click event
      console.log("Row clicked:", item);
    },
  },
};
</script>
