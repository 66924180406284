var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form", [
    _c("div", { staticClass: "row my-1" }, [
      _c("div", { staticClass: "col-4 d-flex align-items-center" }, [
        _c("ul", { staticClass: "breadcrumb m-0" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/dashboard" } }, [
                _c("a", { attrs: { href: "#" } }, [
                  _c("i", { staticClass: "bi-house-door" }),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: _vm.posts.transRouting.replace("-receipt", ""),
                    },
                  },
                },
                [
                  _c("a", { attrs: { href: "#" } }, [
                    _vm._v(_vm._s(_vm.posts.transTitle)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("li", [_vm._v("Receipt")]),
        ]),
      ]),
      _c("div", { staticClass: "col-4 align-items-center" }, [
        _c(
          "h4",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
              "flex-direction": "column",
            },
          },
          [_vm._v(" " + _vm._s(_vm.posts.transTitle) + " Receipt ")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "col-4 d-flex justify-content-end align-items-center" },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: { name: _vm.posts.transRouting.replace("-receipt", "") },
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary btn-xs py-1 me-2",
                  attrs: { type: "button" },
                },
                [
                  _c("i", { staticClass: "bi-chevron-left" }),
                  _vm._v("Kembali "),
                ]
              ),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-xs py-1",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.sendData.apply(null, arguments)
                },
              },
            },
            [_vm._v(" Tambah ")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "row scale-9 position-relative",
        staticStyle: { width: "114%", "z-index": "1" },
      },
      _vm._l(_vm.dummyItems, function (field, index) {
        return field.componentName === "fieldHeader"
          ? _c("div", { key: index, class: [field.componentClass] }, [
              _c("div", { staticClass: "card h-100" }, [
                _c(
                  "div",
                  { staticClass: "row px-3" },
                  _vm._l(field.componentMicro, function (micro) {
                    return _c(
                      "div",
                      { class: ["", micro.microClass] },
                      [
                        micro.microType === "select"
                          ? _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: micro.microName + " :",
                                      "label-cols-sm": "4",
                                      "label-cols-lg": "3",
                                      "content-cols-sm": "8",
                                      "content-cols-lg": "9",
                                    },
                                  },
                                  [
                                    _c("b-form-select", {
                                      staticClass:
                                        "mt-1 form-control-sm py-0 form-control",
                                      attrs: {
                                        options: micro.microOptions,
                                        size: "sm",
                                        disabled: micro.isDisabled,
                                      },
                                      model: {
                                        value: micro.value,
                                        callback: function ($$v) {
                                          _vm.$set(micro, "value", $$v)
                                        },
                                        expression: "micro.value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : micro.microType === "multiselect"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-for": micro.microName,
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                    "invalid-feedback":
                                      "Condition Harus Diisi!",
                                  },
                                },
                                [
                                  _c("multiselect", {
                                    staticClass: "my-1",
                                    attrs: {
                                      id: micro.microName,
                                      options: micro.microOptions,
                                      placeholder: "Select one",
                                      multiple: false,
                                      "show-labels": false,
                                      "custom-label": _vm.customLabel,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.changeCondition(micro.value)
                                      },
                                    },
                                    model: {
                                      value: micro.value,
                                      callback: function ($$v) {
                                        _vm.$set(micro, "value", $$v)
                                      },
                                      expression: "micro.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : micro.key === "productQty"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass:
                                      "form-control form-control-sm py-0",
                                    attrs: {
                                      type: micro.microType,
                                      placeholder: micro.microText,
                                      required: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.getQty(micro.value)
                                      },
                                    },
                                    model: {
                                      value: micro.value,
                                      callback: function ($$v) {
                                        _vm.$set(micro, "value", $$v)
                                      },
                                      expression: "micro.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass:
                                      "form-control form-control-sm py-0",
                                    attrs: {
                                      type: micro.microType,
                                      placeholder: micro.microText,
                                      required: "",
                                      disabled: micro.isDisabled,
                                    },
                                    model: {
                                      value: micro.value,
                                      callback: function ($$v) {
                                        _vm.$set(micro, "value", $$v)
                                      },
                                      expression: "micro.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ])
          : _vm._e()
      }),
      0
    ),
    _c("div", { staticClass: "row mb-3" }, [
      _c(
        "div",
        { staticClass: "col-md-12 scale-12" },
        _vm._l(_vm.component, function (field) {
          return field.componentName === "fieldNote"
            ? _c("div", { class: ["h-100", field.componentClass] }, [
                _c(
                  "div",
                  { staticClass: "card h-100" },
                  [
                    _c("div", { staticClass: "card-header bg-light py-1" }, [
                      _vm._v(" " + _vm._s(field.componentTitle) + " "),
                    ]),
                    _vm._l(field.componentMicro, function (micro) {
                      return [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: micro.value,
                              expression: "micro.value",
                            },
                          ],
                          staticClass:
                            "form-control form-control-sm py-1 h-100",
                          staticStyle: { resize: "none" },
                          attrs: { disabled: "", placeholder: micro.microText },
                          domProps: { value: micro.value },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(micro, "value", $event.target.value)
                            },
                          },
                        }),
                      ]
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e()
        }),
        0
      ),
    ]),
    _c(
      "div",
      { staticClass: "row mb-3" },
      _vm._l(_vm.excelData.woRecipeItem, function (data, index) {
        return _vm.excelData.woRecipeItem.length > 0
          ? _c(
              "div",
              { staticClass: "row", staticStyle: { "padding-bottom": "10px" } },
              [
                _c("div", { staticClass: "col-4" }, [
                  _c("ul", { staticClass: "list-group" }, [
                    _c("b", [
                      _c(
                        "li",
                        {
                          staticClass: "list-group-item active",
                          staticStyle: {
                            padding: "10px",
                            "border-radius": "5px 5px 0 0",
                          },
                        },
                        [
                          _c("i", {
                            class:
                              ["bi bi-"] +
                              data.processCode +
                              ["-square-fill "] +
                              ["list-group-icon"],
                          }),
                          _vm._v(" " + _vm._s(data.processName) + " "),
                        ]
                      ),
                    ]),
                    _c(
                      "li",
                      {
                        staticClass: "list-group-item",
                        staticStyle: {
                          "padding-bottom": "2px",
                          "padding-top": "1px",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "bi bi-box-seam list-group-icon",
                        }),
                        _c("b", [_vm._v("Menghasilkan :")]),
                        _c("br"),
                        _c("table", { staticClass: "table" }, [
                          _c("tr", [
                            _c("td"),
                            _c("td", [
                              _vm._v(_vm._s(data.recipeItemResult.itemName)),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(data.recipeQty) +
                                  " (" +
                                  _vm._s(data.recipeUM) +
                                  ")"
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "list-group-item",
                        staticStyle: {
                          "padding-bottom": "2px",
                          "padding-top": "1px",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "bi bi-receipt list-group-icon",
                        }),
                        _vm._v("Material :"),
                        _c("br"),
                        _c(
                          "table",
                          { staticClass: "table" },
                          _vm._l(data.recipeMaterial, function (itemData) {
                            return _c("tr", [
                              _c("td", [_vm._v(_vm._s(itemData.itemName))]),
                              _c("td", [_vm._v(_vm._s(itemData.qty))]),
                              _c("td", [_vm._v(_vm._s(itemData.UM))]),
                            ])
                          }),
                          0
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-4" }, [
                  _c("ul", { staticClass: "list-group" }, [
                    _c("b", [
                      _c(
                        "li",
                        {
                          staticClass: "list-group-item active",
                          staticStyle: {
                            padding: "10px",
                            "border-radius": "5px 5px 0 0",
                          },
                        },
                        [
                          _c("i", {
                            class: ["bi bi-receipt "] + ["list-group-icon"],
                          }),
                          _vm._v(" Penggunaan Material "),
                        ]
                      ),
                    ]),
                    _c(
                      "li",
                      {
                        staticClass: "list-group-item",
                        staticStyle: {
                          "padding-bottom": "2px",
                          "padding-top": "1px",
                        },
                      },
                      [
                        _vm._v(" \\ "),
                        _c(
                          "table",
                          { staticClass: "table" },
                          _vm._l(data.recipeMaterial, function (itemData, i) {
                            return _c("tr", [
                              _c("td", [_vm._v(_vm._s(itemData.itemName))]),
                              _c("td", [
                                _vm._v(" " + _vm._s(itemData.qty) + " "),
                              ]),
                              _c("td", [_vm._v(_vm._s(itemData.UM))]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: itemData.value,
                                    expression: "itemData.value",
                                  },
                                ],
                                staticStyle: { width: "50px" },
                                attrs: {
                                  width: "50px",
                                  type: "number",
                                  max: "100",
                                  min: "0",
                                },
                                domProps: { value: itemData.value },
                                on: {
                                  change: function ($event) {
                                    return _vm.validateInput(itemData, index, i)
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      itemData,
                                      "value",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ])
                          }),
                          0
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-4" }, [
                  _c("ul", { staticClass: "list-group" }, [
                    _c("b", [
                      _c(
                        "li",
                        {
                          staticClass: "list-group-item active",
                          staticStyle: {
                            padding: "10px",
                            "border-radius": "5px 5px 0 0",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "bi bi-box-seam list-group-icon",
                          }),
                          _c("b", [_vm._v("Hasil Work Order : ")]),
                          _vm._v(
                            " " + _vm._s(data.recipeItemResult.itemName) + " "
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "li",
                      {
                        staticClass: "list-group-item",
                        staticStyle: {
                          "padding-bottom": "2px",
                          "padding-top": "1px",
                        },
                      },
                      [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v(" Nama User ")]),
                              _c("th", [_vm._v(" Qty ")]),
                              _c("th", [
                                _vm._v("(" + _vm._s(data.recipeUM) + ")"),
                              ]),
                              _c("th", [_vm._v("location")]),
                              _c("th"),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(data.receiptWOResult, function (coba, i) {
                              return _c("tr", [
                                _c("td", [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: coba.userId,
                                          expression: "coba.userId",
                                        },
                                      ],
                                      staticStyle: { width: "100px" },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            coba,
                                            "userId",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      coba.userId === ""
                                        ? _c("option", [_vm._v("Pilih")])
                                        : _vm._e(),
                                      _vm._l(_vm.optionsUser, function (user) {
                                        return _c(
                                          "option",
                                          { domProps: { value: user._id } },
                                          [
                                            _vm._v(
                                              " " + _vm._s(user.userName) + " "
                                            ),
                                          ]
                                        )
                                      }),
                                    ],
                                    2
                                  ),
                                ]),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: coba.qtyResult,
                                        expression: "coba.qtyResult",
                                      },
                                    ],
                                    staticStyle: { width: "50px" },
                                    attrs: { type: "number" },
                                    domProps: { value: coba.qtyResult },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          coba,
                                          "qtyResult",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _c("td", [
                                  _vm._v("(" + _vm._s(data.recipeUM) + ")"),
                                ]),
                                _c("td", [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: coba.qtyLocation,
                                          expression: "coba.qtyLocation",
                                        },
                                      ],
                                      staticStyle: { width: "75px" },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            coba,
                                            "qtyLocation",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      coba.qtyLocation === ""
                                        ? _c("option", [_vm._v("Pilih")])
                                        : _vm._e(),
                                      _vm._l(
                                        _vm.optionsLocation,
                                        function (location) {
                                          return _c(
                                            "option",
                                            {
                                              domProps: { value: location._id },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(location.name) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                                _c(
                                  "td",
                                  [
                                    _vm.checkIndex(index, i)
                                      ? [
                                          _c("i", {
                                            staticClass:
                                              "bi-plus-circle text-primary",
                                            on: {
                                              click: function ($event) {
                                                return _vm.addRowUser(index)
                                              },
                                            },
                                          }),
                                        ]
                                      : [
                                          _c("i", {
                                            staticClass:
                                              "bi-x-circle text-danger",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeRowUser(
                                                  index,
                                                  i
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                  ],
                                  2
                                ),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]
            )
          : _c("div", { staticClass: "col-12" }, [
              _c("h4", [_vm._v("Layanan Ini Belum di Setup Tindakannya")]),
            ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }