import axios from "axios";
import { API } from "@/API";

// Temporary

export const getItemByGroup = async (data) => {
const domainId = localStorage.getItem('domainCode');
  // return master.find(data => data.masterRouting === routes).masterData
  const response = await axios.get(API + "/items/bydomainNgroup/" + domainId + "/" + data);
  console.log(response.data, "tes");
  var suppData = response.data;
  return suppData;
};
export const getItem = async () => {
  const domainId = localStorage.getItem('domainCode');
  // return master.find(data => data.masterRouting === routes).masterData
  const response = await axios.get(API + "/items/bydomainNgroup/" + domainId + "/product");
  console.log(response.data, "tes");
  var suppData = response.data;
  return suppData;
};
export const getItemWO = async () => {
  const domainId = localStorage.getItem('domainCode');
  // return master.find(data => data.masterRouting === routes).masterData
  const response = await axios.get(API + "/items/bydomainNgroup/" + domainId + "/product-semiFG");
  console.log(response.data, "tes");
  var suppData = response.data;
  return suppData;
};
export const getItemResepWO = async () => {
  const domainId = localStorage.getItem('domainCode');
  // return master.find(data => data.masterRouting === routes).masterData
  const response = await axios.get(API + "/items/bydomainNgroupResepStock/" + domainId + "/product-semiFG");
  console.log(response.data, "tes");
  var suppData = response.data;
  return suppData;
};
export const addWO = async (data) => {
  // return master.find(data => data.masterRouting === routes).masterData
  const response = await axios.post(API + "/transWO/create", data);
  console.log(response.data, "tes");
  var suppData = response.data;
  return suppData;
};
export const addWOPelayanan = async (data) => {
  // return master.find(data => data.masterRouting === routes).masterData
  const response = await axios.post(API + "/transWO/createPelayanan", data);
  var suppData = response.data;
  return suppData;
};
export const getAllWObyDomain = async () => {
const domainId = localStorage.getItem('domainCode');
  // return master.find(data => data.masterRouting === routes).masterData
  const response = await axios.get(API + "/transWO/getAllWOCreatebyDomain/" + domainId);
  console.log('datanya', response)
  const suppData = response.data;
  return suppData;
};
export const getDetailWObyWoNo = async (noWO) => {
const domainId = localStorage.getItem('domainCode');
  // return master.find(data => data.masterRouting === routes).masterData
  const response = await axios.get(API + "/transWO/getWObyWoNo/" + domainId + "/" + noWO);
  const suppData = response.data;
  return suppData;
};
export const getUserGenNumber = async () => {
const domainId = localStorage.getItem('domainCode');
  // return master.find(data => data.masterRouting === routes).masterData
  const response = await axios.get(API + "/user/getUserGenNumber/" + domainId);
  const suppData = response.data;
  return suppData;
};
