
export const formatNumber = (value) => {
  const floatValue = parseFloat(value);
  if (isNaN(floatValue)) {
    return value; // Return the original string value if it's not a valid number
  }
  const parts = floatValue.toFixed(2).toString().split(',');
  const wholePart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  if (parts.length > 1) {
    return `${wholePart},${parts[1]}`;
  } else {
    return wholePart;
  }
}
export const formatNumberInt = (value) => {
  const floatValue = value.toString();
  if (isNaN(floatValue)) {
    return value; // Return the original string value if it's not a valid number
  }
  const wholePart = floatValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return wholePart;
}
// format input text menjadi angka saja
export const removeFormat = (number) => {
  if (isNaN(number)) {
    return 'NaN'; // Handle invalid input
  }

  const parts = number.toString().split('.');
  const integerPart = parts[0];
  const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

  // Use regex to add commas as thousand separators
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return formattedIntegerPart + decimalPart;
}
// ubah 
export const removeThousandFormatter = (number) => {
  const tes = number.toString()
  return tes.replace(/,/g, '');
}
export const formatPriceDot = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const generateRandomString = (length) => {
  const characters = '0123456789abcdefghijklmnopqrstuvwxyz';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}

export const generateIdChart = (length) => {
  const numChars = "0123456789";
  const alphaChars = "abcdefghijklmnopqrstuvwxyz";
  const characters = numChars.repeat(0.8 * length) + alphaChars.repeat(0.2 * length);
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }

  return result;
}