<template>
  <main id="content" role="main" class="main">
    <div class="w-100" ref="contentDiv"></div>
    <transition name="fade" mode="out-in">
      <div v-if="isDataLoaded === ''" key="empty">
        <loading></loading>
      </div>

      <!-- Main Content -->
      <div v-else-if="isDataLoaded" key="truthy">
        <ul class="breadcrumb m-1 mt-0">
          <li>
            <a href="#"><i class="bi-house-door"></i></a>
          </li>
          <li>
            <router-link to="/transaksi/shipment" class="text-primary"
              >Shipment
            </router-link>
          </li>
          <li>Detail Shipment (Surat Jalan)</li>
          <li>{{ transHysNo }}</li>
        </ul>
        <div class="card">
          <div class="card-header bg-light p-2">
            <div class="row">
              <div class="row col-9 d-flex align-items-center justify-content-end pe-0">
                <b-form-group
                  label-for="coaKasbank"
                  invalid-feedback="Condition Harus Diisi!"
                  style="width: 200px"
                >
                </b-form-group>
              </div>

              <div class="col-3 d-flex justify-content-end py-2" style="z-index: 2">
                <router-link
                  to="/transaksi/shipment"
                  class="btn btn-outline-secondary btn-xs py-1 me-2"
                >
                  <i class="bi-chevron-left"></i>Kembali
                </router-link>
                <b-button-group>
                  <button class="btn btn-primary btn-xs" @click="printReport">
                    <i class="bi-printer pe-2"></i>Print
                  </button>
                  <b-dropdown id="dropdown2" variant="primary" size="xs" right>
                    <div>
                      <p class="p-2 pb-0 m-0">Export</p>
                    </div>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="generatePDF">PDF</b-dropdown-item>
                    <b-dropdown-item>xls</b-dropdown-item>
                  </b-dropdown>
                </b-button-group>
              </div>
            </div>
          </div>
          <div class="card-body p-2 printData">
            <div class="container">
              <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center">
                  <img
                    class="img-fluid w-25"
                    src="/assets/img/logoPrint/dragon1.png"
                    alt="Image Description"
                    data-hs-theme-appearance="default"
                  />
                  <div class="w-50 text-center">
                    <h1><u>SURAT JALAN</u></h1>
                  </div>
                </div>
              </div>
              <div class="row mb-4 d-flex justify-content-between">
                <div class="col-6">
                  <h1 class="m-0">DRAGON MAS</h1>
                  <h5 class="m-0">CV. Dragon Mas Texa Prima</h5>
                  <p class="m-0">(0352) 3576041</p>
                  <p class="m-0">www.PabrikMesinLaundry.com</p>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-6 pe-0">
                      <b>No. Surat Jalan</b>
                    </div>
                    <div class="col-6 ps-0">
                      <b>: </b><span v-if="data">{{ data[0].transHysNo }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 pe-0">
                      <b>No. Resi</b>
                    </div>
                    <div class="col-6 ps-0">
                      <b>: </b
                      ><span v-if="data">{{ data[0].transShipExpedisi.noResi }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 pe-0">
                      <b>Tanggal</b>
                    </div>
                    <div class="col-6 ps-0">
                      <b>: </b><span v-if="data">{{ data[0].transHysEffDate }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 pe-0">
                      <b>Kepada</b>
                    </div>
                    <div class="col-6 ps-0">
                      <b>: </b>
                      <span v-if="data">{{ data[0].transShipCustNo.userName }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Bersama ini kendaraan
                <b v-if="data">{{ data[0].transShipExpedisi.tipeMobil }}</b
                >, Dengan Nomor
                <b v-if="data">{{ data[0].transShipExpedisi.platNomor }}</b>
              </p>

              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th width="10px">NO.</th>
                    <th class="text-center" width="150">BANYAKNYA</th>
                    <th class="text-center">KETERANGAN</th>
                  </tr>
                </thead>
                <tbody v-if="data">
                  <tr v-for="(dt, index) in data[0].transHysItem">
                    <td>
                      <template v-if="dt.itemHysUm === 'set'"
                        ><b
                          ><i>{{ index + 1 }}</i></b
                        ></template
                      ><template v-else>{{ index + 1 }}</template>
                    </td>
                    <td>
                      <template v-if="dt.itemHysUm === 'set'"
                        ><b
                          ><i>{{ dt.itemHysQtyRcp }} {{ dt.itemHysUm }}</i></b
                        ></template
                      ><template v-else
                        >{{ dt.itemHysQtyRcp }} {{ dt.itemHysUm }}</template
                      >
                    </td>
                    <td>
                      <template v-if="dt.itemHysUm === 'set'"
                        ><b
                          ><i>{{ dt.itemHysName }}</i></b
                        ></template
                      ><template v-else>{{ dt.itemHysName }}</template>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="footerPrint">
                <!-- dibuat oleh -->
                <!-- approve oleh -->
                <div class="ttd">
                  <p>Pengirim</p>

                  <!-- <span class="ttdName">( {{ pembuat }} )</span> -->
                </div>
                <div class="ttd">
                  <p>Kurir</p>

                  <!-- <span class="ttdName">( {{ dataPOId[0].PoRouting }} )</span> -->
                </div>
                <div class="ttd">
                  <p>Penerima</p>

                  <!-- <span class="ttdName">( {{ dataPOId[0].PoRouting }} )</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Error -->
      <div v-else key="other">
        <connection-timeout></connection-timeout>
      </div>
    </transition>
  </main>
</template>

<script>
import { getShipmentById } from "../../components/services/components";
import loading from "../../components/loading.vue";
import connectionTimeout from "../../components/connectionTimeout.vue";
export default {
  components: {
    loading,
    connectionTimeout,
  },
  data() {
    return {
      data: [],
      transHysNo: "",
      isDataLoaded: "",
    };
  },
  methods: {
    printReport() {
      const printWindow = window.open("", "_blank");
      const printDocument = `
          <html>
          <head>
	          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet">
          <style scope>
            td {
              position: relative;
            }
            .container {
              max-width: 800px;
              margin: 0 auto;
              padding: 20px;
              padding-top: 0;
              padding-bottom: 0;
              border: 1px solid #ccc;
              background-color: #fff;
              overflow: hidden; /* Untuk mengatasi float */
            }
            th, td {
              padding-top: 2.5px!important;
              padding-bottom : 2.5px!important;
            }
            .header {
              text-align: center;
              background-color: #f2f2f2;
              padding: 10px;
            }

            .header h1 {
              margin: 0;
              color: #333;
            }

            .po-number {
              text-align: right;
              margin-top: 10px;
            }

            .po-number p {
              margin: 0;
              font-weight: bold;
            }

            .po-date {
              text-align: right;
            }

            .po-date p {
              margin: 5px 0;
            }

            .address-info {
              display: flex;
              justify-content: space-between;
              margin-top: 20px;
            }

            .address-info h2 {
              font-size: 18px;
              margin: 0;
            }

            .address-info .address {
              flex: 1;
            }

            .address-info p {
              margin: 5px 0;
            }

            .order-details {
              margin-top: 20px;
              margin-bottom: 0; /* Menghilangkan margin bawah */
            }

            .order-details table {
              width: 100%;
              border-collapse: collapse;
            }

            .order-details table,
            .order-details th,
            .order-details td {
              border: 1px solid #ccc;
            }

            .order-details th,
            .order-details td {
              padding: 10px;
              text-align: left;
            }
            .divNotePrint {
              overflow: hidden;
              text-overflow: ellipsis; /* Add ellipsis for overflow text */
              border: 1px solid #ccc;
              position: absolute;
              top: 10px;
              left: 0;
              bottom: 0;
              right: 10px;
            }
            .footerPrint {
              padding: 25px 25px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            .footerPrint .ttd {
              flex-wrap: wrap;
              display: flex;
              align-items: space-between;
              justify-content: center;
              width: 30%;
              height: 110px;
              border-bottom: 1px solid #000;
            }
            .footerPrint .ttd .ttdName {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: flex-end;
            }
            </style>
              </head>
  
            <body>
              ${this.getReportContent()}
            </body>
          </html>
        `;
      printWindow.document.write(printDocument);
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
        printWindow.close();
      };
    },
    getReportContent() {
      const reportView = document.querySelector(".printData");
      if (reportView) {
        return reportView.innerHTML;
      }
      return "No content found.";
    },
    async getTransHysNo() {
      this.transHysNo = this.$route.query.data;
      this.data = await getShipmentById(this.transHysNo);
      return true;
    },
  },
  async mounted() {
    const timeout = 30000; // 10 seconds
    try {
      const data = await this.getTransHysNo();
      const timeoutPromise = new Promise((resolve) => setTimeout(resolve, timeout));

      // Wait for either finalPromise or timeoutPromise to resolve
      const result = await Promise.race([data, timeoutPromise]);
      this.isDataLoaded = result !== undefined;
    } catch (error) {
      this.isDataLoaded = false;
    }
  },
};
</script>
