var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bg-dark main-body" }, [
    _c(
      "div",
      {
        staticClass:
          "data-top h-15 d-flex justify-content-between align-items-center",
      },
      [
        _c("div", { staticClass: "w-50 ps-3 d-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "rounded-circle bg-white d-flex ustify-content-center align-items-center",
              staticStyle: { width: "60px", height: "55px" },
            },
            [
              _c("img", {
                staticClass: "ms-1",
                staticStyle: { "max-width": "55px" },
                attrs: { src: _vm.imgLogo, alt: "Logo" },
              }),
            ]
          ),
          _c("div", { staticClass: "vr mx-2 text-white" }),
          _c(
            "span",
            { staticClass: "display-4 text-white d-flex align-items-center" },
            [_vm._v(_vm._s(_vm.usahaTitle))]
          ),
        ]),
        _c("div", { staticClass: "w-25 text-end pe-3" }, [
          _c("span", { staticClass: "text-white display-4" }, [
            _vm._v(_vm._s(_vm.currentTime)),
          ]),
          _c("h5", { staticClass: "text-white" }, [
            _vm._v(_vm._s(_vm.currentDay) + ", " + _vm._s(_vm.currentDate)),
          ]),
        ]),
      ]
    ),
    _c("div", { staticClass: "data-top h-55 d-flex" }, [
      _c(
        "div",
        {
          staticClass:
            "data-left w-50 p-3 d-flex justify-content-between flex-column flex-wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "text-center align-middle py-2 w-100",
              class: _vm.data.currentLoket
                ? _vm.data.currentLoket.loketClass
                : "bg-secondary",
            },
            [
              _c("span", { staticClass: "text-white display-4" }, [
                _vm._v("Antrian Saat Ini"),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "h-50 w-100 d-flex justify-content-center align-items-center",
              class: _vm.data.currentLoket
                ? _vm.data.currentLoket.loketClass
                : "bg-secondary",
            },
            [
              _c("span", { staticClass: "text-white display-1" }, [
                _vm._v(
                  _vm._s(
                    _vm.data.currentLoket
                      ? _vm.data.currentLoket.loketNumber
                      : ""
                  )
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "text-center align-middle py-2 w-100",
              class: _vm.data.currentLoket
                ? _vm.data.currentLoket.loketClass
                : "bg-secondary",
            },
            [
              _c("span", { staticClass: "text-white display-4" }, [
                _vm._v(
                  _vm._s(
                    _vm.data.currentLoket ? _vm.data.currentLoket.loketName : ""
                  )
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._m(0),
    ]),
    _c(
      "div",
      { staticClass: "data-top h-30 d-flex" },
      _vm._l(_vm.data.listLoket, function (data) {
        return _c("div", { staticClass: "h-100 w-25 p-3 pt-0" }, [
          _c(
            "div",
            {
              staticClass: "card text-center w-100 h-100",
              class: data.loketClass,
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "card-body p-3 d-flex flex-column justify-content-between",
                },
                [
                  _c("h5", { staticClass: "card-title text-white" }, [
                    _vm._v("Antrian"),
                  ]),
                  _c("span", { staticClass: "display-3 text-white" }, [
                    _vm._v(_vm._s(data.loketNumber)),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "card-footer py-1 pt-2 h4 text-white" },
                [_vm._v(_vm._s(data.loketName))]
              ),
            ]
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "data-left w-50 p-3" }, [
      _c("iframe", {
        attrs: {
          width: "100%",
          height: "100%",
          src: "https://www.youtube.com/embed/vkQM6p0dC6M?si=9frN2VmDykUu6cjY",
          title: "YouTube video player",
          frameborder: "0",
          allow:
            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
          allowfullscreen: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }