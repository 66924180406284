<template>
  <main id="content" role="main" class="main">
    <transition name="fade" mode="out-in">
      <div v-if="isDataLoaded === ''" key="empty">
        <loading></loading>
      </div>

      <!-- Main Content -->
      <div v-else-if="isDataLoaded" key="truthy">
        <div class="content container-fluid p-3 ps-0 pt-0" ref="contentDiv">
          <div class="row my-1">
            <div class="col-4 d-flex align-items-center">
              <ul class="breadcrumb m-0">
                <li>
                  <router-link to="/dashboard">
                    <a href="#"><i class="bi-house-door"></i></a>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: posts.transRouting }">
                    <a href="#">{{ posts.transTitle }}</a>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="col-4 text-center">
              <span class="h2">List {{ posts.transTitle }}</span>
            </div>
          </div>
          <hr class="my-2" />

          <!-- Content -->
          <template v-if="isDataLoaded">
            <div class="card">
              <div class="card-header bg-light p-2">
                <div class="row d-flex">
                  <div class="col-9 row">
                    <div
                      class="d-flex col-12 align-items-center pe-0 mt-2"
                      v-for="(data, i) in filterList"
                    >
                      <select
                        style="font-size: 12px; max-width: 125px"
                        class="form-select form-select-sm py-1 px-2"
                        v-model="data.filterBaris"
                      >
                        <option value="" v-if="!filterBaris">Filter Baris</option>
                        <option
                          v-for="micro in tableHeader"
                          :value="micro.key"
                          v-if="micro.key !== 'actions'"
                        >
                          {{ micro.label }}
                        </option>
                      </select>
                      <select
                        class="mx-2 form-select form-select-sm py-1 px-2"
                        style="font-size: 12px; max-width: 125px"
                        v-model="data.startFrom"
                      >
                        <option value="" v-if="!data.startFrom">Tipe Pencarian</option>
                        <option :value="micro" v-for="micro in optionQuery">
                          {{ micro }}
                        </option>
                      </select>
                      <div class="input-group" style="width: 150px">
                        <div class="input-group-prepend">
                          <span class="input-group-text py-1 px-2"
                            ><i class="bi-search" style="font-size: 10px"></i
                          ></span>
                        </div>
                        <input
                          type="search"
                          class="form-control form-control-sm p-1"
                          placeholder="Cari Data..."
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          style="font-size: 12px"
                          v-model="data.filter1"
                        />
                      </div>
                      <div class="input-group px-2" style="width: 150px">
                        <div class="input-group-prepend">
                          <span class="input-group-text py-1 px-2"
                            ><i class="bi-search" style="font-size: 10px"></i
                          ></span>
                        </div>
                        <input
                          type="search"
                          class="form-control form-control-sm p-1"
                          placeholder="Cari Data..."
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          style="font-size: 12px"
                          v-model="data.filter2"
                        />
                      </div>
                      <select
                        class="mx-2 form-select form-select-sm py-1"
                        style="font-size: 12px; max-width: 65px"
                        v-model="data.logic"
                      >
                        <option value="and">dan</option>
                        <option value="or">atau</option>
                      </select>
                      <i
                        class="bi-plus-circle text-primary ms-1"
                        style="cursor: pointer"
                        @click="addFilterList"
                      ></i>
                      <i
                        class="bi-x-circle text-danger ms-1"
                        style="cursor: pointer"
                        @click="removeFilterRow(i)"
                      ></i>
                    </div>
                  </div>

                  <div
                    class="col-3 d-flex justify-content-between mt-2 align-items-start p-0"
                    style="z-index: 2"
                  >
                    <button type="button" class="btn-xs btn btn-outline-success">
                      Cari
                    </button>
                    <button type="button" class="btn-xs btn btn-outline-warning">
                      Reset
                    </button>
                    <div class="btn-group me-2">
                      <router-link
                        :to="$route.path + '/add'"
                        class="btn btn-xs btn-primary"
                      >
                        Tambah
                      </router-link>
                      <button
                        type="button"
                        class="btn btn-xs btn-primary dropdown-toggle dropdown-toggle-split"
                        href="javascript:;"
                        id="accountNavbarDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-dropdown-animation
                      ></button>
                      <div class="dropdown">
                        <div
                          class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                          aria-labelledby="accountNavbarDropdown"
                        >
                          <div class="flex-grow-1 ms-3">
                            <p class="card-text text-body" style="font-size: 12px">
                              Import file
                            </p>
                          </div>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item" href="#"> json</a>
                          <a class="dropdown-item" href="#">xls</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-2">
                <div class="d-flex align-items-center justify-content-between">
                  <div
                    style="font-size: 11px; width: 300px"
                    class="d-flex align-items-center justify-content-between"
                  >
                    Menampilkan 1 - 10 data dari {{ rows }}
                    <select
                      class="form-select py-1"
                      aria-label="Default select example"
                      style="width: 75px; font-size: 12px; padding: 0 0.5rem !important"
                    >
                      <option v-for="data in pageList" :value="data">
                        {{ data }}
                      </option>
                    </select>
                  </div>
                  <b-pagination
                    class="m-0"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    ><template #first-text
                      ><button class="btn btn-outline-primary btn-xs">
                        First
                      </button></template
                    >
                    <template #prev-text
                      ><button class="btn btn-outline-primary btn-xs">
                        Prev
                      </button></template
                    >
                    <template #next-text
                      ><button class="btn btn-outline-primary btn-xs">
                        Next
                      </button></template
                    >
                    <template #last-text
                      ><button class="btn btn-outline-primary btn-xs">
                        Last
                      </button></template
                    >
                    <template #ellipsis-text> ... </template>
                    <template #page="{ page, active }">
                      <b v-if="active">{{ page }}</b>
                      <i v-else>{{ page }}</i>
                    </template></b-pagination
                  >
                </div>
                <hr class="my-2" />
                <!-- tabel view untuk detil -->
                <b-table
                  striped
                  hover
                  :items="dataListJE"
                  :fields="tableHeader"
                  label-sort-asc=""
                  label-sort-desc=""
                  class="myTable listTable"
                >
                  <template #cell(PoSupplier)="data">
                    {{ data.item.PoSupplier.userName }}
                  </template>
                  <template #cell(coaTrans[0].transEffDate)="data">
                    {{ formatIndDate(data.item.coaTrans[0].transEffDate) }}
                  </template>
                  <template #cell(uraian)="data">
                    {{ data.item.coaTrans[0].transDescription }}
                  </template>
                  <template #cell(debet)="data">
                    <template v-if="data.item.coaTrans[0].transType === 'debet'">
                      {{ formatNumberInt(data.item.coaTrans[0].transAmount) }}
                    </template>
                    <template v-else> 0 </template>
                  </template>
                  <template #cell(kredit)="data">
                    <template v-if="data.item.coaTrans[0].transType === 'kredit'">
                      {{ formatNumberInt(data.item.coaTrans[0].transAmount) }}
                    </template>
                    <template v-else> 0 </template>
                  </template>
                  <!-- <template #cell(coaTrans)="data">
                <div class="inner-table">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Kode COA</th>
                        <th>Nama COA</th>
                        <th>Jumlah (Rp)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in data.item.coaTrans" :key="index">
                        <td>{{ item.transCoaCode }}</td>
                        <td>{{ item.transCoaName }}</td>
                        <td>{{ formatNumberInt(item.transAmount) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template> -->
                  <template v-slot:cell(actions)="data">
                    <b-dropdown id="dropdown-option" right size="xs" variant="primary">
                      <template #button-content class="px-1 py-0">
                        <i class="bi-gear px-1 py-0"></i>
                      </template>
                      <b-dropdown-item @click="detilAction(data.item)"
                        >Detail</b-dropdown-item
                      >
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </div>
          </template>
          <div v-else>Loading...</div>
        </div>
      </div>

      <!-- Error -->
      <div v-else key="other">
        <connection-timeout></connection-timeout>
      </div>
    </transition>
  </main>
</template>

<script>
// import router from "@/router";
import { get1, getJE, getJEbyVoucher } from "../components/services/components";
import { formatNumberInt } from "../components/function/number";
import { formatIndDate } from "../components/function/date";

import loading from "../components/loading.vue";
import connectionTimeout from "../components/connectionTimeout.vue";
export default {
  components: {
    loading,
    connectionTimeout,
  },
  data() {
    return {
      // pagination
      rows: 3,
      perPage: 10,
      currentPage: 1,
      optionQuery: ["start at", "equal", "include"],
      pageList: ["10", "25", "50", "Semua"],
      // ===
      listUser: [],
      filterList: [],
      filterText: "",
      sendData: {},
      transData: [],
      contentDivWidth: 0,
      routing: "",
      tableHeader: [],
      component: [],
      dataListJE: [],
      isDataLoaded: "",
      posts: [],
    };
  },
  async mounted() {
    const timeout = 30000; // 10 seconds
    try {
      this.addFilterList();
      this.posts = await get1(this.$route.name);
      (this.tableHeader = [
        {
          key: "coaTrans[0].transNo",
          label: "Journal Code",
          sortable: true,
          class: "align-top",
          thClass: "text-center",
          thStyle: "width:125px",
        },
        {
          key: "coaCode",
          label: "COA Code",
          sortable: true,
          class: "align-top",
          thClass: "text-center",
          thStyle: "width:85px",
        },
        {
          key: "coaName",
          label: "COA Name",
          sortable: true,
          class: "align-top",
          thClass: "text-center",
        },
        {
          key: "uraian",
          label: "Uraian",
          sortable: true,
          class: "align-top text-center",
          thClass: "",
          thStyle: "width:85px",
        },
        {
          key: "debet",
          label: "Debet",
          sortable: true,
          class: "align-top text-center",
          thClass: "",
        },
        {
          key: "kredit",
          label: "Kredit",
          sortable: true,
          class: "align-top text-center",
          thClass: "",
        },
        {
          key: "coaTrans[0].transEffDate",
          label: "Trans Date",
          sortable: true,
          class: "align-top text-center",
          thClass: "",
          thStyle: "width:100px",
        },
        {
          key: "actions",
          label: "Actions",
          class: "align-top",
          thClass: "text-center",
          thStyle: "width:75px",
        },
      ]),
        // this.listUser = await getUser()
        // this.listSupplier = await getSupplier()
        (this.dataListJE = await getJE());
      const timeoutPromise = new Promise((resolve) => setTimeout(resolve, timeout));

      // Wait for either finalPromise or timeoutPromise to resolve
      const result = await Promise.race([this.dataListJE, timeoutPromise]);
      this.isDataLoaded = result !== undefined;
    } catch (error) {
      this.isDataLoaded = false;
    }
  },
  computed: {
    filteredTableData() {
      const filterText = this.filterText.toLowerCase().trim();
      return this.data.filter((row) => {
        for (let key in row) {
          const value = String(row[key]);
          if (value.toLowerCase().includes(filterText)) {
            return true;
          }
        }
        return false;
      });
    },
  },
  methods: {
    formatIndDate(data) {
      return formatIndDate(data);
    },
    formatNumberInt(data) {
      return formatNumberInt(data);
    },
    addFilterList() {
      const data = {
        filterBaris: "",
        startFrom: "",
        filter1: "",
        logic: "",
        filter2: "",
      };
      this.filterList.push(data);
    },

    removeFilterRow(data) {
      this.filterList.splice(data, 1);
    },
    async detilAction(sonum) {
      const tes = await getJEbyVoucher(encodeURIComponent(sonum.coaTrans[0].transNo));

      this.$router.push({
        name: this.$route.name + "-detail",
        query: { data: JSON.stringify(tes) },
      });
    },
  },
};
</script>
<style scope>
.listTable {
  font-size: 11px;
}
.inner-table table thead tr th {
  font-size: 10px;
}
</style>
