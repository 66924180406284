var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c(
        "audio",
        { ref: "audioElement", staticClass: "d-none", attrs: { controls: "" } },
        [
          _c("source", {
            attrs: {
              src: require("../assets/audio/bell.mp3"),
              type: "audio/mpeg",
            },
          }),
          _vm._v(" Your browser does not support the audio element. "),
        ]
      ),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.isDataLoaded === ""
          ? _c("div", { key: "empty" }, [_c("loading")], 1)
          : _vm.isDataLoaded
          ? _c("div", { key: "truthy" }, [
              _c(
                "div",
                { staticClass: "content container-fluid p-3 ps-0 pt-0" },
                [
                  _c("div", { staticClass: "row my-1" }, [
                    _c(
                      "div",
                      { staticClass: "col-4 d-flex align-items-center" },
                      [
                        _c("ul", { staticClass: "breadcrumb m-0" }, [
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "/dashboard" } },
                                [
                                  _c("a", { attrs: { href: "#" } }, [
                                    _c("i", { staticClass: "bi-house-door" }),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: _vm.$route.path } },
                                [
                                  _c("a", { attrs: { href: "#" } }, [
                                    _vm._v("Resep Masuk Harian"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "col-4 text-center" }),
                  ]),
                  _c("hr", { staticClass: "my-2" }),
                  _vm.isDataLoaded
                    ? [
                        _c(
                          "div",
                          { staticClass: "card" },
                          [
                            _c(
                              "div",
                              { staticClass: "card-header bg-light p-2" },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row col-10 d-flex align-items-center justify-content-between",
                                    },
                                    [
                                      _c("div", { staticClass: "w-25" }, [
                                        _c("b", [
                                          _vm._v("TOTAL DATA: "),
                                          _vm.isRefreshLoaded === ""
                                            ? _c("div", {
                                                staticClass:
                                                  "spinner-border spinner-border-sm",
                                                attrs: { role: "status" },
                                              })
                                            : _c("span", [
                                                _vm._v(_vm._s(_vm.totalData)),
                                              ]),
                                        ]),
                                      ]),
                                      _c(
                                        "b-form-group",
                                        { staticStyle: { width: "200px" } },
                                        [
                                          _c("b-form-input", {
                                            staticClass: "form-control-sm py-1",
                                            attrs: { type: "date" },
                                            on: { change: _vm.getData },
                                            model: {
                                              value: _vm.getDate,
                                              callback: function ($$v) {
                                                _vm.getDate = $$v
                                              },
                                              expression: "getDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-2 d-flex justify-content-between ps-0",
                                      staticStyle: { "z-index": "2" },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-light px-2 py-0",
                                          attrs: { type: "button" },
                                          on: { click: _vm.refreshData },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "bi-arrow-repeat",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "b-button-group",
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-primary btn-xs",
                                              on: { click: _vm.printReport },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bi-printer pe-2",
                                              }),
                                              _vm._v("Print "),
                                            ]
                                          ),
                                          _c(
                                            "b-dropdown",
                                            {
                                              attrs: {
                                                id: "dropdown2",
                                                variant: "primary",
                                                size: "xs",
                                                right: "",
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "p-2 pb-0 m-0",
                                                  },
                                                  [_vm._v("Export")]
                                                ),
                                              ]),
                                              _c("b-dropdown-divider"),
                                              _c(
                                                "b-dropdown-item",
                                                {
                                                  on: {
                                                    click: _vm.generatePDF,
                                                  },
                                                },
                                                [_vm._v("PDF")]
                                              ),
                                              _c("b-dropdown-item", [
                                                _vm._v("xls"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "transition",
                              { attrs: { name: "fade", mode: "out-in" } },
                              [
                                _vm.isRefreshLoaded === ""
                                  ? _c(
                                      "div",
                                      { key: "empty" },
                                      [_c("loading")],
                                      1
                                    )
                                  : _vm.isRefreshLoaded
                                  ? _c("div", { key: "truthy" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card-body p-2 printData",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "h2" },
                                                [
                                                  _vm._v(
                                                    "List Resep Masuk Harian"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass:
                                              "d-flex align-items-center justify-content-between",
                                          }),
                                          _c("hr", { staticClass: "my-2" }),
                                          _c("b-table", {
                                            staticClass: "myTable listTable",
                                            attrs: {
                                              striped: "",
                                              hover: "",
                                              items: _vm.liveData,
                                              fields: _vm.tableHeader,
                                              "label-sort-asc": "",
                                              "label-sort-desc": "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "cell(PoSupplier)",
                                                  fn: function (data) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.item.PoSupplier
                                                              .userName
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(transIdMember)",
                                                  fn: function (data) {
                                                    return [
                                                      data.item.transIdMember
                                                        ? [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.item
                                                                    .transIdMember
                                                                    .userAddress[0]
                                                                    .location
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(transPesanan)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "inner-table",
                                                        },
                                                        [
                                                          _c(
                                                            "table",
                                                            {
                                                              staticClass:
                                                                "table table-bordered",
                                                            },
                                                            [
                                                              _c("thead", [
                                                                _c("tr", [
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Nama Barang"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c("th", [
                                                                    _vm._v(
                                                                      "Qty"
                                                                    ),
                                                                  ]),
                                                                  _c("th", [
                                                                    _vm._v(
                                                                      "Harga"
                                                                    ),
                                                                  ]),
                                                                  _c("th", [
                                                                    _vm._v(
                                                                      "Total"
                                                                    ),
                                                                  ]),
                                                                ]),
                                                              ]),
                                                              _c(
                                                                "tbody",
                                                                _vm._l(
                                                                  data.item
                                                                    .transPesanan,
                                                                  function (
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "tr",
                                                                      {
                                                                        key: index,
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.itemName
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.itemQty
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.formatNumberInt(
                                                                                    item
                                                                                      .itemMasterPrice
                                                                                      .priceValue
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.formatNumberInt(
                                                                                    item
                                                                                      .itemMasterPrice
                                                                                      .priceValue *
                                                                                      item.itemQty
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(actions)",
                                                  fn: function (data) {
                                                    return [
                                                      data.item
                                                        .transStatusSO ===
                                                      "resep"
                                                        ? _c(
                                                            "b-dropdown",
                                                            {
                                                              attrs: {
                                                                id: "dropdown-option",
                                                                right: "",
                                                                size: "xs",
                                                                variant:
                                                                  "primary",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "button-content",
                                                                      fn: function () {
                                                                        return [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "bi-gear px-1 py-0",
                                                                            }
                                                                          ),
                                                                        ]
                                                                      },
                                                                      proxy: true,
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c(
                                                                "b-dropdown-item",
                                                                {
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.detilAction(
                                                                          data.item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("Edit")]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2993118858
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _c(
                                      "div",
                                      { key: "other" },
                                      [_c("connection-timeout")],
                                      1
                                    ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    : _c("div", [_vm._v("Loading...")]),
                ],
                2
              ),
            ])
          : _c("div", { key: "other" }, [_c("connection-timeout")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }