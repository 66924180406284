var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report", staticStyle: { "font-size": "10px" } },
    [
      _c(
        "table",
        { staticClass: "table table-bordered z-1 position-relative" },
        [
          _vm._m(0),
          _vm.realData.length > 0
            ? _c(
                "tbody",
                [
                  _vm._l(_vm.realData, function (entry) {
                    return _c("tr", [
                      _c("td", [_vm._v(_vm._s(entry.coaCode))]),
                      _c("td", [_vm._v(_vm._s(entry.coaName))]),
                      _c("td", [
                        entry.coaPlus === "debet"
                          ? _c("div", [
                              _vm._v(
                                " Rp." +
                                  _vm._s(
                                    _vm.formatNumber(
                                      entry.coaFaAmount.FaPriceBegining
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("div", [_vm._v(" Rp. 0.00 ")]),
                      ]),
                      _c("td", [
                        entry.coaPlus === "kredit"
                          ? _c("div", [
                              _vm._v(
                                " Rp." +
                                  _vm._s(
                                    _vm.formatNumber(
                                      entry.coaFaAmount.FaPriceBegining
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("div", [_vm._v(" Rp. 0.00 ")]),
                      ]),
                      _c(
                        "td",
                        [
                          _vm._v(" Rp. "),
                          entry.coaFaAmount.FaPriceIn !== null
                            ? [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatNumber(
                                      entry.coaFaAmount.FaPriceIn
                                    )
                                  )
                                ),
                              ]
                            : [_vm._v("0.00")],
                        ],
                        2
                      ),
                      _c("td", [
                        _vm._v(
                          "Rp. " +
                            _vm._s(
                              _vm.formatNumber(entry.coaFaAmount.FaPriceOut)
                            )
                        ),
                      ]),
                      _c(
                        "td",
                        [
                          _vm.getSaldoAkhir(
                            entry.coaPlus,
                            entry.coaFaAmount.FaPriceBegining,
                            entry.coaFaAmount.FaPriceIn,
                            entry.coaFaAmount.FaPriceOut
                          ) < 0
                            ? [_vm._v(" Rp. 0.00 ")]
                            : [
                                _vm._v(
                                  " Rp. " +
                                    _vm._s(
                                      _vm.formatNumber(
                                        _vm.getSaldoAkhir(
                                          entry.coaPlus,
                                          entry.coaFaAmount.FaPriceBegining,
                                          entry.coaFaAmount.FaPriceIn,
                                          entry.coaFaAmount.FaPriceOut
                                        )
                                      )
                                    ) +
                                    " "
                                ),
                              ],
                        ],
                        2
                      ),
                      _c(
                        "td",
                        [
                          _vm.getSaldoAkhir(
                            entry.coaPlus,
                            entry.coaFaAmount.FaPriceBegining,
                            entry.coaFaAmount.FaPriceIn,
                            entry.coaFaAmount.FaPriceOut
                          ) < 0
                            ? [
                                _vm._v(
                                  " Rp. " +
                                    _vm._s(
                                      _vm.formatNumber(
                                        _vm.getSaldoAkhir(
                                          entry.coaPlus,
                                          entry.coaFaAmount.FaPriceBegining,
                                          entry.coaFaAmount.FaPriceIn,
                                          entry.coaFaAmount.FaPriceOut
                                        ) * -1
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            : [_vm._v(" Rp. 0.00 ")],
                        ],
                        2
                      ),
                    ])
                  }),
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "centered-text", attrs: { colspan: "2" } },
                      [_vm._v("Jumlah")]
                    ),
                    _c("td"),
                    _c("td"),
                    _c("td", [_vm._v("Rp. " + _vm._s(_vm.totalDebet))]),
                    _c("td", [_vm._v("Rp. " + _vm._s(_vm.totalKredit))]),
                    _c("td"),
                    _c("td"),
                  ]),
                ],
                2
              )
            : _c("tbody", [_vm._m(1)]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "sticky-element" }, [
      _c("tr", [
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "80" } },
          [_c("b", [_vm._v("Kode COA")])]
        ),
        _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
          _c("b", [_vm._v("Nama COA")]),
        ]),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "100" } },
          [_c("b", [_vm._v("Saldo Awal Debet")])]
        ),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "100" } },
          [_c("b", [_vm._v("Saldo Awal Kredit")])]
        ),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "100" } },
          [_c("b", [_vm._v("Mutasi Debet")])]
        ),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "100" } },
          [_c("b", [_vm._v("Mutasi Kredit")])]
        ),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "100" } },
          [_c("b", [_vm._v("Saldo Akhir Debet")])]
        ),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "100" } },
          [_c("b", [_vm._v("Saldo Akhir Kredit")])]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("Data Tidak Ditemukan")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }