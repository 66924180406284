var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report", staticStyle: { "font-size": "10px" } },
    [
      _c(
        "table",
        { staticClass: "table table-bordered z-1 position-relative" },
        [
          _c("thead", [
            _c(
              "tr",
              [
                _vm._m(0),
                _vm._m(1),
                _vm._m(2),
                _vm._l(_vm.data.saldo, function (item) {
                  return _c(
                    "th",
                    {
                      key: item.coaName,
                      staticClass: "text-center align-middle",
                      attrs: { scope: "col", width: "100px" },
                    },
                    [_c("b", [_vm._v(_vm._s(item.coaName))])]
                  )
                }),
              ],
              2
            ),
          ]),
          _vm.data.saldo
            ? _c(
                "tbody",
                [
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticStyle: { "font-size": "12px" },
                        attrs: { colspan: 3 + _vm.data.saldo.length },
                      },
                      [_c("b", [_vm._v("SALDO AWAL KEUANGAN")])]
                    ),
                  ]),
                  _vm._l(_vm.data.saldo, function (saldo) {
                    return _c(
                      "tr",
                      [
                        _c("td", [_vm._v(_vm._s(saldo.coaCode))]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.formatNumber(saldo.coaName))),
                        ]),
                        _c("td", { staticClass: "text-end" }, [
                          _vm._v(_vm._s(_vm.formatNumber(saldo.FaPriceEnd))),
                        ]),
                        _vm._l(_vm.data.saldo, function (item) {
                          return _c(
                            "td",
                            { staticClass: "text-end" },
                            [
                              saldo.coaCode === item.coaCode
                                ? [
                                    _vm._v(
                                      _vm._s(_vm.formatNumber(saldo.FaPriceEnd))
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    )
                  }),
                  _c(
                    "tr",
                    [
                      _vm._m(3),
                      _c("td", { staticClass: "text-end" }, [
                        _c("b", [_vm._v(_vm._s(_vm.totalSaldoAwal))]),
                      ]),
                      _vm._l(_vm.data.saldo, function (item) {
                        return _c("td", { staticClass: "text-end" }, [
                          _c("b", [
                            _vm._v(_vm._s(_vm.formatNumber(item.FaPriceEnd))),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticStyle: { "font-size": "12px" },
                        attrs: { colspan: 3 + _vm.data.saldo.length },
                      },
                      [_c("b", [_vm._v("PENERIMAAN KEUANGAN")])]
                    ),
                  ]),
                  _vm._l(_vm.filterIn, function (saldo) {
                    return _c(
                      "tr",
                      [
                        _c("td", [_vm._v(_vm._s(saldo.transCoaCode))]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.formatNumber(saldo.transCoaName))),
                        ]),
                        _c("td", { staticClass: "text-end" }, [
                          _vm._v(_vm._s(_vm.formatNumber(saldo.transAmount))),
                        ]),
                        _vm._l(_vm.data.saldo, function (item) {
                          return _c(
                            "td",
                            { staticClass: "text-end" },
                            [
                              _vm._l(saldo.filter, function (total) {
                                return [
                                  total.coaCode === item.coaCode
                                    ? [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatNumber(total.transAmount)
                                          )
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    )
                  }),
                  _c(
                    "tr",
                    [
                      _vm._m(4),
                      _c("td", { staticClass: "text-end" }, [
                        isNaN(_vm.totalSaldoIn)
                          ? _c("b", [_vm._v(_vm._s(_vm.totalSaldoIn))])
                          : _c("b", [_vm._v("0.00")]),
                      ]),
                      _vm._l(_vm.data.saldo, function (item) {
                        return _c("td", { staticClass: "text-end" }, [
                          isNaN(item.coaCode)
                            ? _c("b", [
                                _vm._v(_vm._s(_vm.jumlahIn(item.coaCode))),
                              ])
                            : _c("b", [_vm._v("0.00")]),
                        ])
                      }),
                    ],
                    2
                  ),
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticStyle: { "font-size": "12px" },
                        attrs: { colspan: 3 + _vm.data.saldo.length },
                      },
                      [_c("b", [_vm._v("PENGELUARAN KEUANGAN")])]
                    ),
                  ]),
                  _vm._l(_vm.filterOut, function (saldo) {
                    return _c(
                      "tr",
                      [
                        _c("td", [_vm._v(_vm._s(saldo.transCoaCode))]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.formatNumber(saldo.transCoaName))),
                        ]),
                        _c("td", { staticClass: "text-end" }, [
                          _vm._v(_vm._s(_vm.formatNumber(saldo.transAmount))),
                        ]),
                        _vm._l(_vm.data.saldo, function (item) {
                          return _c(
                            "td",
                            { staticClass: "text-end" },
                            [
                              _vm._l(saldo.filter, function (total) {
                                return [
                                  total.coaCode === item.coaCode
                                    ? [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatNumber(total.transAmount)
                                          )
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    )
                  }),
                  _c(
                    "tr",
                    [
                      _vm._m(5),
                      _c("td", { staticClass: "text-end" }, [
                        _c("b", [_vm._v(_vm._s(_vm.totalSaldoOut))]),
                      ]),
                      _vm._l(_vm.data.saldo, function (item) {
                        return _c("td", { staticClass: "text-end" }, [
                          _c("b", [
                            _vm._v(_vm._s(_vm.jumlahOut(item.coaCode))),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticStyle: { "font-size": "12px" },
                        attrs: { colspan: 3 + _vm.data.saldoAkhir.length },
                      },
                      [_c("b", [_vm._v("SALDO AKHIR KEUANGAN")])]
                    ),
                  ]),
                  _vm._l(_vm.data.saldoAkhir, function (saldo) {
                    return _c(
                      "tr",
                      [
                        _c("td", [_vm._v(_vm._s(saldo.coaCode))]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.formatNumber(saldo.coaName))),
                        ]),
                        !isNaN(saldo.FaPriceEnd)
                          ? _c("td", { staticClass: "text-end" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatNumber(saldo.FaPriceEnd)) +
                                  " "
                              ),
                            ])
                          : _c("td", { staticClass: "text-end" }, [
                              _vm._v("0.00"),
                            ]),
                        _vm._l(_vm.data.saldo, function (item) {
                          return _c(
                            "td",
                            { staticClass: "text-end" },
                            [
                              saldo.coaCode === item.coaCode &&
                              !isNaN(saldo.FaPriceEnd)
                                ? [
                                    _vm._v(
                                      _vm._s(_vm.formatNumber(saldo.FaPriceEnd))
                                    ),
                                  ]
                                : [_vm._v("0.00")],
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    )
                  }),
                  _c(
                    "tr",
                    [
                      _vm._m(6),
                      _c("td", { staticClass: "text-end" }, [
                        _c("b", [_vm._v(_vm._s(_vm.totalSaldoAkhir))]),
                      ]),
                      _vm._l(_vm.data.saldoAkhir, function (item) {
                        return _c("td", { staticClass: "text-end" }, [
                          _c("b", [
                            _vm._v(_vm._s(_vm.formatNumber(item.FaPriceEnd))),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                ],
                2
              )
            : _c("tbody", [_vm._m(7)]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      {
        staticClass: "text-center align-middle",
        attrs: { scope: "col", width: "110" },
      },
      [_c("b", [_vm._v("COA")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "text-center align-middle", attrs: { scope: "col" } },
      [_c("b", [_vm._v("Uraian")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      {
        staticClass: "text-center align-middle",
        attrs: { scope: "col", width: "100" },
      },
      [_c("b", [_vm._v("Total")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { attrs: { colspan: "2" } }, [_c("b", [_vm._v("Jumlah")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { attrs: { colspan: "2" } }, [_c("b", [_vm._v("Jumlah")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { attrs: { colspan: "2" } }, [_c("b", [_vm._v("Jumlah")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { attrs: { colspan: "2" } }, [_c("b", [_vm._v("Jumlah")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("Data Tidak Ditemukan")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }