<template>
  <div class="report p-3" style="font-size: 18px; width: 100%; font-family: sans-serif">
    <!-- Title -->
    <h1 class="text-center pt-4"><b>KLINIK UTAMA MUTIARA AISHA</b></h1>
    <p class="text-center">JL. KI HAJAR DEWANTARA 04 SELOSARI MAGETAN</p>
    <p class="text-center">082331 200 500</p>
    <!-- End of Title -->

    <!-- Header -->
    <div class="row pb-1">
      <div class="col-3" style="font-size: 20px">Nm. Pasien</div>
      <div class="col-9" style="font-size: 20px">:</div>
      <div class="col-3" style="font-size: 20px">Nomor RM</div>
      <div class="col-9" style="font-size: 20px">:</div>
      <div class="col-3" style="font-size: 20px">Alamat</div>
      <div class="col-9" style="font-size: 20px">:</div>
      <div class="col-3" style="font-size: 20px">Dokter</div>
      <div class="col-9" style="font-size: 20px">:</div>
      <div class="col-3" style="font-size: 20px">Poli</div>
      <div class="col-9" style="font-size: 20px">:</div>
    </div>
    <div class="w-100 border-dark border-top border-bottom" style="height: 0.25rem"></div>
    <div class="w-100">
      <table class="w-100">
        <thead class="border-bottom border-dark">
          <tr>
            <th style="font-size: 20px">TRANSAKSI</th>
            <th class="text-end" style="font-size: 20px">QTY</th>
            <th class="text-end" style="font-size: 20px">DISC</th>
            <th class="text-end" style="font-size: 20px">JUMLAH</th>
          </tr>
        </thead>
        <tbody class="border-bottom border-dark">
          <tr>
            <td style="font-size: 20px">azam</td>
            <td class="text-end" style="font-size: 20px">1</td>
            <td class="text-end" style="font-size: 20px">5000</td>
            <td class="text-end" style="font-size: 20px">30000</td>
          </tr>
        </tbody>
      </table>
      <div class="row d-flex justify-content-between">
        <div class="col-6 row">
          <div class="col-5" style="font-size: 18px">Tanggal</div>
          <div class="col-7" style="font-size: 18px">: 07-10-2023</div>
          <div class="col-5 pe-0" style="font-size: 18px">No. Nota</div>
          <div class="col-7 pe-0" style="font-size: 18px">: 2310070007</div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-6 d-flex justify-content-between">
              SUBTOTAL <span>:</span>
            </div>
            <div class="col-6 text-end" style="font-size: 18px">445.000,00</div>
            <div class="col-6 d-flex justify-content-between" style="font-size: 18px">
              DISC <span>:</span>
            </div>
            <div class="col-6 text-end" style="font-size: 18px">0,00</div>
            <div class="col-6 d-flex justify-content-between" style="font-size: 18px">
              <b>TOTAL</b> <span>:</span>
            </div>
            <div class="col-6 text-end" style="font-size: 18px"><b>445.000,00</b></div>
          </div>
        </div>
      </div>
      <div
        class="w-100 border-dark border-top border-bottom"
        style="height: 0.25rem"
      ></div>
      <p><b>Terbilang :</b> Empat Ratus Empat Puluh Lima Ribu lima ratus Rupiah</p>
      <div class="text-center pt-3">
        <h2>
          Terima kasih atas kunjungannya
          <p>Semoga Lekas Sembuh</p>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import htmlToText from "html-to-text";
import { formatNumber } from "../function/number";
export default {
  props: {
    saldoAwal: String,
    data: Array,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>

<style scoped></style>
