var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.isDataLoaded === ""
          ? _c("div", { key: "empty" }, [_c("loading")], 1)
          : _vm.isDataLoaded
          ? _c(
              "div",
              { key: "truthy" },
              [
                _c("ul", { staticClass: "breadcrumb m-1 mt-0" }, [
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/dashboard" } }, [
                        _c("a", { attrs: { href: "#" } }, [
                          _c("i", { staticClass: "bi-house-door" }),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("li", [_vm._v("Setup")]),
                ]),
                _c(
                  "div",
                  { staticClass: "content container-fluid" },
                  [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 mb-4 d-flex justify-content-between align-items-center",
                          },
                          [
                            _c("span"),
                            _c("span", { staticClass: "display-6" }, [
                              _vm._v(_vm._s(_vm.title)),
                            ]),
                            !_vm.isEditing
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-xs btn-success",
                                    attrs: { type: "button" },
                                    on: { click: _vm.toggleEditForAll },
                                  },
                                  [_vm._v("Edit ")]
                                )
                              : _c("div", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-xs me-2 btn btn-secondary",
                                      attrs: { type: "button" },
                                      on: { click: _vm.cancelEditForAll },
                                    },
                                    [_vm._v("Batal ")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-xs me-2 btn btn-success me-2",
                                      attrs: { type: "button" },
                                      on: { click: _vm.saveChangesForAll },
                                    },
                                    [_vm._v("Simpan ")]
                                  ),
                                ]),
                          ]
                        ),
                        _c(
                          "b-card",
                          {
                            staticClass: "custom-card p-0",
                            scopedSlots: _vm._u([
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c(
                                      "h4",
                                      { staticClass: "m-0 text-white" },
                                      [_vm._v("User Mode")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "col-8" },
                              _vm._l(_vm.dataJson, function (value, key) {
                                return _c(
                                  "div",
                                  { key: key, staticClass: "row" },
                                  [
                                    _vm.isObject(value)
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-content-between align-items-center my-2",
                                            },
                                            [
                                              _c("b", [_vm._v(_vm._s(key))]),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-outline-secondary btn-xs",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toggleSection(
                                                        key
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "pe-1",
                                                    class: _vm.showSections[key]
                                                      ? "bi-eye-slash-fill"
                                                      : "bi-eye-fill",
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.showSections[key]
                                                          ? "Hide"
                                                          : "Show"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("hr", { staticClass: "m-0" }),
                                          _vm.showSections[key]
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  value,
                                                  function (
                                                    innerValue,
                                                    innerKey
                                                  ) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: innerKey,
                                                        staticClass: "row p-2",
                                                      },
                                                      [
                                                        _vm.isObject(innerValue)
                                                          ? [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex justify-content-between align-items-center my-2",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        innerKey
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn btn-outline-secondary btn-xs",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.toggleSection(
                                                                              key +
                                                                                innerKey
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "pe-1",
                                                                        class:
                                                                          _vm
                                                                            .showSections[
                                                                            key +
                                                                              innerKey
                                                                          ]
                                                                            ? "bi-eye-slash-fill"
                                                                            : "bi-eye-fill",
                                                                      }),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .showSections[
                                                                              key +
                                                                                innerKey
                                                                            ]
                                                                              ? "Hide"
                                                                              : "Show"
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm.showSections[
                                                                key + innerKey
                                                              ]
                                                                ? _c(
                                                                    "div",
                                                                    _vm._l(
                                                                      innerValue,
                                                                      function (
                                                                        nestedValue,
                                                                        nestedKey
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key: nestedKey,
                                                                            staticClass:
                                                                              "row p-2",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "label",
                                                                              {
                                                                                staticClass:
                                                                                  "col-4 d-flex justify-content-between align-items-center my-2 pe-0",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    nestedKey
                                                                                  ) +
                                                                                    " "
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      ":"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col-6 my-2",
                                                                              },
                                                                              [
                                                                                _vm.isEditable(
                                                                                  key +
                                                                                    innerKey +
                                                                                    nestedKey
                                                                                )
                                                                                  ? [
                                                                                      _c(
                                                                                        "b-form-input",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              type: "text",
                                                                                            },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .editedData[
                                                                                                  key +
                                                                                                    innerKey +
                                                                                                    nestedKey
                                                                                                ],
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm.editedData,
                                                                                                    key +
                                                                                                      innerKey +
                                                                                                      nestedKey,
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "editedData[key + innerKey + nestedKey]",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  : [
                                                                                      _c(
                                                                                        "b-form-input",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              disabled:
                                                                                                !_vm.isEditing,
                                                                                              type: "text",
                                                                                            },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                value[
                                                                                                  innerKey
                                                                                                ][
                                                                                                  nestedKey
                                                                                                ],
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    value[
                                                                                                      innerKey
                                                                                                    ],
                                                                                                    nestedKey,
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "value[innerKey][nestedKey]",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                              ],
                                                                              2
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          : [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "col-4 d-flex justify-content-between align-items-center my-2 pe-0",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "- " +
                                                                      _vm._s(
                                                                        innerKey
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c("span", [
                                                                    _vm._v(":"),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-6 my-2",
                                                                },
                                                                [
                                                                  _vm.isEditable(
                                                                    key +
                                                                      innerKey
                                                                  )
                                                                    ? [
                                                                        _c(
                                                                          "b-form-input",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "text",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .editedData[
                                                                                    key +
                                                                                      innerKey
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.editedData,
                                                                                      key +
                                                                                        innerKey,
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "editedData[key + innerKey]",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    : [
                                                                        _c(
                                                                          "b-form-input",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  !_vm.isEditing,
                                                                                type: "text",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  value[
                                                                                    innerKey
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      value,
                                                                                      innerKey,
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "value[innerKey]",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                ],
                                                                2
                                                              ),
                                                            ],
                                                      ],
                                                      2
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ]
                                      : Array.isArray(value)
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-content-between align-items-center my-2",
                                            },
                                            [
                                              _c("b", [_vm._v(_vm._s(key))]),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-outline-secondary btn-xs",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toggleArray(
                                                        key
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "pe-1",
                                                    class: _vm.showArrays[key]
                                                      ? "bi-eye-slash-fill"
                                                      : "bi-eye-fill",
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.showArrays[key]
                                                          ? "Hide"
                                                          : "Show"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("hr", { staticClass: "m-0" }),
                                          _vm.showArrays[key]
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  value,
                                                  function (item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass: "m-2",
                                                      },
                                                      [
                                                        _vm.isObject(item)
                                                          ? [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex justify-content-between align-items-center my-2",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Data " +
                                                                        _vm._s(
                                                                          index +
                                                                            1
                                                                        )
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn btn-outline-secondary btn-xs",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.toggleArrayItem(
                                                                              key,
                                                                              index
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "pe-0",
                                                                        class:
                                                                          _vm
                                                                            .showArrayItems[
                                                                            key
                                                                          ] &&
                                                                          _vm
                                                                            .showArrayItems[
                                                                            key
                                                                          ][
                                                                            index
                                                                          ]
                                                                            ? "bi-eye-slash-fill"
                                                                            : "bi-eye-fill",
                                                                      }),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm
                                                                .showArrayItems[
                                                                key
                                                              ] &&
                                                              _vm
                                                                .showArrayItems[
                                                                key
                                                              ][index]
                                                                ? _c(
                                                                    "div",
                                                                    _vm._l(
                                                                      item,
                                                                      function (
                                                                        nestedValue,
                                                                        nestedKey
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key: nestedKey,
                                                                            staticClass:
                                                                              "row p-2",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "label",
                                                                              {
                                                                                staticClass:
                                                                                  "col-4 d-flex justify-content-between align-items-center my-2 pe-0",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    nestedKey
                                                                                  ) +
                                                                                    " "
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      ":"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col-6 my-2",
                                                                              },
                                                                              [
                                                                                _vm.isEditable(
                                                                                  key +
                                                                                    index +
                                                                                    nestedKey
                                                                                )
                                                                                  ? [
                                                                                      _c(
                                                                                        "b-form-input",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              type: "text",
                                                                                            },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .editedData[
                                                                                                  key +
                                                                                                    index +
                                                                                                    nestedKey
                                                                                                ],
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm.editedData,
                                                                                                    key +
                                                                                                      index +
                                                                                                      nestedKey,
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "editedData[key + index + nestedKey]",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  : [
                                                                                      _c(
                                                                                        "b-form-input",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              disabled:
                                                                                                !_vm.isEditing,
                                                                                              type: "text",
                                                                                            },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                value[
                                                                                                  index
                                                                                                ][
                                                                                                  nestedKey
                                                                                                ],
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    value[
                                                                                                      index
                                                                                                    ],
                                                                                                    nestedKey,
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "value[index][nestedKey]",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                              ],
                                                                              2
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          : [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12",
                                                                },
                                                                [
                                                                  _vm.isEditable(
                                                                    key + index
                                                                  )
                                                                    ? [
                                                                        _c(
                                                                          "b-form-input",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "text",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .editedData[
                                                                                    key +
                                                                                      index
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.editedData,
                                                                                      key +
                                                                                        index,
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "editedData[key + index]",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    : [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .editedData[
                                                                                key[
                                                                                  index
                                                                                ]
                                                                              ]
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                        _c(
                                                                          "b-form-input",
                                                                          {
                                                                            staticClass:
                                                                              "my-1",
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  !_vm.isEditing,
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  value[
                                                                                    index
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      value,
                                                                                      index,
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "value[index]",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                ],
                                                                2
                                                              ),
                                                            ],
                                                      ],
                                                      2
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ]
                                      : [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 d-flex justify-content-between pe-0",
                                            },
                                            [
                                              _vm._v(_vm._s(key)),
                                              _c("span", [_vm._v(":")]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-6 my-2" },
                                            [
                                              _vm.isEditable(key)
                                                ? [
                                                    _c("b-form-input", {
                                                      attrs: { type: "text" },
                                                      model: {
                                                        value:
                                                          _vm.editedData[key],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedData,
                                                            key,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedData[key]",
                                                      },
                                                    }),
                                                  ]
                                                : [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        disabled:
                                                          !_vm.isEditing,
                                                        type: "text",
                                                        value: value,
                                                      },
                                                    }),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ],
                                  ],
                                  2
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.detailMode
                      ? _c("JsonEditorVue", {
                          staticStyle: { height: "335px" },
                          attrs: { readOnly: !_vm.isEdit, mode: "text" },
                          model: {
                            value: _vm.dataJson,
                            callback: function ($$v) {
                              _vm.dataJson = $$v
                            },
                            expression: "dataJson",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "b-modal",
                  {
                    attrs: {
                      id: "modal-add",
                      size: "lg",
                      title: "Tambah Function",
                      "ok-title": "Tambah",
                      "cancel-title": "Kembali",
                    },
                    on: { ok: _vm.handleAdd, hidden: _vm.resetJson },
                  },
                  [
                    _c("JsonEditorVue", {
                      staticStyle: { height: "350px" },
                      attrs: { mode: "text" },
                      model: {
                        value: _vm.dataJson,
                        callback: function ($$v) {
                          _vm.dataJson = $$v
                        },
                        expression: "dataJson",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _c("div", { key: "other" }, [_c("connection-timeout")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }