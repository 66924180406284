var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report", staticStyle: { "font-size": "10px" } },
    [
      _c(
        "table",
        {
          staticClass: "table table-bordered table-hover z-1 position-relative",
        },
        [
          _vm._m(0),
          _vm.realData.length > 0
            ? _c(
                "tbody",
                [
                  _vm._l(_vm.realData, function (entry, index) {
                    return [
                      _vm._l(entry.invDetilBegining, function (micro, i) {
                        return _c("tr", [
                          i === 0
                            ? _c(
                                "td",
                                {
                                  staticClass: "align-top",
                                  attrs: {
                                    rowspan: entry.invDetilBegining.length,
                                  },
                                },
                                [_c("b", [_vm._v(_vm._s(index + 1))])]
                              )
                            : _vm._e(),
                          i === 0
                            ? _c(
                                "td",
                                {
                                  staticClass: "align-top",
                                  attrs: {
                                    rowspan: entry.invDetilBegining.length,
                                  },
                                },
                                [_c("b", [_vm._v(_vm._s(entry.itemCode))])]
                              )
                            : _vm._e(),
                          i === 0
                            ? _c(
                                "td",
                                {
                                  staticClass: "align-top",
                                  attrs: {
                                    rowspan: entry.invDetilBegining.length,
                                  },
                                },
                                [_c("b", [_vm._v(_vm._s(entry.itemName))])]
                              )
                            : _vm._e(),
                          _c("td", [
                            _c("b", [_vm._v(_vm._s(micro.itemLocation.name))]),
                          ]),
                          _c(
                            "td",
                            _vm._l(micro.data, function (batch) {
                              return _c("p", { staticClass: "m-0" }, [
                                _c("b", [_vm._v(_vm._s(batch.itemNoBatch))]),
                              ])
                            }),
                            0
                          ),
                          _c(
                            "td",
                            _vm._l(micro.data, function (batch) {
                              return _c("p", { staticClass: "m-0" }, [
                                _c("b", [_vm._v(_vm._s(batch.itemQty))]),
                              ])
                            }),
                            0
                          ),
                          _c("td", [_c("b", [_vm._v(_vm._s(entry.itemUM))])]),
                        ])
                      }),
                      entry.invDetilBegining.length > 0
                        ? _c("tr", { staticClass: "bg-light" }, [
                            _vm._m(1, true),
                            _c("td", [_c("b", [_vm._v(_vm._s(entry.invEnd))])]),
                            _c("td", [_c("b", [_vm._v(_vm._s(entry.itemUM))])]),
                          ])
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _c("tbody", [_vm._m(2)]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "bg-light sticky-element" }, [
      _c("tr", [
        _c("th", [_c("b", [_vm._v("NO")])]),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "110" } },
          [_c("b", [_vm._v("kode Item")])]
        ),
        _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
          _c("b", [_vm._v("nama Item")]),
        ]),
        _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
          _c("b", [_vm._v("Lokasi")]),
        ]),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "150" } },
          [_c("b", [_vm._v("No. Batch")])]
        ),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "100" } },
          [_c("b", [_vm._v("STOK")])]
        ),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", width: "50" } },
          [_c("b", [_vm._v("satuan")])]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { attrs: { colspan: "5" } }, [
      _c("b", [_vm._v("TOTAL QTY")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("Data Transaksi Kosong")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }