<template>
  <div class="report" style="font-size: 10px">
    <table class="table table-bordered z-1 position-relative">
      <thead>
        <tr>
          <th scope="col" class="text-center align-middle" width="110"><b>COA</b></th>
          <th scope="col" class="text-center align-middle"><b>Uraian</b></th>
          <th scope="col" class="text-center align-middle" width="100"><b>Total</b></th>
          <th
            v-for="item in data.saldo"
            :key="item.coaName"
            scope="col"
            class="text-center align-middle"
            width="100px"
          >
            <b>{{ item.coaName }}</b>
          </th>
        </tr>
      </thead>
      <tbody v-if="data.saldo">
        <tr>
          <td :colspan="3 + data.saldo.length" style="font-size: 12px">
            <b>SALDO AWAL KEUANGAN</b>
          </td>
        </tr>
        <tr v-for="saldo in data.saldo">
          <td>{{ saldo.coaCode }}</td>
          <td>{{ formatNumber(saldo.coaName) }}</td>
          <td class="text-end">{{ formatNumber(saldo.FaPriceEnd) }}</td>
          <td class="text-end" v-for="item in data.saldo">
            <template v-if="saldo.coaCode === item.coaCode">{{
              formatNumber(saldo.FaPriceEnd)
            }}</template>
          </td>
        </tr>
        <tr>
          <td colspan="2"><b>Jumlah</b></td>
          <td class="text-end">
            <b>{{ totalSaldoAwal }}</b>
          </td>
          <td class="text-end" v-for="item in data.saldo">
            <b>{{ formatNumber(item.FaPriceEnd) }}</b>
          </td>
        </tr>
        <!-- <tbody v-html="getSaldoAwal()" style="font-size: 12px;"></tbody> -->
        <tr>
          <td :colspan="3 + data.saldo.length" style="font-size: 12px">
            <b>PENERIMAAN KEUANGAN</b>
          </td>
        </tr>
        <tr v-for="saldo in filterIn">
          <td>{{ saldo.transCoaCode }}</td>
          <td>{{ formatNumber(saldo.transCoaName) }}</td>
          <td class="text-end">{{ formatNumber(saldo.transAmount) }}</td>
          <td class="text-end" v-for="item in data.saldo">
            <template v-for="total in saldo.filter">
              <template v-if="total.coaCode === item.coaCode">{{
                formatNumber(total.transAmount)
              }}</template>
            </template>
          </td>
        </tr>
        <tr>
          <td colspan="2"><b>Jumlah</b></td>
          <td class="text-end">
            <b v-if="isNaN(totalSaldoIn)">{{ totalSaldoIn }}</b>
            <b v-else>0.00</b>
          </td>
          <td class="text-end" v-for="item in data.saldo">
            <b v-if="isNaN(item.coaCode)">{{ jumlahIn(item.coaCode) }}</b>
            <b v-else>0.00</b>
          </td>
        </tr>
        <tr>
          <td :colspan="3 + data.saldo.length" style="font-size: 12px">
            <b>PENGELUARAN KEUANGAN</b>
          </td>
        </tr>
        <tr v-for="saldo in filterOut">
          <td>{{ saldo.transCoaCode }}</td>
          <td>{{ formatNumber(saldo.transCoaName) }}</td>
          <td class="text-end">{{ formatNumber(saldo.transAmount) }}</td>
          <td class="text-end" v-for="item in data.saldo">
            <template v-for="total in saldo.filter">
              <template v-if="total.coaCode === item.coaCode">{{
                formatNumber(total.transAmount)
              }}</template>
            </template>
          </td>
        </tr>
        <tr>
          <td colspan="2"><b>Jumlah</b></td>
          <td class="text-end">
            <b>{{ totalSaldoOut }}</b>
          </td>
          <td class="text-end" v-for="item in data.saldo">
            <b>{{ jumlahOut(item.coaCode) }}</b>
          </td>
        </tr>
        <tr>
          <td :colspan="3 + data.saldoAkhir.length" style="font-size: 12px">
            <b>SALDO AKHIR KEUANGAN</b>
          </td>
        </tr>
        <tr v-for="saldo in data.saldoAkhir">
          <td>{{ saldo.coaCode }}</td>
          <td>{{ formatNumber(saldo.coaName) }}</td>
          <td class="text-end" v-if="!isNaN(saldo.FaPriceEnd)">
            {{ formatNumber(saldo.FaPriceEnd) }}
          </td>
          <td class="text-end" v-else>0.00</td>
          <td class="text-end" v-for="item in data.saldo">
            <template v-if="saldo.coaCode === item.coaCode && !isNaN(saldo.FaPriceEnd)">{{
              formatNumber(saldo.FaPriceEnd)
            }}</template>
            <template v-else>0.00</template>
          </td>
        </tr>
        <tr>
          <td colspan="2"><b>Jumlah</b></td>
          <td class="text-end">
            <b>{{ totalSaldoAkhir }}</b>
          </td>
          <td class="text-end" v-for="item in data.saldoAkhir">
            <b>{{ formatNumber(item.FaPriceEnd) }}</b>
          </td>
          <!-- <td colspan="2"><b>Jumlah</b></td>
          <td class="text-end">
            <b v-if="!isNaN(totalSaldoAkhir)">{{ totalSaldoAkhir }}</b>
            <b v-else>0.00</b>
          </td>
          <td class="text-end" v-for="item in data.saldo">
            <b v-if="isNaN(item.FaPriceEnd)">{{ jumlahOut(item.FaPriceEnd) }}</b>
            <b v-else>0.00</b>
          </td> -->
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="6">Data Tidak Ditemukan</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatNumber } from "../function/number";
export default {
  props: {
    saldoAwal: String,
    data: Array,
  },
  data() {
    return {
      filteredIn: [],
      filterredOut: [],
    };
  },
  computed: {
    fixData() {
      return this.data;
    },
    totalSaldoAwal() {
      return formatNumber(
        this.data.saldo.reduce((sum, item) => sum + parseFloat(item.FaPriceEnd), 0)
      );
    },
    totalSaldoAkhir() {
      return formatNumber(
        this.data.saldoAkhir.reduce((sum, item) => sum + parseFloat(item.FaPriceEnd), 0)
      );
    },
    totalSaldoIn() {
      return formatNumber(
        this.data.In.reduce((sum, item) => sum + parseFloat(item.transAmount), 0)
      );
    },
    totalSaldoOut() {
      return formatNumber(
        this.data.out.reduce((sum, item) => sum + parseFloat(item.transAmount), 0) * -1
      );
    },

    filterIn() {
      const groupedData = {};
      for (const item of this.data.In) {
        if (!groupedData[item.transCoaCode]) {
          groupedData[item.transCoaCode] = {
            transCoaCode: item.transCoaCode,
            transCoaName: item.transCoaName,
            transAmount: 0,
            filter: [],
          };
        }
        // alert(groupedData[item.transCoaCode].transAmount)
        if (!isNaN(parseFloat(item.transAmount))) {
          groupedData[item.transCoaCode].transAmount += parseFloat(item.transAmount);
          const data = groupedData[item.transCoaCode].filter;
          if (data.some((x) => x.coaCode === item.coaCode)) {
            data.find((x) => x.coaCode === item.coaCode).transAmount += item.transAmount;
          } else {
            data.push({
              coaCode: item.coaCode,
              transAmount: item.transAmount,
            });
          }
        }
      }
      return Object.values(groupedData);
    },
    filterOut() {
      const groupedData = {};
      const cek = this.data.out.map((x) => ({ ...x, transAmount: x.transAmount * -1 }));
      console.log(cek);
      for (const item of cek) {
        if (!groupedData[item.transCoaCode]) {
          groupedData[item.transCoaCode] = {
            transCoaCode: item.transCoaCode,
            transCoaName: item.transCoaName,
            transAmount: 0,
            filter: [],
          };
        }
        // alert(groupedData[item.transCoaCode].transAmount)
        if (!isNaN(parseFloat(item.transAmount))) {
          groupedData[item.transCoaCode].transAmount += parseFloat(item.transAmount);
          const data = groupedData[item.transCoaCode].filter;
          if (data.some((x) => x.coaCode === item.coaCode)) {
            data.find((x) => x.coaCode === item.coaCode).transAmount += item.transAmount;
          } else {
            data.push({
              coaCode: item.coaCode,
              transAmount: item.transAmount,
            });
          }
        }
      }
      console.log("price out", this.data.out);
      console.log("value out", groupedData);
      return Object.values(groupedData);
    },
  },
  methods: {
    formatNumber(value) {
      return formatNumber(value);
    },
    jumlahIn(value) {
      if (value.length > 0) {
        return formatNumber(
          this.data.In.filter((x) => x.coaCode === value).reduce(
            (sum, item) => sum + parseFloat(item.transAmount),
            0
          )
        );
      } else {
        return "0,00";
      }
    },
    jumlahOut(value) {
      if (value.length > 0) {
        return formatNumber(
          this.data.out
            .filter((x) => x.coaCode === value)
            .reduce((sum, item) => sum + parseFloat(item.transAmount), 0) * -1
        );
      } else {
        return "0,00";
      }
    },
  },

  mounted() {},
};
</script>

<style scoped></style>
