<template v-if="fields.length > 0">
  <form @submit="handlePayment">
    <transition name="fade" mode="out-in">
      <div v-if="isDataLoaded === ''" key="empty">
        <loading></loading>
      </div>

      <!-- Main Content -->
      <div v-else-if="isDataLoaded" key="truthy">
        <div class="modal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Modal title</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div
                  class="report p-3 printable-element"
                  style="font-size: 18px; width: 100%; font-family: sans-serif"
                  v-if="fixDataStore"
                >
                  <!-- Title -->
                  <h1 class="text-center pt-4"><b>KLINIK UTAMA MUTIARA AISHA</b></h1>
                  <p class="text-center">JL. KI HAJAR DEWANTARA 04 SELOSARI MAGETAN</p>
                  <p class="text-center">082331 200 500</p>
                  <!-- End of Title -->

                  <!-- Header -->
                  <div class="row pb-1" v-if="fixDataStore.transId">
                    <div class="col-3" style="font-size: 20px">Nm. Pasien</div>
                    <div class="col-9" style="font-size: 18px">
                      : {{ fixDataStore.transName }}
                    </div>
                    <div class="col-3" style="font-size: 20px">Nomor RM</div>
                    <div class="col-9" style="font-size: 18px">
                      : {{ fixDataStore.transIdMember.userCode }}
                    </div>
                    <div class="col-3" style="font-size: 20px">Alamat</div>
                    <div class="col-9" style="font-size: 18px">
                      : {{ fixDataStore.transIdMember.userAddress[0].location }}
                    </div>
                    <div class="col-3" style="font-size: 20px">Dokter</div>
                    <div class="col-9" style="font-size: 18px">
                      : {{ fixDataStore.transKasir.userName }}
                    </div>
                    <div class="col-3" style="font-size: 20px">Poli</div>
                    <div class="col-9" style="font-size: 18px">:</div>
                  </div>
                  <div
                    class="w-100 border-dark border-top border-bottom"
                    style="height: 0.25rem"
                  ></div>
                  <div class="w-100">
                    <table class="w-100" v-if="fixDataInvoice">
                      <thead class="border-bottom border-dark">
                        <tr>
                          <th style="font-size: 20px">TRANSAKSI</th>
                          <th class="text-end" style="font-size: 20px">QTY</th>
                          <th class="text-end" style="font-size: 20px">DISC</th>
                          <th class="text-end" style="font-size: 20px">JUMLAH</th>
                        </tr>
                      </thead>
                      <tbody class="border-bottom border-dark">
                        <tr
                          v-for="data in fixDataInvoice"
                          v-if="fixDataInvoice.length > 0"
                        >
                          <td style="font-size: 18px; width: 60%">
                            <template v-if="data.itemGroup[0] === 'layanan'"
                              >ADMIN
                            </template>
                            <template v-else-if="data.itemGroup[0] === 'Fee'"
                              >TARIF
                            </template>
                            {{ data.itemName }}
                          </td>
                          <td class="text-end" style="font-size: 18px; width: 5%">
                            {{ data.itemQty }}
                          </td>
                          <td class="text-end" style="font-size: 18px; width: 17.5%">
                            0
                          </td>
                          <td class="text-end" style="font-size: 18px; width: 17.5%">
                            {{
                              formatNumberInt(
                                data.itemMasterPrice[0].priceValue * data.itemQty
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="row d-flex justify-content-between">
                      <div class="col-6 row">
                        <div class="col-5" style="font-size: 18px">Tanggal</div>
                        <div
                          class="col-7"
                          style="font-size: 18px"
                          v-if="fixDataStore.transDate"
                        >
                          : {{ formatIndDate(fixDataStore.transDate) }}
                        </div>
                        <div class="col-5 pe-0" style="font-size: 18px">No. Nota</div>
                        <div class="col-7 pe-0" style="font-size: 18px">
                          : {{ fixDataStore.transNoSO }}
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-6 d-flex justify-content-between">
                            SUBTOTAL <span>:</span>
                          </div>
                          <div
                            class="col-6 text-end"
                            style="font-size: 18px"
                            v-if="fixGrandTotal"
                          >
                            {{ formatNumberInt(fixGrandTotal) }},00
                          </div>
                          <div
                            class="col-6 d-flex justify-content-between"
                            style="font-size: 18px"
                          >
                            DISC <span>:</span>
                          </div>
                          <div class="col-6 text-end" style="font-size: 18px">0,00</div>
                          <div
                            class="col-6 d-flex justify-content-between"
                            style="font-size: 18px"
                          >
                            <b>TOTAL</b> <span>:</span>
                          </div>
                          <div
                            class="col-6 text-end"
                            style="font-size: 18px"
                            v-if="fixGrandTotal"
                          >
                            <b>{{ formatNumberInt(fixGrandTotal) }},00</b>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="w-100 border-dark border-top border-bottom"
                      style="height: 0.25rem"
                    ></div>
                    <p>
                      <b v-if="fixGrandTotal">Terbilang :</b>
                      {{ numberToText(fixGrandTotal) }}
                    </p>
                    <div class="text-center pt-3">
                      <h2>
                        Terima kasih atas kunjungannya
                        <p>Semoga Lekas Sembuh</p>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary">Save changes</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <b-modal
          ok-title="Bayar"
          id="modal-payment"
          title="Detail Tagihan"
          @ok.prevent="sendData"
          size="lg"
        >
          <div class="row" v-if="fixDataStore">
            <div class="col-6">
              <pre v-if="fixDataStore.transId" class="m-0">
  No. Invoice   :  {{ fixDataStore.transId }}
  No. RM        :  {{ fixDataStore.transIdMember.userCode }}
  Nama Pasien   :  <p>{{ fixDataStore.transName }}</p>
      </pre>
            </div>
            <div class="col-6">
              <pre v-if="fixDataStore.transId" class="m-0">
  Tgl. Berobat  :  {{ fixDataStore.transDate }}
  Alamat        :  <span style="white-space: normal;">{{ fixDataStore.transIdMember.userAddress[0].location }}</span>
      </pre>
            </div>
          </div>
          <table class="table table-bordered" v-if="fixExcelData">
            <thead>
              <tr>
                <th class="text-center align-middle">Kode</th>
                <th class="text-center align-middle">Nama</th>
                <th class="text-center align-middle">Qty</th>
                <th class="text-center align-middle">Harga</th>
                <th class="text-center align-middle">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in fixDataInvoice" v-if="fixDataInvoice.length > 0">
                <td class="fs-11 align-middle">
                  {{ data.itemCode }}
                </td>
                <td class="fs-11 align-middle">
                  {{ data.itemName }}
                </td>
                <td class="fs-11 align-middle">
                  {{ data.itemQty }}
                </td>
                <!-- <td>{{ data }}</td> -->
                <td class="fs-11 align-middle text-end">
                  {{ formatNumberInt(data.itemMasterPrice[0].priceValue) }}
                </td>
                <td class="fs-11 align-middle text-end">
                  {{ formatNumberInt(data.itemMasterPrice[0].priceValue * data.itemQty) }}
                </td>
              </tr>

              <tr>
                <th colspan="3" class="text-end">Total</th>
                <th colspan="2" class="text-end">
                  Rp. {{ formatNumberInt(fixGrandTotal) }}
                </th>
              </tr>
            </tbody>
          </table>
          <multiselect
            class="my-1"
            v-model="payMethods"
            :options="optionsCoa"
            placeholder="Metode Pembayaran"
            :multiple="false"
            :show-labels="false"
            :allow-empty="required"
            label="coaName"
          >
          </multiselect>
        </b-modal>
        <div class="row my-1">
          <div class="col-4 d-flex align-items-end">
            <ul class="breadcrumb m-0">
              <li>
                <router-link to="/dashboard">
                  <a href="#"><i class="bi-house-door"></i></a>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: posts.transRouting.replace('-edit', '') }">
                  <a href="#">{{ posts.transTitle }}</a>
                </router-link>
              </li>
              <li>Edit</li>
            </ul>
          </div>
          <div class="col-4 text-center">
            <span class="h2">Edit {{ posts.transTitle }}</span>
          </div>
          <div class="col-1 d-flex justify-content-end align-items-center">
            <div class="form-check form-switch d-flex align-items-center">
              <input
                type="checkbox"
                class="form-check-input"
                id="formSwitch1"
                v-model="checkedPajak"
                style="height: 17.5px; margin-top: 1px"
              />
              <label class="form-check-label" for="formSwitch1">Pajak</label>
            </div>
          </div>
          <div class="col-3 d-flex justify-content-end align-items-center">
            <router-link :to="{ name: posts.transRouting.replace('-edit', '') }"
              ><button class="btn btn-outline-secondary btn-xs py-1 me-2" type="button">
                <i class="bi-chevron-left"></i>Kembali
              </button></router-link
            >
            <b-button
              class="btn-xs py-1"
              variant="primary"
              type="button"
              v-b-modal.modal-payment
              >Simpan & Bayar</b-button
            >
          </div>
        </div>

        <hr class="mb-3 mt-1" />
        <div class="row scale-9 position-relative" style="width: 114%; z-index: 1">
          <div
            v-for="(field, index) in dummyItems"
            :class="[field.componentClass]"
            v-if="field.componentName === 'fieldHeader'"
            :key="index"
          >
            <div class="card h-100">
              <div class="row px-3">
                <div
                  v-for="micro in field.componentMicro"
                  :class="['', micro.microClass]"
                >
                  <div class="row" v-if="micro.microType === 'select'">
                    <b-form-group
                      :label="micro.microName + ' :'"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm="8"
                      content-cols-lg="9"
                    >
                      <b-form-select
                        disabled
                        v-model="micro.value"
                        :options="micro.microOptions"
                        size="sm"
                        class="mt-1 form-control-sm py-0 form-control"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <template v-else-if="micro.microType === 'multiselect'">
                    <b-form-group
                      disabled
                      :label="micro.microName + ' :'"
                      :label-for="micro.microName"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm="8"
                      content-cols-lg="9"
                      invalid-feedback="Condition Harus Diisi!"
                    >
                      <template v-if="micro.key === 'shipTo'">
                        <multiselect
                          disabled
                          class="my-1"
                          :id="micro.microName"
                          v-model="micro.value"
                          :options="shipTo"
                          placeholder="Pilih Alamat"
                          :multiple="false"
                          :show-labels="false"
                          required
                          :custom-label="customLabel1"
                        >
                        </multiselect>
                      </template>
                      <template v-else-if="micro.key === 'customer'">
                        <multiselect
                          disabled
                          class="my-1"
                          :id="micro.microName"
                          v-model="micro.value"
                          :options="micro.microOptions"
                          @input="selectCustomer(micro.value)"
                          placeholder="Select one"
                          :multiple="false"
                          :show-labels="false"
                          required
                          :custom-label="customLabel"
                        >
                        </multiselect>
                      </template>
                      <template v-else>
                        <multiselect
                          disabled
                          class="my-1"
                          :id="micro.microName"
                          v-model="micro.value"
                          :options="micro.microOptions"
                          placeholder="Select one"
                          :multiple="false"
                          :show-labels="false"
                          required
                          :custom-label="customLabel"
                        >
                        </multiselect>
                      </template>
                    </b-form-group>
                  </template>
                  <template v-else-if="micro.key === 'orderNumber'">
                    <b-form-group
                      :label="micro.microName + ' :'"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm="8"
                      content-cols-lg="9"
                    >
                      <b-form-input
                        disabled
                        v-model="micro.value"
                        class="form-control form-control-sm py-0"
                        :type="micro.microType"
                        :placeholder="micro.microText"
                      ></b-form-input>
                    </b-form-group>
                  </template>
                  <template v-else>
                    <b-form-group
                      :label="micro.microName + ' :'"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm="8"
                      content-cols-lg="9"
                    >
                      <b-form-input
                        v-model="micro.value"
                        disabled
                        class="form-control form-control-sm py-0"
                        :type="micro.microType"
                        :placeholder="micro.microText"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div
            v-for="field in component"
            :class="[field.componentClass]"
            v-if="field.componentName === 'fieldTable'"
          >
            <div class="row">
              <div
                class="hot-container col-md-12 position-relative"
                style="overflow-x: auto; z-index: 0"
              >
                <hot-table
                  :settings="hotSettings"
                  :data="fixExcelData"
                  :rowHeaders="true"
                  :colHeaders="true"
                  :colWidths="widthTable"
                ></hot-table>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-6 scale-9">
            <div
              v-for="field in component"
              :class="['h-100', field.componentClass]"
              v-if="field.componentName === 'fieldNote'"
            >
              <div class="card h-100">
                <div class="card-header bg-light py-1">{{ field.componentTitle }}</div>
                <template v-for="micro in field.componentMicro">
                  <textarea
                    class="form-control form-control-sm py-1 h-100"
                    style="resize: none"
                    :placeholder="micro.microText"
                    v-model="micro.microValue"
                  ></textarea>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-6 row p-0 scale-9" style="transform-origin: right top">
            <div
              v-for="field in component"
              :class="[field.componentClass]"
              v-if="field.componentName === 'fieldTotal'"
            >
              <template v-if="field.componentMicro.length > 0">
                <div
                  class="row d-flex justify-content-end"
                  v-for="micro in field.componentMicro"
                >
                  <template v-if="micro.key === 'tableTotal'">
                    <div
                      :class="[
                        'border border-light bg-light d-flex justify-content-between align-items-center',
                        micro.microClass,
                      ]"
                    >
                      {{ micro.microName }} <span>:</span>
                    </div>
                    <div :class="['p-0 border border-light', micro.microClass]">
                      <input
                        :disabled="!micro.editableTotal"
                        :type="micro.microValue"
                        :value="subTotal()"
                        class="form-control form-control-sm py-1"
                        style="text-align: right"
                      />
                    </div>
                  </template>
                  <template v-else-if="micro.key === 'dp'">
                    <div
                      :class="[
                        'border border-light bg-light d-flex justify-content-between align-items-center',
                        micro.microClass,
                      ]"
                    >
                      {{ micro.microName }} <span>:</span>
                    </div>
                    <div :class="['p-0 border border-light', micro.microClass]">
                      <input
                        v-format-number
                        :disabled="!micro.editableTotal"
                        type="text"
                        v-model="totalDp"
                        class="form-control form-control-sm py-1"
                        style="text-align: right"
                        @change="checkTotal('DP')"
                      />
                    </div>
                  </template>
                  <template v-else-if="micro.key === 'diskon'">
                    <div
                      :class="[
                        'border border-light bg-light d-flex justify-content-between align-items-center',
                        micro.microClass,
                      ]"
                    >
                      {{ micro.microName }} <span>:</span>
                    </div>
                    <div :class="['p-0 border border-light', micro.microClass]">
                      <input
                        v-format-number
                        :disabled="!micro.editableTotal"
                        type="text"
                        v-model="totalDiskon"
                        class="form-control form-control-sm py-1"
                        style="text-align: right"
                        @change="checkTotal('Diskon')"
                      />
                    </div>
                  </template>
                  <template v-else-if="micro.key === 'total'">
                    <div
                      :class="[
                        'border border-light bg-light d-flex justify-content-between align-items-center',
                        micro.microClass,
                      ]"
                    >
                      {{ micro.microName }} <span>:</span>
                    </div>
                    <div :class="['p-0 border border-light', micro.microClass]">
                      <input
                        :disabled="!micro.editableTotal"
                        :type="micro.microValue"
                        :value="formatNumberInt(grandTotal())"
                        class="form-control form-control-sm py-1"
                        style="text-align: right"
                      />
                    </div>
                  </template>
                  <template v-else-if="micro.key === 'ppn'">
                    <template v-if="checkedPajak">
                      <div
                        :class="[
                          'border border-light bg-light d-flex justify-content-between align-items-center',
                          micro.microClass,
                        ]"
                      >
                        {{ micro.microName }} <span>:</span>
                      </div>
                      <div :class="['p-0 border border-light', micro.microClass]">
                        <input
                          :disabled="!micro.editableTotal"
                          :type="micro.microValue"
                          :value="formatNumberInt(totalPPN)"
                          class="form-control form-control-sm py-1"
                          style="text-align: right"
                        />
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div
                      :class="[
                        'border border-light bg-light d-flex justify-content-between align-items-center',
                        micro.microClass,
                      ]"
                    >
                      {{ micro.microName }} <span>:</span>
                    </div>
                    <div :class="['p-0 border border-light', micro.microClass]">
                      <input
                        :disabled="!micro.editableTotal"
                        @input="refreshMethods()"
                        :type="micro.microType"
                        v-model="micro.value"
                        class="form-control form-control-sm py-1"
                        style="text-align: right"
                      />
                    </div>
                  </template>
                </div>
              </template>
              <!-- <div class="col-12 d-flex justify-content-center pt-3 align-items-center">
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <!-- Error -->
      <div v-else key="other">
        <connection-timeout></connection-timeout>
      </div>
    </transition>
  </form>
</template>

<script>
import { HotTable } from "@handsontable/vue";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";

// register Handsontable's modules
registerAllModules();
import { numberToText } from "../../components/function/terbilang";
import { updateSIKlinik, getSaldoKasbank } from "../services/components";
import { dynamicJS } from "../services/dynamic";
import {
  formatNumberInt,
  removeThousandFormatter,
} from "../../components/function/number";
import { formatIndDate, getFullDate } from "../../components/function/date";

import loading from "../../components/loading.vue";
import connectionTimeout from "../../components/connectionTimeout.vue";
export default {
  components: {
    HotTable,
    loading,
    connectionTimeout,
  },
  props: {
    dataInvoice: {
      type: Array,
    },
    dataStore: {
      type: Object,
    },
    dataHidden: {
      type: Array,
    },
    refreshMethods: {
      type: Function,
      required: true,
    },
    posts: {
      type: Object,
      required: true,
    },
    Item: {
      type: Array,
      required: true,
    },
    component: {
      type: Array,
      required: true,
    },
    hotSettings: {
      type: Object,
      required: true,
    },
    contentDivWidth: {
      type: Number,
      required: true,
    },
    widthTable: {
      type: Number,
      required: true,
    },
    excelData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      payMethods: "",
      optionsCoa: [],
      paymentData: {},
      isDataLoaded: "",
      totalDp: "",
      totalDiskon: "",
      checkedPajak: false,
      shipTo: [],
      dynamicFunction: dynamicJS,
      filter: "",
      filterFields: "",
      filterSort: "",
      filterList: [{ filter: "" }],
      checkedFields: [],
      sortBy: "",
      sortDesc: false,
      currentPage: 1,
      perPage: 8,
    };
  },
  computed: {
    fixTableData() {
      const filteredItem = {};
      if (this.component.length > 0) {
        this.Item.forEach((item) => {
          filteredItem[item.itemCode] = item;
          filteredItem[item.itemCode].itemId = filteredItem[item.itemCode]._id;
        });
        console.log(this.component);
        const fieldsTable = this.component.find(
          ({ componentName }) => componentName === "fieldTable"
        ).componentMicro;
        return this.fixExcelData
          .filter((item) => {
            return fieldsTable.some((obj) => {
              const value = item[obj.key];
              return (
                value !== "" && value !== undefined && value !== null && value.length > 0
              );
            });
          })
          .map((item) => {
            const data = {
              ...filteredItem[item.productCode],
              // itemMasterPrice: item.itemMasterPrice,
              itemMasterPrice:
                item.productCode !== "" && filteredItem[item.productCode]
                  ? filteredItem[item.productCode].itemMasterPrice
                  : item.itemMasterPrice,
              itemQty: item.tableQty,
            };
            if (item.productCode !== "" && !filteredItem[item.productCode]) {
              return {
                ...item,
                ...data,
              };
            } else {
              return data;
            }
          });
      }
    },
    fixDataInvoice() {
      if (this.fixTableData) {
        const mainData = this.fixDataStore;
        const first = mainData.transPesanan.map((x) => {
          const data = {
            ...x,
            itemMasterPrice: [x.itemMasterPrice],
          };
          return data;
        });
        const last = this.fixTableData.slice(mainData.transPesananAsli.length);
        const final = first.concat(last);
        return final;
      }
    },
    fixGrandTotal() {
      const data = this.fixTableData;

      // Initialize a variable to store the sum
      let totalSum = 0;

      // Iterate through the array and calculate the sum
      for (const item of data) {
        const qty = parseFloat(item.itemQty); // Convert qty to an integer
        const amount = parseFloat(item.itemMasterPrice[0].priceValue); // Convert amount to an integer
        const product = qty * amount; // Calculate the product for each object
        totalSum += product; // Add the product to the total sum
      }
      return totalSum;
    },
    fixDataStore() {
      return this.dataStore;
    },
    totalTransPesananAsli() {
      if (this.fixDataStore) {
        return this.fixDataStore.transPesananAsli.length;
      } else {
        return 0;
      }
    },
    fixDataHidden() {
      return this.dataHidden;
    },
    totalPPN() {
      return (this.subTotal() * 11) / 100;
    },
    fixExcelData() {
      return this.excelData;
    },

    dummyItems() {
      const dummy = {};
      for (const key in this.component) {
        dummy[key] = { ...this.component[key] };
      }
      return dummy;
    },
    // subTotal() {
    //   let sum = 0
    //   for (const item of this.excelData) {
    //     const value = item.microValue; // Remove leading and trailing spaces
    //     if (value !== '') {
    //       sum += parseFloat(value); // Convert to float and add to the sum
    //     }
    //   }
    //   return sum
    // },
    dataRange() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.data.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.data.length / this.perPage);
    },
    dataRangeText() {
      const startIndex = (this.currentPage - 1) * this.perPage + 1;
      const endIndex = Math.min(startIndex + this.perPage - 1, this.data.length);
      return `${startIndex} - ${endIndex} data dari total ${this.data.length} data`;
    },
  },
  created() {
    this.initializeCheckedFields();
  },
  async mounted() {
    const timeout = 30000; // 10 seconds
    try {
      const currentDate = await getFullDate();
      const tes = await this.excelData;
      this.optionsCoa = await getSaldoKasbank(currentDate);
      this.payMethods = this.optionsCoa.find((x) => x.coaCode === "1.1.1.1.02");
      const timeoutPromise = new Promise((resolve) => setTimeout(resolve, timeout));

      // Wait for either finalPromise or timeoutPromise to resolve
      const result = await Promise.race([tes, timeoutPromise]);
      this.isDataLoaded = result !== undefined;
    } catch (error) {
      this.isDataLoaded = false;
    }
  },
  methods: {
    numberToText(data) {
      return numberToText(data);
    },
    printReport() {
      console.log(this.getReportContent());
      const printWindow = window.open("", "_blank");
      const printDocument = `
        <html>
          <head>
	          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet">
          <style>
              th {
                font-weight: 500
              }
              tr {
                font-size: 10px
              }
              body {
                padding-left: 1px;
                width: 5.65in;
              }
          </style>
            </head>

          <body>
            ${this.getReportContent()}
          </body>
        </html>
      `;
      printWindow.document.write(printDocument);
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
        printWindow.close();
      };
    },
    getReportContent() {
      const reportView = document.querySelector(".printable-element");
      if (reportView) {
        return reportView.innerHTML;
      }
      return "No content found.";
    },
    alertLoading() {
      this.$swal({
        title: "Loading...",
        text: "Mohon Ditunggu ya.",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        willOpen: () => {
          this.$swal.showLoading();
        },
      });
    },
    successAdd() {
      this.$swal("Berhasil!", "Pembayaran Berhasil!", "success");
    },
    formatIndDate(data) {
      return formatIndDate(data);
    },
    formatNumberInt(data) {
      return formatNumberInt(data);
    },
    handlePayment(data) {
      this.$bvModal.show("modal-payment");
      this.paymentData = data;
    },
    checkTotal(data) {
      if (this.grandTotal() < 0) {
        if (data === "DP") {
          this.totalDp = "";
        } else if (data === "Diskon") {
          this.totalDiskon = "";
        }
        alert(data + " Tidak boleh lebih dari Grand Total!");
      }
    },
    subTotal() {
      const tes = this.fixExcelData.reduce(
        (accumulator, item) => accumulator + item.tablePrice * item.tableQty,
        0
      );
      return tes;
    },
    grandTotal() {
      let tes;
      if (this.checkedPajak) {
        tes = this.subTotal() + this.totalPPN;
      } else {
        tes = this.subTotal();
      }
      tes =
        tes -
        removeThousandFormatter(this.totalDiskon) -
        removeThousandFormatter(this.totalDp);
      return tes;
    },
    formatNumberInt(data) {
      return formatNumberInt(data);
    },
    alertLoading() {
      this.$swal({
        title: "Loading...",
        text: "Mohon Ditunggu ya.",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        willOpen: () => {
          this.$swal.showLoading();
        },
      });
    },
    successAdd() {
      this.$swal("Berhasil!", "Data berhasil ditambahkan", "success");
    },
    generateIdChart(length) {
      const numChars = "0123456789";
      const alphaChars = "abcdefghijklmnopqrstuvwxyz";
      const characters = numChars.repeat(0.8 * length) + alphaChars.repeat(0.2 * length);
      let result = "";

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }

      return result;
    },
    selectCustomer(data) {
      if (data) {
        this.shipTo = data.userAddress;
      } else {
        this.shipTo = [];
        this.component
          .filter((x) => x.componentName === "fieldHeader")
          .forEach((y) => {
            y.componentMicro.find((z) => {
              if (z.key === "shipTo") {
                z.value = [];
              }
            });
          });
      }
    },
    addRequester(newCat) {
      const cat = {
        userName: newCat,
        userType: "-",
      };
      this.component
        .filter((x) => x.componentName === "fieldHeader")
        .forEach((y) => {
          y.componentMicro.find((z) => {
            if (z.microType === "multiselect") {
              if (z.key === "PrRequestBy") {
                z.microOptions.push(cat);
                z.value = cat;
              }
            }
          });
        });
    },
    customLabel(option) {
      return `${option.userName} (${option.userType})`;
    },
    customLabel1(option) {
      return `${option.nameAddr} (${option.location})`;
    },
    async sendData(event) {
      event.preventDefault();
      // header Data
      // table Data
      const finalNote = this.component.reduce((result, item) => {
        if (item.componentName === "fieldNote" && Array.isArray(item.componentMicro)) {
          item.componentMicro.forEach((microItem) => {
            result[microItem.key] = microItem.microValue;
          });
        }
        return result;
      }, {});
      const filteredItem = {};
      this.Item.forEach((item) => {
        filteredItem[item.itemCode] = item;
        filteredItem[item.itemCode].itemId = filteredItem[item.itemCode]._id;
      });
      console.log(filteredItem);
      const fieldsTable = this.component.find(
        ({ componentName }) => componentName === "fieldTable"
      ).componentMicro;
      const finalTable = this.fixExcelData
        .filter((item) => {
          return fieldsTable.some((obj) => {
            const value = item[obj.key];
            return (
              value !== "" && value !== undefined && value !== null && value.length > 0
            );
          });
        })
        .map((item) => {
          const data = {
            ...filteredItem[item.productCode],
            // itemMasterPrice: item.itemMasterPrice,
            itemMasterPrice:
              item.productCode !== "" && filteredItem[item.productCode]
                ? filteredItem[item.productCode].itemMasterPrice
                : item.itemMasterPrice,
            itemQty: item.tableQty,
          };
          if (item.productCode !== "" && !filteredItem[item.productCode]) {
            return {
              ...item,
              ...data,
            };
          } else {
            return data;
          }
        });
      // final Note
      // final Total
      const finalTotal = this.component.reduce((result, item) => {
        if (item.componentName === "fieldTotal" && Array.isArray(item.componentMicro)) {
          item.componentMicro
            .filter((tes) => tes.editableTotal === true)
            .forEach((microItem) => {
              if (isNaN(microItem.value)) {
                result[microItem.key] = 0;
              } else {
                result[microItem.key] = microItem.value;
              }
            });
        }
        return result;
      }, {});
      // final Footer
      const finalFooter = {
        note: finalNote,
        total: finalTotal,
      };
      // final Data
      const now = new Date();
      const hour = String(now.getHours()).padStart(2, 0);
      const minutes = String(now.getMinutes()).padStart(2, 0);
      const finalData = this.fixDataStore;

      // console.log("final Datanya :", finalData);
      finalData.transPesananAsli = this.fixDataHidden.concat(
        finalTable.slice(0, this.totalTransPesananAsli)
      );
      finalData.transPesananTambah = this.fixDataHidden.concat(
        finalTable.slice(this.totalTransPesananAsli)
      );
      (finalData.transPaymentType = {
        _id: this.payMethods._id,
        coaCode: this.payMethods.coaCode,
        coaName: this.payMethods.coaName,
      }),
        console.log("ini final table", JSON.stringify(finalData));

      this.$swal({
        title: "Anda Yakin?",
        text: "Tekan Bayar jika anda sudah yakin!",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Bayar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.alertLoading();
          try {
            if (finalTable.length >= 0) {
              this.alertLoading();
              try {
                const voucher = await updateSIKlinik(finalData);
                this.successAdd();
                this.printReport();
                this.$router.push({ name: this.posts.transRouting.replace("-edit", "") });
              } catch (error) {
                alert(error);
              }
            } else {
              alert("ada data yang masih Kosong!");
            }
            // this.connectToPrinter();
            this.$bvModal.hide("modal-payment");
          } catch (error) {
            alert(error);
          }
        }
      });
      console.log("kiriman :", JSON.stringify(finalData));
    },
    addList() {
      const link = "/transaksi/" + this.routingLink + "/add";
      this.$router.push(link);
    },
    resetFilter() {
      this.filter = "";
      this.filterFields = "";
      this.filterSort = "";
      this.filterList = [{ filter: "" }];
    },
    addFilter() {
      if (this.filter !== "") {
        this.filterList.push({ filter: "" });
      }
    },
    removeFilter() {
      if (this.filterList.length > 1) {
        this.filterList.splice(-1);
      }
    },
    serviceDetail(item) {
      const data = item._id;
      this.$store.commit("setDataProses", item);
      this.$router.push({ name: "transaksi-detail", query: { data } });
    },
    initializeCheckedFields() {
      if (this.fields !== undefined) {
        this.checkedFields = [];
        this.fields.forEach((field) => {
          this.checkedFields.push(field.key);
        });
      }
    },
    handleButtonClick(item) {
      // Perform action when the button is clicked
      console.log("Button clicked:", item);
    },
    handleRowClick(item) {
      // Handle row click event
      console.log("Row clicked:", item);
    },
  },
};
</script>
