var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    { on: { submit: _vm.sendData } },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.isDataLoaded === ""
          ? _c("div", { key: "empty" }, [_c("loading")], 1)
          : _vm.isDataLoaded
          ? _c("div", { key: "truthy" }, [
              _c("div", { staticClass: "row my-1" }, [
                _c("div", { staticClass: "col-4 d-flex align-items-end" }, [
                  _c("ul", { staticClass: "breadcrumb m-0" }, [
                    _c(
                      "li",
                      [
                        _c("router-link", { attrs: { to: "/dashboard" } }, [
                          _c("a", { attrs: { href: "#" } }, [
                            _c("i", { staticClass: "bi-house-door" }),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: _vm.posts.transRouting.replace(
                                  "-edit",
                                  ""
                                ),
                              },
                            },
                          },
                          [
                            _c("a", { attrs: { href: "#" } }, [
                              _vm._v(_vm._s(_vm.posts.transTitle)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("li", [_vm._v("Edit")]),
                  ]),
                ]),
                _c("div", { staticClass: "col-4 text-center" }, [
                  _c("span", { staticClass: "h2" }, [
                    _vm._v("Edit " + _vm._s(_vm.posts.transTitle)),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-4 d-flex justify-content-end align-items-center",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "form-check form-switch d-flex align-items-center",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.checkedPajak,
                              expression: "checkedPajak",
                            },
                          ],
                          staticClass: "form-check-input",
                          staticStyle: {
                            height: "17.5px",
                            "margin-top": "1px",
                          },
                          attrs: { type: "checkbox", id: "formSwitch1" },
                          domProps: {
                            checked: Array.isArray(_vm.checkedPajak)
                              ? _vm._i(_vm.checkedPajak, null) > -1
                              : _vm.checkedPajak,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.checkedPajak,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.checkedPajak = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.checkedPajak = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.checkedPajak = $$c
                              }
                            },
                          },
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: { for: "formSwitch1" },
                          },
                          [_vm._v("Pajak")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _c("hr", { staticClass: "mb-3 mt-1" }),
              _c(
                "div",
                {
                  staticClass: "row scale-9 position-relative",
                  staticStyle: { width: "114%", "z-index": "1" },
                },
                _vm._l(_vm.dummyItems, function (field, index) {
                  return field.componentName === "fieldHeader"
                    ? _c("div", { key: index, class: [field.componentClass] }, [
                        _c("div", { staticClass: "card h-100" }, [
                          _c(
                            "div",
                            { staticClass: "row px-3" },
                            _vm._l(field.componentMicro, function (micro) {
                              return _c(
                                "div",
                                { class: ["", micro.microClass] },
                                [
                                  micro.microType === "select"
                                    ? _c(
                                        "div",
                                        { staticClass: "row" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: micro.microName + " :",
                                                "label-cols-sm": "4",
                                                "label-cols-lg": "3",
                                                "content-cols-sm": "8",
                                                "content-cols-lg": "9",
                                              },
                                            },
                                            [
                                              _c("b-form-select", {
                                                staticClass:
                                                  "mt-1 form-control-sm py-0 form-control",
                                                attrs: {
                                                  disabled: "",
                                                  options: micro.microOptions,
                                                  size: "sm",
                                                },
                                                model: {
                                                  value: micro.value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      micro,
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "micro.value",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : micro.microType === "multiselect"
                                    ? [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              disabled: "",
                                              label: micro.microName + " :",
                                              "label-for": micro.microName,
                                              "label-cols-sm": "4",
                                              "label-cols-lg": "3",
                                              "content-cols-sm": "8",
                                              "content-cols-lg": "9",
                                              "invalid-feedback":
                                                "Condition Harus Diisi!",
                                            },
                                          },
                                          [
                                            micro.key === "shipTo"
                                              ? [
                                                  _c("multiselect", {
                                                    staticClass: "my-1",
                                                    attrs: {
                                                      disabled: "",
                                                      id: micro.microName,
                                                      options: _vm.shipTo,
                                                      placeholder:
                                                        "Pilih Alamat",
                                                      multiple: false,
                                                      "show-labels": false,
                                                      required: "",
                                                      "custom-label":
                                                        _vm.customLabel1,
                                                    },
                                                    model: {
                                                      value: micro.value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          micro,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "micro.value",
                                                    },
                                                  }),
                                                ]
                                              : micro.key === "customer"
                                              ? [
                                                  _c("multiselect", {
                                                    staticClass: "my-1",
                                                    attrs: {
                                                      disabled: "",
                                                      id: micro.microName,
                                                      options:
                                                        micro.microOptions,
                                                      placeholder: "Select one",
                                                      multiple: false,
                                                      "show-labels": false,
                                                      required: "",
                                                      "custom-label":
                                                        _vm.customLabel,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.selectCustomer(
                                                          micro.value
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: micro.value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          micro,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "micro.value",
                                                    },
                                                  }),
                                                ]
                                              : [
                                                  _c("multiselect", {
                                                    staticClass: "my-1",
                                                    attrs: {
                                                      disabled: "",
                                                      id: micro.microName,
                                                      options:
                                                        micro.microOptions,
                                                      placeholder: "Select one",
                                                      multiple: false,
                                                      "show-labels": false,
                                                      required: "",
                                                      "custom-label":
                                                        _vm.customLabel,
                                                    },
                                                    model: {
                                                      value: micro.value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          micro,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "micro.value",
                                                    },
                                                  }),
                                                ],
                                          ],
                                          2
                                        ),
                                      ]
                                    : micro.key === "orderNumber"
                                    ? [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: micro.microName + " :",
                                              "label-cols-sm": "4",
                                              "label-cols-lg": "3",
                                              "content-cols-sm": "8",
                                              "content-cols-lg": "9",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              staticClass:
                                                "form-control form-control-sm py-0",
                                              attrs: {
                                                disabled: "",
                                                type: micro.microType,
                                                placeholder: micro.microText,
                                              },
                                              model: {
                                                value: micro.value,
                                                callback: function ($$v) {
                                                  _vm.$set(micro, "value", $$v)
                                                },
                                                expression: "micro.value",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: micro.microName + " :",
                                              "label-cols-sm": "4",
                                              "label-cols-lg": "3",
                                              "content-cols-sm": "8",
                                              "content-cols-lg": "9",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              staticClass:
                                                "form-control form-control-sm py-0",
                                              attrs: {
                                                disabled: "",
                                                type: micro.microType,
                                                placeholder: micro.microText,
                                                required: "",
                                              },
                                              model: {
                                                value: micro.value,
                                                callback: function ($$v) {
                                                  _vm.$set(micro, "value", $$v)
                                                },
                                                expression: "micro.value",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                ],
                                2
                              )
                            }),
                            0
                          ),
                        ]),
                      ])
                    : _vm._e()
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "row mb-3" },
                _vm._l(_vm.component, function (field) {
                  return field.componentName === "fieldTable"
                    ? _c("div", { class: [field.componentClass] }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "hot-container col-md-12 position-relative",
                              staticStyle: {
                                "overflow-x": "auto",
                                "z-index": "0",
                              },
                            },
                            [
                              _c("hot-table", {
                                attrs: {
                                  settings: _vm.hotSettings,
                                  data: _vm.fixExcelData,
                                  rowHeaders: true,
                                  colHeaders: true,
                                  colWidths: _vm.widthTable,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e()
                }),
                0
              ),
              _c("div", { staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col-md-8 scale-9" }, [
                  _c(
                    "div",
                    { staticClass: "row d-flex" },
                    [
                      _c("div", { staticClass: "card h-100 col-8 p-0" }, [
                        _c(
                          "div",
                          { staticClass: "card-header bg-light py-1" },
                          [_vm._v("Catatan Dokter")]
                        ),
                        _c("textarea", {
                          staticStyle: { resize: "none", height: "150px" },
                          attrs: { disabled: "" },
                          domProps: { value: _vm.fixDataStore.dtAmnestesiP },
                        }),
                      ]),
                      _vm._l(_vm.component, function (field) {
                        return field.componentName === "fieldNote"
                          ? _c("div", { class: ["h-100 col-4"] }, [
                              _c(
                                "div",
                                { staticClass: "card h-100" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-header bg-light py-1",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(field.componentTitle) +
                                          " Tambahan "
                                      ),
                                    ]
                                  ),
                                  _vm._l(
                                    field.componentMicro,
                                    function (micro) {
                                      return [
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: micro.microValue,
                                              expression: "micro.microValue",
                                            },
                                          ],
                                          staticStyle: {
                                            resize: "none",
                                            height: "150px",
                                          },
                                          attrs: {
                                            placeholder: micro.microText,
                                          },
                                          domProps: { value: micro.microValue },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                micro,
                                                "microValue",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ])
                          : _vm._e()
                      }),
                    ],
                    2
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "col-md-4 row p-0 scale-9",
                    staticStyle: { "transform-origin": "right top" },
                  },
                  _vm._l(_vm.component, function (field) {
                    return field.componentName === "fieldTotal"
                      ? _c(
                          "div",
                          { class: [field.componentClass] },
                          [
                            field.componentMicro.length > 0
                              ? _vm._l(field.componentMicro, function (micro) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row d-flex justify-content-end",
                                    },
                                    [
                                      micro.key === "tableTotal"
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                class: [
                                                  "border border-light bg-light d-flex justify-content-between align-items-center",
                                                  micro.microClass,
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(micro.microName) +
                                                    " "
                                                ),
                                                _c("span", [_vm._v(":")]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                class: [
                                                  "p-0 border border-light",
                                                  micro.microClass,
                                                ],
                                              },
                                              [
                                                _c("input", {
                                                  staticClass:
                                                    "form-control form-control-sm py-1",
                                                  staticStyle: {
                                                    "text-align": "right",
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      !micro.editableTotal,
                                                    type: micro.microValue,
                                                  },
                                                  domProps: {
                                                    value: _vm.formatNumberInt(
                                                      _vm.subTotal()
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        : micro.key === "dp"
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                class: [
                                                  "border border-light bg-light d-flex justify-content-between align-items-center",
                                                  micro.microClass,
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(micro.microName) +
                                                    " "
                                                ),
                                                _c("span", [_vm._v(":")]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                class: [
                                                  "p-0 border border-light",
                                                  micro.microClass,
                                                ],
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "format-number",
                                                      rawName:
                                                        "v-format-number",
                                                    },
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.totalDp,
                                                      expression: "totalDp",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm py-1",
                                                  staticStyle: {
                                                    "text-align": "right",
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      !micro.editableTotal,
                                                    type: "text",
                                                  },
                                                  domProps: {
                                                    value: _vm.totalDp,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkTotal(
                                                        "DP"
                                                      )
                                                    },
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.totalDp =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        : micro.key === "diskon"
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                class: [
                                                  "border border-light bg-light d-flex justify-content-between align-items-center",
                                                  micro.microClass,
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(micro.microName) +
                                                    " "
                                                ),
                                                _c("span", [_vm._v(":")]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                class: [
                                                  "p-0 border border-light",
                                                  micro.microClass,
                                                ],
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "format-number",
                                                      rawName:
                                                        "v-format-number",
                                                    },
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.totalDiskon,
                                                      expression: "totalDiskon",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm py-1",
                                                  staticStyle: {
                                                    "text-align": "right",
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      !micro.editableTotal,
                                                    type: "text",
                                                  },
                                                  domProps: {
                                                    value: _vm.totalDiskon,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkTotal(
                                                        "Diskon"
                                                      )
                                                    },
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.totalDiskon =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        : micro.key === "total"
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                class: [
                                                  "border border-light bg-light d-flex justify-content-between align-items-center",
                                                  micro.microClass,
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(micro.microName) +
                                                    " "
                                                ),
                                                _c("span", [_vm._v(":")]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                class: [
                                                  "p-0 border border-light",
                                                  micro.microClass,
                                                ],
                                              },
                                              [
                                                _c("input", {
                                                  staticClass:
                                                    "form-control form-control-sm py-1",
                                                  staticStyle: {
                                                    "text-align": "right",
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      !micro.editableTotal,
                                                    type: micro.microValue,
                                                  },
                                                  domProps: {
                                                    value: _vm.formatNumberInt(
                                                      _vm.grandTotal()
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        : micro.key === "ppn"
                                        ? [
                                            _vm.checkedPajak
                                              ? [
                                                  _c(
                                                    "div",
                                                    {
                                                      class: [
                                                        "border border-light bg-light d-flex justify-content-between align-items-center",
                                                        micro.microClass,
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            micro.microName
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("span", [_vm._v(":")]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      class: [
                                                        "p-0 border border-light",
                                                        micro.microClass,
                                                      ],
                                                    },
                                                    [
                                                      _c("input", {
                                                        staticClass:
                                                          "form-control form-control-sm py-1",
                                                        staticStyle: {
                                                          "text-align": "right",
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            !micro.editableTotal,
                                                          type: micro.microValue,
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.formatNumberInt(
                                                              _vm.totalPPN
                                                            ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ]
                                        : [
                                            _c(
                                              "div",
                                              {
                                                class: [
                                                  "border border-light bg-light d-flex justify-content-between align-items-center",
                                                  micro.microClass,
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(micro.microName) +
                                                    " "
                                                ),
                                                _c("span", [_vm._v(":")]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                class: [
                                                  "p-0 border border-light",
                                                  micro.microClass,
                                                ],
                                              },
                                              [
                                                micro.microType === "checkbox"
                                                  ? _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: micro.value,
                                                          expression:
                                                            "micro.value",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control form-control-sm py-1",
                                                      staticStyle: {
                                                        "text-align": "right",
                                                      },
                                                      attrs: {
                                                        disabled:
                                                          !micro.editableTotal,
                                                        type: "checkbox",
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          micro.value
                                                        )
                                                          ? _vm._i(
                                                              micro.value,
                                                              null
                                                            ) > -1
                                                          : micro.value,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.refreshMethods()
                                                        },
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$a = micro.value,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = null,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  micro,
                                                                  "value",
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  micro,
                                                                  "value",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              micro,
                                                              "value",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                      },
                                                    })
                                                  : micro.microType === "radio"
                                                  ? _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: micro.value,
                                                          expression:
                                                            "micro.value",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control form-control-sm py-1",
                                                      staticStyle: {
                                                        "text-align": "right",
                                                      },
                                                      attrs: {
                                                        disabled:
                                                          !micro.editableTotal,
                                                        type: "radio",
                                                      },
                                                      domProps: {
                                                        checked: _vm._q(
                                                          micro.value,
                                                          null
                                                        ),
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.refreshMethods()
                                                        },
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.$set(
                                                            micro,
                                                            "value",
                                                            null
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: micro.value,
                                                          expression:
                                                            "micro.value",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control form-control-sm py-1",
                                                      staticStyle: {
                                                        "text-align": "right",
                                                      },
                                                      attrs: {
                                                        disabled:
                                                          !micro.editableTotal,
                                                        type: micro.microType,
                                                      },
                                                      domProps: {
                                                        value: micro.value,
                                                      },
                                                      on: {
                                                        input: [
                                                          function ($event) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              micro,
                                                              "value",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                          function ($event) {
                                                            return _vm.refreshMethods()
                                                          },
                                                        ],
                                                      },
                                                    }),
                                              ]
                                            ),
                                          ],
                                    ],
                                    2
                                  )
                                })
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 d-flex justify-content-center pt-3 align-items-center",
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: _vm.posts.transRouting.replace(
                                          "-edit",
                                          ""
                                        ),
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-outline-secondary btn-xs py-1 me-2",
                                        attrs: { type: "button" },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "bi-chevron-left",
                                        }),
                                        _vm._v("Kembali "),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary btn-xs py-1",
                                    attrs: { type: "submit" },
                                  },
                                  [_vm._v("Simpan")]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        )
                      : _vm._e()
                  }),
                  0
                ),
              ]),
            ])
          : _c("div", { key: "other" }, [_c("connection-timeout")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }