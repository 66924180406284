var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.isDataLoaded === ""
          ? _c("div", { key: "empty" }, [_c("loading")], 1)
          : _vm.isDataLoaded
          ? _c("div", { key: "truthy" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 d-flex align-items-end" }, [
                  _c("ul", { staticClass: "breadcrumb m-1 mt-0" }, [
                    _c(
                      "li",
                      [
                        _c("router-link", { attrs: { to: "/dashboard" } }, [
                          _c("a", { attrs: { href: "#" } }, [
                            _c("i", { staticClass: "bi-house-door" }),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c("li", [_vm._v("Master Item")]),
                  ]),
                ]),
                _c("div", { staticClass: "col-4 text-center" }, [
                  _c("span", { staticClass: "h2" }, [
                    _vm._v("List Master Item"),
                  ]),
                ]),
                _c("hr", { staticClass: "mb-3 mt-1" }),
              ]),
              _c(
                "div",
                { staticClass: "card mb-3 mb-lg-5" },
                [
                  _c(
                    "div",
                    { staticClass: "card-header py-0 bg-custom-1" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "b-input-group",
                                { staticClass: "mx-2", attrs: { size: "sm" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "filter-input",
                                      type: "search",
                                      placeholder: "Type to Search",
                                    },
                                    model: {
                                      value: _vm.filter,
                                      callback: function ($$v) {
                                        _vm.filter = $$v
                                      },
                                      expression: "filter",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            size: "sm",
                                            disabled: !_vm.filter,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.filter = ""
                                            },
                                          },
                                        },
                                        [_vm._v("Clear")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "d-flex justify-content-end align-items-center",
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.modal-add",
                                      modifiers: { "modal-add": true },
                                    },
                                  ],
                                  attrs: { variant: "primary", size: "sm" },
                                },
                                [_c("i", { staticClass: "bi-plus-circle" })]
                              ),
                              _c(
                                "b-dropdown",
                                {
                                  staticClass: "m-2",
                                  attrs: {
                                    id: "dropdown-right",
                                    right: "",
                                    text: "Filter Column",
                                    variant: "dark",
                                    size: "sm",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "button-content",
                                      fn: function () {
                                        return [
                                          _c("i", {
                                            staticClass: "bi-sliders",
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _c("b-form-checkbox-group", {
                                    attrs: {
                                      id: "checkbox-group-1",
                                      options: _vm.fields,
                                      name: "flavour-1",
                                      buttons: "",
                                    },
                                    model: {
                                      value: _vm.selected,
                                      callback: function ($$v) {
                                        _vm.selected = $$v
                                      },
                                      expression: "selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.selected.length > 0
                    ? _c("b-table", {
                        ref: "tableUsaha",
                        staticClass: "fs-11",
                        attrs: {
                          "label-sort-asc": "",
                          "label-sort-desc": "",
                          "label-sort-clear": "",
                          bordered: true,
                          stacked: "md",
                          items: _vm.usaha,
                          fields: _vm.activeFields,
                          "current-page": _vm.currentPage,
                          "per-page": _vm.perPage,
                          filter: _vm.filter,
                          "tbody-tr-class": "row-class",
                        },
                        on: { filtered: _vm.onFiltered },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(opsi)",
                              fn: function (row) {
                                return [
                                  _c(
                                    "div",
                                    { attrs: { width: "0px" } },
                                    [
                                      _c(
                                        "b-dropdown",
                                        {
                                          staticClass: "button-y0",
                                          attrs: {
                                            id: "dropdown-right",
                                            right: "",
                                            text: "Right align",
                                            variant: "primary",
                                            size: "xs",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "button-content",
                                                fn: function () {
                                                  return [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi-gear-fill",
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.info(
                                                    row.item,
                                                    row.index,
                                                    $event.target
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Detail")]
                                          ),
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.edit(
                                                    row.item,
                                                    row.index,
                                                    $event.target
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Edit")]
                                          ),
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: { href: "#" },
                                              on: { click: _vm.alertDelete },
                                            },
                                            [_vm._v("Hapus")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(icon)",
                              fn: function (row) {
                                return undefined
                              },
                            },
                            {
                              key: "cell(itemGroup)",
                              fn: function (data) {
                                return _vm._l(
                                  data.item.itemGroup,
                                  function (group) {
                                    return _c("p", { staticClass: "m-0" }, [
                                      _vm._v("- " + _vm._s(group)),
                                    ])
                                  }
                                )
                              },
                            },
                            {
                              key: "cell(itemMasterPrice)",
                              fn: function (data) {
                                return [
                                  _vm._l(
                                    data.item.itemMasterPrice,
                                    function (group) {
                                      return group.priceName === "Default"
                                        ? _vm._l(
                                            group.priceData,
                                            function (data) {
                                              return _c(
                                                "p",
                                                { staticClass: "m-0" },
                                                [
                                                  _vm._v(
                                                    _vm._s(data.priceQty) +
                                                      " = " +
                                                      _vm._s(
                                                        data.priceValue
                                                          ? data.priceValue
                                                          : 0
                                                      )
                                                  ),
                                                ]
                                              )
                                            }
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2134481808
                        ),
                      })
                    : _vm._e(),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: _vm.infoModal.id,
                        size: "lg",
                        title: _vm.infoModal.title,
                        "ok-only": "",
                        "no-close-on-esc": "",
                        "no-close-on-backdrop": "",
                        "hide-header-close": "",
                        "body-class": ["p-0"],
                      },
                      on: { hide: _vm.resetInfoModal },
                      scopedSlots: _vm._u([
                        {
                          key: "modal-footer",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-100 d-flex justify-content-end",
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "me-2",
                                      attrs: {
                                        variant: "secondary",
                                        size: "sm",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$bvModal.hide("info-modal")
                                        },
                                      },
                                    },
                                    [_vm._v(" Kembali ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "b-tabs",
                        {
                          staticClass: "custom-tabs",
                          attrs: { "content-class": "mt-3 px-5", card: "" },
                        },
                        [
                          _c(
                            "b-tab",
                            { attrs: { title: "General", active: "" } },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-3 d-flex justify-content-center",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "avatar avatar-xxl avatar-circle avatar-uploader profile-cover-avatar",
                                        staticStyle: { margin: "0" },
                                        attrs: {
                                          for: "addImage",
                                          tabindex: "-1",
                                          "invalid-feedback":
                                            "Provinsi Harus Diisi!",
                                          state: _vm.imageState,
                                        },
                                        on: {
                                          change: (event) =>
                                            _vm.previewFiles(event),
                                        },
                                      },
                                      [
                                        _vm.general.image !== ""
                                          ? _c("img", {
                                              staticClass: "avatar-img",
                                              attrs: {
                                                id: "editAvatarImgModal",
                                                src: _vm.general.image,
                                                alt: "Image Description",
                                              },
                                            })
                                          : _c("img", {
                                              staticClass: "avatar-img",
                                              attrs: {
                                                id: "editAvatarImgModal",
                                                src: "assets/img/160x160/img0.jpg",
                                                alt: "Image Description",
                                              },
                                            }),
                                        _c("input", {
                                          staticClass:
                                            "js-file-attach avatar-uploader-input",
                                          attrs: {
                                            tabindex: "-1",
                                            type: "file",
                                            id: "addImage",
                                            state: _vm.imageState,
                                            required: "",
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "avatar-uploader-trigger",
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "bi-pencil-fill avatar-uploader-icon shadow-sm",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "row col-md-9" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Kode (Barcode)",
                                            "label-for": "itemCode",
                                            "invalid-feedback":
                                              "Kode Item Harus Diisi!",
                                            state: _vm.groupState,
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "itemCode",
                                              state: _vm.groupState,
                                              required: "",
                                              disabled: "",
                                              placeholder:
                                                "Kode Item (Barcode)",
                                            },
                                            model: {
                                              value: _vm.group,
                                              callback: function ($$v) {
                                                _vm.group = $$v
                                              },
                                              expression: "group",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Nama",
                                            "label-for": "itemName",
                                            "invalid-feedback":
                                              "Nama Item Harus Diisi!",
                                            state: _vm.NameState,
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "itemName",
                                              state: _vm.NameState,
                                              required: "",
                                              disabled: "",
                                              placeholder: "Nama Item",
                                            },
                                            model: {
                                              value: _vm.name,
                                              callback: function ($$v) {
                                                _vm.name = $$v
                                              },
                                              expression: "name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Deskripsi (Spec)",
                                            "label-for": "itemDefStatus",
                                            "invalid-feedback":
                                              "Deskripsi Harus Diisi!",
                                            state: _vm.descState,
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "itemDefStatus",
                                              state: _vm.descState,
                                              required: "",
                                              disabled: "",
                                              placeholder: "Deskripsi Item",
                                            },
                                            model: {
                                              value: _vm.desc,
                                              callback: function ($$v) {
                                                _vm.desc = $$v
                                              },
                                              expression: "desc",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Merk",
                                            "label-for": "itemAddMerk",
                                            "invalid-feedback":
                                              "Tipe Item Harus Diisi!",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "itemAddMerk",
                                              required: "",
                                              disabled: "",
                                              placeholder: "Merk Item",
                                            },
                                            model: {
                                              value: _vm.general.merk,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.general,
                                                  "merk",
                                                  $$v
                                                )
                                              },
                                              expression: "general.merk",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Tipe",
                                            "label-for": "itemAddType",
                                            "invalid-feedback":
                                              "Tipe Item Harus Diisi!",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "itemAddType",
                                              required: "",
                                              disabled: "",
                                              placeholder: "Tipe Item",
                                            },
                                            model: {
                                              value: _vm.general.type,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.general,
                                                  "type",
                                                  $$v
                                                )
                                              },
                                              expression: "general.type",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Default UM",
                                            "label-for": "itemSatuan",
                                            "invalid-feedback":
                                              "Anda Harus Memilih Satuan!",
                                            state: _vm.dateState,
                                          },
                                        },
                                        [
                                          _c("multiselect", {
                                            attrs: {
                                              id: "itemSatuan",
                                              options: _vm.optionsUm,
                                              placeholder: "Pilih Default UM",
                                              taggable: true,
                                              disabled: "",
                                              multiple: false,
                                            },
                                            on: { tag: _vm.addConvert2 },
                                            model: {
                                              value: _vm.finance.itemUm,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.finance,
                                                  "itemUm",
                                                  $$v
                                                )
                                              },
                                              expression: "finance.itemUm",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "col-md-6 mb-2" }),
                                  _c("div", { staticClass: "col-md-12" }, [
                                    _c("hr", { staticClass: "my-2" }),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Kategori",
                                            "label-for": "itemCat",
                                            "invalid-feedback":
                                              "Kategori Item Harus Diisi!",
                                            state: _vm.katState,
                                          },
                                        },
                                        [
                                          _c("multiselect", {
                                            attrs: {
                                              id: "itemCat",
                                              options: _vm.optionsCategory,
                                              disabled: "",
                                              placeholder: "Pilih Kategori",
                                              taggable: true,
                                              multiple: false,
                                              "show-labels": false,
                                            },
                                            on: { tag: _vm.addCategory },
                                            model: {
                                              value: _vm.kat,
                                              callback: function ($$v) {
                                                _vm.kat = $$v
                                              },
                                              expression: "kat",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Jenis",
                                            "label-for": "itemJenis",
                                            "invalid-feedback":
                                              "Jenis Item Harus Diisi!",
                                          },
                                        },
                                        [
                                          _c("multiselect", {
                                            attrs: {
                                              id: "itemJenis",
                                              options: _vm.optionsJenis,
                                              placeholder: "Pilih Jenis",
                                              multiple: false,
                                              disabled: "",
                                              "show-labels": false,
                                            },
                                            model: {
                                              value: _vm.general.jenis,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.general,
                                                  "jenis",
                                                  $$v
                                                )
                                              },
                                              expression: "general.jenis",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Grup",
                                            "label-for": "itemJenis",
                                            "invalid-feedback":
                                              "Jenis Item Harus Diisi!",
                                          },
                                        },
                                        [
                                          _c("multiselect", {
                                            class:
                                              _vm.general.group === null
                                                ? "invalid"
                                                : "",
                                            attrs: {
                                              options: _vm.optionsGroup,
                                              multiple: true,
                                              "clear-on-select": false,
                                              "preserve-search": true,
                                              disabled: "",
                                              placeholder: "Pilih Grup",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "selection",
                                                fn: function ({
                                                  values,
                                                  search,
                                                  isOpen,
                                                }) {
                                                  return [
                                                    values.length
                                                      ? _c(
                                                          "span",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: !isOpen,
                                                                expression:
                                                                  "!isOpen",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "multiselect__single",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                values.length
                                                              ) +
                                                                " data dipilih"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ]),
                                            model: {
                                              value: _vm.general.group,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.general,
                                                  "group",
                                                  $$v
                                                )
                                              },
                                              expression: "general.group",
                                            },
                                          }),
                                          _vm.general.group
                                            ? [
                                                _vm.general.group.length > 0
                                                  ? _c(
                                                      "pre",
                                                      [
                                                        _vm._v("Grup List :\n"),
                                                        _vm._l(
                                                          _vm.general.group,
                                                          function (data) {
                                                            return [
                                                              _vm._v(
                                                                "- " +
                                                                  _vm._s(data) +
                                                                  "\n"
                                                              ),
                                                            ]
                                                          }
                                                        ),
                                                        _vm._v("\n"),
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Bundling",
                                            "label-for": "itemJenis",
                                            "invalid-feedback":
                                              "Jenis Item Harus Diisi!",
                                          },
                                        },
                                        [
                                          _c("multiselect", {
                                            attrs: {
                                              options: _vm.usaha,
                                              label: "itemName",
                                              multiple: true,
                                              "clear-on-select": false,
                                              "preserve-search": true,
                                              disabled: "",
                                              placeholder: "Item Bundling",
                                              "track-by": "_id",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "selection",
                                                fn: function ({
                                                  values,
                                                  search,
                                                  isOpen,
                                                }) {
                                                  return [
                                                    values.length
                                                      ? _c(
                                                          "span",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: !isOpen,
                                                                expression:
                                                                  "!isOpen",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "multiselect__single",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                values.length
                                                              ) +
                                                                " data dipilih"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ]),
                                            model: {
                                              value: _vm.general.bundling,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.general,
                                                  "bundling",
                                                  $$v
                                                )
                                              },
                                              expression: "general.bundling",
                                            },
                                          }),
                                          _vm.general.bundling.length > 0
                                            ? _c(
                                                "pre",
                                                [
                                                  _vm._v("Bundling List :\n"),
                                                  _vm._l(
                                                    _vm.general.bundling,
                                                    function (data) {
                                                      return [
                                                        _vm._v(
                                                          "- " +
                                                            _vm._s(
                                                              data.itemName
                                                            ) +
                                                            "\n"
                                                        ),
                                                      ]
                                                    }
                                                  ),
                                                  _vm._v("\n"),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _c("b-tab", { attrs: { title: "Finance" } }, [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-6 mb-2" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Kode Akun",
                                        "label-for": "itemCat",
                                        "invalid-feedback":
                                          "Anda Harus Memilih Kode Akun!",
                                        state: _vm.typeState,
                                      },
                                    },
                                    [
                                      _c("multiselect", {
                                        attrs: {
                                          id: "itemCat",
                                          options: _vm.optionsCoa,
                                          disabled: "",
                                          placeholder: "Select one",
                                          taggable: false,
                                          multiple: false,
                                          "track-by": "_id",
                                          "show-labels": false,
                                          "custom-label": _vm.customLabel,
                                        },
                                        model: {
                                          value: _vm.type,
                                          callback: function ($$v) {
                                            _vm.type = $$v
                                          },
                                          expression: "type",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-6 mb-2" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Standart HPP",
                                        "label-for": "financeHPP",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { prepend: "Rp." } },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "format-number",
                                                rawName: "v-format-number",
                                              },
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.finance.standartHPP,
                                                expression:
                                                  "finance.standartHPP",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              id: "financeHPP",
                                              disabled: "",
                                              required: "",
                                              placeholder: "0",
                                            },
                                            domProps: {
                                              value: _vm.finance.standartHPP,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.finance,
                                                  "standartHPP",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-6 mb-2" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Standar Jasa",
                                        "label-for": "financeJasa",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { prepend: "Rp." } },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "format-number",
                                                rawName: "v-format-number",
                                              },
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.finance.standartLabour,
                                                expression:
                                                  "finance.standartLabour",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              id: "financeJasa",
                                              disabled: "",
                                              required: "",
                                              placeholder: "0",
                                            },
                                            domProps: {
                                              value: _vm.finance.standartLabour,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.finance,
                                                  "standartLabour",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-6 mb-2" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Harga Jual (Default Price)",
                                        "label-for": "itemPrice",
                                        "invalid-feedback":
                                          "Harga Item Harus Diisi!",
                                        state: _vm.priceState,
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { prepend: "Rp." } },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "format-number",
                                                rawName: "v-format-number",
                                              },
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.price,
                                                expression: "price",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              id: "itemPrice",
                                              disabled: "",
                                              state: _vm.priceState,
                                              required: "",
                                              placeholder: "0",
                                            },
                                            domProps: { value: _vm.price },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.price = $event.target.value
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-4 mt-2 d-none" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "px-2 d-flex justify-content-between align-items-center",
                                      staticStyle: { background: "#113448" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-white m-0" },
                                        [_vm._v("Topping/Add-Ons")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                        },
                                        [
                                          _vm.showTopping
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass: "btn p-0",
                                                  attrs: {
                                                    type: "button",
                                                    "data-toggle": "tooltip",
                                                    title: "Hide",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showTopping =
                                                        !_vm.showTopping
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "bi bi-caret-up-square text-white",
                                                    staticStyle: {
                                                      "font-size": "20px",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _c(
                                                "button",
                                                {
                                                  staticClass: "btn p-0",
                                                  attrs: {
                                                    type: "button",
                                                    "data-toggle": "tooltip",
                                                    title: "Show",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showTopping =
                                                        !_vm.showTopping
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "bi bi-caret-down-square text-white",
                                                    staticStyle: {
                                                      "font-size": "20px",
                                                    },
                                                  }),
                                                ]
                                              ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.showTopping
                                    ? _c(
                                        "table",
                                        { staticClass: "table border" },
                                        [
                                          _c("thead", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("Nama")]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("Harga")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("Opsi")]
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.optionTopping,
                                              function (topping, i) {
                                                return _c("tr", { key: i }, [
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(topping.name)
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(topping.price)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn p-0",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editPrice(
                                                                topping,
                                                                i,
                                                                $event.target
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "bi bi-pencil-square text-warning",
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn p-0",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeData(
                                                                i
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "bi bi-trash text-danger",
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c("div", { staticClass: "col-md-12 mt-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "px-2 d-flex justify-content-between align-items-center",
                                    staticStyle: { background: "#113448" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-white m-0" },
                                      [_vm._v("Master Harga")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _vm.showPrice
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "btn p-0",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "tooltip",
                                                  title: "Hide",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showPrice =
                                                      !_vm.showPrice
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-caret-up-square text-white",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "button",
                                              {
                                                staticClass: "btn p-0",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "tooltip",
                                                  title: "Show",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showPrice =
                                                      !_vm.showPrice
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-caret-down-square text-white",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                }),
                                              ]
                                            ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm.showPrice
                                  ? _c(
                                      "table",
                                      { staticClass: "table border" },
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Nama")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Qty")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Harga")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "col" },
                                              },
                                              [_vm._v("Opsi")]
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.optionAdd,
                                            function (price, i) {
                                              return _c("tr", { key: i }, [
                                                _c(
                                                  "td",
                                                  { staticClass: "align-top" },
                                                  [_vm._v(_vm._s(price.name))]
                                                ),
                                                _c(
                                                  "td",
                                                  _vm._l(
                                                    price.masterPrice,
                                                    function (data) {
                                                      return _c(
                                                        "p",
                                                        { staticClass: "m-0" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data.priceQty
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c(
                                                  "td",
                                                  _vm._l(
                                                    price.masterPrice,
                                                    function (data) {
                                                      return _c(
                                                        "p",
                                                        { staticClass: "m-0" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data.priceValue
                                                            ) + " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-center align-top",
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass: "btn p-0",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.detailPrice(
                                                              price,
                                                              i,
                                                              $event.target
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-eye text-success",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "col-md-12 mt-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "px-2 d-flex justify-content-between align-items-center bg-primary",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-white m-0" },
                                      [_vm._v("Diskon")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _vm.showDiskon
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "btn p-0",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "tooltip",
                                                  title: "Hide",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showDiskon =
                                                      !_vm.showDiskon
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-caret-up-square text-white",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "button",
                                              {
                                                staticClass: "btn p-0",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "tooltip",
                                                  title: "Show",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showDiskon =
                                                      !_vm.showDiskon
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-caret-down-square text-white",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                }),
                                              ]
                                            ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm.showDiskon
                                  ? _c(
                                      "table",
                                      { staticClass: "table border" },
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Qty")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Tipe")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Nominal")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Tgl. Mulai")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Tgl. Selesai")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "col" },
                                              },
                                              [_vm._v("Opsi")]
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.optionDiskon,
                                            function (diskon, i) {
                                              return _c("tr", { key: i }, [
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(diskon.diskonQty)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(diskon.diskonType)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(diskon.diskonValue)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      diskon.diskonStartDate
                                                    )
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(diskon.diskonEndDate)
                                                  ),
                                                ]),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass: "btn p-0",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.editPrice(
                                                              diskon,
                                                              i,
                                                              $event.target
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-pencil-square text-warning",
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass: "btn p-0",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeData(
                                                              i
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-trash text-danger",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-md-12 mt-2 d-none" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "px-2 d-flex justify-content-between align-items-center",
                                      staticStyle: { background: "#113448" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-white m-0" },
                                        [_vm._v("Pajak")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                        },
                                        [
                                          _vm.showTax
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass: "btn p-0",
                                                  attrs: {
                                                    type: "button",
                                                    "data-toggle": "tooltip",
                                                    title: "Hide",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showTax = !_vm.showTax
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "bi bi-caret-up-square text-white",
                                                    staticStyle: {
                                                      "font-size": "20px",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _c(
                                                "button",
                                                {
                                                  staticClass: "btn p-0",
                                                  attrs: {
                                                    type: "button",
                                                    "data-toggle": "tooltip",
                                                    title: "Show",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showTax = !_vm.showTax
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "bi bi-caret-down-square text-white",
                                                    staticStyle: {
                                                      "font-size": "20px",
                                                    },
                                                  }),
                                                ]
                                              ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.showTax
                                    ? _c(
                                        "table",
                                        { staticClass: "table border" },
                                        [
                                          _c("thead", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("Nama")]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("Jumlah")]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("Tgl.Mulai")]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("Tgl. Selesai")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("Opsi")]
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.optionTax,
                                              function (tax, i) {
                                                return _c("tr", { key: i }, [
                                                  _c("td", [
                                                    _vm._v(_vm._s(tax.taxName)),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(_vm._s(tax.taxQty)),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(tax.taxStartDate)
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(tax.taxEndDate)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn p-0",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editPrice(
                                                                tax,
                                                                i,
                                                                $event.target
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "bi bi-pencil-square text-warning",
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn p-0",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeData(
                                                                i
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "bi bi-trash text-danger",
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]),
                          _c("b-tab", { attrs: { title: "Inventory" } }, [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-6 mb-2" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "PO receipt",
                                        "label-for": "itemJenis",
                                        "invalid-feedback":
                                          "Jenis Item Harus Diisi!",
                                      },
                                    },
                                    [
                                      _c("multiselect", {
                                        attrs: {
                                          id: "itemJenis",
                                          options: _vm.optionsLocation,
                                          placeholder:
                                            "Lokasi Default PO receipt",
                                          multiple: false,
                                          disabled: "",
                                          "show-labels": false,
                                          "custom-label": _vm.labelLocation,
                                        },
                                        model: {
                                          value: _vm.inventory.poReceipt,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inventory,
                                              "poReceipt",
                                              $$v
                                            )
                                          },
                                          expression: "inventory.poReceipt",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-6 mb-2" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "WO receipt",
                                        "label-for": "itemJenis",
                                        "invalid-feedback":
                                          "Jenis Item Harus Diisi!",
                                      },
                                    },
                                    [
                                      _c("multiselect", {
                                        attrs: {
                                          id: "itemJenis",
                                          options: _vm.optionsLocation,
                                          placeholder:
                                            "Lokasi Default WO receipt",
                                          multiple: false,
                                          disabled: "",
                                          "show-labels": false,
                                          "custom-label": _vm.labelLocation,
                                        },
                                        model: {
                                          value: _vm.inventory.woReceipt,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inventory,
                                              "woReceipt",
                                              $$v
                                            )
                                          },
                                          expression: "inventory.woReceipt",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "col-md-12 mt-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "px-2 d-flex justify-content-between align-items-center bg-primary",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-white m-0" },
                                      [_vm._v("Unit Konversi")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _vm.showConvert
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "btn p-0",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "tooltip",
                                                  title: "Hide",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showConvert =
                                                      !_vm.showConvert
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-caret-up-square text-white",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "button",
                                              {
                                                staticClass: "btn p-0",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "tooltip",
                                                  title: "Show",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showConvert =
                                                      !_vm.showConvert
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-caret-down-square text-white",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                }),
                                              ]
                                            ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm.showConvert
                                  ? _c(
                                      "table",
                                      { staticClass: "table border" },
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("*Qty. Awal")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("*UM. Awal")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("*Qty. Konversi")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("*UM. Konversi")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "col" },
                                              },
                                              [_vm._v("Opsi")]
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.optionConvert,
                                            function (convert, i) {
                                              return _c("tr", { key: i }, [
                                                _c("td", [
                                                  _vm._v(_vm._s(convert.qtyA)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(convert.umA)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(convert.qtyB)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(convert.umB)),
                                                ]),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass: "btn p-0",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.editConvert(
                                                              convert,
                                                              i,
                                                              $event.target
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-pencil-square text-warning",
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass: "btn p-0",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeData(
                                                              i,
                                                              "optionConvert"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-trash text-danger",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]),
                          _c("b-tab", { attrs: { title: "Akun" } }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12 mt-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "px-2 d-flex justify-content-between align-items-center bg-primary",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-white m-0" },
                                      [_vm._v("Master Akun")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName:
                                                  "v-b-modal.modal-addConvert",
                                                modifiers: {
                                                  "modal-addConvert": true,
                                                },
                                              },
                                            ],
                                            staticClass: "btn p-0",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "tooltip",
                                              title: "Tambah Konversi",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "bi bi-plus text-white",
                                              staticStyle: {
                                                "font-size": "25px",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm.showConvert
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "btn p-0",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "tooltip",
                                                  title: "Hide",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showConvert =
                                                      !_vm.showConvert
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-caret-up-square text-white",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "button",
                                              {
                                                staticClass: "btn p-0",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "tooltip",
                                                  title: "Show",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showConvert =
                                                      !_vm.showConvert
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-caret-down-square text-white",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                }),
                                              ]
                                            ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm.showConvert
                                  ? _c(
                                      "table",
                                      { staticClass: "table border" },
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("*Operasi")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("*Kredit")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("*Debet")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("*Setting Operasi")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "col" },
                                              },
                                              [_vm._v("Opsi")]
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.optionConvert,
                                            function (convert, i) {
                                              return _c("tr", { key: i }, [
                                                _c("td", [
                                                  _vm._v(_vm._s(convert.qtyA)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(convert.umA)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(convert.qtyB)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(convert.umB)),
                                                ]),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass: "btn p-0",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.editConvert(
                                                              convert,
                                                              i,
                                                              $event.target
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-pencil-square text-warning",
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass: "btn p-0",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeData(
                                                              i,
                                                              "optionConvert"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-trash text-danger",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: _vm.editModal.id,
                        size: "lg",
                        title: _vm.editModal.title,
                        "ok-title": "Simpan",
                        "body-class": ["p-0"],
                      },
                      on: { hidden: _vm.resetEditModal, ok: _vm.handleEdit },
                    },
                    [
                      _c(
                        "form",
                        {
                          ref: "form",
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.handleSubmit.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "b-tabs",
                            {
                              staticClass: "custom-tabs",
                              attrs: { "content-class": "mt-3 px-5", card: "" },
                            },
                            [
                              _c(
                                "b-tab",
                                { attrs: { title: "General", active: "" } },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-md-3 d-flex justify-content-center",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "avatar avatar-xxl avatar-circle avatar-uploader profile-cover-avatar",
                                            staticStyle: { margin: "0" },
                                            attrs: {
                                              for: "addImage",
                                              tabindex: "-1",
                                              "invalid-feedback":
                                                "Provinsi Harus Diisi!",
                                              state: _vm.imageState,
                                            },
                                            on: {
                                              change: (event) =>
                                                _vm.previewFiles(event),
                                            },
                                          },
                                          [
                                            _vm.general.image !== ""
                                              ? _c("img", {
                                                  staticClass: "avatar-img",
                                                  attrs: {
                                                    id: "editAvatarImgModal",
                                                    src: _vm.general.image,
                                                    alt: "Image Description",
                                                  },
                                                })
                                              : _c("img", {
                                                  staticClass: "avatar-img",
                                                  attrs: {
                                                    id: "editAvatarImgModal",
                                                    src: "assets/img/160x160/img0.jpg",
                                                    alt: "Image Description",
                                                  },
                                                }),
                                            _c("input", {
                                              staticClass:
                                                "js-file-attach avatar-uploader-input",
                                              attrs: {
                                                tabindex: "-1",
                                                type: "file",
                                                id: "addImage",
                                                state: _vm.imageState,
                                                required: "",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "avatar-uploader-trigger",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi-pencil-fill avatar-uploader-icon shadow-sm",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "row col-md-9" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Kode (Barcode)",
                                                "label-for": "itemCode",
                                                "invalid-feedback":
                                                  "Kode Item Harus Diisi!",
                                                state: _vm.groupState,
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "itemCode",
                                                  state: _vm.groupState,
                                                  required: "",
                                                  placeholder:
                                                    "Kode Item (Barcode)",
                                                },
                                                model: {
                                                  value: _vm.group,
                                                  callback: function ($$v) {
                                                    _vm.group = $$v
                                                  },
                                                  expression: "group",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Nama",
                                                "label-for": "itemName",
                                                "invalid-feedback":
                                                  "Nama Item Harus Diisi!",
                                                state: _vm.NameState,
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "itemName",
                                                  state: _vm.NameState,
                                                  required: "",
                                                  placeholder: "Nama Item",
                                                },
                                                model: {
                                                  value: _vm.name,
                                                  callback: function ($$v) {
                                                    _vm.name = $$v
                                                  },
                                                  expression: "name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Deskripsi (Spec)",
                                                "label-for": "itemDefStatus",
                                                "invalid-feedback":
                                                  "Deskripsi Harus Diisi!",
                                                state: _vm.descState,
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "itemDefStatus",
                                                  state: _vm.descState,
                                                  required: "",
                                                  placeholder: "Deskripsi Item",
                                                },
                                                model: {
                                                  value: _vm.desc,
                                                  callback: function ($$v) {
                                                    _vm.desc = $$v
                                                  },
                                                  expression: "desc",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Merk",
                                                "label-for": "itemAddMerk",
                                                "invalid-feedback":
                                                  "Tipe Item Harus Diisi!",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "itemAddMerk",
                                                  required: "",
                                                  placeholder: "Merk Item",
                                                },
                                                model: {
                                                  value: _vm.general.merk,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.general,
                                                      "merk",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "general.merk",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Tipe",
                                                "label-for": "itemAddType",
                                                "invalid-feedback":
                                                  "Tipe Item Harus Diisi!",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "itemAddType",
                                                  required: "",
                                                  placeholder: "Tipe Item",
                                                },
                                                model: {
                                                  value: _vm.general.type,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.general,
                                                      "type",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "general.type",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Default UM",
                                                "label-for": "itemSatuan",
                                                "invalid-feedback":
                                                  "Anda Harus Memilih Satuan!",
                                                state: _vm.dateState,
                                              },
                                            },
                                            [
                                              _c("multiselect", {
                                                attrs: {
                                                  id: "itemSatuan",
                                                  options: _vm.optionsUm,
                                                  placeholder:
                                                    "Pilih Default UM",
                                                  taggable: true,
                                                  multiple: false,
                                                },
                                                on: { tag: _vm.addConvert2 },
                                                model: {
                                                  value: _vm.finance.itemUm,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.finance,
                                                      "itemUm",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "finance.itemUm",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("div", {
                                        staticClass: "col-md-6 mb-2",
                                      }),
                                      _c("div", { staticClass: "col-md-12" }, [
                                        _c("hr", { staticClass: "my-2" }),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Kategori",
                                                "label-for": "itemCat",
                                                "invalid-feedback":
                                                  "Kategori Item Harus Diisi!",
                                                state: _vm.katState,
                                              },
                                            },
                                            [
                                              _c("multiselect", {
                                                attrs: {
                                                  id: "itemCat",
                                                  options: _vm.optionsCategory,
                                                  placeholder: "Pilih Kategori",
                                                  taggable: true,
                                                  multiple: false,
                                                  "show-labels": false,
                                                },
                                                on: { tag: _vm.addCategory },
                                                model: {
                                                  value: _vm.kat,
                                                  callback: function ($$v) {
                                                    _vm.kat = $$v
                                                  },
                                                  expression: "kat",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Jenis",
                                                "label-for": "itemJenis",
                                                "invalid-feedback":
                                                  "Jenis Item Harus Diisi!",
                                              },
                                            },
                                            [
                                              _c("multiselect", {
                                                attrs: {
                                                  id: "itemJenis",
                                                  options: _vm.optionsJenis,
                                                  placeholder: "Pilih Jenis",
                                                  multiple: false,
                                                  "show-labels": false,
                                                },
                                                model: {
                                                  value: _vm.general.jenis,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.general,
                                                      "jenis",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "general.jenis",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Grup",
                                                "label-for": "itemJenis",
                                                "invalid-feedback":
                                                  "Jenis Item Harus Diisi!",
                                              },
                                            },
                                            [
                                              _c("multiselect", {
                                                class:
                                                  _vm.general.group === null
                                                    ? "invalid"
                                                    : "",
                                                attrs: {
                                                  options: _vm.optionsGroup,
                                                  multiple: true,
                                                  "clear-on-select": false,
                                                  "preserve-search": true,
                                                  placeholder: "Pilih Grup",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "selection",
                                                    fn: function ({
                                                      values,
                                                      search,
                                                      isOpen,
                                                    }) {
                                                      return [
                                                        values.length
                                                          ? _c(
                                                              "span",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      !isOpen,
                                                                    expression:
                                                                      "!isOpen",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "multiselect__single",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    values.length
                                                                  ) +
                                                                    " data dipilih"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                                model: {
                                                  value: _vm.general.group,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.general,
                                                      "group",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "general.group",
                                                },
                                              }),
                                              _vm.general.group
                                                ? [
                                                    _vm.general.group.length > 0
                                                      ? _c(
                                                          "pre",
                                                          [
                                                            _vm._v(
                                                              "Grup List :\n"
                                                            ),
                                                            _vm._l(
                                                              _vm.general.group,
                                                              function (data) {
                                                                return [
                                                                  _vm._v(
                                                                    "- " +
                                                                      _vm._s(
                                                                        data
                                                                      ) +
                                                                      "\n"
                                                                  ),
                                                                ]
                                                              }
                                                            ),
                                                            _vm._v("\n"),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Bundling",
                                                "label-for": "itemJenis",
                                                "invalid-feedback":
                                                  "Jenis Item Harus Diisi!",
                                              },
                                            },
                                            [
                                              _c("multiselect", {
                                                attrs: {
                                                  options: _vm.usaha,
                                                  label: "itemName",
                                                  multiple: true,
                                                  "clear-on-select": false,
                                                  "preserve-search": true,
                                                  placeholder: "Item Bundling",
                                                  "track-by": "_id",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "selection",
                                                    fn: function ({
                                                      values,
                                                      search,
                                                      isOpen,
                                                    }) {
                                                      return [
                                                        values.length
                                                          ? _c(
                                                              "span",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      !isOpen,
                                                                    expression:
                                                                      "!isOpen",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "multiselect__single",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    values.length
                                                                  ) +
                                                                    " data dipilih"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                                model: {
                                                  value: _vm.general.bundling,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.general,
                                                      "bundling",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "general.bundling",
                                                },
                                              }),
                                              _vm.general.bundling.length > 0
                                                ? _c(
                                                    "pre",
                                                    [
                                                      _vm._v(
                                                        "Bundling List :\n"
                                                      ),
                                                      _vm._l(
                                                        _vm.general.bundling,
                                                        function (data) {
                                                          return [
                                                            _vm._v(
                                                              "- " +
                                                                _vm._s(
                                                                  data.itemName
                                                                ) +
                                                                "\n"
                                                            ),
                                                          ]
                                                        }
                                                      ),
                                                      _vm._v("\n"),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c("b-tab", { attrs: { title: "Finance" } }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Kode Akun",
                                            "label-for": "itemCat",
                                            "invalid-feedback":
                                              "Anda Harus Memilih Kode Akun!",
                                            state: _vm.typeState,
                                          },
                                        },
                                        [
                                          _c("multiselect", {
                                            attrs: {
                                              id: "itemCat",
                                              options: _vm.optionsCoa,
                                              placeholder: "Select one",
                                              taggable: false,
                                              multiple: false,
                                              "track-by": "_id",
                                              "show-labels": false,
                                              "custom-label": _vm.customLabel,
                                            },
                                            model: {
                                              value: _vm.type,
                                              callback: function ($$v) {
                                                _vm.type = $$v
                                              },
                                              expression: "type",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Standart HPP",
                                            "label-for": "financeHPP",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            { attrs: { prepend: "Rp." } },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "format-number",
                                                    rawName: "v-format-number",
                                                  },
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.finance.standartHPP,
                                                    expression:
                                                      "finance.standartHPP",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  id: "financeHPP",
                                                  required: "",
                                                  placeholder: "0",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.finance.standartHPP,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.finance,
                                                      "standartHPP",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Standar Jasa",
                                            "label-for": "financeJasa",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            { attrs: { prepend: "Rp." } },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "format-number",
                                                    rawName: "v-format-number",
                                                  },
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.finance
                                                        .standartLabour,
                                                    expression:
                                                      "finance.standartLabour",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  id: "financeJasa",
                                                  required: "",
                                                  placeholder: "0",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.finance.standartLabour,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.finance,
                                                      "standartLabour",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Harga Jual (Default Price)",
                                            "label-for": "itemPrice",
                                            "invalid-feedback":
                                              "Harga Item Harus Diisi!",
                                            state: _vm.priceState,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            { attrs: { prepend: "Rp." } },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "format-number",
                                                    rawName: "v-format-number",
                                                  },
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.price,
                                                    expression: "price",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  id: "itemPrice",
                                                  state: _vm.priceState,
                                                  required: "",
                                                  placeholder: "0",
                                                },
                                                domProps: { value: _vm.price },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.price =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-4 mt-2 d-none" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "px-2 d-flex justify-content-between align-items-center",
                                          staticStyle: {
                                            background: "#113448",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-white m-0" },
                                            [_vm._v("Topping/Add-Ons")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-modal",
                                                      rawName:
                                                        "v-b-modal.modal-addTopping",
                                                      modifiers: {
                                                        "modal-addTopping": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "btn p-0",
                                                  attrs: {
                                                    type: "button",
                                                    "data-toggle": "tooltip",
                                                    title:
                                                      "Tambah Topping/Add-Ons",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "bi bi-plus text-white",
                                                    staticStyle: {
                                                      "font-size": "25px",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm.showTopping
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass: "btn p-0",
                                                      attrs: {
                                                        type: "button",
                                                        "data-toggle":
                                                          "tooltip",
                                                        title: "Hide",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showTopping =
                                                            !_vm.showTopping
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi bi-caret-up-square text-white",
                                                        staticStyle: {
                                                          "font-size": "20px",
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _c(
                                                    "button",
                                                    {
                                                      staticClass: "btn p-0",
                                                      attrs: {
                                                        type: "button",
                                                        "data-toggle":
                                                          "tooltip",
                                                        title: "Show",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showTopping =
                                                            !_vm.showTopping
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi bi-caret-down-square text-white",
                                                        staticStyle: {
                                                          "font-size": "20px",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.showTopping
                                        ? _c(
                                            "table",
                                            { staticClass: "table border" },
                                            [
                                              _c("thead", [
                                                _c("tr", [
                                                  _c(
                                                    "th",
                                                    { attrs: { scope: "col" } },
                                                    [_vm._v("Nama")]
                                                  ),
                                                  _c(
                                                    "th",
                                                    { attrs: { scope: "col" } },
                                                    [_vm._v("Harga")]
                                                  ),
                                                  _c(
                                                    "th",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      attrs: { scope: "col" },
                                                    },
                                                    [_vm._v("Opsi")]
                                                  ),
                                                ]),
                                              ]),
                                              _c(
                                                "tbody",
                                                _vm._l(
                                                  _vm.optionTopping,
                                                  function (topping, i) {
                                                    return _c(
                                                      "tr",
                                                      { key: i },
                                                      [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(topping.nam)
                                                          ),
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              topping.price
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn p-0",
                                                                attrs: {
                                                                  type: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.editPrice(
                                                                        topping,
                                                                        i,
                                                                        $event.target
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "bi bi-pencil-square text-warning",
                                                                }),
                                                              ]
                                                            ),
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn p-0",
                                                                attrs: {
                                                                  type: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeData(
                                                                        i
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "bi bi-trash text-danger",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-md-12 mt-2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "px-2 d-flex justify-content-between align-items-center",
                                        staticStyle: { background: "#113448" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-white m-0" },
                                          [_vm._v("Master Harga")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-modal",
                                                    rawName:
                                                      "v-b-modal.modal-addPrice",
                                                    modifiers: {
                                                      "modal-addPrice": true,
                                                    },
                                                  },
                                                ],
                                                staticClass: "btn p-0",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "tooltip",
                                                  title: "Tambah Master Harga",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-plus text-white",
                                                  staticStyle: {
                                                    "font-size": "25px",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm.showPrice
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn p-0",
                                                    attrs: {
                                                      type: "button",
                                                      "data-toggle": "tooltip",
                                                      title: "Hide",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showPrice =
                                                          !_vm.showPrice
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-caret-up-square text-white",
                                                      staticStyle: {
                                                        "font-size": "20px",
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn p-0",
                                                    attrs: {
                                                      type: "button",
                                                      "data-toggle": "tooltip",
                                                      title: "Show",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showPrice =
                                                          !_vm.showPrice
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-caret-down-square text-white",
                                                      staticStyle: {
                                                        "font-size": "20px",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm.showPrice
                                      ? _c(
                                          "table",
                                          { staticClass: "table border" },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("Nama")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("Qty")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("Harga")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "text-center",
                                                    attrs: { scope: "col" },
                                                  },
                                                  [_vm._v("Opsi")]
                                                ),
                                              ]),
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.optionAdd,
                                                function (price, i) {
                                                  return _c(
                                                    "tr",
                                                    { key: i },
                                                    [
                                                      price.name !== "Default"
                                                        ? [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "align-top",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    price
                                                                  ) + " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-center align-top",
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn p-0",
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.editPrice(
                                                                            price,
                                                                            i,
                                                                            $event.target
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "bi bi-pencil-square text-warning",
                                                                    }),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn p-0",
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeData(
                                                                            i,
                                                                            "optionAdd"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "bi bi-trash text-danger",
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c("div", { staticClass: "col-md-12 mt-2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "px-2 d-flex justify-content-between align-items-center bg-primary",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-white m-0" },
                                          [_vm._v("Diskon")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-modal",
                                                    rawName:
                                                      "v-b-modal.modal-addDiskon",
                                                    modifiers: {
                                                      "modal-addDiskon": true,
                                                    },
                                                  },
                                                ],
                                                staticClass: "btn p-0",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "tooltip",
                                                  title: "Tambah Diskon",
                                                  disabled: "",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-plus text-white",
                                                  staticStyle: {
                                                    "font-size": "25px",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm.showDiskon
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn p-0",
                                                    attrs: {
                                                      type: "button",
                                                      "data-toggle": "tooltip",
                                                      title: "Hide",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showDiskon =
                                                          !_vm.showDiskon
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-caret-up-square text-white",
                                                      staticStyle: {
                                                        "font-size": "20px",
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn p-0",
                                                    attrs: {
                                                      type: "button",
                                                      "data-toggle": "tooltip",
                                                      title: "Show",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showDiskon =
                                                          !_vm.showDiskon
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-caret-down-square text-white",
                                                      staticStyle: {
                                                        "font-size": "20px",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm.showDiskon
                                      ? _c(
                                          "table",
                                          { staticClass: "table border" },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("Qty")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("Tipe")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("Nominal")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("Tgl. Mulai")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("Tgl. Selesai")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "text-center",
                                                    attrs: { scope: "col" },
                                                  },
                                                  [_vm._v("Opsi")]
                                                ),
                                              ]),
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.optionDiskon,
                                                function (diskon, i) {
                                                  return _c("tr", { key: i }, [
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(diskon.diskonQty)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          diskon.diskonType
                                                        )
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          diskon.diskonValue
                                                        )
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          diskon.diskonStartDate
                                                        )
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          diskon.diskonEndDate
                                                        )
                                                      ),
                                                    ]),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn p-0",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editPrice(
                                                                  diskon,
                                                                  i,
                                                                  $event.target
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "bi bi-pencil-square text-warning",
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn p-0",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.removeData(
                                                                  i
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "bi bi-trash text-danger",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-12 mt-2 d-none" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "px-2 d-flex justify-content-between align-items-center",
                                          staticStyle: {
                                            background: "#113448",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-white m-0" },
                                            [_vm._v("Pajak")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-modal",
                                                      rawName:
                                                        "v-b-modal.modal-addTax",
                                                      modifiers: {
                                                        "modal-addTax": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "btn p-0",
                                                  attrs: {
                                                    type: "button",
                                                    "data-toggle": "tooltip",
                                                    title: "Tambah Pajak",
                                                    disabled: "",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "bi bi-plus text-white",
                                                    staticStyle: {
                                                      "font-size": "25px",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm.showTax
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass: "btn p-0",
                                                      attrs: {
                                                        type: "button",
                                                        "data-toggle":
                                                          "tooltip",
                                                        title: "Hide",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showTax =
                                                            !_vm.showTax
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi bi-caret-up-square text-white",
                                                        staticStyle: {
                                                          "font-size": "20px",
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _c(
                                                    "button",
                                                    {
                                                      staticClass: "btn p-0",
                                                      attrs: {
                                                        type: "button",
                                                        "data-toggle":
                                                          "tooltip",
                                                        title: "Show",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showTax =
                                                            !_vm.showTax
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi bi-caret-down-square text-white",
                                                        staticStyle: {
                                                          "font-size": "20px",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.showTax
                                        ? _c(
                                            "table",
                                            { staticClass: "table border" },
                                            [
                                              _c("thead", [
                                                _c("tr", [
                                                  _c(
                                                    "th",
                                                    { attrs: { scope: "col" } },
                                                    [_vm._v("Nama")]
                                                  ),
                                                  _c(
                                                    "th",
                                                    { attrs: { scope: "col" } },
                                                    [_vm._v("Jumlah")]
                                                  ),
                                                  _c(
                                                    "th",
                                                    { attrs: { scope: "col" } },
                                                    [_vm._v("Tgl.Mulai")]
                                                  ),
                                                  _c(
                                                    "th",
                                                    { attrs: { scope: "col" } },
                                                    [_vm._v("Tgl. Selesai")]
                                                  ),
                                                  _c(
                                                    "th",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      attrs: { scope: "col" },
                                                    },
                                                    [_vm._v("Opsi")]
                                                  ),
                                                ]),
                                              ]),
                                              _c(
                                                "tbody",
                                                _vm._l(
                                                  _vm.optionTax,
                                                  function (tax, i) {
                                                    return _c(
                                                      "tr",
                                                      { key: i },
                                                      [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(tax.taxName)
                                                          ),
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(tax.taxQty)
                                                          ),
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              tax.taxStartDate
                                                            )
                                                          ),
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              tax.taxEndDate
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn p-0",
                                                                attrs: {
                                                                  type: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.editPrice(
                                                                        tax,
                                                                        i,
                                                                        $event.target
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "bi bi-pencil-square text-warning",
                                                                }),
                                                              ]
                                                            ),
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn p-0",
                                                                attrs: {
                                                                  type: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeData(
                                                                        i
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "bi bi-trash text-danger",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("b-tab", { attrs: { title: "Inventory" } }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "PO receipt",
                                            "label-for": "itemJenis",
                                            "invalid-feedback":
                                              "Jenis Item Harus Diisi!",
                                          },
                                        },
                                        [
                                          _c("multiselect", {
                                            attrs: {
                                              id: "itemJenis",
                                              options: _vm.optionsLocation,
                                              placeholder:
                                                "Lokasi Default PO receipt",
                                              multiple: false,
                                              "show-labels": false,
                                              "custom-label": _vm.labelLocation,
                                            },
                                            model: {
                                              value: _vm.inventory.poReceipt,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.inventory,
                                                  "poReceipt",
                                                  $$v
                                                )
                                              },
                                              expression: "inventory.poReceipt",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "WO receipt",
                                            "label-for": "itemJenis",
                                            "invalid-feedback":
                                              "Jenis Item Harus Diisi!",
                                          },
                                        },
                                        [
                                          _c("multiselect", {
                                            attrs: {
                                              id: "itemJenis",
                                              options: _vm.optionsLocation,
                                              placeholder:
                                                "Lokasi Default WO receipt",
                                              multiple: false,
                                              "show-labels": false,
                                              "custom-label": _vm.labelLocation,
                                            },
                                            model: {
                                              value: _vm.inventory.woReceipt,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.inventory,
                                                  "woReceipt",
                                                  $$v
                                                )
                                              },
                                              expression: "inventory.woReceipt",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "col-md-12 mt-2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "px-2 d-flex justify-content-between align-items-center bg-primary",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-white m-0" },
                                          [_vm._v("Unit Konversi")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-modal",
                                                    rawName:
                                                      "v-b-modal.modal-addConvert",
                                                    modifiers: {
                                                      "modal-addConvert": true,
                                                    },
                                                  },
                                                ],
                                                staticClass: "btn p-0",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "tooltip",
                                                  title: "Tambah Unit Konversi",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-plus text-white",
                                                  staticStyle: {
                                                    "font-size": "25px",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm.showConvert
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn p-0",
                                                    attrs: {
                                                      type: "button",
                                                      "data-toggle": "tooltip",
                                                      title: "Hide",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showConvert =
                                                          !_vm.showConvert
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-caret-up-square text-white",
                                                      staticStyle: {
                                                        "font-size": "20px",
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn p-0",
                                                    attrs: {
                                                      type: "button",
                                                      "data-toggle": "tooltip",
                                                      title: "Show",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showConvert =
                                                          !_vm.showConvert
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-caret-down-square text-white",
                                                      staticStyle: {
                                                        "font-size": "20px",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm.showConvert
                                      ? _c(
                                          "table",
                                          { staticClass: "table border" },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("*Qty. Awal")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("*UM. Awal")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("*Qty. Konversi")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("*UM. Konversi")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "text-center",
                                                    attrs: { scope: "col" },
                                                  },
                                                  [_vm._v("Opsi")]
                                                ),
                                              ]),
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.optionConvert,
                                                function (convert, i) {
                                                  return _c("tr", { key: i }, [
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(convert.qtyA)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(convert.umA)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(convert.qtyB)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(convert.umB)
                                                      ),
                                                    ]),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn p-0",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editConvert(
                                                                  convert,
                                                                  i,
                                                                  $event.target
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "bi bi-pencil-square text-warning",
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn p-0",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.removeData(
                                                                  i,
                                                                  "optionConvert"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "bi bi-trash text-danger",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: _vm.detailProc.id,
                        size: _vm.size,
                        title: _vm.detailProc.title,
                        "no-close-on-esc": "",
                        "no-close-on-backdrop": "",
                        "hide-header-close": "",
                      },
                      on: { hide: _vm.resetAllIndex },
                      scopedSlots: _vm._u([
                        {
                          key: "modal-footer",
                          fn: function () {
                            return [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "secondary" },
                                  on: { click: _vm.resetDetailModal },
                                },
                                [_vm._v("Kembali")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _vm.priceIndex !== ""
                        ? _c(
                            "form",
                            {
                              ref: "form",
                              staticClass: "row",
                              on: {
                                submit: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.handleEditPrice.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h4", { staticClass: "m-0" }, [
                                    _vm._v(" Nama price "),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "toppName",
                                        "invalid-feedback": "Nama Harus Diisi!",
                                        state: _vm.titleState,
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "toppName",
                                          state: _vm.titleState,
                                          required: "",
                                          disabled: "",
                                        },
                                        model: {
                                          value: _vm.title,
                                          callback: function ($$v) {
                                            _vm.title = $$v
                                          },
                                          expression: "title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-md-12 mb-2 d-flex justify-content-between align-items-center",
                                },
                                [_c("h5", [_vm._v("List Harga")])]
                              ),
                              _c("div", { staticClass: "col-md-12" }, [
                                _vm.showPrice
                                  ? _c(
                                      "table",
                                      { staticClass: "table border" },
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Jumlah (Qty)")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Harga")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Start Date")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("End Date")]
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.arrBlank,
                                            function (price, i) {
                                              return _c("tr", { key: i }, [
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c("b-form-input", {
                                                          class:
                                                            price.priceQty ===
                                                            ""
                                                              ? "is-invalid"
                                                              : "",
                                                          attrs: {
                                                            disabled:
                                                              _vm.optionPrice
                                                                .length === 0 &&
                                                              _vm.optionAdd
                                                                .length === 0,
                                                            type: "number",
                                                            id: "priceValue",
                                                            required: "",
                                                            disabled: "",
                                                          },
                                                          model: {
                                                            value:
                                                              price.priceQty,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                price,
                                                                "priceQty",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "price.priceQty",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c(
                                                          "b-input-group",
                                                          {
                                                            attrs: {
                                                              prepend: "Rp.",
                                                            },
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "format-number",
                                                                  rawName:
                                                                    "v-format-number",
                                                                },
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    price.priceValue,
                                                                  expression:
                                                                    "price.priceValue",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              class:
                                                                price.priceValue ===
                                                                ""
                                                                  ? "is-invalid"
                                                                  : "",
                                                              attrs: {
                                                                disabled:
                                                                  _vm
                                                                    .optionPrice
                                                                    .length ===
                                                                    0 &&
                                                                  _vm.optionAdd
                                                                    .length ===
                                                                    0,
                                                                id: "priceValue",
                                                                required: "",
                                                                disabled: "",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  price.priceValue,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      price,
                                                                      "priceValue",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      {
                                                        attrs: {
                                                          "invalid-feedback":
                                                            price.priceStartDate ===
                                                            ""
                                                              ? "Tidak Boleh Kosong!"
                                                              : null,
                                                          state:
                                                            price.priceStartDate ===
                                                            ""
                                                              ? false
                                                              : null,
                                                        },
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.optionPrice
                                                                .length === 0 &&
                                                              _vm.optionAdd
                                                                .length === 0,
                                                            type: "date",
                                                            id: "priceQTY",
                                                            disabled: "",
                                                            required: "",
                                                          },
                                                          model: {
                                                            value:
                                                              price.priceStartDate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                price,
                                                                "priceStartDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "price.priceStartDate",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.optionPrice
                                                                .length === 0 &&
                                                              _vm.optionAdd
                                                                .length === 0,
                                                            disabled: "",
                                                            type: "date",
                                                            id: "priceQTY",
                                                          },
                                                          model: {
                                                            value:
                                                              price.priceEndDate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                price,
                                                                "priceEndDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "price.priceEndDate",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        : _vm.convertIndex !== ""
                        ? _c(
                            "form",
                            {
                              ref: "form",
                              staticClass: "row",
                              on: {
                                submit: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.handleEditConvert.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-6 my-2" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "*Qty. Awal" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "number",
                                          id: "convertQtyA",
                                          required: "",
                                          disabled: "",
                                          state:
                                            _vm.arrBlank.qtyA === null
                                              ? false
                                              : null,
                                        },
                                        model: {
                                          value: _vm.arrBlank.qtyA,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.arrBlank, "qtyA", $$v)
                                          },
                                          expression: "arrBlank.qtyA",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6 my-2" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "*UM. Awal" } },
                                    [
                                      _c("multiselect", {
                                        class:
                                          _vm.arrBlank.qtyA === null
                                            ? "invalid"
                                            : "",
                                        attrs: {
                                          id: "itemUmA",
                                          options: _vm.optionsUm,
                                          placeholder: "Pilih UM",
                                          "show-labels": false,
                                          taggable: true,
                                          multiple: false,
                                          disabled: "",
                                        },
                                        on: { tag: _vm.addConvert1 },
                                        model: {
                                          value: _vm.arrBlank.umA,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.arrBlank, "umA", $$v)
                                          },
                                          expression: "arrBlank.umA",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "*QTY. Akhir" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "number",
                                          id: "convertQtyB",
                                          required: "",
                                          disabled: "",
                                          state:
                                            _vm.arrBlank.qtyB === null
                                              ? false
                                              : null,
                                        },
                                        model: {
                                          value: _vm.arrBlank.qtyB,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.arrBlank, "qtyB", $$v)
                                          },
                                          expression: "arrBlank.qtyB",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "*UM. Akhir" } },
                                    [
                                      _c("multiselect", {
                                        class:
                                          _vm.arrBlank.qtyB === null
                                            ? "invalid"
                                            : "",
                                        attrs: {
                                          id: "itemUmA",
                                          options: _vm.optionsUm,
                                          placeholder: "Pilih UM",
                                          "show-labels": false,
                                          taggable: true,
                                          multiple: false,
                                          disabled: "",
                                        },
                                        on: { tag: _vm.addConvert1 },
                                        model: {
                                          value: _vm.arrBlank.umB,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.arrBlank, "umB", $$v)
                                          },
                                          expression: "arrBlank.umB",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _c("form", [_vm._v(" tesa ")]),
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: _vm.editProc.id,
                        size: _vm.size,
                        title: _vm.editProc.title,
                        "no-close-on-esc": "",
                        "no-close-on-backdrop": "",
                        "hide-header-close": "",
                      },
                      on: { hide: _vm.resetAllIndex },
                      scopedSlots: _vm._u(
                        [
                          _vm.priceIndex !== ""
                            ? {
                                key: "modal-footer",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "secondary" },
                                        on: { click: _vm.resetEditModal },
                                      },
                                      [_vm._v("Kembali")]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          type: "submit",
                                          variant: "success",
                                          disabled: _vm.title === "",
                                        },
                                        on: { click: _vm.submitEditPrice },
                                      },
                                      [_vm._v("Edit")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              }
                            : _vm.convertIndex !== ""
                            ? {
                                key: "modal-footer",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "secondary" },
                                        on: { click: _vm.resetEditModal },
                                      },
                                      [_vm._v("Kembali")]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          type: "submit",
                                          variant: "success",
                                        },
                                        on: { click: _vm.submitEditConvert },
                                      },
                                      [_vm._v("Edit")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              }
                            : {
                                key: "modal-footer",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "secondary" },
                                        on: { click: _vm.resetEditModal },
                                      },
                                      [_vm._v("Kembali")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm.priceIndex !== ""
                        ? _c(
                            "form",
                            {
                              ref: "form",
                              staticClass: "row",
                              on: {
                                submit: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.handleEditPrice.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-4 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c("h4", { staticClass: "m-0" }, [
                                    _vm._v(" Nama price "),
                                  ]),
                                  _c("h3", [_vm._v(":")]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-8 my-1" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "toppName",
                                        "invalid-feedback": "Nama Harus Diisi!",
                                        state: _vm.titleState,
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "toppName",
                                          state: _vm.titleState,
                                          required: "",
                                          disabled:
                                            _vm.optionPrice.length === 0 &&
                                            _vm.optionAdd.length === 0,
                                        },
                                        model: {
                                          value: _vm.title,
                                          callback: function ($$v) {
                                            _vm.title = $$v
                                          },
                                          expression: "title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-md-12 mb-2 d-flex justify-content-between align-items-center",
                                },
                                [
                                  _c("h5", [_vm._v("List Harga")]),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary btn-sm",
                                      class:
                                        _vm.optionPrice.length === 0 &&
                                        _vm.optionAdd.length === 0
                                          ? "d-none"
                                          : "",
                                      attrs: {
                                        type: "button",
                                        disabled: _vm.title == "",
                                      },
                                      on: { click: _vm.addRowPrice },
                                    },
                                    [_vm._v("Tambah Baris")]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "col-md-12" }, [
                                _vm.showPrice
                                  ? _c(
                                      "table",
                                      { staticClass: "table border" },
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Jumlah (Qty)")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Harga")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Start Date")]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("End Date")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                class:
                                                  _vm.optionPrice.length ===
                                                    0 &&
                                                  _vm.optionAdd.length === 0
                                                    ? "d-none"
                                                    : "",
                                              },
                                              [_vm._v("Opsi ")]
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.arrBlank,
                                            function (price, i) {
                                              return _c("tr", { key: i }, [
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c("b-form-input", {
                                                          class:
                                                            price.priceQty ===
                                                            ""
                                                              ? "is-invalid"
                                                              : "",
                                                          attrs: {
                                                            disabled:
                                                              _vm.optionPrice
                                                                .length === 0 &&
                                                              _vm.optionAdd
                                                                .length === 0,
                                                            type: "number",
                                                            id: "priceValue",
                                                            required: "",
                                                          },
                                                          model: {
                                                            value:
                                                              price.priceQty,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                price,
                                                                "priceQty",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "price.priceQty",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c(
                                                          "b-input-group",
                                                          {
                                                            attrs: {
                                                              prepend: "Rp.",
                                                            },
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "format-number",
                                                                  rawName:
                                                                    "v-format-number",
                                                                },
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    price.priceValue,
                                                                  expression:
                                                                    "price.priceValue",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              class:
                                                                price.priceValue ===
                                                                ""
                                                                  ? "is-invalid"
                                                                  : "",
                                                              attrs: {
                                                                disabled:
                                                                  _vm
                                                                    .optionPrice
                                                                    .length ===
                                                                    0 &&
                                                                  _vm.optionAdd
                                                                    .length ===
                                                                    0,
                                                                id: "priceValue",
                                                                required: "",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  price.priceValue,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      price,
                                                                      "priceValue",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      {
                                                        attrs: {
                                                          "invalid-feedback":
                                                            price.priceStartDate ===
                                                            ""
                                                              ? "Tidak Boleh Kosong!"
                                                              : null,
                                                          state:
                                                            price.priceStartDate ===
                                                            ""
                                                              ? false
                                                              : null,
                                                        },
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.optionPrice
                                                                .length === 0 &&
                                                              _vm.optionAdd
                                                                .length === 0,
                                                            type: "date",
                                                            id: "priceQTY",
                                                            required: "",
                                                          },
                                                          model: {
                                                            value:
                                                              price.priceStartDate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                price,
                                                                "priceStartDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "price.priceStartDate",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.optionPrice
                                                                .length === 0 &&
                                                              _vm.optionAdd
                                                                .length === 0,
                                                            type: "date",
                                                            id: "priceQTY",
                                                          },
                                                          model: {
                                                            value:
                                                              price.priceEndDate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                price,
                                                                "priceEndDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "price.priceEndDate",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn p-0 py-2",
                                                        class:
                                                          _vm.optionPrice
                                                            .length === 0 &&
                                                          _vm.optionAdd
                                                            .length === 0
                                                            ? "d-none"
                                                            : "",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeRowPrice(
                                                              i
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-trash text-danger",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        : _vm.convertIndex !== ""
                        ? _c(
                            "form",
                            {
                              ref: "form",
                              staticClass: "row",
                              on: {
                                submit: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.handleEditConvert.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-6 my-2" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "*Qty. Awal" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "number",
                                          id: "convertQtyA",
                                          required: "",
                                          state:
                                            _vm.arrBlank.qtyA === null
                                              ? false
                                              : null,
                                        },
                                        model: {
                                          value: _vm.arrBlank.qtyA,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.arrBlank, "qtyA", $$v)
                                          },
                                          expression: "arrBlank.qtyA",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6 my-2" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "*UM. Awal" } },
                                    [
                                      _c("multiselect", {
                                        class:
                                          _vm.arrBlank.qtyA === null
                                            ? "invalid"
                                            : "",
                                        attrs: {
                                          id: "itemUmA",
                                          options: _vm.optionsUm,
                                          placeholder: "Pilih UM",
                                          "show-labels": false,
                                          taggable: true,
                                          multiple: false,
                                        },
                                        on: { tag: _vm.addConvert1 },
                                        model: {
                                          value: _vm.arrBlank.umA,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.arrBlank, "umA", $$v)
                                          },
                                          expression: "arrBlank.umA",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "*QTY. Akhir" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "number",
                                          id: "convertQtyB",
                                          required: "",
                                          state:
                                            _vm.arrBlank.qtyB === null
                                              ? false
                                              : null,
                                        },
                                        model: {
                                          value: _vm.arrBlank.qtyB,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.arrBlank, "qtyB", $$v)
                                          },
                                          expression: "arrBlank.qtyB",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "*UM. Akhir" } },
                                    [
                                      _c("multiselect", {
                                        class:
                                          _vm.arrBlank.qtyB === null
                                            ? "invalid"
                                            : "",
                                        attrs: {
                                          id: "itemUmA",
                                          options: _vm.optionsUm,
                                          placeholder: "Pilih UM",
                                          "show-labels": false,
                                          taggable: true,
                                          multiple: false,
                                        },
                                        on: { tag: _vm.addConvert1 },
                                        model: {
                                          value: _vm.arrBlank.umB,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.arrBlank, "umB", $$v)
                                          },
                                          expression: "arrBlank.umB",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _c("form", [_vm._v(" tesa ")]),
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      ref: "modal",
                      attrs: {
                        id: "modal-add",
                        size: "lg",
                        title: "Tambah Item",
                        "no-close-on-esc": "",
                        "no-close-on-backdrop": "",
                      },
                      on: {
                        show: _vm.resetModal,
                        hidden: _vm.resetModal,
                        ok: _vm.handleOk,
                      },
                    },
                    [
                      _c(
                        "form",
                        {
                          ref: "form",
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.handleSubmit.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "b-tabs",
                            {
                              staticClass: "custom-tabs",
                              attrs: { "content-class": "mt-3 px-5", card: "" },
                            },
                            [
                              _c(
                                "b-tab",
                                { attrs: { title: "General", active: "" } },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-md-3 d-flex justify-content-center",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "avatar avatar-xxl avatar-circle avatar-uploader profile-cover-avatar",
                                            staticStyle: { margin: "0" },
                                            attrs: {
                                              for: "addImage",
                                              tabindex: "-1",
                                              "invalid-feedback":
                                                "Provinsi Harus Diisi!",
                                              state: _vm.imageState,
                                            },
                                            on: {
                                              change: (event) =>
                                                _vm.previewFiles(event),
                                            },
                                          },
                                          [
                                            _vm.general.image !== ""
                                              ? _c("img", {
                                                  staticClass: "avatar-img",
                                                  attrs: {
                                                    id: "editAvatarImgModal",
                                                    src: _vm.general.image,
                                                    alt: "Image Description",
                                                  },
                                                })
                                              : _c("img", {
                                                  staticClass: "avatar-img",
                                                  attrs: {
                                                    id: "editAvatarImgModal",
                                                    src: "assets/img/160x160/img0.jpg",
                                                    alt: "Image Description",
                                                  },
                                                }),
                                            _c("input", {
                                              staticClass:
                                                "js-file-attach avatar-uploader-input",
                                              attrs: {
                                                tabindex: "-1",
                                                type: "file",
                                                id: "addImage",
                                                state: _vm.imageState,
                                                required: "",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "avatar-uploader-trigger",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi-pencil-fill avatar-uploader-icon shadow-sm",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "row col-md-9" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Kode (Barcode)",
                                                "label-for": "itemCode",
                                                "invalid-feedback":
                                                  "Kode Item Harus Diisi!",
                                                state: _vm.groupState,
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "itemCode",
                                                  state: _vm.groupState,
                                                  required: "",
                                                  placeholder:
                                                    "Kode Item (Barcode)",
                                                },
                                                model: {
                                                  value: _vm.group,
                                                  callback: function ($$v) {
                                                    _vm.group = $$v
                                                  },
                                                  expression: "group",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Nama",
                                                "label-for": "itemName",
                                                "invalid-feedback":
                                                  "Nama Item Harus Diisi!",
                                                state: _vm.NameState,
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "itemName",
                                                  state: _vm.NameState,
                                                  required: "",
                                                  placeholder: "Nama Item",
                                                },
                                                model: {
                                                  value: _vm.name,
                                                  callback: function ($$v) {
                                                    _vm.name = $$v
                                                  },
                                                  expression: "name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Deskripsi (Spec)",
                                                "label-for": "itemDefStatus",
                                                "invalid-feedback":
                                                  "Deskripsi Harus Diisi!",
                                                state: _vm.descState,
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "itemDefStatus",
                                                  state: _vm.descState,
                                                  required: "",
                                                  placeholder: "Deskripsi Item",
                                                },
                                                model: {
                                                  value: _vm.desc,
                                                  callback: function ($$v) {
                                                    _vm.desc = $$v
                                                  },
                                                  expression: "desc",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Merk",
                                                "label-for": "itemAddMerk",
                                                "invalid-feedback":
                                                  "Tipe Item Harus Diisi!",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "itemAddMerk",
                                                  required: "",
                                                  placeholder: "Merk Item",
                                                },
                                                model: {
                                                  value: _vm.general.merk,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.general,
                                                      "merk",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "general.merk",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Tipe",
                                                "label-for": "itemAddType",
                                                "invalid-feedback":
                                                  "Tipe Item Harus Diisi!",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "itemAddType",
                                                  required: "",
                                                  placeholder: "Tipe Item",
                                                },
                                                model: {
                                                  value: _vm.general.type,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.general,
                                                      "type",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "general.type",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Default UM",
                                                "label-for": "itemSatuan",
                                                "invalid-feedback":
                                                  "Anda Harus Memilih Satuan!",
                                                state: _vm.dateState,
                                              },
                                            },
                                            [
                                              _c("multiselect", {
                                                attrs: {
                                                  id: "itemSatuan",
                                                  options: _vm.optionsUm,
                                                  placeholder:
                                                    "Pilih Default UM",
                                                  taggable: true,
                                                  multiple: false,
                                                },
                                                on: { tag: _vm.addConvert2 },
                                                model: {
                                                  value: _vm.finance.itemUm,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.finance,
                                                      "itemUm",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "finance.itemUm",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2 d-none" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "No. Seri",
                                                "label-for": "itemAddNoSeri",
                                                "invalid-feedback":
                                                  "Tipe Item Harus Diisi!",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "itemAddNoSeri",
                                                  required: "",
                                                  placeholder:
                                                    "Nomor Seri Item",
                                                },
                                                model: {
                                                  value: _vm.general.noSeri,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.general,
                                                      "noSeri",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "general.noSeri",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "col-md-12" }, [
                                        _c("hr", { staticClass: "my-2" }),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Kategori",
                                                "label-for": "itemCat",
                                                "invalid-feedback":
                                                  "Kategori Item Harus Diisi!",
                                                state: _vm.katState,
                                              },
                                            },
                                            [
                                              _c("multiselect", {
                                                attrs: {
                                                  id: "itemCat",
                                                  options: _vm.optionsCategory,
                                                  placeholder: "Pilih Kategori",
                                                  taggable: true,
                                                  multiple: false,
                                                  "show-labels": false,
                                                },
                                                on: { tag: _vm.addCategory },
                                                model: {
                                                  value: _vm.kat,
                                                  callback: function ($$v) {
                                                    _vm.kat = $$v
                                                  },
                                                  expression: "kat",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Jenis",
                                                "label-for": "itemJenis",
                                                "invalid-feedback":
                                                  "Jenis Item Harus Diisi!",
                                              },
                                            },
                                            [
                                              _c("multiselect", {
                                                attrs: {
                                                  id: "itemJenis",
                                                  options: _vm.optionsJenis,
                                                  placeholder: "Pilih Jenis",
                                                  multiple: false,
                                                  "show-labels": false,
                                                },
                                                model: {
                                                  value: _vm.general.jenis,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.general,
                                                      "jenis",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "general.jenis",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Grup",
                                                "label-for": "itemJenis",
                                                "invalid-feedback":
                                                  "Jenis Item Harus Diisi!",
                                              },
                                            },
                                            [
                                              _c("multiselect", {
                                                class:
                                                  _vm.general.group === null
                                                    ? "invalid"
                                                    : "",
                                                attrs: {
                                                  options: _vm.optionsGroup,
                                                  multiple: true,
                                                  "clear-on-select": false,
                                                  "preserve-search": true,
                                                  placeholder: "Pilih Grup",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "selection",
                                                    fn: function ({
                                                      values,
                                                      search,
                                                      isOpen,
                                                    }) {
                                                      return [
                                                        values.length
                                                          ? _c(
                                                              "span",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      !isOpen,
                                                                    expression:
                                                                      "!isOpen",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "multiselect__single",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    values.length
                                                                  ) +
                                                                    " data dipilih"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                                model: {
                                                  value: _vm.general.group,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.general,
                                                      "group",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "general.group",
                                                },
                                              }),
                                              _vm.general.group
                                                ? [
                                                    _vm.general.group.length > 0
                                                      ? _c(
                                                          "pre",
                                                          [
                                                            _vm._v(
                                                              "Grup List :\n"
                                                            ),
                                                            _vm._l(
                                                              _vm.general.group,
                                                              function (data) {
                                                                return [
                                                                  _vm._v(
                                                                    "- " +
                                                                      _vm._s(
                                                                        data
                                                                      ) +
                                                                      "\n"
                                                                  ),
                                                                ]
                                                              }
                                                            ),
                                                            _vm._v("\n"),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-6 mb-2" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Bundling",
                                                "label-for": "itemJenis",
                                                "invalid-feedback":
                                                  "Jenis Item Harus Diisi!",
                                              },
                                            },
                                            [
                                              _c("multiselect", {
                                                attrs: {
                                                  options: _vm.usaha,
                                                  label: "itemName",
                                                  multiple: true,
                                                  "clear-on-select": false,
                                                  "preserve-search": true,
                                                  placeholder: "Item Bundling",
                                                  "track-by": "_id",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "selection",
                                                    fn: function ({
                                                      values,
                                                      search,
                                                      isOpen,
                                                    }) {
                                                      return [
                                                        values.length
                                                          ? _c(
                                                              "span",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      !isOpen,
                                                                    expression:
                                                                      "!isOpen",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "multiselect__single",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    values.length
                                                                  ) +
                                                                    " data dipilih"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                                model: {
                                                  value: _vm.general.bundling,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.general,
                                                      "bundling",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "general.bundling",
                                                },
                                              }),
                                              _vm.general.bundling.length > 0
                                                ? _c(
                                                    "pre",
                                                    [
                                                      _vm._v(
                                                        "Bundling List :\n"
                                                      ),
                                                      _vm._l(
                                                        _vm.general.bundling,
                                                        function (data) {
                                                          return [
                                                            _vm._v(
                                                              "- " +
                                                                _vm._s(
                                                                  data.itemName
                                                                ) +
                                                                "\n"
                                                            ),
                                                          ]
                                                        }
                                                      ),
                                                      _vm._v("\n"),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c("b-tab", { attrs: { title: "Finance" } }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Kode Akun",
                                            "label-for": "itemCat",
                                            "invalid-feedback":
                                              "Anda Harus Memilih Kode Akun!",
                                            state: _vm.typeState,
                                          },
                                        },
                                        [
                                          _c("multiselect", {
                                            attrs: {
                                              id: "itemCat",
                                              options: _vm.optionsCoa,
                                              placeholder: "Select one",
                                              taggable: false,
                                              multiple: false,
                                              "track-by": "_id",
                                              "show-labels": false,
                                              "custom-label": _vm.customLabel,
                                            },
                                            model: {
                                              value: _vm.type,
                                              callback: function ($$v) {
                                                _vm.type = $$v
                                              },
                                              expression: "type",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Standart HPP",
                                            "label-for": "financeHPP",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            { attrs: { prepend: "Rp." } },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "format-number",
                                                    rawName: "v-format-number",
                                                  },
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.finance.standartHPP,
                                                    expression:
                                                      "finance.standartHPP",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  id: "financeHPP",
                                                  required: "",
                                                  placeholder: "0",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.finance.standartHPP,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.finance,
                                                      "standartHPP",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Standar Jasa",
                                            "label-for": "financeJasa",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            { attrs: { prepend: "Rp." } },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "format-number",
                                                    rawName: "v-format-number",
                                                  },
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.finance
                                                        .standartLabour,
                                                    expression:
                                                      "finance.standartLabour",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  id: "financeJasa",
                                                  required: "",
                                                  placeholder: "0",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.finance.standartLabour,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.finance,
                                                      "standartLabour",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Harga Jual (Default Price)",
                                            "label-for": "itemPrice",
                                            "invalid-feedback":
                                              "Harga Item Harus Diisi!",
                                            state: _vm.priceState,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            { attrs: { prepend: "Rp." } },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "format-number",
                                                    rawName: "v-format-number",
                                                  },
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.price,
                                                    expression: "price",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  id: "itemPrice",
                                                  state: _vm.priceState,
                                                  required: "",
                                                  placeholder: "0",
                                                },
                                                domProps: { value: _vm.price },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.price =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-4 mt-2 d-none" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "px-2 d-flex justify-content-between align-items-center",
                                          staticStyle: {
                                            background: "#113448",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-white m-0" },
                                            [_vm._v("Topping/Add-Ons")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-modal",
                                                      rawName:
                                                        "v-b-modal.modal-addTopping",
                                                      modifiers: {
                                                        "modal-addTopping": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "btn p-0",
                                                  attrs: {
                                                    type: "button",
                                                    "data-toggle": "tooltip",
                                                    title:
                                                      "Tambah Topping/Add-Ons",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "bi bi-plus text-white",
                                                    staticStyle: {
                                                      "font-size": "25px",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm.showTopping
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass: "btn p-0",
                                                      attrs: {
                                                        type: "button",
                                                        "data-toggle":
                                                          "tooltip",
                                                        title: "Hide",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showTopping =
                                                            !_vm.showTopping
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi bi-caret-up-square text-white",
                                                        staticStyle: {
                                                          "font-size": "20px",
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _c(
                                                    "button",
                                                    {
                                                      staticClass: "btn p-0",
                                                      attrs: {
                                                        type: "button",
                                                        "data-toggle":
                                                          "tooltip",
                                                        title: "Show",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showTopping =
                                                            !_vm.showTopping
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi bi-caret-down-square text-white",
                                                        staticStyle: {
                                                          "font-size": "20px",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.showTopping
                                        ? _c(
                                            "table",
                                            { staticClass: "table border" },
                                            [
                                              _c("thead", [
                                                _c("tr", [
                                                  _c(
                                                    "th",
                                                    { attrs: { scope: "col" } },
                                                    [_vm._v("Nama")]
                                                  ),
                                                  _c(
                                                    "th",
                                                    { attrs: { scope: "col" } },
                                                    [_vm._v("Harga")]
                                                  ),
                                                  _c(
                                                    "th",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      attrs: { scope: "col" },
                                                    },
                                                    [_vm._v("Opsi")]
                                                  ),
                                                ]),
                                              ]),
                                              _c(
                                                "tbody",
                                                _vm._l(
                                                  _vm.optionTopping,
                                                  function (topping, i) {
                                                    return _c(
                                                      "tr",
                                                      { key: i },
                                                      [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(topping.nam)
                                                          ),
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              topping.price
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn p-0",
                                                                attrs: {
                                                                  type: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.editPrice(
                                                                        topping,
                                                                        i,
                                                                        $event.target
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "bi bi-pencil-square text-warning",
                                                                }),
                                                              ]
                                                            ),
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn p-0",
                                                                attrs: {
                                                                  type: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeData(
                                                                        i
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "bi bi-trash text-danger",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-md-12 mt-2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "px-2 d-flex justify-content-between align-items-center",
                                        staticStyle: { background: "#113448" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-white m-0" },
                                          [_vm._v("Master Harga")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-modal",
                                                    rawName:
                                                      "v-b-modal.modal-addPrice",
                                                    modifiers: {
                                                      "modal-addPrice": true,
                                                    },
                                                  },
                                                ],
                                                staticClass: "btn p-0",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "tooltip",
                                                  title: "Tambah Master Harga",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-plus text-white",
                                                  staticStyle: {
                                                    "font-size": "25px",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm.showPrice
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn p-0",
                                                    attrs: {
                                                      type: "button",
                                                      "data-toggle": "tooltip",
                                                      title: "Hide",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showPrice =
                                                          !_vm.showPrice
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-caret-up-square text-white",
                                                      staticStyle: {
                                                        "font-size": "20px",
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn p-0",
                                                    attrs: {
                                                      type: "button",
                                                      "data-toggle": "tooltip",
                                                      title: "Show",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showPrice =
                                                          !_vm.showPrice
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-caret-down-square text-white",
                                                      staticStyle: {
                                                        "font-size": "20px",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm.showPrice
                                      ? _c(
                                          "table",
                                          { staticClass: "table border" },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("Nama")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("Qty")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("Harga")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "text-center",
                                                    attrs: { scope: "col" },
                                                  },
                                                  [_vm._v("Opsi")]
                                                ),
                                              ]),
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.optionAdd,
                                                function (price, i) {
                                                  return _c("tr", { key: i }, [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "align-top",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(price.name)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      _vm._l(
                                                        price.masterPrice,
                                                        function (data) {
                                                          return _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "m-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  data.priceQty
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "td",
                                                      _vm._l(
                                                        price.masterPrice,
                                                        function (data) {
                                                          return _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "m-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  data.priceValue
                                                                ) + " "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-center align-top",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn p-0",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editPrice(
                                                                  price,
                                                                  i,
                                                                  $event.target
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "bi bi-pencil-square text-warning",
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn p-0",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.removeData(
                                                                  i,
                                                                  "optionAdd"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "bi bi-trash text-danger",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c("div", { staticClass: "col-md-12 mt-2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "px-2 d-flex justify-content-between align-items-center bg-primary",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-white m-0" },
                                          [_vm._v("Diskon")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-modal",
                                                    rawName:
                                                      "v-b-modal.modal-addDiskon",
                                                    modifiers: {
                                                      "modal-addDiskon": true,
                                                    },
                                                  },
                                                ],
                                                staticClass: "btn p-0",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "tooltip",
                                                  title: "Tambah Diskon",
                                                  disabled: "",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-plus text-white",
                                                  staticStyle: {
                                                    "font-size": "25px",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm.showDiskon
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn p-0",
                                                    attrs: {
                                                      type: "button",
                                                      "data-toggle": "tooltip",
                                                      title: "Hide",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showDiskon =
                                                          !_vm.showDiskon
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-caret-up-square text-white",
                                                      staticStyle: {
                                                        "font-size": "20px",
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn p-0",
                                                    attrs: {
                                                      type: "button",
                                                      "data-toggle": "tooltip",
                                                      title: "Show",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showDiskon =
                                                          !_vm.showDiskon
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-caret-down-square text-white",
                                                      staticStyle: {
                                                        "font-size": "20px",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm.showDiskon
                                      ? _c(
                                          "table",
                                          { staticClass: "table border" },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("Qty")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("Tipe")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("Nominal")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("Tgl. Mulai")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("Tgl. Selesai")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "text-center",
                                                    attrs: { scope: "col" },
                                                  },
                                                  [_vm._v("Opsi")]
                                                ),
                                              ]),
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.optionDiskon,
                                                function (diskon, i) {
                                                  return _c("tr", { key: i }, [
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(diskon.diskonQty)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          diskon.diskonType
                                                        )
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          diskon.diskonValue
                                                        )
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          diskon.diskonStartDate
                                                        )
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          diskon.diskonEndDate
                                                        )
                                                      ),
                                                    ]),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn p-0",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editPrice(
                                                                  diskon,
                                                                  i,
                                                                  $event.target
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "bi bi-pencil-square text-warning",
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn p-0",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.removeData(
                                                                  i
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "bi bi-trash text-danger",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-12 mt-2 d-none" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "px-2 d-flex justify-content-between align-items-center",
                                          staticStyle: {
                                            background: "#113448",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-white m-0" },
                                            [_vm._v("Pajak")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-modal",
                                                      rawName:
                                                        "v-b-modal.modal-addTax",
                                                      modifiers: {
                                                        "modal-addTax": true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "btn p-0",
                                                  attrs: {
                                                    type: "button",
                                                    "data-toggle": "tooltip",
                                                    title: "Tambah Pajak",
                                                    disabled: "",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "bi bi-plus text-white",
                                                    staticStyle: {
                                                      "font-size": "25px",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm.showTax
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass: "btn p-0",
                                                      attrs: {
                                                        type: "button",
                                                        "data-toggle":
                                                          "tooltip",
                                                        title: "Hide",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showTax =
                                                            !_vm.showTax
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi bi-caret-up-square text-white",
                                                        staticStyle: {
                                                          "font-size": "20px",
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _c(
                                                    "button",
                                                    {
                                                      staticClass: "btn p-0",
                                                      attrs: {
                                                        type: "button",
                                                        "data-toggle":
                                                          "tooltip",
                                                        title: "Show",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showTax =
                                                            !_vm.showTax
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi bi-caret-down-square text-white",
                                                        staticStyle: {
                                                          "font-size": "20px",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.showTax
                                        ? _c(
                                            "table",
                                            { staticClass: "table border" },
                                            [
                                              _c("thead", [
                                                _c("tr", [
                                                  _c(
                                                    "th",
                                                    { attrs: { scope: "col" } },
                                                    [_vm._v("Nama")]
                                                  ),
                                                  _c(
                                                    "th",
                                                    { attrs: { scope: "col" } },
                                                    [_vm._v("Jumlah")]
                                                  ),
                                                  _c(
                                                    "th",
                                                    { attrs: { scope: "col" } },
                                                    [_vm._v("Tgl.Mulai")]
                                                  ),
                                                  _c(
                                                    "th",
                                                    { attrs: { scope: "col" } },
                                                    [_vm._v("Tgl. Selesai")]
                                                  ),
                                                  _c(
                                                    "th",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      attrs: { scope: "col" },
                                                    },
                                                    [_vm._v("Opsi")]
                                                  ),
                                                ]),
                                              ]),
                                              _c(
                                                "tbody",
                                                _vm._l(
                                                  _vm.optionTax,
                                                  function (tax, i) {
                                                    return _c(
                                                      "tr",
                                                      { key: i },
                                                      [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(tax.taxName)
                                                          ),
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(tax.taxQty)
                                                          ),
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              tax.taxStartDate
                                                            )
                                                          ),
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              tax.taxEndDate
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn p-0",
                                                                attrs: {
                                                                  type: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.editPrice(
                                                                        tax,
                                                                        i,
                                                                        $event.target
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "bi bi-pencil-square text-warning",
                                                                }),
                                                              ]
                                                            ),
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn p-0",
                                                                attrs: {
                                                                  type: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeData(
                                                                        i
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "bi bi-trash text-danger",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("b-tab", { attrs: { title: "Inventory" } }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "PO receipt",
                                            "label-for": "itemJenis",
                                            "invalid-feedback":
                                              "Jenis Item Harus Diisi!",
                                          },
                                        },
                                        [
                                          _c("multiselect", {
                                            attrs: {
                                              id: "itemJenis",
                                              options: _vm.optionsLocation,
                                              placeholder:
                                                "Lokasi Default PO receipt",
                                              multiple: false,
                                              "show-labels": false,
                                              "custom-label": _vm.labelLocation,
                                            },
                                            model: {
                                              value: _vm.inventory.poReceipt,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.inventory,
                                                  "poReceipt",
                                                  $$v
                                                )
                                              },
                                              expression: "inventory.poReceipt",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6 mb-2" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "WO receipt",
                                            "label-for": "itemJenis",
                                            "invalid-feedback":
                                              "Jenis Item Harus Diisi!",
                                          },
                                        },
                                        [
                                          _c("multiselect", {
                                            attrs: {
                                              id: "itemJenis",
                                              options: _vm.optionsLocation,
                                              placeholder:
                                                "Lokasi Default WO receipt",
                                              multiple: false,
                                              "show-labels": false,
                                              "custom-label": _vm.labelLocation,
                                            },
                                            model: {
                                              value: _vm.inventory.woReceipt,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.inventory,
                                                  "woReceipt",
                                                  $$v
                                                )
                                              },
                                              expression: "inventory.woReceipt",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "col-md-12 mt-2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "px-2 d-flex justify-content-between align-items-center bg-primary",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-white m-0" },
                                          [_vm._v("Unit Konversi")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-modal",
                                                    rawName:
                                                      "v-b-modal.modal-addConvert",
                                                    modifiers: {
                                                      "modal-addConvert": true,
                                                    },
                                                  },
                                                ],
                                                staticClass: "btn p-0",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "tooltip",
                                                  title: "Tambah Unit Konversi",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-plus text-white",
                                                  staticStyle: {
                                                    "font-size": "25px",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm.showConvert
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn p-0",
                                                    attrs: {
                                                      type: "button",
                                                      "data-toggle": "tooltip",
                                                      title: "Hide",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showConvert =
                                                          !_vm.showConvert
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-caret-up-square text-white",
                                                      staticStyle: {
                                                        "font-size": "20px",
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn p-0",
                                                    attrs: {
                                                      type: "button",
                                                      "data-toggle": "tooltip",
                                                      title: "Show",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showConvert =
                                                          !_vm.showConvert
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-caret-down-square text-white",
                                                      staticStyle: {
                                                        "font-size": "20px",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm.showConvert
                                      ? _c(
                                          "table",
                                          { staticClass: "table border" },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("*Qty. Awal")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("*UM. Awal")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("*Qty. Konversi")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("*UM. Konversi")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "text-center",
                                                    attrs: { scope: "col" },
                                                  },
                                                  [_vm._v("Opsi")]
                                                ),
                                              ]),
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.optionConvert,
                                                function (convert, i) {
                                                  return _c("tr", { key: i }, [
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(convert.qtyA)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(convert.umA)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(convert.qtyB)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(convert.umB)
                                                      ),
                                                    ]),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn p-0",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editConvert(
                                                                  convert,
                                                                  i,
                                                                  $event.target
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "bi bi-pencil-square text-warning",
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn p-0",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.removeData(
                                                                  i,
                                                                  "optionConvert"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "bi bi-trash text-danger",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      ref: "modal",
                      attrs: {
                        id: "modal-addPrice",
                        size: "lg",
                        title: "Tambah Master Harga",
                        "hide-header-close": "",
                        "no-close-on-esc": "",
                        "no-close-on-backdrop": "",
                        "ok-disabled": _vm.arrBlank.length === 0,
                      },
                      on: { hidden: _vm.resetAddModal, ok: _vm.handleOkPrice },
                    },
                    [
                      _c(
                        "form",
                        {
                          ref: "form",
                          staticClass: "row",
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.handleSubmitPrice.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-md-12 mb-2" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Nama Master Harga",
                                    "label-for": "priceName",
                                    "invalid-feedback":
                                      "Nama Master Harga Harus Diisi!",
                                    state: _vm.titleState,
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "priceName",
                                      state: _vm.titleState,
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.title,
                                      callback: function ($$v) {
                                        _vm.title = $$v
                                      },
                                      expression: "title",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-md-12 mb-2 d-flex justify-content-between align-items-center",
                            },
                            [
                              _c("h5", [_vm._v("List Harga")]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-sm",
                                  attrs: {
                                    type: "button",
                                    disabled: _vm.title == "",
                                  },
                                  on: { click: _vm.addRowPrice },
                                },
                                [_vm._v("Tambah Baris")]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "col-md-12" }, [
                            _vm.showPrice
                              ? _c("table", { staticClass: "table border" }, [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("Jumlah (Qty)"),
                                      ]),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("Harga"),
                                      ]),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("Start Date"),
                                      ]),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("End Date"),
                                      ]),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v("Opsi"),
                                      ]),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.arrBlank, function (price, i) {
                                      return _c("tr", { key: i }, [
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "number",
                                                    id: "priceValue",
                                                    required: "",
                                                    state:
                                                      price.priceQty === null
                                                        ? false
                                                        : null,
                                                  },
                                                  model: {
                                                    value: price.priceQty,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        price,
                                                        "priceQty",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "price.priceQty",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "b-input-group",
                                                  { attrs: { prepend: "Rp." } },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "format-number",
                                                          rawName:
                                                            "v-format-number",
                                                        },
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            price.priceValue,
                                                          expression:
                                                            "price.priceValue",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      class:
                                                        price.priceQty === null
                                                          ? "is-invalid"
                                                          : "",
                                                      attrs: {
                                                        id: "priceValue",
                                                        required: "",
                                                      },
                                                      domProps: {
                                                        value: price.priceValue,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            price,
                                                            "priceValue",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  "invalid-feedback":
                                                    price.priceStartDate === ""
                                                      ? "Tidak Boleh Kosong!"
                                                      : null,
                                                  state:
                                                    price.priceStartDate === ""
                                                      ? false
                                                      : null,
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "date",
                                                    id: "priceQTY",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: price.priceStartDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        price,
                                                        "priceStartDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "price.priceStartDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "date",
                                                    id: "priceQTY",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: price.priceEndDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        price,
                                                        "priceEndDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "price.priceEndDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-center" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn p-0 py-2",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeRowPrice(i)
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-trash text-danger",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      ref: "modal",
                      attrs: {
                        id: "modal-addTopping",
                        size: "sm",
                        title: "Tambah Topping/Add-Ons",
                        "hide-header-close": "",
                        "no-close-on-esc": "",
                        "no-close-on-backdrop": "",
                      },
                      on: {
                        hidden: _vm.resetAddTopping,
                        ok: _vm.handleOkTopping,
                      },
                    },
                    [
                      _c(
                        "form",
                        {
                          ref: "form",
                          staticClass: "row",
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.handleSubmitTopping.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-md-12 mb-2" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "*Nama",
                                    "label-for": "toppingName",
                                    "invalid-feedback":
                                      "Nama Topping Harus Diisi!",
                                    state: _vm.modalTopping.titleState,
                                  },
                                },
                                [
                                  _c("multiselect", {
                                    attrs: {
                                      id: "itemJenis",
                                      options: _vm.optionAddOns,
                                      placeholder: "Pilih Nama Topping/Add-Ons",
                                      multiple: false,
                                      "show-labels": false,
                                      label: "itemName",
                                    },
                                    model: {
                                      value: _vm.modalTopping.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.modalTopping, "name", $$v)
                                      },
                                      expression: "modalTopping.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-12 mb-2" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "*Harga",
                                    "label-for": "toppingPrice",
                                    "invalid-feedback": "Harga Harus Diisi!",
                                    state: _vm.modalTopping.priceState,
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    { attrs: { prepend: "Rp." } },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "format-number",
                                            rawName: "v-format-number",
                                          },
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.modalTopping.price,
                                            expression: "modalTopping.price",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          placeholder: "100.000",
                                          id: "toppingPrice",
                                          state: _vm.modalTopping.priceState,
                                          required: "",
                                        },
                                        domProps: {
                                          value: _vm.modalTopping.price,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.modalTopping,
                                              "price",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      ref: "modal",
                      attrs: {
                        id: "modal-addDiskon",
                        size: "lg",
                        title:
                          "Tambah Diskon Untuk Promo per Item (Contoh: Promo Ramadhan)",
                        "hide-header-close": "",
                        "no-close-on-esc": "",
                        "no-close-on-backdrop": "",
                        "ok-disabled": _vm.arrBlank.length === 0,
                      },
                      on: { hidden: _vm.resetAddModal, ok: _vm.handleOkDiskon },
                    },
                    [
                      _c(
                        "form",
                        {
                          ref: "form",
                          staticClass: "row",
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.handleSubmitDiskon.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-md-12 mb-2 d-flex justify-content-between align-items-center",
                            },
                            [
                              _c("h5", [_vm._v("List Diskon")]),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-sm",
                                  attrs: { type: "button" },
                                  on: { click: _vm.addRowDiskon },
                                },
                                [_vm._v(" Tambah Baris Diskon ")]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "col-md-12" }, [
                            _vm.showDiskon
                              ? _c("table", { staticClass: "table border" }, [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("Kode"),
                                      ]),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("Qty Diskon"),
                                      ]),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("Diskon"),
                                      ]),
                                      _vm._v(" "),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("Nominal"),
                                      ]),
                                      _vm._v(" "),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("Start Date"),
                                      ]),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("End Date"),
                                      ]),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v("Opsi"),
                                      ]),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.arrBlank, function (diskon, i) {
                                      return _c("tr", { key: i }, [
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  "invalid-feedback":
                                                    diskon.codeDiscount === ""
                                                      ? "Tidak Boleh Kosong!"
                                                      : null,
                                                  state:
                                                    diskon.codeDiscount === ""
                                                      ? false
                                                      : null,
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  staticClass: "px-2",
                                                  attrs: {
                                                    id: "diskonCode",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: diskon.codeDiscount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        diskon,
                                                        "codeDiscount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "diskon.codeDiscount",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  "invalid-feedback":
                                                    diskon.diskonQty === ""
                                                      ? "Tidak Boleh Kosong!"
                                                      : null,
                                                  state:
                                                    diskon.diskonQty === ""
                                                      ? false
                                                      : null,
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  staticClass: "px-2",
                                                  staticStyle: {
                                                    width: "60px",
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    id: "diskonValue",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: diskon.diskonQty,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        diskon,
                                                        "diskonQty",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "diskon.diskonQty",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  "invalid-feedback":
                                                    diskon.diskonType === ""
                                                      ? "Tidak Boleh Kosong!"
                                                      : null,
                                                  state:
                                                    diskon.diskonType === ""
                                                      ? false
                                                      : null,
                                                },
                                              },
                                              [
                                                _c("multiselect", {
                                                  staticStyle: {
                                                    "max-width": "100px",
                                                  },
                                                  attrs: {
                                                    options:
                                                      _vm.optionsPriceType,
                                                    placeholder: "Pilih",
                                                    "show-labels": false,
                                                  },
                                                  model: {
                                                    value: diskon.diskonType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        diskon,
                                                        "diskonType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "diskon.diskonType",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  "invalid-feedback":
                                                    diskon.diskonValue === ""
                                                      ? "Tidak Boleh Kosong!"
                                                      : null,
                                                  state:
                                                    diskon.diskonValue === ""
                                                      ? false
                                                      : null,
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-input-group",
                                                  { attrs: { prepend: "Rp." } },
                                                  [
                                                    _c("b-form-input", {
                                                      staticClass: "px-2",
                                                      attrs: {
                                                        type: "number",
                                                        id: "diskonValue",
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          diskon.diskonValue,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            diskon,
                                                            "diskonValue",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "diskon.diskonValue",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  "invalid-feedback":
                                                    diskon.diskonStartDate ===
                                                    ""
                                                      ? "Tidak Boleh Kosong!"
                                                      : null,
                                                  state:
                                                    diskon.diskonStartDate ===
                                                    ""
                                                      ? false
                                                      : null,
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  staticClass: "px-2",
                                                  staticStyle: {
                                                    width: "125px",
                                                  },
                                                  attrs: {
                                                    type: "date",
                                                    id: "diskonQTY",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      diskon.diskonStartDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        diskon,
                                                        "diskonStartDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "diskon.diskonStartDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass: "px-2",
                                                  staticStyle: {
                                                    width: "125px",
                                                  },
                                                  attrs: {
                                                    type: "date",
                                                    id: "diskonQTY",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: diskon.diskonEndDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        diskon,
                                                        "diskonEndDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "diskon.diskonEndDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-center" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn p-0 py-2",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeRowDiskon(
                                                      i
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-trash text-danger",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      ref: "modal",
                      attrs: {
                        id: "modal-addTax",
                        size: "lg",
                        title: "Tambah Tipe Pajak",
                        "hide-header-close": "",
                        "no-close-on-esc": "",
                        "no-close-on-backdrop": "",
                        "ok-disabled": _vm.arrBlank.length === 0,
                      },
                      on: { hidden: _vm.resetAddModal, ok: _vm.handleOkTax },
                    },
                    [
                      _c(
                        "form",
                        {
                          ref: "form",
                          staticClass: "row",
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.handleSubmitTax.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-md-12 mb-2 d-flex justify-content-between align-items-center",
                            },
                            [
                              _c("h5", [_vm._v("List Pajak")]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-sm",
                                  attrs: { type: "button" },
                                  on: { click: _vm.addRowTax },
                                },
                                [_vm._v(" Tambah Baris ")]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "col-md-12" }, [
                            _vm.showTax
                              ? _c("table", { staticClass: "table border" }, [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("Nama"),
                                      ]),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("Jumlah"),
                                      ]),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("Start Date"),
                                      ]),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("End Date"),
                                      ]),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v("Opsi"),
                                      ]),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.arrBlank, function (tax, i) {
                                      return _c("tr", { key: i }, [
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  "invalid-feedback":
                                                    tax.taxName === ""
                                                      ? "Tidak Boleh Kosong!"
                                                      : null,
                                                  state:
                                                    tax.taxName === ""
                                                      ? false
                                                      : null,
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "taxName",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: tax.taxName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        tax,
                                                        "taxName",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "tax.taxName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  "invalid-feedback":
                                                    tax.taxValue === ""
                                                      ? "Tidak Boleh Kosong!"
                                                      : null,
                                                  state:
                                                    tax.taxValue === ""
                                                      ? false
                                                      : null,
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-input-group",
                                                  { attrs: { append: "%" } },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "number",
                                                        id: "taxValue",
                                                        required: "",
                                                        min: "0",
                                                        max: "100",
                                                      },
                                                      model: {
                                                        value: tax.taxValue,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            tax,
                                                            "taxValue",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "tax.taxValue",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  "invalid-feedback":
                                                    tax.taxStartDate === ""
                                                      ? "Tidak Boleh Kosong!"
                                                      : null,
                                                  state:
                                                    tax.taxStartDate === ""
                                                      ? false
                                                      : null,
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "date",
                                                    id: "taxQTY",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: tax.taxStartDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        tax,
                                                        "taxStartDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "tax.taxStartDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "date",
                                                    id: "taxQTY",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: tax.taxEndDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        tax,
                                                        "taxEndDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "tax.taxEndDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-center" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn p-0 py-2",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeRowTax(i)
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-trash text-danger",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      ref: "modal",
                      attrs: {
                        id: "modal-addConvert",
                        size: "lg",
                        title: "Tambah Tipe Convert",
                        "hide-header-close": "",
                        "no-close-on-esc": "",
                        "no-close-on-backdrop": "",
                        "ok-disabled": _vm.arrBlank.length === 0,
                      },
                      on: {
                        hidden: _vm.resetAddModal,
                        ok: _vm.handleOkConvert,
                      },
                    },
                    [
                      _c(
                        "form",
                        {
                          ref: "form",
                          staticClass: "row",
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.handleSubmitConvert.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-md-12 mb-2 d-flex justify-content-between align-items-center",
                            },
                            [
                              _c("h5", [_vm._v("List Convert")]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-sm",
                                  attrs: { type: "button" },
                                  on: { click: _vm.addRowConvert },
                                },
                                [_vm._v(" Tambah Baris ")]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "col-md-12" }, [
                            _vm.showConvert
                              ? _c("table", { staticClass: "table border" }, [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("Qty. Awal"),
                                      ]),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("UM. Awal"),
                                      ]),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("Qty. Konversi"),
                                      ]),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v("UM. Konversi"),
                                      ]),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v("Opsi"),
                                      ]),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.arrBlank, function (convert, i) {
                                      return _c("tr", { key: i }, [
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "number",
                                                    id: "convertQtyA",
                                                    required: "",
                                                    state:
                                                      convert.qtyA === null
                                                        ? false
                                                        : null,
                                                  },
                                                  model: {
                                                    value: convert.qtyA,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        convert,
                                                        "qtyA",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "convert.qtyA",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("multiselect", {
                                                  class:
                                                    convert.qtyA === null
                                                      ? "invalid"
                                                      : "",
                                                  attrs: {
                                                    id: "itemUmA",
                                                    options: _vm.optionsUm,
                                                    placeholder: "Pilih UM",
                                                    "show-labels": false,
                                                    taggable: true,
                                                    multiple: false,
                                                  },
                                                  on: { tag: _vm.addConvert1 },
                                                  model: {
                                                    value: convert.umA,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        convert,
                                                        "umA",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "convert.umA",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "number",
                                                    id: "convertQtyB",
                                                    required: "",
                                                    state:
                                                      convert.qtyB === null
                                                        ? false
                                                        : null,
                                                  },
                                                  model: {
                                                    value: convert.qtyB,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        convert,
                                                        "qtyB",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "convert.qtyB",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("multiselect", {
                                                  class:
                                                    convert.qtyB === null
                                                      ? "invalid"
                                                      : "",
                                                  attrs: {
                                                    id: "itemUmA",
                                                    options: _vm.optionsUm,
                                                    placeholder: "Pilih UM",
                                                    "show-labels": false,
                                                    taggable: true,
                                                    multiple: false,
                                                  },
                                                  on: { tag: _vm.addConvert1 },
                                                  model: {
                                                    value: convert.umB,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        convert,
                                                        "umB",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "convert.umB",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-center" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn p-0 py-2",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeRowConvert(
                                                      i
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-trash text-danger",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      ref: "modal",
                      attrs: {
                        id: "modal-addRecipe",
                        size: "lg",
                        title: "Tambah Tipe Resep",
                        "hide-header-close": "",
                        "no-close-on-esc": "",
                        "no-close-on-backdrop": "",
                        "ok-disabled": _vm.arrBlank.length === 0,
                      },
                      on: {
                        hidden: _vm.resetAddRecipe,
                        ok: _vm.handleOkRecipe,
                      },
                    },
                    [
                      _c(
                        "form",
                        {
                          ref: "form",
                          staticClass: "row",
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.handleSubmitRecipe.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-md-6 mb-2" },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "*Kode Proses" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "recipeCode",
                                      required: "",
                                      placeholder: "Masukan Kode Proses",
                                      state:
                                        _vm.modalRecipe.processCode === null
                                          ? false
                                          : null,
                                    },
                                    model: {
                                      value: _vm.modalRecipe.processCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modalRecipe,
                                          "processCode",
                                          $$v
                                        )
                                      },
                                      expression: "modalRecipe.processCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6 mb-2" },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "*Nama Proses" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "recipeName",
                                      required: "",
                                      placeholder: "Masukan Nama Proses",
                                      state:
                                        _vm.modalRecipe.processName === null
                                          ? false
                                          : null,
                                    },
                                    model: {
                                      value: _vm.modalRecipe.processName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modalRecipe,
                                          "processName",
                                          $$v
                                        )
                                      },
                                      expression: "modalRecipe.processName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-4 mb-2" },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "*Qty Resep" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "number",
                                      id: "recipeQty",
                                      required: "",
                                      placeholder:
                                        "Kuantitas yg dihasilkan resep",
                                      state:
                                        _vm.modalRecipe.recipeQty === null
                                          ? false
                                          : null,
                                    },
                                    model: {
                                      value: _vm.modalRecipe.recipeQty,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modalRecipe,
                                          "recipeQty",
                                          $$v
                                        )
                                      },
                                      expression: "modalRecipe.recipeQty",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-4 mb-2" },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "*UM Resep" } },
                                [
                                  _c("multiselect", {
                                    class:
                                      _vm.modalRecipe.recipeUM === null
                                        ? "invalid"
                                        : "",
                                    attrs: {
                                      id: "itemUm",
                                      options: _vm.optionsUm,
                                      placeholder: "Pilih UM",
                                      "show-labels": true,
                                      taggable: true,
                                      multiple: false,
                                    },
                                    on: { tag: _vm.addConvert3 },
                                    model: {
                                      value: _vm.modalRecipe.recipeUM,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modalRecipe,
                                          "recipeUM",
                                          $$v
                                        )
                                      },
                                      expression: "modalRecipe.recipeUM",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-4 mb-2" },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "*Hasil Resep" } },
                                [
                                  _c("multiselect", {
                                    class:
                                      _vm.modalRecipe.recipeItemResult === null
                                        ? "invalid"
                                        : "",
                                    attrs: {
                                      id: "itemUm",
                                      options: _vm.optionsHasil,
                                      placeholder: "Hasil yg dihasilkan",
                                      "show-labels": false,
                                      "custom-label": _vm.customLabel1,
                                      multiple: false,
                                    },
                                    model: {
                                      value: _vm.modalRecipe.recipeItemResult,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modalRecipe,
                                          "recipeItemResult",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "modalRecipe.recipeItemResult",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-md-12 mb-2 d-flex justify-content-between align-items-center",
                            },
                            [
                              _c("h5", [_vm._v("List Material Resep")]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-sm",
                                  attrs: { type: "button" },
                                  on: { click: _vm.addRowRecipe },
                                },
                                [_vm._v(" Tambah Material ")]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "col-md-12" }, [
                            _vm.showRecipe
                              ? _c("table", { staticClass: "table border" }, [
                                  _c("thead", [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { scope: "col" },
                                        },
                                        [_vm._v("*Nama Item")]
                                      ),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v("&"),
                                      ]),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { scope: "col" },
                                        },
                                        [_vm._v("Kode Item")]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { scope: "col" },
                                        },
                                        [_vm._v("Deskripsi")]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { scope: "col" },
                                        },
                                        [_vm._v("*UM")]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { scope: "col" },
                                        },
                                        [_vm._v("*Qty")]
                                      ),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v("Opsi"),
                                      ]),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.arrBlank, function (recipe, i) {
                                      return _c("tr", { key: i }, [
                                        _c(
                                          "td",
                                          { attrs: { colspan: "3" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("multiselect", {
                                                  class:
                                                    recipe.itemCode === null
                                                      ? "invalid"
                                                      : "",
                                                  attrs: {
                                                    id: "recipeCode",
                                                    options:
                                                      _vm.optionsMaterial,
                                                    placeholder: "Select one",
                                                    taggable: false,
                                                    multiple: false,
                                                    "show-labels": false,
                                                    "custom-label":
                                                      _vm.customLabel1,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.changeUM(
                                                        recipe,
                                                        i
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: recipe.itemCode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        recipe,
                                                        "itemCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "recipe.itemCode",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticStyle: { width: "175px" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass: "px-2",
                                                  attrs: {
                                                    id: "itemDescription",
                                                    required: "",
                                                    state:
                                                      recipe.itemDescription ===
                                                      null
                                                        ? false
                                                        : null,
                                                  },
                                                  model: {
                                                    value:
                                                      recipe.itemDescription,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        recipe,
                                                        "itemDescription",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "recipe.itemDescription",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("multiselect", {
                                                  class:
                                                    recipe.itemUM === null
                                                      ? "invalid"
                                                      : "",
                                                  attrs: {
                                                    id: "itemUmA",
                                                    options: _vm.optionsUm,
                                                    placeholder: "Pilih",
                                                    "show-labels": false,
                                                    taggable: true,
                                                    multiple: false,
                                                  },
                                                  on: {
                                                    tag: function ($event) {
                                                      return _vm.addConvert4(
                                                        i,
                                                        $event
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: recipe.itemUM,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        recipe,
                                                        "itemUM",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "recipe.itemUM",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticStyle: { width: "100px" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "number",
                                                    id: "qty",
                                                    required: "",
                                                    state:
                                                      recipe.qty === null
                                                        ? false
                                                        : null,
                                                  },
                                                  model: {
                                                    value: recipe.qty,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        recipe,
                                                        "qty",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "recipe.qty",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-center" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn p-0 py-2",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeRowRecipe(
                                                      i
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-trash text-danger",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-row",
                    { staticClass: "my-4" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "my-1", attrs: { sm: "5", md: "6" } },
                        [
                          _c("span", { staticClass: "px-2" }, [_vm._v("Show")]),
                          _c("b-form-select", {
                            attrs: {
                              id: "per-page-select",
                              options: _vm.pageOptions,
                              size: "sm",
                            },
                            model: {
                              value: _vm.perPage,
                              callback: function ($$v) {
                                _vm.perPage = $$v
                              },
                              expression: "perPage",
                            },
                          }),
                          _vm._v(
                            " Data of " + _vm._s(_vm.totalRows) + " Data "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "my-1", attrs: { sm: "7", md: "6" } },
                        [
                          _c("b-pagination", {
                            staticClass: "my-0",
                            attrs: {
                              "total-rows": _vm.totalRows,
                              "per-page": _vm.perPage,
                              align: "fill",
                              size: "sm",
                            },
                            model: {
                              value: _vm.currentPage,
                              callback: function ($$v) {
                                _vm.currentPage = $$v
                              },
                              expression: "currentPage",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _c("div", { key: "other" }, [_c("connection-timeout")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }