<template>
  <main id="content" role="main" class="main">
    <form
      class="content container-fluid p-3 ps-0 py-0"
      ref="contentDiv"
      @submit="sendData"
    >
      <!-- Header -->
      <div class="row my-1">
        <div class="col-4 d-flex align-items-center">
          <ul class="breadcrumb m-0">
            <li>
              <router-link to="/dashboard">
                <a href="#"><i class="bi-house-door"></i></a>
              </router-link>
            </li>
            <li>
              <router-link :to="$route.path.replace('/add', '')">
                <a href="#">Kasbon</a>
              </router-link>
            </li>
            <li>Tambah</li>
          </ul>
        </div>
        <div class="col-4 text-center"><span class="h2">Tambah Kasbon</span></div>
        <div class="col-4 d-flex justify-content-end align-items-center">
          <router-link :to="$route.path.replace('/add', '')">
            <button class="btn btn-outline-secondary btn-xs py-1 me-2" type="button">
              <i class="bi-chevron-left"></i>Kembali
            </button>
          </router-link>
          <button type="submit" class="btn btn-primary btn-xs py-1">Simpan</button>
        </div>
      </div>

      <!-- End of Header -->

      <!-- Main Data -->
      <div class="main-data mt-4">
        <div class="row scale-9 position-relative" style="width: 114%; z-index: 1">
          <!-- First Header -->
          <div class="col-6">
            <div class="card h-100">
              <div class="row px-3 py-1">
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between"
                    >*Tipe <span>:</span></label
                  >
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-select
                      v-model="transType"
                      :options="filteredType"
                      size="sm"
                      class="form-control"
                    ></b-form-select>
                  </div>
                </div>
              </div>
              <div class="row px-3 py-1">
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between"
                    >*Kode Kasbank <span>:</span></label
                  >
                  <div class="col-sm-8 col-md-9 pe-0">
                    <multiselect
                      @input="changeCondition(transKasbank)"
                      v-model="transKasbank"
                      :options="optionsKasbank"
                      placeholder="Select one"
                      :multiple="false"
                      :show-labels="false"
                      :custom-label="customLabel"
                    >
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="row px-3 py-1">
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between"
                    >*Trans. Date <span>:</span></label
                  >
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input
                      @input="inOut(micro.value)"
                      v-model="transEffDate"
                      size="sm"
                      type="date"
                      class="form-control-sm py-0 form-control"
                      required
                      placeholder="Masukan Tanggal Transaksi"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End of First Header -->

          <!-- Secondx Header -->
          <div class="col-6">
            <div class="card h-100">
              <div class="row px-3 py-1">
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between"
                    >*PIC <span>:</span></label
                  >
                  <div class="col-sm-8 col-md-9 pe-0">
                    <multiselect
                      v-model="transPIC"
                      :options="optionsUser"
                      placeholder="Diberikan oleh/kepada"
                      :multiple="false"
                      :show-labels="false"
                      :custom-label="customLabel1"
                    >
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="row px-3 py-1">
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between"
                    >*Kode Voucher <span>:</span></label
                  >
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input
                      v-model="voucherCode"
                      size="sm"
                      disabled
                      class="form-control-sm py-0 form-control"
                      required
                      placeholder="Kode Voucher Kasbon"
                    ></b-form-input>
                  </div>
                </div>
              </div>
              <div class="row px-3 py-1">
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between"
                    >*Saldo Saat Ini <span>:</span></label
                  >
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input
                      size="sm"
                      :value="formatNumberInt(currentSaldo)"
                      disabled
                      class="form-control-sm py-0 form-control"
                      placeholder="Sisa Saldo Saat ini"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End of Secondx Header -->
        </div>

        <div class="row my-3">
          <!-- Hot Table -->
          <div class="col-12">
            <div
              class="hot-container col-md-12 position-relative"
              style="overflow-x: auto; z-index: 0"
            >
              <hot-table
                ref="hot"
                :settings="hotSettings"
                :data="excelData"
                :rowHeaders="true"
              ></hot-table>
            </div>
          </div>
          <!-- End of Hot Table -->
        </div>

        <!-- Footer -->
        <div class="row mb-3 d-flex justify-content-between">
          <div class="col-md-6 scale-9">
            <div class="h-100">
              <div class="card h-100">
                <div class="card-header bg-light py-1">Catatan</div>
                <textarea
                  class="form-control form-control-sm py-1 h-100"
                  style="resize: none"
                  placeholder="Masukan Catatan"
                  v-model="transNotes"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="col-md-4 row p-0 scale-9" style="transform-origin: right top">
            <div class="row d-flex justify-content-end">
              <div
                class="border border-light bg-light d-flex align-items-center justify-content-between col-5"
              >
                Total Amount <span>:</span>
              </div>
              <div class="p-0 border border-light bg-light pt-1 col-7 pe-2">
                <input
                  type="text"
                  :value="formatNumberInt(totalAmount)"
                  class="form-control form-control-sm py-1"
                  style="text-align: right"
                  disabled
                />
              </div>
              <div
                class="border border-light bg-light d-flex align-items-center justify-content-between col-5"
              >
                Saldo Akhir
                <span>:</span>
              </div>
              <div class="p-0 border border-light bg-light pt-1 col-7 pe-2">
                <input
                  @input="refreshMethods()"
                  type="text"
                  :value="formatNumberInt(totalAkhir)"
                  class="form-control form-control-sm py-1"
                  style="text-align: right"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <!-- End of Footer -->
      </div>
      <!-- End of Main Data -->
    </form>
  </main>
</template>

<script>
import {
  getCoa,
  getF,
  getB,
  getUser,
  addKasbank,
  getSaldoKasbank,
} from "../../components/services/components";
import { getFullDate, monthOnly, yearOnly } from "../../components/function/date";
import { formatNumberInt } from "../../components/function/number";
import {
  alertLoading,
  successAddData,
  errorAddData,
} from "../../components/function/sweetalert";
export default {
  data() {
    return {
      // Main Data
      transNo: "",
      transEffDate: "",
      transNotes: "",
      transType: "",
      subTotal: "",
      transKasbank: "",
      transPIC: "",

      currentSaldo: "",

      // HOT Data
      contentDivWidth: "",
      colHeader: [
        "*Kode COA",
        "*Nama COA",
        "Cost Center",
        "No. Reff",
        "*Uraian",
        "*Nominal",
      ],
      colBody: [
        {
          data: "transCoaCode",
          type: "autocomplete",
          width: 100,
        },
        {
          data: "transCoaName",
          type: "autocomplete",
        },
        {
          data: "transCostCenter",
          type: "text",
        },
        {
          data: "transReff",
          type: "text",
          width: 100,
        },
        {
          data: "transDescription",
          type: "text",
        },
        {
          data: "transAmount",
          type: "numeric",
          validator: this.validateNumericValue,
          numericFormat: {
            pattern: "0,0",
          },
          width: 125,
        },
      ],
      excelData: [],
      hotSettings: {
        fixedrowsbottom: 0,
        afterChange: this.onChange,
        beforeKeyDown: this.onKeyDown1,
        licenseKey: "non-commercial-and-evaluation",
        cells: function (row, col, prop) {
          const cellProperties = {};

          if (row === 999) {
            cellProperties.readOnly = true; // Make the merged cells read-only
          }
        },
        height: 250,
        colHeaders: [],

        rowHeaderWidth: 35,
        startRows: 10,
        columns: [],
      },

      // OPTIONS
      optionsType: [
        { value: "", text: "Pilih Tipe" },
        { value: "pemasukan", text: "Pemasukan" },
        { value: "pengeluaran", text: "Pengeluaran" },
      ],
      optionsKasbank: [],
      optionsUser: [],

      // OTHER
      voucher: {},
      dataList: [],
    };
  },
  async mounted() {
    this.coa = await getCoa();
    this.voucher = await getB();
    this.transEffDate = await getFullDate();
    const tes = await getF(monthOnly(), yearOnly());
    this.optionsUser = await getUser();

    // this.dataList = tes.saldoAwal;
    this.dataList = await getSaldoKasbank(getFullDate());
    console.log("coba gan", this.dataList);
    this.optionsKasbank = this.dataList;
    this.hotSettings.colHeaders = this.colHeader;
    this.hotSettings.columns = this.colBody;
    this.hotSettings.columns.find(
      (x) => x.data === "transCoaCode"
    ).source = this.coa.coaNonBank.map((x) => x.coaCode);
    this.hotSettings.columns.find(
      (x) => x.data === "transCoaName"
    ).source = this.coa.coaNonBank.map((x) => x.coaName);
    const dataObject = {};
    this.colBody.forEach((item) => {
      dataObject[item.data] = "";
    });
    console.log(dataObject);
    for (let i = 0; i < 1000; i++) {
      const key = `row_${i}`;
      const objectAdd = { ...dataObject, key };
      this.excelData.push(objectAdd);
    }
    this.getContentWidth();
  },
  computed: {
    voucherCode() {
      if (this.transKasbank) {
        if (
          this.transType === "pemasukan" &&
          this.transKasbank.coaCode.includes("1.1.1.1.")
        ) {
          return this.voucher[0].cashIn;
        } else if (
          this.transType === "pengeluaran" &&
          this.transKasbank.coaCode.includes("1.1.1.1.")
        ) {
          return this.voucher[0].cashOut;
        } else if (
          this.transType === "pemasukan" &&
          this.transKasbank.coaCode.includes("1.1.1.2.")
        ) {
          return this.voucher[0].bankIn;
        } else if (
          this.transType === "pengeluaran" &&
          this.transKasbank.coaCode.includes("1.1.1.2.")
        ) {
          return this.voucher[0].bankOut;
        } else {
          return "";
        }
      }
    },
    filteredType() {
      return this.transType === "" ? this.optionsType : this.optionsType.slice(1);
    },
    totalAmount() {
      const tes = this.excelData
        .filter((x) => x.transAmount !== "")
        .map((x) => x.transAmount);
      if (tes.length > 0) {
        const sum = tes.reduce((accumulator, currentValue) => {
          // Convert the current value to a number using parseFloat
          const numericValue = parseFloat(currentValue);

          // Check if the conversion is successful (not NaN) before adding to the accumulator
          if (!isNaN(numericValue)) {
            return accumulator + numericValue;
          } else {
            return accumulator; // Ignore non-numeric values
          }
        }, 0); // Start with an initial accumulator value of 0
        return sum;
      } else {
        return 0;
      }
    },
    totalAkhir() {
      if (this.transType === "pemasukan") {
        return this.currentSaldo + this.totalAmount;
      } else if (this.transType === "pengeluaran") {
        return this.currentSaldo - this.totalAmount;
      } else {
        return this.currentSaldo;
      }
    },
  },
  methods: {
    findVoucher() {
      if (this.kasbank.coaCode && this.transType !== "") {
        // alert('ada datanya', this.kasbank)
        if (this.transType === "pemasukan" && this.kasbank.coaCode.includes("1.1.1.1.")) {
          this.transNo = this.voucher[0].cashIn;
        } else if (
          this.transType === "pengeluaran" &&
          this.kasbank.coaCode.includes("1.1.1.1.")
        ) {
          this.transNo = this.voucher[0].cashOut;
        } else if (
          this.transType === "pemasukan" &&
          this.kasbank.coaCode.includes("1.1.1.2.")
        ) {
          this.transNo = this.voucher[0].bankIn;
        } else if (
          this.transType === "pengeluaran" &&
          this.kasbank.coaCode.includes("1.1.1.2.")
        ) {
          this.transNo = this.voucher[0].bankOut;
        } else {
          transNo = "";
        }

        console.log("inOut Change", this.transNo, this.kasbank.coaCode);
        this.changeVoucher();
      }
    },
    changeVoucher() {
      this.voucherCode = this.transNo;
      // this.component
      //   .filter((x) => x.componentName === "fieldHeader")
      //   .forEach((y) => {
      //     y.componentMicro.find((z) => {
      //       if (z.key === "transNo") {
      //         z.value = this.transNo;
      //       }
      //     });
      //   });
    },
    changeCondition(data) {
      console.log("ini data", data);
      // const tes = this.dataList.find(x => x._id === this.transKasbank._id)
      // if (tes) {
      //   this.currentSaldo = tes.FaPriceBegining
      // } else {
      //   this.currentSaldo = ''
      // }

      if (data !== undefined) {
        const tes = this.dataList.find((x) => x._id === this.transKasbank._id);
        if (tes) {
          this.currentSaldo = tes.FaPriceBegining;
        } else {
          this.currentSaldo = "";
        }
      }
      this.findVoucher();
    },
    customLabel(option) {
      return `${option.coaCode} (${option.coaName})`;
    },
    customLabel1(option) {
      return `${option.userName} (${option.userType})`;
    },
    formatNumberInt(data) {
      return formatNumberInt(data);
    },
    validateEmptyValue(value, callback) {
      if (isNaN(value)) {
        callback(false);
      } else {
        callback(true);
      }
    },
    validateNumericValue(value, callback) {
      if (isNaN(value)) {
        callback(false);
      } else if (value < 0) {
        callback(false);
      } else {
        callback(true);
      }
    },
    onKeyDown1(event) {
      const hotInstance = this.$refs.hot.hotInstance;
      const selected = hotInstance.getSelected();
      const [startRow, startCol, endRow, endCol] = selected;

      if (
        event.key === "Tab" &&
        startRow[1] >= 4 &&
        this.excelData[startRow[0]].transDescription === ""
      ) {
        // alert('Uraian tidak Boleh Kosong!')
        hotInstance.selectCell(startRow[0], startRow[1] - 1);
      }
    },
    onChange(changes, source) {
      if (source === "edit") {
        this.formatTable(changes);
      } else if (source === "CopyPaste.paste") {
        this.formatTable(changes);
      }
    },
    formatTable(changes) {
      for (const change of changes) {
        const [row, prop, oldValue, newValue] = change;
        const baris = this.excelData[row];

        if (prop === "transCoaCode" && baris) {
          const findName = this.coa.coaNonBank.find(
            (x) => x.coaCode === baris.transCoaCode
          );
          if (baris.transCoaCode !== null && findName !== undefined) {
            baris.transCoaName = findName.coaName;
            if (baris.transCoaName === undefined) {
              baris.transCoaName = null;
              baris.transCoaCode = null;
            }
          } else {
            baris.transCoaName = null;
            baris.transCoaCode = null;
          }
          this.excelData = [...this.excelData];
        } else if (prop === "transCoaName" && baris) {
          const findCode = this.coa.coaNonBank.find(
            (x) => x.coaName === baris.transCoaName
          );
          if (baris.transCoaName !== null && findCode !== undefined) {
            baris.transCoaCode = findCode.coaCode;
          } else {
            baris.transCoaName = null;
            baris.transCoaCode = null;
          }
          this.excelData = [...this.excelData];
        } else {
          console.log("gagal");
        }
      }
    },
    sendData(event) {
      event.preventDefault();
      const finalTable = this.excelData.filter((item) => {
        return this.colBody.some((obj) => {
          const value = item[obj.data];
          return value !== "" && value !== undefined && value !== null;
        });
      });
      const tableValidator = finalTable.every((obj) => {
        return Object.entries(obj).every(([key, value]) => {
          if (key === "itemTopping" || key === "itemPajak" || key === "itemImage") {
            return true;
          } else if (typeof value === "array" && value.length === 0) {
            console.log(value);
            return false;
          } else if (value === "" || value === null) {
            console.log(value);
            return false;
          }
          return true;
        });
      });
      const data = finalTable.map((x) => {
        const data = {
          ...x,
        };
        data.transUserTo = this.transPIC._id;
        data.coaCode = this.transKasbank.coaCode;
        data.coaName = this.transKasbank.coaName;
        data.transAmount = x.transAmount.toString();
        data.transEffDate = this.transEffDate;
        data.transName = this.transPIC._id;
        data.transNo = this.voucherCode;
        data.transNotes = this.transNotes;
        data.transType = this.transType === "pemasukan" ? "debet" : "kredit";
        data.transUser = localStorage.getItem("_id");

        return data;
      });
      console.log(data);
      if (
        this.transKasbank &&
        this.transEffDate &&
        this.transType &&
        this.transPIC &&
        this.voucherCode &&
        tableValidator &&
        finalTable.length > 0
      ) {
        this.$swal({
          title: "Anda Yakin?",
          text: "Tekan Simpan jika anda sudah yakin!",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Simpan",
        }).then(async (result) => {
          if (result.isConfirmed) {
            alertLoading();
            try {
              // const data = this.excelData
              //   .filter((item) => {
              //     const coaCodeIsEmpty = item.transCoaCode === "";
              //     const coaNameIsEmpty = item.transCoaName === "";
              //     const kreditIsEmpty = item.transAmount === "";
              //     const debetIsEmpty = item.Debet === "";
              //     const transDescriptionIsEmpty = item.transDescription === "";

              //     // Filter out objects with both transCoaCode and transCoaName empty
              //     if (coaCodeIsEmpty && coaNameIsEmpty) {
              //       return false;
              //     }

              //     // Filter out objects with both transAmount and Debet empty
              //     if (kreditIsEmpty && debetIsEmpty) {
              //       return false;
              //     }

              //     return true;
              //   })
              //   .map((x) => {
              //     const data = {
              //       ...x,
              //       transNo: this.transNo,
              //       transEffDate: this.transEffDate,
              //       transNotes: this.transNotes,
              //       transName: localStorage.getItem("_id"),
              //       transType: x.Debet > 0 ? "debet" : "kredit",
              //       transAmount: x.Debet > 0 ? x.Debet : x.transAmount,
              //     };
              //     return data;
              //   });
              console.log(data);
              await addKasbank(
                data,
                this.transKasbank._id,
                this.transKasbank.coaCode,
                this.transKasbank.coaName
              );
              successAddData();
              this.$router.push({ name: this.$route.name.replace("-add", "") });
            } catch (error) {
              errorAddData();
            }
          }
        });
      } else {
        alert("ada data wajib yang masih kosong!");
      }
    },
    getContentWidth() {
      const contentDiv = this.$refs.contentDiv;
      let totalWidth = 0;
      const tes = this.hotSettings.columns.filter((x) => !x.width).length;
      const widthTes = this.hotSettings.columns.filter((x) => x.width);
      for (const column of widthTes) {
        if (column.width) {
          totalWidth += column.width;
        }
      }
      if (contentDiv) {
        this.hotSettings.colWidths = (contentDiv.offsetWidth - 70 - totalWidth) / tes;
      }
    },
  },
};
</script>
