<template>
  <div class="report" style="font-size: 10px">
    <template v-if="realData.length > 0">
      <table
        class="z-1 position-relative table table-bordered table-hover"
        v-for="entry in realData"
      >
        <thead class="bg-light">
          <tr>
            <th scope="col" width="100">
              <b>{{ entry.coaCode }}</b>
            </th>
            <th scope="col" colspan="4">
              <b>{{ entry.coaName }}</b>
            </th>
            <th scope="col" class="text-end" width="140">
              <b>SALDO AWAL :</b>
            </th>
            <th scope="col" width="150">
              <b
                >Rp. {{ formatNumber(entry.coaFaAmount.FaPriceBegining) }}
                <!-- {{ sumTransSaldoAwal(entry.coaTransSaldo) }} -->
              </b>
            </th>
          </tr>
          <tr v-if="entry.coaTrans.length > 0">
            <th scope="col" width="100" class="text-center">
              <b>TANGGAL</b>
            </th>
            <th scope="col" width="110" class="text-center">
              <b>Nomer Dokumen</b>
            </th>
            <th scope="col" class="text-center" width="80">
              <b>CODE</b>
            </th>
            <th scope="col" class="text-center">
              <b>Keterangan</b>
            </th>
            <th scope="col" width="140" class="text-center">
              <b>DEBET</b>
            </th>
            <th scope="col" width="140" class="text-center">
              <b>KREDIT</b>
            </th>
            <th scope="col" width="150" class="text-center">
              <b>TOTAL</b>
            </th>
          </tr>
        </thead>
        <tbody v-if="entry.coaTrans.length > 0" >
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              Rp.
              {{ formatNumber(entry.coaFaAmount.FaPriceBegining) }}
            </td>
            <td>
              Rp.
              {{ formatNumber(entry.coaFaAmount.FaPriceBegining) }}
            </td>
          </tr>
          <tr v-for="(data, index) in entry.coaTrans">
            <td>{{ formatIndDate(data.transEffDate) }}</td>
            <td>{{ data.transNo }}</td>
            <td>{{ data.transCoaCode }}</td>
            <td>{{ data.transCoaName + " / " + data.transDescription }}</td>
            <td>
              {{
                data.transType === "debet"
                  ? "Rp. " + formatNumber(data.transAmount).replace("-", "")
                  : ""
              }}
            </td>
            
            <td>
              {{
                data.transType === "kredit"
                  ? "Rp. " + formatNumber(data.transAmount).replace("-", "")
                  : ""
              }}
            </td>
            <td v-if=" totalTransaksi(entry.coaTrans, index) +
                    parseFloat(entry.coaFaAmount.FaPriceBegining) < 0" >
                Rp. 
                    {{
                      formatNumber(
                        (totalTransaksi(entry.coaTrans, index) +  parseFloat(entry.coaFaAmount.FaPriceBegining)) * -1
                      )
                    }}
            </td>
            <td v-else>
             Rp.  
                    {{
                      formatNumber(
                        totalTransaksi(entry.coaTrans, index) +
                          parseFloat(entry.coaFaAmount.FaPriceBegining) 
                      )
                    }}
            </td>
          </tr>
          <tr>
            <td colspan="4"><b>TOTAL</b></td>
            <td>
              <b>Rp. {{ formatNumber(totalDebetData(entry.coaTrans)) }}</b>
            </td>
            <td>
              <b
                >Rp.
                {{ formatNumber(totalKreditData(entry.coaTrans)).replace("-", "") }}</b
              >
            </td>

            <td v-if="(parseFloat(entry.coaFaAmount.FaPriceBegining) +
                      parseFloat(totalKreditData(entry.coaTrans)) -
                      parseFloat(totalDebetData(entry.coaTrans) )) < 0" >
              <b
                >Rp.
                {{
                  formatNumber(
                      (parseFloat(entry.coaFaAmount.FaPriceBegining) +
                      parseFloat(totalKreditData(entry.coaTrans)) -
                      parseFloat(totalDebetData(entry.coaTrans))) * -1
                  )
                }}</b
              >
            </td>
            <td v-else >
              <b
                >Rp.
                {{
                  formatNumber(
                      parseFloat(entry.coaFaAmount.FaPriceBegining) +
                      parseFloat(totalKreditData(entry.coaTrans)) -
                      parseFloat(totalDebetData(entry.coaTrans)) 
                  )
                }}</b
              >
            </td>

          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5">Data Transaksi Kosong</td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { formatNumber } from "../function/number";
import { formatIndDate } from "../function/date";
export default {
  props: {
    saldoAwal: String,
    data: Array,
  },
  data() {
    return {
      saldoAkhir: "0",
      saldoIn: "0",
      saldoOut: "0",
    };
  },
  computed: {
    realData() {
      console.log(this.data);
      return this.data.map((x) => ({
        ...x,
        coaTrans: x.coaTrans.sort((a, b) => a.transEffDate.localeCompare(b.transEffDate)),
      }));
    },
    updateSaldoAwal() {
      return formatNumber(this.saldoAwal.toString());
    },
    updateSaldoIn() {
      let ini = this.data
        .filter((x) => parseFloat(x.transIn) > 0 && parseFloat(x.transOut) <= 0)
        .reduce((sum, item) => sum + parseFloat(item.transAmount), 0);
      this.saldoIn = ini;
      return formatNumber(ini);
    },
    updateSaldoOut() {
      let out = this.data
        .filter((x) => parseFloat(x.transOut) > 0 && parseFloat(x.transIn) <= 0)
        .reduce((sum, item) => sum + parseFloat(item.transAmount), 0);
      if (out < 0) {
        out = out * -1;
      }
      this.saldoOut = out;
      return formatNumber(out);
    },
    updateSaldoAkhir() {
      return formatNumber((this.saldoAwal + this.saldoIn - this.saldoOut).toString());
    },
  },
  methods: {
    // totalTransaksi(data, index) {
    //   console.log("BOSS",JSON.stringify(data))
    //   const sum = data.slice(0, index + 1).reduce((accumulator, currentValue) => {
    //     return accumulator + parseFloat(currentValue.transAmount);
    //   }, 0);
    //   return sum;
    // },
    isNegativeTotal(coaTrans, faPriceBegining, index) {
    return totalTransaksi(coaTrans, index) + parseFloat(faPriceBegining) < 0;
  },
    totalTransaksi(data,index) {
      let total = 0;
      for (let i = 0; i <= index; i++) {
        if (data[i].transType === "kredit") {
          total += parseFloat(data[i].transAmount);
        } else {
          total -= parseFloat(data[i].transAmount);
        }
      }
      return total;
    },
    formatIndDate(data) {
      return formatIndDate(data);
    },
    sumTransSaldoAwal(data) {
      const sum = data.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.transAmount);
      }, 0);
      return sum;
    },

    
    totalDebetData(data) {
      const sum = data
        .filter((x) => x.transType === "debet")
        .reduce((accumulator, currentValue) => {
          return accumulator + parseFloat(currentValue.transAmount);
        }, 0);
      return sum;
    },
    totalKreditData(data) {
      const sum = data
        .filter((x) => x.transType === "kredit")
        .reduce((accumulator, currentValue) => {
          return accumulator + parseFloat(currentValue.transAmount);
        }, 0);
      return sum;
    },



    formatNumber(data) {
      return formatNumber(data);
    },
  },
};
</script>

<style scoped></style>
