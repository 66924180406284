var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c(
        "div",
        {
          ref: "contentDiv",
          staticClass: "content container-fluid p-3 ps-0 py-0",
        },
        [
          _c("transaksiAddPayment", {
            attrs: {
              posts: _vm.posts,
              component: _vm.refreshComponent,
              hotSettings: _vm.hotSettings,
              widthTable: _vm.contentDivWidth,
              excelData: _vm.excelData,
              refreshMethods: _vm.refreshValue,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }