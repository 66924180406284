<template>
    <main id="content" role="main" class="main">
      <div class="content container-fluid p-3 ps-0 pt-0" ref="contentDiv">
        <ul class="breadcrumb m-1 mt-0">
          <li>
            <a href="#"><i class="bi-house-door"></i></a>
          </li>
          <li>Laporan Piutang CLient</li>
        </ul>
        <!-- Content -->
        <transition name="fade" mode="out-in">
          <div v-if="isDataLoaded === ''" key="empty">
            <loading></loading>
          </div>
  
          <!-- Main Content -->
          <div v-else-if="isDataLoaded" key="truthy">
            <div>
              <div class="card">
                <div class="card-header bg-light p-2">
                  <div class="row">
                    <div
                      class="row col-10 d-flex align-items-center justify-content-between"
                    >
                      <div class="w-25">
                        <b>TOTAL DATA: {{ hotData.length }}</b>
                      </div>
                      <b-form-group
                        label-for="coaKasbank"
                        invalid-feedback="Condition Harus Diisi!"
                        style="width: 200px"
                      >
                        <b-form-input
                          type="month"
                          class="form-control-sm py-1"
                          v-model="getDate"
                          @change="refreshData"
                        ></b-form-input>
                      </b-form-group>
                    </div>
  
                    <div
                      class="col-2 d-flex justify-content-between ps-0"
                      style="z-index: 2"
                    >
                      <button
                        type="button"
                        @click="refreshData"
                        class="btn btn-light px-2 py-0"
                      >
                        <i class="bi-arrow-repeat"></i>
                      </button>
                      <b-button-group>
                        <button class="btn btn-primary btn-xs" @click="printReport">
                          <i class="bi-printer pe-2"></i>Print
                        </button>
                        <b-dropdown id="dropdown2" variant="primary" size="xs" right>
                          <div>
                            <p class="p-2 pb-0 m-0">Export</p>
                          </div>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item @click="generatePDF">PDF</b-dropdown-item>
                          <b-dropdown-item>xls</b-dropdown-item>
                        </b-dropdown>
                      </b-button-group>
                    </div>
                  </div>
                </div>
                <div class="card-body p-2 printData">
                  <transition name="fade" mode="out-in">
                    <div v-if="isRefreshLoaded === ''" key="empty">
                      <loading></loading>
                    </div>
  
                    <div v-else-if="isDataLoaded" key="truthy">
                      <h2 class="text-center">Laporan Piutang Client</h2>
                      <piutangClient :hotData="hotData"></piutangClient>
                    </div>
  
                    <div v-else key="other">
                      <connection-timeout></connection-timeout>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
  
          <!-- Error -->
          <div v-else key="other">
            <connection-timeout></connection-timeout>
          </div>
        </transition>
  
        <!-- <tableNeracaSaldo :header="header" :data="data" :fields="tableFields" :settings="hotSettings" :routingLink="routing"
            :tableWidth="contentDivWidth" :filterText="filterText" /> -->
      </div>
    </main>
  </template>
  
  <script>
  import loading from "../components/loading.vue";
  import { getListObatBulanan, getListPiutangClient } from "../components/services/components";
  import { getMonth } from "../components/function/date";
  import apotekBulanan from "../components/print/apotekBulanan";
  import piutangClient from "../components/print/piutangClient.vue";

  import connectionTimeout from "../components/connectionTimeout.vue";
  export default {
    components: {
      loading,
      connectionTimeout,
      apotekBulanan,
      piutangClient
      
      // tableNeracaSaldo,
    },
    data() {
      return {
        hotData: [],
        getDate: "",
        isDataLoaded: "",
        isRefreshLoaded: true,
        filterText: "",
      };
    },
    async mounted() {
      const timeout = 30000; // 10 seconds
      try {
        const currentMonth = await getMonth();
        this.getDate = currentMonth;
        const tes = this.getHotData();
        const timeoutPromise = new Promise((resolve) => setTimeout(resolve, timeout));
  
        // Wait for either finalPromise or timeoutPromise to resolve
        const result = await Promise.race([tes, timeoutPromise]);
        this.isDataLoaded = result !== undefined;
      } catch (error) {
        this.isDataLoaded = false;
      }
    },
    computed: {
      filteredTableData() {
        const filterText = this.filterText.toLowerCase().trim();
        return this.coaList.coaCashBank.filter((row) => {
          for (let key in row) {
            const value = String(row[key]);
            if (value.toLowerCase().includes(filterText)) {
              return true;
            }
          }
          return false;
        });
      },
    },
    methods: {
      async getHotData() {
        const tes = await getListPiutangClient(this.getDate);
        this.hotData = tes.sort((a, b) => {
          const nameA = a.itemName.toUpperCase();
          const nameB = b.itemName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; // names are equal
        });
        return true;
      },
      async refreshData() {
        this.isRefreshLoaded = "";
        const timeout = 30000; // 10 seconds
        try {
          const tes = this.getHotData();
          const timeoutPromise = new Promise((resolve) => setTimeout(resolve, timeout));
  
          // Wait for either finalPromise or timeoutPromise to resolve
          const result = await Promise.race([tes, timeoutPromise]);
          this.isRefreshLoaded = result !== undefined;
        } catch (error) {
          this.isRefreshLoaded = false;
        }
      },
      printReport() {
        console.log("tes");
      },
      generatePDF() {
        console.log("tes");
      },
      sumProperty(property, condition) {
        if (condition !== "") {
          condition = "item." + condition;
          const filterFunction = new Function("item", `return ${condition}`);
          console.log(this.posts.transData.filter(filterFunction));
          return this.posts.transData
            .filter(filterFunction)
            .reduce((sum, entry) => parseFloat(sum) + entry[property], 0);
        } else {
          return this.posts.transData.reduce(
            (sum, entry) => parseFloat(sum) + entry[property],
            0
          );
        }
      },
      getTotal(property, condition) {
        return this.sumProperty(property, condition);
      },
      async getData() {
        const tes = this.getHotData();
        // console.log(this.posts.transData);
        // return this.posts.transData.reduce((result, item) => {
        //   result.push(item);
        //   return result;
        // }, []);
      },
      serviceAdd() {
        this.$router.push("/transaksi/" + this.routing + "/add");
      },
      buttonRenderer(instance, td, row, col, prop, value, cellProperties) {
        const rowData = this.data[row];
        // Clear the cell content
        while (td.firstChild) {
          td.removeChild(td.firstChild);
        }
  
        // Create the buttons container element
        const buttonsContainer = document.createElement("div");
  
        // Create the first button
        const button1 = document.createElement("button");
        button1.innerHTML = '<i class="bi-eye"></i>';
        button1.className = "btn btn-xs btn-outline-secondary px-1 py-0 rounded-circle";
        button1.addEventListener("click", () => {
          // Handle button 1 click event
          this.handleButtonClick(rowData, 1);
        });
        buttonsContainer.appendChild(button1);
  
        // Append the buttons container to the cell
        td.appendChild(buttonsContainer);
  
        // Add necessary CSS classes
        td.classList.add("htCenter"); // Adjust the class based on your styling needs
      },
      handleButtonClick(rowData, buttonIndex) {
        console.log(rowData);
        console.log(buttonIndex);
        // Handle the button click event with row data
        this.sendData = this.posts.transData.find((item) => item.header === rowData);
        if (buttonIndex === 1) {
          this.$router.push({
            name: this.routing + "-detail",
            params: { dataDetail: this.sendData },
          });
        }
      },
      async getComponent() {
        this.component = this.posts.transProperties.find(
          ({ dataName }) => dataName === "menuListReportHarian"
        ).dataMicro;
      },
      async getHeader() {
        this.header = this.posts.transProperties.find(
          ({ dataName }) => dataName === "headerReportHarian"
        ).dataMicro;
      },
      getFieldTable() {
        const fields = this.component;
        const trueFields = fields.map((x) => {
          if (x.microType === "date") {
            return { data: x.key, title: x.microName, type: x.microType, width: 110 };
          } else if (x.microType === "numeric") {
            return {
              data: x.key,
              title: x.microName,
              type: x.microType,
              width: 125,
              renderer: (instance, td, row, col, prop, value) => {
                if (typeof value === "number") {
                  const formattedValue = value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  td.innerText = formattedValue;
                } else {
                  td.innerText = value;
                }
              },
              validator: (value, callback) => {
                if (value > 1000000000000 || value < 0) {
                  callback(false);
                } else {
                  callback(true);
                }
              },
            };
          } else if (x.microType === "dropdown") {
            return {
              data: "action",
              title: "opsi",
              width: 50,
  
              renderer: this.buttonRenderer,
            };
          } else {
            if (x.width <= 0) {
              return { data: x.key, title: x.microName, type: x.microType };
            } else {
              return { data: x.key, title: x.microName, type: x.microType, width: x.width };
            }
          }
        });
        return trueFields;
      },
      getContentWidth() {
        const contentDiv = this.$refs.contentDiv;
        let columns = 0;
        const tes = this.hotSettings.columns.filter((x) => x.type === "text" && !x.width)
          .length;
        const widthTes = this.hotSettings.columns;
        for (let i = 0; i < widthTes.length; i++) {
          if (widthTes[i].width >= 0 || widthTes[i].width !== undefined) {
            columns += widthTes[i].width;
          }
        }
        if (contentDiv) {
          this.contentDivWidth = (contentDiv.offsetWidth - 100 - columns) / tes;
        }
        return true;
      },
    },
  };
  </script>
  