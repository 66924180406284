<template>
  <div class="report" style="font-size: 10px">
    <!-- {{ fixData }} -->
    <table class="z-1 position-relative table table-bordered table-hover" style>
      <thead class="sticky-element bg-secondary">
        <tr>
          <th scope="col" class="text-center align-middle text-white" width="50">
            <b>NO</b>
          </th>
          <th scope="col" class="text-center align-middle text-white">
            <b>NAMA OBAT</b>
          </th>
          <!-- <th scope="col" class="text-center align-middle text-white" rowspan="2">
            <b>NAMA PRODUK</b>
          </th> -->
          <!-- <th
            scope="col"
            class="text-center align-middle text-white"
            width="110"
            rowspan="2"
          >
            <b>SALDO AWAL</b>
          </th> -->
          <th scope="col" class="text-center align-middle text-white" width="85">
            <b>No. Batch</b>
          </th>
          <th scope="col" class="text-center align-middle text-white" width="50">
            <b>SATUAN</b>
          </th>
          <!-- <th
            scope="col"
            class="text-center align-middle text-white"
            width="110"
            rowspan="2"
          >
            <b>PENGELUARAN</b>
          </th> -->
          <th scope="col" class="text-center align-middle text-white" width="85">
            <b>STOK AWAL</b>
          </th>
          <th scope="col" class="text-center align-middle text-white" width="85">
            <b>MASUK</b>
          </th>
          <th scope="col" class="text-center align-middle text-white" width="85">
            <b>KELUAR</b>
          </th>
          <th scope="col" class="text-center align-middle text-white" width="85">
            <b>STOK AKHIR</b>
          </th>
          <th scope="col" class="text-center align-middle text-white" width="125">
            <b>HARGA BELI</b>
          </th>
          <th scope="col" class="text-center align-middle text-white" width="125">
            <b>JUMLAH</b>
          </th>
        </tr>
      </thead>

      <tbody v-if="fixData.length > 0">
        <tr v-for="(data, i) in fixData">
          <td>{{ i + 1 }}</td>
          <td>{{ data.itemName }}</td>
          <td>{{ data.itemCode }}</td>
          <td>
            <template v-if="data.itemInvStock">{{ data.itemInvStock.invUM }}</template
            ><template v-else>-</template>
          </td>
          <!-- <td>Azy</td> -->
          <!-- <td class="text-end">Rp. 1.500.000</td> -->
          <td>
            <template v-if="data.itemInvStock">{{
              data.itemInvStock.invBegining
            }}</template
            ><template v-else>-</template>
          </td>
          <td>
            <template v-if="data.itemInvStock">{{
              sumInOut(data.itemInvStock.invIn)
            }}</template
            ><template v-else>-</template>
          </td>
          <td>
            <template v-if="data.itemInvStock">{{
              sumInOut(data.itemInvStock.invOut)
            }}</template
            ><template v-else>-</template>
          </td>
          <!-- <td class="text-end">Rp. 500.000</td> -->
          <td>
            <template v-if="data.itemInvStock">{{ data.itemInvStock.invEnd }}</template
            ><template v-else>-</template>
          </td>
          <td class="text-end">
            Rp. {{ formatNumberInt(data.itemStandartHPP ? data.itemStandartHPP : 0) }}
          </td>
          <td class="text-end">
            <b>
              <template v-if="data.itemInvStock && data.itemStandartHPP"
                >Rp.
                {{
                  formatNumberInt(data.itemInvStock.invEnd * data.itemStandartHPP)
                }}</template
              ><template v-else>Rp. -</template></b
            >
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="10">Belum Ada data atau data kosong!</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatNumberInt } from "../function/number";
export default {
  props: {
    hotData: Array,
  },
  data() {
    return {};
  },
  computed: {
    fixData() {
      return this.hotData;
    },
  },
  methods: {
    formatNumberInt(data) {
      return formatNumberInt(data);
    },
    sumInOut(data) {
      if (data.length > 0) {
        const sumQtyRcp = data.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.itemHysQtyRcp;
        }, 0);
        return sumQtyRcp;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style scoped></style>
