var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chartjs-custom" }, [
    _c("canvas", { ref: "chartCanvas", class: _vm.chartData.class }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }