var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "footer d-flex justify-content-between align-items-center",
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("p", { staticClass: "fs-6 mb-0" }, [
              _vm._v("© PT. Revolusi Digital Solution "),
              _c("span", { staticClass: "d-none d-sm-inline-block" }, [
                _vm._v("2023 | lapakPOS."),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-auto" }, [
            _c("div", { staticClass: "d-flex justify-content-end" }, [
              _c("ul", { staticClass: "list-inline list-separator" }),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }