var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      staticClass:
        "navbar navbar-expand-lg navbar-fixed navbar-height navbar-container navbar-bordered bg-white",
      attrs: { id: "header" },
    },
    [
      _c("div", { staticClass: "navbar-nav-wrap" }, [
        _vm._m(0),
        _vm._m(1),
        _c("div", { staticClass: "navbar-nav-wrap-content-end" }, [
          _c(
            "ul",
            { staticClass: "navbar-nav" },
            [
              _vm._l(_vm.btnList, function (btn) {
                return [
                  btn.key === "submit"
                    ? [
                        _c("div", { staticClass: "btn-group me-2" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.alertSwal(btn.microRouting)
                                },
                              },
                            },
                            [_vm._v("Tambah")]
                          ),
                          _c("button", {
                            staticClass:
                              "btn btn-primary dropdown-toggle dropdown-toggle-split",
                            attrs: {
                              type: "button",
                              href: "javascript:;",
                              id: "accountNavbarDropdown",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false",
                              "data-bs-dropdown-animation": "",
                            },
                          }),
                          _vm._m(2, true),
                        ]),
                      ]
                    : btn.key === "edit"
                    ? [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-success dropdown-toggle dropdown-toggle-split me-2",
                            attrs: {
                              type: "button",
                              href: "javascript:;",
                              id: "accountNavbarDropdown",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false",
                              "data-bs-dropdown-animation": "",
                            },
                          },
                          [_vm._v(" Export ")]
                        ),
                        _c("div", { staticClass: "dropdown" }, [
                          _vm._m(3, true),
                        ]),
                      ]
                    : btn.key === "save"
                    ? [
                        _c("div", { staticClass: "btn-group me-2" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.alertSwal(btn.microRouting)
                                },
                              },
                            },
                            [_vm._v("Edit")]
                          ),
                          _c("button", {
                            staticClass:
                              "btn btn-success dropdown-toggle dropdown-toggle-split",
                            attrs: {
                              type: "button",
                              href: "javascript:;",
                              id: "accountNavbarDropdown",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false",
                              "data-bs-dropdown-animation": "",
                            },
                          }),
                          _vm._m(4, true),
                        ]),
                      ]
                    : btn.key === "back"
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-outline-secondary me-2",
                            attrs: { type: "button" },
                            on: { click: _vm.backToList },
                          },
                          [
                            _c("i", { staticClass: "bi-chevron-left" }),
                            _vm._v(" " + _vm._s(btn.microText) + " "),
                          ]
                        ),
                      ]
                    : void 0,
                ]
              }),
              _c("div", { staticClass: "vr" }),
              _c("li", { staticClass: "nav-item d-none d-sm-inline-block" }, [
                _c("div", { staticClass: "dropdown" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-ghost-secondary btn-icon rounded-circle",
                      attrs: {
                        type: "button",
                        id: "navbarNotificationsDropdown",
                        "data-bs-toggle": "dropdown",
                        "aria-expanded": "false",
                        "data-bs-auto-close": "outside",
                        "data-bs-dropdown-animation": "",
                        tabindex: _vm.tabindex,
                      },
                    },
                    [
                      _c("i", { staticClass: "bi-bell" }),
                      _vm.listNotif.length > 0
                        ? _c("span", {
                            staticClass:
                              "btn-status btn-sm-status btn-status-danger",
                          })
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-menu dropdown-menu-end dropdown-card navbar-dropdown-menu navbar-dropdown-menu-borderless",
                      staticStyle: { width: "25rem" },
                      attrs: {
                        "aria-labelledby": "navbarNotificationsDropdown",
                      },
                    },
                    [
                      _c("div", { staticClass: "card" }, [
                        _vm._m(5),
                        _c(
                          "ul",
                          {
                            staticClass: "nav nav-tabs nav-justified",
                            attrs: { id: "notificationTab", role: "tablist" },
                          },
                          [
                            _c("li", { staticClass: "nav-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link active",
                                  attrs: {
                                    href: "#notificationNavOne",
                                    id: "notificationNavOne-tab",
                                    "data-bs-toggle": "tab",
                                    "data-bs-target": "#notificationNavOne",
                                    role: "tab",
                                    "aria-controls": "notificationNavOne",
                                    "aria-selected": "true",
                                  },
                                },
                                [
                                  _vm._v(
                                    "Messages (" +
                                      _vm._s(_vm.listNotif.length) +
                                      ")"
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "card-body-height" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tab-content",
                              attrs: { id: "notificationTabContent" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "tab-pane fade show active",
                                  attrs: {
                                    id: "notificationNavOne",
                                    role: "tabpanel",
                                    "aria-labelledby": "notificationNavOne-tab",
                                  },
                                },
                                [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "list-group list-group-flush navbar-card-list-group",
                                    },
                                    [
                                      _vm._l(_vm.listNotif, function (notif) {
                                        return [
                                          notif.Process === "Purchase Request"
                                            ? _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "list-group-item form-check-select",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleNotif(
                                                        notif,
                                                        notif.PrId
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      _vm._m(6, true),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col ms-n2",
                                                        },
                                                        [
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "mb-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  notif.Process
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-body fs-5",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "by: " +
                                                                  _vm._s(
                                                                    notif.UserBy
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "blockquote",
                                                            {
                                                              staticClass:
                                                                "blockquote blockquote-sm",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    notif.PrPurpose
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "col-auto text-muted text-cap",
                                                        },
                                                        [_vm._v("10hr")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("a", {
                                                    staticClass:
                                                      "stretched-link",
                                                    attrs: { href: "#" },
                                                  }),
                                                ]
                                              )
                                            : notif.Process === "Purchase Order"
                                            ? _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "list-group-item form-check-select",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleNotif(
                                                        notif,
                                                        notif.PoId
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      _vm._m(7, true),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col ms-n2",
                                                        },
                                                        [
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "mb-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  notif.Process
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-body fs-5",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "by: " +
                                                                  _vm._s(
                                                                    notif.UserBy
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "col-auto text-muted text-cap",
                                                        },
                                                        [_vm._v("10hr")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("a", {
                                                    staticClass:
                                                      "stretched-link",
                                                    attrs: { href: "#" },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      }),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _vm._m(8),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c("li", { staticClass: "nav-item" }, [
                _c("div", { staticClass: "dropdown" }, [
                  _vm._m(9),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account",
                      staticStyle: { width: "16rem" },
                      attrs: { "aria-labelledby": "accountNavbarDropdown" },
                    },
                    [
                      _c("div", { staticClass: "dropdown-item-text" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "avatar avatar-sm avatar-circle",
                                attrs: { tabindex: _vm.tabindex + 1 },
                              },
                              [
                                _c("img", {
                                  staticClass: "avatar-img",
                                  attrs: {
                                    src: require("../assets/user.png"),
                                    alt: "Image Description",
                                  },
                                }),
                              ]
                            ),
                            _c("div", { staticClass: "flex-grow-1 ms-3" }, [
                              _c("h5", { staticClass: "mb-0" }, [
                                _vm._v(_vm._s(_vm.username)),
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass: "card-text text-body",
                                  staticStyle: { "font-size": "12px" },
                                },
                                [_vm._v(_vm._s(_vm.email))]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _vm.usahaTitle !== undefined
                        ? _c("div", { staticClass: "dropdown-divider" })
                        : _vm._e(),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: { click: _vm.logout },
                        },
                        [_vm._v("Sign out")]
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "navbar-brand",
        attrs: { href: "index.html", "aria-label": "Front" },
      },
      [
        _c("img", {
          staticClass: "navbar-brand-logo",
          attrs: {
            src: "/assets/svg/logos/logo.svg",
            alt: "Logo",
            "data-hs-theme-appearance": "default",
          },
        }),
        _c("img", {
          staticClass: "navbar-brand-logo",
          attrs: {
            src: "/assets/svg/logos-light/logo.svg",
            alt: "Logo",
            "data-hs-theme-appearance": "dark",
          },
        }),
        _c("img", {
          staticClass: "navbar-brand-logo-mini",
          attrs: {
            src: "/assets/svg/logos/logo-short.svg",
            alt: "Logo",
            "data-hs-theme-appearance": "default",
          },
        }),
        _c("img", {
          staticClass: "navbar-brand-logo-mini",
          attrs: {
            src: "/assets/svg/logos-light/logo-short.svg",
            alt: "Logo",
            "data-hs-theme-appearance": "dark",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "navbar-nav-wrap-content-start" }, [
      _c(
        "button",
        {
          staticClass:
            "js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler",
          attrs: { type: "button" },
        },
        [
          _c("i", {
            staticClass: "bi-arrow-bar-left navbar-toggler-short-align",
            attrs: {
              "data-bs-template":
                '<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "right",
              title: "Collapse",
            },
          }),
          _c("i", {
            staticClass: "bi-arrow-bar-right navbar-toggler-full-align",
            attrs: {
              "data-bs-template":
                '<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "right",
              title: "Expand",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dropdown" }, [
      _c(
        "div",
        {
          staticClass:
            "dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account",
          attrs: { "aria-labelledby": "accountNavbarDropdown" },
        },
        [
          _c("div", { staticClass: "flex-grow-1 ms-3" }, [
            _c(
              "p",
              {
                staticClass: "card-text text-body",
                staticStyle: { "font-size": "12px" },
              },
              [_vm._v("Import file")]
            ),
          ]),
          _c("div", { staticClass: "dropdown-divider" }),
          _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
            _vm._v(" json"),
          ]),
          _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
            _vm._v("xls"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account",
        attrs: { "aria-labelledby": "accountNavbarDropdown" },
      },
      [
        _c("div", { staticClass: "flex-grow-1 ms-3" }, [
          _c(
            "p",
            {
              staticClass: "card-text text-body",
              staticStyle: { "font-size": "12px" },
            },
            [_vm._v("Export file")]
          ),
        ]),
        _c("div", { staticClass: "dropdown-divider" }),
        _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
          _vm._v(" json"),
        ]),
        _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
          _vm._v("xls"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dropdown" }, [
      _c(
        "div",
        {
          staticClass:
            "dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account",
          attrs: { "aria-labelledby": "accountNavbarDropdown" },
        },
        [
          _c("div", { staticClass: "flex-grow-1 ms-3" }, [
            _c(
              "p",
              {
                staticClass: "card-text text-body",
                staticStyle: { "font-size": "12px" },
              },
              [_vm._v("Import file")]
            ),
          ]),
          _c("div", { staticClass: "dropdown-divider" }),
          _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
            _vm._v(" json"),
          ]),
          _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
            _vm._v("xls"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "card-header card-header-content-between" },
      [_c("h4", { staticClass: "card-title mb-0" }, [_vm._v("Notifications")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-auto" }, [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c("div", { staticClass: "form-check" }, [
          _c("input", {
            staticClass: "form-check-input",
            attrs: {
              type: "checkbox",
              value: "",
              id: "notificationCheck2",
              checked: "",
            },
          }),
          _c("label", {
            staticClass: "form-check-label",
            attrs: { for: "notificationCheck2" },
          }),
          _c("span", { staticClass: "form-check-stretched-bg" }),
        ]),
        _c(
          "div",
          { staticClass: "avatar avatar-sm avatar-soft-dark avatar-circle" },
          [_c("span", { staticClass: "avatar-initials" }, [_vm._v("PR")])]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-auto" }, [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c("div", { staticClass: "form-check" }, [
          _c("input", {
            staticClass: "form-check-input",
            attrs: {
              type: "checkbox",
              value: "",
              id: "notificationCheck2",
              checked: "",
            },
          }),
          _c("label", {
            staticClass: "form-check-label",
            attrs: { for: "notificationCheck2" },
          }),
          _c("span", { staticClass: "form-check-stretched-bg" }),
        ]),
        _c(
          "div",
          { staticClass: "avatar avatar-sm avatar-soft-dark avatar-circle" },
          [_c("span", { staticClass: "avatar-initials" }, [_vm._v("PO")])]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "card-footer text-center", attrs: { href: "#" } },
      [
        _vm._v(" View all notifications "),
        _c("i", { staticClass: "bi-chevron-right" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "navbar-dropdown-account-wrapper",
        attrs: {
          href: "javascript:;",
          id: "accountNavbarDropdown",
          "data-bs-toggle": "dropdown",
          "aria-expanded": "false",
          "data-bs-dropdown-animation": "",
        },
      },
      [
        _c("div", { staticClass: "avatar avatar-sm avatar-circle" }, [
          _c("img", {
            staticClass: "avatar-img",
            attrs: {
              src: require("../assets/user.png"),
              alt: "Image Description",
            },
          }),
          _c("span", {
            staticClass: "avatar-status avatar-sm-status avatar-status-success",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }