<template>
  <main id="content" role="main" class="main">
    <div class="w-100" ref="contentDiv"></div>
    <transition name="fade" mode="out-in">
      <div v-if="isDataLoaded === ''" key="empty">
        <loading></loading>
      </div>

      <!-- Main Content -->
      <div v-else-if="isDataLoaded" key="truthy">
        <ul class="breadcrumb m-1 mt-0">
          <li>
            <a href="#"><i class="bi-house-door"></i></a>
          </li>
          <li>
            <router-link to="/transaksi/sales-invoice-manufacture" class="text-primary"
              >Shipment
            </router-link>
          </li>
          <li>Detail Shipment (Surat Jalan)</li>
          <li>{{ transHysNo }}</li>
        </ul>
        <div class="card">
          <div class="card-header bg-light p-2">
            <div class="row">
              <div class="row col-9 d-flex align-items-center justify-content-end pe-0">
                <b-form-group
                  label-for="coaKasbank"
                  invalid-feedback="Condition Harus Diisi!"
                  style="width: 200px"
                >
                </b-form-group>
              </div>

              <div class="col-3 d-flex justify-content-end py-2" style="z-index: 2">
                <router-link
                  to="/transaksi/sales-invoice-manufacture"
                  class="btn btn-outline-secondary btn-xs py-1 me-2"
                >
                  <i class="bi-chevron-left"></i>Kembali
                </router-link>
                <b-button-group>
                  <button class="btn btn-primary btn-xs" @click="printReport">
                    <i class="bi-printer pe-2"></i>Print
                  </button>
                  <b-dropdown id="dropdown2" variant="primary" size="xs" right>
                    <div>
                      <p class="p-2 pb-0 m-0">Export</p>
                    </div>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="generatePDF">PDF</b-dropdown-item>
                    <b-dropdown-item>xls</b-dropdown-item>
                  </b-dropdown>
                </b-button-group>
              </div>
            </div>
          </div>
          <div class="card-body p-2 printData">
            <div class="container">
              <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center">
                  <img
                    class="img-fluid w-25"
                    src="/assets/img/logoPrint/dragon1.png"
                    alt="Image Description"
                    data-hs-theme-appearance="default"
                  />
                  <div class="w-50 text-center">
                    <h1 class="text-danger">
                      <b><u>UNPAID</u></b>
                    </h1>
                  </div>
                </div>
              </div>
              <hr />
              <!-- {{ data }} -->
              <div class="row mb-4 d-flex justify-content-between">
                <div class="col-4">
                  <h5 class="m-0">Invoice To</h5>
                  <p class="m-0">{{ data[0].transShipCustNo.userName }}</p>
                  <p class="m-0">{{ data[0].transShipCustNo.userPhone }}</p>
                  <p class="m-0">
                    <b>{{ data[0].transShipCustNo.userAddress[0].nameAddr }}</b>
                  <p>{{ data[0].transShipCustNo.userAddress[0].location }}</p>
                  </p>
                </div>
                <div class="col-8 text-end">
                  <h5 class="m-0">Pay To</h5>
                  <p class="m-0">Transfer Pembayaran Ke:</p>
                  <p class="m-0">- Bank Central Asia (BCA) 2890632281</p>
                  <p class="m-0">- Bank Rakyat Indonesia (BRI) 649301000010567</p>
                  <p class="m-0">- Bank Rakyat Indonesia (BRI) 384301022907534</p>
                  <p class="m-0">Atau Cara Lain:</p>
                  <p class="m-0">- Cash of Delivery (COD)</p>
                </div>
                <div class="col-4 mt-4">
                  <h5 class="m-0">Invoice Number</h5>
                  <p class="m-0" v-if="data[0].transSINo">{{ data[0].transSINo }}</p>
                </div>
                <div class="col-8 mt-4 text-end">
                  <h5 class="m-0">Invoice Date</h5>
                  <p class="m-0" v-if="data[0].transHysEffDate">
                    {{ formatIndDate(data[0].transHysEffDate) }}
                  </p>
                </div>
              </div>

              <div class="card">
                <div class="card-header bg-light"><b>INVOICE ITEMS</b></div>
                <div class="card-body p-3">
                  <table class="table table-bordered m-0">
                    <thead>
                      <tr>
                        <th width="10px">NO.</th>
                        <th class="text-center">KETERANGAN</th>
                        <th class="text-center" width="75">QTY</th>
                        <th class="text-center" width="135">HARGA</th>
                        <th class="text-center" width="135">TOTAL</th>
                      </tr>
                    </thead>
                    <tbody v-if="data">
                      <tr v-for="(dt, index) in data[0].transHysItem">
                        <td>{{ index + 1 }}</td>
                        <td>{{ dt.itemHysName }}</td>
                        <td>{{ dt.itemHysQtyRcp }} {{ dt.itemHysUm }}</td>
                        <td class="text-end">
                          Rp.
                          {{
                            dt.itemHysMasterPrice.priceValue
                              ? formatNumberInt(dt.itemHysMasterPrice.priceValue)
                              : 0
                          }}
                        </td>
                        <td class="text-end">
                          {{
                            dt.itemHysMasterPrice.priceValue && dt.itemHysQtyRcp
                              ? formatNumberInt(
                                  dt.itemHysMasterPrice.priceValue * dt.itemHysQtyRcp
                                )
                              : 0
                          }}
                        </td>
                      </tr>
                      <tr class="bg-light">
                        <td colspan="4" class="text-end"><b>Sub Total</b></td>
                        <td class="text-end"><b>Rp. {{ formatNumberInt(subTotal(data)) }}</b></td>
                      </tr>
                      <tr class="bg-light">
                        <td colspan="4" class="text-end"><b>Diskon</b></td>
                        <td class="text-end"><b>Rp. {{ formatNumberInt(discTotal(data[0].transDiscount)) }}</b></td>
                      </tr>
                      <!-- <tr class="bg-light">
                        <td colspan="4" class="text-end"><b>DP</b></td>
                        <td class="text-end"><b>Rp. {{ formatNumberInt(data[0].transDPValue) }}</b></td>
                      </tr> -->
                      <tr class="bg-light">
                        <td colspan="4" class="text-end"><b>Total</b></td>
                        <td class="text-end"><b>Rp. {{ formatNumberInt(grandTotal()) }}</b></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <h5 class="mt-3">Payment History</h5>
              <table class="table table-bordered m-0">
                <thead>
                  <tr>
                    <th width="10px">NO.</th>
                    <th class="text-center" width="150">Trans. ID</th>
                    <th class="text-center" width="135">Tgl. Bayar</th>
                    <th class="text-center" width="150">Bayar Ke</th>
                    <th class="text-center">Keterangan</th>
                    <th class="text-center" width="135">Jumlah</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="payHistory" v-for="(dt, index) in payHistory">
                    <td>{{ index + 1 }}</td>
                    <td>{{ dt.transId }}</td>
                    <td>{{ formatIndDate(dt.transDate) }}</td>
                    <td>{{ dt.transPayTo }}</td>
                    <td>{{ dt.transDesc }}</td>
                    <td class="text-end">Rp. {{ formatNumberInt(dt.transAmount) }}</td>
                  </tr>
                  <!-- <tr v-for="(dt, index) in data[0].transHysItem">
                    <td>{{ index + 1 }}</td>
                    <td>{{ dt.itemHysName }}</td>
                    <td>{{ dt.itemHysQtyRcp }} {{ dt.itemHysUm }}</td>
                    <td class="text-end">Rp. 10.000.000</td>
                    <td class="text-end">Rp. 10.000.000</td>
                  </tr> -->
                  <tr v-else>
                    <td colspan="6">Belum Ada Pembayaran!</td>
                  </tr>
                  <tr>
                    <td colspan="5" class="text-end"><b>Menunggu Pembayaran</b></td>
                    <td class="text-end"><b>Rp. {{ formatNumberInt(grandAmount()) }}</b></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Error -->
      <div v-else key="other">
        <connection-timeout></connection-timeout>
      </div>
    </transition>
  </main>
</template>

<script>
import { getShipmentById, getSIById } from "../../components/services/components";
import loading from "../../components/loading.vue";
import connectionTimeout from "../../components/connectionTimeout.vue";
import { formatIndDate } from "../../components/function/date";
import { formatNumberInt } from "../../components/function/number";
export default {
  components: {
    loading,
    connectionTimeout,
  },
  data() {
    return {
      payHistory: [],
      data: [],
      transHysNo: "",
      isDataLoaded: "",
    };
  },
  methods: {
    discTotal(data) {
      let totalData = 0
      if (data.length > 0) {
        console.log('tes',data)
        // Calculate the sum of itemHysStdAmount
        // Calculate the sum of (itemHysQtyRcp * itemHysMasterPrice.priceValue)
        let sumDisc = data.reduce((acc, item) => {
          console.log(acc, item)
          let qtyRcp = parseFloat(item.totalItem || 0);
          let priceValue = parseFloat(item.totalTrans || 0);
          return acc + qtyRcp + priceValue;
        }, 0);
        // Add both totalHysStdAmount and totalSisaBayar properties to the main object
        console.log(sumDisc)
      totalData += sumDisc;
      }
      return totalData
    },
    grandAmount() {
      let tes = this.grandTotal()
      if (tes) {
        this.payHistory.forEach(x => {
          tes -= parseFloat(x.transAmount)
        });

      }
      return tes
    },
    grandTotal() {
      let tes = this.data
      let totalDiskon = tes[0].transDiscount ? this.discTotal(tes[0].transDiscount) : 0
      if (tes) {
        return this.subTotal(tes) - totalDiskon
      } else {
      return 0
      }
    },
    subTotal(data) {
      let totalData = 0
      if (data.length  > 0) {
        
      data.forEach((obj) => {
        console.log('datanya', obj)
        // Calculate the sum of itemHysStdAmount
        // Calculate the sum of (itemHysQtyRcp * itemHysMasterPrice.priceValue)
        let sumSisaBayar = obj.transHysItem.reduce((acc, item) => {
          let qtyRcp = parseFloat(item.itemHysQtyRcp || 0);
          let priceValue = parseFloat(item.itemHysMasterPrice.priceValue || 0);
          return acc + qtyRcp * priceValue;
        }, 0);
        // Add both totalHysStdAmount and totalSisaBayar properties to the main object
        totalData += sumSisaBayar;
      });
      }
      return totalData
    },
    formatNumberInt(date) {
      return formatNumberInt(date);
    },
    formatIndDate(date) {
      return formatIndDate(date);
    },
    printReport() {
      const printWindow = window.open("", "_blank");
      const printDocument = `
          <html>
          <head>
	          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet">
          <style scope>
            td {
              position: relative;
            }
            .container {
              max-width: 800px;
              margin: 0 auto;
              padding: 20px;
              padding-top: 0;
              border: 1px solid #ccc;
              background-color: #fff;
              overflow: hidden; /* Untuk mengatasi float */
            }
            hr {
              margin-top: 0 !important
            }
            h5, p, th, td {
              font-size: 0.75rem;
            }
            th, td {
              padding-top: 2.5px !important;
              padding-bottom: 2.5px !important;
            }
            .card-header {
              font-size: 0.75rem!important;
              padding-top: 2.5px !important;
              padding-bottom: 2.5px !important;
            }
            div h1 {
              font-size: 1.5rem!important;
              text-align: right;
            }
            .row div img {
              width: 15%!important;
            }
            .header {
              text-align: center;
              background-color: #f2f2f2;
              padding: 10px;
            }

            .header h1 {
              margin: 0;
              color: #333;
            }

            .po-number {
              text-align: right;
              margin-top: 10px;
            }

            .po-number p {
              margin: 0;
              font-weight: bold;
            }

            .po-date {
              text-align: right;
            }

            .po-date p {
              margin: 5px 0;
            }

            .address-info {
              display: flex;
              justify-content: space-between;
              margin-top: 20px;
            }

            .address-info h2 {
              font-size: 18px;
              margin: 0;
            }

            .address-info .address {
              flex: 1;
            }

            .address-info p {
              margin: 5px 0;
            }

            .order-details {
              margin-top: 20px;
              margin-bottom: 0; /* Menghilangkan margin bawah */
            }

            .order-details table {
              width: 100%;
              border-collapse: collapse;
            }

            .order-details table,
            .order-details th,
            .order-details td {
              border: 1px solid #ccc;
            }

            .order-details th,
            .order-details td {
              padding: 10px;
              text-align: left;
            }
            .divNotePrint {
              overflow: hidden;
              text-overflow: ellipsis; /* Add ellipsis for overflow text */
              border: 1px solid #ccc;
              position: absolute;
              top: 10px;
              left: 0;
              bottom: 0;
              right: 10px;
            }
            .footerPrint {
              padding: 25px 25px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            .footerPrint .ttd {
              flex-wrap: wrap;
              display: flex;
              align-items: space-between;
              justify-content: center;
              width: 30%;
              height: 110px;
              border-bottom: 1px solid #000;
            }
            .footerPrint .ttd .ttdName {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: flex-end;
            }
            </style>
              </head>

            <body>
              ${this.getReportContent()}
            </body>
          </html>
        `;
      printWindow.document.write(printDocument);
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
        printWindow.close();
      };
    },
    getReportContent() {
      const reportView = document.querySelector(".printData");
      if (reportView) {
        return reportView.innerHTML;
      }
      return "No content found.";
    },
    async getTransHysNo() {
      console.log(this.$route.query.data);
      this.transHysNo = this.$route.query.data;
      this.data = await getSIById(this.transHysNo);
      if (this.data[0].transDPValue > 0) {
        const data = {
          transId: "-",
          transDate: this.data[0].transHysEffDate,
          transPayTo: "-",
          transDesc: "DP",
          transAmount: this.data[0].transDPValue,          
        }
        this.payHistory.push(data)
      }
      // this.data = await getShipmentById(this.transHysNo);
      return true;
    },
  },
  async mounted() {
    const timeout = 30000; // 10 seconds
    try {
      const data = await this.getTransHysNo();
      const timeoutPromise = new Promise((resolve) => setTimeout(resolve, timeout));

      // Wait for either finalPromise or timeoutPromise to resolve
      const result = await Promise.race([data, timeoutPromise]);
      this.isDataLoaded = result !== undefined;
    } catch (error) {
      this.isDataLoaded = false;
    }
  },
};
</script>
