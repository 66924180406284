<template v-if="fields.length > 0">
  <div>
    <!-- <div class="row">
        <div :class="['col-sm-6 col-lg-3 mb-3', micro.microClass]" v-for="(micro, index) in header" :key="index">
          <div class="card card-hover-shadow h-100">
            <div class="card-body">
              <h6 class="card-subtitle">{{ micro.microName }}</h6>
              <div class="row align-items-center gx-2">
                <div class="col-12">
                  <h1 class="card-title d-flex align-items-center my-2">
                    <span class="h4 m-0 pe-1" :class="micro.value < 0 ? 'text-danger' : ''">{{ micro.microCurrency }}</span>
                    <numberCounter :class="micro.value < 0 ? 'text-danger' : ''" :target-number="micro.value">
                    </numberCounter>
                  </h1>
                </div>
                <div v-if="micro.microName === 'Saldo Akhir' && micro.value < 0" style="font-size: 11px">Saldo Minus! Mohon
                  cek transaksi</div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    <div class="card">
      <div class="card-header bg-light p-2">
        <div class="row">
          <div class="row d-flex align-items-center justify-content-between pe-0">
            <div class="w-50">
              <b-form-group
                label-for="coaKasbank"
                invalid-feedback="Condition Harus Diisi!"
                label-cols="2"
                content-cols="10"
                label="Pilih Item :"
              >
                <!-- <b-form-input type="month" class="form-control-sm py-1" v-model="getDate" @change="getData"></b-form-input> -->
                <select
                  class="form-control-sm py-1"
                  v-model="selectData"
                  @change="changeBincard()"
                >
                  <option v-for="dataItem in hotDataSelect" :value="dataItem._id">
                    {{ dataItem.itemCode }} - {{ dataItem.itemName }}
                  </option>
                </select>
              </b-form-group>
            </div>
            <div class="col-4 d-flex justify-content-end py-2" style="z-index: 2">
              <b-button-group>
                <input
                  type="month"
                  class="form-control-sm form-control"
                  style="width: 175px"
                  v-model="currentMonth"
                  @change="changeBincard()"
                />
                <button class="btn btn-primary btn-xs rounded-0">
                  <i class="bi-printer pe-2" @click.prevent="printfungsi"></i>Print
                </button>
                <b-dropdown id="dropdown2" variant="primary" size="xs" right>
                  <div>
                    <!-- <p class="p-2 pb-0 m-0">Halo</p> -->
                    <button class="btn btn-primary" @click.prevent="printfungsi">
                      Tekan
                    </button>
                  </div>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="generatePDF">PDF</b-dropdown-item>
                  <b-dropdown-item>xls</b-dropdown-item>
                </b-dropdown>
              </b-button-group>
            </div>
          </div>
        </div>
      </div>
      <!-- <pre>
      {{ binData }}
      </pre> -->
      <div class="card-body p-2 printData">
        <h2 class="text-center">Laporan Bincard</h2>
        <!-- <printbincard :title="print" :data="hotData" :saldoAwal="saldoAwal"></printbincard> -->
        <!-- punyaku  -->
        <div class="report" style="font-size: 10px">
          <table class="table table-bordered" ref="PrintTable">
            <thead>
              <tr>
                <th scope="col" class="text-center" colspan="2"><b>Item code</b></th>
                <th scope="col" class="text-center" colspan="3"><b>Item Name</b></th>
                <th scope="col" class="text-center" width="100"><b>UM</b></th>
                <th scope="col" class="text-center" width="100">
                  <b>Item Desc</b>
                </th>
                <th scope="col" class="text-center" width="100"><b>Stok awal</b></th>
                <th scope="col" class="text-center" width="100"><b>stok akhir</b></th>
              </tr>
            </thead>
            <tbody v-if="binData">
              <tr v-if="binData.saldo.length > 0">
                <th colspan="2" scope="col" class="text-center">
                  <b>{{ binData.saldo[0].itemCode }}</b>
                </th>
                <th colspan="3" scope="col" class="text-center">
                  <b>{{ binData.saldo[0].itemName }}</b>
                </th>
                <th scope="col" class="text-center">
                  <b>{{ binData.saldo[0].itemUM }}</b>
                </th>
                <th scope="col" class="text-center" width="100">
                  <b>{{ binData.saldo[0].itemDescription }}</b>
                </th>
                <!-- <th scope="col" class="text-center"><b>{{ FilterItem[0].itemInvStock[0].invYear }} - {{
                  FilterItem[0].itemInvStock[0].invMonth }}</b></th> -->
                <th scope="col" class="text-center" width="100">
                  <b>{{ formatNumberInt(binData.saldo[0].invBegining) }}</b>
                </th>
                <th scope="col" class="text-center" width="100">
                  <b>{{ formatNumberInt(binData.saldo[0].invEnd) }}</b>
                </th>
              </tr>
              <tr v-else>
                <td colspan="9">Data Bincard Kosong!</td>
              </tr>
            </tbody>
            <thead>
              <tr v-if="binData.transaksi.length > 0">
                <th scope="col" class="text-center" width="75"><b>Date</b></th>
                <th scope="col" class="text-center" width="75"><b>Code</b></th>
                <th scope="col" class="text-center"><b>Reff</b></th>
                <th scope="col" class="text-center"><b>Type</b></th>
                <th scope="col" class="text-center"><b>From / To</b></th>
                <th scope="col" class="text-center"><b>In</b></th>
                <th scope="col" class="text-center" width="100"><b>Out</b></th>
                <th scope="col" class="text-center" width="100"><b>Balance</b></th>
                <th scope="col" class="text-center" width="100"><b>Remark</b></th>
              </tr>
              <tr v-else>
                <td colspan="9">List Transaksi Bincard Kosong!</td>
              </tr>
            </thead>
            <tbody v-if="binData.transaksi.length > 0">
              <tr v-for="(entry, i) in binData.transaksi">
                <td>{{ entry.transHysEffDate }}</td>
                <td>{{ entry.transHysNo }}</td>
                <td width="80">{{ entry.transHysReff }}</td>
                <td width="85">{{ entry.transHysType }}</td>
                <td>{{ entry.itemLocation.name }}</td>
                <td class="text-center">
                  {{
                    entry.transHysType === "TF-ITM-PLUS" ||
                    entry.transHysType === "ADJ-PLUS" ||
                    entry.transHysType === "WO-RCT" ||
                    entry.transHysType === "PO-RCT"
                      ? entry.itemHysQtyRcp
                      : ""
                  }}
                </td>
                <td class="text-center">
                  {{
                    entry.transHysType === "TF-ITM-MINUS" ||
                    entry.transHysType === "ADJ-MINUS" ||
                    entry.transHysType === "BOM" ||
                    entry.transHysType === "DO"
                      ? entry.itemHysQtyRcp
                      : ""
                  }}
                </td>
                <td class="text-center">
                  {{ formatNumberInt(binBalance(i, binData.saldo[0].invBegining)) }}
                </td>
                <td class="text-center">{{ entry.transHysNotes }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <!-- <td colspan="6">Data Tidak Ditemukan</td> -->
              </tr>
            </tbody>
          </table>
          <table class="table table-bordered">
            <thead>
              <tr>
                <td colspan="3" rowspan="2" class="p-0">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th class="text-center" style="width: 30%">Dibuat Oleh</th>
                        <!-- <th class="text-center" style="width: 30%">|</th> -->
                        <th class="text-center" style="width: 30%">Disetujui Oleh</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <!-- <td><br><br><br><br></td> -->
                        <td><br /><br /><br /><br /></td>

                        <td><br /><br /><br /><br /></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getE,
  getG,
  getListItembyDomain,
  getListItembyIdDomain,
  getBincard,
} from "./services/components";
import numberCounter from "../components/niceTo/numberCounter";
import { kasbankByDate } from "./function/exportPDF";
import { formatNumber, formatNumberInt } from "./function/number";
// import printbincard from './print/printbincard'

export default {
  components: {
    numberCounter,
  },
  props: {
    header: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    routingLink: {
      type: String,
      required: true,
    },
    filterText: {
      type: String,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    tableWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      binData: [],
      selectData: [],
      currentMonth: "",
      saldoAwal: "",
      print: "ini title",
      description: "ini cuma deskripsi",
      optionsData: [],
      getDate: "",
      hotData: [],
      newData: [],
      startDate: "",
      endDate: "",
      filterOption: "Data",
      filter: "",
      filterFields: "",
      filterSort: "",
      filterList: [{ filter: "" }],
      checkedFields: [],
      sortBy: "",
      sortDesc: false,
      currentPage: 1,
      perPage: 8,
      // Punyaku
      select: "",
      hotDataSelect: [],
      // ----
    };
  },
  computed: {
    getDataSelect() {
      return this.hotDataSelect;
    },
    refreshData() {
      return this.data;
    },
    dataRange() {
      if (this.data !== undefined) {
        const startIndex = (this.currentPage - 1) * this.perPage;
        const endIndex = startIndex + this.perPage;
        return this.data.map((item) => item).slice(startIndex, endIndex);
      }
    },
    totalPages() {
      return Math.ceil(this.data.length / this.perPage);
    },
    dataRangeText() {
      const startIndex = (this.currentPage - 1) * this.perPage + 1;
      const endIndex = Math.min(startIndex + this.perPage - 1, this.data.length);
      return `${startIndex} - ${endIndex} data dari total ${this.data.length} data`;
    },
  },
  async mounted() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    this.getDate = year + "-" + month;
    this.currentMonth = year + "-" + month;
    this.getData();
    this.getselect();
    // this.select = this.hotDataSelect[0];
    this.header.forEach((item) => {
      item.value = 0;
    });
    console.log("tes", this.hotDataSelect);
  },
  created() {
    this.initializeCheckedFields();
  },
  // punyaku
  computed: {
    FilterItem: function () {
      return this.hotDataSelect.filter((data) => {
        return data._id.toLowerCase().includes(this.select.toLowerCase());
      });
    },
  },
  // ----
  methods: {
    formatNumberInt(data) {
      return formatNumberInt(data);
    },
    binBalance(index, data) {
      let total = parseFloat(data);
      for (let i = 0; i <= index; i++) {
        if (
          this.binData.transaksi[i].transHysType === "TF-ITM-PLUS" ||
          this.binData.transaksi[i].transHysType === "ADJ-PLUS" ||
          this.binData.transaksi[i].transHysType === "WO-RCT" ||
          this.binData.transaksi[i].transHysType === "PO-RCT"
        ) {
          total += parseFloat(this.binData.transaksi[i].itemHysQtyRcp);
        } else if (
          this.binData.transaksi[i].transHysType === "DO" ||
          this.binData.transaksi[i].transHysType === "BOM" ||
          this.binData.transaksi[i].transHysType === "ADJ-MINUS" ||
          this.binData.transaksi[i].transHysType === "TF-ITM-MINUS"
        ) {
          total -= parseFloat(this.binData.transaksi[i].itemHysQtyRcp);
        }
      }
      return total;
    },
    async changeBincard() {
      if ((this.currentMonth, this.selectData)) {
        const tes = await getBincard(this.selectData, this.currentMonth);
        this.binData = tes;
        console.log(tes);
      }
      console.log("tes", this.currentMonth, this.selectData);
    },
    printfungsi() {
      var newWin;
      var divToPrint = this.$refs.PrintTable;
      newWin = window.open("");
      newWin.document.write(divToPrint.outerHTML);
      newWin.print();
      newWin.close();
    },
    doPrint() {
      window.print();
    },
    printReport() {
      console.log("ini print", this.getReportContent());
      const printWindow = window.open("", "_blank");
      const printDocument = `
          <html>
            <head>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet">
            <style>
  th {
    font-weight: 500
  }
  tr {
    font-size: 10px
  }
            </style>
              </head>

            <body>
              ${this.getReportContent()}
            </body>
          </html>
        `;
      printWindow.document.write(printDocument);
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
        printWindow.close();
      };
    },
    getReportContent() {
      const reportView = document.querySelector(".printData");
      if (reportView) {
        return reportView.innerHTML;
      }
      return "No content found.";
    },
    async generatePDF() {
      const data = {
        title: "Laporan Kas Harian",
        tgl: this.getDate,
        nomor: "BKK-0118",
        saldoAwal: await getG(this.getDate),
        transData: this.optionsData
          .flatMap((item) => item.trans)
          .map((x) => {
            const minus = {
              bukti: x.transNo,
              name: x.transDescription,
              code: x.coaCode,
            };

            if (x.transType === "debet") {
              minus.kredit = x.transAmount;
              minus.debet = 0;
            } else if (x.transType === "kredit") {
              minus.debet = x.transAmount;
              minus.kredit = 0;
            }

            minus.kredit = parseFloat(minus.kredit);
            minus.debet = parseFloat(minus.debet);
            return minus;
          }),
        // transData: [
        //   { code: '001', name: 'Kas Besar', kredit: 20000, debet: 20000 },
        //   { code: '001', name: 'Kas Besar', kredit: 20000, debet: 20000 },
        //   { code: '002', name: 'Kas Kecil', kredit: 15000, debet: 20000 },
        //   { code: '002', name: 'Kas Kecil', kredit: 30000, debet: 20000 },
        // ],
        // catatan: ''
      };
      await kasbankByDate(data);
    },
    async getData() {
      this.hotData = await getListItembyIdDomain(this.select);
      this.hotData = [...this.hotData];
      // const data = await getE(this.getDate)
      // this.saldoAwal = await getG(this.getDate)
      // this.optionsData = data
      // this.listData()
    },
    async getselect() {
      this.hotDataSelect = await getListItembyDomain();
      this.hotDataSelect = [...this.hotDataSelect];
      this.selectData = this.hotDataSelect[0]._id;
      await this.changeBincard();
      console.log(this.selectData, this.currentMonth);
      console.log("ini binData", this.binData);
      console.log("ini select", this.hotDataSelect);
    },
    async listData() {
      console.log(this.newData);
      let saldoAwal = "";
      let cok = "";
      this.hotData.splice(0, this.hotData.length);

      if (this.newData == null || this.newData.length === 0) {
        saldoAwal = 0;

        cok = this.optionsData
          .flatMap((item) => item.trans)
          .map((x) => {
            const minus = {
              ...x,
            };
            if (x.transAmount.startsWith("-")) {
              x.transAmount = x.transAmount.slice(1);
            }
            if (x.transType === "debet") {
              minus.transIn = x.transAmount;
              minus.transOut = 0;
            } else if (x.transType === "kredit") {
              minus.transOut = x.transAmount;
              minus.transIn = 0;
            }
            minus.transIn = formatNumber(minus.transIn);
            minus.transOut = formatNumber(minus.transOut);
            return minus;
          });
        this.hotData.push(...cok);
      } else if (this.newData.code !== "" && this.newData.code !== undefined) {
        const data = this.optionsData.find((x) => x.code === this.newData.code);
        this.hotData.push(
          ...data.trans.map((x) => {
            const value = {
              ...x,
            };
            if (x.transAmount === "debet") {
              value.transIn = x.transAmount;
              value.transOut = 0;
            } else if (x.transType === "kredit") {
              value.transOut = x.transAmount.slice(1);
              value.transIn = 0;
            }
            value.transIn = formatNumber(value.transIn);
            value.transOut = formatNumber(value.transOut);
            console.log("value", value);
            return value;
          })
        );
        saldoAwal = 0;
      }

      let saldoMasuk = "";
      let saldoKeluar = "";

      if (this.hotData.length > 0) {
        saldoMasuk = this.hotData
          .filter((x) => x.transType === "debet")
          .reduce((sum, item) => sum + parseFloat(item.transAmount), 0);
        saldoKeluar = this.hotData
          .filter((x) => x.transType === "kredit")
          .reduce((sum, item) => sum + parseFloat(item.transAmount), 0);
        this.header.forEach((item) => {
          if (item.key === "saldoAwal") {
            item.value = saldoAwal;
          } else if (item.key === "saldoIn") {
            item.value = saldoMasuk;
          } else if (item.key === "saldoOut") {
            item.value = saldoKeluar;
          } else if (item.key === "saldoAkhir") {
            item.value = saldoAwal + saldoMasuk - saldoKeluar;
          }
        });
      } else {
        this.header.forEach((item) => {
          item.value = 0;
        });
      }
    },
    customLabel(option) {
      return `${option.code} (${option.user})`;
    },
    addList() {
      const link = "/transaksi/" + this.routingLink + "/add";
      this.$router.push(link);
    },
    resetFilter() {
      this.filter = "";
      this.filterFields = "";
      this.filterSort = "";
      this.filterList = [{ filter: "" }];
      this.startDate = "";
      this.endDate = "";
    },
    addFilter() {
      if (this.filter !== "") {
        this.filterList.push({ filter: "" });
      }
    },
    removeFilter() {
      if (this.filterList.length > 1) {
        this.filterList.splice(-1);
      }
    },
    serviceDetail(item) {
      const data = item._id;
      this.$store.commit("setDataProses", item);
      this.$router.push({ name: "transaksi-detail", params: { data } });
    },
    initializeCheckedFields() {
      this.checkedFields = [];
      this.fields.forEach((field) => {
        this.checkedFields.push(field.key);
      });
    },
    handleButtonClick(item) {
      // Perform action when the button is clicked
      console.log("Button clicked:", item);
    },
    handleRowClick(item) {
      // Handle row click event
      console.log("Row clicked:", item);
    },
  },
};
</script>
<style></style>
