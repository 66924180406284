var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-12" },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.isDataLoaded === ""
          ? _c(
              "div",
              { key: "empty" },
              [_c("loadingAuto", { staticStyle: { "min-height": "350px" } })],
              1
            )
          : _vm.isDataLoaded
          ? _c("div", { key: "truthy" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12 mb-3 mb-lg-5" }, [
                  _c("div", { staticClass: "card h-100" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header card-header-content-sm-between py-2 bg-custom-1",
                      },
                      [
                        _c(
                          "h4",
                          {
                            staticClass:
                              "card-header-title mb-2 mb-sm-0 text-white",
                          },
                          [_vm._v(" Grafik Kasbank Bulanan ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-4 d-flex align-items-center" },
                          [
                            _c("b-form-input", {
                              staticClass: "py-1",
                              attrs: { type: "month" },
                              on: { input: _vm.refreshData },
                              model: {
                                value: _vm.editableDate,
                                callback: function ($$v) {
                                  _vm.editableDate = $$v
                                },
                                expression: "editableDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row mb-4" }, [
                        _c("div", { staticClass: "col-sm mb-2 mb-sm-0" }, [
                          _c("div", {
                            staticClass: "d-flex align-items-center",
                          }),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-sm-auto align-self-sm-end" },
                          [
                            _c(
                              "div",
                              { staticClass: "row fs-6 text-body" },
                              _vm._l(_vm.chartData.datasets, function (data) {
                                return _c("div", { staticClass: "col-auto" }, [
                                  _c("span", {
                                    staticClass: "legend-indicator",
                                    style: {
                                      backgroundColor: data.backgroundColor,
                                    },
                                  }),
                                  _vm._v(" " + _vm._s(data.label) + " "),
                                ])
                              }),
                              0
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "chartjs-custom" },
                        [
                          _c("chart-component", {
                            attrs: { chartData: _vm.chartData },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12 mb-3 mb-lg-5" }, [
                  _c("div", { staticClass: "card h-100" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header card-header-content-sm-between py-2 bg-custom-1",
                      },
                      [
                        _c(
                          "h4",
                          {
                            staticClass:
                              "card-header-title mb-2 mb-sm-0 text-white",
                          },
                          [_vm._v(" Laporan Kasbank Bulanan ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-4 d-flex align-items-center" },
                          [
                            _c("b-form-input", {
                              staticClass: "py-1",
                              attrs: { type: "month", disabled: "" },
                              model: {
                                value: _vm.editableDate,
                                callback: function ($$v) {
                                  _vm.editableDate = $$v
                                },
                                expression: "editableDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "card-body p-0" }, [
                      _c("div", { staticClass: "chartjs-custom" }, [
                        _c("table", { staticClass: "table table-bordered" }, [
                          _c("thead", { staticClass: "bg-light" }, [
                            _c(
                              "tr",
                              [
                                _c("th", { attrs: { scope: "col" } }),
                                _vm._l(_vm.data, function (header) {
                                  return _c(
                                    "th",
                                    {
                                      staticClass: "text-center",
                                      attrs: { scope: "col" },
                                    },
                                    [_c("b", [_vm._v(_vm._s(header.coaName))])]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                          _c("tbody", [
                            _c(
                              "tr",
                              [
                                _c("th", { attrs: { scope: "row" } }, [
                                  _vm._v("Awal Periode"),
                                ]),
                                _vm._l(_vm.data, function (awal) {
                                  return _c(
                                    "td",
                                    { staticClass: "text-center" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatNumber(
                                              awal.FaPriceBegining
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                            _c(
                              "tr",
                              [
                                _c("th", { attrs: { scope: "row" } }, [
                                  _vm._v("Akhir Periode"),
                                ]),
                                _vm._l(_vm.data, function (akhir) {
                                  return !isNaN(akhir.FaPriceEnd)
                                    ? _c("td", { staticClass: "text-center" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatNumber(akhir.FaPriceEnd)
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("td", { staticClass: "text-center" }, [
                                        _vm._v("0,00"),
                                      ])
                                }),
                              ],
                              2
                            ),
                            _c(
                              "tr",
                              [
                                _c("th", { attrs: { scope: "row" } }, [
                                  _vm._v("% Kenaikan/Penurunan"),
                                ]),
                                _vm._l(_vm.data, function (awal) {
                                  return _c(
                                    "td",
                                    { staticClass: "text-center" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(awal.presentase) + " % "
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _c("div", { key: "other" }, [_c("connection-timeout")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }