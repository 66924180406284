var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report", staticStyle: { "font-size": "10px" } },
    [
      _vm.realData.length > 0
        ? _vm._l(_vm.realData, function (entry) {
            return _c(
              "table",
              {
                staticClass:
                  "z-1 position-relative table table-bordered table-hover",
              },
              [
                _c("thead", { staticClass: "bg-light" }, [
                  _c("tr", [
                    _c("th", { attrs: { scope: "col", width: "100" } }, [
                      _c("b", [_vm._v(_vm._s(entry.coaCode))]),
                    ]),
                    _c("th", { attrs: { scope: "col", colspan: "4" } }, [
                      _c("b", [_vm._v(_vm._s(entry.coaName))]),
                    ]),
                    _vm._m(0, true),
                    _c("th", { attrs: { scope: "col", width: "150" } }, [
                      _c("b", [
                        _vm._v(
                          "Rp. " +
                            _vm._s(
                              _vm.formatNumber(
                                entry.coaFaAmount.FaPriceBegining
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  entry.coaTrans.length > 0
                    ? _c("tr", [
                        _vm._m(1, true),
                        _vm._m(2, true),
                        _vm._m(3, true),
                        _vm._m(4, true),
                        _vm._m(5, true),
                        _vm._m(6, true),
                        _vm._m(7, true),
                      ])
                    : _vm._e(),
                ]),
                entry.coaTrans.length > 0
                  ? _c(
                      "tbody",
                      [
                        _c("tr", [
                          _c("td"),
                          _c("td"),
                          _c("td"),
                          _c("td"),
                          _c("td"),
                          _c("td", [
                            _vm._v(
                              " Rp. " +
                                _vm._s(
                                  _vm.formatNumber(
                                    entry.coaFaAmount.FaPriceBegining
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              " Rp. " +
                                _vm._s(
                                  _vm.formatNumber(
                                    entry.coaFaAmount.FaPriceBegining
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _vm._l(entry.coaTrans, function (data, index) {
                          return _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.formatIndDate(data.transEffDate))
                              ),
                            ]),
                            _c("td", [_vm._v(_vm._s(data.transNo))]),
                            _c("td", [_vm._v(_vm._s(data.transCoaCode))]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  data.transCoaName +
                                    " / " +
                                    data.transDescription
                                )
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    data.transType === "debet"
                                      ? "Rp. " +
                                          _vm
                                            .formatNumber(data.transAmount)
                                            .replace("-", "")
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    data.transType === "kredit"
                                      ? "Rp. " +
                                          _vm
                                            .formatNumber(data.transAmount)
                                            .replace("-", "")
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]),
                            _vm.totalTransaksi(entry.coaTrans, index) +
                              parseFloat(entry.coaFaAmount.FaPriceBegining) <
                            0
                              ? _c("td", [
                                  _vm._v(
                                    " Rp. " +
                                      _vm._s(
                                        _vm.formatNumber(
                                          (_vm.totalTransaksi(
                                            entry.coaTrans,
                                            index
                                          ) +
                                            parseFloat(
                                              entry.coaFaAmount.FaPriceBegining
                                            )) *
                                            -1
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("td", [
                                  _vm._v(
                                    " Rp. " +
                                      _vm._s(
                                        _vm.formatNumber(
                                          _vm.totalTransaksi(
                                            entry.coaTrans,
                                            index
                                          ) +
                                            parseFloat(
                                              entry.coaFaAmount.FaPriceBegining
                                            )
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                          ])
                        }),
                        _c("tr", [
                          _vm._m(8, true),
                          _c("td", [
                            _c("b", [
                              _vm._v(
                                "Rp. " +
                                  _vm._s(
                                    _vm.formatNumber(
                                      _vm.totalDebetData(entry.coaTrans)
                                    )
                                  )
                              ),
                            ]),
                          ]),
                          _c("td", [
                            _c("b", [
                              _vm._v(
                                "Rp. " +
                                  _vm._s(
                                    _vm
                                      .formatNumber(
                                        _vm.totalKreditData(entry.coaTrans)
                                      )
                                      .replace("-", "")
                                  )
                              ),
                            ]),
                          ]),
                          parseFloat(entry.coaFaAmount.FaPriceBegining) +
                            parseFloat(_vm.totalKreditData(entry.coaTrans)) -
                            parseFloat(_vm.totalDebetData(entry.coaTrans)) <
                          0
                            ? _c("td", [
                                _c("b", [
                                  _vm._v(
                                    "Rp. " +
                                      _vm._s(
                                        _vm.formatNumber(
                                          (parseFloat(
                                            entry.coaFaAmount.FaPriceBegining
                                          ) +
                                            parseFloat(
                                              _vm.totalKreditData(
                                                entry.coaTrans
                                              )
                                            ) -
                                            parseFloat(
                                              _vm.totalDebetData(entry.coaTrans)
                                            )) *
                                            -1
                                        )
                                      )
                                  ),
                                ]),
                              ])
                            : _c("td", [
                                _c("b", [
                                  _vm._v(
                                    "Rp. " +
                                      _vm._s(
                                        _vm.formatNumber(
                                          parseFloat(
                                            entry.coaFaAmount.FaPriceBegining
                                          ) +
                                            parseFloat(
                                              _vm.totalKreditData(
                                                entry.coaTrans
                                              )
                                            ) -
                                            parseFloat(
                                              _vm.totalDebetData(entry.coaTrans)
                                            )
                                        )
                                      )
                                  ),
                                ]),
                              ]),
                        ]),
                      ],
                      2
                    )
                  : _c("tbody", [_vm._m(9, true)]),
              ]
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "text-end", attrs: { scope: "col", width: "140" } },
      [_c("b", [_vm._v("SALDO AWAL :")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "text-center", attrs: { scope: "col", width: "100" } },
      [_c("b", [_vm._v("TANGGAL")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "text-center", attrs: { scope: "col", width: "110" } },
      [_c("b", [_vm._v("Nomer Dokumen")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "text-center", attrs: { scope: "col", width: "80" } },
      [_c("b", [_vm._v("CODE")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
      _c("b", [_vm._v("Keterangan")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "text-center", attrs: { scope: "col", width: "140" } },
      [_c("b", [_vm._v("DEBET")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "text-center", attrs: { scope: "col", width: "140" } },
      [_c("b", [_vm._v("KREDIT")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      { staticClass: "text-center", attrs: { scope: "col", width: "150" } },
      [_c("b", [_vm._v("TOTAL")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { attrs: { colspan: "4" } }, [_c("b", [_vm._v("TOTAL")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "5" } }, [_vm._v("Data Transaksi Kosong")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }