<template>
  <main id="content" role="main" class="main">
    <form class="content container-fluid p-3 ps-0 py-0" ref="contentDiv">
      <!-- Header -->
      <div class="row my-1">
        <div class="col-4 d-flex align-items-center">
          <ul class="breadcrumb m-0">
            <li>
              <router-link to="/dashboard">
                <a href="#"><i class="bi-house-door"></i></a>
              </router-link>
            </li>
            <li>
              <router-link :to="$route.path.replace('/detail', '')">
                <a href="#">Jurnal Entry</a>
              </router-link>
            </li>
            <li>Detail</li>
          </ul>
        </div>
        <div class="col-4 text-center"><span class="h2">Detail Jurnal Entry</span></div>
        <div class="col-4 d-flex justify-content-end align-items-center">
          <router-link :to="$route.path.replace('/detail', '')">
            <button class="btn btn-outline-secondary btn-xs py-1 me-2" type="button">
              <i class="bi-chevron-left"></i>Kembali</button>
          </router-link>
        </div>
      </div>


      <!-- End of Header -->

      <!-- Main Data -->
      <div class="main-data mt-4">
        <div class="row mx-1">
        </div>
        <div class="row scale-9 position-relative" style="width: 114%; z-index: 1;">
          <!-- First Header -->
          <div class="col-6">
            <div class="card h-100">
              <div class="row px-3 py-1">
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between ">*Kode Voucher <span>:</span></label>
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input v-model="transNo" size="sm" disabled class="form-control-sm py-0 form-control" required
                      placeholder="Kode Voucher Jurnal Entry"></b-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End of First Header -->

          <!-- Secondx Header -->
          <div class="col-6">
            <div class="card h-100">
              <div class="row px-3 py-1">
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between">*Trans. Date <span>:</span></label>
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input @input="inOut(micro.value)" v-model="transEffDate" size="sm" type="date"
                      class="form-control-sm py-0 form-control" required disabled
                      placeholder="Masukan Tanggal Transaksi"></b-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End of Secondx Header -->
        </div>

        <div class="row my-3">
          <!-- Hot Table -->
          <div class=" col-12">
            <div class="hot-container col-md-12 position-relative" style="overflow-x: auto; z-index: 0">
              <hot-table ref="hot" :settings="hotSettings" :data="listData" :rowHeaders="true"></hot-table>
            </div>
          </div>
          <!-- End of Hot Table -->
        </div>

        <!-- Footer -->
        <div class="row mb-3 d-flex justify-content-between">
          <div class="col-md-6 scale-9">
            <div class="h-100">
              <div class="card h-100">
                <div class="card-header bg-light py-1">Catatan</div>
                <textarea class="form-control form-control-sm py-1 h-100" style="resize: none" disabled
                  placeholder="Masukan Catatan" v-model="transNotes"></textarea>
              </div>
            </div>

          </div>
          <div class="col-md-4 row p-0 scale-9" style="transform-origin: right top">
            <div class="row d-flex justify-content-end">
              <div class="border border-light bg-light d-flex align-items-center justify-content-between col-5">Total
                Debet <span>:</span></div>
              <div class="p-0 border border-light bg-light pt-1 col-7 pe-2">
                <input type="text" :value="formatNumberInt(totalDebet)" class="form-control form-control-sm py-1"
                  style="text-align: right" disabled>
              </div>
              <div class="border border-light bg-light d-flex align-items-center justify-content-between col-5">Total
                Kredit <span>:</span></div>
              <div class="p-0 border border-light bg-light pt-1 col-7 pe-2">
                <input type="text" :value="formatNumberInt(totalKredit)" class="form-control form-control-sm py-1"
                  style="text-align: right" disabled>
              </div>
              <div class="border border-light bg-light d-flex align-items-center justify-content-between col-5">Balance
                Value
                <span>:</span>
              </div>
              <div class="p-0 border border-light bg-light pt-1 col-7 pe-2">
                <input @input="refreshMethods()" type="text" :value="formatNumberInt(totalAmount)"
                  class="form-control form-control-sm py-1" style="text-align: right" disabled>
              </div>
            </div>
          </div>
        </div>
        <!-- End of Footer -->
      </div>
      <!-- End of Main Data -->
    </form>
  </main>
</template>

<script>

import { formatNumberInt } from '../../components/function/number'
export default {
  data() {
    return {
      transData: [],
      // Main Data
      transNo: '',
      transEffDate: '',
      transNotes: '',

      subTotal: '',

      // HOT Data   
      contentDivWidth: '',
      colHeader: ['*Kode COA', '*Nama COA', 'Cost Center', 'No. Reff', '*Uraian', '*Debet', '*Kredit'],
      colBody: [
        {
          data: 'transCoaCode', type: 'text',
          width: 100
        },
        {
          data: 'transCoaName', type: 'text',
        },
        {
          data: 'transCostCenter', type: 'text',
        },
        {
          data: 'transReff', type: 'text',
          width: 100
        },
        {
          data: 'transDescription', type: 'text',
        },
        {
          data: 'Kredit', type: 'numeric',
          validator: this.validateNumericValue,
          numericFormat: {
            pattern: '0,0',
          },
          width: 125
        },
        {
          data: 'Debet', type: 'numeric',         
          validator: this.validateNumericValue, 
          numericFormat: {
            pattern: '0,0',
          },
          width: 125
        },
      ],
      excelData: [],
      hotSettings: {
        readOnly: true,
        licenseKey: 'non-commercial-and-evaluation',
        height: 200,
        colHeaders: [],

        rowHeaderWidth: 35,
        startRows: 10,
        columns: []
      },
    }
  },
  async mounted() {
    const tes = await this.listData
    this.hotSettings.colHeaders = this.colHeader
    this.hotSettings.columns = this.colBody
    const dataObject = {}
    this.colBody.forEach(item => {
      dataObject[item.data] = '';
    });
    console.log(dataObject)
    this.getContentWidth()
  },
  computed: {
    listData() {
    const tes = this.$route.query;
    if (tes.data) {
        this.transNo = JSON.parse(tes.data)[0].coaTrans[0].transNo
        this.transEffDate = JSON.parse(tes.data)[0].coaTrans[0].transEffDate
        this.transNotes = JSON.parse(tes.data)[0].coaTrans[0].transNotes
      return JSON.parse(tes.data).map(x => ({
        transCoaCode: x.coaCode, 
        transCoaName: x.coaName, 
        transCostCenter: x.coaTrans[0].transCostCenter,
        transReff: x.coaTrans[0].transReff,
        transCostCenter: x.coaTrans[0].transCostCenter,
        transDescription: x.coaTrans[0].transDescription,
        Kredit: x.coaTrans[0].transType === 'kredit' ? x.coaTrans[0].transAmount : 0,
        Debet: x.coaTrans[0].transType === 'debet' ? x.coaTrans[0].transAmount : 0
      }))
    } else {
      return []
    }
    },
    totalKredit() {
      const tes = this.listData.filter(x => x.Kredit !== '').map(x => x.Kredit)
      if (tes.length > 0) {
        const sum = tes.reduce((accumulator, currentValue) => {
          // Convert the current value to a number using parseFloat
          const numericValue = parseFloat(currentValue);

          // Check if the conversion is successful (not NaN) before adding to the accumulator
          if (!isNaN(numericValue)) {
            return accumulator + numericValue;
          } else {
            return accumulator; // Ignore non-numeric values
          }
        }, 0); // Start with an initial accumulator value of 0
        return sum
      } else {
        return 0
      }
    },
    totalDebet() {
      const tes = this.listData.filter(x => x.Debet !== '').map(x => x.Debet)
      if (tes.length > 0) {
        const sum = tes.reduce((accumulator, currentValue) => {
          // Convert the current value to a number using parseFloat
          const numericValue = parseFloat(currentValue);

          // Check if the conversion is successful (not NaN) before adding to the accumulator
          if (!isNaN(numericValue)) {
            return accumulator + numericValue;
          } else {
            return accumulator; // Ignore non-numeric values
          }
        }, 0); // Start with an initial accumulator value of 0
        return sum
      } else {
        return 0
      }
    },
    totalAmount() {
      return this.totalKredit - this.totalDebet
    }
  },
  methods: {
    formatNumberInt(data) {
      return formatNumberInt(data)
    },
    getContentWidth() {
      const contentDiv = this.$refs.contentDiv;
      let totalWidth = 0;
      const tes = this.hotSettings.columns.filter(x => !x.width).length
      const widthTes = this.hotSettings.columns.filter(x => x.width)
      for (const column of widthTes) {
        if (column.width) {
          totalWidth += column.width;
        }
      }
      if (contentDiv) {
        this.hotSettings.colWidths = (contentDiv.offsetWidth - 70 - totalWidth) / tes;
      }
    },
  },
};
</script>