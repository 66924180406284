// const API = "http://192.168.18.178:3000"; 

// const API = "http://192.168.0.103:3007";
// const API = "https://api-redipos.bitniaga.net.id";

// const API = "http://103.77.106.136:3007";      
// const API = "http://103.150.191.221:3000";
const API = process.env.VUE_APP_API_BASE_URL;
const WA = "http://103.77.106.136:2101"

// const dragon = "http://localhost:3007"
export  {API, WA}