<template>
  <main id="content" role="main" class="main">
    <!-- Content -->
    <div>
      <currentList></currentList>
      <grafikKasbank></grafikKasbank>
      <!-- <grafikKasbank></grafikKasbank> -->
      <!-- End Row -->
      <div class="row">
        <!-- Card -->
        <div class="col-lg-6">
          <div class="card mb-3 mb-lg-5">
            <!-- Header -->
            <div class="card-header bg-custom-1">
              <div class="row justify-content-between align-items-center flex-grow-1">
                <div class="col-md-12">
                  <div class="d-flex justify-content-between align-items-center">
                    <h4 class="card-header-title text-white">Sales Order</h4>
                    <h4 class="card-header-title text-white">
                      Pesanan Bulan Ini: {{ filterByDate(soList).length }}
                    </h4>

                    <!-- Datatable Info -->
                    <div id="datatableCounterInfo" style="display: none">
                      <div class="d-flex align-items-center">
                        <span class="fs-6 me-3">
                          <span id="datatableCounter">0</span>
                          Selected
                        </span>
                        <a class="btn btn-outline-danger btn-sm" href="javascript:;">
                          <i class="tio-delete-outlined"></i> Delete
                        </a>
                      </div>
                    </div>
                    <!-- End Datatable Info -->
                  </div>
                </div>
                <!-- End Col -->

                <div class="col-md-12 mt-2">
                  <!-- Filter -->
                  <div class="row align-items-sm-center">
                    <div class="col-sm-auto">
                      <div class="row align-items-center gx-0">
                        <div class="col">
                          <span class="text-white me-2">Status:</span>
                        </div>
                        <!-- End Col -->

                        <div class="col-auto">
                          <!-- Select -->
                          <div class="tom-select-custom tom-select-custom-end">
                            <select
                              v-model="soType"
                              class="custom-select-1 js-select js-datatable-filter form-select form-select-sm form-select-borderless"
                              data-target-column-index="2"
                              data-target-table="datatable"
                              autocomplete="off"
                              data-hs-tom-select-options='{
                                "searchInDropdown": false,
                                "hideSearch": true,
                                "dropdownWidth": "10rem"
                              }'
                            >
                              <option value="">All</option>
                              <option value="New">New</option>
                              <option value="On-Progress">On-Progress</option>
                              <option value="Complete">Complete</option>
                            </select>
                          </div>
                          <!-- End Select -->
                        </div>
                        <!-- End Col -->
                      </div>
                      <!-- End Row -->
                    </div>

                    <div class="col-md">
                      <form>
                        <!-- Search -->
                        <div class="input-group input-group-merge input-group-flush">
                          <div
                            class="input-group-prepend input-group-text text-white"
                            style="position: relative; z-index: 1"
                          >
                            <i class="bi-search"></i>
                          </div>
                          <input
                            id="datatableSearch"
                            v-model="soFilter"
                            type="search"
                            class="form-control custom-input-1 text-white"
                            placeholder="Cari Sales Order..."
                            aria-label="Search users"
                          />
                        </div>
                        <!-- End Search -->
                      </form>
                    </div>
                    <!-- End Col -->
                  </div>
                  <!-- End Filter -->
                </div>
                <!-- End Col -->
              </div>
              <!-- End Row -->
            </div>
            <!-- End Header -->

            <!-- Table -->
            <div class="table-responsive datatable-custom mb-0">
              <table
                id="datatable"
                class="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
              >
                <thead class="thead-light px-3">
                  <tr>
                    <th class="table-column-ps-2 text-center text-dark">Customer</th>
                    <th class="text-dark text-center">Sales</th>
                    <th class="text-dark">Tgl. Order</th>
                    <th class="text-dark">Tgl. Kirim</th>
                    <th class="text-dark">Status</th>
                  </tr>
                </thead>

                <tbody class="px-3">
                  <tr v-for="so in soFilteredData">
                    <td class="table-column-ps-0">
                      <a
                        class="d-flex align-items-center"
                        href="#"
                        @click="detilActionSO(so._id)"
                      >
                        <div class="flex-shrink-0">
                          <div class="avatar avatar-sm avatar-soft-primary avatar-circle">
                            <span class="avatar-initials">{{
                              so.transName.charAt(0)
                            }}</span>
                          </div>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h5 class="text-inherit mb-0">{{ so.transName }}</h5>
                        </div>
                      </a>
                    </td>
                    <td class="text-center" v-if="so.transKasir">
                      {{ so.transKasir.userName }}
                    </td>
                    <td class="text-center" v-else>-</td>
                    <td>{{ formatIndDate(so.transDate) }}</td>
                    <td>{{ formatIndDate(so.transShipmentPlanDate) }}</td>
                    <td>
                      <template v-if="so.transStatusSO === 'open'"
                        ><span class="legend-indicator bg-success"></span>New</template
                      >
                      <template v-else-if="so.transStatusSO === 'Closed'"
                        ><span class="legend-indicator bg-danger"></span
                        >Complete</template
                      >
                      <template v-else
                        ><span class="legend-indicator bg-warning"></span
                        >On-Progress</template
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End Table -->

            <!-- Footer -->
            <div class="card-footer py-0">
              <!-- Pagination -->
              <div
                class="row justify-content-center justify-content-sm-between align-items-sm-center"
              >
                <div class="col-sm mb-2 mb-sm-0">
                  <div
                    class="d-flex justify-content-center justify-content-sm-start align-items-center"
                  >
                    <span class="me-2">Showing:</span>

                    <!-- Select -->
                    <div class="tom-select-custom">
                      <select
                        id="datatableEntries"
                        class="js-select form-select form-select-borderless w-auto"
                        autocomplete="off"
                        data-hs-tom-select-options='{
                          "searchInDropdown": false,
                          "hideSearch": true
                        }'
                        v-model="totalShowSO"
                      >
                        <option value="4">4</option>
                        <option value="6">6</option>
                        <option value="8">8</option>
                        <option value="12">12</option>
                      </select>
                    </div>
                    <!-- End Select -->

                    <span class="text-secondary me-2">Newest Data</span>
                  </div>
                </div>
                <!-- End Col -->

                <div class="col-sm-auto">
                  <div class="d-flex justify-content-center justify-content-sm-end">
                    <!-- Pagination -->
                    <nav id="datatablePagination" aria-label="Activity pagination"></nav>
                  </div>
                </div>
                <!-- End Col -->
              </div>
              <!-- End Pagination -->
            </div>
            <!-- End Footer -->
          </div>
        </div>
        <!-- End Card -->

        <div class="col-lg-6">
          <div class="card mb-3 mb-lg-5">
            <!-- Header -->
            <div class="card-header bg-custom-1">
              <div class="row justify-content-between align-items-center flex-grow-1">
                <div class="col-md-12">
                  <div class="d-flex justify-content-between align-items-center">
                    <h4 class="card-header-title text-white">Purchase Order</h4>
                    <h4 class="card-header-title text-white">
                      PO Bulan Ini: {{ filterByDatePO(poList).length }}
                    </h4>

                    <!-- Datatable Info -->
                    <div id="datatableCounterInfo" style="display: none">
                      <div class="d-flex align-items-center">
                        <span class="fs-6 me-3">
                          <span id="datatableCounter">0</span>
                          Selected
                        </span>
                        <a class="btn btn-outline-danger btn-sm" href="javascript:;">
                          <i class="tio-delete-outlined"></i> Delete
                        </a>
                      </div>
                    </div>
                    <!-- End Datatable Info -->
                  </div>
                </div>
                <!-- End Col -->

                <div class="col-md-12 mt-2">
                  <!-- Filter -->
                  <div class="row align-items-sm-center">
                    <div class="col-sm-auto">
                      <div class="row align-items-center gx-0">
                        <div class="col">
                          <span class="text-white me-2">Status:</span>
                        </div>
                        <!-- End Col -->

                        <div class="col-auto">
                          <!-- Select -->
                          <div class="tom-select-custom tom-select-custom-end">
                            <select
                              v-model="poType"
                              class="custom-select-1 js-select js-datatable-filter form-select form-select-sm form-select-borderless"
                              data-target-column-index="2"
                              data-target-table="datatable"
                              autocomplete="off"
                              data-hs-tom-select-options='{
                                "searchInDropdown": false,
                                "hideSearch": true,
                                "dropdownWidth": "10rem"
                              }'
                            >
                              <option value="">All</option>
                              <option value="New">New</option>
                              <option value="On-Progress">On-Progress</option>
                              <option value="Complete">Complete</option>
                            </select>
                          </div>
                          <!-- End Select -->
                        </div>
                        <!-- End Col -->
                      </div>
                      <!-- End Row -->
                    </div>

                    <div class="col-md">
                      <form>
                        <!-- Search -->
                        <div class="input-group input-group-merge input-group-flush">
                          <div
                            class="input-group-prepend input-group-text text-white"
                            style="position: relative; z-index: 1"
                          >
                            <i class="bi-search"></i>
                          </div>
                          <input
                            id="datatableSearch"
                            v-model="poFilter"
                            type="search"
                            class="form-control custom-input-1 text-white"
                            placeholder="Cari Sales Order..."
                            aria-label="Search users"
                          />
                        </div>
                        <!-- End Search -->
                      </form>
                    </div>
                    <!-- End Col -->
                  </div>
                  <!-- End Filter -->
                </div>
                <!-- End Col -->
              </div>
              <!-- End Row -->
            </div>
            <!-- End Header -->

            <!-- Table -->
            <div class="table-responsive datatable-custom mb-0">
              <table
                id="datatable"
                class="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
              >
                <thead class="thead-light px-3">
                  <tr>
                    <th class="table-column-ps-2 text-center text-dark">Supplier</th>
                    <th class="text-dark text-center">Requester</th>
                    <th class="text-dark">Tgl. Order</th>
                    <th class="text-dark">Tgl. Butuh</th>
                    <th class="text-dark">Status</th>
                  </tr>
                </thead>

                <tbody class="px-3">
                  <tr v-for="po in poFilteredData">
                    <td class="table-column-ps-0">
                      <a class="d-flex align-items-center" href="user-profile.html">
                        <div class="flex-shrink-0">
                          <div class="avatar avatar-sm avatar-soft-primary avatar-circle">
                            <span class="avatar-initials" v-if="po.PoSupplier">{{
                              po.PoSupplier.userName.charAt(0)
                            }}</span>
                          </div>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h5 class="text-inherit mb-0" v-if="po.PoSupplier">
                            {{ po.PoSupplier.userName }}
                          </h5>
                        </div>
                      </a>
                    </td>
                    <td class="text-center" v-if="po.PoUserCreated">
                      {{ po.PoUserCreated.userName }}
                    </td>
                    <td class="text-center" v-else>-</td>
                    <td>{{ formatIndDate(po.poDate) }}</td>
                    <td>{{ formatIndDate(po.PoNeedDate) }}</td>

                    <td>
                      <template v-if="po.PoStatus === 'Open'"
                        ><span class="legend-indicator bg-success"></span>New</template
                      >
                      <template v-else-if="po.PoStatus === 'Closed'"
                        ><span class="legend-indicator bg-danger"></span
                        >Complete</template
                      >
                      <template v-else
                        ><span class="legend-indicator bg-warning"></span
                        >On-Progress</template
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End Table -->

            <!-- Footer -->
            <div class="card-footer py-0">
              <!-- Pagination -->
              <div
                class="row justify-content-center justify-content-sm-between align-items-sm-center"
              >
                <div class="col-sm mb-2 mb-sm-0">
                  <div
                    class="d-flex justify-content-center justify-content-sm-start align-items-center"
                  >
                    <span class="me-2">Showing:</span>

                    <!-- Select -->
                    <div class="tom-select-custom">
                      <select
                        id="datatableEntries"
                        class="js-select form-select form-select-borderless w-auto"
                        autocomplete="off"
                        data-hs-tom-select-options='{
                          "searchInDropdown": false,
                          "hideSearch": true
                        }'
                        v-model="totalShowPO"
                      >
                        <option value="4">4</option>
                        <option value="6">6</option>
                        <option value="8">8</option>
                        <option value="12">12</option>
                      </select>
                    </div>
                    <!-- End Select -->

                    <span class="text-secondary me-2">Newest Data</span>
                  </div>
                </div>
                <!-- End Col -->

                <div class="col-sm-auto">
                  <div class="d-flex justify-content-center justify-content-sm-end">
                    <!-- Pagination -->
                    <nav id="datatablePagination" aria-label="Activity pagination"></nav>
                  </div>
                </div>
                <!-- End Col -->
              </div>
              <!-- End Pagination -->
            </div>
            <!-- End Footer -->
          </div>
        </div>
      </div>

      <div class="row d-none">
        <div class="col-lg-12 mb-3 mb-lg-5">
          <!-- Card -->
          <div class="card h-100">
            <!-- Header -->
            <div class="card-header card-header-content-sm-between">
              <h4 class="card-header-title mb-2 mb-sm-0">Grafik Bulanan</h4>

              <!-- Nav -->
              <ul class="nav nav-segment nav-fill" id="expensesTab" role="tablist">
                <li
                  class="nav-item"
                  data-bs-toggle="chart-bar"
                  data-datasets="thisWeek"
                  data-trigger="click"
                  data-action="toggle"
                >
                  <a class="nav-link active" href="javascript:;" data-bs-toggle="tab"
                    >This Year</a
                  >
                </li>
                <li
                  class="nav-item"
                  data-bs-toggle="chart-bar"
                  data-datasets="lastWeek"
                  data-trigger="click"
                  data-action="toggle"
                >
                  <a class="nav-link" href="javascript:;" data-bs-toggle="tab"
                    >Last Year</a
                  >
                </li>
              </ul>
              <!-- End Nav -->
            </div>
            <!-- End Header -->

            <!-- Body -->
            <div class="card-body">
              <div class="row mb-4">
                <div class="col-sm mb-2 mb-sm-0">
                  <div class="d-flex align-items-center">
                    <span class="h1 mb-0">35%</span>
                    <span class="text-success ms-2">
                      <i class="bi-graph-up"></i> 25.3%
                    </span>
                  </div>
                </div>
                <!-- End Col -->

                <div class="col-sm-auto align-self-sm-end">
                  <div class="row fs-6 text-body">
                    <div class="col-auto">
                      <span class="legend-indicator bg-primary"></span> Pendapatan
                    </div>
                    <!-- End Col -->

                    <div class="col-auto">
                      <span class="legend-indicator bg-danger"></span> Pengeluaran
                    </div>
                    <!-- End Col -->
                  </div>
                  <!-- End Row -->
                </div>
                <!-- End Col -->
              </div>
              <!-- End Row -->

              <!-- Bar Chart -->
              <!-- End Bar Chart -->
            </div>
            <!-- End Body -->
          </div>
          <!-- End Card -->
        </div>
        <!-- End Col -->
      </div>
    </div>

    <!-- End Content -->
  </main>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import currentList from "../page/dashboard/currentList.vue";
import grafikKasbank from "../page/dashboard/grafikKasbank.vue";

import { formatIndDate, getMonth } from "../components/function/date.js";
// @ is an alias to /src
import { getH, getL } from "../components/services/components";
export default {
  components: {
    currentList,
    grafikKasbank,
  },
  name: "home",
  data() {
    return {
      soType: "",
      poType: "",
      currentMonth: "",
      totalShowSO: "4",
      totalShowPO: "4",
      soFilter: "",
      poFilter: "",
      soList: [],
      poList: [],
      // chartTahun: {
      //   labels: ["Jan", "Feb", "Mar", "Apr"],
      //   datasets: [
      //     {

      //       label: "Awal Periode",
      //       data: [0, 1, 2, 300000],
      //       backgroundColor: "#377dff",
      //       hoverBackgroundColor: "#377dff",
      //       borderColor: "#377dff",
      //       maxBarThickness: "40"
      //     },
      //     {
      //       label: "Akhir Periode",
      //       data: [0, 1, 2, 250000],
      //       backgroundColor: "#ED4C78",
      //       borderColor: "#ED4C78",
      //       maxBarThickness: "40"
      //     }
      //   ]
      // },
    };
  },
  computed: {
    ...mapState(["loket4"]),
    soTotal() {
      let sum = 0;
      for (const item of this.soList) {
        for (const subItem of item.transPesanan) {
          // Convert qty to a number and add it to the sum
          sum += parseFloat(subItem.itemQty);
        }
      }
      return sum;
    },
    poTotalAll() {
      let sum = 0;
      for (const item of this.poList) {
        for (const subItem of item.PoItemDetails) {
          // Convert qty to a number and add it to the sum
          sum += parseFloat(subItem.itemQty);
        }
      }
      return sum;
    },
    soFilteredData() {
      const searchText = this.soFilter.toLowerCase();
      console.log(this.soType);
      let tes;
      if (this.soType === "") {
        tes = this.soList;
      } else if (this.soType === "New") {
        tes = this.soList.filter((x) => x.transStatusSO === "open");
      } else if (this.soType === "Complete") {
        tes = this.soList.filter((x) => x.transStatusSO === "Closed");
      } else {
        tes = this.soList.filter((x) => x.transStatusSO === "process");
      }
      const data = this.filterByDate(tes).filter((item) => {
        console.log("ini datanya", item);
        return (
          item.transKasir.userName.toLowerCase().includes(searchText) ||
          item.transName.toLowerCase().includes(searchText) ||
          this.formatIndDate(item.transDate).toLowerCase().includes(searchText) ||
          this.formatIndDate(item.transShipmentPlanDate)
            .toLowerCase()
            .includes(searchText)
          // item.transPesanan.length.toString().includes(searchText)
          // // item.transPesanan.some(
          // //   (order) =>
          // //     order.itemName.toLowerCase().includes(searchText) ||
          // //     order.itemBundling.some((bundItem) =>
          //      bundItem.bundItemName.toLowerCase().includes(searchText)
          //     )
          // )
        );
      });
      console.log(data);
      // const startIdx = data - this.totalShowSO;

      // Use the slice method to get the 8 latest elements
      return data.slice(0, this.totalShowSO);
    },
    poFilteredData() {
      const searchText = this.poFilter.toLowerCase();
      let tes;
      if (this.poType === "") {
        tes = this.poList;
      } else if (this.poType === "New") {
        tes = this.poList.filter((x) => x.transStatusSO === "open");
      } else if (this.poType === "Complete") {
        tes = this.poList.filter((x) => x.transStatusSO === "Closed");
      } else {
        tes = this.poList.filter((x) => x.transStatusSO === "process");
      }
      const data = this.filterByDatePO(tes).filter((item) => {
        console.log("ini datanya", item);
        return (
          item.PoSupplier.userName.toLowerCase().includes(searchText) ||
          item.PoUserCreated.userName.toLowerCase().includes(searchText) ||
          this.formatIndDate(item.poDate).toLowerCase().includes(searchText) ||
          this.formatIndDate(item.PoNeedDate).toLowerCase().includes(searchText)
          // item.transPesanan.length.toString().includes(searchText)
          // item.transPesanan.some(
          //   (order) =>
          //     order.itemName.toLowerCase().includes(searchText) ||
          //     order.itemBundling.some((bundItem) =>
          //       bundItem.bundItemName.toLowerCase().includes(searchText)
          //     )
          // )
        );
      });
      console.log(this.totalShowPO, data);
      // const startIdx = data - this.totalShowSO;

      // Use the slice method to get the 8 latest elements
      return data.slice(0, this.totalShowPO);
    },
    // poFilteredData() {
    //   const searchText = this.poFilter.toLowerCase();
    //   console.log("list po", this.poList);
    //   const data = this.poList.filter((item) => {
    //     // Check if any property of the item includes the search text
    //     for (const key in item) {
    //       if (
    //         typeof item[key] === "string" &&
    //         item[key].toLowerCase().includes(searchText)
    //       ) {
    //         return true;
    //       }
    //     }
    //     return false;
    //   });

    //   const startIdx = this.poList.length - this.totalShowPO;

    //   // Use the slice method to get the 8 latest elements
    //   return data.slice(startIdx);
    // },
  },
  async mounted() {
    console.log(this.loket4);
    this.currentMonth = await getMonth();
    this.soList = await getH();
    this.poList = await getL();
  },
  methods: {
    filterByDate(data) {
      return data.filter(
        (item) =>
          item.transDate.includes(this.currentMonth) ||
          item.transShipmentPlanDate.includes(this.currentMonth)
      );
    },
    filterByDatePO(data) {
      return data.filter(
        (item) =>
          item.poDate.includes(this.currentMonth) ||
          item.PoNeedDate.includes(this.currentMonth)
      );
    },
    detilActionSO(sonum) {
      // alert(sonum);
      this.$router.push({ name: "sales-order-detail", params: { noSO: sonum } });
    },
    formatIndDate(date) {
      return formatIndDate(date);
    },
    poTotal(data) {
      console.log(data);
      let sum = 0;
      for (const subItem of data) {
        // Convert qty to a number and add it to the sum
        sum += parseFloat(subItem.itemQty);
      }
      return sum;
    },
    checkObject(data) {
      return Object.values(data).length > 0;
    },
  },
};
</script>
