var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form", [
    _c("div", { staticClass: "row my-1" }, [
      _c("div", { staticClass: "col-4 d-flex align-items-center" }, [
        _c("ul", { staticClass: "breadcrumb m-0" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/dashboard" } }, [
                _c("a", { attrs: { href: "#" } }, [
                  _c("i", { staticClass: "bi-house-door" }),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { name: _vm.posts.transRouting.replace("-add", "") },
                  },
                },
                [_c("a", { attrs: { href: "#" } }, [_vm._v("Stock Opname")])]
              ),
            ],
            1
          ),
          _c("li", [_vm._v("Tambah")]),
        ]),
      ]),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "col-4 d-flex justify-content-end align-items-center" },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: { name: _vm.posts.transRouting.replace("-add", "") },
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary btn-xs py-1 me-2",
                  attrs: { type: "button" },
                },
                [
                  _c("i", { staticClass: "bi-chevron-left" }),
                  _vm._v("Kembali "),
                ]
              ),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-xs py-1",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.sendData.apply(null, arguments)
                },
              },
            },
            [_vm._v(" Simpan ")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "row scale-9 position-relative",
        staticStyle: { width: "114%", "z-index": "1" },
      },
      _vm._l(_vm.dummyItems, function (field, index) {
        return field.componentName === "fieldHeader"
          ? _c("div", { key: index, class: [field.componentClass] }, [
              _c("div", { staticClass: "card h-100" }, [
                _c(
                  "div",
                  { staticClass: "row px-3" },
                  _vm._l(field.componentMicro, function (micro) {
                    return _c(
                      "div",
                      { class: ["", micro.microClass] },
                      [
                        micro.microType === "select"
                          ? _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: micro.microName + " :",
                                      "label-cols-sm": "4",
                                      "label-cols-lg": "3",
                                      "content-cols-sm": "8",
                                      "content-cols-lg": "9",
                                    },
                                  },
                                  [
                                    _c("b-form-select", {
                                      staticClass:
                                        "mt-1 form-control-sm py-0 form-control",
                                      attrs: {
                                        options: micro.microOptions,
                                        size: "sm",
                                        disabled: micro.isDisabled,
                                      },
                                      model: {
                                        value: micro.value,
                                        callback: function ($$v) {
                                          _vm.$set(micro, "value", $$v)
                                        },
                                        expression: "micro.value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : micro.microType === "multiselect" &&
                            micro.key === "productLocation"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-for": micro.microName,
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                    "invalid-feedback":
                                      "Condition Harus Diisi!",
                                  },
                                },
                                [
                                  _c("multiselect", {
                                    staticClass: "my-1",
                                    attrs: {
                                      id: micro.microName,
                                      options: micro.microOptions,
                                      placeholder: "Select one",
                                      multiple: false,
                                      "show-labels": false,
                                      "custom-label": _vm.customLabel1,
                                    },
                                    on: { input: _vm.changeValue },
                                    model: {
                                      value: _vm.locationData,
                                      callback: function ($$v) {
                                        _vm.locationData = $$v
                                      },
                                      expression: "locationData",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : micro.microType === "multiselect" &&
                            micro.key === "productName"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-for": micro.microName,
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                    "invalid-feedback":
                                      "Condition Harus Diisi!",
                                  },
                                },
                                [
                                  _c("multiselect", {
                                    staticClass: "my-1",
                                    attrs: {
                                      id: micro.microName,
                                      options: micro.microOptions,
                                      placeholder: "Select one",
                                      multiple: false,
                                      "show-labels": false,
                                      "custom-label": _vm.customLabel,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.changeCondition(micro.value)
                                      },
                                    },
                                    model: {
                                      value: micro.value,
                                      callback: function ($$v) {
                                        _vm.$set(micro, "value", $$v)
                                      },
                                      expression: "micro.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : micro.microType === "multiselect"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-for": micro.microName,
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                    "invalid-feedback":
                                      "Condition Harus Diisi!",
                                  },
                                },
                                [
                                  _c("multiselect", {
                                    staticClass: "my-1",
                                    attrs: {
                                      id: micro.microName,
                                      options: micro.microOptions,
                                      placeholder: "Select one",
                                      multiple: false,
                                      "show-labels": false,
                                      "custom-label": _vm.customLabel,
                                    },
                                    model: {
                                      value: micro.value,
                                      callback: function ($$v) {
                                        _vm.$set(micro, "value", $$v)
                                      },
                                      expression: "micro.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : micro.key === "productQty"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass:
                                      "form-control form-control-sm py-0",
                                    attrs: {
                                      type: micro.microType,
                                      placeholder: micro.microText,
                                      required: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.getQty(micro.value)
                                      },
                                    },
                                    model: {
                                      value: micro.value,
                                      callback: function ($$v) {
                                        _vm.$set(micro, "value", $$v)
                                      },
                                      expression: "micro.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: micro.microName + " :",
                                    "label-cols-sm": "4",
                                    "label-cols-lg": "3",
                                    "content-cols-sm": "8",
                                    "content-cols-lg": "9",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass:
                                      "form-control form-control-sm py-0",
                                    attrs: {
                                      type: micro.microType,
                                      placeholder: micro.microText,
                                      required: "",
                                      disabled: micro.isDisabled,
                                    },
                                    model: {
                                      value: micro.value,
                                      callback: function ($$v) {
                                        _vm.$set(micro, "value", $$v)
                                      },
                                      expression: "micro.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ])
          : _vm._e()
      }),
      0
    ),
    _c("div", { staticClass: "row mb-3" }, [
      _c(
        "div",
        { staticClass: "col-md-12 scale-12" },
        _vm._l(_vm.component, function (field) {
          return field.componentName === "fieldNote"
            ? _c("div", { class: ["h-100", field.componentClass] }, [
                _c(
                  "div",
                  { staticClass: "card h-100" },
                  [
                    _c("div", { staticClass: "card-header bg-light py-1" }, [
                      _vm._v(" " + _vm._s(field.componentTitle) + " "),
                    ]),
                    _vm._l(field.componentMicro, function (micro) {
                      return [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: micro.microValue,
                              expression: "micro.microValue",
                            },
                          ],
                          staticClass:
                            "form-control form-control-sm py-1 h-100",
                          staticStyle: { resize: "none" },
                          attrs: { placeholder: micro.microText },
                          domProps: { value: micro.microValue },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(micro, "microValue", $event.target.value)
                            },
                          },
                        }),
                      ]
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e()
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "row mb-3" }),
    _c("table", { staticClass: "table table-bordered" }, [
      _vm._m(1),
      _vm.listItem.length > 0
        ? _c(
            "tbody",
            _vm._l(_vm.listItem, function (data, index) {
              return _c("tr", [
                _c("td", [_vm._v(_vm._s(index + 1))]),
                _c(
                  "td",
                  [
                    _c("multiselect", {
                      staticClass: "my-1",
                      attrs: {
                        id: "kodeItem" + index,
                        options: _vm.fixOptionsItem,
                        placeholder: "Select one",
                        multiple: false,
                        "show-labels": false,
                        "custom-label": _vm.customLabel,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.changeRowItem(index)
                        },
                      },
                      model: {
                        value: data.item,
                        callback: function ($$v) {
                          _vm.$set(data, "item", $$v)
                        },
                        expression: "data.item",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("multiselect", {
                      staticClass: "my-1",
                      attrs: {
                        id: "kodeBatch" + index,
                        options: _vm.optionsBatch(data.item.invDetilBegining),
                        placeholder: "Select one",
                        multiple: false,
                        "show-labels": false,
                        label: "itemNoBatch",
                      },
                      model: {
                        value: data.dataBatch,
                        callback: function ($$v) {
                          _vm.$set(data, "dataBatch", $$v)
                        },
                        expression: "data.dataBatch",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    data.item
                      ? _c("tempalte", [_vm._v(_vm._s(data.item.itemUM))])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    data.dataBatch
                      ? _c("tempalte", [_vm._v(_vm._s(data.dataBatch.itemQty))])
                      : _vm._e(),
                  ],
                  1
                ),
                _c("td", [
                  data.item
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: data.item.qtyAdjust,
                            expression: "data.item.qtyAdjust",
                          },
                        ],
                        staticClass: "py-0",
                        staticStyle: { width: "100px" },
                        attrs: { type: "number", disabled: !data.dataBatch },
                        domProps: { value: data.item.qtyAdjust },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              data.item,
                              "qtyAdjust",
                              $event.target.value
                            )
                          },
                        },
                      })
                    : _vm._e(),
                ]),
                _c("td", { staticClass: "text-center" }, [
                  _c("i", {
                    class:
                      index + 1 === _vm.listItem.length
                        ? "bi-plus-circle text-primary"
                        : "bi-x-circle text-danger",
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        index + 1 === _vm.listItem.length
                          ? _vm.addItem()
                          : _vm.removeItem(index)
                      },
                    },
                  }),
                ]),
              ])
            }),
            0
          )
        : _c("tbody", [
            _c("tr", [
              _c("td", { attrs: { colspan: "6" } }, [
                _vm._v("Data Item Kosong!"),
              ]),
              _c("td", { staticClass: "text-center" }, [
                _c("i", {
                  staticClass: "bi-plus-circle text-primary",
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.addItem },
                }),
              ]),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4 align-items-center" }, [
      _c(
        "h4",
        {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "justify-content": "center",
            "flex-direction": "column",
          },
        },
        [_vm._v(" Tambah Stock Opname ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "bg-light table-border" }, [
      _c("tr", [
        _c("th", { staticClass: "text-center", attrs: { width: "50" } }, [
          _vm._v("No"),
        ]),
        _c("th", { staticClass: "text-center", attrs: { width: "" } }, [
          _vm._v("Kode & Nama Item"),
        ]),
        _c("th", { staticClass: "text-center", attrs: { width: "" } }, [
          _vm._v("No. Kontrol"),
        ]),
        _c("th", { staticClass: "text-center", attrs: { width: "100" } }, [
          _vm._v("Satuan"),
        ]),
        _c("th", { staticClass: "text-center", attrs: { width: "100" } }, [
          _vm._v("Qty Current"),
        ]),
        _c("th", { staticClass: "text-center", attrs: { width: "100" } }, [
          _vm._v("Qty Adjust"),
        ]),
        _c("th", { staticClass: "text-center", attrs: { width: "50" } }, [
          _vm._v("Opsi"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }