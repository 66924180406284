var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "loading-page d-flex align-items-center justify-content-center me-5 pe-5",
      staticStyle: { height: "calc(100vh - 150px)" },
    },
    [
      _c("div", { staticClass: "content container-fluid" }, [
        _c(
          "div",
          {
            staticClass:
              "row justify-content-center align-items-sm-center py-sm-10",
          },
          [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "col-sm-6 col-lg-4 text-center text-sm-start" },
              [
                _c("h1", { staticClass: "display-1 mb-0" }, [_vm._v("522")]),
                _vm._m(1),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    on: { click: _vm.refreshPage },
                  },
                  [_vm._v("Muat ulang")]
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-9 col-sm-6 col-lg-4" }, [
      _c(
        "div",
        { staticClass: "text-center text-sm-end me-sm-4 mb-5 mb-sm-0" },
        [
          _c("img", {
            staticClass: "img-fluid",
            attrs: {
              src: "/assets/svg/illustrations/oc-thinking.svg",
              alt: "Image Description",
              "data-hs-theme-appearance": "default",
            },
          }),
          _c("img", {
            staticClass: "img-fluid",
            attrs: {
              src: "/assets/svg/illustrations-light/oc-thinking.svg",
              alt: "Image Description",
              "data-hs-theme-appearance": "dark",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "lead" }, [
      _c("b", [_vm._v("Connection Timeout,")]),
      _vm._v(" silahkan cek koneksi internet anda!"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }