<template>
  <main id="content" role="main" class="main">
    <div class="content container-fluid p-3 ps-0 py-0" ref="contentDiv">
      <b-modal
        v-model="modalOpen"
        title="List Harga"
        @ok="saveModalData"
        :ok-disabled="!this.modalInput.priceData"
        @hide="closeModal"
      >
        <!-- Modal content here, e.g., input fields to collect data -->
        <b-form-group
          label="Jenis Harga"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm="8"
          content-cols-lg="9"
          invalid-feedback="Condition Harus Diisi!"
        >
          <multiselect
            class="my-1"
            v-model="modalInput"
            :options="priceList"
            placeholder="Pilih Jenis Harga"
            :multiple="false"
            :show-labels="false"
             
            :custom-label="customLabel0"
          >
          </multiselect>
        </b-form-group>
        <template v-if="modalInput">
          <div class="row">
            <div class="col-sm-4 col-lg-3 text-dark">List Harga</div>
            <div class="col-sm-8 col-lg-9">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="text-center align-middle"><b>Min. Qty</b></th>
                    <th class="text-center align-middle"><b>Harga Satuan</b></th>
                    <th class="text-center align-middle"><b>Diskon</b></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="price in modalInput.priceData">
                    <td>{{ price.priceQty }}</td>
                    <td>{{ price.priceValue }}</td>
                    <td v-for="discount in price.priceDiscount">{{ discount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </b-modal>
      <b-modal
        v-model="modalOpenAddOn"
        title="List Add-On"
        @ok="saveModalDataAddOn"
        @hide="closeModalAddOn"
        ok-disabled="true"
      >
        <!-- Modal content here, e.g., input fields to collect data -->
        <b-form-group
          label="Jenis Add-On"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm="8"
          content-cols-lg="9"
          invalid-feedback="Condition Harus Diisi!"
        >
          <multiselect
            class="my-1"
            :options="emptyData"
            placeholder="Pilih Jenis Add-On"
            :multiple="false"
            :show-labels="false"
             
            :custom-label="customLabel0"
          >
          </multiselect>
        </b-form-group>
      </b-modal>
      <transaksiEditSOKlinik
        :dataStore="dataStore"
        :dataHidden="dataHidden"
        :Item="Item"
        :posts="posts"
        :component="refreshComponent"
        :hotSettings="hotSettings"
        :widthTable="contentDivWidth"
        :excelData="excelData"
        :refreshMethods="onRefresh"
      ></transaksiEditSOKlinik>
    </div>
  </main>
</template>

<script>
import { formatNumber, removeThousandFormatter } from "../../components/function/number";
import { getFullDate } from "../../components/function/date";
import {
  getFieldTable,
  getComponentAdd,
  dataTableAdd,
  refreshValue,
  getContentWidth,
} from "../../components/services/transaksi";
import {
  get1,
  getUser,
  getCustomer,
  getListItemSO,
} from "../../components/services/components";
import transaksiEditSOKlinik from "../../components/transaksi/transaksiEditSOKlinik.vue";

export default {
  components: {
    transaksiEditSOKlinik,
  },
  data() {
    return {
      Item: [],
      dataStore: {},
      dataShow: [],
      dataHidden: [],
      emptyData: [],
      currentDate: "",
      priceList: [],
      modalOpen: false,
      modalOpenAddOn: false,
      modalInput: [],
      modalRow: null,
      modalCol: null,
      Item: [],
      contentDivWidth: 0,
      excelData: [],
      hotSettings: {
        afterChange: this.refreshValue,
        licenseKey: "non-commercial-and-evaluation",
        height: 200,
        colHeaders: true,

        rowHeaderWidth: 35,
        startRows: 10,
        columns: [{ data: "tes", type: "numeric" }],
        // Other Handsontable settings
      },
      component: [],
      posts: [],
      itemTable: [],
    };
  },
  async mounted() {
    this.dataStore = JSON.parse(this.$route.query.data);
    this.currentDate = this.dataStore.transShipmentPlanDate;
    console.log("datanya :", this.dataStore);
    this.Item = await getListItemSO("product");

    this.posts = await get1("sales-order-klinik-edit");
    this.component = await getComponentAdd(this.posts);
    this.moreFunction();
    this.hotSettings.columns = await getFieldTable(this.component);
    this.hotSettings.columns.find((x) => x.data === "tableQty").width = 50;
    this.hotSettings.columns.find(
      (x) => x.data === "tablePriceCode"
    ).renderer = this.buttonRenderer;
    this.hotSettings.columns.find((x) => x.data === "tablePriceCode").readOnly = true;
    this.hotSettings.columns.find((x) => x.data === "tableUoM").readOnly = true;
    this.hotSettings.columns.find(
      (x, i) => x.data === "tableUoM"
    ).renderer = this.disabledRenderer;
    this.hotSettings.columns.find(
      (x, i) => x.data === "productCode"
    ).renderer = this.disabledRenderer;
    this.hotSettings.columns.find(
      (x, i) => x.data === "productName"
    ).renderer = this.disabledRenderer;
    this.hotSettings.columns.find(
      (x) => x.data === "tablePrice"
    ).renderer = this.numberRenderer;
    this.hotSettings.columns.find((x) => x.data === "tableTotal").readOnly = true;
    this.hotSettings.columns.find(
      (x) => x.data === "tableTotal"
    ).renderer = this.totalRenderer;
    this.hotSettings.columns.find((x) => x.data === "productCode").source = this.Item.map(
      (x) => x.itemCode
    );
    this.hotSettings.columns.find((x) => x.data === "productName").source = this.Item.map(
      (x) => x.itemName
    );
    await dataTableAdd(this.excelData, this.hotSettings.columns);
    const listData = this.dataStore.transPesanan.map((x) => ({
      ...x,
      productCode: x.itemCode,
      productName: x.itemName,
      itemTopping: x.itemToping,
      tableUoM: x.itemUm,
      tablePriceCode: x.itemMasterPrice[0].priceName,
      tablePrice: x.itemMasterPrice[0].priceValue,
      tableQty: x.itemQty,
    }));

    this.excelData = this.dataStore.transPesanan;
    console.log(this.excelData);
    this.onRefresh();
    this.contentDivWidth = await getContentWidth(
      this.$refs.contentDiv,
      this.hotSettings.columns
    );
  },
  computed: {
    refreshComponent() {
      return this.component.map((item) => ({
        ...item,
      }));
    },
  },
  methods: {
    formatNumber(data) {
      return formatNumber(data);
    },
    calculateDistribution(array, value) {
      // Sort the array in descending order based on priceQty
      array.sort((a, b) => parseFloat(b.priceQty) - parseFloat(a.priceQty));

      const distribution = [];
      let remainingValue = value;

      for (const item of array) {
        const priceQty = parseFloat(item.priceQty);
        const priceValue = parseFloat(item.priceValue.replace(/,/g, ""));

        if (remainingValue >= priceQty) {
          const count = Math.floor(remainingValue / priceQty);
          distribution.push({ priceQty, count });
          remainingValue -= count * priceQty;
        }
      }

      // Distribute remaining value among available quantities
      for (const item of array) {
        const priceQty = parseFloat(item.priceQty);

        if (remainingValue > 0 && remainingValue >= priceQty) {
          distribution.push({ priceQty, count: 1 });
          remainingValue -= priceQty;
        }
      }

      return distribution;
    },
    customLabel0(option) {
      return `${option.priceName} (${option.priceCode})`;
    },
    totalRenderer(instance, td, row, col, prop, value, cellProperties) {
      if (row < this.dataShow.length) {
        td.style.backgroundColor = "lightgray"; // Set a background color to indicate it's disabled
        td.style.pointerEvents = "none"; // Disable pointer events
        td.style.color = "gray"; // Change text color to indicate it's disabled
      }
      const rowData = this.excelData[row];
      let total = parseFloat(rowData.tableQty) * parseFloat(rowData.tablePrice);
      if (total <= 0 || isNaN(total)) {
        total = null;
      } else {
        total = formatNumber(total);
      }
      // Clear the cell content
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }

      // Create the buttons container element
      const buttonsContainer = document.createElement("div");
      buttonsContainer.className = "text-start";
      buttonsContainer.innerHTML = total;
      // Append the buttons container to the cell
      td.appendChild(buttonsContainer);

      // Add necessary CSS classes
      td.classList.add("htCenter"); // Adjust the class based on your styling needs
    },
    disabledRenderer(instance, td, row, col, prop, value, cellProperties) {
      if (row < this.dataShow.length) {
        td.style.backgroundColor = "lightgray"; // Set a background color to indicate it's disabled
        td.style.pointerEvents = "none"; // Disable pointer events
        td.style.color = "gray"; // Change text color to indicate it's disabled
      }
      // Clear the cell content
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }

      // Create the buttons container element
      const buttonsContainer = document.createElement("div");
      buttonsContainer.className = "text-start";
      buttonsContainer.innerHTML = value ? value : null;
      // Append the buttons container to the cell
      td.appendChild(buttonsContainer);

      // Add necessary CSS classes
      td.classList.add("htCenter"); // Adjust the class based on your styling needs
    },
    numberRenderer(instance, td, row, col, prop, value, cellProperties) {
      if (row < this.dataShow.length) {
        td.style.backgroundColor = "lightgray"; // Set a background color to indicate it's disabled
        td.style.pointerEvents = "none"; // Disable pointer events
        td.style.color = "gray"; // Change text color to indicate it's disabled
      }
      const rowData = this.excelData[row];
      // Clear the cell content
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }

      // Create the buttons container element
      const buttonsContainer = document.createElement("div");
      buttonsContainer.className = "text-start";
      buttonsContainer.innerHTML = formatNumber(rowData.tablePrice);
      // Append the buttons container to the cell
      td.appendChild(buttonsContainer);

      // Add necessary CSS classes
      td.classList.add("htCenter"); // Adjust the class based on your styling needs
    },
    buttonRenderer(instance, td, row, col, prop, value, cellProperties) {
      if (row < this.dataShow.length) {
        td.style.backgroundColor = "lightgray"; // Set a background color to indicate it's disabled
        td.style.pointerEvents = "none"; // Disable pointer events
        td.style.color = "gray"; // Change text color to indicate it's disabled
      }
      const rowData = this.excelData[row];
      // Clear the cell content
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }

      // Create the buttons container element
      const buttonsContainer = document.createElement("div");
      let classBody = "";
      if (rowData.tablePriceCode !== "" && rowData.tablePriceCode !== undefined) {
        classBody = "d-flex justify-content-between h-100 align-items-start";
      } else {
        classBody =
          "d-flex justify-content-between h-100 flex-row-reverse align-items-start";
      }
      buttonsContainer.className = classBody;
      // Create the first button
      buttonsContainer.innerHTML = rowData.tablePriceCode;
      const button1 = document.createElement("button");
      button1.type = "button";
      button1.innerHTML = '<i class="bi-search"></i>';
      button1.style = "margin-top: 2px";
      button1.className = "btn btn-xs btn-outline-secondary px-1 py-0 rounded-circle";
      button1.addEventListener("click", () => {
        // Handle button 1 click event
        // this.handleButtonClick(rowData, 1);
        this.openModal(rowData, row);
      });
      buttonsContainer.appendChild(button1);

      // Append the buttons container to the cell
      td.appendChild(buttonsContainer);

      // Add necessary CSS classes
      td.classList.add("htCenter"); // Adjust the class based on your styling needs
    },
    toppingRenderer(instance, td, row, col, prop, value, cellProperties) {
      if (row < this.dataShow.length) {
        td.style.backgroundColor = "lightgray"; // Set a background color to indicate it's disabled
        td.style.pointerEvents = "none"; // Disable pointer events
        td.style.color = "gray"; // Change text color to indicate it's disabled
      }
      let contentData = "";
      let classData = "";
      if (this.excelData[row].itemTopping === "") {
        this.excelData[row].itemTopping = [];
      }
      const rowData = this.excelData[row];

      if (rowData.itemTopping.length > 0) {
        contentData = rowData.itemTopping.length + "Add-on Dipilih";
        classData = "justify-content-between align-items-start";
      } else {
        contentData = "";
        classData = " justify-content-end align-items-start";
      }
      // Clear the cell content
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }

      // Create the buttons container element
      const buttonsContainer = document.createElement("div");
      buttonsContainer.className = "d-flex h-100 " + classData;
      // Create the first button
      buttonsContainer.innerHTML = contentData;
      const button1 = document.createElement("button");
      button1.type = "button";
      button1.innerHTML = '<i class="bi-search"></i>';
      button1.className = "btn btn-xs btn-outline-secondary px-1 py-0 rounded-circle";
      button1.addEventListener("click", () => {
        // Handle button 1 click event
        // this.handleButtonClick(rowData, 1);
        this.openModalAddOn(rowData, row);
      });
      buttonsContainer.appendChild(button1);

      // Append the buttons container to the cell
      td.appendChild(buttonsContainer);

      // Add necessary CSS classes
      td.classList.add("htCenter"); // Adjust the class based on your styling needs
    },
    openModal(data, row) {
      this.priceList = [];
      this.modalInput = [];
      this.modalRow = null;
      if (
        data.productCode !== "" &&
        data.productCode !== null &&
        data.tablePriceCode === ""
      ) {
        console.log(data);
        console.log(this.Item);
        this.priceList = this.Item.find(
          (x) => x.itemCode === data.productCode
        ).itemMasterPrice;
      } else if (
        data.itemCode !== "" &&
        data.itemCode !== null &&
        data.tablePriceCode !== ""
      ) {
        this.priceList = this.Item.find(
          (x) => x.itemCode === data.productCode
        ).itemMasterPrice;
        this.modalInput = this.Item.find(
          (x) => x.itemCode === data.productCode
        ).itemMasterPrice.find((x) => x.priceName === data.tablePriceCode);
      } else {
        this.priceList = [];
      }
      this.modalOpen = true;
      this.modalRow = row;
    },
    closeModal() {
      this.modalOpen = false;
      this.modalInput = "";
      this.modalRow = null;
    },
    openModalAddOn(data, row) {
      this.priceList = [];
      this.modalInput = [];
      this.modalRow = null;
      if (data.itemCode !== "" && data.itemCode !== null && data.tablePriceCode === "") {
        this.priceList = this.Item.find(
          (x) => x.itemCode === data.itemCode
        ).itemMasterPrice;
      } else if (
        data.itemCode !== "" &&
        data.itemCode !== null &&
        data.tablePriceCode !== ""
      ) {
        this.priceList = this.Item.find(
          (x) => x.itemCode === data.itemCode
        ).itemMasterPrice;
        this.modalInput = this.Item.find(
          (x) => x.itemCode === data.itemCode
        ).itemMasterPrice.find((x) => x.priceName === data.tablePriceCode);
      } else {
        this.priceList = [];
      }
      this.modalOpenAddOn = true;
      this.modalRow = row;
    },
    closeModalAddOn() {
      this.modalOpenAddOn = false;
      this.modalInput = "";
      this.modalRow = null;
    },
    saveModalDataAddOn() {
      console.log("tes");
    },
    saveModalData() {
      if (this.modalRow !== null) {
        const totalQty = parseFloat(this.excelData[this.modalRow].tableQty);
        let resultValue = null;
        if (totalQty > 0 && this.modalInput) {
          for (const item of this.modalInput.priceData) {
            const itemQty = parseFloat(item.priceQty);
            const itemValue = parseFloat(item.priceValue.replace(/,/g, ""));
            if (itemQty === totalQty) {
              resultValue = itemValue;
              break; // Exit the loop when an exact match is found
            } else if (itemQty < totalQty) {
              resultValue = itemValue;
            } else {
              break; // Exit the loop when we've passed the target value
            }
          }
          if (resultValue !== null) {
            this.excelData[this.modalRow].tablePriceCode = this.modalInput.priceName;
            this.excelData[this.modalRow].tablePrice = resultValue;
          } else {
            this.excelData[this.modalRow].tablePriceCode = this.modalInput.priceName;
            this.excelData[this.modalRow].tablePrice = this.priceList.find(
              (x) => x.priceCode === "default"
            ).priceData[0].priceValue;
          }
        } else {
          console.log("tes else", this.modalInput);
          if (
            this.modalInput &&
            this.modalInput.priceData.some((x) => x.priceQty === "1")
          ) {
            this.excelData[this.modalRow].tablePriceCode = this.modalInput.priceName;
            // this.excelData[this.modalRow].tablePrice = this.modalInput.priceData[0].priceValue
          } else {
            this.excelData[this.modalRow].tablePriceCode = this.modalInput.priceName;
            this.excelData[this.modalRow].tablePrice = this.priceList.find(
              (x) => x.priceCode === "default"
            ).priceData[0].priceValue;
          }
        }
        this.excelData[this.modalRow].tableTotal =
          this.excelData[this.modalRow].tableQty *
          this.excelData[this.modalRow].tablePriceCode;
        this.excelData = [...this.excelData];
      }
    },
    refreshValue(changes, source) {
      if (source === "edit" || source === "CopyPaste.paste") {
        this.getAllItem(changes);
      }
      this.onRefresh();
    },
    getAllItem(changes) {
      for (const change of changes) {
        const [row, prop, oldValue, newValue] = change;
        this.hotSettings.columns[5].type = "text";
        const baris = this.excelData[row];

        if (prop === "itemCode" && baris) {
          const findName = this.Item.find((x) => x.itemCode === baris.productCode);
          if (baris.itemCode !== null && findName !== undefined) {
            baris.productName = findName.itemName;
            baris.tableUoM = findName.itemUm;
            if (baris.productName === undefined) {
              baris.productName = null;
              baris.itemCode = null;
              baris.tableUoM = null;
            }
          } else {
            baris.productName = null;
            baris.itemCode = null;
            baris.tableUoM = null;
            baris.itemCode = null;
          }
          baris.tablePrice = "";
          baris.tablePriceCode = "";
          this.excelData = [...this.excelData];
        } else if (prop === "productName" && baris) {
          const findCode = this.Item.find((x) => x.itemName === baris.productName);
          if (baris.productName !== null && findCode !== undefined) {
            baris.productCode = findCode.itemCode;
            baris.tableUoM = findCode.itemUM;
          } else {
            baris.productName = null;
            baris.itemCode = null;
            baris.tableUoM = null;
          }
          baris.tablePrice = "";
          baris.tablePriceCode = "";
          this.excelData = [...this.excelData];
        } else if (prop === "tableQty" && baris) {
          if (baris.tableQty < 0) {
            baris.tableQty = null;
          } else if (isNaN(baris.tableQty)) [(baris.tableQty = null)];
          this.excelData = [...this.excelData];
          this.saveModalData();
        } else if (prop === "tablePrice" && baris) {
          baris.tablePriceCode = "-";
          this.excelData = [...this.excelData];
        } else {
          console.log("gagal");
        }
      }
    },
    async moreFunction() {
      this.component
        .filter((x) => x.componentName === "fieldHeader")
        .forEach((y) => {
          y.componentMicro.find((z) => {
            if (z.key === "sales") {
              z.value = this.dataStore.transKasir.userName;
            } else if (z.key === "customer") {
              z.value =
                this.dataStore.transIdMember.userCode +
                " - " +
                this.dataStore.transIdMember.userName;
            } else if (z.key === "orderDate") {
              z.value = this.dataStore.transDate;
            } else if (z.key === "shipPlan") {
              z.value = this.dataStore.transIdMember.userBirth;
            } else if (z.key === "orderNumber") {
              z.value = this.dataStore.transNoSO;
            } else if (z.key === "shipTo") {
              const data = this.dataStore.transIdMember.userAddress[0];
              z.value = data.nameAddr + ": " + data.location;
            }
          });
        });
    },
    onRefresh() {
      refreshValue(this.excelData, this.component);
    },
  },
};
</script>
