var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main", attrs: { id: "content", role: "main" } },
    [
      _c(
        "form",
        {
          ref: "contentDiv",
          staticClass: "content container-fluid p-3 ps-0 py-0",
          on: { submit: _vm.sendData },
        },
        [
          _c("div", { staticClass: "row my-1" }, [
            _c("div", { staticClass: "col-4 d-flex align-items-center" }, [
              _c("ul", { staticClass: "breadcrumb m-0" }, [
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: "/dashboard" } }, [
                      _c("a", { attrs: { href: "#" } }, [
                        _c("i", { staticClass: "bi-house-door" }),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: _vm.$route.path.replace("/add", "") } },
                      [
                        _c("a", { attrs: { href: "#" } }, [
                          _vm._v("Sales Invoice"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c("li", [_vm._v("Tambah")]),
              ]),
            ]),
            _vm._m(0),
            _c(
              "div",
              {
                staticClass:
                  "col-1 d-flex justify-content-end align-items-center",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "form-check form-switch d-flex align-items-center",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.checkedPajak,
                          expression: "checkedPajak",
                        },
                      ],
                      staticClass: "form-check-input",
                      staticStyle: { height: "17.5px", "margin-top": "1px" },
                      attrs: {
                        type: "checkbox",
                        id: "formSwitch1",
                        disabled: "",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.checkedPajak)
                          ? _vm._i(_vm.checkedPajak, null) > -1
                          : _vm.checkedPajak,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.checkedPajak,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.checkedPajak = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.checkedPajak = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.checkedPajak = $$c
                          }
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "formSwitch1" },
                      },
                      [_vm._v("Pajak")]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-3 d-flex justify-content-end align-items-center",
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: _vm.$route.path.replace("/add", "") } },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-outline-secondary btn-xs py-1 me-2",
                        attrs: { type: "button" },
                      },
                      [
                        _c("i", { staticClass: "bi-chevron-left" }),
                        _vm._v("Kembali"),
                      ]
                    ),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-xs py-1",
                    attrs: { type: "submit" },
                  },
                  [_vm._v("Simpan")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "main-data mt-4" }, [
            _c(
              "div",
              {
                staticClass: "row scale-9 position-relative",
                staticStyle: { width: "114%", "z-index": "1" },
              },
              [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "card h-100" }, [
                    _c("div", { staticClass: "row px-3 py-1" }, [
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(1),
                        _c(
                          "div",
                          { staticClass: "col-sm-8 col-md-9 pe-0" },
                          [
                            _c("multiselect", {
                              staticClass: "my-1",
                              attrs: {
                                options: _vm.listSupplier,
                                placeholder: "Select one",
                                multiple: false,
                                "show-labels": false,
                                required: "",
                                "custom-label": _vm.customLabel,
                                "track-by": "_id",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.changeKode(_vm.transSupplier)
                                },
                              },
                              model: {
                                value: _vm.transSupplier,
                                callback: function ($$v) {
                                  _vm.transSupplier = $$v
                                },
                                expression: "transSupplier",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(2),
                        _c(
                          "div",
                          { staticClass: "col-sm-8 col-md-9 pe-0" },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control-sm py-0 form-control",
                              attrs: {
                                size: "sm",
                                required: "",
                                placeholder: "Credit Terms",
                                disabled: "",
                              },
                              model: {
                                value: _vm.transCreditTerms,
                                callback: function ($$v) {
                                  _vm.transCreditTerms = $$v
                                },
                                expression: "transCreditTerms",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(3),
                        _c(
                          "div",
                          { staticClass: "col-sm-8 col-md-9 pe-0" },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control-sm py-0 form-control",
                              attrs: {
                                size: "sm",
                                type: "text",
                                required: "",
                                placeholder: "Masukan Nomor Referensi",
                              },
                              model: {
                                value: _vm.transNoFaktur,
                                callback: function ($$v) {
                                  _vm.transNoFaktur = $$v
                                },
                                expression: "transNoFaktur",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "card h-100" }, [
                    _c("div", { staticClass: "row px-3 py-1" }, [
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(4),
                        _c(
                          "div",
                          { staticClass: "col-sm-8 col-md-9 pe-0" },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control-sm py-0 form-control",
                              attrs: {
                                disabled: "",
                                size: "sm",
                                placeholder: "Kode Purchase Invoice",
                              },
                              model: {
                                value: _vm.transInvNo,
                                callback: function ($$v) {
                                  _vm.transInvNo = $$v
                                },
                                expression: "transInvNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(5),
                        _c(
                          "div",
                          { staticClass: "col-sm-8 col-md-9 pe-0" },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control-sm py-0 form-control",
                              attrs: {
                                size: "sm",
                                type: "date",
                                required: "",
                                placeholder: "Masukan Tanggal Transaksi",
                              },
                              model: {
                                value: _vm.transInvDate,
                                callback: function ($$v) {
                                  _vm.transInvDate = $$v
                                },
                                expression: "transInvDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row my-1 pe-0" }, [
                        _vm._m(6),
                        _c(
                          "div",
                          { staticClass: "col-sm-8 col-md-9 pe-0" },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control-sm py-0 form-control",
                              attrs: {
                                size: "sm",
                                type: "date",
                                required: "",
                                placeholder: "Masukan Tanggal Transaksi",
                              },
                              model: {
                                value: _vm.transDueDate,
                                callback: function ($$v) {
                                  _vm.transDueDate = $$v
                                },
                                expression: "transDueDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "row my-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "hot-container col-md-12 position-relative",
                    staticStyle: { "overflow-x": "auto", "z-index": "0" },
                  },
                  [
                    _c("hot-table", {
                      ref: "hot",
                      attrs: {
                        settings: _vm.hotSettings,
                        data: _vm.fixExcelData,
                        rowHeaders: true,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "row mb-3 d-flex justify-content-between" },
              [
                _c("div", { staticClass: "col-md-6 scale-9" }, [
                  _c("div", { staticClass: "h-100" }, [
                    _c("div", { staticClass: "card h-100" }, [
                      _c("div", { staticClass: "card-header bg-light py-1" }, [
                        _vm._v("Catatan"),
                      ]),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.transNotes,
                            expression: "transNotes",
                          },
                        ],
                        staticClass: "form-control form-control-sm py-1 h-100",
                        staticStyle: { resize: "none" },
                        attrs: { placeholder: "Masukan Catatan" },
                        domProps: { value: _vm.transNotes },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.transNotes = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "col-md-4 row p-0 scale-9",
                    staticStyle: { "transform-origin": "right top" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row d-flex justify-content-end" },
                      [
                        _vm.checkedPajak
                          ? [
                              _vm._m(7),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "p-0 border border-light bg-light pt-1 d-flex align-items-center justify-content-between col-7 pe-2",
                                },
                                [
                                  _c("input", {
                                    staticClass:
                                      "form-control form-control-sm py-1",
                                    staticStyle: { "text-align": "right" },
                                    attrs: { type: "text", disabled: "" },
                                    domProps: {
                                      value: _vm.formatNumberInt(
                                        _vm.totalPrice
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._m(8),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "p-0 border border-light bg-light pt-1 d-flex align-items-center justify-content-between col-7 pe-2",
                                },
                                [
                                  _c("input", {
                                    staticClass:
                                      "form-control form-control-sm py-1",
                                    staticStyle: { "text-align": "right" },
                                    attrs: { type: "text", disabled: "" },
                                    domProps: {
                                      value: _vm.formatNumberInt(_vm.totalPPN),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm._m(9),
                        _c(
                          "div",
                          {
                            staticClass:
                              "p-0 border border-light bg-light pt-1 col-7 d-flex align-items-center justify-content-between col-5 pe-2",
                          },
                          [
                            _c("input", {
                              staticClass: "form-control form-control-sm py-1",
                              staticStyle: { "text-align": "right" },
                              attrs: { type: "text", disabled: "" },
                              domProps: {
                                value: _vm.formatNumberInt(_vm.totalAmount),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.refreshMethods()
                                },
                              },
                            }),
                          ]
                        ),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _c(
            "b-modal",
            {
              attrs: {
                title: "List Good Receipt (PO Receipt)",
                "ok-disabled": _vm.modalInput.length < 1,
              },
              on: { ok: _vm.saveModalData, hide: _vm.closeModal },
              model: {
                value: _vm.modalOpen,
                callback: function ($$v) {
                  _vm.modalOpen = $$v
                },
                expression: "modalOpen",
              },
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Pilih GR :",
                    "label-cols-sm": "4",
                    "label-cols-lg": "3",
                    "content-cols-sm": "8",
                    "content-cols-lg": "9",
                    "invalid-feedback": "Condition Harus Diisi!",
                  },
                },
                [
                  _c("multiselect", {
                    staticClass: "my-1",
                    attrs: {
                      options: _vm.filterGR(_vm.optionsInvoice),
                      placeholder: "Pilih PO",
                      multiple: false,
                      "show-labels": false,
                      "custom-label": _vm.customLabelKode,
                    },
                    model: {
                      value: _vm.modalInput,
                      callback: function ($$v) {
                        _vm.modalInput = $$v
                      },
                      expression: "modalInput",
                    },
                  }),
                ],
                1
              ),
              _vm.modalInput.transHysNo
                ? _c("div", { staticClass: "col-12" }, [
                    _c("table", { staticClass: "table table-bordered" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("Nama"),
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("Qty"),
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("Harga"),
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("Due Date"),
                          ]),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.modalInput.transHysItem, function (data) {
                          return _c("tr", [
                            _c("td", [_vm._v(_vm._s(data.itemHysName))]),
                            _c("td", [_vm._v(_vm._s(data.itemHysQtyRcp))]),
                            _c("td", [
                              _vm._v(
                                "Rp. " +
                                  _vm._s(_vm.formatNumberInt(data.itemHysPrice))
                              ),
                            ]),
                            _c("td", [_vm._v(_vm._s(data.itemHysEffDate))]),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4 text-center" }, [
      _c("span", { staticClass: "h2" }, [_vm._v("Tambah Sales Invoice")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 col-md-3 d-flex justify-content-between" },
      [_vm._v("*Supplier"), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 col-md-3 d-flex justify-content-between" },
      [_vm._v("Credit Terms "), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 col-md-3 d-flex justify-content-between" },
      [_vm._v("*No. Faktur "), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 col-md-3 d-flex justify-content-between" },
      [_vm._v("*Kode Invoice"), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 col-md-3 d-flex justify-content-between" },
      [_vm._v("*Invoice Date "), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 col-md-3 d-flex justify-content-between" },
      [_vm._v("*Due Date "), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "border border-light bg-light d-flex align-items-center justify-content-between col-5",
      },
      [_vm._v("Sub Total "), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "border border-light bg-light d-flex align-items-center justify-content-between col-5",
      },
      [_vm._v("PPN (Rp.) "), _c("span", [_vm._v(":")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "border border-light bg-light d-flex align-items-center justify-content-between col-5",
      },
      [_vm._v("Grand Total "), _c("span", [_vm._v(":")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }