<template>
  <div style="position: fixed; bottom: 10px; right: 10px">
    <div
      v-if="showChat"
      class="bg-light bg-white border border-secondary rounded"
      style="width: 350px; height: 80vh"
    >
      <div
        class="chat-header p-2 d-flex justify-content-between rounded-top bg-info align-items-center border-bottom border-secondary"
      >
        <b class="text-white">Pesan</b>
        <i
          class="bi-x text-white"
          style="font-size: 20px; cursor: pointer"
          @click="showChat = false"
        ></i>
      </div>
      <div class="chatting-body p-1 pt-3">
        <div class="w-100">
          <div class="card">
            <div class="card-header p-2 fs-11 d-flex justify-content-between">
              <b>Azam Rizky Andrean</b>
              <b>22:10 - 22/10/2023</b>
            </div>
            <div class="card-body p-2">
              tes lorem ipsum dolot sit amet dan lain lain dan lain lain
            </div>
          </div>
          <hr class="my-2" />
          <div class="card">
            <div class="card-header p-2 fs-11 d-flex justify-content-between">
              <b>Azam Rizky Andrean</b>
              <b>22:10 - 22/10/2023</b>
            </div>
            <div class="card-body p-2">
              tes lorem ipsum dolot sit amet dan lain lain dan lain lain
            </div>
          </div>
          <hr class="my-2" />
        </div>
      </div>
    </div>
    <button
      v-else
      class="btn btn-lg btn-info rounded-circle"
      style="padding: 5px 10px"
      @click="showChat = !showChat"
    >
      <i class="bi-chat-text-fill" style="font-size: 20px"></i>
    </button>
  </div>
  <!-- <main id="content" role="main" class="main">
    <div id="app">
      <h1>Real-time and REST API Data</h1>
      <p>{{ restApiMessage }}</p>
      <ul>
        <li v-for="message in messages">{{ message.data }}</li>
      </ul>
    </div>
  </main> -->
</template>

<script>
import io from "socket.io-client";
export default {
  data() {
    return {
      showChat: false,
      restApiMessage: "",
      messages: [],
    };
  },
  mounted() {
    // REST API request
    // fetch("http://192.168.0.103:3008/pesan")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     this.restApiMessage = data.message;
    //   });
    // // WebSocket connection
    // const socket = io("http://192.168.0.103:3008");
    // socket.on("message", (message) => {
    //   console.log(message);
    //   // Update the messages array with real-time data
    //   this.messages.push(message);
    // });
  },
};
</script>
