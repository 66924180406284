var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("button", { on: { click: _vm.print } }, [_vm._v("Print")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }