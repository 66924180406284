<template>
  <main id="content" role="main" class="main">
    <!-- Content -->
  <transition name="fade" mode="out-in">
    <div v-if="isDataLoaded === ''" key="empty">
      <loading></loading>
    </div>

    <!-- Main Content -->
    <div v-else-if="isDataLoaded" key="truthy">
    <div class="content container-fluid">
      <div class="row">
        <div class="col-4 d-flex align-items-end">
          <ul class="breadcrumb m-1 mt-0">
            <li>
              <router-link to="/dashboard">
                <a href="#"><i class="bi-house-door"></i></a>
              </router-link>
            </li>
            <li>Pasien</li>
          </ul>
        </div>
        <div class="col-4 text-center">
          <span class="h2">List Master Pasien</span>
        </div>
        <hr class="mb-3 mt-1" />
      </div>
      <!-- Page Header -->
      <div class="card">
        <!-- Header -->
        <div class="card-header py-0 bg-custom-1">
          <b-row>
            <b-col class="d-flex align-items-center">
              <b-input-group size="sm" class="mx-2">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  pleaceholder="Type to Search"
                  @keyup.enter="doSearch"
                ></b-form-input>

                <b-input-group-append>
                  <b-button size="sm" :disabled="!filter" @click="doSearch"
                    >Cari</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col class="d-flex justify-content-end align-items-center">
              <b-button v-b-modal.modal-add variant="primary" size="sm"
                ><i class="bi-plus-circle"></i
              ></b-button>
              <b-dropdown
                id="dropdown-right"
                right
                text="Filter Column"
                variant="dark"
                class="m-2"
                size="sm"
              >
                <template #button-content>
                  <i class="bi-sliders"></i>
                </template>
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="selected"
                  :options="fields"
                  name="flavour-1"
                  buttons
                >
                </b-form-checkbox-group>
              </b-dropdown>
            </b-col>
          </b-row>
        </div>
        <!-- Main Table -->
        <b-container>
          <b-table
            ref="tableItem" class="fs-11"
            label-sort-asc=""
            label-sort-desc=""
            label-sort-clear=""
            :bordered="true"
            stacked="md"
            :items="listItem" :fields="activeFields" :current-page="currentPage" :per-page="perPage" :td-class="mainTable" v-if="selected.length > 0"
            show-empty
          >
            <template
              #cell(opsi)="row"
              class="justify-content-center"
              style="width: 100px"
            >
              <div width="0px">
                <b-dropdown
                class="button-y0"
                  id="dropdown-right"
                  right
                  text="Right align"
                  variant="primary"
                  size="xs"
                >
                  <template #button-content>
                    <i class="bi-gear-fill"></i>
                  </template>
                  <b-dropdown-item
                    href="#"
                    @click="info(row.item, row.index, $event.target)"
                    >Detail</b-dropdown-item
                  >
                  <b-dropdown-item 
                    href="#"
                    @click="edit(row.item, row.index, $event.target)"
                    >Edit</b-dropdown-item
                  >
                  <b-dropdown-item href="#" @click="listRM(row.item, row.index, $event.target)"
                    >List RM</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </template>
            <template #cell(userBirth)="data">
              <span v-if="data.value">{{ formatIndDate(data.value) }}</span>
            </template>
            <template #cell(userAddress)="data">
              <template v-for="address in data.value">
                <p class="m-0">
                  {{ address.location }}
                </p>
              </template>
            </template>
            <template #empty="scope">
              Data Kosong!
            </template>
          </b-table>
        </b-container>
        <b-modal id="ModalDetailRM" :title="'Detail RM Pasien : '+titleRM" size="lg">
      <!-- Modal content goes here -->
  <div>
    <div class="header d-flex align-items-center justify-content-between">
      <b>Data Umum</b>

      <b-button
      @click="printReport"
        class="button-y0"
        id="dropdown-right"
        right
        text="Right align"
        variant="primary" 
        size="xs"
      >
        <i class="bi-printer"></i>
      </b-button>
    </div>
  <printRM class="printData" :dataRM="detailDataRM" :data="dataRM"></printRM>
  </div>
    </b-modal>
        <b-modal id="ModalListRM" :title="'List RM Pasien : '+titleRM">
      <!-- Modal content goes here -->
  <div>
    <b-table striped hover :items="optionsRM" :fields="fieldsRM">
              <template #cell(action)="row">
                <div width="0px">
                <b-button 
                @click="detailRM(row.item, row.index, $event.target)"
                  class="button-y0"
                    id="dropdown-right"
                    right
                    text="Right align"
                    variant="primary"
                    size="xs">
                    <i class="bi-eye"></i>
                </b-button>
                </div>
              </template></b-table>
  </div>
    </b-modal>
        <b-modal
          :id="infoModal.id"
          :title="infoModal.title"
          ok-only
          @hide="resetInfoModal"
          no-close-on-esc
          no-close-on-backdrop
          hide-header-close
        >
          <div class="row" v-if="dataId !== '' && procId !== '' && microId === ''">
            <div class="col-4 d-flex justify-content-between"><span>Kode</span>:</div>
            <div class="col-8 ps-1">{{ infoModal.content.appProcCode }}</div>
            <div class="col-4 d-flex justify-content-between">
              <span>Nama Proses</span>:
            </div>
            <div class="col-8 ps-1">{{ infoModal.content.appProcName }}</div>
            <div class="col-4 d-flex justify-content-between">
              <span>Deskripsi</span>:
            </div>
            <div class="col-8 ps-1">{{ infoModal.content.appProcDesc }}</div>
            <div class="col-4 d-flex justify-content-between">
              <span>Group App</span>:
            </div>
            <div class="col-8 ps-1">{{ infoModal.content.appGroupMenu }}</div>
            <div class="col-4 d-flex justify-content-between">
              <span>Tanggal Dibuat</span>:
            </div>
            <div class="col-8 ps-1">{{ infoModal.content.appProcCreated }}</div>
            <div class="col-12 d-flex justify-content-center"></div>
            <div class="row py-2 mt-2 border-top">
              <div class="col-2"></div>
              <div class="col-8 text-center">
                <h1>Microservice</h1>
              </div>
              <div class="col-2 d-flex justify-content-center align-items-center"></div>
            </div>
            <b-table
              ref="tableMicro"
              :fields="microFields"
              :items="microservice"
              hover
              modal-scrollable
              class="table-bordered"
            >
              <template #cell(opsiMicro)="row">
                <div width="0px">
                  <b-dropdown
                  class="button-y0"
                    id="dropdown-right"
                    right
                    text="Right align"
                    variant="primary"
                    size="xs"
                  >
                    <template #button-content>
                      <i class="bi-gear-fill"></i>
                    </template>
                    <b-dropdown-item
                      href="#"
                      @click="infoMicro(row.item, row.index, $event.target)"
                      >Detail</b-dropdown-item
                    >
                    <b-dropdown-item
                      href="#"
                      @click="editMicroModal(row.item, row.index, $event.target)"
                      >Edit</b-dropdown-item
                    >
                    <b-dropdown-item
                      href="#"
                      @click="editMicroVar(row.item, row.index, $event.target)"
                      >Edit Variabel</b-dropdown-item
                    >
                    <b-dropdown-item href="#" @click="alertDelete">Hapus</b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
          </div>
          <div class="row" v-else-if="dataId !== '' && procId !== '' && microId !== ''">
            <h4>Detail Microservice</h4>
            <div class="col-4 d-flex justify-content-between"><span>Kode</span>:</div>
            <div class="col-8 ps-1">{{ microModal.content.userCode }}</div>
            <div class="col-4 d-flex justify-content-between">
              <span>Nama Microservise</span>:
            </div>
            <div class="col-8 ps-1">{{ microModal.content.userName }}</div>
            <div class="col-4 d-flex justify-content-between"><span>Tipe</span>:</div>
            <div class="col-8 ps-1">{{ microModal.content.microType }}</div>
            <div class="col-4 d-flex justify-content-between">
              <span>Deskripsi</span>:
            </div>
            <div class="col-8 ps-1">{{ microModal.content.microDescription }}</div>
            <div class="col-4 d-flex justify-content-between">
              <span>Placeholder</span>:
            </div>
            <div class="col-8 ps-1">{{ microModal.content.microPleaceholder }}</div>
            <div class="col-4 d-flex justify-content-between"><span>Icon</span>:</div>
            <div class="col-8 ps-1">{{ microModal.content.microIcon }}</div>
            <div class="col-4 d-flex justify-content-between"><span>Navigate</span>:</div>
            <div class="col-8 ps-1">{{ microModal.content.microNavigate }}</div>
            <div class="col-4 d-flex justify-content-between"><span>Routing</span>:</div>
            <div class="col-8 ps-1">{{ microModal.content.microRouting }}</div>
            <div class="col-4 d-flex justify-content-between">
              <span>API Function Script</span>:
            </div>
            <div class="col-8 ps-1">{{ microModal.content.microAPIFunctionScript }}</div>
            <div
              class="col-12 d-flex flex-wrap justify-content-between my-4 shadow p-3 bg-white rounded"
            >
              <h4 class="d-flex align-items-center m-0">Data Microservice</h4>
              <button
                type="button "
                class="btn btn-xs btn-outline-secondary"
                @click="microData = !microData"
              >
                <i v-if="microData" class="bi-chevron-down"></i>
                <i v-else class="bi-chevron-up"></i>
              </button>
              <pre v-if="microData" class="w-100 mt-2 mb-0"
                >{{ JSON.stringify(microModal.content.microData, null, " ") }}
                            </pre
              >
            </div>
            <div
              class="col-12 d-flex flex-wrap justify-content-between shadow p-3 bg-white rounded"
            >
              <h4 class="d-flex align-items-center m-0">Variabel Microservice</h4>
              <button
                type="button "
                class="btn btn-xs btn-outline-secondary"
                @click="microVariable = !microVariable"
              >
                <i v-if="microVariable" class="bi-chevron-down"></i>
                <i v-else class="bi-chevron-up"></i>
              </button>
              <pre v-if="microVariable" class="w-100 mt-2 mb-0"
                >{{ JSON.stringify(microModal.content.microVariable, null, " ") }}
                            </pre
              >
            </div>
          </div>
          <div class="row fs-11" v-else>
            <div class="row col-lg-4">
              <label
                class="avatar avatar-xxl overflow-hidden d-flex justify-content-center"
                for="editAvatarUploaderModal"
              >
                <img src="../assets/img/160x160/img1.jpg" alt="icon" />
              </label>
            </div>
            <div class="row col-lg-8">
              <div class="col-5 font-weight-bold d-flex justify-content-between"><span><b>Kode</b></span>:</div>
              <div class="col-7 ps-1">{{ infoModal.content.userCode }}</div>
              <div class="col-5 font-weight-bold d-flex justify-content-between"><span><b>Nama</b></span>:</div>
              <div class="col-7 ps-1">{{ infoModal.content.userName }}</div>
              <div class="col-5 font-weight-bold d-flex justify-content-between"><span><b>Tgl. Lahir</b></span>:</div>
              <div class="col-7 ps-1" v-if="infoModal.content">{{ formatIndDate(infoModal.content.userBirth) }}</div>
              <div class="col-7 ps-1" v-else></div>
              <div class="col-5 font-weight-bold d-flex justify-content-between">
                <span><b>No. NIK</b></span>:
              </div>
              <div class="col-7 ps-1">{{ infoModal.content.userNIK }}</div>
              <div class="col-5 font-weight-bold d-flex justify-content-between">
                <span><b>No. Telp</b></span>:
              </div>
              <div class="col-7 ps-1">{{ infoModal.content.userPhone }}</div>
              <div class="col-5 font-weight-bold d-flex justify-content-between">
                <span><b>Gender</b></span>:
              </div>
              <div class="col-7 ps-1">{{ infoModal.content.userGender }}</div>
              <!-- <div class="col-5 d-flex justify-content-between"><span>E-mail</span>:</div>
                            <div class="col-7 ps-1">{{ infoModal.content.userEmail }}</div> -->
              </div>
            <div class="col-lg-12 mt-3 row">
              <div class="col-4 pe-0 d-flex justify-content-between">
                <span><b>Agama</b></span>:
              </div>
              <div class="col-8">{{ infoModal.content.userReligion }}</div>
              <div class="col-4 pe-0 d-flex justify-content-between">
                <span><b>Pekerjaan</b></span>:
              </div>
              <div class="col-8">{{ infoModal.content.userJob }}</div>
              <div class="col-4 pe-0 d-flex justify-content-between">
                <span><b>Pend. Terakhir</b></span>:
              </div>
              <div class="col-8">{{ infoModal.content.userEducation }}</div>
              <div class="col-4 pe-0 d-flex justify-content-between"><span><b>Alamat</b></span>:</div>
              <div class="col-8">
                <template v-for="alamat in infoModal.content.userAddress">
                  <p class="m-0">{{ alamat.location }}</p>
                </template>
              </div>
              <div class="col-4 pe-0 d-flex justify-content-between">
                <span><b>Contact Person</b></span>:
              </div>
              <div class="col-8">
                <template v-for="(person, index) in infoModal.content.userContactPerson">
                  <p class="m-0">{{ index + 1 }}. {{ person.nama }} ({{ person.HP }})</p>
                </template>
              </div>
              </div>
          </div>
          <template #modal-footer>
            <div
              v-if="dataId !== '' && procId !== '' && microId !== ''"
              class="w-100 d-flex justify-content-end"
            >
              <b-button variant="secondary" size="sm" @click="microId = ''">
                Kembali
              </b-button>
            </div>
            <div
              v-else-if="dataId !== '' && procId !== '' && microId == ''"
              class="w-100 d-flex justify-content-end"
            >
              <b-button
                class="me-2"
                variant="secondary"
                size="sm"
                @click="$bvModal.hide('info-modal')"
              >
                Kembali
              </b-button>
              <b-button size="sm" variant="primary" @click="addMicroservice"
                >Tambah Micro</b-button
              >
            </div>
            <div v-else class="w-100 d-flex justify-content-end">
              <b-button
                class="me-2"
                variant="secondary"
                size="sm"
                @click="$bvModal.hide('info-modal')"
              >
                Kembali
              </b-button>
            </div>
          </template>
        </b-modal>
        <b-modal
          :id="procModal.id"
          :title="procModal.title"
          stacked
          @ok="addProcModal"
          ok-title="Tambah Proses"
          cancel-title="Kembali"
        >
          <div class="row">
            <!-- App Proc Table -->
            <b-table ref="tableProc" hover :fields="procFields" :items="process">
              <template #cell(opsiProc)="row" style="width: 100px">
                <div width="0px">
                  <b-dropdown
                  class="button-y0"
                    id="dropdown-right"
                    right
                    text="Right align"
                    variant="primary"
                    size="xs"
                  >
                    <template #button-content>
                      <i class="bi-gear-fill"></i>
                    </template>
                    <b-dropdown-item
                      href="#"
                      @click="infoProc(row.item, row.index, $event.target)"
                      >Detail</b-dropdown-item
                    >
                    <b-dropdown-item
                      href="#"
                      @click="editProcModal(row.item, row.index, $event.target)"
                      >Edit</b-dropdown-item
                    >
                    <b-dropdown-item href="#" @click="alertDelete">Hapus</b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
              {{ process }}
            </b-table>
          </div>
        </b-modal>

        <b-modal
          :id="editModal.id"
          :title="editModal.title"
          @hidden="resetEditModal"
          @ok="handleEdit"
          ok-variant="success"
          ok-title="Edit"
          cancel-title="Kembali"
        >
          <form ref="form" class="row" @submit.stop.prevent="handleEditSubmit">
            <div
              class="col-md-12 d-flex justify-content-center align-items-end pe-5 pt-5"
              style="min-height: 120px"
            >
              <div
                class="d-flex justify-content-center align-items-center flex-wrap h-100 pt-5"
              >
                <b-form-group
                  class="avatar avatar-xl avatar-circle avatar-uploader profile-cover-avatar bg-transparent d-flex align-items-center"
                  label-for="img1"
                  invalid-feedback="Tambah gambar dulu!"
                  @change="(event) => previewFiles(event)"
                  tabindex="-1"
                  :state="imageState"
                  style="border: none"
                >
                  <label
                    class="avatar avatar-xxl avatar-circle avatar-uploader profile-cover-avatar"
                    for="addImage"
                    @change="(event) => previewFiles(event)"
                    tabindex="-1"
                    invalid-feedback="Provinsi Harus Diisi!"
                    :state="imageState"
                    style="margin: 0"
                  >
                    <img
                      v-if="image !== ''"
                      id="editAvatarImgModal"
                      class="avatar-img"
                      :src="image"
                      alt="Image Description"
                    />
                    <img
                      v-else
                      id="editAvatarImgModal"
                      class="avatar-img"
                      src="../assets/img/160x160/img1.jpg"
                      alt="Image Description"
                    />
                    <input
                      tabindex="-1"
                      type="file"
                      class="js-file-attach avatar-uploader-input"
                      id="addImage"
                      :state="imageState"
                      required
                    />
                    <span class="avatar-uploader-trigger">
                      <i class="bi-pencil-fill avatar-uploader-icon shadow-sm"></i>
                    </span>
                  </label>
                  <b-form-input
                    tabindex="-1"
                    id="img1"
                    class="js-file-attach avatar-uploader-input"
                    v-model="image"
                    :state="imageState"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Kode Pasien</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group label-for="ItemCode">
                <b>{{ id }}</b>
              </b-form-group>
            </div>

            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">*Nama </h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="ItemName"
                invalid-feedback="Nama Pasien Harus Diisi!"
                :state="NameState"
              >
                <b-form-input
                  id="ItemName"
                  v-model="name"
                  :state="NameState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Tgl. Lahir </h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="userBirth"
              >
                <b-form-input
                  type="date"
                  id="userBirth"
                  v-model="moreData.userBirth"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">NIK</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="ItemType"
                invalid-feedback="No. NPWP Harus Diisi!"
                :state="typeState"
              >
                <b-form-input
                  id="ItemType"
                  v-model="moreData.nik"
                  :state="typeState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Telepon</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="ItemDesc"
                invalid-feedback="Nomor Telepon Harus Diisi!"
                :state="descState"
              >
                <b-input-group prepend="+62">
                  <b-form-input
                    id="ItemDesc"
                    v-model="desc"
                    :state="descState"
                    required
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Gender </h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="userGender"
              >
                  <select
                    class="userAddGender form-control"
                    aria-label="Default select example"
                    v-model="moreData.userGender"
                    :state="addressState"
                    required
                  >
                    <option value="Pria">Laki-Laki</option>
                    <option value="Wanita">Perempuan</option>
                  </select>
              </b-form-group>
            </div>            
            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Agama </h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="userReligion"
              >
                  <select
                    class="userAddGender form-control"
                    aria-label="Default select example"
                    v-model="moreData.userReligion"
                    :state="addressState"
                    required
                  >
                    <option value="" v-if="moreData.userReligion === ''">
                      Pilih Agama
                    </option>
                    <option value="Islam">Islam</option>
                    <option value="Kristen">Kristen</option>
                    <option value="Katolik">Katolik</option>
                    <option value="Hindu">Hindu</option>
                    <option value="Budhha">Budhha</option>
                    <option value="KongHuChu">Kong Hu Chu</option>
                  </select>
              </b-form-group>
            </div>
            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Pekerjaan </h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="userJob"
              >
                  <multiselect
                    id="userJob"
                    v-model="moreData.userJob"
                    :options="optionsJob"
                    placeholder="Pilih Satu"
                    :taggable="true"
                    @tag="addJob"
                    :multiple="false"
                     
                  >
                  </multiselect>
              </b-form-group>
            </div>
            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Pend. Terakhir</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="userEducation"
              >
                  <multiselect
                    id="userEducation"
                    v-model="moreData.userEducation"
                    :options="optionsEducation"
                    placeholder="Pilih Satu"
                    :taggable="true"
                    @tag="addEducation"
                    :multiple="false"
                     
                  >
                  </multiselect>
              </b-form-group>
            </div>
            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Alamat </h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="userAlamat"
              >
                <b-form-input
                  placeholder="Masukan Alamat Pasien"
                  id="userAlamat"
                  v-model="alamat"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-12 mt-4 d-none">
              <div
                class="px-2 bg-primary d-flex justify-content-between align-items-center"
              >
                <span class="h4 text-white m-0">*Alamat</span>
                <div class="d-flex align-items-center">
                  <button
                    type="button"
                    v-b-modal.modal-addAddress
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Tambah Alamat"
                  >
                    <i class="bi bi-plus text-white" style="font-size: 25px"></i>
                  </button>
                  <button
                    v-if="showAddress"
                    @click="showAddress = !showAddress"
                    type="button"
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Hide"
                  >
                    <i
                      class="bi bi-caret-up-square text-white"
                      style="font-size: 20px"
                    ></i>
                  </button>
                  <button
                    v-else
                    @click="showAddress = !showAddress"
                    type="button"
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Show"
                  >
                    <i
                      class="bi bi-caret-down-square text-white"
                      style="font-size: 20px"
                    ></i>
                  </button>
                </div>
              </div>
              <table v-if="showAddress" class="table border">
                <thead>
                  <tr>
                    <th scope="col">Nama</th>
                    <th scope="col">Lokasi</th>
                    <th scope="col" class="text-center">Opsi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(address, i) in optionAddress" :key="i">
                    <td>{{ address.nameAddr }}</td>
                    <td>{{ address.location }}</td>
                    <td class="text-center">
                      <button
                        type="button"
                        class="btn p-0"
                        @click="editAddress(address, i, $event.target)"
                      >
                        <i class="bi bi-pencil-square text-warning"></i>
                      </button>
                      <button type="button" class="btn p-0" @click="removeAddress(i)">
                        <i class="bi bi-trash text-danger"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-12 mt-4">
              <div
                class="px-2 bg-primary d-flex justify-content-between align-items-center"
              >
                <span class="h4 text-white m-0">Contact Person</span>
                <div class="d-flex align-items-center">
                  <button
                    tabindex="-1"
                    type="button"
                    v-b-modal.modal-addContact
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Tambah Contact Person"
                  >
                    <i class="bi bi-plus text-white" style="font-size: 25px"></i>
                  </button>
                  <button
                    tabindex="-1"
                    v-if="showContact"
                    @click="showContact = !showContact"
                    type="button"
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Hide"
                  >
                    <i
                      class="bi bi-caret-up-square text-white"
                      style="font-size: 20px"
                    ></i>
                  </button>
                  <button
                    v-else
                    @click="showContact = !showContact"
                    type="button"
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Show"
                  >
                    <i
                      class="bi bi-caret-down-square text-white"
                      style="font-size: 20px"
                    ></i>
                  </button>
                </div>
              </div>
              <table v-if="showContact" class="table border">
                <thead>
                  <tr>
                    <th class="align-middle" scope="col">Nama</th>
                    <th class="text-center align-middle" scope="col">No. Telp</th>
                    <th class="text-center align-middle" scope="col">
                      Hubungan Dengan Pasien
                    </th>
                    <th class="text-center align-middle" scope="col">Opsi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(contact, i) in optionContact" :key="i">
                    <td>{{ contact.nama }}</td>
                    <td class="text-center">{{ contact.HP }}</td>
                    <td class="text-center">{{ contact.HUB }}</td>
                    <td class="text-center">
                      <button
                        type="button"
                        class="btn p-0"
                        @click="editContact(contact, i, $event.target)"
                      >
                        <i class="bi bi-pencil-square text-warning"></i>
                      </button>
                      <button type="button" class="btn p-0" @click="removeContact(i)">
                        <i class="bi bi-trash text-danger"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-12 mt-4 d-none">
              <div class="px-2 bg-dark d-flex justify-content-between align-items-center">
                <span class="h4 text-white m-0">Payment Type</span>
                <div class="d-flex align-items-center">
                  <button
                    type="button"
                    v-b-modal.modal-addPayment
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Tambah Payment Type"
                  >
                    <i class="bi bi-plus text-white" style="font-size: 25px"></i>
                  </button>
                  <button
                    v-if="showPayment"
                    @click="showPayment = !showPayment"
                    type="button"
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Hide"
                  >
                    <i
                      class="bi bi-caret-up-square text-white"
                      style="font-size: 20px"
                    ></i>
                  </button>
                  <button
                    v-else
                    @click="showPayment = !showPayment"
                    type="button"
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Show"
                  >
                    <i
                      class="bi bi-caret-down-square text-white"
                      style="font-size: 20px"
                    ></i>
                  </button>
                </div>
              </div>
              <table v-if="showPayment" class="table border">
                <thead>
                  <tr>
                    <th scope="col">Tipe</th>
                    <th scope="col">Kode</th>
                    <th scope="col">Nama</th>
                    <th scope="col" class="text-center">Opsi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(payment, i) in optionPayment" :key="i">
                    <td>{{ payment.Type }}</td>
                    <td>{{ payment.Number }}</td>
                    <td>{{ payment.Name }}</td>
                    <td class="text-center">
                      <button
                        type="button"
                        class="btn p-0"
                        @click="editPayment(payment, i, $event.target)"
                      >
                        <i class="bi bi-pencil-square text-warning"></i>
                      </button>
                      <button type="button" class="btn p-0" @click="removePayment(i)">
                        <i class="bi bi-trash text-danger"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </b-modal>
        <b-modal
          :id="editProc.id"
          :title="editProc.title"
          no-close-on-esc
          no-close-on-backdrop
          hide-header-close
        >
          <form
            v-if="addressIndex !== ''"
            ref="form"
            class="row"
            @submit.stop.prevent="handleProcSubmit"
          >
            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Nama</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="toppName"
                invalid-feedback="Nama Harus Diisi!"
                :state="provState"
              >
                <b-form-input
                  id="toppName"
                  v-model="prov"
                  :state="provState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Lokasi</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="addrecLocation"
                invalid-feedback="Lokasi Harus Diisi!"
                :state="kabState"
              >
                <b-form-input
                  id="addrecLocation"
                  v-model="kab"
                  :state="kabState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </form>
          <form
            v-else-if="contactIndex !== ''"
            ref="form"
            class="row"
            @submit.stop.prevent="handleProcSubmit"
          >
            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Nama</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="toppName"
                invalid-feedback="Nama Harus Diisi!"
                :state="provState"
              >
                <b-form-input
                  id="toppName"
                  v-model="prov"
                  :state="provState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">No. Telepon</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="contactPhone"
                invalid-feedback="Harga Harus Diisi!"
                :state="kabState"
              >
                <b-input-group prepend="+62">
                  <b-form-input
                    type="number"
                    id="contactPhone"
                    v-model="kab"
                    :state="kabState"
                    required
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>

            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Hubungan Dengan Pasien</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="contactHub"
                invalid-feedback="Hubungan dengan pasien Harus Diisi!"
                :state="kecState"
              >
                <b-form-input
                  id="contactHub"
                  v-model="kec"
                  :state="kecState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </form>
          <form
            v-else-if="paymentIndex !== ''"
            ref="form"
            class="row"
            @submit.stop.prevent="handleProcSubmit"
          >
            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Tipe Payment</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="toppName"
                invalid-feedback="Nama Harus Diisi!"
                :state="provState"
              >
                <b-form-input
                  id="toppName"
                  v-model="prov"
                  :state="provState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Nomor Payment</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="paymentNumber"
                invalid-feedback="Nomor Pembayaran Harus Diisi!"
                :state="provState"
              >
                <b-form-input
                  id="paymentNumber"
                  type="number"
                  v-model="kab"
                  :state="provState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Nama Payment</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="paymentName"
                invalid-feedback="Nama Pembayaran Harus Diisi!"
                :state="provState"
              >
                <b-form-input
                  id="paymentName"
                  v-model="kec"
                  :state="provState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </form>
          <form
            ref="form"
            @submit.stop.prevent="handleMicroEdit"
            v-else-if="dataId !== '' && procId !== '' && microId !== ''"
          >
            <div v-if="!microVar" class="row">
              <div class="col-4 d-flex align-items-center justify-content-between">
                <h4 class="m-0">Nama</h4>
                <h3>:</h3>
              </div>
              <div class="col-md-8 my-1">
                <b-form-group
                  label-for="procName"
                  invalid-feedback="Nama Microservice Harus Diisi!"
                  :state="NameState"
                >
                  <b-form-input
                    id="procName"
                    v-model="name"
                    :state="NameState"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-4 d-flex align-items-center justify-content-between">
                <h4 class="m-0">Kode</h4>
                <h3>:</h3>
              </div>
              <div class="col-md-8 my-1">
                <b-form-group
                  label-for="procMicroCode"
                  invalid-feedback="Kode Microservice Harus Diisi!"
                  :state="descState"
                >
                  <b-input-group :prepend="this.infoModal.content.appProcCode + '.'">
                    <b-form-input
                      id="procMicroCode"
                      type="number"
                      v-model="microCode"
                      :state="descState"
                      required
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </div>

              <div class="col-4 d-flex align-items-center justify-content-between">
                <h4 class="m-0">Tipe</h4>
                <h3>:</h3>
              </div>
              <div class="col-md-8 my-1">
                <b-form-group
                  label-for="microType"
                  invalid-feedback="Tipe Microservice Harus Diisi!"
                  :state="typeState"
                >
                  <b-form-input
                    id="microType"
                    v-model="type"
                    :state="typeState"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-4 d-flex align-items-center justify-content-between">
                <h4 class="m-0">Deskripsi</h4>
                <h3>:</h3>
              </div>
              <div class="col-md-8 my-1">
                <b-form-group label-for="procDesc">
                  <b-form-input id="procDesc" v-model="desc"></b-form-input>
                </b-form-group>
              </div>

              <div class="col-4 d-flex align-items-center justify-content-between">
                <h4 class="m-0">Icon</h4>
                <h3>:</h3>
              </div>
              <div class="col-md-8 my-1">
                <b-form-group label-for="microIcon">
                  <b-form-input id="microIcon" v-model="image"></b-form-input>
                </b-form-group>
              </div>

              <div class="col-4 d-flex align-items-center justify-content-between">
                <h4 class="m-0">Placeholder</h4>
                <h3>:</h3>
              </div>
              <div class="col-md-8 my-1">
                <b-form-group label-for="microPleaceholder">
                  <b-form-input id="microPleaceholder" v-model="prov"></b-form-input>
                </b-form-group>
              </div>

              <div class="col-4 d-flex align-items-center justify-content-between">
                <h4 class="m-0">Routing</h4>
                <h3>:</h3>
              </div>
              <div class="col-md-8 my-1">
                <b-form-group label-for="microRouting">
                  <b-form-input id="microRouting" v-model="kab"></b-form-input>
                </b-form-group>
              </div>

              <div class="col-4 d-flex align-items-center justify-content-between">
                <h4 class="m-0">Navigate</h4>
                <h3>:</h3>
              </div>
              <div class="col-md-8 my-1">
                <b-form-group label-for="microNaviagate">
                  <b-form-input id="microNaviagate" v-model="kec"></b-form-input>
                </b-form-group>
              </div>

              <div class="col-4 d-flex align-items-center justify-content-between">
                <h4 class="m-0">API Function Script</h4>
                <h3>:</h3>
              </div>
              <div class="col-md-8 my-1">
                <b-form-group label-for="microFunctionScript">
                  <b-form-input id="microFunctionScript" v-model="prov"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-4 d-flex pt-3 justify-content-between">
                <h4 class="m-0">Data</h4>
                <h3>:</h3>
              </div>
              <div class="col-md-8 my-1">
                <b-form-group label-for="microId">
                  <b-form-textarea id="microId" v-model="id"></b-form-textarea>
                </b-form-group>
              </div>
            </div>

            <JsonEditorVue
              v-else
              style="height: 350px"
              v-model="arrayVariable"
              Mode="text"
              :options="{
                Mode: 'text',
                Modes: ['text'],
                onError: handleJsonError,
              }"
            ></JsonEditorVue>
          </form>
          <form ref="form" class="row" @submit.stop.prevent="handleProcEdit" v-else>
            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Nama Proses*</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="procName"
                invalid-feedback="Nama Proses Harus Diisi!"
                :state="NameState"
              >
                <b-form-input
                  id="procName"
                  v-model="name"
                  :state="NameState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Kode*</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="procCode"
                invalid-feedback="Kode Harus Diisi!"
                :state="descState"
              >
                <b-form-input
                  id="procCode"
                  v-model="id"
                  :state="descState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Group App*</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="procGroup"
                invalid-feedback="Group App Harus Diisi!"
                :state="typeState"
              >
                <b-form-input
                  id="procGroup"
                  v-model="type"
                  :state="typeState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Deskripsi</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group label-for="procDesc">
                <b-form-input id="procDesc" v-model="desc"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Routing*</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group
                label-for="procRouting"
                invalid-feedback="Routing Harus Diisi!"
                :state="provState"
              >
                <b-form-input
                  id="procRouting"
                  v-model="prov"
                  :state="provState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-4 d-flex align-items-center justify-content-between">
              <h4 class="m-0">Icon</h4>
              <h3>:</h3>
            </div>
            <div class="col-md-8 my-1">
              <b-form-group label-for="procIcon">
                <b-form-input id="procIcon" v-model="kab"></b-form-input>
              </b-form-group>
            </div>
          </form>
          <template #modal-footer v-if="addressIndex !== ''">
            <b-button variant="secondary" @click="resetEditArray">Kembali</b-button>
            <b-button variant="success" @click="saveEditAddress">Edit</b-button>
          </template>
          <template #modal-footer v-else-if="contactIndex !== ''">
            <b-button variant="secondary" @click="resetEditArray">Kembali</b-button>
            <b-button variant="success" @click="saveEditContact">Edit</b-button>
          </template>
          <template #modal-footer v-else-if="paymentIndex !== ''">
            <b-button variant="secondary" @click="resetEditArray">Kembali</b-button>
            <b-button variant="success" @click="saveEditPayment">Edit</b-button>
          </template>
          <template
            #modal-footer
            v-else-if="dataId !== '' && procId !== '' && microId !== ''"
          >
            <b-button variant="secondary" @click="resetEditModal">Kembali</b-button>
            <b-button variant="success" @click="handleMicroEdit">Edit</b-button>
          </template>
          <template #modal-footer v-else>
            <b-button variant="secondary" @click="resetEditModal">Kembali</b-button>
            <b-button variant="success" @click="handleProcEdit">Edit</b-button>
          </template>
        </b-modal>
        <b-modal
          id="modal-add"
          size="lg"
          title="Tambah Pasien"
          no-close-on-esc
          no-close-on-backdrop
          ref="modal"
          @show="showModal"
          @hidden="resetModal"
          @ok="handleOk"
          ok-title="Simpan"
          cancel-title="Kembali"
        >
          <form ref="form" class="row p-5" @submit.stop.prevent="handleSubmit">
            <div class="col-md-3 d-flex justify-content-center">
              <div
                class="d-flex justify-content-center align-items-center flex-wrap pb-3"
              >
                <b-form-group
                  class="avatar avatar-xxl avatar-circle avatar-uploader profile-cover-avatar bg-transparent d-flex align-items-center"
                  label-for="img1"
                  invalid-feedback="Tambah gambar dulu!"
                  @change="(event) => previewFiles(event)"
                  tabindex="-1"
                  :state="imageState"
                  style="border: none"
                >
                  <label
                    class="avatar avatar-xxl avatar-circle avatar-uploader profile-cover-avatar"
                    for="addImage"
                    @change="(event) => previewFiles(event)"
                    tabindex="-1"
                    invalid-feedback="Provinsi Harus Diisi!"
                    :state="imageState"
                    style="margin: 0"
                  >
                    <img
                    tabindex="-1"
                      v-if="image !== ''"
                      id="editAvatarImgModal"
                      class="avatar-img"
                      :src="image"
                      alt="Image Description"
                    />
                    <img
                    tabindex="-1"
                      v-else
                      id="editAvatarImgModal"
                      class="avatar-img"
                      src="../assets/img/160x160/img1.jpg"
                      alt="Image Description"
                    />
                    <input
                      tabindex="-1"
                      type="file"
                      class="js-file-attach avatar-uploader-input"
                      id="addImage"
                      :state="imageState"
                      required
                    />
                    <span class="avatar-uploader-trigger">
                      <i class="bi-pencil-fill avatar-uploader-icon shadow-sm"></i>
                    </span>
                  </label>
                  <b-form-input
                    tabindex="-1"
                    id="img1"
                    class="js-file-attach avatar-uploader-input"
                    v-model="image"
                    :state="imageState"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="col-9 row m-0 p-0">
              <div class="col-md-12 mb-2">
                Kode Pasien : <b>{{ contact }}</b>
                <b-form-group
                  label="Kode Pasien"
                  label-for="ItemName"
                  class="d-none"
                  invalid-feedback="Kode Pasien Harus Diisi!"
                  :state="contactState"
                >
                  <b-form-input
                    id="ItemName"
                    v-model="contact"
                    :state="contactState"
                    required
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 mb-2">
                <b-form-group
                  label="*Nama"
                  label-for="ItemName"
                  invalid-feedback="Nama Pasien Harus Diisi!"
                  :state="NameState"
                >
                  <b-form-input
                  placeholder="Masukan Nama Pasien"
                    id="ItemName"
                    v-model="name"
                    :state="NameState"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 mb-2">
                <b-form-group label="Tgl. Lahir" label-for="ItemBirth">
                  <b-form-input
                    type="date"
                    id="ItemBirth"
                    v-model="moreData.userBirth"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 mb-2 d-none">
                <b-form-group
                  label="No. NPWP"
                  label-for="ItemType"
                  invalid-feedback="No. NPWP Harus Diisi!"
                >
                  <b-form-input id="ItemType" v-model="type"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 mb-2">
                <b-form-group
                  label="*NIK"
                  label-for="ItemType"
                  invalid-feedback="No. NIK Harus Diisi!"
                  :state="typeState"
                >
                  <b-form-input
                  placeholder="Masukan No. NIK Pasien"
                    id="ItemType"
                    v-model="moreData.nik"
                    :state="typeState"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 mb-2">
                <b-form-group
                  label="*Nomor Telepon"
                  label-for="usahaTelp"
                  invalid-feedback="Nomor Telepon Harus Diisi!"
                  :state="descState"
                >
                  <b-input-group prepend="+62">
                    <b-form-input
                  placeholder="81xxxxxxxxx"
                      type="number"
                      id="usahaTelp"
                      v-model="desc"
                      :state="descState"
                      required
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-md-6 mb-2 d-none">
                <b-form-group
                  label="E-mail"
                  label-for="ItemContact"
                  invalid-feedback="E-mail Harus Diisi!"
                  :state="addressState"
                >
                  <b-form-input
                    id="ItemContact"
                    v-model="address"
                    :state="addressState"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 mb-2">
                <b-form-group label="Gender" label-for="userAddGender">
                  <select
                    class="userAddGender form-control"
                    aria-label="Default select example"
                    v-model="moreData.userGender"
                    :state="addressState"
                    required
                  >
                    <option value="Pria">Laki-Laki</option>
                    <option value="Wanita">Perempuan</option>
                  </select>
                </b-form-group>
              </div>
              <div class="col-md-6 mb-2">
                <b-form-group
                  label="Agama"
                  label-for="ItemContact"
                  invalid-feedback="E-mail Harus Diisi!"
                  :state="addressState"
                >
                  <select
                    class="userAddGender form-control"
                    aria-label="Default select example"
                    v-model="moreData.userReligion"
                    :state="addressState"
                    required
                  >
                    <option value="" v-if="moreData.userReligion === ''">
                      Pilih Agama
                    </option>
                    <option value="Islam">Islam</option>
                    <option value="Kristen">Kristen</option>
                    <option value="Katolik">Katolik</option>
                    <option value="Hindu">Hindu</option>
                    <option value="Budhha">Budhha</option>
                    <option value="KongHuChu">Kong Hu Chu</option>
                  </select>
                </b-form-group>
              </div>
              <div class="col-md-6 mb-2">
                <b-form-group label="Pekerjaan" label-for="ItemJob">
                  <multiselect
                    id="ItemJob"
                    v-model="moreData.userJob"
                    :options="optionsJob"
                    placeholder="Pilih Satu"
                    :taggable="true"
                    @tag="addJob"
                    :multiple="false"
                     
                  >
                  </multiselect>
                </b-form-group>
              </div>
              <div class="col-md-6 mb-2">
                <b-form-group label="Pendidikan Terakhir" label-for="ItemGrade">
                  <multiselect
                    id="ItemGrade"
                    v-model="moreData.userEducation"
                    :options="optionsEducation"
                    placeholder="Pilih Satu"
                    :taggable="true"
                    @tag="addEducation"
                    :multiple="false"
                     
                  >
                  </multiselect>
                </b-form-group>
              </div>
              <div class="col-md-12 mb-2">
                <b-form-group label="Alamat" label-for="ItemGrade">
                  <input class="form-control" v-model="alamat" placeholder="Masukan Alamat Pasien">
                  </input>
                </b-form-group>
              </div>
            </div>
            <div class="col-md-6 mt-4 d-none">
              <div
                class="px-2 bg-primary d-flex justify-content-between align-items-center"
              >
                <span class="h4 text-white m-0">*Alamat</span>
                <div class="d-flex align-items-center">
                  <button
                    type="button"
                    v-b-modal.modal-addAddress
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Tambah Alamat"
                  >
                    <i class="bi bi-plus text-white" style="font-size: 25px"></i>
                  </button>
                  <button
                    v-if="showAddress"
                    @click="showAddress = !showAddress"
                    type="button"
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Hide"
                  >
                    <i
                      class="bi bi-caret-up-square text-white"
                      style="font-size: 20px"
                    ></i>
                  </button>
                  <button
                    v-else
                    @click="showAddress = !showAddress"
                    type="button"
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Show"
                  >
                    <i
                      class="bi bi-caret-down-square text-white"
                      style="font-size: 20px"
                    ></i>
                  </button>
                </div>
              </div>
              <table v-if="showAddress" class="table border">
                <thead>
                  <tr>
                    <th scope="col">Nama</th>
                    <th scope="col">Lokasi</th>
                    <th scope="col" class="text-center">Opsi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(address, i) in optionAddress" :key="i">
                    <td>{{ address.nameAddr }}</td>
                    <td>{{ address.location }}</td>
                    <td class="text-center">
                      <button
                        type="button"
                        class="btn p-0"
                        @click="editAddress(address, i, $event.target)"
                      >
                        <i class="bi bi-pencil-square text-warning"></i>
                      </button>
                      <button type="button" class="btn p-0" @click="removeAddress(i)">
                        <i class="bi bi-trash text-danger"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-12 mt-4">
              <div
                class="px-2 bg-primary d-flex justify-content-between align-items-center"
              >
                <span class="h4 text-white m-0">Contact Person</span>
                <div class="d-flex align-items-center">
                  <button
                    tabindex="-1"
                    type="button"
                    v-b-modal.modal-addContact
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Tambah Contact Person"
                  >
                    <i class="bi bi-plus text-white" style="font-size: 25px"></i>
                  </button>
                  <button
                    tabindex="-1"
                    v-if="showContact"
                    @click="showContact = !showContact"
                    type="button"
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Hide"
                  >
                    <i
                      class="bi bi-caret-up-square text-white"
                      style="font-size: 20px"
                    ></i>
                  </button>
                  <button
                    v-else
                    @click="showContact = !showContact"
                    type="button"
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Show"
                  >
                    <i
                      class="bi bi-caret-down-square text-white"
                      style="font-size: 20px"
                    ></i>
                  </button>
                </div>
              </div>
              <table v-if="showContact" class="table border">
                <thead>
                  <tr>
                    <th class="align-middle" scope="col">Nama</th>
                    <th class="text-center align-middle" scope="col">No. Telp</th>
                    <th class="text-center align-middle" scope="col">
                      Hubungan Dengan Pasien
                    </th>
                    <th class="text-center align-middle" scope="col">Opsi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(contact, i) in optionContact" :key="i">
                    <td>{{ contact.nama }}</td>
                    <td class="text-center">{{ contact.HP }}</td>
                    <td class="text-center">{{ contact.HUB }}</td>
                    <td class="text-center">
                      <button
                        type="button"
                        class="btn p-0"
                        @click="editContact(contact, i, $event.target)"
                      >
                        <i class="bi bi-pencil-square text-warning"></i>
                      </button>
                      <button type="button" class="btn p-0" @click="removeContact(i)">
                        <i class="bi bi-trash text-danger"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-12 mt-4 d-none">
              <div class="px-2 bg-dark d-flex justify-content-between align-items-center">
                <span class="h4 text-white m-0">Payment Type</span>
                <div class="d-flex align-items-center">
                  <button
                    type="button"
                    v-b-modal.modal-addPayment
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Tambah Payment Type"
                  >
                    <i class="bi bi-plus text-white" style="font-size: 25px"></i>
                  </button>
                  <button
                    v-if="showPayment"
                    @click="showPayment = !showPayment"
                    type="button"
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Hide"
                  >
                    <i
                      class="bi bi-caret-up-square text-white"
                      style="font-size: 20px"
                    ></i>
                  </button>
                  <button
                    v-else
                    @click="showPayment = !showPayment"
                    type="button"
                    class="btn p-0"
                    data-toggle="tooltip"
                    title="Show"
                  >
                    <i
                      class="bi bi-caret-down-square text-white"
                      style="font-size: 20px"
                    ></i>
                  </button>
                </div>
              </div>
              <table v-if="showPayment" class="table border">
                <thead>
                  <tr>
                    <th scope="col">Tipe</th>
                    <th scope="col">Kode</th>
                    <th scope="col">Nama</th>
                    <th scope="col" class="text-center">Opsi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(payment, i) in optionPayment" :key="i">
                    <td>{{ payment.Type }}</td>
                    <td>{{ payment.Number }}</td>
                    <td>{{ payment.Name }}</td>
                    <td class="text-center">
                      <button
                        type="button"
                        class="btn p-0"
                        @click="editPayment(payment, i, $event.target)"
                      >
                        <i class="bi bi-pencil-square text-warning"></i>
                      </button>
                      <button type="button" class="btn p-0" @click="removePayment(i)">
                        <i class="bi bi-trash text-danger"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </b-modal>
        <b-modal
          id="modal-addMicro"
          size="lg"
          title="Tambah Microservice"
          no-close-on-esc
          no-close-on-backdrop
          ref="modal"
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleMicroOk"
          ok-title="Simpan"
          cancel-title="Kembali"
        >
          <form ref="form" class="row" @submit.stop.prevent="handleMicroSubmit">
            <div class="col-md-6 mb-2">
              <b-form-group
                class="h4 my-1"
                label="Nama Microservice"
                label-for="microName"
                invalid-feedback="Nama Microservice Harus Diisi!"
                :state="NameState"
              >
                <b-form-input
                  id="microName"
                  v-model="name"
                  :state="NameState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 mb-2">
              <b-form-group
                class="h4 my-1"
                label="Tipe Microservice"
                label-for="microType"
                invalid-feedback="Tipe Microservice Harus Diisi!"
                :state="typeState"
              >
                <b-form-input
                  id="microType"
                  v-model="type"
                  :state="typeState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 mb-2">
              <b-form-group
                class="h4 my-1"
                label="Deskripsi Microservice"
                label-for="microDesc"
              >
                <b-form-input id="microDesc" v-model="desc"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 mb-2">
              <b-form-group
                class="h4 my-1"
                label="icon Microservice"
                label-for="microImage"
              >
                <b-form-input id="microImage" v-model="image"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 mb-2">
              <b-form-group
                class="h4 my-1"
                label="Placeholder Microservice"
                label-for="microPleaceholder"
              >
                <b-form-input id="microPleaceholder" v-model="prov"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 mb-2">
              <b-form-group
                class="h4 my-1"
                label="Routing Microservice"
                label-for="microRouting"
              >
                <b-form-input id="microRouting" v-model="kab"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 mb-2">
              <b-form-group
                class="h4 my-1"
                label="Navigasi Microservice"
                label-for="microNavigate"
              >
                <b-form-input id="microNavigate" v-model="kec"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 mb-2">
              <b-form-group
                class="h4 my-1"
                label="API Function Script"
                label-for="microAddress"
              >
                <b-form-input id="microAddress" v-model="address"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-3 mb-2">
              <b-form-group
                class="h4 my-1"
                label="Kode Microservice"
                label-for="microData"
                invalid-feedback="Kode Microservice Harus Diisi!"
                :state="descState"
              >
                <b-input-group :prepend="this.infoModal.content.appProcCode + '.'">
                  <b-form-input
                    id="microData"
                    type="number"
                    v-model="microCode"
                    :state="descState"
                    required
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-md-9 mb-2">
              <b-form-group
                class="h4 my-1"
                label="Data Microservice"
                label-for="microData"
              >
                <b-form-textarea id="microData" v-model="id"></b-form-textarea>
              </b-form-group>
            </div>
          </form>
        </b-modal>
        <b-modal
          id="modal-addProc"
          size="sm"
          title="Tambah Proses"
          hide-header-close
          no-close-on-esc
          no-close-on-backdrop
          ref="modal"
          @show="resetModal"
          @hidden="resetProcDetail"
          @ok="handleOkProc"
          ok-title="Simpan"
          cancel-title="Kembali"
        >
          <form ref="form" class="row" @submit.stop.prevent="handleSubmitProc">
            <div class="col-md-12 mb-2">
              <b-form-group
                label="Nama Proses"
                label-for="ItemName"
                invalid-feedback="Nama Proc Harus Diisi!"
                :state="NameState"
              >
                <b-form-input
                  id="ItemName"
                  v-model="name"
                  :state="NameState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-12 mb-2">
              <b-form-group
                label="Kode"
                label-for="ItemKode"
                invalid-feedback="Kode Harus Diisi!"
                :state="descState"
              >
                <b-form-input
                  id="ItemKode"
                  v-model="id"
                  :state="descState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-12 mb-2">
              <b-form-group
                label="Group App"
                label-for="ItemType"
                invalid-feedback="Group App Harus Diisi!"
                :state="typeState"
              >
                <b-form-input
                  id="ItemType"
                  v-model="type"
                  :state="typeState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-12 mb-2">
              <b-form-group label="Deskripsi" label-for="ItemDesc">
                <b-form-input id="ItemDesc" v-model="desc"></b-form-input>
              </b-form-group>
            </div>
          </form>
        </b-modal>
        <b-modal
          id="modal-addAddress"
          size="sm"
          title="Tambah Alamat"
          hide-header-close
          no-close-on-esc
          no-close-on-backdrop
          ref="modal"
          @hidden="resetArray"
          @ok="handleOkAddress"
        >
          <form ref="form" class="row" @submit.stop.prevent="handleSubmitAddress">
            <div class="col-md-12 mb-2">
              <b-form-group
                label="Nama "
                label-for="addressName"
                invalid-feedback="Nama Alamat Harus Diisi!"
                :state="provState"
              >
                <b-form-input
                  id="addressName"
                  v-model="prov"
                  :state="provState"
                  required
                  placeholder="Masukan Nama Alamat"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-12 mb-2">
              <b-form-group
                label="Alamat Lokasi"
                label-for="usahaLokasi"
                invalid-feedback="Lokasi Harus Diisi!"
                :state="kabState"
              >
                <textarea
                  class="form-control"
                  id="usahaLokasi"
                  v-model="kab"
                  :state="kabState"
                  required
                  rows="4"
                ></textarea>
              </b-form-group>
            </div>
          </form>
        </b-modal>
        <b-modal
          id="modal-addContact"
          size="sm"
          title="Tambah Contact Person"
          hide-header-close
          no-close-on-esc
          no-close-on-backdrop
          ref="modal"
          @hidden="resetArray"
          @ok="handleOkContact"
        >
          <form ref="form" class="row" @submit.stop.prevent="handleSubmitContact">
            <div class="col-md-12 mb-2">
              <b-form-group
                label="Nama Contact Person"
                label-for="contactName"
                invalid-feedback="Nama Contact Person Harus Diisi!"
                :state="provState"
              >
                <b-form-input
                  id="contactName"
                  v-model="prov"
                  :state="provState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-12 mb-2">
              <b-form-group
                label="Nomor Telepon"
                label-for="usahaTelp"
                invalid-feedback="Nomor Telepon Harus Diisi!"
                :state="kabState"
              >
                <b-input-group prepend="+62">
                  <b-form-input
                    type="number"
                    id="usahaTelp"
                    v-model="kab"
                    :state="kabState"
                    required
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-md-12 mb-2">
              <b-form-group
                label="Hubungan Dengan Pasien"
                label-for="hubPasien"
                invalid-feedback="Hubungan Dengan Pasien Harus Diisi!"
                :state="kecState"
              >
                <b-form-input
                  id="hubPasien"
                  v-model="kec"
                  :state="kecState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </form>
        </b-modal>
        <b-modal
          id="modal-addPayment"
          size="sm"
          title="Tambah Payment Type"
          hide-header-close
          no-close-on-esc
          no-close-on-backdrop
          ref="modal"
          @hidden="resetArray"
          @ok="handleOkPayment"
        >
          <form ref="form" class="row" @submit.stop.prevent="handleSubmitPayment">
            <div class="col-md-12 mb-2">
              <b-form-group
                label="Tipe Pembayaran"
                label-for="paymentType"
                invalid-feedback="Tipe Pembayaran Harus Diisi!"
                :state="provState"
              >
                <b-form-input
                  id="paymentType"
                  v-model="prov"
                  :state="provState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-12 mb-2">
              <b-form-group
                label="Nomor Pembayaran"
                label-for="usahaTelp"
                invalid-feedback="Nomor Pembayaran Harus Diisi!"
                :state="kabState"
              >
                <b-form-input
                  type="number"
                  id="usahaTelp"
                  v-model="kab"
                  :state="kabState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-12 mb-2">
              <b-form-group
                label="Nama"
                label-for="paymentName"
                invalid-feedback="Nama Pembayaran Harus Diisi!"
                :state="kecState"
              >
                <b-form-input
                  id="paymentName"
                  v-model="kec"
                  :state="kecState"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </form>
        </b-modal>
        <b-row class="my-3">
          <b-col sm="5" md="6" class="my-1">
            <span class="px-2">Show</span>
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
            Data of {{ totalRows }} Data
          </b-col>
          <b-col sm="7" md="6" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
    </div>

    <!-- Error -->
    <div v-else key="other">
      <connection-timeout></connection-timeout>
    </div>
  </transition>
  </main>
</template>

<script>
import axios from "axios";
import printRM from "../components/print/printRM.vue";
import {
  getListRMAll,
  getCustomer,
  addUserPasien,
  updateCustomer,
  getListDistinc,
  postFilterUser
} from "../components/services/components";
import { getUserGenNumber } from "../components/services/transComponent";
import { formatIndDate } from "../components/function/date";
import { addProc, addMicro, updateProc, updateMicro } from "./services/usaha";

  import loading from "../components/loading.vue";
  import connectionTimeout from "../components/connectionTimeout.vue";
  export default {
    components: {
      loading,
      connectionTimeout,
      printRM
    },
    data() {
      return {
        detailDataRM: '',
        titleRM: '',
        fieldsRM: [
          { key: 'woItemName', label: 'Jenis Pemeriksaan' },
          { key: 'woNo', label: 'Kode' },
          { key: 'woStartDate', label: 'Tgl. Periksa' },
          { key: 'action', label: 'Opsi'}
        ],
        optionsRM: [],
        filteredData: "",
        filterField: "",
        isDataLoaded: "",
      moreData: {
        nik: "",
        code: "",
        userGender: "",
        userReligion: "",
        userJob: "",
        userEducation: "",
        userBirth: "",
      },
      showAddress: true,
      showContact: true,
      showPayment: true,
      optionAddress: [],
      optionContact: [],
      optionPayment: [],
      optionsJob: [],
      optionsEducation: [],
      addressIndex: "",
      contactIndex: "",
      paymentIndex: "",

      microData: true,
      microVariable: false,
      procFields: [
        { key: "appProcCode", label: "Kode" },
        { key: "appGroupMenu", label: "Group" },
        { key: "appProcName", label: "Nama" },
        { key: "appProcDesc", label: "Deskripsi" },
        { key: "opsiProc", label: "Opsi" },
      ],
      microFields: [
        { key: "microCode", label: "Kode" },
        { key: "microName", label: "Nama" },
        { key: "microType", label: "Tipe" },
        { key: "microDescription", label: "Deskripsi" },
        { key: "opsiMicro", label: "Opsi" },
      ],

      listLogo: [],
      dataId: "",
      procId: "",
      microId: "",
      dataIndex: "",
      procIndex: "",
      microIndex: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 15, 25],
      listFields: [
        "userName",
        "userCode",
        "userNIK",
        "userPhone",
        "userAddress",
        "userBirth",
        "userGender"
      ],
      fields: [
        "userName",
        "userCode",
        "userNIK",
        "userPhone",
        "userAddress",
        "userBirth",
        "userGender",
        "opsi",
      ],
      sorts: [],
      selected: [
        "userName",
        "userCode",
        "userNIK",
        "userPhone",
        "userAddress",
        "userBirth",
        "userGender",
        "opsi",
      ],
      Item: [],
      process: [],
      microservice: [],
      filter: "",
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      editModal: {
        id: "edit-modal",
        title: "",
        content: "",
      },
      editProc: {
        id: "edit-modal-proc",
        title: "",
        content: "",
      },
      procModal: {
        id: "proc-modal",
        title: "",
        content: "",
      },
      microModal: {
        id: "micro-modal",
        title: "",
        content: "",
      },
      microVar: false,
      arrayVariable: [],
      options: [],
      microCode: "",
      id: "",
      name: "",
      type: "",
      desc: "",
      prov: "",
      kab: "",
      kec: "",
      address: "",
      image: "",
      contact: "",
      alamat: "",
      xFilEkey: null,

      contactState: null,
      NameState: null,
      typeState: null,
      descState: null,
      provState: null,
      kabState: null,
      kecState: null,
      addressState: null,
      imageState: null,
    };
  },
  methods: {
    getReportContent() {
      const reportView = document.querySelector('.printData');
      if (reportView) {
        return reportView.innerHTML;
      }
      return 'No content found.';
    },
    printReport() {
      console.log('ini print',this.getReportContent())
      const printWindow = window.open('', '_blank');
      const printDocument = `
        <html>
          <head>
	          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet">
          <style>
th {
  font-weight: 500
}
tr {
  font-size: 10px
}
          </style>
            </head>

          <body>
            ${this.getReportContent()}
          </body>
        </html>
      `;
      printWindow.document.write(printDocument);
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
        printWindow.close();
      };
    },
    async doSearch() {
      let hasil
      const data = {
        searchParams: {
          $or: [
            {userName: this.filter},
            {userCode: this.filter},
            {userNIK: this.filter},
            {userPhone: this.filter},
            {userAddress: this.filter},
            {userBirth: this.filter},
            {userGender: this.filter}
          ]
        }
      }
      try {
        hasil = await postFilterUser(data)
      } catch (error) {
        hasil = ""
      }
      this.filteredData = hasil
    },
    formatIndDate(data) {
      return formatIndDate(data);
    },
    generateIdChart(length) {
      const numChars = "0123456789";
      const alphaChars = "abcdefghijklmnopqrstuvwxyz";
      const characters = numChars.repeat(0.8 * length) + alphaChars.repeat(0.2 * length);
      let result = "";

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }

      return result;
    },
    addJob(newCat) {
      this.optionsJob.push(newCat);
      this.moreData.userJob = newCat;
    },
    addEducation(newCat) {
      this.optionsEducation.push(newCat);
      this.moreData.userEducation = newCat;
    },
    async showModal() {
      this.resetModal();
      this.contact = await getUserGenNumber();
    },
    editPhone(phone) {
      if (phone.length > 0) {
        return phone.slice(1);
      } else {
        return phone;
      }
    },
    removeAddress(index) {
      this.$swal({
        title: "Hapus Address?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Hapus!",
        cancelButtonText: "Kembali",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: "success",
            title: "Data terhapus!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.optionAddress.splice(index, 1);
        }
      });
    },
    handleOkAddress(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmitAddress();
    },
    handleSubmitAddress() {
      if (this.prov && this.kab) {
        const data = {
          nameAddr: this.prov,
          location: this.kab,
        };
        this.optionAddress.push(data);
        this.$swal({
          icon: "success",
          title: "Address Ditambahkan!",
          showConfirmButton: false,
          timer: 1500,
        });
        this.$nextTick(() => {
          this.$bvModal.hide("modal-addAddress");
        });
      }
      // add validation
      if (!this.prov) {
        this.provState = false;
      } else {
        this.provState = true;
      }
      if (!this.kab) {
        this.kabState = false;
      } else {
        this.kabState = true;
      }
    },
    editAddress(item, index, button) {
      this.editProc.title = "Edit Address";
      this.$root.$emit("bv::show::modal", this.editProc.id, button);
      this.addressIndex = index;
      const addressContent = this.optionAddress[index];
      this.prov = addressContent.nameAddr;
      this.kab = addressContent.location;
    },
    saveEditAddress() {
      if (this.prov && this.kab) {
        const topp = this.optionAddress[this.addressIndex];
        const data = {
          nameAddr: this.prov,
          location: this.kab,
        };
        this.optionAddress[this.addressIndex] = data;
        this.$swal({
          icon: "success",
          title: "Address Diedit!",
          showConfirmButton: false,
          timer: 1500,
        });
        this.$nextTick(() => {
          this.$bvModal.hide("edit-modal-proc");
        });
      }
      // add validation
      if (!this.prov) {
        this.provState = false;
      } else {
        this.provState = true;
      }
      if (!this.kab) {
        this.kabState = false;
      } else {
        this.kabState = true;
      }
    },

    removeContact(index) {
      this.$swal({
        title: "Hapus Contact?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Hapus!",
        cancelButtonText: "Kembali",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: "success",
            title: "Data terhapus!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.optionContact.splice(index, 1);
        }
      });
    },
    handleOkContact(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmitContact();
    },
    handleSubmitContact() {
      if (this.prov && this.kab && this.kec) {
        const data = {
          nama: this.prov,
          HP: "0" + this.kab,
          HUB: this.kec,
        };
        this.optionContact.push(data);
        this.$swal({
          icon: "success",
          title: "Contact Ditambahkan!",
          showConfirmButton: false,
          timer: 1500,
        });
        this.$nextTick(() => {
          this.$bvModal.hide("modal-addContact");
        });
      }
      // add validation
      if (!this.prov) {
        this.provState = false;
      } else {
        this.provState = true;
      }
      if (!this.kab) {
        this.kabState = false;
      } else {
        this.kabState = true;
      }
      if (!this.kec) {
        this.kecState = false;
      } else {
        this.kecState = true;
      }
    },
    editContact(item, index, button) {
      this.editProc.title = "Edit Contact";
      this.$root.$emit("bv::show::modal", this.editProc.id, button);
      this.contactIndex = index;
      const contactContent = this.optionContact[index];
      this.prov = contactContent.nama;
      this.kab = this.editPhone(contactContent.HP);
      this.kec = contactContent.HUB;
    },
    saveEditContact() {
      if (this.prov && this.kab && this.kec) {
        const topp = this.optionContact[this.contactIndex];
        const data = {
          nama: this.prov,
          HP: "0" + this.kab,
          HUB: this.kec,
        };
        this.optionContact[this.contactIndex] = data;
        this.$swal({
          icon: "success",
          title: "Address Diedit!",
          showConfirmButton: false,
          timer: 1500,
        });
        this.$nextTick(() => {
          this.$bvModal.hide("edit-modal-proc");
        });
      }
      // add validation
      if (!this.prov) {
        this.provState = false;
      } else {
        this.provState = true;
      }
      if (!this.kab) {
        this.kabState = false;
      } else {
        this.kabState = true;
      }
      if (!this.kec) {
        this.kecState = false;
      } else {
        this.kecState = true;
      }
    },

    removePayment(index) {
      this.$swal({
        title: "Hapus Payment Type?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Hapus!",
        cancelButtonText: "Kembali",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: "success",
            title: "Data terhapus!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.optionPayment.splice(index, 1);
        }
      });
    },
    handleOkPayment(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmitPayment();
    },
    handleSubmitPayment() {
      if (this.prov && this.kab && this.kec) {
        const data = {
          Type: this.prov,
          Number: this.kab,
          Name: this.kec,
        };
        this.optionPayment.push(data);
        this.$swal({
          icon: "success",
          title: "Payment Type Ditambahkan!",
          showConfirmButton: false,
          timer: 1500,
        });
        this.$nextTick(() => {
          this.$bvModal.hide("modal-addPayment");
        });
      }
      // add validation
      if (!this.prov) {
        this.provState = false;
      } else {
        this.provState = true;
      }
      if (!this.kab) {
        this.kabState = false;
      } else {
        this.kabState = true;
      }
      if (!this.kec) {
        this.kecState = false;
      } else {
        this.kecState = true;
      }
    },
    editPayment(item, index, button) {
      this.editProc.title = "Edit Contact";
      this.$root.$emit("bv::show::modal", this.editProc.id, button);
      this.paymentIndex = index;
      const paymentContent = this.optionPayment[index];
      this.prov = paymentContent.Type;
      this.kab = paymentContent.Number;
      this.kec = paymentContent.Name;
    },
    saveEditPayment() {
      4;
      if (this.prov && this.kab && this.kec) {
        const topp = this.optionPayment[this.contactIndex];
        const data = {
          Type: this.prov,
          Number: this.kab,
          Name: this.kec,
        };
        this.optionPayment[this.paymentIndex] = data;
        this.$swal({
          icon: "success",
          title: "Payment Type Diedit!",
          showConfirmButton: false,
          timer: 1500,
        });
        this.$nextTick(() => {
          this.$bvModal.hide("edit-modal-proc");
        });
      }
      // add validation
      if (!this.prov) {
        this.provState = false;
      } else {
        this.provState = true;
      }
      if (!this.kab) {
        this.kabState = false;
      } else {
        this.kabState = true;
      }
      if (!this.kec) {
        this.kecState = false;
      } else {
        this.kecState = true;
      }
    },

    mainTable() {
      return "d-flex";
    },
    handleJsonError(err) {
      console.error("JSON editor error:", err);
    },
    convertToJson() {
      try {
        const lines = this.id.trim().split("\n").replace('"', "");
        lines.forEach((line) => {
          const array = line.split(",");
          this.parsedArrays.push(array);
        });
      } catch (error) {
        console.error(error);
      }
    },
    getLogo(imageUrls) {
      Promise.all(
        imageUrls.map((url) =>
          axios
            .get(url, {
              responseType: "blob", // Set the response type to arraybuffer to handle binary data
            })
            .then((responses) => URL.createObjectURL(responses.data))
        )
      ).then((imageUrls) => {
        this.listLogo = imageUrls.map((url) => ({ icon: url }));
        this.Item = this.Item.map((item, index) =>
          Object.assign({}, item, this.listLogo[index])
        );
      });
    },
    async ItemTable() {
      this.Item = await getCustomer();
      // const imageUrls = this.Item.map(logo => logo.domainLogo);
      // await this.getLogo(imageUrls)
      this.totalRows = this.Item.length;
      const oldCurrentPage = this.currentPage;
      this.currentPage = 1;
      this.$nextTick(() => {
        this.currentPage = oldCurrentPage;
      });
return true
    },
    async procTable() {
      await this.ItemTable();
      this.procModal.content = this.Item.filter(
        (dTemplate) => dTemplate._id === this.dataId
      )[0];
      this.process = this.procModal.content.appProcess;
      this.$store.commit("setDataItem", this.process);
    },
    async microTable() {
      await this.procTable();
      this.microservice = this.process[this.procIndex].appProcMicroservices;
      this.microModal.content = this.microservice;
    },

    swalDel() {},
    successAdd() {
      this.$swal("Berhasil!", "Data berhasil ditambahkan", "success");
    },
    failedAdd() {
      this.$swal("Gagal!", "Data gagal ditambahkan!", "cancel");
    },
    errorAdd(error) {
      this.$swal("Gagal!", error, "cancel");
    },
    successEdit() {
      this.$swal("Berhasil!", "Data berhasil Diedit", "success");
    },
    successDelete() {
      this.$swal("Berhasil!", "Data berhasil Dihapus", "success");
    },
    alertLoading() {
      this.$swal({
        title: "Loading...",
        text: "Mohon Ditunggu ya.",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        willOpen: () => {
          this.$swal.showLoading();
        },
      });
    },
    alertAdd(typeData) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Tekan Simpan jika anda sudah yakin!",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Simpan",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.alertLoading()
          if (typeData === "Item") {
            if (this.xFilEkey === null) {
              this.xFilEkey = [];
            }
            const pushedData = {
          nameAddr: 'Rumah',
          location: this.alamat,
        }
            this.optionAddress.push(pushedData)
            const data = {
              userDomainCode: JSON.parse(localStorage.getItem("userDomainCode") || "[]"),
              userCode: this.contact,
              userType: "customer",
              userName: this.name,
              userTax: this.type,
              userPhone: this.desc ? "0" + this.desc : '',
              userEmail: this.contact.slice(0, -3)+"@gmail.com",
              userAddress: this.optionAddress,
              userContactPerson: this.optionContact,
              userTypePayment: this.optionPayment,
              userImage: "",
              userToken: "",
              userPassword: "aksnfajsfa sjcacj 1231310 as c09oas",
              userGender: this.moreData.userGender,
              userReligion: this.moreData.userReligion,
              userJob: this.moreData.userJob,
              userEducation: this.moreData.userEducation,
              userNIK: this.moreData.nik,
              userBirth: this.moreData.userBirth,
            };
            console.log(data, this.moreData);
            // const formData = new FormData();
            // formData.append('userDomainCode', JSON.parse(localStorage.getItem('userDomainCode') || '[]'));
            // formData.append('userCode', this.contact);
            // formData.append('userType', 'customer');
            // formData.append('userName', this.name);
            // formData.append('userTax', this.type);
            // formData.append('userPhone', '0' + this.desc);
            // formData.append('userEmail', this.address);
            // formData.append('userAddress', this.optionAddress);
            // formData.append('userContactPerson', this.optionContact);
            // formData.append('userTypePayment', this.optionPayment);
            // formData.append('xFilekey', this.xFilEkey);

            try {
              // await addFormData(formData)
              const hasil = await addUserPasien(data);
              await this.ItemTable();
              this.$bvModal.hide("modal-add");
              if (hasil.State === "AlertSuccess") {
                this.successAdd();
              } else {
                this.errorAdd(hasil.Text);
              }
            } catch (error) {
              alert(error);
            }
          } else if (typeData === "proses") {
            const id = this.dataId;
            const data = {
              appProcCode: this.id,
              appProcName: this.name,
              appProcDesc: this.desc,
              appGroupMenu: this.type,
              appProcMicroservices: [],
            };
            try {
              await addProc(data, id);
              await this.procTable();
            } catch (error) {
              alert(error);
            }
            this.$bvModal.hide("modal-addProc");
            this.successAdd();
          } else if (typeData === "micro") {
            if (this.id == "") {
              alert(this.id, '" "');
            }
            try {
              const microData = JSON.parse(this.id);
            } catch (error) {}
            const link = this.dataId + "/" + this.procId;
            const data = {
              microCode: this.infoModal.content.appProcCode + "." + this.microCode,
              microName: this.name,
              microType: this.type,
              microDescription: this.desc,
              microIcon: this.image,
              microData: JSON.parse(this.id),
              microVariable: [],
              microPleaceholder: this.prov,
              microRouting: this.kab,
              microNavigate: this.kec,
              microAPIFunctionScript: this.address,
            };
            try {
              await addMicro(data, link);
              await this.microTable();
              this.$bvModal.hide("modal-addMicro");
              this.successAdd();
            } catch (error) {
              alert(error);
              this.failedAdd();
            }
          }
        }
      });
    },
    alertEdit(typeData) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Tekan Edit jika anda sudah yakin!",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#00AB8E",
        confirmButtonText: "Edit",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.alertLoading();
          if (typeData === "Item") {
            if (this.xFilEkey === null) {
              this.xFilEkey = "";
            }
            const pushedData = {
          nameAddr: 'Rumah',
          location: this.alamat,
        }
            this.optionAddress[0] = pushedData
            const uId = this.contact;
            const data = {
              userName: this.name,
              userPhone: this.desc ? "0" + this.desc : '',
              userTax: this.type,
              userEmail: this.contact+"@gmail.com",
              userAddress: this.address,
              userImage: this.xFilEkey,
              userAddress: this.optionAddress,
              userContactPerson: this.optionContact,
              userTypePayment: this.optionPayment,
              
              userGender: this.moreData.userGender,
              userReligion: this.moreData.userReligion,
              userJob: this.moreData.userJob,
              userEducation: this.moreData.userEducation,
              userNIK: this.moreData.nik,
              userBirth: this.moreData.userBirth,
            };
            try {
              await updateCustomer(data, uId);
              await this.ItemTable();
            } catch (error) {
              alert(error);
            }
            this.$bvModal.hide("edit-modal");
            this.successEdit();
          } else if (typeData === "proses") {
            const link = this.dataId + "/" + this.procId;
            const data = {
              appProcCode: this.id,
              appProcName: this.name,
              appProcDesc: this.desc,
              appGroupMenu: this.type,
              appLinkMenu: this.prov,
              appIconMenu: this.kab,
            };
            try {
              await updateProc(data, link);
              await this.procTable();
            } catch (error) {
              alert(error);
            }
            this.$bvModal.hide("edit-modal-proc");
            this.successEdit();
          } else if (typeData === "micro") {
            const link = this.dataId + "/" + this.procId + "/" + this.microId;
            const microVar = this.microservice.find((x) => x._id === this.microId)
              .microVariable;
            if (this.arrayVariable === microVar) {
              this.arrayVariable = JSON.stringify(this.arrayVariable);
            }

            const data = {
              microCode: this.infoModal.content.appProcCode + "." + this.microCode,
              microName: this.name,
              microType: this.type,
              microDescription: this.desc,
              microIcon: this.image,
              microData: JSON.parse(this.id),
              microVariable: JSON.parse(this.arrayVariable),
              microPleaceholder: this.prov,
              microRouting: this.kab,
              microNavigate: this.kec,
              microAPIFunctionScript: this.address,
            };
            try {
              await updateMicro(data, link);
              await this.microTable();
            } catch (error) {
              alert(error);
            }
            this.$bvModal.hide("edit-modal-proc");
            this.successEdit();
          }
        }
      });
    },
    alertDelete(typeData, data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data yang dihapus tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonText: "Kembali",
        confirmButtonText: "Ya, Hapus Saja!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (typeData === "Item") {
            try {
              await deleteTemplate(data._id);
              await this.ItemTable();
            } catch (error) {
              alert(error);
            }
          } else if (typeData === "proses") {
            try {
              await deleteProc(this.dataId + "/" + data._id);
              await this.procTable();
            } catch (error) {
              alert(error);
            }
          } else if (typeData === "micro") {
            try {
              await deleteMicro(this.dataId + "/" + this.procId + "/" + data._id);
              await this.microTable();
            } catch (error) {
              alert(error);
            }
          }
          this.successDelete();
        }
      });
    },

    resetAll() {
      this.alamat = "";
      this.id = "";
      this.name = "";
      this.type = "";
      this.desc = "";
      this.address = "";
      this.image = "";
      this.addressIndex = "";
      this.contactIndex = "";
      this.paymentIndex = "";

      (this.moreData = {
        nik: "",
        code: "",
        userGender: "",
        userReligion: "",
        userJob: "",
        userEducation: "",
        userBirth: "",
      }),
        (this.NameState = null);
      this.typeState = null;
      this.descState = null;
      this.addressState = null;
      this.imageState = null;
      this.resetArray();
    },
    resetArray() {
      this.prov = "";
      this.kab = "";
      this.kec = "";

      this.provState = null;
      this.kabState = null;
      this.kecState = null;
    },
    resetModal() {
      this.microCode = "";
      this.resetAll();
      this.optionAddress = [];
      this.optionContact = [];
      this.optionPayment = [];
    },
    numberFormat(value) {
      this.zero = Number(value.replace(/\D/g, ""));
      return value == null ? "" : this.zero.toLocaleString();
    },
    previewFiles(event) {
      const file = event.target.files[0];
      this.xFilEkey = file;
      this.image = URL.createObjectURL(file);
    },
    // edit methods
    resetEditModal() {
      this.editModal.title = "";
      this.editModal.content = "";
      this.resetAll();
    },
    resetEditArray() {
      this.editProc.title = "";
      this.editProc.content = "";

      this.microId = "";
      this.microCode = "";
      this.microVar = false;
      this.arrayVariable = [];
      this.$bvModal.hide("edit-modal-proc");
      this.resetArray();
    },
    async listRM(data, index, button) {
      this.$root.$emit("bv::show::modal", "ModalListRM", button);
      console.log(data)
      this.titleRM = data.userName
      this.dataRM = data  
      this.optionsRM = await getListRMAll(data._id)
      console.log(this.optionsRM)
    },
    detailRM(data, index, button) {
      this.$root.$emit("bv::show::modal", "ModalDetailRM", button);
      this.detailDataRM = data
    },
    edit(Item, index, button) {
      this.editModal.title = `Edit Data - ${Item.userName}`;
      this.editModal.content = Item;
      this.$root.$emit("bv::show::modal", this.editModal.id, button);

      this.contact = this.editModal.content._id;
      this.id = this.editModal.content.userCode;
      this.name = this.editModal.content.userName;
      this.type = this.editModal.content.userTax;
      this.desc = this.editModal.content.userPhone.slice(1);
      this.address = this.editModal.content.userEmail;
      this.optionAddress = this.editModal.content.userAddress;
      this.optionContact = this.editModal.content.userContactPerson;
      this.optionPayment = this.editModal.content.userTypePayment;

      this.moreData.nik = this.editModal.content.userNIK
      this.moreData.userGender = this.editModal.content.userGender
      this.moreData.userReligion = this.editModal.content.userReligion
      this.moreData.userJob = this.editModal.content.userJob
      this.moreData.userEducation = this.editModal.content.userEducation
      this.moreData.userBirth = this.editModal.content.userBirth
      this.alamat = this.optionAddress.length > 0 ? this.optionAddress[0].location : ''
      
    },
    handleEdit(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleEditSubmit();
    },
    handleEditSubmit() {
      // Exit when the form isn't valid
      if ((this.name)) {
        this.alertEdit("Item");
      }
      if (this.name == "") {
        this.NameState = false;
      } else {
        this.NameState = true;
      }
    },

    infoMicro(Item, index) {
      this.microModal.content = this.microservice[index];
      this.microId = this.microModal.content._id;
      this.microIndex = index;
    },
    editMicroModal(item, index, button) {
      this.editProc.title = "Edit Microservice";
      this.$root.$emit("bv::show::modal", this.editProc.id, button);
      this.microCode = parseFloat(
        item.microCode.replace(this.infoModal.content.appProcCode + ".", " ")
      );
      this.name = item.microName;
      this.type = item.microType;
      this.desc = item.microDescription;
      this.image = item.microIcon;
      this.id = JSON.stringify(item.microData, null, " ");
      this.prov = item.microPleaceholder;
      this.kab = item.microRouting;
      this.kec = item.microNavigate;
      this.address = item.microAPIFunctionScript;
      this.microId = item._id;
      this.arrayVariable = item.microVariable;
    },
    editMicroVar(item, index, button) {
      this.editMicroModal(item, index, button);
      this.editProc.title = "Edit Variabel Microservice";
      this.microVar = true;
    },
    addMicroservice() {
      this.$bvModal.show("modal-addMicro");
      this.id = '" "';
    },
    // add method proc
    proc(Item, index, button) {
      this.procModal.title = `Proses Dari: ${Item.userTax}`;
      this.procModal.content = Item;
      this.process = Item.appProcess;
      this.$root.$emit("bv::show::modal", this.procModal.id, button);
      this.dataId = Item._id;
      this.dataIndex = index;
    },
    resetProcDetail() {
      this.resetAll();
      this.$bvModal.show("proc-modal");
    },

    addProcModal() {
      this.$bvModal.show("modal-addProc");
    },
    handleOkProc(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmitProc();
    },
    handleSubmitProc() {
      if (this.name && this.type && this.desc) {
        this.alertAdd("proses");
      }
      // add validation
      if (!this.name) {
        this.NameState = false;
      } else {
        this.NameState = true;
      }
      if (!this.type) {
        this.typeState = false;
      } else {
        this.typeState = true;
      }
      if (!this.desc) {
        this.descState = false;
      } else {
        this.descState = true;
      }
    },
    // Item Item
    item(item) {
      const sendData = {
        title: item.userTax,
        code: item._id,
      };
      this.$router.push({
        name: "item",
        params: {
          itemDomain: sendData,
        },
      });
    },
    // Info Proc
    infoProc(item, index, button) {
      this.infoModal.title = this.procModal.title;
      this.infoModal.content = this.procModal.content.appProcess[index];
      this.microservice = this.infoModal.content.appProcMicroservices;
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
      this.procId = this.infoModal.content._id;
      this.procIndex = index;
    },
    // edit proc methods
    editProcModal(item, index, button) {
      this.editProc.title = this.procModal.title;
      this.$root.$emit("bv::show::modal", this.editProc.id, button);
      this.procId = this.procModal.content.appProcess[index]._id;
      this.procIndex = index;
      this.id = item.appProcCode;
      this.name = item.appProcName;
      this.desc = item.appProcDesc;
      this.type = item.appGroupMenu;
      this.prov = item.appLinkMenu;
      this.kab = item.appIconMenu;
    },
    resetEditProc() {
      this.editProc.title = "";
      this.editProc.content = "";

      this.microId = "";
      this.microCode = "";
      this.microVar = false;
      this.arrayVariable = [];
      this.resetAll();
    },
    handleProcEdit() {
      // Exit when the form isn't valid
      if (this.name && this.id && this.type && this.prov) {
        this.alertEdit("proses");
      }
      if (this.name == "") {
        this.NameState = false;
      } else {
        this.NameState = true;
      }
      if (this.id == "") {
        this.descState = false;
      } else {
        this.descState = true;
      }
      if (this.type == "") {
        this.typeState = false;
      } else {
        this.typeState = true;
      }
      if (this.prov == "") {
        this.provState = false;
      } else {
        this.provState = true;
      }
    },
    // add micro data
    handleMicroOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleMicroSubmit();
    },
    handleMicroSubmit() {
      if (this.name && this.type && this.microCode) {
        this.alertAdd("micro");
      }
      // add validation
      if (!this.name) {
        this.NameState = false;
      } else {
        this.NameState = true;
      }
      if (!this.type) {
        this.typeState = false;
      } else {
        this.typeState = true;
      }
      if (!this.microCode) {
        this.descState = false;
      } else {
        this.descState = true;
      }
    },
    handleMicroEdit() {
      if (this.name && this.type && this.microCode) {
        this.alertEdit("micro");
      }
      // add validation
      if (!this.name) {
        this.NameState = false;
      } else {
        this.NameState = true;
      }
      if (!this.type) {
        this.typeState = false;
      } else {
        this.typeState = true;
      }
    },
    // add method info
    async info(Item, index, button) {
      this.infoModal.title = `Detail Data - ${Item.userName}`;
      this.infoModal.content = Item;
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
      this.dataId = "";
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
      this.microservice = "";
      this.procId = "";
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      if (this.name) {
        this.alertAdd("Item");
      } else {
        alert("Ada data wajib yang masih kosong");
      }
      // add validation
      if (!this.name) {
        this.NameState = false;
      } else {
        this.NameState = true;
      }
      // if (!this.moreData.nik) {
      //   this.typeState = false;
      // } else {
      //   this.typeState = true;
      // }
      // if (!this.desc) {
      //   this.descState = false;
      // } else {
      //   this.descState = true;
      // }
      // if (!this.contact) {
      //   this.contactState = false;
      // } else {
      //   this.contactState = true;
      // }
    },
  },
  computed: {
    listItem() {
      if (this.filteredData === "") {
        this.totalRows = this.Item.length;
        return this.Item
      } else if (this.filter === "" || this.filter === null) {
        this.filteredData = ""
        this.totalRows = this.Item.length;
        return this.Item
      } else {
        this.totalRows = this.filteredData.length;
        return this.filteredData
      }
    },
    activeFields() {
      this.sorts = this.fields.filter((name) => this.selected.includes(name));
      return this.sorts.map((v) => {
        var Item = {};
        Item["key"] = v;
        Item["label"] = v;
        Item["thStyle"] = "font-weight:600;";
        Item["thClass"] = "bg-custom-2 text-center align-middle";
        Item["class"] = "align-top";
        if (v !== "opsi") {
          Item["sortable"] = true;
          Item["thStyle"] = "font-weight:600";
        } else {
          (Item["fillterByFormatted"] = false),
            (Item["thStyle"] = "width:80px; font-weight:600"),
            (Item["class"] = "text-center");
        }
        if (v === "icon") {
          (Item["sortable"] = false),
            (Item["thStyle"] = "width: 60px"),
            (Item["class"] = "text-center");
        }
        if (v === "userCode") {
            Item["thStyle"] = "width: 90px; font-weight:600",
          Item["label"] = "Kode";
        }
        if (v === "userNIK") {
          Item["label"] = "No. NIK";
        }
        if (v === "userName") {
          Item["label"] = "Nama Pasien";
        }
        if (v === "userAddress") {
          Item["label"] = "Alamat";
          Item["thStyle"] = "width: 275px; font-weight:600";
        }
        if (v === "userPhone") {
          Item["label"] = "No. Telp";
        }
        if (v === "userBirth") {
            Item["thStyle"] = "width: 75px; font-weight:600",
          Item["label"] = "tgl. lahir";
        }
        if (v === "userGender") {
          Item["label"] = "Gender";
        }
        return Item;
      });
    },
  },
  async mounted() {    
    const timeout = 30000; // 10 seconds
      try {
  
        this.optionsJob = await getListDistinc("userJob");
        const opsi = await getListDistinc("userEducation");
        this.optionsEducation = opsi.filter(x => x !== "")
        const tes = await this.ItemTable();
        console.log('hasilnya', tes)
        const timeoutPromise = new Promise((resolve) => setTimeout(resolve, timeout));
  
        // Wait for either finalPromise or timeoutPromise to resolve
        const result = await Promise.race([tes, timeoutPromise]);
        this.isDataLoaded = result !== undefined;
      } catch (error) {
        this.isDataLoaded = false;
      }
  },
};
</script>

<style>
.btn:has(input[value="opsi"]) {
  display: none;
}
</style>
