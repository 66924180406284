<template>
  <main id="content" role="main" class="main">
    <div class="w-100" ref="contentDiv"></div>
    <transition name="fade" mode="out-in">
      <div v-if="isDataLoaded === ''" key="empty">
        <loading></loading>
      </div>

      <!-- Main Content -->
      <div v-else-if="isDataLoaded" key="truthy">
        <div class="content container-fluid p-3 ps-0 py-0">
          <transaksiDetailPelayananWO
            :posts="posts"
            :component="refreshComponent"
            :hotSettings="hotSettings"
            :widthTable="contentDivWidth"
            :excelData="excelData"
            :refreshMethods="onRefresh()"
          ></transaksiDetailPelayananWO>
        </div>
      </div>

      <!-- Error -->
      <div v-else key="other">
        <connection-timeout></connection-timeout>
      </div>
    </transition>
  </main>
</template>

<script>
import {
  getFieldTable,
  getComponentAdd,
  dataTableAdd,
  refreshValue,
  getContentWidth,
} from "../../components/services/transaksi";
import { get1 } from "../../components/services/components";
import { getFullDate } from "../../components/function/date";
import { getItem, getDetailWObyWoNo } from "../../components/services/transComponent";
import transaksiDetailPelayananWO from "../../components/transaksi/transaksiDetailPelayananWO.vue";
import axios from "axios";

import loading from "../../components/loading.vue";
import connectionTimeout from "../../components/connectionTimeout.vue";
export default {
  components: {
    loading,
    connectionTimeout,
    transaksiDetailPelayananWO,
  },
  data() {
    return {
      qtyWO: [],
      dataDetilWo: [],
      contentDivWidth: 0,
      excelData: [],
      hotSettings: {
        readOnly: true,
        afterChange: this.onRefresh,
        licenseKey: "non-commercial-and-evaluation",
        height: 200,
        colHeaders: true,
        readOnly: true,

        rowHeaderWidth: 35,
        startRows: 10,
        columns: [{ data: "tes", type: "numeric" }],
        // Other Handsontable settings
      },
      component: [],
      posts: {
        transTitle: "Pelayanan Pasien",
        transRouting: "wo-pelayanan-detail",
        transProperties: [
          {
            dataName: "menuAdd",
            dataMicro: [
              {
                componentName: "fieldHeader",
                componentTitle: "",
                componentClass: "col-md-6",
                componentMicro: [
                  {
                    key: "pasienName",
                    microName: "Nama Pasien",
                    microType: "text",
                    microOptions: [],
                    isDisabled: true,
                    microClass: "",
                    microValue: "",
                    microFormat: "DD/MM/YYYY",
                  },
                  {
                    key: "productName",
                    microName: "Nama Layanan",
                    microType: "text",
                    microOptions: [],
                    microText: "Masukan nama produk",
                    isDisabled: true,
                    microClass: "",
                    microValue: "",
                  },
                  {
                    key: "noBatch",
                    microName: "No. Antrian",
                    microType: "text",
                    microText: "",
                    isDisabled: true,
                    microClass: "",
                    microValue: "",
                  },
                  {
                    key: "StockProduk",
                    microName: "Stock",
                    microType: "text",
                    microText: "0",
                    isDisabled: true,
                    microClass: "col-6 d-none",
                    microValue: "",
                  },
                  {
                    key: "minQtyProduk",
                    microName: "Min. Produksi",
                    microType: "text",
                    microText: "0",
                    isDisabled: true,
                    microClass: "col-6 d-none",
                    microValue: "",
                  },
                ],
              },
              {
                componentName: "fieldHeader",
                componentTitle: "",
                componentClass: "col-md-6",
                componentMicro: [
                  {
                    key: "woScheduleDate",
                    microName: "Schedule",
                    microType: "date",
                    microText: "",
                    isDisabled: true,
                    microClass: "col-12",
                    microValue: "",
                    microFormat: "DD/MM/YYYY",
                  },
                  {
                    key: "woStartDate",
                    microName: "Start",
                    microType: "date",
                    microText: "",
                    isDisabled: true,
                    microClass: "col-12",
                    microValue: "",
                    microFormat: "DD/MM/YYYY",
                  },
                ],
              },
              {
                componentName: "fieldTable",
                componentTitle: "",
                componentClass: "col-md-12",
                componentMicro: [
                  {
                    key: "itemCode",
                    microName: "Kode Item",
                    microType: "text",
                    width: 100,
                    microText: "",
                    microClass: "",
                  },
                  {
                    key: "itemName",
                    microName: "Nama Item",
                    microType: "text",
                    width: 0,
                    microText: "",
                    microClass: "",
                  },
                  {
                    key: "desc",
                    microName: "Deskripsi",
                    microType: "text",
                    width: 0,
                    microText: "",
                    microClass: "",
                  },
                  {
                    key: "qty",
                    microName: "Qty",
                    microType: "numeric",
                    width: 0,
                    microText: "",
                    microClass: "",
                  },
                  {
                    key: "uom",
                    microName: "UoM",
                    microType: "text",
                    width: 100,
                    microText: "",
                    microClass: "",
                  },
                ],
              },
              {
                componentName: "fieldNote",
                componentTitle: "Catatan",
                componentClass: "col-md-12",
                componentMicro: [
                  {
                    key: "woNoted",
                    microName: "Note",
                    microType: "textarea",
                    microText: "Masukan Catatan Tambahan",
                    microClass: "",
                    microValue: "",
                  },
                ],
              },
              {
                componentName: "fieldTotal",
                componentTitle: "Total",
                componentClass: "col-md-12",
                componentMicro: [],
              },
              {
                componentName: "routingButton",
                componentTitle: "button",
                componentClass: "col-md-12",
                componentMicro: [
                  {
                    key: "back",
                    microName: "Kembali",
                    microRouting: "back",
                    microText: "Kembali",
                    microClass: "btn-light",
                  },
                  {
                    key: "submit",
                    microName: "",
                    microRouting: "add",
                    microText: "",
                    microClass: "btn-primary",
                  },
                ],
              },
            ],
          },
          {
            dataName: "routingButton",
            dataTitle: "button",
            dataClass: "col-md-12",
            dataMicro: [
              {
                key: "add",
                microName: "",
                microRouting: "add",
                microText: "",
                microClass: "btn-primary",
              },
            ],
          },
        ],
      },
      itemTable: [],
      isDataLoaded: "",
      dataWO: {},
    };
  },
  async mounted() {
    const timeout = 30000; // 10 seconds
    try {
      const woNo = this.$route.params.dataDetail;

      // get detil wo
      const axiosDetilWO = await getDetailWObyWoNo(woNo);
      this.dataWO = axiosDetilWO;
      console.log("coba", axiosDetilWO);
      this.excelData = axiosDetilWO;
      console.log("detilWO :", this.excelData);

      this.component = await getComponentAdd(this.posts);
      // const qtyWO = []
      this.component
        .filter((x) => x.componentName === "fieldHeader")
        .forEach((item) => {
          // console.log('data',item.componentMicro.find(dataItem => dataItem.key ==='productQty'))
          this.qtyWO = item.componentMicro.filter(
            (dataItem) => dataItem.key === "productQty"
          );
          // qrtWO.push(...matchingData);
        });

      this.detailData = Object.entries(this.excelData).map(([key, value]) => ({
        key,
        value,
      }));
      this.component.forEach((component) => {
        component.componentMicro.forEach((item) => {
          const matchedObject = this.detailData.find((obj) => obj.key === item.key);
          console.log(matchedObject);
          if (matchedObject) {
            item.value = matchedObject.value;
          }
        });
      });
      // console.log('cok', this.qtyWO)
      this.hotSettings.columns = await getFieldTable(this.component);
      console.log(this.hotSettings.columns);
      // this.hotSettings.columns.find(x => x.data === 'qty').renderer = this.qtyRenderer
      // await dataTableAdd(this.excelData, this.hotSettings.columns);
      this.moreFunction();
      this.onRefresh();
      this.contentDivWidth = await getContentWidth(
        this.$refs.contentDiv,
        this.hotSettings.columns
      );
      const timeoutPromise = new Promise((resolve) => setTimeout(resolve, timeout));

      // Wait for either finalPromise or timeoutPromise to resolve
      const result = await Promise.race([this.contentDivWidth, timeoutPromise]);
      this.isDataLoaded = result !== undefined;
    } catch (error) {
      this.isDataLoaded = false;
    }
  },
  computed: {
    refreshComponent() {
      return this.component.map((item) => ({
        ...item,
      }));
    },
  },
  methods: {
    async moreFunction() {
      this.component
        .filter((x) => x.componentName === "fieldHeader")
        .forEach((y) => {
          y.componentMicro.find((z) => {
            if (z.key === "productName") {
              z.value = this.dataWO.woitemId.itemName;
            } else if (z.key === "pasienName") {
              z.value = this.dataWO.woUserCust.userName;
            } else if (z.key === "noBatch") {
              z.value = this.dataWO.woNoBatch;
            }
          });
        });
    },
    onRefresh() {
      refreshValue(this.excelData, this.component);
    },
  },
};
</script>
