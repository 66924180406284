<template>
  <main id="content" role="main" class="main">
    <div class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Modal title</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <printNota style="z-index: 0" id="printable-element"></printNota>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary">Save changes</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="content container-fluid p-3 ps-0 pt-0" ref="contentDiv">
      <ul class="breadcrumb m-1 mt-0">
        <li>
          <a href="#"><i class="bi-house-door"></i></a>
        </li>
        <li>Report</li>
        <li>printNota</li>
      </ul>

      <!-- Content -->
      <template v-if="isDataLoaded">
        <div class="card">
          <div class="card-header bg-light p-2">
            <div class="row">
              <div class="row col-9 d-flex align-items-center justify-content-end pe-0">
                <b-form-group
                  label-for="coaKasbank"
                  invalid-feedback="Condition Harus Diisi!"
                  style="width: 200px"
                >
                </b-form-group>
              </div>

              <div class="col-3 d-flex justify-content-end py-2" style="z-index: 2">
                <router-link
                  to="/transaksi/po"
                  class="btn btn-outline-secondary btn-xs py-1 me-2"
                >
                  <i class="bi-chevron-left"></i>Kembali
                </router-link>
                <b-button-group>
                  <button class="btn btn-primary btn-xs" v-print="'#printable-element'">
                    <i class="bi-printer pe-2"></i>Cek
                  </button>
                  <b-dropdown id="dropdown2" variant="primary" size="xs" right>
                    <div>
                      <p class="p-2 pb-0 m-0">Export</p>
                    </div>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="generatePDF">PDF</b-dropdown-item>
                    <b-dropdown-item>xls</b-dropdown-item>
                  </b-dropdown>
                </b-button-group>
              </div>
            </div>
          </div>
          <div class="card-body p-2 printData">
            <button @click="connectToPrinter">Connect to Printer</button>
          </div>
        </div>
      </template>
      <div v-else>Loading...</div>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import VuePrintNB from "vue-print-nb";
Vue.use(VuePrintNB);
import { formatNumber } from "../components/function/number";
import printNota from "../components/print/printNota.vue";
export default {
  directives: {
    print: VuePrintNB,
  },
  components: {
    printNota,
  },
  data() {
    return {
      poNumb: "",
      dataPOId: [],
      isDataLoaded: false,
      posts: [],
      pembuat: [],
      subTotal: 0,
    };
  },
  async mounted() {
    this.isDataLoaded = true;
  },
  computed: {},
  methods: {
    async connectToPrinter() {
      this.$nextTick(() => {
        VuePrintNB.print("#printable-element");
      });
      // try {
      //   const device = await navigator.usb.requestDevice({
      //     filters: [{ vendorId: 0x04b8 }],
      //   });
      //   await device.open();
      //   await device.selectConfiguration(1);
      //   await device.claimInterface(0);

      //   // Define the title and its font size
      //   const title = "KLINIK UTAMA MUTIARA AISHA"; // Change \x32 to another value for different font sizes
      //   const address = "JL. KI HAJAR DEWANTARA 04 SELOSARI MAGETAN";
      //   const phone = "082331 200 500";
      //   // Define column names and fixed column widths
      //   const terbilang = "Satu Juta Rupiah";
      //   const footer = ["Terima kasih atas kunjungannya", "Semoga Lekas Sembuh"];
      //   const columns = [
      //     { name: "TRANSAKSI", width: 35 },
      //     { name: "QTY", width: 5 },
      //     { name: "DISC", width: 15 },
      //     { name: "JUMLAH", width: 15 },
      //   ];
      //   const infoPasien = [
      //     { name: "", width: 15 },
      //     { name: "", width: 55 },
      //   ];
      //   const totalData = [
      //     { name: "", width: 15 },
      //     { name: "", width: 25 },
      //     { name: "", width: 10 },
      //     { name: "", width: 20 },
      //   ];
      //   // Calculate the total width of the dashed line based on column widths
      //   const totalWidth = columns.reduce((acc, column) => acc + column.width, 0);

      //   // Create the dashed line based on the total width
      //   const equalLine = "=".repeat(totalWidth);
      //   const dashedLine = "-".repeat(totalWidth);

      //   // Build the header row with fixed widths
      //   const header = columns
      //     .map((column) => column.name.padEnd(column.width))
      //     .join("  ");

      //   // Define your dynamic table data
      //   const tableData = [
      //     ["Pemeriksaan Jantung", "1", "0", "50.000"],
      //     ["Pemeriksaan Darah", "1", "50.000", "1.000.000"],
      //   ];
      //   const tablePasien = [
      //     ["Nm. Pasien", ": "],
      //     ["Nomor RM", ": "],
      //     ["Alamat", ": "],
      //     ["Dokter", ": "],
      //     ["Poli", ": "],
      //   ];
      //   const tableTotal = [
      //     ["Tanggal", ": ", "TOTAL", ": 1.050.000"],
      //     ["No. Nota", ": ", "DISC", ": 50.000"],
      //     ["", "", "SUBTOTAL", ": 1.000.000"],
      //   ];

      //   // Build the table content with fixed column widths
      //   const listPasien = [
      //     ...tablePasien.map((row) => {
      //       return row
      //         .map((cell, index) => cell.padEnd(infoPasien[index].width))
      //         .join("  ");
      //     }),
      //   ].join("\n");
      //   const listTotal = [
      //     ...tableTotal.map((row) => {
      //       return row
      //         .map((cell, index) => cell.padEnd(totalData[index].width))
      //         .join("  ");
      //     }),
      //     equalLine,
      //   ].join("\n");
      //   const tableContent = [
      //     equalLine,
      //     header,
      //     dashedLine,
      //     ...tableData.map((row) => {
      //       return row.map((cell, index) => cell.padEnd(columns[index].width)).join("  ");
      //     }),
      //     dashedLine,
      //   ].join("\n");

      //   const tableCommand = new TextEncoder().encode(
      //     "\x1B@" +
      //       "\x1B!" +
      //       "\x2D\x40" +
      //       title +
      //       "\x0A" +
      //       "\x1B@" +
      //       "\x1B!" +
      //       "\x1D!" +
      //       address +
      //       "\x0A" +
      //       phone +
      //       "\x0A" +
      //       "\x0A" +
      //       "\x1B!" +
      //       "\x1D!\x00" +
      //       listPasien +
      //       "\x0A" +
      //       tableContent +
      //       "\x0A" +
      //       listTotal +
      //       "\x0A" +
      //       terbilang +
      //       "\x0A" +
      //       "\x0A" +
      //       "\x0A" +
      //       footer[0] +
      //       "\x0A" +
      //       footer[1] +
      //       "\x1B!" +
      //       "\x1Bd\x01"
      //   );

      //   await device.transferOut(1, tableCommand);
      //   await device.close();
      // } catch (error) {
      //   console.error("Error:", error);
      // }
    },

    formatNumber(data) {
      return formatNumber(data);
    },
    async doPrint() {
      const printContent = `
        <div>
          <h1>Hello, World!</h1>
          <p>Ini adalah contoh mencetak dengan print-js.</p>
        </div>
        `;
      printJS({
        printable: printContent,
        type: "html",
        documentTitle: "Contoh Cetak",
      });

      //     const coba = `<div>tes</div>`
      // const dataHTML = `
      //   <html>
      //     <head>
      //       <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet">
      //       <style>
      //         body {
      //           font-family: sans-serif;
      //         }
      //         @media print {
      //           * {
      //             margin: 0 !important;
      //             padding: 0 !important;
      //           }
      //         }
      //         @page {
      //           text-align: left;
      //           orientation: portrait;
      //           margin: 0;
      //           padding: 0;
      //         }
      //       </style>
      //     </head>
      //     <body>
      //       ${this.getReportContent()}
      //     </body>
      //   </html>
      // `;
      // console.log('tes ',dataHTML)
      // const options = {
      //     printable: '<div>tes</div>',
      //     type: 'html',
      //     documentTitle: 'Print Invoice',
      //     base64: false
      // }

      // printJS(options)
    },

    printReport() {
      // console.log("ini print", this.getReportContent());
      const printWindow = window.open("", "_blank");
      const printDocument = `
          <html>
            <head>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet">
            <style scope>
            body {
                font-family: sans-serif;
            }
            @media print {
            * {
                margin: 0 !important;
                padding: 0 !important;
            }
            }
            @page {
                text-align: left;
                orientation: portrait;
                margin: 0;
                padding: 0;
            }
              </style>
            </head>

            <body>
              ${this.getReportContent()}
            </body>
          </html>
        `;
      printWindow.document.write(printDocument);
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
        printWindow.close();
      };
    },
    getReportContent() {
      const reportView = document.querySelector(".printData");
      if (reportView) {
        return reportView.innerHTML;
      }
      return "No content found.";
    },
  },
};
</script>

<style scope>
td {
  position: relative;
}
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: hidden; /* Untuk mengatasi float */
}

.header {
  text-align: center;
  background-color: #f2f2f2;
  padding: 10px;
}

.header h1 {
  margin: 0;
  color: #333;
}

.po-number {
  text-align: right;
  margin-top: 10px;
}

.po-number p {
  margin: 0;
  font-weight: bold;
}

.po-date {
  text-align: right;
}

.po-date p {
  margin: 5px 0;
}

.address-info {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.address-info h2 {
  font-size: 18px;
  margin: 0;
}

.address-info .address {
  flex: 1;
}

.address-info p {
  margin: 5px 0;
}

.order-details {
  margin-top: 20px;
  margin-bottom: 0; /* Menghilangkan margin bawah */
}

.order-details table {
  width: 100%;
  border-collapse: collapse;
}

.order-details table,
.order-details th,
.order-details td {
  border: 1px solid #ccc;
}

.order-details th,
.order-details td {
  padding: 10px;
  text-align: left;
}
.divNotePrint {
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  border: 1px solid #ccc;
  position: absolute;
  top: 10px;
  left: 0;
  bottom: 0;
  right: 10px;
}
.footerPrint {
  padding: 25px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footerPrint .ttd {
  flex-wrap: wrap;
  display: flex;
  align-items: space-between;
  justify-content: center;
  width: 250px;
  height: 150px;
  border-bottom: 1px solid #000;
}
.footerPrint .ttd .ttdName {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
</style>
