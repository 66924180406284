<template>
  <div class="report" style="font-size: 10px">
    <table class="table table-bordered table-hover z-1 position-relative">
      <thead class="bg-light sticky-element">
        <tr>
          <th>
            <b>NO</b>
          </th>
          <th scope="col" width="110" class="text-center">
            <b>kode Item</b>
          </th>
          <th scope="col" class="text-center">
            <b>nama Item</b>
          </th>
          <th scope="col" class="text-center">
            <b>Lokasi</b>
          </th>
          <th scope="col" width="150" class="text-center">
            <b>No. Batch</b>
          </th>
          <th scope="col" width="100" class="text-center">
            <b>STOK</b>
          </th>
          <th scope="col" width="50" class="text-center">
            <b>satuan</b>
          </th>
        </tr>
      </thead>
      <tbody v-if="realData.length > 0">
        <template v-for="(entry, index) in realData">
          <tr v-for="(micro, i) in entry.invDetilBegining">
            <td class="align-top" v-if="i === 0" :rowspan="entry.invDetilBegining.length">
              <b>{{ index + 1 }}</b>
            </td>
            <td class="align-top" v-if="i === 0" :rowspan="entry.invDetilBegining.length">
              <b>{{ entry.itemCode }}</b>
            </td>
            <td class="align-top" v-if="i === 0" :rowspan="entry.invDetilBegining.length">
              <b>{{ entry.itemName }}</b>
            </td>
            <td>
              <b>{{ micro.itemLocation.name }}</b>
            </td>
            <td>
              <p class="m-0" v-for="batch in micro.data">
                <b>{{ batch.itemNoBatch }}</b>
              </p>
            </td>
            <td>
              <p class="m-0" v-for="batch in micro.data">
                <b>{{ batch.itemQty }}</b>
              </p>
            </td>
            <td>
              <b>{{ entry.itemUM }}</b>
            </td>
          </tr>
          <tr class="bg-light" v-if="entry.invDetilBegining.length > 0">
            <td colspan="5">
              <b>TOTAL QTY</b>
            </td>
            <td>
              <b>{{ entry.invEnd }}</b>
            </td>
            <td>
              <b>{{ entry.itemUM }}</b>
            </td>
          </tr>
        </template>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="6">Data Transaksi Kosong</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatNumber } from "../function/number";
import { formatIndDate } from "../function/date";
export default {
  props: {
    saldoAwal: String,
    data: Array,
  },
  data() {
    return {
      saldoAkhir: "0",
      saldoIn: "0",
      saldoOut: "0",
    };
  },
  computed: {
    realData() {
      let modifiedData = this.data.map((item) => {
        let modifiedDetilBegining = item.invDetilBegining.map((entry) => {
          return {
            itemLocation: entry.itemLocation,
            data: [
              {
                itemQty: entry.itemQty,
                itemNoBatch: entry.itemNoBatch,
              },
              // Add more entries if needed
            ],
          };
        });

        return {
          ...item,
          invDetilBegining: modifiedDetilBegining,
        };
      });

      console.log("hasilnya", modifiedData);

      return modifiedData;
    },
    updateSaldoAwal() {
      return formatNumber(this.saldoAwal.toString());
    },
    updateSaldoIn() {
      let ini = this.data
        .filter((x) => parseFloat(x.transIn) > 0 && parseFloat(x.transOut) <= 0)
        .reduce((sum, item) => sum + parseFloat(item.transAmount), 0);
      this.saldoIn = ini;
      return formatNumber(ini);
    },
    updateSaldoOut() {
      let out = this.data
        .filter((x) => parseFloat(x.transOut) > 0 && parseFloat(x.transIn) <= 0)
        .reduce((sum, item) => sum + parseFloat(item.transAmount), 0);
      if (out < 0) {
        out = out * -1;
      }
      this.saldoOut = out;
      return formatNumber(out);
    },
    updateSaldoAkhir() {
      return formatNumber((this.saldoAwal + this.saldoIn - this.saldoOut).toString());
    },
  },
  methods: {
    joinData(data) {
      return data.join(" ");
    },
    totalTransaksi(data, index) {
      const sum = data.slice(0, index + 1).reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.transAmount);
      }, 0);
      return sum;
    },
    formatIndDate(data) {
      return formatIndDate(data);
    },
    sumTransSaldoAwal(data) {
      const sum = data.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.transAmount);
      }, 0);
      return sum;
    },
    totalDebetData(data) {
      const sum = data
        .filter((x) => x.transType === "debet")
        .reduce((accumulator, currentValue) => {
          return accumulator + parseFloat(currentValue.transAmount);
        }, 0);
      return sum;
    },
    totalKreditData(data) {
      const sum = data
        .filter((x) => x.transType === "kredit")
        .reduce((accumulator, currentValue) => {
          return accumulator + parseFloat(currentValue.transAmount);
        }, 0);
      return sum;
    },
    formatNumber(data) {
      return formatNumber(data);
    },
  },
};
</script>

<style scoped></style>
