<template>
  <main id="content" role="main" class="main">
    <form class="content container-fluid p-3 ps-0 py-0" ref="contentDiv" @submit="sendData">
      <!-- Header -->
      <div class="row my-1">
        <div class="col-4 d-flex align-items-center">
          <ul class="breadcrumb m-0">
            <li>
              <router-link to="/dashboard">
                <a href="#"><i class="bi-house-door"></i></a>
              </router-link>
            </li>
            <li>
              <router-link :to="$route.path.replace('/new', '')">
                <a href="#">Jurnal Entry</a>
              </router-link>
            </li>
            <li>Tambah</li>
          </ul>
        </div>
        <div class="col-4 text-center"><span class="h2">Tambah Jurnal Entry</span></div>
        <div class="col-4 d-flex justify-content-end align-items-center">
          <router-link :to="$route.path.replace('/new', '')">
            <button class="btn btn-outline-secondary btn-xs py-1 me-2" type="button">
              <i class="bi-chevron-left"></i>Kembali</button>
          </router-link>
          <button type="submit" class="btn btn-primary btn-xs py-1">Simpan</button>
        </div>
      </div>


      <!-- End of Header -->

      <!-- Main Data -->
      <div class="main-data mt-4">
        <div class="row mx-1">
          <div class="alert alert-danger" role="alert" :class="totalAmount < 0 ? '' : 'd-none'">
            <b><i class="bi-exclamation-circle"></i> Total Amount Todak Boleh Minus!</b>
          </div>
        </div>
        <div class="row scale-9 position-relative" style="width: 114%; z-index: 1;">
          <!-- First Header -->
          <div class="col-6">
            <div class="card h-100">
              <div class="row px-3 py-1">
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between ">*Kode Voucher <span>:</span></label>
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input v-model="transNo" size="sm" disabled class="form-control-sm py-0 form-control" required
                      placeholder="Kode Voucher Jurnal Entry"></b-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End of First Header -->

          <!-- Secondx Header -->
          <div class="col-6">
            <div class="card h-100">
              <div class="row px-3 py-1">
                <div class="row my-1 pe-0">
                  <label class="col-sm-4 col-md-3 d-flex justify-content-between ">*Trans. Date <span>:</span></label>
                  <div class="col-sm-8 col-md-9 pe-0">
                    <b-form-input @input="inOut(micro.value)" v-model="transEffDate" size="sm" type="date"
                      class="form-control-sm py-0 form-control" required
                      placeholder="Masukan Tanggal Transaksi"></b-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End of Secondx Header -->
        </div>

        <div class="row my-3">
          <!-- Hot Table -->
          <div class=" col-12">
            <div class="hot-container col-md-12 position-relative" style="overflow-x: auto; z-index: 0">
              <hot-table ref="hot" :settings="hotSettings" :data="excelData" :rowHeaders="true"></hot-table>
            </div>
          </div>
          <!-- End of Hot Table -->
        </div>

        <!-- Footer -->
        <div class="row mb-3 d-flex justify-content-between">
          <div class="col-md-6 scale-9">
            <div class="h-100">
              <div class="card h-100">
                <div class="card-header bg-light py-1">Catatan</div>
                <textarea class="form-control form-control-sm py-1 h-100" style="resize: none"
                  placeholder="Masukan Catatan" v-model="transNotes"></textarea>
              </div>
            </div>

          </div>
          <div class="col-md-4 row p-0 scale-9" style="transform-origin: right top">
            <div class="row d-flex justify-content-end">
              <div class="border border-light bg-light d-flex align-items-center justify-content-between col-5">Total
                Kredit <span>:</span></div>
              <div class="p-0 border border-light bg-light pt-1 col-7 pe-2">
                <input type="text" v-model="totalKredit" class="form-control form-control-sm py-1"
                  style="text-align: right" disabled>
              </div>
              <div class="border border-light bg-light d-flex align-items-center justify-content-between col-5">Total
                Debet <span>:</span></div>
              <div class="p-0 border border-light bg-light pt-1 col-7 pe-2">
                <input type="text" v-model="totalDebet" class="form-control form-control-sm py-1"
                  style="text-align: right" disabled>
              </div>
              <div class="border border-light bg-light d-flex align-items-center justify-content-between col-5">Total
                Amount
                <span>:</span>
              </div>
              <div class="p-0 border border-light bg-light pt-1 col-7 pe-2">
                <input @input="refreshMethods()" type="text" v-model="totalAmount"
                  class="form-control form-control-sm py-1" style="text-align: right" disabled>
              </div>
            </div>
          </div>
        </div>
        <!-- End of Footer -->
      </div>
      <!-- End of Main Data -->
    </form>
  </main>
</template>

<script>

import { getCoa, getJEVoucher, addJE } from '../../components/services/components'
import { getFullDate } from '../../components/function/date'
import { alertLoading, successAddData, errorAddData } from '../../components/function/sweetalert'
export default {
  data() {
    return {
      // Main Data
      transNo: '',
      transEffDate: '',
      transNotes: '',

      subTotal: '',

      // HOT Data   
      contentDivWidth: '',
      colHeader: ['*Kode COA', '*Nama COA', 'Cost Center', 'No. Reff', '*Uraian', '*Kredit', '*Debet'],
      colBody: [
        {
          data: 'transCoaCode', type: 'autocomplete',
          width: 100
        },
        {
          data: 'transCoaName', type: 'autocomplete',
        },
        {
          data: 'transCostCenter', type: 'text',
        },
        {
          data: 'transReff', type: 'text',
          width: 100
        },
        {
          data: 'transDescription', type: 'text',
        },
        {
          data: 'Kredit', type: 'numeric',
          validator: this.validateNumericValue,
          numericFormat: {
            pattern: '0,0',
          },
          width: 125
        },
        {
          data: 'Debet', type: 'numeric',         
          validator: this.validateNumericValue, 
          numericFormat: {
            pattern: '0,0',
          },
          width: 125
        },
      ],
      excelData: [],
      hotSettings: {
        afterChange: this.onChange,
        beforeKeyDown: this.onKeyDown1,
        licenseKey: 'non-commercial-and-evaluation',
        height: 200,
        colHeaders: [],

        rowHeaderWidth: 35,
        startRows: 10,
        columns: []
      },
    }
  },
  async mounted() {
    this.coa = await getCoa()
    this.transNo = await getJEVoucher()
    this.transEffDate = await getFullDate()
    this.hotSettings.colHeaders = this.colHeader
    this.hotSettings.columns = this.colBody
    this.hotSettings.columns.find(x => x.data === 'transCoaCode').source = this.coa.coaNonBank.map(x => x.coaCode)
    this.hotSettings.columns.find(x => x.data === 'transCoaName').source = this.coa.coaNonBank.map(x => x.coaName)
    const dataObject = {}
    this.colBody.forEach(item => {
      dataObject[item.data] = '';
    });
    console.log(dataObject)
    for (let i = 0; i < 1000; i++) {
      const key = `row_${i}`;
      const objectAdd = { ...dataObject, key }
      this.excelData.push(objectAdd)
    }
    this.getContentWidth()
  },
  computed: {
    totalKredit() {
      const tes = this.excelData.filter(x => x.Kredit !== '').map(x => x.Kredit)
      if (tes.length > 0) {
        const sum = tes.reduce((accumulator, currentValue) => {
          // Convert the current value to a number using parseFloat
          const numericValue = parseFloat(currentValue);

          // Check if the conversion is successful (not NaN) before adding to the accumulator
          if (!isNaN(numericValue)) {
            return accumulator + numericValue;
          } else {
            return accumulator; // Ignore non-numeric values
          }
        }, 0); // Start with an initial accumulator value of 0
        return sum
      } else {
        return 0
      }
    },
    totalDebet() {
      const tes = this.excelData.filter(x => x.Debet !== '').map(x => x.Debet)
      if (tes.length > 0) {
        const sum = tes.reduce((accumulator, currentValue) => {
          // Convert the current value to a number using parseFloat
          const numericValue = parseFloat(currentValue);

          // Check if the conversion is successful (not NaN) before adding to the accumulator
          if (!isNaN(numericValue)) {
            return accumulator + numericValue;
          } else {
            return accumulator; // Ignore non-numeric values
          }
        }, 0); // Start with an initial accumulator value of 0
        return sum
      } else {
        return 0
      }
    },
    totalAmount() {
      return this.totalKredit - this.totalDebet
    }
  },
  methods: {
    validateEmptyValue(value, callback) {
      if (isNaN(value)) {
        callback(false);
      }    else {
        callback(true);
      }
    },
    validateNumericValue(value, callback) {
      if (isNaN(value)) {
        callback(false);
      } else if (value < 0) {
        callback(false);
      } else {
        callback(true);
      }
    },
    onKeyDown1(event) {
      const hotInstance = this.$refs.hot.hotInstance;
      const selected = hotInstance.getSelected();
      const [startRow, startCol, endRow, endCol] = selected;

      if (event.key === "Tab" && startRow[1] >= 4 && this.excelData[startRow[0]].transDescription === '') {
        console.log(this.excelData[startRow[0]].transDescription)
        // alert('Uraian tidak Boleh Kosong!')
        hotInstance.selectCell(startRow[0], startRow[1] - 1);
      }
    },
    onChange(changes, source) {
      if (source === 'edit') {
        this.formatTable(changes);
      }
      else if (source === 'CopyPaste.paste') {
        this.formatTable(changes);
      }
    },
    formatTable(changes) {
      for (const change of changes) {
        const [row, prop, oldValue, newValue] = change;
        const baris = this.excelData[row]

        if (prop === 'transCoaCode' && baris) {
        const findName = this.coa.coaNonBank.find(x => x.coaCode === baris.transCoaCode)
          if (baris.transCoaCode !== null && findName !== undefined) {
            baris.transCoaName = findName.coaName
            if (baris.transCoaName === undefined) {
              baris.transCoaName = null
              baris.transCoaCode = null
            }
          } else {
            baris.transCoaName = null
            baris.transCoaCode = null
          }
          this.excelData = [...this.excelData]
        } else if (prop === 'transCoaName' && baris) {
        const findCode = this.coa.coaNonBank.find(x => x.coaName === baris.transCoaName)
          if (baris.transCoaName !== null && findCode !== undefined) {
            baris.transCoaCode = findCode.coaCode
          } else {
            baris.transCoaName = null
            baris.transCoaCode = null
          }
          this.excelData = [...this.excelData]
        } else if (prop === 'Kredit' || prop === 'Debet' && baris) {
          const oppositeProp = prop === 'Kredit' ? 'Debet' : 'Kredit';
          const oppositeValue = this.excelData[row][oppositeProp];
            console.log(prop, oppositeProp)
            console.log(newValue, oppositeValue)
          if (newValue !== '' && newValue !== null) {
            // If one input has a non-empty value, set the opposite input to 0
              this.excelData[row][oppositeProp] = 0;
              this.hotSettings.columns.find(column => column.data === oppositeProp).readOnly = true;
              console.log(this.hotSettings.columns.find(column => column.data === oppositeProp))
          } else {
              this.excelData[row][oppositeProp] = '';
              this.hotSettings.columns.find(column => column.data === oppositeProp).readOnly = false;
          }
          this.excelData = [...this.excelData]
          this.hotSettings.columns = [...this.hotSettings.columns]
        } else {
          console.log('gagal')
        }
      }
    },
    sendData(event) {
      event.preventDefault()
      this.excelData = this.excelData
        .filter((item) => {
          const coaCodeIsEmpty = item.transCoaCode === "";
          const coaNameIsEmpty = item.transCoaName === "";
          const kreditIsEmpty = item.Kredit === "";
          const debetIsEmpty = item.Debet === "";
          const transDescriptionIsEmpty = item.transDescription === "";

          // Filter out objects with both transCoaCode and transCoaName empty
          if (coaCodeIsEmpty && coaNameIsEmpty) {
            return false;
          }

          // Filter out objects with both Kredit and Debet empty
          if (kreditIsEmpty && debetIsEmpty) {
            return false;
          }

          return true;
        })
        .map(x => {
          const data = {
            ...x,
            transNo: this.transNo,
            transEffDate: this.transEffDate,
            transNotes: this.transNotes,
            transUser: localStorage.getItem('_id'),
            transType:  x.Debet > 0 ? "debet":"kredit",
            transAmount: x.Debet > 0 ? x.Debet : x.Kredit,
          }
          return data
        })
      console.log(this.excelData)
      if (this.excelData.length > 0) {
        this.$swal({
          title: "Anda Yakin?",
          text: "Tekan Simpan jika anda sudah yakin!",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Simpan",
        }).then(async (result) => {
          if (result.isConfirmed) {
            alertLoading();
            try {
              console.log(this.excelData);
              await addJE(this.excelData)
              successAddData();
              this.$router.push({ name: this.$route.name.replace('-new', '') })
            } catch (error) {
              errorAddData();
            }
          }
        });
      } else {
        alert('ada data wajib yang masih kosong!')
      }
    },
    getContentWidth() {
      const contentDiv = this.$refs.contentDiv;
      let totalWidth = 0;
      const tes = this.hotSettings.columns.filter(x => !x.width).length
      const widthTes = this.hotSettings.columns.filter(x => x.width)
      for (const column of widthTes) {
        if (column.width) {
          totalWidth += column.width;
        }
      }
      if (contentDiv) {
        this.hotSettings.colWidths = (contentDiv.offsetWidth - 70 - totalWidth) / tes;
      }
    },
  },
};
</script>