import axios from "axios";
import { API, WA } from "@/API";
import transaksi from "../../API/transaksi";
import report from "../../API/report";
import master from "../../API/master";

// Sales Invoice
export const getSOCode = async () => {
  const domainID = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/trans/getTransId/'+domainID)
  var suppData = response.data;
  return suppData
}
export const SIedit = async (id) => {
  const response = await axios.get(API+'/trans/salesOrderbyId/'+id)
  var suppData = response.data;
  return suppData
}
export const SIFAedit = async (id) => {
  const response = await axios.get(API+'/trans/soListSalesOrderFAbyId/'+id)
  var suppData = response.data;
  return suppData
}
// Get

export const getPOInvoiceByPI = async (pi) => {
  const domainID = localStorage.getItem('domainCode')
  console.log("URLMU CAK ", API+'/coa/listPuchaseInvoiceForPaymentByNoPI/'+domainID+"/"+pi)
  const response = await axios.get(API+'/coa/listPuchaseInvoiceForPaymentByNoPI/'+domainID+"/"+pi)
  var suppData = response.data;
  return suppData
}
export const getShipmentById = async (id) => {
  const domainID = localStorage.getItem('domainCode')
  const response = await axios.get(API+'/shipment/getShipmentbyID/'+domainID+"/"+id)
  var suppData = response.data;
  return suppData
}
export const getSIById = async (id) => {
  const domainID = localStorage.getItem('domainCode')
  const response = await axios.get(API+'/shipment/getDetilSIbyNo/'+domainID+"/"+id)
  var suppData = response.data;
  return suppData
}
export const getDomainCode = async () => {
  const data = {
    userDomainCode: JSON.parse(localStorage.getItem('userDomainCode'))
  }
  console.log(API+'/domains/allbyuser')
  const response = await axios.post(API+'/domains/allbyuser', data)
  console.log(response.data, 'tes');
  if (response.data.length > 0) {
    return response.data[0]._id
  }
  return response.data
}

export const getSOById = async (year, month) => {
  const id = localStorage.getItem('domainCode')
  const response = await axios.get(API+'/trans/listTransById/'+id+'/'+year+'/'+month)
  console.log(response.data, 'tes');
  var suppData = response.data;
  return suppData
}
export const getWoReceiptList = async (date) => {
  const id = localStorage.getItem('domainCode')
  const response = await axios.get(API+'/transWO/getAllWOReceiptbyDomain/'+id+'/'+date)
  var suppData = response.data;
  return suppData
}
export const getWoReportByUser = async (user, date) => {
  const id = localStorage.getItem('domainCode')
  const response = await axios.get(API+'/transWO/getAllWOReceiptbyDomainByUser/'+id+'/'+date+'/'+user)
  var suppData = response.data;
  return suppData
}
export const get0 = async (routes) => {
    return master.find(data => data.masterRouting === routes)
}
export const getA = async (year, month) => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/coa/cashBankCurrentMonth/'+id+'/'+year+'/'+month)
    console.log(response.data, 'tes');
    var suppData = response.data;
    return suppData
}
export const getB = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/coa/cashBankVoucher/'+id)
    console.log(response.data, 'tes');
    var suppData = response.data;
    return suppData
}
export const getC = async (year, month) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API + "/coa/listcashBankTransByPeriode/" + id + "/" + year + "/" + month);
  console.log(response.data.voucher, "tes");
  var suppData = response.data;
  return suppData;
};
export const getD = async (code) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API + "/coa/cashBankTransByVoucher/" + id + "/" + code);
  var suppData = response.data;
  return suppData;
};
export const getE = async (code) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API + "/coa/listcashBankTrans/" + id + "/" + code);
  var suppData = response.data;
  return suppData;
};
export const getLBH = async (code) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API + "/coa/listBankHarian/" + id + "/" + code);
  var suppData = response.data;
  return suppData;
};
export const getLKH = async (code) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API + "/coa/listKasHarian/" + id + "/" + code);
  var suppData = response.data;
  return suppData;
};
export const getF = async (year, month) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API + "/coa/cashBankNeraca/" + id + "/" + year + "/" + month);
  var suppData = response.data;
  return suppData;
};

export const getG = async (date) => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/coa/getSumCashBankSaldoByDate/'+id+'/'+date)
    var suppData = response.data;
    return suppData
}
export const getH = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/trans/listTransAll/'+id)
    var suppData = response.data.filter(x => x.transType === 'SalesOrder');
    return suppData
}
export const getI = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/purchase/allpurchase/'+id)
    var suppData = response.data;
    return suppData
}
export const getListPRKlinik = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/purchase/alldefecta/'+id)
    var suppData = response.data;
    return suppData
}
export const getJ = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/purchase/allpurchase/'+id)
    var suppData = response.data;
    return suppData
}
export const getK = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/purchase/prListforPO/'+id)
    var suppData = response.data;
    return suppData
}
export const getListDefectaForSP = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/purchase/defectaListforSP/'+id)
    var suppData = response.data;
    return suppData
}
export const getL = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/purchase/listPObyDomain/'+id)
    var suppData = response.data;
    return suppData
}
export const getM = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/purchase/supplierListforGR/'+id)
    var suppData = response.data;
    return suppData
}
export const getN = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/domains/mappList/'+id)
    var suppData = response.data;
    return suppData
}
export const getO = async () => {
    const response = await axios.get(API+'/user/getUserDepartemenEnum')
    var suppData = response.data;
    return suppData
}
export const getP = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/transHys/getSupplierGRbyDomain/'+id)
    var suppData = response.data;
    return suppData
}
export const getQ = async (year, month) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API + "/coa/grafikCashBankDash/" + id + "/" + year + "/" + month);
  var suppData = response.data;
  return suppData;
};
export const get1 = async (routes) => {
    return transaksi.find(data => data.transRouting === routes)
}
export const get3 = async (routes) => {
  return report.find((data) => data.transRouting === routes);
};
export const get4 = async (data) => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/domains/getRoutingByAppProcess/'+id+'/'+data)
    console.log(response.data, 'tes');
    var suppData = response.data;
    return suppData
}
export const get5 = async () => {
  const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/items/getItemGroupEnum/'+id)
    var suppData = response.data;
    return suppData
}
export const getItemCat = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/items/getFieldCatDistinct/'+id)
    var suppData = response.data;
    return suppData
}
export const getItemByLocation = async (month, category) => {
    const domainId = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/invreport/detilInvByLocation/'+domainId+"/"+month+"/"+category)
    var suppData = response.data;
    return suppData
}
export const get7 = async (data) => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/items/bydomainNgroup/'+id+'/'+data)
    var suppData = response.data;
    return suppData
}
export const getSaldoKasbank = async (date) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API + "/coa/saldoCurrKasBankByDate/" + id + "/" + date);
  var suppData = response.data;
  return suppData;
};

export const getItem = async () => {
    const idDomain = localStorage.getItem('domainCode')
    // return master.find(data => data.masterRouting === routes).masterData
    const response = await axios.get(API+'/items/bydomain/'+idDomain)
    console.log(response.data, 'tes');
    var suppData = response.data;
    return suppData
}
export const getItemWithSaldo = async (date) => {
  const idDomain = localStorage.getItem('domainCode')
  // return master.find(data => data.masterRouting === routes).masterData
  const response = await axios.get(API+'/items/bydomainWithSaldo/'+idDomain+'/'+date)
  console.log(response.data, 'tes');
  var suppData = response.data;
  return suppData
}
export const getCoa = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/coa/allCoa/'+id)
    var suppData = response.data;
    return suppData
}
export const getCoaCode = async () => {
    const id = localStorage.getItem('domainCode')
    console.log("Halooooooo ", API+'/coa/allCoaCode/'+id)
    const response = await axios.get(API+'/coa/allCoaCode/'+id)
    var suppData = response.data;
    return suppData
}

export const getCustomer = async () => {
  const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/user/allCustomer/'+id)
    return response.data
}
export const getCustomerLite = async () => {
  const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/user/allCustomerLite/'+id)
    return response.data
}
export const getSupplier = async () => {
  const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/user/allSupplier/'+id)
    return response.data
}
export const getUser = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/user/allUser/'+id)
    return response.data
}
export const getRouting = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/domains/routeList/'+id)
    return response.data
}
export const getRoutingGroup = async () => {
    const response = await axios.get(API+'/domains/getRoutingGroupEnum')
    return response.data
}
export const getMappingLocation = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/domains/mappList/'+id)
    return response.data
}
export const getCreditTerms = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/domains/creditList/'+id)
    return response.data
}
export const getCostCenter = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/domains/costCenterList/'+id)
    return response.data
}
export const getUsaha = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/domains/nextStepByCode/'+id+'/3.1.1')
    return response.data
}
export const getPeriod = async () => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/domains/periodList/'+id)
    return response.data
}
export const getSetup = async () => {
    const response = await axios.get(API+'/setup/getAllSetup')

    return response.data
}
export const getListDistinc = async (data) => {
    // return master.find(data => data.masterRouting === routes).masterData
    const response = await axios.get(API+'/user/getUserFieldDistinct/3.1.1.3.1/'+data)
    var suppData = response.data;
    return suppData
}
export const getAppGroup = async () => {
  const id = localStorage.getItem('domainCode');
    const response = await axios.get(API+'/domains/getEnumAppGroup/'+id)
    var suppData = response.data;
    return suppData
}
export const getFilterAkses = async (data) => {
  const id = localStorage.getItem('domainCode');
    const response = await axios.get(API+'/domains/listappProcess/'+id+'/'+data)
    var suppData = response.data;
    return suppData
}
export const getDashboardPeriod = async (data) => {
  const id = localStorage.getItem('domainCode');
    const response = await axios.get(API+'/coa/saldoTotalCashBankDash/'+id+'/'+data)
    var suppData = response.data;
    return suppData
}
export const getListSidebar = async () => {
  const id = localStorage.getItem('domainCode');
    const response = await axios.get(API+'/domains/listappProcessAll/'+id)
    var suppData = response.data;
    return suppData
}
export const getPObyId = async (poNo) => {
    const id = localStorage.getItem('domainCode');
    const response = await axios.get(API + "/purchase/PObyId/" + id + "/" + poNo);
    var suppData = response.data;
    return suppData;
  };
  
export const getJEVoucher = async () => {
  const response = await axios.get(API+'/coa/journalEntryVoucher')
  return response.data
} 
export const getInventoryReport = async (date) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/invreport/getAllItemByPeriode/'+id+'/'+date)
  return response.data
}
export const getInventoryStockReport = async (date) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/invreport/getDetilAllItemByPeriode/'+id+'/'+date)
  return response.data
}
export const getListCustomerSO = async () => {
  const id = localStorage.getItem('domainCode');
  console.log("CEK ",API+'/trans/soListforShipment/'+id)
  const response = await axios.get(API+'/trans/soListforShipment/'+id)
  return response.data
}
export const getListWO = async () => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/transWO/getAllWOCreatebyDomain/'+id)
  return response.data
}
export const getUserDisctinc = async (code) => {
  const response = await axios.get(API+'/user/getUserFieldDistinct'+code)
  return response.data
}
export const getListPelayanan = async () => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/transWO/getAllWOPelayananByDomain/'+id)
  return response.data
}
export const getListLayanan = async (data) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/items/bydomainNgroupNlayanan/'+id+'/'+data)
  return response.data
}
export const getListRM = async (code) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/transWO/getAllWOPelayananbyDomainNUser/'+id+'/'+code)
  return response.data
}
export const getListRMAll = async (code) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/transWO/getAllWOPelayananbyDomainNUserAllDate/'+id+'/'+code)
  return response.data
}
export const getSOManufacture = async (status) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/trans/listTransStatusSO/'+status+'/'+id)
  return response.data
}

export const getSOARDP = async (status) => {
  const id = localStorage.getItem('domainCode');
  console.log("YOMAN ", API+'/trans/listTransStatusSOARDP/'+status+'/'+id)
  const response = await axios.get(API+'/trans/listTransStatusSOARDP/'+status+'/'+id)
  return response.data
}
export const getSO = async () => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/trans/soListSalesOrder/'+id)
  return response.data
}
export const getSIDO = async () => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/shipment/getAllDOforSI/'+id)
  return response.data
}
export const getSIDOCustomer = async (data) => {
  const id = localStorage.getItem('domainCode');
  console.log("URL GET SO BY USER",API+'/shipment/getAllDOforSIbyCustomer/'+id+'/'+data)
  const response = await axios.get(API+'/shipment/getAllDOforSIbyCustomer/'+id+'/'+data)
  return response.data
}
export const getSOFA = async () => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/trans/soListSalesOrderFA/'+id)
  return response.data
}
export const getJE = async () => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/coa/allJounalEntry/'+id)
  return response.data
}
export const getJEbyVoucher = async (data) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/coa/JounalEntryByVoucher/'+id+'/'+data)
  return response.data
}
export const getPOReceipt = async () => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/transHys/getAllGRbyDomain/'+id)
  return response.data
}
export const getPOKlinikReceipt = async () => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/transHys/getAllGRKlinikbyDomain/'+id)
  return response.data
}
export const getShipment = async () => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/shipment/getAllDObyDomain/'+id)
  return response.data
}
export const getNeracaSaldo = async (data) => {
  const [year, month] = data.split('-');
  const id = localStorage.getItem('domainCode');

  console.log("HMMMM NERACA SALDO ", API+'/coa/neracaSaldo/'+id+'/'+year+'/'+month)

  const response = await axios.get(API+'/coa/neracaSaldo/'+id+'/'+year+'/'+month)
  return response.data
}
export const getFormula = async () => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/items/formulaList/'+id)
  return response.data
}
export const getItemDetail = async (id) => {
  const response = await axios.get(API+'/items/getItemAllDetailbyId/'+id)
  return response.data
}
export const getListPI = async () => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API+'/coa/listPuchaseInvoiceForPaymentAll/'+id)
  return response.data
}

// Post
export const allByUser = async () => {
  const uItem = JSON.parse(localStorage.getItem("userDomainCode") || "[]");
  console.log(uItem);
  const response = await axios.post(API + "/domains/allbyuser", {
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userDomainCode: uItem,
    }),
  });
  var suppData = response.data;
  return suppData;
};
export const postFilterUser = async (data) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/user/getSearchField/"+ id, data);
  var suppData = response.data;
  return suppData;
};
export const postOTP = async (data) => {
  const response = await axios.post(API + "/coa/create", data);
  var suppData = response.data;
  return suppData;
};
export const postConfirmPR = async (prId, routingId, userLogicId, logic) => {
  console.log(prId, routingId, userLogicId, logic);
  const response = await axios.post(
    API + "/purchase/confirmPRByIdConfirm/" + prId + "/" + routingId + "/" + userLogicId + "/" + logic
  );
  var suppData = response.data;
  return suppData;
};
export const postRejectPR = async (prId, routingId, userLogicId) => {
  console.log(prId, routingId, userLogicId);
  const response = await axios.post(
    API + "/purchase/rejectPRById/" + prId + "/" + routingId + "/" + userLogicId
  );
  var suppData = response.data;
  return suppData;
};
export const postConfirmPO = async (prId, routingId, userLogicId, logic) => {
  console.log(prId, routingId, userLogicId, logic);
  const response = await axios.post(
    API + "/purchase/confirmPRByIdConfirm/" + prId + "/" + routingId + "/" + userLogicId + "/" + logic
  );
  var suppData = response.data;
  return suppData;
};
export const postPaymentCredit = async (data) => {
  const id = localStorage.getItem('domainCode');
  console.log("URLMU CAK ",  API + "/coa/cashBankCreatePaymentPO/"+id )
  const response = await axios.post(
    API + "/coa/cashBankCreatePaymentPO/"+id, data
  );
  var suppData = response.data;
  return suppData;
};

export const postPaymentDP = async (data,_id) => {
  const id = localStorage.getItem('domainCode');
  console.log("URLMU CAK ",  API + "/trans/paymentDP/" +_id +"/"+id )
  const response = await axios.post(
    API + "/trans/paymentDP/"+_id +"/"+id, data
  );
  var suppData = response.data;
  return suppData;
};

// Add

export const createSI = async (data) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/shipment/createSIbyCustomerDO/"+ id, data);
  var suppData = response.data;
  return suppData;
};
export const addSOHv = async (data) => {
  const response = await axios.post(API + "/trans/createSOKlinik", data);
  var suppData = response.data;
  return suppData;
};
export const addFormData = async (data) => {
  const response = await axios.post(API + "/user/register", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  var suppData = response.data;
  return suppData;
};
export const addCOA = async (data) => {
  
  const response = await axios.post(API + "/coa/create", data);
  var suppData = response.data;
  return suppData;
};
export const addUser = async (data) => {
  const response = await axios.post(API + "/user/register", data);
  var suppData = response.data;
  return suppData;
};
export const addUserPasien = async (data) => {
  const response = await axios.post(API + "/user/registerPasien", data);
  var suppData = response.data;
  return suppData;
};
export const addUserSupplier = async (data) => {
  const response = await axios.post(API + "/user/registerSupplier", data);
  var suppData = response.data;
  return suppData;
};
export const addWOReceipt = async (data) => {
  const response = await axios.post(API + "/transWO/wo-rct", data);
  var suppData = response.data;
  return suppData;
};
export const cancelWO = async (data, id) => {
  const response = await axios.post(API + "/transWO/woUpdateById/"+id, data);
  var suppData = response.data;
  return suppData;
};
export const addItems = async (data) => {
    const response = await axios.post(API+'/items/create', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    console.log(response.data, 'tes');
    var suppData = response.data;
    return suppData
}
export const addMappingLocation = async (data) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/domains/mappcreate/" + id, data);
  var suppData = response.data;
  return suppData;
};
export const addCreditTerms = async (data) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/domains/creditCreate/" + id, data);
  var suppData = response.data;
  return suppData;
};
export const addCostCenter = async (data) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/domains/costCenterCreate/" + id, data);
  var suppData = response.data;
  return suppData;
};
export const addRouting = async (data) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/domains/routeCreate/" + id, data);
  var suppData = response.data;
  return suppData;
};
export const addPeriod = async (data) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/domains/periodCreate/" + id, data);
  var suppData = response.data;
  return suppData;
};
export const addStockOpname = async (data, user) => {
  const domainID = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/items/updateStockOpname/"+ domainID + '/' + user, data);
  var suppData = response.data;
  return suppData;
};
export const addKasbank = async (data, id, code, name) => {
  const domainID = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/coa/cashBankCreate/"+ domainID + '/' + id + "/" + code + "/" + name, data);
  var suppData = response.data;
  return suppData;
};
export const addJE = async (data) => {
  const domainID = localStorage.getItem('domainCode')
  const response = await axios.post(API + "/coa/JournalEntryCreate/"+domainID, data);
  var suppData = response.data;
  return suppData;
};
export const addPI = async (data) => {
  const domainID = localStorage.getItem('domainCode')
  const response = await axios.post(API + "/purchase/createPurchaseInvoice/"+domainID, data);
  var suppData = response.data;
  return suppData;
};
export const soByIdDragon = async (data) => {
  const response = await axios.get(API + "/trans/listTransByIdDragon/"+ data);
  var suppData = response.data;
  return suppData;
};
export const addTrans = async (data) => {
  const response = await axios.post(API + "/trans/createTrans/", data);
  var suppData = response.data;
  return suppData;
};
export const addPR = async (data) => {
  const response = await axios.post(API + "/purchase/purchaseRequestCreate", data);
  var suppData = response.data;
  return suppData;
};
export const addPRKlinik = async (data) => {
  const response = await axios.post(API + "/purchase/defectaCreate", data);
  var suppData = response.data;
  return suppData;
};
export const addPO = async (data) => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.post(API+'/purchase/poCreate/'+id, data)
    console.log(response.data, 'tes');
    var suppData = response.data;
    return suppData
}
export const addPOKlinik = async (data) => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.post(API+'/purchase/spCreate/'+id, data)
    console.log(response.data, 'tes');
    var suppData = response.data;
    return suppData
}
export const addPOCash = async (data) => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.post(API+'/purchase/poCreateCash/'+id, data)
    console.log(response.data, 'tes');
    var suppData = response.data;
    return suppData
}
export const addGR = async (data) => {
  console.log(data)
    const response = await axios.post(API+'/transHys/create', data)
    console.log(response.data, 'tes');
    var suppData = response.data;
    return suppData
}
export const addGRKlinik = async (data) => {
  console.log(data)
    const response = await axios.post(API+'/transHys/createKlinik', data)
    console.log(response.data, 'tes');
    var suppData = response.data;
    return suppData
}
export const addShipment = async (data) => {
    const response = await axios.post(API+'/shipment/ShipmentCreate', data)
    console.log(response.data, 'tes');
    var suppData = response.data;
    return suppData
}
export const addFormula = async (data, id, qty) => {
  const response = await axios.post(API+'/items/formulaCreate/'+id+'/'+qty, data)
  console.log(response.data, 'tes');
  var suppData = response.data;
  return suppData
}
export const addLayanan = async (data, id) => {
  const response = await axios.post(API+'/items/paketLayananCreate/'+id, data)
  var suppData = response.data;
  return suppData
}

// Update
export const updateCOA = async (data, id) => {
  const response = await axios.post(API + "/coa/edit/" + id, data);
  var suppData = response.data;
  return suppData;
};

export const updateCustomer = async (data, id) => {
  const response = await axios.post(API + "/user/editCust/" + id, data);
  var suppData = response.data;
  return suppData;
};
export const updateSupplier = async (data, id) => {
  const response = await axios.post(API + "/user/editCust/" + id, data);
  var suppData = response.data;
  return suppData;
};
export const updateUser = async (data, id) => {
  const response = await axios.post(API + "/user/editCust/" + id, data);
  var suppData = response.data;
  return suppData;
};

export const updateItem = async (data, id) => {
  const response = await axios.post(API + "/items/updateItemOne/" + id, data);
  var suppData = response.data;
  return suppData;
};
export const updateMappingLocation = async (data, id) => {
  const domainID = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/domains/mappEdit/" + domainID + "/" + id, data);
  var suppData = response.data;
  return suppData;
};
export const updateCreditTerms = async (data, id) => {
  const domainID = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/domains/creditEdit/" + domainID + "/" + id, data);
  var suppData = response.data;
  return suppData;
};
export const updateCostCenter = async (data, id) => {
  const domainID = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/domains/costCenterEdit/" + domainID + "/" + id, data);
  var suppData = response.data;
  return suppData;
};
export const updateRouting = async (data, id) => {
  const domainID = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/domains/routeEdit/" + domainID + "/" + id, data);
  var suppData = response.data;
  return suppData;
};
export const updatePeriod = async (data, id) => {
  const domainID = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/domains/periodEdit/" + domainID + "/" + id, data);
  var suppData = response.data;
  return suppData;
};
export const updateSetup = async (data) => {
  const response = await axios.post(API + "/setup/updateAllsetup", data);
  var suppData = response.data;
  return suppData;
};
export const updatePassword = async (data, id) => {
  const response = await axios.post(API + "/user/editPwd/"+id, data);
  var suppData = response.data;
  return suppData;
};
export const updateFormula = async (data, id) => {
  const response = await axios.post(API + "/items/updateItemOne/"+id, data);
  var suppData = response.data;
  return suppData;
};
export const payHVKlinik = async (data, id) => {
  const domainID = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/trans/updateTrans/" + domainID + "/" + id + "/bayar", data);
  var suppData = response.data;
  return suppData;
};
export const updateSOKlinik = async (data, id) => {
  const response = await axios.post(API + "/trans/wo-updatePelayananApotek/" + id, data);
  var suppData = response.data;
  return suppData;
};
export const updateSIKlinik = async (data) => {
  const domainID = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/trans/savePaymentSalesInvoice/" + domainID, data);
  var suppData = response.data;
  return suppData;
};
export const paymentSIKlinik = async (data) => {
  const domainID = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/trans/paymentSalesInvoice/" + domainID, data);
  var suppData = response.data;
  return suppData;
};
export const paymentSIDragon = async (data) => {
  const domainID = localStorage.getItem('domainCode');
  // console.log("URL SI PAYMENNT ", API + "/trans/paymentSalesInvoiceERP/" + domainID)
  const response = await axios.post(API + "/trans/paymentSalesInvoiceERP/" + domainID, data);
  var suppData = response.data;
  return suppData;
};

// Delete
export const deleteCOA = async (data, id) => {
  const response = await axios.post(API + "/coa/edit/" + id, data);
  var suppData = response.data;
  return suppData;
};
export const deleteCreditTerms = async (id) => {
  const domainID = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/domains/creditDelete/" + domainID + "/" + id);
  var suppData = response.data;
  return suppData;
};
export const deleteCostCenter = async (id) => {
  const domainID = localStorage.getItem('domainCode');
  const response = await axios.post(API + "/domains/costCenterDelete/" + domainID + "/" + id);
  var suppData = response.data;
  return suppData;
};

// Other
export const mergePeriod = async (year, month) => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/domains/periodListbyMonth/'+id+'/'+year+'/'+month)
    return response.data
}
// Topbar
export const getNotif = async (data) => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/purchase/getListConfirmByUser/'+id+'/'+data)
    return response.data
}
export const getStockWO = async (date, data) => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/invreport/invSaldobyItemId/'+id+'/'+date+'/'+data)
    return response.data
}
export const getListObatBulanan = async (date) => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/invreport/laporanApotekByPeriode/'+id+'/'+date)
    return response.data
}
export const getListPiutangClient = async (date) => {
  const id = localStorage.getItem('domainCode')
  const response = await axios.get(API+'/invreport/laporanApotekByPeriode/'+id+'/'+date)
  return response.data
}
export const getConfirmPR = async (data) => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/purchase/detilPurchaseById/'+data)
    return response.data
}
// Global

export const getDataDistinc = async (data) => {
    const id = localStorage.getItem('domainCode')
    const response = await axios.get(API+'/items/getItemFieldDistinct/'+id+'/'+data)
    console.log(response.data, 'tes');
    var suppData = response.data;
    return suppData
}

export const getListPObyDomainPagination = async (perPage, currpage) => {
    const id = localStorage.getItem('domainCode');
    const response = await axios.get(API + "/purchase/listPObyDomainPagination/" + id + "/" + perPage + "/" + currpage);
    var suppData = response.data;
    return suppData;
  };
  
export const getListPOKlinikbyDomainPagination = async (perPage, currpage) => {
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API + "/purchase/listSPbyDomainPagination/" + id + "/" + perPage + "/" + currpage);
  var suppData = response.data;
  return suppData;
};

  export const getListArrItem = async (data) => {
    const id = localStorage.getItem('domainCode');
    console.log(data)
    const response = await axios.get(API + "/items/listArrItemGroupbydomain/" + id + "/" + data );
    var suppData = response.data;
    return suppData;
  };
  export const getListOpname = async (date) => {
    const domainCode = localStorage.getItem('domainCode');
    const response = await axios.get(API + "/transWO/getAllStockOpnamebyDomain/" + domainCode + "/" + date );
    var suppData = response.data;
    return suppData;
  };
  
  export const getListItemSO = async (data) => {
    const id = localStorage.getItem('domainCode');
    const response = await axios.get(API + "/items/bydomainByArrItemGroupSO/" + id + "/" + data );
    var suppData = response.data;
    return suppData;
  };
  export const getListApotekSO = async (date) => {
    const id = localStorage.getItem('domainCode');
    const response = await axios.get(API + "/trans/soListSalesOrderResep/" + id + '/' + date );
    var suppData = response.data;
    return suppData;
  };
  export const getListReportApotekSO = async () => {
    const id = localStorage.getItem('domainCode');
    const response = await axios.get(API + "/trans/soListSalesOrderApotekReport/" + id );
    var suppData = response.data;
    return suppData;
  };
  export const getListReportApotekSOBulanan = async (date) => {
    const tes = date.split("-")
    if (tes.length === 2) {      
    const id = localStorage.getItem('domainCode');
    const response = await axios.get(API + "/trans/soListSalesOrderApotekReportByPeriode/" + id + '/' + tes[0] + '/' + tes[1] );
    var suppData = response.data;
    return suppData;
    }
    else {
      return []
    }
  };
  export const getListPenjualanHV = async () => {
    const id = localStorage.getItem('domainCode');
    const response = await axios.get(API + "/trans/listTransAllStatus/" + id );
    var suppData = response.data;
    return suppData;
  };
  export const getListItem = async (data) => {
    const id = localStorage.getItem('domainCode');
    const response = await axios.get(API + "/items/bydomainByArrItemGroup/" + id + "/" + data );
    var suppData = response.data;
    return suppData;
  };

export const getListItembyDomain = async(data) =>{
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API + "/items/bydomain/" + id);
  var itemData = response.data;
  return itemData
}

export const getBincard = async(data, date) =>{
  const id = localStorage.getItem('domainCode');
  const response = await axios.get(API + "/invreport/binCardByPeriode/" + id + "/" + date + "/" +data);
  var itemData = response.data;
  return itemData
}

export const getDetailItemById = async(id) =>{
  const response = await axios.get(API + "/items/getItemDetailbyId/" + id);
  var itemData = response.data;
  return itemData
}

export const getListItembyIdDomain = async(id) =>{
  const response = await axios.get(API + "/items/getItemAllDetailbyId/" + id);
  var itemData = response.data;
  return itemData
}


export const getNoAntrian = async(id) =>{
  const response = await axios.get(API + "/transWO/getNoAntrian/" + id);
  var itemData = response.data;
  return itemData
}