<template>
    <main id="content" role="main" class="main">
      <div class="content container-fluid p-3 ps-0 pt-0" ref="contentDiv">
        <ul class="breadcrumb m-1 mt-0">
          <li><a href="#"><i class="bi-house-door"></i></a></li>
          <li>Laporan Laba Rugi</li>
        </ul>
        <!-- Content -->
        <template>
    <div>
      <h2 class="text-center">Laporan Laba Rugi</h2>
      <div class="section">
        <h4 class="judul">I. PENJUALAN BERSIH</h4>
        <table>
            <tr>
            <td>Penjualan</td>
            <td class="nominal"></td>
            <td class="nominal"></td>
            <td class="nominal">Rp 3.500.000</td>
            </tr>
            <tr>
            <td>Retur Penjualan</td>
            <td class="nominal"></td>
            <td class="nominal"></td>
            <td class="nominal">Rp 300.000</td>
            </tr>
            <tr>
            <td>Potongan Penjualan</td>
            <td class="nominal"></td>
            <td class="nominal"></td>
            <td class="nominal">Rp 200.000</td>
            </tr>
        </table>
      </div>
      
  
      <h4 class="judul">II HARGA POKOK PENJUALAN</h4>
      <table>
        <tr>
          <td>Persediaan Barang Jadi Awal</td>
          <td class="nominal">Rp 1.000.000</td>
          <td class="nominal"></td>
          <td class="nominal"></td>
        </tr>
        <tr>
          <td>Harga Pokok Produksi</td>
          <td class="nominal">Rp 1.000.000</td>
          <td class="nominal"></td>
          <td class="nominal"></td>
        </tr>
        <tr>
          <td>Barang Tersedia Untuk Dijual</td>
          <td class="nominal"></td>
          <td class="nominal">Rp 1.000.000</td>
          <td class="nominal"></td>
        </tr>
        <tr>
          <td>Persediaan Barang Jadi Akhir</td>
          <td class="nominal"></td>
          <td class="nominal">Rp 1.000.000</td>
          <td class="nominal"></td>
        </tr>
        <tr>
          <td>Harga Pokok Penjualan</td>
          <td class="nominal"></td>
          <td class="nominal"></td>
          <td class="nominal">Rp 1.000.000</td>
        </tr>
        <tr>
          <td>Laba Kotor</td>
          <td class="nominal"></td>
          <td class="nominal"></td>
          <td class="nominal">Rp 1.000.000</td>
        </tr>
      </table>
    </div>
</template>
      </div>
    </main>
  </template>


  
  <script>
  export default {
    // Your component logic goes here
  }
  </script>
  
  <style scoped>
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  td {
    border: 1px solid #dddddd;
    text-align: left;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10;
  }
  .nominal{
    width: 200px;
  }
  .section {
    margin-bottom: 20px; /* Memberi jarak bawah sebesar 20px */
  }
 
  </style>
  