var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "report p-3",
        staticStyle: {
          "font-size": "18px",
          width: "100%",
          "font-family": "sans-serif",
        },
      },
      [
        _c("h1", { staticClass: "text-center pt-4" }, [
          _c("b", [_vm._v("KLINIK UTAMA MUTIARA AISHA")]),
        ]),
        _c("p", { staticClass: "text-center" }, [
          _vm._v("JL. KI HAJAR DEWANTARA 04 SELOSARI MAGETAN"),
        ]),
        _c("p", { staticClass: "text-center" }, [_vm._v("082331 200 500")]),
        _c("div", { staticClass: "row pb-1" }, [
          _c(
            "div",
            { staticClass: "col-3", staticStyle: { "font-size": "20px" } },
            [_vm._v("Nm. Pasien")]
          ),
          _c(
            "div",
            { staticClass: "col-9", staticStyle: { "font-size": "20px" } },
            [_vm._v(":")]
          ),
          _c(
            "div",
            { staticClass: "col-3", staticStyle: { "font-size": "20px" } },
            [_vm._v("Nomor RM")]
          ),
          _c(
            "div",
            { staticClass: "col-9", staticStyle: { "font-size": "20px" } },
            [_vm._v(":")]
          ),
          _c(
            "div",
            { staticClass: "col-3", staticStyle: { "font-size": "20px" } },
            [_vm._v("Alamat")]
          ),
          _c(
            "div",
            { staticClass: "col-9", staticStyle: { "font-size": "20px" } },
            [_vm._v(":")]
          ),
          _c(
            "div",
            { staticClass: "col-3", staticStyle: { "font-size": "20px" } },
            [_vm._v("Dokter")]
          ),
          _c(
            "div",
            { staticClass: "col-9", staticStyle: { "font-size": "20px" } },
            [_vm._v(":")]
          ),
          _c(
            "div",
            { staticClass: "col-3", staticStyle: { "font-size": "20px" } },
            [_vm._v("Poli")]
          ),
          _c(
            "div",
            { staticClass: "col-9", staticStyle: { "font-size": "20px" } },
            [_vm._v(":")]
          ),
        ]),
        _c("div", {
          staticClass: "w-100 border-dark border-top border-bottom",
          staticStyle: { height: "0.25rem" },
        }),
        _c("div", { staticClass: "w-100" }, [
          _c("table", { staticClass: "w-100" }, [
            _c("thead", { staticClass: "border-bottom border-dark" }, [
              _c("tr", [
                _c("th", { staticStyle: { "font-size": "20px" } }, [
                  _vm._v("TRANSAKSI"),
                ]),
                _c(
                  "th",
                  {
                    staticClass: "text-end",
                    staticStyle: { "font-size": "20px" },
                  },
                  [_vm._v("QTY")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "text-end",
                    staticStyle: { "font-size": "20px" },
                  },
                  [_vm._v("DISC")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "text-end",
                    staticStyle: { "font-size": "20px" },
                  },
                  [_vm._v("JUMLAH")]
                ),
              ]),
            ]),
            _c("tbody", { staticClass: "border-bottom border-dark" }, [
              _c("tr", [
                _c("td", { staticStyle: { "font-size": "20px" } }, [
                  _vm._v("azam"),
                ]),
                _c(
                  "td",
                  {
                    staticClass: "text-end",
                    staticStyle: { "font-size": "20px" },
                  },
                  [_vm._v("1")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "text-end",
                    staticStyle: { "font-size": "20px" },
                  },
                  [_vm._v("5000")]
                ),
                _c(
                  "td",
                  {
                    staticClass: "text-end",
                    staticStyle: { "font-size": "20px" },
                  },
                  [_vm._v("30000")]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row d-flex justify-content-between" }, [
            _c("div", { staticClass: "col-6 row" }, [
              _c(
                "div",
                { staticClass: "col-5", staticStyle: { "font-size": "18px" } },
                [_vm._v("Tanggal")]
              ),
              _c(
                "div",
                { staticClass: "col-7", staticStyle: { "font-size": "18px" } },
                [_vm._v(": 07-10-2023")]
              ),
              _c(
                "div",
                {
                  staticClass: "col-5 pe-0",
                  staticStyle: { "font-size": "18px" },
                },
                [_vm._v("No. Nota")]
              ),
              _c(
                "div",
                {
                  staticClass: "col-7 pe-0",
                  staticStyle: { "font-size": "18px" },
                },
                [_vm._v(": 2310070007")]
              ),
            ]),
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-6 d-flex justify-content-between" },
                  [_vm._v(" SUBTOTAL "), _c("span", [_vm._v(":")])]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-6 text-end",
                    staticStyle: { "font-size": "18px" },
                  },
                  [_vm._v("445.000,00")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-6 d-flex justify-content-between",
                    staticStyle: { "font-size": "18px" },
                  },
                  [_vm._v(" DISC "), _c("span", [_vm._v(":")])]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-6 text-end",
                    staticStyle: { "font-size": "18px" },
                  },
                  [_vm._v("0,00")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-6 d-flex justify-content-between",
                    staticStyle: { "font-size": "18px" },
                  },
                  [
                    _c("b", [_vm._v("TOTAL")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(":")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-6 text-end",
                    staticStyle: { "font-size": "18px" },
                  },
                  [_c("b", [_vm._v("445.000,00")])]
                ),
              ]),
            ]),
          ]),
          _c("div", {
            staticClass: "w-100 border-dark border-top border-bottom",
            staticStyle: { height: "0.25rem" },
          }),
          _c("p", [
            _c("b", [_vm._v("Terbilang :")]),
            _vm._v(" Empat Ratus Empat Puluh Lima Ribu lima ratus Rupiah"),
          ]),
          _c("div", { staticClass: "text-center pt-3" }, [
            _c("h2", [
              _vm._v(" Terima kasih atas kunjungannya "),
              _c("p", [_vm._v("Semoga Lekas Sembuh")]),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }